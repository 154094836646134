import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { collection, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import Flex from 'components/common/Flex';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { GaugeChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import types from 'components/dashboards/types.json';
import React, { useContext, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { UserContext } from 'context/Context';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  GaugeChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = ({ color = 'primary', data }) => ({
  series: [
    {
      animation: color !== 'gray-500',
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      radius: '90%',
      pointer: {
        show: false
      },
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
        itemStyle: {
          color: getColor(color)
        }
      },
      axisLine: {
        lineStyle: {
          width: 8,
          color: [[1, getColor('gray-200')]]
        }
      },
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      },
      data: [
        {
          value: data,
          detail: {
            offsetCenter: ['7%', '4%']
          }
        }
      ],
      detail: {
        width: 50,
        height: 14,
        fontFamily: 'Poppins',
        fontSize: 28,
        fontWeight: 500,
        color: getColor('gray-500'),
        formatter: isNaN(data) ? `${data}%` : '{value}%'
      }
    }
  ]
});

const Totals = ({ bodyClassName, className, header, type, user }) => {
  const title = types[type];
  const { company } = useContext(UserContext);
  const db = useFirestore();
  const now = new Date(Date.now());
  const defaultFromDate = new Date(
    now.setMonth(new Date(Date.now()).getMonth() - 1)
  )
    .toISOString()
    .split(':')[0];
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const toDate = new Date(Date.now());
  toDate.setDate(toDate.getDate() + 1);
  const filters = [
    where('companyId', '==', `${company?.NO_ID_FIELD}`),
    user && where('userId', '==', `${user.NO_ID_FIELD}`),
    where('type', '==', type),
    where('createdAt', '>', fromDate),
    where('createdAt', '<=', toDate.toISOString().split('T')[0])
  ].filter(filter => filter);
  let indexesQuery = query(collection(db, 'none'));
  if (company?.NO_ID_FIELD) {
    indexesQuery = query(collection(db, 'indexes'), ...filters);
  }
  const { data: raw = [] } = useFirestoreCollectionData(indexesQuery);
  const color = raw.length ? 'primary' : 'gray-500';
  const average = raw.length
    ? Math.round(
        raw?.reduce((total, { value = 0 }) => total + value, 0) /
          (raw?.length || 1) || 0
      )
    : '-';

  const handleChange = event => {
    const { currentTarget } = event;
    const { value } = currentTarget;
    const fromDate = new Date(
      now.setMonth(new Date(Date.now()).getMonth() - value)
    )
      .toISOString()
      .split(':')[0];
    setFromDate(fromDate);
  };

  return (
    <Card className={classNames(className)}>
      {header || (
        <Card.Header>
          <h6 className="m-0 text-capitalize">{title}</h6>
        </Card.Header>
      )}
      <Card.Body
        className={classNames('pt-0', bodyClassName)}
        as={Flex}
        direction="column"
        alignItems="between"
      >
        <ReactEChartsCore
          echarts={echarts}
          className="m-auto"
          option={getOptions({ color, data: average })}
          style={{ height: '10.3rem', width: '10.3rem' }}
        />
        <div className="text-center mt-3">
          <h6 className="fs-0 mb-1">
            <FontAwesomeIcon
              icon="check"
              transform="shrink-2"
              className="text-success me-1"
            />
            {average}% de {title} en el equipo
          </h6>
        </div>
      </Card.Body>
      <Card.Footer className="bg-light py-2 align-content-end">
        <Form.Select
          defaultValue={1}
          size="sm"
          className="me-2"
          onChange={handleChange}
        >
          <option value={1}>Último mes</option>
          <option value={3}>Últimos 3 meses</option>
          <option value={12}>Últimos 12 meses</option>
        </Form.Select>
      </Card.Footer>
    </Card>
  );
};

Totals.propTypes = {
  bodyClassName: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.node,
  type: PropTypes.string,
  user: PropTypes.object
};

export default Totals;
