import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { ChatContext, UserContext } from 'context/Context';

dayjs.extend(calendar);

const Partner = ({ onClick, partner = {} }) => {
  const { createChat, setIsOpenNewChat } = useContext(ChatContext);
  const { me } = useContext(UserContext);
  let { avatar, name, ref } = partner || {};

  const handlePartnerSelected = () => {
    if (onClick) {
      onClick(ref);
      return;
    }
    createChat({ participants: [me?.ref, ref], type: 'user' });
    setIsOpenNewChat(false);
  };

  return (
    <div className="cursor-pointer" onClick={handlePartnerSelected}>
      <Flex alignItems="center">
        <Avatar className="ms-3" src={avatar} name={name} size="2xl" />
        <div className="border-top flex-1 py-3 chat-contact-body ms-3 d-md-none d-lg-block">
          <h6 className="my-1">{name}</h6>
        </div>
      </Flex>
    </div>
  );
};

Partner.propTypes = {
  onClick: PropTypes.func,
  partner: PropTypes.object.isRequired
};

export default Partner;
