import React from 'react';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import SoftBadge from 'components/common/SoftBadge';

const sliderSettings = {
  autoplay: true,
  fade: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: true
};

const ProductSingleImage = ({ image, name }) => {
  return (
    <Image
      src={image.src}
      className="w-100 fit-cover bg-200 rounded-top"
      alt={name}
      style={{ height: '360px' }}
    />
  );
};

const ProductImage = ({ name, id, isNew, files }) => {
  return (
    <div className="position-relative rounded-top overflow-hidden">
      {files.length === 1 && (
        <ProductSingleImage id={id} image={files[0]} name={name} />
      )}
      {files.length > 1 && (
        <Slider
          {...sliderSettings}
          className="p-0 slick-slider-arrow-inner h-100 full-height-slider"
        >
          {files.map((image, index) => (
            <ProductSingleImage
              key={`ProductImage-${id}-${index}`}
              id={`ProductImage-${id}-${index}`}
              image={image}
              name={name}
            />
          ))}
        </Slider>
      )}
      {isNew && (
        <SoftBadge
          pill
          bg="accent"
          className="position-absolute top-0 end-0 me-2 mt-2 fs--2 z-index-2"
        >
          Nuevo
        </SoftBadge>
      )}
    </div>
  );
};

ProductSingleImage.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string.isRequired
  }),
  name: PropTypes.string.isRequired
};

ProductImage.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isNew: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ProductImage;
