import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';

const RankingPodium = ({ data, unit }) => {
  return (
    <Col xs={12} lg={8}>
      <Card
        className={classNames('h-100 shadow-none', {
          'border border-3': true
        })}
      >
        <Card.Body>
          {!data?.length ? (
            <span>No hay datos</span>
          ) : (
            <Row className="mt-4 text-center">
              {data?.slice(0, 3).map(({ count = '-', user }, index) => {
                const { avatar, name = '-' } = user || {};
                return (
                  <Col
                    key={`Podium-User-${user?.NO_ID_FIELD || uuid()}`}
                    xs={classNames({
                      12: index === 0,
                      6: index !== 0
                    })}
                    className={classNames('mb-4', {
                      'mt-md-n7': index !== 0,
                      'pe-md-8': index === 1,
                      'ps-md-8': index === 2
                    })}
                  >
                    <Flex
                      className="position-relative"
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <div
                        className={classNames(
                          'position-absolute top-0 rounded-circle border-bottom border-5 px-5 pt-7 bg-opacity-50 opacity-50',
                          {
                            'bg-yellow border-yellow': index === 0,
                            'bg-400 border-400': index === 1,
                            'bg-orange border-orange': index === 2
                          }
                        )}
                      />
                      <div className="position-relative pt-3 px-3 pb-3">
                        <Avatar
                          className="mt-n4"
                          src={avatar}
                          name={name}
                          size="3xl"
                        />
                        <FontAwesomeIcon
                          icon="trophy"
                          className={classNames(
                            'position-absolute bottom-0 end-0 me-n4 mb-n3',
                            {
                              'text-yellow': index === 0,
                              'text-400': index === 1,
                              'text-orange': index === 2
                            }
                          )}
                          size="3x"
                        />
                      </div>
                      <Badge
                        className="position-relative mt-n2 mb-2 fs-1"
                        pill
                        bg="primary"
                        size="lg"
                        style={{ width: 32, height: 32 }}
                        as={Flex}
                        alignItems="center"
                        justifyContent="center"
                      >
                        {index + 1}
                      </Badge>
                      <div className="text-nowrap text-truncate position-relative">
                        <h5>{name}</h5>
                        <span className="fs--1 text-600">
                          {count} {unit}
                        </span>
                      </div>
                    </Flex>
                  </Col>
                );
              })}
            </Row>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

RankingPodium.propTypes = {
  data: PropTypes.array,
  unit: PropTypes.string
};

export default RankingPodium;
