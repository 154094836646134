import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Badge, Button } from 'react-bootstrap';
import { doc, deleteField } from '@firebase/firestore';
import Lottie from 'lottie-react';
import { useFirestore } from 'reactfire';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import UserList from 'components/common/UserList';
import SoftBadge from 'components/common/SoftBadge';
import { ChatContext, UserContext } from 'context/Context';
import confetti from 'assets/img/animated-icons/confetti.json';

const CheckItem = ({ item, index }) => {
  const { partners = [] } = useContext(UserContext);
  const { text, done } = item;

  return (
    <div className={classNames({ 'mt-3 mb-3': index === 0 })}>
      <Flex
        alignItems="center"
        className={classNames('py-3', { 'border-bottom': !!done.length })}
      >
        <div className="flex-grow-1 fs-0 fw-bold">{text}</div>
        <div className="fs-0 fw-bold">
          {!done.length ? (
            <SoftBadge>Por hacer</SoftBadge>
          ) : (
            <SoftBadge bg="success">
              <FontAwesomeIcon className="me-1" icon="check" size={24} />
              {done.length}
            </SoftBadge>
          )}
        </div>
      </Flex>
      {!!done.length && (
        <div style={{ paddingVertical: 8 }}>
          <UserList
            className="shadow-none"
            users={done.map(({ ref }) => {
              const user =
                partners.find(partner => partner?.ref?.path === ref?.path) ||
                {};
              return user;
            })}
            tableProps={{
              pagination: true,
              perPage: 10
            }}
          />
        </div>
      )}
    </div>
  );
};

const CheckList = ({ items, title }) => {
  const doneItems = items?.filter(({ done }) => done?.length) || [];
  const areAllDone = doneItems && doneItems.length === items.length;
  const percentage = parseInt((doneItems.length * 100) / (items.length || 0));
  return (
    <>
      <div>
        <div className="mb-2">
          <div className="flex-grow-1 fs-1 fw-bold">{title}</div>
        </div>
        <Flex alignItems="center">
          <div className="w-100 rounded-3 bg-250 overflow-hidden">
            <div
              className={classNames('h-100 bg-primary rounded-3 py-1', {
                'bg-success': areAllDone
              })}
              style={{
                width: `${(doneItems?.length * 100) / items?.length}%`
              }}
            />
          </div>
          <SoftBadge
            bg={classNames({
              'secondary bg-opacity-25': !areAllDone,
              success: areAllDone
            })}
            className="m-0 ms-2"
          >
            {percentage}%
          </SoftBadge>
        </Flex>
        {items
          ?.sort((i1, i2) => (i1?.done?.length > i2?.done?.length ? -1 : 1))
          .map((item, index) => (
            <CheckItem
              key={`Modal-Option-${index}`}
              item={item}
              index={index}
            />
          ))}
      </div>
    </>
  );
};

const ChecklistMessage = ({ message }) => {
  const { me, partners = [] } = useContext(UserContext);
  const { updateChat, updateMessage, showModal, setModalChildren } =
    useContext(ChatContext);
  const { NO_ID_FIELD, chat, chatId, extra } = message || {};
  const { title, items = [] } = extra || {};
  const doneItems = items?.filter(({ done }) => done?.length);
  const areAllDone = doneItems && doneItems.length === items.length;
  const db = useFirestore();
  const [state, setState] = useState({ areAllDone });

  const handleDo = async itemProp => {
    const newItems = items.map(item => {
      let { done = [] } = item;
      if (itemProp.text === item.text) {
        if (done.map(check => check?.ref?.path).includes(me?.ref?.path)) {
          done = done.filter(vote => vote?.ref?.path !== me?.ref?.path);
        } else {
          const createdAt = new Date(Date.now()).toISOString();
          done = [...done, { ref: me?.ref, createdAt }];
        }
      }
      return {
        ...item,
        done
      };
    });

    await updateMessage(NO_ID_FIELD, {
      extra: { ...extra, items: newItems }
    });
    const ref = doc(db, 'chat_messages', NO_ID_FIELD);
    await updateChat(chatId, {
      lastMessage: { ref, text: `${title}: ${me?.name} ha votado` },
      updatedAt: new Date(Date.now()).toISOString()
    });
    if (chat?.type === 'user' && chat?.deletedTo) {
      await updateChat(chatId, {
        deletedTo: deleteField()
      });
    }
  };

  const handleShowMoreInfo = () => {
    setModalChildren(<CheckList items={[...items]} title={title} />);
    showModal(true);
  };

  useEffect(() => {
    const showConfetti = !state?.areAllDone && areAllDone;
    setState(state => ({ ...state, areAllDone, showConfetti }));
  }, [areAllDone]);

  return (
    <>
      {state?.showConfetti && (
        <div className="position-absolute w-100 h-100 m-n2 overflow-hidden pointer-none z-index-2">
          <Lottie
            animationData={confetti}
            className="position-absolute mx-n6 start-0 end-0 h-100"
            loop={false}
          />
        </div>
      )}
      <h5 className="mb-1">{title}</h5>
      <Badge
        bg={classNames({ success: areAllDone, secondary: !areAllDone })}
        className={classNames('fs--1 mb-3', {
          'bg-opacity-25 text-body': !areAllDone
        })}
      >
        <FontAwesomeIcon icon={['far', 'square-check']} className="me-2" />
        {doneItems?.length}/{items?.length}
      </Badge>
      {items.map((item, index) => {
        const { text, done = [] } = item;
        const doneByMe = done
          .map(check => check?.ref?.path)
          .includes(me?.ref?.path);
        return (
          <div key={`option-${NO_ID_FIELD}-${index}`} className="mb-3">
            <Flex alignItems="start" justifyContent="">
              <Button
                variant="white-outline"
                className={classNames('border border-2 me-2 p-1 rounded-4', {
                  'bg-primary border-primary': doneByMe && !areAllDone,
                  'bg-success border-success': doneByMe && areAllDone,
                  'border-400': !doneByMe
                })}
                onClick={() => handleDo(item)}
                style={{ width: '23px', height: '23px' }}
              >
                <Flex alignItems="center" justifyContent="center">
                  <FontAwesomeIcon
                    icon="check"
                    className={classNames('fs--2', {
                      'text-white': doneByMe,
                      'text-200': !doneByMe
                    })}
                  />
                </Flex>
              </Button>
              <div className="flex-grow-1">
                <Flex justifyContent="space-between">
                  <strong
                    className={classNames('fs--1 flex-grow-1', {
                      'text-decoration-line-through': doneByMe
                    })}
                    style={{ minWidth: 100, marginTop: '2px' }}
                  >
                    {text}
                  </strong>
                  <div
                    className="me-1"
                    style={{
                      alignItems: 'flex-end'
                    }}
                  >
                    <AvatarGroup className="justify-content-end">
                      {done.map(({ ref }) => {
                        const user =
                          partners.find(
                            partner => partner?.ref?.path === ref?.path
                          ) || {};
                        const { id, avatar, name } = user;
                        return (
                          <Avatar
                            key={`Questionaire-Avatar-${id}`}
                            src={avatar && avatar}
                            name={name && name}
                            size="s"
                          />
                        );
                      })}
                    </AvatarGroup>
                  </div>
                </Flex>
              </div>
            </Flex>
          </div>
        );
      })}
      <div className="d-grid">
        <Button
          className="rounded-pill fs--1 fw-normal"
          size="sm"
          variant="outline-primary"
          onClick={() => handleShowMoreInfo(true)}
        >
          Ver detalles
        </Button>
      </div>
    </>
  );
};

CheckItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number
};

CheckList.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string
};

ChecklistMessage.propTypes = {
  message: PropTypes.object
};

export default ChecklistMessage;
