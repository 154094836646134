import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { AssistantContext } from 'context/Context';

const styles = {
  control: base => ({
    ...base,
    borderRadius: '1.375rem',
    minHeight: 32
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  })
};

const AddCandidateForm = ({ showForm, setShowForm }) => {
  const {
    candidates = [],
    createCandidate,
    offers = []
  } = useContext(AssistantContext);
  const [formData, setFormData] = useState({});

  const handleOfferChange = ({ value: offerId }) => {
    setFormData({ ...formData, offerId });
  };

  useEffect(() => {
    !showForm && setFormData({});
  }, [showForm]);

  return (
    <>
      {showForm && (
        <div
          className={classNames(
            'rounded-3 transition-none card shadow-sm p-3 bg-white dark__bg-1000 mb-3',
            {
              'mt-3': !!candidates?.length
            }
          )}
        >
          <Form
            onSubmit={e => {
              e.preventDefault();
              createCandidate({ ...formData, index: candidates.length });
              setShowForm(false);
            }}
          >
            <Form.Group className="mb-3">
              <Button
                variant={classNames({
                  'falcon-default': !formData?.cv,
                  'falcon-success': formData?.cv
                })}
                className="w-100"
                size="sm"
                onClick={({ target }) => target.nextElementSibling.click()}
              >
                Selecciona CV
              </Button>
              <Form.Control
                type="file"
                className="d-none"
                accept="application/pdf"
                onChange={({ target }) =>
                  setFormData({ ...formData, cv: target.files?.[0] })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Select
                menuPlacement="auto"
                options={offers?.map(({ NO_ID_FIELD, title }) => ({
                  label: title,
                  value: NO_ID_FIELD
                }))}
                placeholder="Selecciona la oferta"
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                className="form-select-sm p-0"
                onChange={handleOfferChange}
                styles={styles}
              />
            </Form.Group>
            <Row className="gx-2">
              <Col xs={5}>
                <Button
                  variant="link"
                  size="sm"
                  className="d-block w-100 text-500 text-decoration-none"
                  type="button"
                  onClick={() => setShowForm(false)}
                >
                  Cancelar
                </Button>
              </Col>
              <Col xs={7}>
                <Button
                  variant="primary"
                  size="sm"
                  className="d-block w-100 text-truncate"
                  type="submit"
                >
                  Añadir candidato
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </>
  );
};

AddCandidateForm.propTypes = {
  showForm: PropTypes.bool,
  setShowForm: PropTypes.func
};

export default AddCandidateForm;
