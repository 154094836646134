import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Loader from 'components/common/Loader';
import { UserContext } from 'context/Context';
import Logo from 'components/common/Logo';

const Root = () => {
  const { me } = useContext(UserContext);
  const { hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!me?.type) {
      return;
    }
    hash.includes('Feed') ? navigate(`/feed${hash}`) : navigate(`/home${hash}`);
  }, [hash, me?.type]);

  if (me?.type) {
    return null;
  }

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      className="min-vw-100 min-vh-100"
    >
      <Logo width={256} />
      <Loader />
    </Flex>
  );
};

export default Root;
