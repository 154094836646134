import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import Flex from 'components/common/Flex';
import { getColor, groupBy, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { ReportsContext } from 'context/Context';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
dayjs.locale('es');

import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

const getOptions = ({ labels, data }) => ({
  tooltip: {
    trigger: 'axis',
    formatter: '<strong>{b0}</strong> : {c0}'
  },
  xAxis: {
    data: labels
  },
  series: [
    {
      type: 'line',
      data,
      smooth: true,
      lineStyle: {
        width: 3
      },

      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.25)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: {
    bottom: '2%',
    top: '2%',
    right: '10px',
    left: '10px'
  }
});

const MonthlyUseOfHappyfy = () => {
  const { department: departmentProp } = useParams();
  let { activity = [], partners = [], start } = useContext(ReportsContext);

  const data = activity
    .filter(({ createdAt }) => createdAt >= start)
    .map(data => {
      const { createdAt, userId } = data;
      const [date] = new Date(createdAt)
        .toISOString()
        .match(/\d{4}-\d{2}-\d{2}/);
      const { department } =
        partners.find(({ id } = {}) => id === userId) || {};
      return { ...data, date, department };
    })
    .filter(
      ({ department }) => !departmentProp || department === departmentProp
    );

  const lastMonthData = activity
    .filter(({ createdAt }) => createdAt < start)
    .map(data => {
      const { userId } = data;
      const { department } =
        partners.find(({ id } = {}) => id === userId) || {};
      return { ...data, department };
    })
    .filter(
      ({ department }) => !departmentProp || department === departmentProp
    );

  const byDate = Object.entries(groupBy(data, 'date'))
    .sort(([key1], [key2]) => (key1 < key2 ? -1 : 1))
    .map(([key, value]) => ({ [key]: value?.length }))
    .reduce((result, date) => ({ ...result, ...date }), {});

  let labels = Object.keys(byDate).map(key => {
    const date = new Date(key);
    return dayjs(date).format('DD/MM/YYYY');
  });

  let values = Object.values(byDate);

  const diff = data.length - lastMonthData.length;
  const diffPercentage = Math.abs(
    Math.round((diff * 100) / (lastMonthData.length || 1))
  );
  const icon = diff === 0 ? 'minus' : diff > 0 ? 'caret-up' : 'caret-down';
  const diffBg = diff === 0 ? 'info' : diff > 0 ? 'success' : 'danger';

  return (
    <Card className="border border-3 h-md-100 shadow-none">
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">Uso mensual de Happyfy</h6>
      </Card.Header>

      <Card.Body
        as={Flex}
        alignItems="end"
        justifyContent="between"
        className="pt-0"
      >
        <div>
          <h2 className="fw-normal text-700 mb-1 lh-1">{data.length}</h2>
          <span>
            <SoftBadge pill bg={diffBg} className="me-2 fs--2">
              <FontAwesomeIcon icon={icon} className="me-1" />
              {diffPercentage}%
            </SoftBadge>
          </span>
        </div>
        <div className="ps-0">
          <BasicECharts
            echarts={echarts}
            options={getOptions({ labels, data: values })}
            style={{ width: '8.5rem', height: 90 }}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

MonthlyUseOfHappyfy.propTypes = {
  date: PropTypes.object
};

export default MonthlyUseOfHappyfy;
