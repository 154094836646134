import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import EventDetailAside from './EventDetailAside';
import EventDetailContent from './EventDetailContent';
import EventDetailHeader from './EventDetailHeader';
import { CalendarContext } from 'context/Context';

const EventDetail = () => {
  const { eventId } = useParams();
  const { events = [] } = useContext(CalendarContext);
  const event = events.find(({ id }) => id === eventId) || {};

  return (
    <>
      <EventDetailHeader event={event} />
      <Row className="g-3">
        <Col lg={8}>
          <EventDetailContent event={event} />
        </Col>
        <Col lg={4}>
          <EventDetailAside event={event} />
        </Col>
      </Row>
    </>
  );
};

export default EventDetail;
