import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { Form } from 'react-bootstrap';
import googleMapStyles from 'helpers/googleMapStyles';
import {
  GoogleMap as ReactGoogleMap,
  Marker,
  InfoWindow,
  StandaloneSearchBox,
  useJsApiLoader
} from '@react-google-maps/api';
// import mapMarker from '../../../src/assets/img/icons/map-marker.png';

const GoogleMap = ({
  mapStyle,
  initialCenter,
  darkStyle,
  className,
  searchClassName,
  mapClassName,
  children,
  onSearch,
  options: optionsProp,
  showInfoWindow: showInfoWindowProp = true,
  zoom = 18,
  ...rest
}) => {
  const libraries = useRef(['geocode', 'places']);
  const searchBox = useRef();
  const [place, setPlace] = useState();
  const [isLoaded, setIsLoaded] = useState();
  const { isLoaded: _isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: libraries.current
  });

  const {
    config: { isDark }
  } = useContext(AppContext);

  const [showInfoWindow, setShowInfoWindow] = useState(showInfoWindowProp);
  const [mapStyles, setMapStyles] = useState(mapStyle);

  const options = {
    mapTypeControl: true,
    streetViewControl: true,
    fullscreenControl: true,
    styles: googleMapStyles[mapStyles],
    ...(optionsProp || {})
  };

  const onLoad = ref => {
    searchBox.current = ref;
  };

  const onPlacesChanged = () => {
    const [place = {}] = searchBox?.current?.getPlaces() || [];
    onSearch?.(place);
  };

  useEffect(() => {
    setShowInfoWindow(showInfoWindowProp);
  }, [showInfoWindowProp]);

  useEffect(() => {
    setShowInfoWindow(!!initialCenter);
  }, [initialCenter]);

  useEffect(() => {
    if (darkStyle && isDark) setMapStyles(darkStyle);
    else setMapStyles(mapStyle);
  }, [isDark]);

  useEffect(() => {
    let mounted = true;
    if (!initialCenter || !window.google?.maps?.places) {
      return;
    }
    (async () => {
      const pl = new window.google.maps.Geocoder();
      const { results } = (await pl.geocode({ location: initialCenter })) || {};
      const place =
        results.find(({ types }) => types.includes('establishment')) ||
        results?.[0];
      const { place_id: placeId } = place || {};
      if (mounted && placeId) {
        const placeService = new window.google.maps.places.PlacesService(
          document.createElement('div')
        );
        placeService.getDetails({ placeId }, (place, status) => {
          if (mounted && status === 'OK') {
            place && setPlace(place);
          }
        });
      }
    })();
    return () => {
      mounted = false;
    };
  }, [initialCenter, window.google?.maps?.places]);

  useEffect(() => {
    setIsLoaded(_isLoaded);
  }, [_isLoaded]);

  if (!isLoaded) return <div>Cargando...</div>;

  return (
    <div className={`h-100 ${className}`} {...rest}>
      {onSearch && (
        <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
          <Form.Control
            placeholder=""
            type="text"
            name="location"
            className={searchClassName}
          />
        </StandaloneSearchBox>
      )}

      <ReactGoogleMap
        zoom={zoom}
        options={options}
        center={initialCenter}
        mapContainerClassName={mapClassName}
        mapContainerStyle={{
          width: '100%',
          height: '100%'
        }}
      >
        {place?.place_id && !!initialCenter && (
          <Marker
            onClick={() => setShowInfoWindow(!showInfoWindow)}
            position={initialCenter}
          >
            {!!showInfoWindow && (
              <InfoWindow
                onCloseClick={() => setShowInfoWindow(false)}
                position={initialCenter}
              >
                <>
                  {children && <div>{children}</div>}
                  {place?.name && <h5>{place?.name}</h5>}
                  {place?.adr_address && (
                    <div
                      dangerouslySetInnerHTML={{ __html: place?.adr_address }}
                    />
                  )}
                </>
              </InfoWindow>
            )}
          </Marker>
        )}
      </ReactGoogleMap>
    </div>
  );
};

GoogleMap.propTypes = {
  mapStyle: PropTypes.oneOf([
    'Default',
    'Gray',
    'Midnight',
    'Hopper',
    'Beard',
    'AssassianCreed',
    'SubtleGray',
    'Tripitty',
    'Cobalt'
  ]),
  darkStyle: PropTypes.oneOf([
    'Default',
    'Gray',
    'Midnight',
    'Hopper',
    'Beard',
    'AssassianCreed',
    'SubtleGray',
    'Tripitty',
    'Cobalt'
  ]),
  className: PropTypes.string,
  searchClassName: PropTypes.string,
  mapClassName: PropTypes.string,
  children: PropTypes.node,
  marker: PropTypes.node,
  initialCenter: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  onSearch: PropTypes.func,
  options: PropTypes.object,
  showInfoWindow: PropTypes.bool,
  zoom: PropTypes.number
};

export default GoogleMap;
