import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  query,
  updateDoc,
  where
} from '@firebase/firestore';
import {
  deleteObject,
  ref,
  getDownloadURL,
  uploadBytes
} from '@firebase/storage';
import {
  useFirestore,
  useFirestoreCollectionData,
  useStorage
} from 'reactfire';
import Compressor from 'compressorjs';
import { FilesContext, UserContext } from 'context/Context';

const FilesProvider = ({ children }) => {
  const { company, me } = useContext(UserContext);
  const [isOpenNewFilesModal, setIsOpenNewFilesModal] = useState(false);
  const db = useFirestore();
  const storage = useStorage();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(me?.NO_ID_FIELD);
  let filesQuery = query(collection(db, 'none'));
  if (company?.NO_ID_FIELD && selectedEmployeeId) {
    filesQuery = query(
      collection(db, 'files'),
      where('companyId', '==', company?.NO_ID_FIELD),
      where('userId', '==', selectedEmployeeId)
    );
  }
  const { status, data: files = [] } = useFirestoreCollectionData(filesQuery);

  const createFile = async (file, params) => {
    try {
      try {
        if (!file?.type?.match(/image\//)) {
          throw Error('Not compressable');
        }
        file = await new Promise(
          resolve =>
            new Compressor(file, {
              quality: 0.8,
              maxWidth: 1200,
              maxHeight: 1200,
              success(result) {
                const { name, type } = result;
                const newFile = new File([result], name, { type });
                resolve(newFile);
              }
            })
        );
      } catch (error) {
        console.error(error);
      }
      const { type: mimeType, size } = file;
      let { path, type, userId } = params;

      userId = userId || me?.NO_ID_FIELD;
      const [extension] = path.match(/([^.]*)$/);
      const createdAt = new Date(Date.now()).toISOString();
      const data = {
        companyId: company?.NO_ID_FIELD,
        createdAt,
        userId,
        ...params,
        mimeType,
        size
      };
      const fileDoc = await addDoc(collection(db, 'files'), data);
      const fileRef = ref(
        storage,
        `users/${userId}/documents/${type}/${fileDoc.id}.${extension}`
      );
      await uploadBytes(fileRef, file);
      return fileDoc;
    } catch (error) {
      console.error(error);
    }
  };

  const updateFile = async (item, { file, ...params } = {}) => {
    try {
      const { NO_ID_FIELD, path, type, userId } = item;
      if (params) {
        const docRef = doc(db, 'files', NO_ID_FIELD);
        await updateDoc(docRef, params);
      }
      if (file) {
        const {
          path: newPath = path,
          type: newType = type,
          userId: newUserId = userId
        } = params;
        const [extension] = path.match(/([^.]*)$/);
        const [newExtension] = newPath.match(/([^.]*)$/);
        const fileRef = ref(
          storage,
          `/users/${userId}/documents/${type}/${NO_ID_FIELD}.${extension}`
        );
        await deleteObject(fileRef);
        const newFileRef = ref(
          storage,
          `/users/${newUserId}/documents/${newType}/${NO_ID_FIELD}.${newExtension}`
        );
        try {
          new Compressor(file, {
            quality: 0.8,
            maxWidth: 1200,
            maxHeight: 1200,
            success(result) {
              uploadBytes(newFileRef, result);
            }
          });
        } catch (error) {
          await uploadBytes(newFileRef, file);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteFile = async item => {
    try {
      const { NO_ID_FIELD, path, type, userId } = item;
      const [extension] = path.match(/([^.]*)$/);
      try {
        const fileRef = ref(
          storage,
          `/users/${userId}/documents/${type}/${NO_ID_FIELD}.${extension}`
        );
        await deleteObject(fileRef);
      } catch (error) {
        console.error(error);
      }
      const docRef = doc(db, 'files', NO_ID_FIELD);
      await deleteDoc(docRef);
    } catch (error) {
      console.error(error);
    }
  };
  const getFileUrl = async item => {
    try {
      const { NO_ID_FIELD, path, type, userId } = item;
      const [extension] = path.match(/([^.]*)$/);
      return await getDownloadURL(
        ref(
          storage,
          `/users/${userId}/documents/${type}/${NO_ID_FIELD}.${extension}`
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  // console.log('FILES >>>', status, files);

  return (
    <FilesContext.Provider
      value={{
        createFile,
        deleteFile,
        files,
        getFileUrl,
        status,
        updateFile,
        isOpenNewFilesModal,
        setIsOpenNewFilesModal,
        selectedEmployeeId,
        setSelectedEmployeeId
      }}
    >
      {children}
    </FilesContext.Provider>
  );
};

FilesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default FilesProvider;
