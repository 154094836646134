import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { collection, limit, orderBy, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { Card, Col, Row } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from 'context/Context';

dayjs.extend(relativeTime);
dayjs.locale('es');

const Activity = ({
  activity: {
    title,
    text,
    icon = 'check',
    createdAt,
    color: status = 'primary'
  },
  isLast
}) => {
  const time = dayjs().to(dayjs(createdAt));
  return (
    <Row
      className={classNames(
        'g-3 recent-activity-timeline recent-activity-timeline-past',
        {
          [`recent-activity-timeline-${status}`]: status !== 'accent',
          'pb-x1': !isLast
        }
      )}
    >
      <Col xs="auto" className="ps-4 ms-2">
        <div className="ps-2">
          <div className="icon-item icon-item-sm rounded-circle bg-200 shadow-none">
            <FontAwesomeIcon icon={icon} className={`text-${status}`} />
          </div>
        </div>
      </Col>
      <Col>
        <Row className={classNames('g-3', { 'border-bottom pb-x1': !isLast })}>
          <Col>
            <h6 className="text-800 mb-1">{title}</h6>
            <p className="fs--1 text-600 mb-0">{text}</p>
          </Col>
          <Col xs="auto">
            <p className="fs--2 text-500 mb-0">{time}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const RecentActivity = ({ className, header, user }) => {
  const { company } = useContext(UserContext);
  const firestore = useFirestore();
  const filters = [
    company &&
      where('companyId', '==', `${user?.company || company?.NO_ID_FIELD}`),
    user && where('userId', '==', `${user.NO_ID_FIELD}`),
    where('timeline', '==', true),
    orderBy('createdAt', 'desc')
  ].filter(filter => filter);
  let activityQuery = query(collection(firestore, 'none'));
  if (company || user) {
    activityQuery = query(
      collection(firestore, 'events'),
      ...filters,
      limit(20)
    );
  }
  let { data = [] } = useFirestoreCollectionData(activityQuery);

  return (
    <Card as={Flex} direction="column" className={className}>
      {header || <FalconCardHeader title="Actividad reciente" titleTag="h6" />}
      <SimpleBarReact className="py-0 h-0 flex-grow-1 recent-activity-body-height">
        <Card.Body className="ps-2">
          {data.map((activity, index) => (
            <Activity
              key={`Activity-${activity.NO_ID_FIELD}`}
              activity={activity}
              isLast={index === data.length - 1}
            />
          ))}
        </Card.Body>
      </SimpleBarReact>
    </Card>
  );
};

Activity.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    createdAt: PropTypes.string.isRequired,
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
  }),
  isLast: PropTypes.bool
};

RecentActivity.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node,
  user: PropTypes.object
};

export default RecentActivity;
