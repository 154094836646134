import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import Tooltip from 'components/common/Tooltip';
import AppContext, { UserContext } from 'context/Context';
import { Nav } from 'react-bootstrap';
import CompanyDropdown from './CompanyDropdown';

const TopNavRightSideNavItem = () => {
  const { pathname } = useLocation();
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);
  const {
    me = {},
    isEditing,
    openWidgetsModal,
    setEditing
  } = useContext(UserContext);
  const { settings = {} } = me;
  const { colorScheme = 'auto' } = settings;

  const handleAddButtonClick = () => {
    openWidgetsModal(true);
  };

  const handleEditButtonClick = () => {
    setEditing(isEditing => !isEditing);
  };

  useEffect(() => {
    document.body.classList.toggle('editing', isEditing);
  }, [isEditing]);

  const handleAutoChange = ({ matches }) => {
    isDark !== matches && setConfig('isDark', matches);
  };

  useEffect(() => {
    if (colorScheme !== 'auto') {
      setConfig('isDark', colorScheme === 'dark');
      return;
    }
    const { matches: isDark } = window.matchMedia(
      '(prefers-color-scheme: dark)'
    );
    setConfig('isDark', isDark);
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', handleAutoChange);

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', handleAutoChange);
    };
  }, [colorScheme]);

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {isEditing && (
        <Nav.Item as="li">
          <Nav.Link
            className="px-2 theme-control-toggle"
            onClick={handleAddButtonClick}
          >
            <div className="theme-control-toggle-label w-auto h-auto rounded-pill py-1 px-2 fs--2">
              <FontAwesomeIcon icon="plus" className="fs-0" />
            </div>
          </Nav.Link>
        </Nav.Item>
      )}
      {pathname === '/home' && (
        <Nav.Item as="li">
          <Nav.Link
            className="px-2 theme-control-toggle"
            onClick={handleEditButtonClick}
          >
            <div className="theme-control-toggle-label w-auto h-auto rounded-pill py-2 py-md-1 px-2 fs--2">
              <FontAwesomeIcon
                icon={isEditing ? 'check' : 'th-large'}
                className="fs-0 me-md-2"
              />
              <span className="d-none d-md-inline-block">
                {isEditing ? 'ACEPTAR' : 'EDITAR'}
              </span>
            </div>
          </Nav.Link>
        </Nav.Item>
      )}
      <Nav.Item as="li">
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <Tooltip
            placement={isRTL ? 'bottom' : 'left'}
            title={isDark ? 'Cambiar al tema claro' : 'Cambiar al tema oscuro'}
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className="fs-0"
              />
            </div>
          </Tooltip>
        </Nav.Link>
      </Nav.Item>

      {me?.type === 'admin' && <CompanyDropdown />}
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
