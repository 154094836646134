import React, { useContext } from 'react';
import classNames from 'classnames';
import CompanyProfile from './CompanyProfile';
import UserProfile from './UserProfile';
import PricingTable from './PricingTable';
import Employees from './createEmployees';
import FirstQuestionaire from './happyQuestionaireViewer';
import { OnBoardingContext } from 'context/Context';

const Welcome = () => {
  const { step } = useContext(OnBoardingContext);

  return (
    <div className="mt-4">
      {step === 1 && (
        <UserProfile
          className={classNames({
            'd-none': step !== 1
          })}
        />
      )}
      {step === 2 && (
        <CompanyProfile
          className={classNames({
            'd-none': step !== 2
          })}
        />
      )}
      {step === 3 && (
        <Employees
          className={classNames({
            'd-none': step !== 3
          })}
        />
      )}
      {step === 4 && (
        <FirstQuestionaire
          className={classNames({
            'd-none': step !== 4
          })}
        />
      )}
      {step === 5 && (
        <PricingTable
          className={classNames({
            'd-none': step !== 5
          })}
        />
      )}
    </div>
  );
};

export default Welcome;
