import React, { useContext } from 'react';
import NavbarDropdown from './NavbarDropdown';
import {
  startRoutes,
  myRoutes,
  companyRoutes,
  benefitsRoutes
} from 'routes/routes';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NavbarDropdownApp from './NavbarDropdownApp';
import NavbarDropdownPages from './NavbarDropdownPages';
import AppContext, { UserContext } from 'context/Context';

const NavbarTopDropDownMenus = () => {
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig
  } = useContext(AppContext);
  const { me } = useContext(UserContext);

  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      setConfig('navbarCollapsed', !navbarCollapsed);
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

  return (
    <>
      <NavbarDropdown title="start">
        {startRoutes.children?.[0]?.children?.map(route => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown>

      <NavbarDropdown title="Usuario">
        <NavbarDropdownApp
          items={myRoutes.children.filter(
            ({ role }) => !role || me?.type === role
          )}
        />
      </NavbarDropdown>

      <NavbarDropdown title="Empresa">
        <NavbarDropdownApp
          items={companyRoutes.children.filter(
            ({ role }) => !role || me?.type === role
          )}
        />
      </NavbarDropdown>

      <NavbarDropdown title="Beneficios para empleados">
        <NavbarDropdownPages
          items={benefitsRoutes.children.filter(
            ({ role }) => !role || me?.type === role
          )}
        />
      </NavbarDropdown>
    </>
  );
};

export default NavbarTopDropDownMenus;
