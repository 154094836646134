import React, {
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AppContext, {
  CalendarContext,
  TimeBalanceContext,
  UserContext
} from 'context/Context';
import DatePicker from 'react-datepicker';
import Select, { components } from 'react-select';
import Flex from 'components/common/Flex';
import Popover from 'components/common/Popover';
import UserList from 'components/common/UserList';
import TimeBalanceProvider from 'components/user/time-control/TimeBalanceProvider';
import timeOffSubtypes from 'components/user/time-off/subtypes.json';
import { getDaySeconds, getUserSchedule } from 'helpers/utils';

const styles = {
  control: base => ({
    ...base,
    borderRadius: '1.375rem',
    fontFamily:
      'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol'
  }),
  option: base => ({
    ...base,
    fontFamily:
      'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol'
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  })
};

const Option = ({ data, isFocused, isSelected, label, ...rest }) => {
  const { description, me } = data;
  return (
    <components.Option
      {...rest}
      className={classNames('m-0', {
        'bg-primary': isSelected,
        'bg-soft-primary': isFocused && !isSelected,
        'border-bottom': me?.type === 'admin'
      })}
    >
      <span
        className={classNames('m-0', {
          'fw-semi-bold': me?.type === 'admin',
          'text-white': isSelected,
          'text-dark': isFocused && !isSelected
        })}
      >
        {label}
      </span>
      {description && me?.type === 'admin' && (
        <p
          className={classNames('m-0 mt-2 text-600 fs--1', {
            'text-white': isSelected,
            'text-dark': isFocused && !isSelected
          })}
        >
          {description}
        </p>
      )}
    </components.Option>
  );
};

Option.propTypes = {
  data: PropTypes.object,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string
};

const NewTimeOffModal = ({
  setIsOpenScheduleModal,
  isOpenScheduleModal,
  scheduleStartDate,
  setScheduleStartDate,
  scheduleEndDate,
  setScheduleEndDate
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { create, update, editingEvent, setEditingEvent, timeOff } =
    useContext(CalendarContext);
  const { balance: balanceSeconds = 0 } = useContext(TimeBalanceContext);
  const companyList = useRef();
  const userList = useRef();
  const balanceHours = parseInt(balanceSeconds / 3600);
  const {
    company,
    me,
    partners = [],
    schedules = []
  } = useContext(UserContext);
  const [formData, setFormData] = useState({
    subtype: 'vacation',
    comment: ''
  });
  const subtypeData =
    timeOffSubtypes.find(({ value }) => value === formData?.subtype) || {};
  const {
    avatar: companyAvatar,
    name: companyName,
    vacationLock,
    vacationDays: companyVacationDays = 22
  } = company || {};
  const user =
    formData?.participants?.length === 1
      ? partners.find(
          ({ ref }) => ref?.path === formData?.participants?.[0]?.path
        )
      : undefined;
  const schedule = getUserSchedule(schedules, user);

  const isSelectable = date => {
    const isoDate = date.toISOString();
    const day = date.getDay();
    const isWeekDay = day !== 0 && day !== 6;
    const isLocked =
      subtypeData?.value === 'vacation' &&
      vacationLock?.some(
        lock => lock?.start <= isoDate && isoDate <= lock?.end
      );
    return isWeekDay && !isLocked;
  };

  const handleClose = () => {
    setIsOpenScheduleModal(!isOpenScheduleModal);
    setEditingEvent();
    setFormData({ subtype: 'vacation', comment: '' });
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const handleChangeForAllCompany = useCallback(
    users => {
      setFormData(formData => {
        let { participants } = formData;
        if (!participants) {
          companyList.current.toggleRowSelected(0, true);
        }
        const isForAllCompany = !!users?.length;
        if (isForAllCompany) {
          !!userList?.current?.selectedFlatRows?.length &&
            userList?.current?.toggleAllRowsSelected?.(false);
          participants = null;
        }
        return { ...formData, participants };
      });
    },
    [formData.participants]
  );

  const handleChangeSelection = useCallback(() => {
    setFormData(formData => {
      const participants = userList?.current?.selectedFlatRows.map(
        ({ original }) => original.ref
      );
      const isForAllCompany = !participants?.length;
      companyList?.current?.toggleAllRowsSelected?.(isForAllCompany);
      return {
        ...formData,
        participants: isForAllCompany ? null : participants
      };
    });
  }, [formData.participants]);

  const handleChangeType = ({ value }) => {
    handleChange({
      target: {
        name: 'subtype',
        value
      }
    });
  };

  const handleChangeDates = dates => {
    let [start, end] = Array.isArray(dates) ? dates : [dates, dates];
    setScheduleStartDate(start);
    setScheduleEndDate(end);
    const [startDay] = start?.toISOString()?.match(/\d{4}-\d{2}-\d{2}/) || [];
    const [endDay = startDay] =
      end?.toISOString()?.match(/\d{4}-\d{2}-\d{2}/) || [];
    const [startTime] = start?.toISOString()?.match(/\d{2}:\d{2}:\d{2}/) || [];
    const [endTime] = end?.toISOString()?.match(/\d{2}:\d{2}:\d{2}/) || [];
    startTime &&
      setScheduleStartDate(new Date(`${startDay}T${startTime}.000Z`));
    endTime && setScheduleEndDate(new Date(`${endDay}T${endTime}.000Z`));
  };

  const handleSubmit = e => {
    e.preventDefault();
    let { participants, ...data } = formData;
    const { start, end } = data;
    const dates = [];
    const date = new Date(start);
    const currentYear = date.getFullYear();
    const endDate = new Date(end);
    const { location } = user || {};
    const { holidays = [] } = location || {};
    const holidayDates = holidays.map(({ start }) =>
      new Date(start).toDateString()
    );
    while (
      date.toISOString().split('T')[0] <= endDate.toISOString().split('T')[0]
    ) {
      const _date = new Date(date);
      const day = _date.getDay();
      const timeOffDay = timeOff.some(({ start, end, participants }) => {
        return (
          start <= _date.toISOString() &&
          _date.toISOString() <= end &&
          (participants === null ||
            participants.some(ref => ref?.path === user?.ref?.path))
        );
      });
      if (
        day !== 0 &&
        day !== 6 &&
        !holidayDates.includes(_date.toDateString()) &&
        !timeOffDay
      ) {
        dates.push(_date);
      }
      date.setDate(date.getDate() + 1);
    }
    const days = dates.length;

    const lockedBlock =
      subtypeData?.value === 'vacation' &&
      vacationLock?.find(
        lock =>
          (lock?.start <= start && start <= lock?.end) ||
          (lock?.start <= end && end <= lock?.end) ||
          (start <= lock?.start && end >= lock?.end)
      );

    const isNotValid = participants?.some(ref => {
      const user = partners?.find(partner => partner?.ref?.path === ref?.path);
      const {
        startAt,
        vacationDays: fullYearVacationDays = companyVacationDays
      } = user || {};
      const startDateISO =
        startAt > `${currentYear}-01-01` ? startAt : `${currentYear}-01-01`;
      const startDate = new Date(startDateISO);
      const startDateMonthDays = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        0
      ).getDate();
      const percentage =
        ((startDateMonthDays - (startDate.getDate() - 1)) * 100) /
        startDateMonthDays;
      const vacationDaysPerMonth = fullYearVacationDays / 12;
      const firstMonthDays = (percentage * vacationDaysPerMonth) / 100;
      const restDays = (12 - (startDate.getMonth() + 1)) * vacationDaysPerMonth;
      const generatedDays = parseInt(
        Number((firstMonthDays + restDays).toFixed(13))
      );
      const userTimeOff = timeOff.filter(
        ({ start, participants }) =>
          start > `${currentYear}` &&
          (!participants ||
            participants?.some(ref => ref?.path === user?.ref?.path))
      );
      const approvedTimeOff = userTimeOff.filter(
        ({ approved }) => approved === true
      );
      const approvedDays = approvedTimeOff
        .filter(({ subtype = 'vacation' }) => subtype === 'vacation')
        .reduce((total, { days }) => total + days, 0);

      const totalSeconds = dates.reduce((total, date) => {
        const daySeconds = getDaySeconds(schedule, date);
        return total + daySeconds;
      }, 0);

      const availableDays =
        subtypeData?.max || subtypeData?.value === 'vacation'
          ? generatedDays - approvedDays
          : -1;

      return (
        (subtypeData?.value === 'compensation' &&
          totalSeconds > balanceSeconds) ||
        (availableDays > -1 && days > availableDays)
      );
    });

    if (lockedBlock) {
      toast.warning(
        `Has seleccionado días bloqueados:
        ${lockedBlock?.title}
        (${new Date(lockedBlock?.start).toLocaleDateString()} - ${new Date(
          lockedBlock?.end
        ).toLocaleDateString()})`
      );
      return;
    }

    if (isNotValid) {
      toast.warning(
        `Excedes el número de días disponibles para la ausencia: ${subtypeData?.label?.toLowerCase()} ${
          subtypeData?.max ? `(${subtypeData?.max} días)` : ''
        }`
      );
      return;
    }

    if (editingEvent) {
      const {
        approved: prevApproved = '',
        comment: prevComment = '',
        start: prevStart,
        end: prevEnd,
        subtype: prevSubtype
      } = editingEvent;
      const { comment = prevComment, start, end, subtype } = data;
      const approved =
        start !== prevStart || end !== prevEnd || subtype !== prevSubtype
          ? null
          : prevApproved;
      update(editingEvent, { approved, comment, start, end, subtype });
    } else {
      if (!participants?.length) {
        create({
          ...data,
          title: 'Vacaciones de empresa',
          participants: null,
          type: 'time-off',
          approved: null
        });
      } else {
        participants.forEach(participant => {
          const { name = '-' } =
            partners?.find(({ ref }) => ref?.path === participant?.path) || {};
          create({
            ...data,
            title: `${name}`,
            participants: [participant],
            type: 'time-off',
            approved: null
          });
        });
      }
    }
    handleClose();
  };

  useEffect(() => {
    if (!me?.type || me?.type === 'admin') {
      return;
    }
    setFormData({
      ...formData,
      participants: [me?.ref]
    });
  }, [isOpenScheduleModal, me]);

  useEffect(() => {
    if (isOpenScheduleModal) {
      const start =
        scheduleStartDate && new Date(scheduleStartDate).toISOString();
      const end = scheduleEndDate && new Date(scheduleEndDate).toISOString();
      setFormData({
        ...formData,
        start,
        end
      });
    }
  }, [isOpenScheduleModal, scheduleStartDate, scheduleEndDate]);

  useEffect(() => {
    if (!editingEvent) {
      return;
    }
    const { start, end } = editingEvent;
    setScheduleStartDate(new Date(start));
    setScheduleEndDate(new Date(end));
    setFormData({
      ...formData,
      ...editingEvent
    });
  }, [editingEvent]);

  return (
    <Modal
      show={isOpenScheduleModal}
      onHide={handleClose}
      contentClassName="border"
      onExited={() => {
        setScheduleStartDate(null);
        setScheduleEndDate(null);
      }}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-x1 border-bottom-0"
        >
          <Modal.Title as="h5">
            {editingEvent ? 'Editando ausencia' : 'Nueva ausencia'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          <Form.Group className="mb-3">
            <Form.Label className="fs-0">
              Tipo de ausencia{' '}
              <Popover
                placement="bottom"
                content={
                  <>
                    <div className="text-end">
                      <FontAwesomeIcon className="me-2" icon="eye" />
                      Visible para admins
                    </div>
                    <hr />
                    {timeOffSubtypes.map(
                      ({ label, description, value }, index) => (
                        <div key={`Subtype-description-${value}`}>
                          {index > 0 && <hr />}
                          <h6>{label}</h6>
                          <p>{description}</p>
                        </div>
                      )
                    )}
                  </>
                }
                popoverClass="d-flex flex-column overflow-auto max-w-500px max-h-500px"
              >
                <FontAwesomeIcon icon="question-circle" />
              </Popover>
            </Form.Label>
            <Select
              menuPlacement="auto"
              options={timeOffSubtypes.filter(
                ({ value }) => value !== 'compensation' || balanceHours > 0
              )}
              defaultValue={timeOffSubtypes.find(
                ({ value }) => value === (editingEvent || formData)?.subtype
              )}
              placeholder="Selecciona tipo"
              classNamePrefix="react-select"
              onChange={handleChangeType}
              menuPortalTarget={document.body}
              styles={styles}
              components={{ Option }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="fs-0">Fecha</Form.Label>
            <DatePicker
              selected={scheduleStartDate}
              onChange={handleChangeDates}
              startDate={scheduleStartDate}
              endDate={scheduleEndDate}
              filterDate={isSelectable}
              selectsRange={!subtypeData?.time}
              className="form-control"
              placeholderText="d MMMM - d MMMM"
              dateFormat="d MMMM"
            />
          </Form.Group>

          {subtypeData?.time && (
            <Form.Group className="mb-3">
              <Form.Label className="fs-0">Hora</Form.Label>
              <Flex alignItems="center" justifyContent="between">
                <DatePicker
                  selected={scheduleStartDate}
                  onChange={start =>
                    handleChangeDates([
                      start,
                      formData?.end && new Date(formData.end)
                    ])
                  }
                  filterDate={isSelectable}
                  showTimeSelect
                  showTimeSelectOnly
                  className="form-control flex-grow-1"
                  placeholderText="--:--"
                  dateFormat="H:mm"
                />
                <FontAwesomeIcon icon="arrow-right" className="mx-2" />
                <DatePicker
                  selected={scheduleEndDate}
                  onChange={end =>
                    handleChangeDates([
                      formData?.start && new Date(formData.start),
                      end
                    ])
                  }
                  filterDate={isSelectable}
                  showTimeSelect
                  showTimeSelectOnly
                  className="form-control flex-grow-1"
                  placeholderText="--:--"
                  dateFormat="H:mm"
                />
              </Flex>
            </Form.Group>
          )}

          <Form.Group className="mb-3">
            <Form.Label className="fs-0">Comentario</Form.Label>
            <Form.Control
              as="textarea"
              className="resize-none"
              name="comment"
              value={formData?.comment || ''}
              rows={5}
              onChange={({ target }) =>
                setFormData(params => ({
                  ...params,
                  comment: target.value
                }))
              }
              placeholder="Si quieres puedes dejar algún comentario"
            />
          </Form.Group>

          {me?.type === 'admin' && !editingEvent && (
            <Form.Group>
              <Form.Label className="fs-0">
                Empleados a los que afecta la ausencia
              </Form.Label>
              <UserList
                innerRef={companyList}
                className="shadow-none mb-3"
                onChangeSelection={handleChangeForAllCompany}
                users={[
                  {
                    NO_ID_FIELD: null,
                    avatar: companyAvatar,
                    name: companyName,
                    department: 'Vacaciones para toda la empresa'
                  }
                ]}
                headerClassName="d-none"
                tableProps={{
                  selection: true,
                  selectionColumnWidth: 28
                }}
              />
              <UserList
                innerRef={userList}
                className="shadow-none"
                onChangeSelection={handleChangeSelection}
                users={partners}
                headerClassName="d-none"
                tableProps={{
                  search: true,
                  selection: true,
                  selectionColumnWidth: 28,
                  pagination: true,
                  perPage: 6
                }}
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer className="bg-light px-x1 border-top-0">
          <Button
            disabled={!formData?.start || !formData?.end}
            variant="primary"
            type="submit"
            className="px-4 mx-0"
          >
            {editingEvent ? 'Editar ausencia' : 'Crear ausencia'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

NewTimeOffModal.propTypes = {
  setIsOpenScheduleModal: PropTypes.func.isRequired,
  isOpenScheduleModal: PropTypes.bool.isRequired,
  scheduleStartDate: PropTypes.instanceOf(Date),
  setScheduleStartDate: PropTypes.func.isRequired,
  scheduleEndDate: PropTypes.instanceOf(Date),
  setScheduleEndDate: PropTypes.func.isRequired
};

const NewTimeOffModalWithBalance = props => {
  const { selectedEmployeeId } = useContext(CalendarContext);
  return (
    <TimeBalanceProvider userId={selectedEmployeeId}>
      <NewTimeOffModal {...props} />
    </TimeBalanceProvider>
  );
};

export default NewTimeOffModalWithBalance;
