import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FeedCardHeader from './FeedCardHeader';
import { Card } from 'react-bootstrap';
import { onSnapshot } from '@firebase/firestore';
import FeedCardContent from './FeedCardContent';
import FeedCardFooter from './FeedCardFooter';
import { CalendarContext } from 'context/Context';

const FeedCard = ({ className, feed, ...rest }) => {
  const [data, setData] = useState();
  const { events } = useContext(CalendarContext);
  const event = events?.find(
    ({ NO_ID_FIELD }) => NO_ID_FIELD === data?.content?.event
  );

  const handleChanges = async doc => {
    const data = await doc.data();
    setData({ NO_ID_FIELD: doc.id, ...data });
  };

  useEffect(() => {
    onSnapshot(feed.ref, handleChanges);
  }, []);

  if (!data || (data?.content?.event && !event)) {
    return null;
  }

  return (
    <Card
      id={`Feed${feed?.id}`}
      {...rest}
      className={classNames('overflow-hidden', className)}
    >
      <FeedCardHeader feed={data} />
      <FeedCardContent feed={data} />
      <FeedCardFooter feed={data} />
    </Card>
  );
};

FeedCard.Header = FeedCardHeader;
FeedCard.propTypes = {
  className: PropTypes.string,
  feed: PropTypes.object
};

export default FeedCard;
