import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import AddItem from './AddItem';
import Item from './Item';

const Personality = ({ className, color, user }) => {
  const { profile = {} } = user || {};
  const { personality: data = [] } = profile;

  return (
    <Card className={className}>
      <FalconCardHeader title="Personalidad" light />
      <Card.Body>
        <Flex wrap="wrap">
          {data.map(tag => (
            <Item
              key={`Personality-${tag}`}
              color={color}
              profileKey="personality"
              user={user}
              text={tag}
            />
          ))}
          <AddItem
            color={color}
            profileKey="personality"
            placeholder="Personalidad"
            user={user}
          />
        </Flex>
      </Card.Body>
    </Card>
  );
};

Personality.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  user: PropTypes.object
};

export default Personality;
