import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Collapse, Form, Modal } from 'react-bootstrap';
import AppContext, {
  CalendarContext,
  ChatContext,
  UserContext
} from 'context/Context';
import DatePicker from 'react-datepicker';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import UserList from 'components/common/UserList';
import GoogleMap from 'components/map/GoogleMap';

const NewEventModal = ({
  setIsOpenScheduleModal,
  isOpenScheduleModal,
  scheduleStartDate,
  setScheduleStartDate,
  scheduleEndDate,
  setScheduleEndDate
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { create } = useContext(CalendarContext);
  const { currentChat } = useContext(ChatContext) || {};
  const { partners = [] } = useContext(UserContext);
  const { participants } = currentChat || {};

  const [placeImages, setPlaceImages] = useState([]);
  const [isMoreOptionsShown, showMoreOptions] = useState(false);
  const [formData, setFormData] = useState({
    start: scheduleStartDate?.toISOString?.() || null,
    end: scheduleEndDate?.toISOString?.() || null,
    className: 'bg-soft-info'
  });

  const handleClose = () => {
    setPlaceImages([]);
    setIsOpenScheduleModal(!isOpenScheduleModal);
  };

  const handleChange = ({ target }) => {
    let name = target.name;
    let value = name === 'allDay' ? target.checked : target.value;
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const handleChangeSelection = users => {
    if (
      (!users?.length && !formData.participants?.length) ||
      users?.length === formData.participants?.length
    ) {
      return;
    }
    handleChange({
      target: {
        name: 'participants',
        value: users.map(({ ref }) => ref)
      }
    });
  };

  const handleChangeImage = value => {
    handleChange({
      target: {
        name: 'placeImageIndex',
        value
      }
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    let { participants, ...data } = formData;
    if (!participants?.length) {
      participants = null;
    }
    create({ ...data, participants, type: 'event' });
    setIsOpenScheduleModal(false);
  };

  const handleSearchLocation = (place = {}) => {
    let {
      formatted_address: address,
      geometry,
      name,
      place_id: placeId,
      website
    } = place;
    const { location } = geometry;
    const latlng = location?.toJSON();

    setFormData({
      ...formData,
      location: name || null,
      address: address || null,
      latlng,
      placeId,
      placeImageIndex: 0,
      website: website || null
    });
  };

  useEffect(() => {
    if (isOpenScheduleModal) {
      const start =
        (scheduleStartDate && new Date(scheduleStartDate).toISOString()) ||
        null;
      const end =
        (scheduleEndDate && new Date(scheduleEndDate).toISOString()) || null;
      setFormData(formData => ({
        ...formData,
        start,
        end
      }));
    }
  }, [isOpenScheduleModal, scheduleStartDate, scheduleEndDate]);

  useEffect(() => {
    const { placeId } = formData;
    if (!placeId || !window.google?.maps?.places) {
      return;
    }
    const placeService = new window.google.maps.places.PlacesService(
      document.createElement('div')
    );
    placeService.getDetails({ placeId }, (place, status) => {
      if (status === 'OK') {
        const { photos } = place;
        const placeImages = photos.map(photo => photo?.getUrl());
        setPlaceImages(placeImages);
      }
    });
  }, [formData?.placeId, window.google?.maps?.places]);

  return (
    <Modal
      show={isOpenScheduleModal}
      onHide={handleClose}
      contentClassName="border"
      onExited={() => {
        setScheduleStartDate(null);
        setScheduleEndDate(null);
      }}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-x1 border-bottom-0"
        >
          <Modal.Title as="h5">Nuevo evento</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          <Form.Group className="mb-3" controlId="titleInput">
            <Form.Label className="fs-0">Título</Form.Label>
            <Form.Control
              type="text"
              name="title"
              required
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="startDate">
            <Form.Label className="fs-0">Inicio</Form.Label>
            <DatePicker
              selected={formData?.start && new Date(formData?.start)}
              onChange={date => {
                setScheduleStartDate(date);
                setFormData({ ...formData, start: date?.toISOString() });
              }}
              className="form-control"
              placeholderText="DD-MM-YYYY H:M"
              dateFormat="dd-MM-yyyy h:mm aa"
              showTimeSelect
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="endDate">
            <Form.Label className="fs-0">Fin</Form.Label>
            <DatePicker
              selected={formData?.end && new Date(formData?.end)}
              onChange={date => {
                setScheduleEndDate(date);
                setFormData({ ...formData, end: date?.toISOString() });
              }}
              className="form-control"
              placeholderText="DD-MM-YYYY H:M"
              dateFormat="dd-MM-yyyy h:mm aa"
              showTimeSelect
            />
          </Form.Group>

          <Form.Group controlId="allDay">
            <Form.Check
              type="checkbox"
              id="allDay"
              label="Todo el día"
              name="allDay"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="description">
            <Form.Label className="fs-0">Descripción</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="fs-0">Localización</Form.Label>
            <GoogleMap
              initialCenter={
                formData?.latlng || {
                  lat: 38.83631704135949,
                  lng: -0.6023632417617178
                }
              }
              className="d-flex flex-column rounded-soft position-relative"
              mapClassName="d-none"
              onSearch={handleSearchLocation}
            />
          </Form.Group>

          {!!placeImages?.length && (
            <Form.Group>
              <Form.Label className="fs-0">
                Selecciona la imagen del evento
              </Form.Label>
              <Flex alignItems="center" wrap="nowrap" className="overflow-auto">
                {placeImages?.map((url, index) => (
                  <Button
                    key={url}
                    variant="link"
                    className="p-0 me-2"
                    onClick={() => handleChangeImage(index)}
                  >
                    <Avatar
                      src={url}
                      size="4xl"
                      rounded="2"
                      className={classNames({
                        'border border-3 border-primary rounded-3':
                          formData?.placeImageIndex === index
                      })}
                    />
                  </Button>
                ))}
              </Flex>
            </Form.Group>
          )}
          <Collapse in={isMoreOptionsShown} className="p-0 mt-3">
            <div>
              <hr className="mt-0" />

              <Form.Group className="mb-3" controlId="label">
                <Form.Label className="fs-0">Categoría</Form.Label>
                <Form.Select name="className" onChange={handleChange}>
                  <option value="bg-soft-info">Sin categoría</option>
                  <option value="bg-soft-dark">Business</option>
                  <option value="bg-soft-danger">Importante</option>
                  <option value="bg-soft-warning">Must Attend</option>
                  <option value="bg-soft-primary">Personal</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="fs-0">Participantes</Form.Label>
                <UserList
                  className="shadow-none"
                  onChangeSelection={handleChangeSelection}
                  users={participants?.map(({ ref }) => {
                    const user =
                      partners.find(
                        partner => partner?.ref?.path === ref?.path
                      ) || {};
                    return user;
                  })}
                  tableProps={{
                    sortable: true,
                    selection: true,
                    selectionColumnWidth: 28,
                    pagination: true,
                    perPage: 10
                  }}
                />
              </Form.Group>
            </div>
          </Collapse>
        </Modal.Body>
        <Modal.Footer className="bg-light px-x1 border-top-0">
          <Button variant="link" onClick={() => showMoreOptions(show => !show)}>
            Más opciones
          </Button>
          <Button variant="primary" type="submit" className="px-4 mx-0">
            Crear evento
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

NewEventModal.propTypes = {
  setIsOpenScheduleModal: PropTypes.func.isRequired,
  isOpenScheduleModal: PropTypes.bool.isRequired,
  scheduleStartDate: PropTypes.instanceOf(Date),
  setScheduleStartDate: PropTypes.func.isRequired,
  scheduleEndDate: PropTypes.instanceOf(Date),
  setScheduleEndDate: PropTypes.func.isRequired
};

export default NewEventModal;
