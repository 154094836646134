import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import { download } from 'helpers/utils';
import { ReportsContext } from 'context/Context';

const DepartmentReportItem = ({ department, report }) => {
  const { label, value } = report;
  const { company: { NO_ID_FIELD: companyId } = {} } = useContext(UserContext);
  const [isLoading, setLoading] = useState(false);

  const createReport = async () => {
    setLoading(true);
    const blob = await (
      await fetch(
        `${process.env.REACT_APP_FIREBASE_URL}/createReport?company=${companyId}&department=${department}&date=${value}`
      )
    ).blob();
    download(blob, `happyfy-${department}-${value}.pdf`);
    setLoading(false);
  };

  return (
    <Card className="border border-200 shadow-none">
      <Card.Body>
        <Flex>
          <div className="file-thumbnail">
            <Avatar
              mediaClass="align-items-center justify-content-center bg-primary fs-0"
              icon="file-pdf"
            />
          </div>
          <div className="ms-3">
            <h6 className="mb-1">{label}</h6>
            <div className="fs--1">
              <span className="fw-medium text-500">Informe mensual</span>
            </div>
            <Button
              className="mt-3"
              size="sm"
              variant="falcon-default"
              disabled={isLoading}
              onClick={!isLoading ? createReport : null}
            >
              {isLoading ? (
                <Flex alignItems="center">
                  <Spinner
                    size="sm"
                    variant="secondary"
                    style={{ width: '1rem', height: '1rem' }}
                  />
                  <span className="ms-2">Generando</span>
                </Flex>
              ) : (
                <span>Descargar</span>
              )}
            </Button>
          </div>
        </Flex>
      </Card.Body>
    </Card>
  );
};

const DepartmentReportList = () => {
  const { reports = [] } = useContext(ReportsContext);
  const { me } = useContext(UserContext);

  return (
    <Card className="mb-3">
      <FalconCardHeader
        title={`Informes del departamento: ${me?.department}`}
      />
      <Card.Body>
        <Row className="g-3">
          {reports?.length ? (
            reports.map(report => (
              <Col xs={12} md={3} key={`Report-${report.value}`}>
                <DepartmentReportItem
                  department={me?.department}
                  report={report}
                />
              </Col>
            ))
          ) : (
            <p>-</p>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

DepartmentReportItem.propTypes = {
  department: PropTypes.string,
  report: PropTypes.object
};

DepartmentReportList.propTypes = {
  report: PropTypes.object
};

export default DepartmentReportList;
