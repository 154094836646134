import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import Item from './Item';
import AddItem from './AddItem';

const Pros = ({ className, color, user }) => {
  const { profile = {} } = user || {};
  const { pros: data = [] } = profile;
  return (
    <Card className={className}>
      <FalconCardHeader title="Pros" light />
      <Card.Body>
        <Flex wrap="wrap">
          {data.map(tag => (
            <Item
              key={`Pros-${tag}`}
              color={color}
              profileKey="pros"
              icon="thumbs-up"
              user={user}
              text={tag}
            />
          ))}
          <AddItem
            color={color}
            profileKey="pros"
            placeholder="Pro"
            user={user}
          />
        </Flex>
      </Card.Body>
    </Card>
  );
};

Pros.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  user: PropTypes.object
};

export default Pros;
