import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Holidays from 'components/company/profile/Holidays';
import LocationSelect from 'components/company/profile/LocationSelect';
import AppContext from 'context/Context';

const HolidaysModal = ({ setIsOpen, isOpen }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      contentClassName="border"
      size="lg"
    >
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className="bg-light px-x1 pb-0 border-bottom-0"
      >
        <Modal.Title>Días festivos</Modal.Title>
        <LocationSelect className="w-0 ms-2 flex-grow-1 fs-2 border-0 bg-transparent shadow-none text-end" />
      </Modal.Header>
      <Holidays className="shadow-none" showHeader={false} />
    </Modal>
  );
};

HolidaysModal.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default HolidaysModal;
