import React, { useRef } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import useScrollSpy from 'react-use-scrollspy';

const Terms = () => {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  return (
    <>
      <PageHeader
        title="Términos"
        description="Términos y condiciones de uso del servicio"
        className="mb-3"
      />
      <Row className="g-0">
        <Col lg={8} className="pe-lg-2">
          <Card className="mb-3">
            <Card.Body>
              <h6
                id="intro"
                className="text-primary text-uppercase"
                ref={sectionRefs[0]}
              >
                INTRODUCCIÓN
              </h6>
              <p>
                Nos tomamos la privacidad muy en serio. Happyfy se rige por la
                normativa de privacidad Europea, lee a continuación los
                detalles.
              </p>
              <p>
                HAPPYFY SOFTWARE, S.L. empresa española con domicilio en Carrer
                La Trama, 1, 46870 Ontinyent, Valencia, España, y NIF
                B-72769904, Inscrita en el Registro Mercantil de Valencia, en el
                Tomo 11264, Folio 201, Hoja V-207454, Inscripción 1ª, ha
                desarrollado una aplicación para medir indicadores de clima
                laboral, automatizar los procesos, tareas administrativas y
                obligaciones legales en materia de Recursos Humanos en las
                empresas.
              </p>
              <p>
                Los presentes Términos y Condiciones de Uso regulan las normas a
                las que se somete el uso de acceso y utilización de la
                plataforma Happyfy, accesible a través del dominio web
                happyfy.app (en adelante, "la Plataforma"). El acceso a la
                Plataforma atribuye la condición de usuario a quien lo utiliza
                (en adelante, el "Usuario") e implica la aceptación de todas las
                condiciones incluidas en este documento y en la Política de
                Privacidad de Happyfy.
              </p>
              <hr className="my-4" />
              <h6
                id="service"
                className="text-primary text-uppercase"
                ref={sectionRefs[1]}
              >
                Descripción de los Servicios de Happyfy
              </h6>
              <p>
                Happyfy es una plataforma de gestión de RRHH en la nube que
                utilizan las organizaciones en su calidad de empleadoras y/o en
                calidad de gestoría o asesoría de las organizaciones empleadoras
                (en adelante, "Clientes") para medir, controlar y mejorar los
                indicadores del clima laboral. Además optimizar sus procesos de
                RRHH centralizando y digitalizando las tareas administrativas
                relacionadas con sus empleados así como dando cumplimiento a las
                obligaciones legales en esta materia. Sujeto a, y condicionado
                al cumplimiento continuo y total por parte del Cliente de todos
                los términos y condiciones, Happyfy concede al Cliente y a sus
                Usuarios, durante el periodo de vigencia, una licencia y un
                derecho revocable, intransferible, no exclusivo y limitado para
                acceder y utilizar los Servicios y la Plataforma únicamente para
                sus fines internos.
              </p>
              <hr className="my-4" />
              <h6
                id="use"
                className="text-primary text-uppercase"
                ref={sectionRefs[2]}
              >
                Uso
              </h6>
              <p>
                El Usuario deberá utilizar la Plataforma de buena fe y de
                acuerdo con la legislación vigente y los presentes Términos y
                Condiciones. Asimismo, el Usuario deberá respetar los derechos
                de propiedad intelectual e industrial titularidad de Happyfy.
              </p>
              <p>
                Queda estrictamente prohibido el uso de cualquiera de los
                contenidos de la Plataforma con fines que sean o puedan ser
                ilícitos, así como la realización de cualquier acción que cause
                o pueda causar daños o alteraciones de cualquier tipo no
                consentidas por Happyfy a la Plataforma, a sus contenidos o a
                otros Usuarios. En particular (pero sin limitarse a ello) el
                Usuario no podrá:
              </p>
              <p>
                Recuperar sistemáticamente datos u otros contenidos de la
                Plataforma (incluida la recopilación de nombres de usuario y/o
                direcciones de correo electrónico de los Usuarios por medios
                electrónicos o de otro tipo) para crear o recopilar, directa o
                indirectamente, una colección, compilación, base de datos o
                directorio sin el permiso por escrito de Happyfy.
              </p>
              <p>
                Eludir, desactivar o interferir de otro modo con las funciones
                de seguridad de la Plataforma, ni interferir, interrumpir o
                crear una carga indebida en la Plataforma o en los servicios
                conectados a la Plataforma.
              </p>
              <p>
                Engañar, defraudar o engañar a Happyfy o a otros Usuarios,
                especialmente en cualquier intento de conocer información
                sensible de la cuenta, como las contraseñas de los Usuarios, o
                hacerse pasar por otro Usuario o persona o utilizar el nombre de
                usuario de otro Usuario.
              </p>
              <p>
                Hacer un uso inadecuado de nuestros servicios de asistencia o
                presentar informes falsos de abuso o mala conducta.
              </p>
              <p>
                Vender, compartir o transferir de otro modo su perfil o sus
                credenciales.
              </p>
              <p>
                Utilizar cualquier información obtenida de la Plataforma para
                molestar, intimidar, amenazar con acosar, abusar o dañar a otra
                persona, incluidos otros Usuarios y nuestros empleados.
              </p>
              <p>
                Cargar o transmitir (o intentar cargar o transmitir) virus,
                troyanos u otro material que interfiera o pueda interferir en el
                uso y disfrute ininterrumpido de la Plataforma por parte de
                cualquier parte o que modifique, perjudique, interrumpa, altere
                o interfiera en el uso, las características, las funciones, el
                funcionamiento o el mantenimiento de la Plataforma
              </p>
              <hr className="my-4" />
              <h6
                id="access"
                className="text-primary text-uppercase"
                ref={sectionRefs[3]}
              >
                Acceso y seguridad
              </h6>
              <p>
                Tenga en cuenta que, si decide acceder a la Plataforma de
                Happyfy a través de la autenticación y verificación de la cuenta
                de Google, los propios términos y condiciones de Google Ireland
                Ltd. también podrían resultar de aplicación.{' '}
              </p>
              <p>
                Independientemente del método de autenticación utilizado, el
                Usuario deberá mantener la confidencialidad del nombre de
                usuario y la contraseña de su cuenta y no permitirá que ninguna
                persona utilice su nombre de usuario, contraseña o cualquier
                otra forma de autorización. El Usuario debe asegurarse de salir
                de la Plataforma después de terminar su uso. El Usuario es el
                único responsable de toda la actividad que emplee su cuenta, lo
                que incluye cualquier uso indebido del nombre de usuario y de la
                contraseña y cualquier daño causado a causa de ese uso indebido.
                Happyfy puede confiar en que el suministro de su nombre de
                usuario y contraseña le identifica y autentifica como Usuario.
                Happyfy no será responsable de los daños, costes, gastos u
                honorarios que se deriven de la revelación de su nombre de
                usuario, contraseña u otra información de la cuenta a otra
                persona.
              </p>
              <p>
                Si la seguridad o confidencialidad de su nombre de usuario,
                contraseña o cualquier otra forma de autorización se ve
                comprometida o si cree que su cuenta ha sido objeto de un uso no
                autorizado, deberá notificarlo inmediatamente a
                accounts@happyfy.app.
              </p>
              <hr className="my-4" />
              <h6
                id="price"
                className="text-primary text-uppercase"
                ref={sectionRefs[4]}
              >
                Precio
              </h6>
              <p>
                Happyfy dispone de un Plan de Precios. Si desea obtener un
                presupuesto a medida puede contactar con Happyfy. Los precios
                que se indican respecto a cada producto en su correspondiente
                ficha no incluyen impuestos directos aplicables y, en todo caso,
                se expresan por defecto en la moneda local. Dichos precios,
                salvo que se indique expresamente lo contrario, no incluyen los
                gastos de cualesquiera otros servicios adicionales (entendidos
                como servicios Premium) y anexos al servicio adquirido.
              </p>
              <p>Las modalidades de pago disponibles son las siguientes:</p>-
              Tarjeta bancaria de débito o de crédito. - Recibo domiciliado a
              través del servicio SEPA. - Cualquier otro método de pago que
              nuestro partner Stripe ofrezca en cada región.
              <p>
                Intereses. - Happyfy se reserva el derecho a incrementar
                automáticamente cualquier saldo pendiente de cualquier factura
                después de treinta (30) días de la fecha de la factura con el
                mayor de (a) el tipo de interés legal aplicable más siete (7)
                puntos, o el tipo de interés aplicado por el Banco Central
                Europeo en su última operación de refinanciación más diez (10)
                puntos. Sin perjuicio de lo anterior, en caso de que el Cliente
                no pague las facturas adeudadas en un plazo de treinta (30)
                días, Happyfy podrá además, a su entera discreción, suspender el
                acceso del Cliente a los Servicios y/o rescindir la relación
                total o parcialmente, sin responsabilidad alguna y sin perjuicio
                de su derecho a reclamar todas las cantidades adeudadas por el
                Cliente a Happyfy, según sea el caso.
              </p>
              <p>
                Prueba gratuita. - El Cliente puede optar por probar los
                Servicios durante un período de hasta 15 días naturales a partir
                del momento de la habilitación del mismo y hasta lo que ocurra
                primero: (a) el final del período de prueba gratuito para el que
                se registró, o (b) la fecha de inicio de las suscripción de pago
                al Servicio según el Plan de Precios (en adelante, la "Demo
                Gratuita"). Al final del periodo de Demo Gratuita, el Cliente
                podrá adquirir productos y servicios adicionales no incluidos en
                la Prueba Gratuita seleccionando un Plan de Precios. Por favor,
                tenga en cuenta que cualquier dato que se introduzca en los
                Servicios durante la Demo Gratuita, así como cualquier
                personalización realizada durante dicho periodo se perderá
                permanentemente a menos que (a) se adquiera una suscripción de
                pago a los Servicios, o (b) se exporten dichos datos antes de
                que finalice la Demo Gratuita. En cualquier caso, Happyfy no se
                hace responsable de la pérdida de cualquier información como
                resultado de la falta de exportación antes de la finalización de
                la Demo Gratuita.
              </p>
              <hr className="my-4" />
              <h6
                id="rights"
                className="text-primary text-uppercase"
                ref={sectionRefs[5]}
              >
                Derechos de propiedad industrial e intelectual
              </h6>
              <p>
                Happyfy ha desarrollado y es el pleno y exclusivo propietario y
                titular de todos los derechos de Propiedad Industrial e
                Intelectual sobre la Plataforma, así como de sus
                actualizaciones, mejoras, revisiones, ampliaciones,
                modificaciones, adaptaciones, rectificaciones, traducciones o
                nuevas versiones.
              </p>
              <p>
                El Usuario se compromete a respetar y mantener los derechos de
                Propiedad Industrial e Intelectual de la Plataforma, así como la
                documentación e información que Happyfy ponga a su disposición
                en cumplimiento de lo dispuesto en las presentes condiciones.
              </p>
              <p>
                Los Usuarios cooperarán con Happyfy en la protección de dichos
                derechos de Propiedad Industrial e Intelectual de la Plataforma
                , o de cualesquiera otros derechos de Propiedad Industrial e
                Intelectual e informarán a Happyfy de cualquier infracción de
                dichos derechos de la que el Usuario tenga conocimiento directo
                o indirecto.
              </p>
              <p>
                El Usuario reconoce que la reproducción, modificación,
                distribución, comercialización, descompilación, desensamblaje,
                utilización de técnicas de ingeniería inversa o cualquier otro
                medio para obtener el código fuente, transformación o
                publicación de cualquier resultado de pruebas de referencia no
                autorizadas de cualquiera de los elementos y utilidades
                integradas dentro del desarrollo constituye una infracción de
                los derechos de propiedad intelectual de Happyfy y, en
                consecuencia, se compromete a no realizar ninguna de las
                acciones mencionadas.
              </p>
              <p>
                Happyfy podrá solicitar o recopilar y/o el Cliente podrá
                proporcionar, de vez en cuando, sugerencias, opiniones o
                comentarios por escrito como parte del uso de los Servicios por
                parte del Cliente y los Usuarios (en adelante, las "Opiniones").
                El Cliente reconoce y acepta que dichas Opiniones se
                considerarán propiedad de Happyfy y esta será la propietarias
                exclusivos de todos los derechos, conocidos o futuros, de las
                Opiniones globalmente y a perpetuidad, y tendrán derecho a
                utilizar las Opiniones para cualquier propósito, comercial o de
                otro tipo, sin compensación para el proveedor de las Opiniones.
                Asimismo, Happyfy se reserva el derecho a incluir el nombre y
                logotipo estándar del Cliente en sus listas públicas de
                clientes, comunicados de prensa y similares.
              </p>
              <hr className="my-4" />
              <h6
                id="confidenciality"
                className="text-primary text-uppercase"
                ref={sectionRefs[6]}
              >
                Protección de datos y Confidencialidad
              </h6>
              <p>
                Al aceptar los presentes términos y condiciones, las Partes
                también suscriben el Contrato de Encargo de Tratamiento
                disponible aquí (en adelante, el “Contrato de Encargo”), el cual
                regula las obligaciones en materia de protección de datos
                personales que tenemos con nuestros clientes y que cumplen con
                las obligaciones legales en dicha materia. El Contrato de
                Encargo forma parte inseparable de los presentes términos y
                condiciones y regula el tratamiento de datos personales del
                Cliente (en adelante, los “Datos Personales del Cliente”) por
                parte de Happyfy, como Encargado de tratamiento, en nombre del
                Cliente, como Responsable de tratamiento. Happyfy se toma en
                serio la privacidad de sus Clientes, por lo que se compromete a
                utilizar la información proporcionada por el Cliente de acuerdo
                con los términos contenidos dicho Contrato de Encargo (cuando
                actúa encargado de tratamiento) y con la Política de Privacidad,
                tal como pueda ser modificada por Happyfy de vez en cuando
                (cuando actúa como responsable del tratamiento).
              </p>
              <p>
                Las Partes se comprometen a mantener reservada y confidencial la
                existencia y el contenido de toda la documentación e información
                que se facilite, transmita o divulgue mediante la firma del
                presente Acuerdo con independencia del método, forma o soporte
                utilizado (en adelante, "Información Confidencial"),
                comprometiéndose a no realizar ninguna divulgación a terceros o
                comunicación pública sin la previa autorización por escrito de
                la otra parte.
              </p>
              <p>
                De forma enunciativa pero no limitativa, se entenderá como
                Información Confidencial la información referida a datos de
                clientes, su existencia, su estructura, planes de promoción y
                venta, códigos fuente y objeto de programas informáticos,
                sistemas, técnicas, inventos, procesos, patentes, marcas,
                diseños registrados, derechos de autor, know-how, nombres
                comerciales, datos técnicos y no técnicos, dibujos, bocetos,
                datos financieros, planes relativos a nuevos productos, datos
                relativos a clientes o potenciales clientes así como cualquier
                otra información utilizada en el ámbito empresarial de Happyfy.
              </p>
              <p>
                La obligación de confidencialidad subsistirá incluso después de
                la resolución, por cualquier causa, de la relación contractual
                entre las partes sin que se genere ningún tipo de indemnización.
              </p>
              <p>
                El incumplimiento de la obligación de confidencialidad asumida
                en este acuerdo o la devolución de la Información Confidencial
                establecida anteriormente dará derecho a cualquiera de las
                Partes a reclamar el importe íntegro de los daños y perjuicios
                que dicho incumplimiento hubiera generado.
              </p>
              <hr className="my-4" />
              <h6
                id="age"
                className="text-primary text-uppercase"
                ref={sectionRefs[7]}
              >
                Edad
              </h6>
              <p>
                Para utilizar la Plataforma, debe tener al menos 18 años o más
                (o contar con el consentimiento de sus padres o tutores).
              </p>
              <hr className="my-4" />
              <h6
                id="responsibility"
                className="text-primary text-uppercase"
                ref={sectionRefs[8]}
              >
                Responsabilidades
              </h6>
              <p>
                Happyfy proporciona la Plataforma "tal cual" y "según
                disponibilidad" sin ninguna promesa o garantía, expresa o
                implícita. En particular, Happyfy no garantiza ni hace ninguna
                representación con respecto a la validez, exactitud, fiabilidad
                o disponibilidad de la Plataforma o su contenido. En la medida
                en que lo permita la legislación aplicable, Happyfy excluye
                todas las promesas, ya sean expresas o implícitas, incluidas las
                promesas de que la Plataforma es adecuada para su propósito, de
                calidad satisfactoria, no infractora, libre de defectos, capaz
                de funcionar de forma ininterrumpida, que el uso de la
                Plataforma por parte del Usuario cumple con las leyes o que
                cualquier información que usted transmita en relación con la
                Plataforma será transmitida con éxito, precisión o seguridad.
              </p>
              <p>
                No obstante, lo anterior, Happyfy declara que ha adoptado las
                medidas necesarias que, dentro de sus posibilidades y del estado
                de la tecnología, permiten el correcto funcionamiento de la
                Plataforma, así como la ausencia de componentes dañinos.
              </p>
              <p>
                Cuando la Plataforma o cualquiera de sus servicios accede a
                contenidos a través de Internet, nuestro objetivo es garantizar
                que estos estén disponibles las 24 horas del día; sin embargo,
                Happyfy no será responsable si no están disponibles en algún
                momento por cualquier motivo. El acceso a los contenidos en
                línea puede ser suspendido temporalmente o eliminado por
                completo sin previo aviso (por ejemplo, si hay un fallo del
                sistema, o por mantenimiento o reparación).
              </p>
              <p>
                El Cliente se compromete a indemnizar, defender y mantener
                indemne a Happyfy por, todas las pérdidas, responsabilidades,
                daños, reclamaciones (incluyendo posibles honorarios legales)
                incurridos, que surjan de o en relación con: (i) cualquier
                incumplimiento o presunto incumplimiento de los presentes
                Términos y Condiciones por parte del Cliente o sus Usuarios;
                (ii) la violación por parte del Cliente o sus Usuarios de
                cualquier Ley y/o de los derechos de un tercero que afecte a
                Happyfy; (iii) el incumplimiento por parte del Cliente o sus
                Usuarios de la instalación puntual de cualquier actualización,
                mejora o parche de cualquier software proporcionado por Happyfy
                y que no cuente con la autorización de este; y (iv) las
                reclamaciones relacionadas con los Datos del Cliente y/o las
                reclamaciones relacionadas con cualquier dato transferido por el
                Cliente a aplicaciones de terceros, y que esto suponga un daño
                directo a Happyfy o para ellos.
              </p>
              <p>
                Las disposiciones de esta Sección sobrevivirán a la terminación
                de este Acuerdo.
              </p>
              <hr className="my-4" />
              <h6
                id="links"
                className="text-primary text-uppercase"
                ref={sectionRefs[9]}
              >
                Enlaces y recursos
              </h6>
              <p>
                Cuando el Sitio Web de Happyfy contiene enlaces a otros sitios y
                recursos proporcionados por terceros, estos enlaces se
                proporcionan únicamente para su información. Happyfy no tiene
                ningún control sobre el contenido de esos sitios o recursos, por
                lo que estos Términos y Condiciones y nuestra Política de
                Privacidad se aplican únicamente al uso de nuestros servicios.
                Cuando utilices productos o servicios de terceros, sus propios
                términos y condiciones y políticas de privacidad regirán el uso
                que hagas de esos productos o servicios. Por favor, lea dichos
                términos y condiciones de uso y políticas de privacidad para
                saber cómo recogen y tratan sus datos personales y otras
                informaciones relevantes.
              </p>
              <hr className="my-4" />
              <h6
                id="compensation"
                className="text-primary text-uppercase"
                ref={sectionRefs[10]}
              >
                Indemnización. Limitación de responsabilidad.
              </h6>
              <p>
                El Cliente acepta indemnizar y eximir de responsabilidad a
                Happyfy por cualquier reclamación, juicio, pérdida,
                responsabilidad, costas y gastos, incluidos los honorarios de
                abogados, que surjan directa o indirectamente de o en relación
                con cualquier violación de las disposiciones de los presentes
                Términos y Condiciones por el Cliente. Esta obligación de
                indemnización no se aplicará en supuestos en los que exista un
                comportamiento previo doloso o gravemente culposo de Happyfy.
              </p>
              <p>
                Happyfy no se hace responsable de los posibles errores de
                seguridad que se puedan producir ni de los posibles daños que
                puedan causarse al sistema informático del Cliente (hardware y
                software), los ficheros o documentos almacenados en el mismo,
                como consecuencia de la presencia de virus en el equipo del
                Cliente utilizado para la conexión y/o uso de la Plataforma, de
                un mal funcionamiento del navegador o del uso de versiones no
                actualizadas del mismo.
              </p>
              <p>
                El Cliente es consciente de que será responsabilidad exclusiva
                del Cliente, entre otros aspectos, asegurar la adecuación del
                uso de los productos a la normativa aplicable y el cumplimiento
                de cualquier regulación que sea de aplicación.En este sentido, y
                modo de ejemplo, el cliente reconoce que la funcionalidad de
                Gestión de Gastos no cumple con la Directiva 2014/55/UE del
                Parlamento Europeo y del Consejo, de 16 de abril de 2014,
                relativa a la facturación electrónica en la contratación
                pública, por lo que no permite convertir los tickets y/o
                comprobantes de gastos en una imagen digital codificada conforme
                a uno de los formatos estándares admitidos por la Directiva. Por
                ello, el Cliente estará obligado a mantener en soporte papel los
                comprobantes de gastos.
              </p>
              <p>
                CON SUJECIÓN A LA CLÁUSULA 8, Y EN LA MEDIDA EN QUE LO PERMITA
                LA LEGISLACIÓN APLICABLE, HAPPYFY NO SERÁ RESPONSABLE FRENTE AL
                CLIENTE, YA SEA POR RESPONSABILIDAD CONTRACTUAL,
                EXTRACONTRACTUAL O POR CUALQUIER OTRA TEORÍA DE RESPONSABILIDAD,
                POR:
              </p>
              <p>
                CUALESQUIERA DAÑOS INDIRECTOS, INCIDENTALES, CONSECUENCIALES,
                REPUTACIONALES, PUNITIVOS O ESPECIALES;
              </p>
              <p>
                CUALQUIER PÉRDIDA (DIRECTA O INDIRECTA) DE INGRESOS, LUCRO
                CESANTE, BENEFICIOS REALES O PREVISTOS, OPORTUNIDAD DE NEGOCIO,
                FONDO DE COMERCIO O REPUTACIÓN;
              </p>
              <p>
                CUALQUIER PÉRDIDA Y/O CORRUPCIÓN DE DATOS (YA SEA DIRECTA O
                INDIRECTA); Y/O
              </p>
              <p>
                CUALQUIER PÉRDIDA O DAÑO COMO RESULTADO DE MODIFICACIONES Y/O
                LIMITACIONES (INCLUIDA CUALQUIER SUSPENSIÓN DEL USO DE LA
                PLATAFORMA O CUALQUIER CESE PERMANENTE O TEMPORAL DEL USO DE LA
                PLATAFORMA) POR CAUSAS QUE RESULTEN IMPREVISIBLES Y/O ESCAPEN AL
                CONTROL DE HAPPYFY, COMO SUPUESTOS DE CASO FORTUITO Y FUERZA
                MAYOR.
              </p>
              <p>
                EL CLIENTE SERÁ RESPONSABLE DE COMPENSAR A HAPPYFY POR CUALQUIER
                DAÑO Y PERJUICIO Y/O PÉRDIDA QUE HAYA SUFRIDO HAPPYFY DEBIDO AL
                USO DE LA PLATAFORMA POR OTRA PERSONA DISTINTA AL CLIENTE (O LOS
                EMPLEADOS DEL CLIENTE).
              </p>
              <p>
                EN EL CASO DE QUE HAPPYFY SEA RESPONSABLE DE UN INCUMPLIMIENTO
                DE LOS PRESENTES TÉRMINOS Y CONDICIONES, LA RESPONSABILIDAD
                MÁXIMA TOTAL DE HAPPYFY NO PODRÁ EXCEDER UN IMPORTE MÁXIMO IGUAL
                AL VALOR TOTAL DE LAS CANTIDADES ABONADAS POR EL CLIENTE A
                HAPPYFY EN LOS DOCE (12) MESES ANTERIORES AL SUCESO PERJUDICIAL
                (O SI HAN TRANSCURRIDO MENOS DE DOCE (12) MESES DESDE LA ENTRADA
                EN VIGOR DE ESTOS TÉRMINOS Y CONDICIONES, DOCE (12) VECES EL
                ÚLTIMO PAGO MENSUAL EFECTUADO POR EL CLIENTE A HAPPYFY POR EL
                USO DE LA PLATAFORMA). ESTA CANTIDAD, CONFORME AL ARTÍCULO 1.152
                DEL CÓDIGO CIVIL, SUSTITUYE CON EL CONSENTIMIENTO EXPRESO DEL
                CLIENTE A CUALQUIER OTRA INDEMNIZACIÓN POR DAÑOS Y PERJUICIOS.
                SERÁ RESPONSABILIDAD DEL CLIENTE ADOPTAR LAS PÓLIZAS DE SEGURO
                ADECUADAS PARA CUBRIR LOS DAÑOS SUFRIDOS QUE PUEDAN SUPERAR EL
                LÍMITE MÁXIMO DE RESPONSABILIDAD MENCIONADO.
              </p>
              <p>
                Happyfy no será responsable frente al Cliente de ningún daño o
                perjuicio en los términos de la presente cláusula que no le haya
                sido notificado por el Cliente a Happyfy por escrito en un plazo
                máximo de 25 días naturales a partir de la fecha en la que el
                Cliente tuvo conocimiento del daño o perjuicio.
              </p>
              <p>
                Las previsiones de esta Cláusula 10 deben entenderse sin
                perjuicio de los casos en los que la responsabilidad no pueda
                excluirse o limitarse conforme a la normativa imperativa
                aplicable.
              </p>
              <hr className="my-4" />
              <h6
                id="integrations"
                className="text-primary text-uppercase"
                ref={sectionRefs[11]}
              >
                Integraciones
              </h6>
              <p>
                La Plataforma de Happyfy facilita intercambio de datos con
                sistemas de terceras partes (en adelante, “Integraciones”).
                Todas esas integraciones se proveen bajo la responsabilidad
                exclusiva de dichas terceras partes o proveedores externos. El
                alcance del servicio prestado por estos y las instrucciones
                necesarias para configurar la integración se proporcionará en la
                Plataforma, así como en el sitio web del proveedor externo.
              </p>
              <p>
                Las integraciones de terceros no constituyen servicios prestados
                por Happyfy. El alcance del servicio, los precios, el plazo y
                cualquier otro término de uso para la provisión de la
                integración, incluido el soporte, se basa en los términos y
                condiciones de uso y políticas de privacidad que regulan la
                relación contractual entre el cliente y el proveedor. Happyfy no
                asume ninguna responsabilidad ni garantía por las integraciones
                de terceros.
              </p>
              <p>
                Por favor, lea dichos términos y condiciones de uso y políticas
                de privacidad para saber cómo recogen y tratan sus datos
                personales y otras informaciones relevantes.
              </p>
              <hr className="my-4" />
              <h6
                id="digitalization"
                className="text-primary text-uppercase"
                ref={sectionRefs[12]}
              >
                Uso de la funcionalidad de digitalización de facturas
                simplificadas
              </h6>
              <p>
                En el caso de que el Cliente haga uso de la funcionalidad de
                digitalización certificada para poder digitalizar facturas
                simplificadas y facturas y documentos asociados, de acuerdo con
                la Resolución 24 de octubre de 2007, de la Agencia Estatal de
                Administración Tributaria, sobre procedimiento para la
                homologación de software de digitalización contemplado en la
                Orden EHA/962/2007, de 10 de abril de 2007.
              </p>
              <p>
                Dicha funcionalidad tiene como objetivo garantizar la correcta
                calidad de la imagen de las facturas obtenidas y de sus
                metadatos, independientemente del momento de tiempo en que se
                haga uso del software de digitalización.
              </p>
              <p>
                En relación con dicha funcionalidad, Happyfy ha redactado las
                siguientes especificaciones técnicas del sistema de facturación
                digital en cuanto a seguridad que el Cliente ha de respetar y
                cumplir:
              </p>
              <p>
                Autenticación: Todos los servicios web requieren de
                autenticación para el acceso a toda la funcionalidad que permite
                la modificación, así como la visualización de los datos de
                carácter mercantil. En caso de que dicha autenticación no se
                produzca, no se podrán acceder a los datos. Para evitar la
                interceptación o manipulación de la información intercambiada,
                la instalación deberá asegurar que la información intercambiada
                esté cifrada. Esto se consigue exponiendo los servicios web
                mediante protocolo HTTPS.
              </p>
              <p>
                Medidas de seguridad: La firma digital del archivo PDF se
                realiza mediante una librería externo de código ruby, llamada
                HEXA PDF: Medidas de seguridad: La firma digital del archivo PDF
                se realiza mediante una librería externo de código ruby, llamada
                HEXA PDF:
                https://hexapdf.gettalong.org/documentation/howtos/signing-pdfs.html.
                En el enlace se puede consultar cómo funciona así como también
                ver su código fuente original. La web está protegida por un
                certificado SSL que asegura transmisión de datos segura. La
                aplicación móvil se puede instalar desde las tiendas de
                aplicaciones de Android o iOS. Cuando una factura pertenece a un
                cierre, esta no se permite modificar ni borrar, es una
                limitación a nivel de Interfaz... En el enlace se puede
                consultar cómo funciona así como también ver su código fuente
                original. La web está protegida por un certificado SSL que
                asegura transmisión de datos segura. La aplicación móvil se
                puede instalar desde las tiendas de aplicaciones de Android o
                iOS. Cuando una factura pertenece a un cierre, esta no se
                permite modificar ni borrar, es una limitación a nivel de
                Interfaz.
              </p>
              <p>
                Usuario de producción: El usuario de producción debe tener las
                siguientes capacidades y limitaciones:
              </p>
              <p>
                Podrá generar las imágenes a través del módulo de digitalización
              </p>
              <p>
                Podrá realizar búsquedas de facturas en el módulo de búsquedas y
                liquidación de periodos contables
              </p>
              <p>
                Podrá realizar los cierres de periodos contables a través del
                módulo de liquidación de periodos contables
              </p>
              <p>
                No podrá modificar ni eliminar las imágenes generadas por el
                módulo de escaneo
              </p>
              <p>
                Happyfy tendrá un usuario especial Administrador. Solamente este
                usuario tendrá acceso completo con las restricciones antes
                comentadas, con una clave robusta de acuerdo con los estándares
                del cliente. Dicha clave será acordada con el responsable de
                Seguridad designado, que será quien garantice que no se
                distribuye internamente, cumpliendo una serie de requerimientos
                básicos en la política de contraseñas, como son:
              </p>
              <p>Longitud: de al menos 8 caracteres. </p>
              <p>Complejidad: caracteres alfanuméricos y símbolos. </p>
              <p>
                Happyfy realiza una configuración inicial de seguridad,
                cumpliendo con las guías de buenas prácticas, donde se activa la
                política de auditoría sobre accesos fallidos al sistema y se
                realiza una asignación de contraseñas robustas de
                administración.
              </p>
              <p>
                Happyfy realizará auditorías internas y revisiones periódicas de
                los usuarios para asegurar que se continúan observando estas
                directivas de seguridad después de completado el proyecto y
                durante la vida en producción del sistema. Será el responsable
                de sistemas designado el encargado de realizar estas auditorías.
                Estas revisiones son especialmente críticas en aquellos entornos
                en los que no haya definida una política corporativa de acceso a
                los sistemas con sus controles necesarios.
              </p>
              <p>
                Seguridad de la base de datos documental: La contraseña para
                acceder a la base de datos debe cumplir las directivas de
                complejidad de contraseñas, se comprueban los requisitos de
                longitud mínima, complejidad, historial y caducidad de la
                contraseña. Las directivas de complejidad de contraseñas están
                diseñadas para impedir ataques por fuerza bruta mediante el
                aumento del número de contraseñas posibles. Happyfy realizar
                auditorías internas y revisiones periódicas de los usuarios para
                asegurar que se continúan observando estas directivas de
                seguridad después de completado el proyecto y durante la vida en
                producción del sistema. Será el responsable de sistemas
                designado por HAPPYFY SOFTWARE S.L. el encargado de realizar
                estas auditorías. Todas las peticiones realizadas a través tanto
                de la aplicación móvil como de la aplicación web se realizan con
                autenticación basada en token. El protocolo de comunicación es
                obligatoriamente HTTPS con SSL.
              </p>
              <p>
                Política de Backup:Los backups se realizarán una vez por semana.
                Son copias enteras tanto de las tablas de la base de datos como
                de los PDF signados. Estos archivos de backups se mantendran
                guardados indefinidamente. Los backups son signados con el
                certificado original, siguiendo el siguiente procedimiento:
              </p>
              <p>
                Gestión de incidencias: Si se produce cualquier tipo de
                manipulación en la base de datos documental, el Gestor será
                capaz de informar de esta situación e indicar que los datos han
                sido alterados. Según sea la incidencia se tratará de un aviso
                por pantalla o también de un Log detallado con el registro que
                ha provocado el error. El Gestor documental, será capaz de
                realizar la búsqueda de las facturas a la vez que indicará al
                usuario si los datos o la imagen ha sido alterada de forma
                ilícita, en este caso aparecerá los siguientes mensajes
              </p>
              <p>
                Escaner vía app: El escaneo de Facturas simplificadas y facturas
                se realiza mediante dispositivos de alta resolución recomendados
                con los ajustes de calidad comentados en esta cláusula.
              </p>
              <p>
                Gestión de facturas, visor y cierres de periodo vía web: El
                escaneo de facturas desde el software se conectan con el escáner
                y se lanza el mismo proceso de esta cláusula. Incluyendo las
                funcionalidades de gestión de facturas, visor y cierres.
              </p>
              <p>
                Mantenimiento de dispositivos asociados: Para asegurar la
                calidad y velocidad de la producción, se deben satisfacer los
                requisitos mínimos recomendados en este punto. Hay que tener en
                cuenta que estos requisitos suponen que el software Happyfy
                Digitalización Certificada es el único programa ejecutándose en
                el sistema. Si se están ejecutando otros programas al mismo
                tiempo, los requisitos de cada programa deben añadirse a los de
                Happyfy Digitalización Certificada. Los requisitos mínimos y
                recomendables de los sistemas operativos y dispositivos
                recomendados son los siguientes:
              </p>
              iDocCar Scan: - IOS 12.5 - Android 5.0 Happyfy Invoice
              Certification: - Windows 10 - Navegador Mozilla Firefox v92 o
              superior, Google Chrome v94
              <p>
                Requisitos de los dispositivos de escaneo: Los documentos
                escaneados deben tener los siguientes requisitos mínimos:
              </p>
              <p>Formato de digitalización: PDF.</p>
              <p>Compresión: Sin pérdida.</p>
              <p>Algoritmo de compresión: Sin compresión.</p>
              <p>Admisión de varias páginas: Sí.</p>
              <p>Visualización PDF:</p>
              <p>
                Información de la firma (propietario de la firma digital
                avanzada).
              </p>
              <p>Marca de tiempo con fecha y hora de digitalización.</p>
              <p>Calidad de imagen: 200 PPP (Puntos por pulgada)</p>
              <p>
                Se debe emplear en cada dispositivo que haga uso de la
                aplicación de escritorio un escáner que tenga como funciones
                realizar el procesado de imágenes para una óptima resolución,
                brillo y contraste, recorte y orientación, asegurando los
                parámetros de calidad y los 200 ppp de resolución.
              </p>
              <p>
                Gestión del proyecto: Usamos control de versiones de código con
                GIT. La metodología de desarrollo es ágil, con scrum y
                deployments continuos (CI/CD)..
              </p>
              <p>
                Procedimiento de instalación: El procedimiento de instalación
                consiste en los siguientes pasos:
              </p>
              <p>
                La versión iOS de la aplicación de escaneo se descarga a través
                de la aplicación App Store de iOS.
              </p>
              <p>
                La versión Android de la aplicación de escaneo se descarga a
                través de la aplicación Google Play Store de Android.
              </p>
              <p>
                La aplicación web de gestión no requiere de instalación, al
                alojarse en un servidor web.
              </p>
              <p>
                Vigencia de las normas y algoritmos asociados: Cuando la AEAT
                requiere cambios en la normativa de la facturación electrónica,
                HAPPYFY SOFTWARE S.L. notificará a sus clientes los cambios a
                realizar y el proceso que los clientes deben seguir para
                continuar cumpliendo con la normativa vigente, de esta forma se
                garantiza que los clientes estarán actualizados con los posibles
                cambios. No obstante, el cliente debe velar por el cumplimiento
                de la normativa vigente.
              </p>
              <p>
                En este sentido, el Cliente declara haber leído el Plan de
                Gestión de Calidad para el uso del software Happyfy
                Digitalización Certificada, el cual permite velar por la
                correcta calidad de la imagen obtenida en el proceso de
                digitalización y de sus metadatos independientemente del momento
                de tiempo en el que se haga uso del software de digitalización.
              </p>
              <p>
                Asimismo, el Cliente adquiere el compromiso expreso de llevar a
                cabo el conjunto de operaciones de seguridad y buenas prácticas
                permitan garantizar mediante su cumplimiento que, en todo
                momento, el estado del software de digitalización y sus
                dispositivos asociados producen imágenes fieles e íntegras, de
                acuerdo con la ORDEN EHA/962/2007, de 10 de abril, por la que se
                desarrollan determinadas disposiciones sobre facturación
                telemática y conservación electrónica de facturas, contenidas en
                el Real Decreto 1496/2003, de 28 de noviembre, por el que se
                aprueba el reglamento por el que se regulan las obligaciones de
                facturación; y de acuerdo con la RESOLUCIÓN de 24 de octubre de
                2007, de la Agencia Estatal de Administración Tributaria sobre
                procedimiento para la homologación de software de digitalización
                contemplado en la citada ORDEN EHA/962/2007, de 10 de abril de
                2007
              </p>
              <hr className="my-4" />
              <h6
                id="wallet"
                className="text-primary text-uppercase"
                ref={sectionRefs[13]}
              >
                Monedero o Wallet
              </h6>
              <p>
                Happyfy se reserva el derecho a cancelar los saldos del usuario
                si este decide poner fin a dicho acuerdo. Sin posibilidad de
                reclamación de dichos saldos.{' '}
              </p>
              <hr className="my-4" />
              <h6
                id="benefits"
                className="text-primary text-uppercase"
                ref={sectionRefs[14]}
              >
                Beneficios sociales o cualquier beneficio en especie
              </h6>
              <p>
                Happyfy se reserva el derecho de cancelar los beneficios que los
                usuarios tengan asignados si este decide poner fin a dicho
                acuerdo. Sin posibilidad de reclamación de dichos saldos.{' '}
              </p>
              <hr className="my-4" />
              <h6
                id="update"
                className="text-primary text-uppercase"
                ref={sectionRefs[15]}
              >
                Modificación
              </h6>
              <p>
                Happyfy se reserva el derecho a modificar los presentes términos
                y condiciones para adaptarlos a cualquier cambio o nueva
                normativa, por razones técnicas, por cambios en los servicios
                ofrecidos por Happyfy o por decisiones estratégicas de la
                empresa.
              </p>
              <p>
                Si el Usuario no está de acuerdo con los cambios, deberá dejar
                de utilizar la Plataforma.
              </p>
              <hr className="my-4" />
              <h6
                id="assignment"
                className="text-primary text-uppercase"
                ref={sectionRefs[16]}
              >
                Cesión
              </h6>
              <p>
                Happyfy podrá ceder el contrato a cualquier sociedad de su
                grupo, o del grupo al que pertenece su accionista, en el sentido
                previsto en el artículo 42 del Código de Comercio. El Cliente no
                podrá ceder los derechos y obligaciones asumidas en virtud del
                presente Contrato a ningún tercero sin el consentimiento expreso
                y por escrito de la otra Parte.
              </p>
              <hr className="my-4" />
              <h6
                id="duration"
                className="text-primary text-uppercase"
                ref={sectionRefs[17]}
              >
                Duración del servicio
              </h6>
              <p>
                Los presentes Términos y Condiciones de Uso tienen una duración
                indefinida desde su aceptación salvo que se haya celebrado un
                contrato específico de duración determinada.
              </p>
              <p>
                En caso de querer poner fin a dicho acuerdo, el Cliente deberá
                informar por escrito a Happyfy de su intención de rescindir el
                contrato enviando un email a la dirección info@happyfy.app con
                una antelación mínima de 30 días antes de la finalización del
                contrato, tanto para el Plan anual como para el Plan mensual.
              </p>
              <p>
                La resolución anticipada del contrato no dará derecho al Cliente
                al reembolso de la suma correspondiente a la parte de la
                anualidad o mensualidad no disfrutada.
              </p>
              <hr className="my-4" />
              <h6
                id="contact"
                className="text-primary text-uppercase"
                ref={sectionRefs[18]}
              >
                Contacto
              </h6>
              <p>
                Puede ponerse en contacto con nosotros en la dirección arriba
                indicada. También puede ponerse en contacto con nosotros por
                correo electrónico en accounts@happyfy.app si el ordenador desde
                el que se conecta tiene un programa de correo electrónico
                correctamente configurado y operativo.
              </p>
              <hr className="my-4" />
              <h6
                id="languages"
                className="text-primary text-uppercase"
                ref={sectionRefs[19]}
              >
                Acceder a nuestros términos y condiciones en otros idiomas
              </h6>
              <p>
                Para acceder a nuestros Términos y Condiciones en otros idiomas,
                cambie la configuración del idioma de su navegador. Si nuestras
                condiciones no están disponibles en el idioma que seleccione,
                puede ponerse en contacto con nosotros en la dirección
                accounts@happyfy.app.
              </p>
              <hr className="my-4" />
              <h6
                id="jurisdiction"
                className="text-primary text-uppercase"
                ref={sectionRefs[20]}
              >
                Jurisdicción y legislación aplicable
              </h6>
              <p>
                Estas Condiciones Generales se regirán e interpretarán conforme
                a la legislación española.
              </p>
              <p>
                El Usuario y Happyfy acuerdan someter cualquier controversia
                derivada del uso la Plataforma, o la contratación de productos
                y/o servicios realizada a través de este, a los Juzgados y
                Tribunales de Ontinyent, Valencia, con renuncia expresa a
                cualquier otro fuero que pudiera corresponderles.
              </p>
              <p>Última actualización: 24/05/2023</p>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} className="ps-lg-2">
          <div className="sticky-sidebar">
            <Card className="sticky-top">
              <Card.Header className="border-bottom">
                <h6 className="mb-0 fs-0">Índice</h6>
              </Card.Header>
              <Card.Body>
                <div
                  id="terms-sidebar"
                  className="terms-sidebar nav flex-column fs--1"
                >
                  <Nav.Item>
                    <Nav.Link
                      href="#intro"
                      className="nav-link px-0 py-1"
                      active={activeSection === 0}
                    >
                      Introducción
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#service"
                      className="nav-link px-0 py-1"
                      active={activeSection === 1}
                    >
                      Descripción de los Servicios de Happyfy
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#use"
                      className="nav-link px-0 py-1"
                      active={activeSection === 2}
                    >
                      Uso
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#access"
                      className="nav-link px-0 py-1"
                      active={activeSection === 3}
                    >
                      Acceso y seguridad
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#price"
                      className="nav-link px-0 py-1"
                      active={activeSection === 4}
                    >
                      Precio
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#rights"
                      className="nav-link px-0 py-1"
                      active={activeSection === 5}
                    >
                      Derechos de propiedad industrial e intelectual
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#confidenciality"
                      className="nav-link px-0 py-1"
                      active={activeSection === 6}
                    >
                      Protección de datos y Confidencialidad
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#age"
                      className="nav-link px-0 py-1"
                      active={activeSection === 7}
                    >
                      Edad
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#responsibility"
                      className="nav-link px-0 py-1"
                      active={activeSection === 8}
                    >
                      Responsabilidades
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#links"
                      className="nav-link px-0 py-1"
                      active={activeSection === 9}
                    >
                      Enlaces y recursos
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#compensation"
                      className="nav-link px-0 py-1"
                      active={activeSection === 10}
                    >
                      Indemnización
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#integrations"
                      className="nav-link px-0 py-1"
                      active={activeSection === 11}
                    >
                      Integraciones
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#digitalization"
                      className="nav-link px-0 py-1"
                      active={activeSection === 12}
                    >
                      Facturas simplificadas
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#wallet"
                      className="nav-link px-0 py-1"
                      active={activeSection === 13}
                    >
                      Monedero o Wallet
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#benefits"
                      className="nav-link px-0 py-1"
                      active={activeSection === 14}
                    >
                      Beneficios sociales
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#update"
                      className="nav-link px-0 py-1"
                      active={activeSection === 15}
                    >
                      Modificación
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#update"
                      className="nav-link px-0 py-1"
                      active={activeSection === 16}
                    >
                      Actualización de política
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#assignment"
                      className="nav-link px-0 py-1"
                      active={activeSection === 17}
                    >
                      Cesión
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#duration"
                      className="nav-link px-0 py-1"
                      active={activeSection === 18}
                    >
                      Duración del servicio
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#contact"
                      className="nav-link px-0 py-1"
                      active={activeSection === 19}
                    >
                      Contacto
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#languages"
                      className="nav-link px-0 py-1"
                      active={activeSection === 19}
                    >
                      Otros idiomas
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#jurisdiction"
                      className="nav-link px-0 py-1"
                      active={activeSection === 20}
                    >
                      Jurisdicción y legislación aplicable
                    </Nav.Link>
                  </Nav.Item>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Terms;
