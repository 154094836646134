import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Col, Collapse, Row } from 'react-bootstrap';
import Countdown from 'components/common/Countdown';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import dayjs from 'dayjs';

const TrialBanner = () => {
  const [link, setLink] = useState();
  const [open, setOpen] = useState(false);
  const { company, me, subscription } = useContext(UserContext);
  const { status, trial_end: trialEnd } = subscription || {};
  const endDate = trialEnd * 1000;

  const refreshRedirectionLink = async () => {
    try {
      const redirection = await (
        await fetch(
          `${process.env.REACT_APP_FIREBASE_URL}/redirectToBillingPortal`,
          {
            method: 'POST',
            body: JSON.stringify({
              companyId: company?.NO_ID_FIELD
            })
          }
        )
      ).text();

      setLink(redirection);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    !link && open && status && refreshRedirectionLink();
    setOpen(status === 'trialing');
  }, [link, open, status]);

  return me?.type === 'admin' ? (
    <Collapse in={open}>
      <div className="position-sticky sticky-sidebar z-index-1016">
        <Alert
          show={open}
          className="bg-line-chart-gradient border-0 card rounded-3 text-white"
          dismissible
          closeVariant="white"
          onClose={() => setOpen(false)}
        >
          <Row alignItems="center">
            <Col
              xs={12}
              lg={true}
              className="justify-content-center justify-content-lg-start"
              as={Flex}
            >
              <FontAwesomeIcon
                className="me-3 border border-3 border-white rounded-5 p-2 w-32px h-32px"
                icon="crown"
                size="xl"
              />
              <div>
                <Alert.Heading className="m-0">
                  Tu prueba terminará en{' '}
                  {dayjs(endDate).diff(new Date(), 'days')} días
                </Alert.Heading>
                <p className="mb-0">
                  Cuando tu período de prueba gratuita finalice, este plan ya no
                  continuará hasta que se añada un método de pago.
                </p>
              </div>
            </Col>
            <Col
              xs={12}
              lg="auto"
              className="mt-3 mt-lg-0"
              alignItems="center"
              justifyContent="center"
              as={Flex}
            >
              <Countdown
                className="mx-3"
                date={new Date(endDate)}
                itemClassName="fs--1 text-white"
              />
              <Button
                variant="falcon-default"
                className="mx-lg-3 rounded-pill text-nowrap"
                as={Link}
                to={link}
                target="_blank"
              >
                Activa tu plan
              </Button>
            </Col>
          </Row>
        </Alert>
      </div>
    </Collapse>
  ) : null;
};
export default TrialBanner;
