export const hobbies = [
  { label: 'Bailar', value: 'Bailar' },
  { label: 'Cantar', value: 'Cantar' },
  { label: 'Ir al cine', value: 'Ir al cine' },
  { label: 'Gastronomía', value: 'Gastronomía' },
  { label: 'Viajar', value: 'Viajar' }
];

export const music = [
  { label: 'Pop', value: 'Pop' },
  { label: 'Rock', value: 'Rock' },
  { label: 'Hip hop', value: 'Hip hop' },
  { label: 'Flamenco', value: 'Flamenco' },
  { label: 'Dance y electrónica', value: 'Dance y electrónica' },
  { label: 'Indie', value: 'Indie' },
  { label: 'Rhythm and blues', value: 'Rhythm and blues' },
  { label: 'Metal', value: 'Metal' },
  { label: 'Jazz', value: 'Jazz' },
  { label: 'Clásica', value: 'Clásica' },
  { label: 'Folk', value: 'Folk' },
  { label: 'Soul', value: 'Soul' },
  { label: 'Reggae', value: 'Reggae' },
  { label: 'Punk', value: 'Punk' },
  { label: 'Blues', value: 'Blues' },
  { label: 'Funk', value: 'Funk' },
  { label: 'Bachata', value: 'Bachata' },
  { label: 'Salsa', value: 'Salsa' },
  { label: 'Cumbia', value: 'Cumbia' },
  { label: 'Country', value: 'Country' }
];

export const sports = [
  { label: 'Fútbol', value: 'Fútbol' },
  { label: 'Tenis', value: 'Tenis' },
  { label: 'Baloncesto', value: 'Baloncesto' },
  { label: 'Hockey', value: 'Hockey' },
  { label: 'Vóleibol', value: 'Vóleibol' },
  { label: 'Balonmano', value: 'Balonmano' },
  { label: 'Béisbol', value: 'Béisbol' },
  { label: 'Ciclismo', value: 'Ciclismo' },
  { label: 'Deportes de motor', value: 'Deportes de motor' },
  { label: 'MMA', value: 'MMA' },
  { label: 'Fútbol americano', value: 'Fútbol americano' },
  { label: 'Rugby', value: 'Rugby' },
  { label: 'Bádminton', value: 'Bádminton' },
  { label: 'Snooker', value: 'Snooker' },
  { label: 'Dardos', value: 'Dardos' },
  { label: 'Fútbol sala', value: 'Fútbol sala' },
  { label: 'Tenis de mesa', value: 'Tenis de mesa' },
  { label: 'Waterpolo', value: 'Waterpolo' },
  { label: 'E-Sports', value: 'E-Sports' }
];

export const interests = [
  {
    label: 'Aficiones',
    options: hobbies
  },
  {
    label: 'Deportes',
    options: sports
  },
  {
    label: 'Música',
    options: music
  }
];

export default interests;
