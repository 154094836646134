import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import AppContext, { UserContext } from 'context/Context';
import { getUnique } from 'helpers/utils';

const FormControl = forwardRef((props, ref) => (
  <Form.Control readOnly ref={ref} {...props} />
));

const eventTimeFormat = {
  hour: 'numeric',
  minute: '2-digit',
  omitZeroMinute: true,
  meridiem: true
};

const EditModal = ({ holiday, isOpen, setIsOpen }) => {
  const {
    locations,
    updateLocation,
    selectedLocationId: locationId
  } = useContext(UserContext);
  const location = locations?.find(
    ({ NO_ID_FIELD }) => NO_ID_FIELD === locationId
  );
  const { holidays = [], locality } = location || {};
  const [startDate, setScheduleStartDate] = useState(
    new Date(holiday?.start || Date.now())
  );
  const [title, setTitle] = useState(
    holiday?.title || `Festivo local${locality && ` (${locality})`}`
  );

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleUpdate = async () => {
    let _holidays = [...holidays]
      .filter(({ kind }) => !kind)
      .map(_holiday =>
        new Date(_holiday?.start).toDateString() ===
        new Date(holiday?.start).toDateString()
          ? {
              start: startDate?.toISOString(),
              title
            }
          : _holiday
      );
    updateLocation(location, { holidays: _holidays });
    handleClose();
  };

  const handleDelete = async () => {
    const start = startDate.toISOString();
    let _holidays = [...holidays]
      .filter(({ kind }) => !kind)
      .filter(({ start: _start }) => _start !== start);
    updateLocation(location, { holidays: _holidays });
    handleClose();
  };

  const handleWorkingHoliday = async () => {
    const start = startDate.toISOString();
    let _holidays = [...holidays].filter(
      ({ kind, start: _start }) => !kind && _start !== start
    );
    _holidays = [..._holidays, { start, title, working: true }];
    updateLocation(location, { holidays: _holidays });
    handleClose();
  };

  useEffect(() => {
    holiday?.start && setScheduleStartDate(new Date(holiday?.start));
    holiday?.title && setTitle(holiday?.title);
  }, [holiday]);

  const isAllowToSave =
    holiday &&
    (startDate?.toDateString() !== new Date(holiday.start).toDateString() ||
      title !== holiday.title);

  return (
    <Modal
      centered
      backdropClassName="z-index-1060"
      className="z-index-1065"
      contentClassName="border"
      show={isOpen}
      onHide={handleClose}
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="me-2">{holiday?.title}</span>
          {holiday?.working && (
            <SoftBadge bg="warning" className="fs--1">
              Laboral
            </SoftBadge>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Día</Form.Label>
          {holiday?.kind === 'calendar#event' ? (
            <h5>{dayjs(startDate).format('D MMMM YYYY')}</h5>
          ) : (
            <DatePicker
              selected={startDate}
              disabled={holiday?.working}
              onChange={newDate => {
                setScheduleStartDate(newDate);
              }}
              customInput={<FormControl disabled={holiday?.working} />}
              dateFormat="d MMMM yyyy"
            />
          )}
        </Form.Group>
        {holiday?.kind !== 'calendar#event' && (
          <Form.Group>
            <Form.Label htmlFor="NewHolidayInput">Título</Form.Label>
            {holiday?.kind === 'calendar#event' ? (
              <h5>{title}</h5>
            ) : (
              <Form.Control
                id="NewHolidayInput"
                value={title}
                disabled={holiday?.working}
                onFocus={() => focus(true)}
                onChange={({ target }) => setTitle(target.value)}
              />
            )}
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        {holiday?.kind === 'calendar#event' ? (
          <>
            <Button variant="falcon-warning" onClick={handleWorkingHoliday}>
              Laboral
            </Button>
          </>
        ) : (
          <>
            <Button variant="falcon-danger" onClick={handleDelete}>
              Eliminar
            </Button>
            {!holiday?.working && (
              <Button disabled={!isAllowToSave} onClick={handleUpdate}>
                Guardar
              </Button>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

EditModal.propTypes = {
  holiday: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
};

const NewHolidayModal = ({ start, isOpen, setIsOpen }) => {
  const {
    locations,
    selectedLocationId: locationId,
    updateLocation
  } = useContext(UserContext);
  const location = locations?.find(
    ({ NO_ID_FIELD }) => NO_ID_FIELD === locationId
  );
  const { holidays = [], locality } = location || {};
  const [startDate, setScheduleStartDate] = useState(start || new Date());
  const [title, setTitle] = useState(
    `Festivo local${locality && ` (${locality})`}`
  );

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAdd = async () => {
    const start = startDate.toISOString();
    let _holidays = [...holidays].filter(
      ({ kind, start: _start }) => !kind && _start !== start
    );
    _holidays = [..._holidays, { start, title }];
    updateLocation(location, { holidays: _holidays });
    handleClose();
  };

  useEffect(() => {
    setScheduleStartDate(start);
  }, [start]);

  return (
    <Modal
      centered
      backdropClassName="z-index-1060"
      className="z-index-1065"
      contentClassName="border"
      show={isOpen}
      onHide={handleClose}
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>Nuevo día festivo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Día</Form.Label>
          <DatePicker
            selected={startDate}
            onChange={newDate => {
              setScheduleStartDate(newDate);
            }}
            customInput={<FormControl />}
            dateFormat="d MMMM yyyy"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="NewHolidayInput">Título</Form.Label>
          <Form.Control
            id="NewHolidayInput"
            value={title}
            onFocus={() => focus(true)}
            onChange={({ target }) => setTitle(target.value)}
            autoFocus
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleAdd}>Crear día festivo</Button>
      </Modal.Footer>
    </Modal>
  );
};

NewHolidayModal.propTypes = {
  start: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
};

const Holidays = ({ className, showHeader = true }) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const { locations, selectedLocationId: locationId } = useContext(UserContext);
  const location = locations?.find(
    ({ NO_ID_FIELD }) => NO_ID_FIELD === locationId
  );
  const { holidays = [], manualHolidays = [], locality } = location || {};
  const [selectedHoliday, selectHoliday] = useState({});
  const [isOpenHolidayModal, setIsOpenHolidayModal] = useState(false);
  const [propsNewHoliday, setPropsNewHoliday] = useState({});
  const [isOpenNewHolidayModal, setIsOpenNewHolidayModal] = useState(false);
  const [calendarApi, setCalendarApi] = useState({});
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const calendarRef = useRef();
  const thisYear = new Date().getFullYear();

  useEffect(() => {
    const api = calendarRef?.current?.getApi();
    api && setCalendarApi(api);
  }, [calendarRef?.current]);

  const handleEventClick = info => {
    const { event } = info;
    const { start, title, extendedProps } = event;
    selectHoliday({ start: start.toISOString(), title, ...extendedProps });
    setIsOpenHolidayModal(true);
  };

  const handlePrevYear = () => {
    setCurrentYear(currentYear - 1);
    calendarApi.gotoDate(new Date(`${currentYear - 1}`));
  };

  const handleNextYear = () => {
    setCurrentYear(currentYear + 1);
    calendarApi.gotoDate(new Date(`${currentYear + 1}`));
  };

  return (
    <>
      <EditModal
        holiday={selectedHoliday}
        isOpen={isOpenHolidayModal}
        setIsOpen={setIsOpenHolidayModal}
      />
      <NewHolidayModal
        {...propsNewHoliday}
        isOpen={isOpenNewHolidayModal}
        setIsOpen={setIsOpenNewHolidayModal}
      />
      <Card className={className}>
        {showHeader && (
          <FalconCardHeader
            title={`Días festivos${!!name && ` de ${name}`}
          ${!!locality && !name.includes(locality) && ` (${locality})`}`}
          />
        )}
        <Card.Body className="bg-light pb-0">
          <div className="holidays-calendar position-relative">
            <Flex justifyContent="between" className="position-absolute w-100">
              <Button
                disabled={currentYear === thisYear - 1}
                variant="falcon-default"
                size="sm"
                onClick={handlePrevYear}
              >
                <FontAwesomeIcon icon="chevron-left" />
              </Button>
              <Button
                disabled={currentYear === thisYear + 1}
                variant="falcon-default"
                size="sm"
                onClick={handleNextYear}
              >
                <FontAwesomeIcon icon="chevron-right" />
              </Button>
            </Flex>
            <FullCalendar
              firstDay={1}
              headerToolbar={{
                start: '',
                center: 'title',
                end: ''
              }}
              ref={calendarRef}
              plugins={[multiMonthPlugin, interactionPlugin]}
              locale="es"
              initialView="multiMonthYear"
              themeSystem="bootstrap"
              dayHeaderFormat={{ weekday: 'narrow' }}
              dayMaxEvents={1}
              direction={isRTL ? 'rtl' : 'ltr'}
              multiMonthMaxColumns={4}
              multiMonthMinWidth={160}
              editable
              selectable
              selectMirror
              select={info => {
                setPropsNewHoliday({ start: info.start });
                setIsOpenNewHolidayModal(true);
              }}
              eventTimeFormat={eventTimeFormat}
              eventClick={handleEventClick}
              events={getUnique([...holidays, ...manualHolidays], 'start').map(
                holiday => ({
                  ...holiday,
                  allDay: true
                })
              )}
              eventClassNames={({ event }) => {
                const { extendedProps } = event || {};
                const { working } = extendedProps || {};
                return `holiday${working ? ' working-holiday' : ''}`;
              }}
              moreLinkContent=""
              moreLinkClassNames=""
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

Holidays.propTypes = {
  className: PropTypes.string,
  showHeader: PropTypes.bool
};

export default Holidays;
