import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import dayjs from 'dayjs';
import SimpleBarReact from 'simplebar-react';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import FeedbackProvider from 'components/feedback/FeedbackProvider';
import { FeedbackContext, UserContext } from 'context/Context';
import anonymousAvatar from 'assets/img/icons/blank-profile.svg';
import { parseUrls } from 'helpers/utils';

const UserCell = ({ response = {}, user = {} }) => {
  const { createdAt, new: isNew } = response;
  const { id, avatar, department, name } = user;

  return (
    <Flex alignItems="center">
      <Avatar size="2xl" src={avatar} name={name || 'H'} />
      <div className="ms-3">
        {!id ? (
          <h6 className="mb-0 fw-semi-bold">
            {name}
            {isNew && (
              <SoftBadge pill className="ms-2">
                Nuevo
              </SoftBadge>
            )}
          </h6>
        ) : (
          <>
            <Link
              to={`/feedback/contact-details/${id}`}
              className="text-800 text-wrap min-w-140px"
            >
              <h6 className="mb-0 fw-semi-bold">
                {name}
                {isNew && (
                  <SoftBadge pill className="ms-2">
                    Nuevo
                  </SoftBadge>
                )}
              </h6>
            </Link>
            <p className="fs--2 mb-0 text-700">{department}</p>
          </>
        )}

        <p className="fs--2 mb-0 text-500">
          {dayjs(createdAt).format('DD/MM/YYYY')}
        </p>
      </div>
    </Flex>
  );
};

UserCell.propTypes = {
  response: PropTypes.object,
  user: PropTypes.object
};

const UserFeedbacksContent = () => {
  const { feedback = [] } = useContext(FeedbackContext);
  const { partners = [] } = useContext(UserContext);
  const feedbackWithUser = feedback.map(item => {
    const { userId } = item;
    const user = partners.find(({ id } = {}) => id === userId) || {
      avatar: anonymousAvatar,
      name: 'Anónimo'
    };
    return { ...item, user };
  });

  return (
    <>
      {feedbackWithUser.map(({ user, feedback, ...data }, index) => {
        const output = parseUrls(feedback);
        return (
          <div key={`Feedback-${data?.NO_ID_FIELD}`}>
            {index > 0 && <div className="border-dashed border-bottom my-3" />}
            <UserCell response={data} user={user} />
            <p
              className="mt-2 ms-5 ps-2 fs--1 text-pre-wrap"
              dangerouslySetInnerHTML={{ __html: output }}
            />
          </div>
        );
      })}
      {!feedback.length && <span className="fs--1">No hay sugerencias</span>}
    </>
  );
};

const UserFeedbacks = ({ className }) => {
  return (
    <FeedbackProvider limit={5}>
      <Card className={className} as={Flex} direction="column">
        <SimpleBarReact className="h-100">
          <Card.Header className="bg-white dark__bg-1100 sticky-top">
            <h6 className="m-0">Sugerencias de empleados</h6>
          </Card.Header>
          <Card.Body className="position-relative">
            <UserFeedbacksContent />
          </Card.Body>
        </SimpleBarReact>
      </Card>
    </FeedbackProvider>
  );
};

UserFeedbacks.propTypes = {
  className: PropTypes.string
};

export default UserFeedbacks;
