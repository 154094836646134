/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */
import Fuse from 'fuse.js';
import { getUnique } from 'helpers/utils';

export const productReducer = (state, action) => {
  const { type, payload } = action;
  const options = {
    // Search in `title`, `description` and in `tags` array
    keys: ['title', 'description', 'tags']
  };
  const fuse = new Fuse(state.products || [], options);
  switch (type) {
    case 'SORT_PRODUCT':
      return {
        ...state,
        sortBy: payload.sortBy,
        order: payload.order,
        products: [...state.primaryProducts]
          .filter(
            ({ tags }) =>
              !state.filters.length ||
              tags.some(tag =>
                state.filters.map(({ name }) => name).includes(tag)
              )
          )
          .sort((a, b) => {
            if (payload.order === 'asc') {
              return a[payload.sortBy] < b[payload.sortBy] ? -1 : 1;
            }
            return a[payload.sortBy] > b[payload.sortBy] ? -1 : 1;
          })
      };
    case 'FILTER_PRODUCT':
      return {
        ...state,
        filters: payload.filters,
        products: [...state.primaryProducts]
          .filter(
            ({ tags, title }) =>
              !payload.filters.length ||
              tags.some(tag =>
                payload.filters.map(({ name }) => name).includes(tag)
              ) ||
              payload.filters.map(({ name }) => name).includes(title)
          )
          .sort((a, b) => {
            if (state.order === 'asc') {
              return a[state.sortBy] < b[state.sortBy] ? -1 : 1;
            }
            return a[state.sortBy] > b[state.sortBy] ? -1 : 1;
          })
      };
    case 'SEARCH_PRODUCT':
      let searchedProducts = state.products;
      if (payload.searchedText) {
        searchedProducts = fuse?._docs?.length
          ? fuse.search(payload.searchedText).map(({ item }) => item)
          : [];
      }
      return {
        ...state,
        searchedText: payload.searchedText,
        products: searchedProducts
      };
    case 'ADD_TO_CART':
      return {
        ...state,
        cartItems: [...state.cartItems, payload.product]
      };
    case 'REMOVE_FROM_CART':
      return {
        ...state,
        cartItems: state.cartItems.filter(
          product => product.id !== payload.product.id
        )
      };
    case 'ADD_TO_FAVOURITES':
      return {
        ...state,
        products: state.products.map(product =>
          product.id === payload.product.id
            ? { ...product, favorite: product.favorite + 1 }
            : product
        ),
        favouriteItems: [...state.favouriteItems, payload.product]
      };
    case 'REMOVE_FROM_FAVOURITES':
      return {
        ...state,
        products: state.products.map(product =>
          product.id === payload.product.id
            ? { ...product, favorite: product.favorite - 1 }
            : product
        ),
        favouriteItems: state.favouriteItems.filter(
          product => product.id !== payload.product.id
        )
      };
    case 'RESET':
      const stateProducts = getUnique([
        ...(payload.append ? state.products : []),
        ...payload.products
      ])
        .filter(
          ({ tags }) =>
            !state.filters.length ||
            tags.some(tag =>
              state.filters.map(({ name }) => name).includes(tag)
            )
        )
        .sort((a, b) => {
          if (state.order === 'asc') {
            return a[state.sortBy] < b[state.sortBy] ? -1 : 1;
          }
          return a[state.sortBy] > b[state.sortBy] ? -1 : 1;
        });
      let filteredProducts = stateProducts;

      return {
        ...state,
        initProducts: getUnique([
          ...(payload.append ? state.initProducts : []),
          ...payload.products
        ]),
        products: filteredProducts,
        primaryProducts: getUnique([
          ...(payload.append ? state.primaryProducts : []),
          ...payload.products
        ])
      };
    default:
      return state;
  }
};
