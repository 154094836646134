const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
const week = new Date();
week.setDate(week.getDate() - 7);

export const startRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Inicio',
      to: '/home',
      exact: true,
      icon: 'home',
      active: true,
      private: true
    },
    {
      id: 'questionaires',
      name: 'Clima laboral',
      active: true,
      icon: 'chart-pie',
      role: 'admin',
      children: [
        {
          name: 'Felicidad',
          to: '/dashboard/happiness',
          exact: true,
          active: true,
          private: true,
          role: 'admin'
        },
        {
          name: 'Ambiente laboral',
          to: '/dashboard/environment',
          exact: true,
          active: true,
          private: true,
          role: 'admin'
        },
        {
          name: 'Compañerismo',
          to: '/dashboard/fellowship',
          exact: true,
          active: true,
          private: true,
          role: 'admin'
        },
        {
          name: 'Motivación',
          to: '/dashboard/motivation',
          exact: true,
          active: true,
          private: true,
          role: 'admin'
        },
        {
          name: 'Satisfacción',
          to: '/dashboard/satisfaction',
          exact: true,
          active: true,
          private: true,
          role: 'admin'
        },
        {
          name: 'Estrés',
          to: '/dashboard/stress',
          exact: true,
          active: true,
          private: true,
          role: 'admin'
        }
      ]
    },
    {
      name: 'Marketplace',
      icon: 'store',
      to: '/marketplace',
      role: 'admin',
      active: true
    }
  ]
};

export const companyRoutes = {
  label: 'Empresa',
  children: [
    {
      id: 'feed',
      name: 'Tablón de anuncios',
      icon: 'rss',
      to: '/feed',
      badge: {
        type: 'accent',
        collection: 'feeds',
        filter: [['createdAt', '>', yesterday.toISOString()]]
      }
    },
    {
      name: 'Empleados',
      icon: 'users',
      to: '/company/employees',
      role: 'admin'
    },
    {
      name: 'Empleados',
      icon: 'users',
      to: '/user/partners',
      role: 'employee',
      show: false
    },
    {
      id: 'questionaires',
      name: 'Cuestionarios',
      icon: 'question-circle',
      to: '/company/questionaires',
      role: 'admin',
      badge: {
        type: 'accent',
        collection: 'questionaires'
      }
    },
    {
      id: 'questionaires',
      name: 'Cuestionarios',
      icon: 'question-circle',
      to: '/user/questionaires',
      role: 'employee',
      badge: {
        type: 'accent',
        collection: 'questionaires'
      }
    },
    {
      id: 'calendar',
      name: 'Calendario',
      icon: 'calendar-alt',
      to: '/company/calendar',
      active: true
    },
    {
      id: 'assistant',
      name: 'Asistente RRHH',
      icon: 'rocket',
      role: 'admin',
      active: true,
      children: [
        {
          name: 'Ofertas',
          icon: ['fab', 'trello'],
          to: '/company/assistant/offers'
        },
        {
          name: 'CRM Contratación',
          icon: ['fab', 'trello'],
          to: '/company/assistant/crm'
        }
      ]
    },
    {
      id: 'chat',
      name: 'Chat',
      icon: 'comments',
      to: '/company/chat',
      badge: {
        type: 'accent',
        collection: 'chat'
      }
    },
    {
      id: 'reviews',
      name: 'Evaluaciones',
      icon: 'chart-line',
      to: '/company/reviews',
      role: 'admin',
      badge: {
        type: 'accent',
        collection: 'reviews_responses',
        filter: [['new', '==', true]]
      }
    },
    {
      id: 'reviews',
      name: 'Evaluaciones',
      icon: 'chart-line',
      to: '/user/reviews',
      role: 'employee',
      badge: {
        type: 'accent',
        collection: 'reviews'
      }
    },
    {
      id: 'events',
      name: 'Eventos',
      icon: 'calendar-day',
      to: '/company/events',
      badge: {
        type: 'accent',
        collection: 'calendar',
        filterType: 'event'
      }
    },
    {
      id: 'reports',
      name: 'Informes',
      icon: 'file-alt',
      to: '/company/reports',
      role: 'admin'
    },
    {
      id: 'reports',
      name: 'Informes',
      icon: 'file-alt',
      to: '/department/reports',
      role: 'employee',
      level: 1
    },
    {
      id: 'objectives',
      name: 'Objetivos',
      icon: 'trophy',
      to: '/company/objectives',
      badge: {
        type: 'accent',
        collection: 'calendar',
        filterType: 'objective'
      }
    },
    {
      id: 'rankings',
      name: 'Rankings',
      icon: 'ranking-star',
      to: '/company/rankings'
    },
    {
      name: 'Configuración',
      icon: 'cog',
      to: '/company/profile',
      role: 'admin'
    }
  ]
};

export const myRoutes = {
  label: 'Empleado',
  children: [
    {
      id: 'time-control',
      name: 'Fichaje',
      icon: 'stopwatch',
      to: '/user/time-control'
    },
    {
      name: 'Perfil',
      icon: 'user',
      to: '/me',
      badge: {
        collection: 'profile'
      }
    },
    {
      id: 'time-off',
      name: 'Ausencias',
      icon: 'suitcase-rolling',
      to: '/user/time-off',
      badge: {
        type: 'accent',
        collection: 'calendar',
        filterType: 'time-off'
      }
    },
    {
      id: 'appreciations',
      name: 'Reconocimientos',
      icon: 'award',
      to: '/company/appreciations',
      badge: {
        type: 'success',
        collection: 'appreciations'
      }
    },
    {
      id: 'feedback',
      name: 'Sugerencias',
      icon: 'comment-medical',
      to: '/feedback',
      badge: {
        collection: 'feedback'
      }
    },
    {
      id: 'documents',
      name: 'Documentos',
      icon: 'address-book',
      to: '/user/documents'
    }
  ]
};

export const benefitsRoutes = {
  label: 'Beneficios',
  children: [
    {
      name: 'Mis beneficios',
      icon: 'wallet',
      to: '/user/benefits',
      badge: {
        type: 'success',
        collection: 'benefits',
        filter: [['active', '==', 'default']]
      }
    },
    {
      name: 'Ofertas exclusivas',
      icon: 'certificate',
      to: '/benefits/exclusive',
      badge: {
        type: 'accent',
        collection: 'programmes',
        filter: [
          ['source', '==', 'exclusive'],
          ['start', '>', week.toISOString()]
        ]
      }
    },
    {
      name: 'Descuentos',
      icon: 'tags',
      to: '/benefits/discounts',
      badge: {
        type: 'accent',
        collection: 'programmes',
        filter: [
          ['source', '==', 'discount'],
          ['start', '>', week.toISOString()]
        ]
      }
    },
    {
      name: 'Cashback',
      icon: 'money-bill',
      to: '/benefits/cashback',
      badge: {
        type: 'accent',
        collection: 'programmes',
        filter: [
          ['source', '==', 'awin'],
          ['start', '>', week.toISOString()]
        ]
      }
    }
  ]
};

export default [startRoutes, myRoutes, companyRoutes, benefitsRoutes];
