import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Badge, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { collection, doc, getDoc } from '@firebase/firestore';
import { getDownloadURL, ref } from '@firebase/storage';
import { useFirestore, useStorage } from 'reactfire';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';

const RankingPodium = ({ data, unit }) => {
  if (!data?.length) {
    data = [{}, {}, {}];
  }

  return (
    <Row className="mt-4 text-center">
      {data?.slice(0, 3).map(({ count = '-', reward, user }, index) => {
        const { avatar, disabled, name = '-' } = user || {};
        return (
          <Col
            key={`Podium-User-${user?.NO_ID_FIELD}-${count}-${index}`}
            md={classNames({
              12: index === 0,
              6: index !== 0
            })}
            className={classNames('mb-4', {
              'mt-md-n7': index !== 0,
              'pe-md-8': index === 1,
              'ps-md-8': index === 2
            })}
          >
            <Flex
              className="position-relative"
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <div
                className={classNames(
                  'position-absolute top-0 rounded-circle border-bottom border-5 px-5 pt-7 bg-opacity-50 opacity-50',
                  {
                    'bg-yellow border-yellow': index === 0,
                    'bg-400 border-400': index === 1,
                    'bg-orange border-orange': index === 2
                  }
                )}
              />
              <div className="position-relative pt-3 px-3 pb-3">
                <Avatar
                  className={classNames('mt-n4', { disabled })}
                  src={avatar}
                  name={name}
                  size="3xl"
                />
                <FontAwesomeIcon
                  icon="trophy"
                  className={classNames(
                    'position-absolute bottom-0 end-0 me-n4 mb-n3',
                    {
                      'text-yellow': index === 0,
                      'text-400': index === 1,
                      'text-orange': index === 2
                    }
                  )}
                  size="3x"
                />
              </div>
              <Badge
                className="position-relative mt-n2 mb-2 fs-1"
                pill
                bg="primary"
                size="lg"
                style={{ width: 32, height: 32 }}
                as={Flex}
                alignItems="center"
                justifyContent="center"
              >
                {index + 1}
              </Badge>
              <div className="text-nowrap text-truncate position-relative">
                <h5>{name}</h5>
                <h5 className="text-600">
                  {count} {unit}
                </h5>
                {reward && <h6 className="fw-normal text-600">{reward}</h6>}
              </div>
            </Flex>
          </Col>
        );
      })}
    </Row>
  );
};

RankingPodium.propTypes = {
  data: PropTypes.array,
  unit: PropTypes.string
};

const FeedRanking = ({ ranking = {} }) => {
  const db = useFirestore();
  const storage = useStorage();
  const { company, partners = [] } = useContext(UserContext);
  const { podium = [], unit } = ranking;
  const [data, setData] = useState();

  const getData = async () => {
    const data = await Promise.all(
      podium?.map(async ({ userId, ...rest }) => {
        let user = partners.find(({ NO_ID_FIELD }) => NO_ID_FIELD === userId);
        if (!user) {
          try {
            const userDoc = await getDoc(doc(collection(db, 'users'), userId));
            let avatar;
            try {
              avatar = await getDownloadURL(
                ref(storage, `/users/${userId}/avatar.jpeg`)
              );
            } catch (error) {
              console.error(error);
            }
            user = !userDoc
              ? {}
              : {
                  NO_ID_FIELD: userDoc.id,
                  id: userDoc.id,
                  ref: userDoc.ref,
                  ...(await userDoc.data()),
                  avatar,
                  disabled: true
                };
          } catch (error) {
            console.error(error);
          }
        }
        return { user, ...rest };
      })
    );
    setData(data);
  };

  useEffect(() => {
    partners?.length > 1 && getData();
  }, [company?.NO_ID_FIELD, podium, partners?.length]);

  return (
    <>
      <Flex direction="column" alignItems="center" justifyContent="center">
        <RankingPodium data={data} unit={unit} />
      </Flex>
    </>
  );
};

FeedRanking.propTypes = {
  ranking: PropTypes.object
};

export default FeedRanking;
