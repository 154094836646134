import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { collection, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import Face from 'components/common/Face';
import Flex from 'components/common/Flex';
import types from 'components/dashboards/types.json';
import { UserContext } from 'context/Context';

const Totals = ({ bodyClassName, className, type, user }) => {
  const title = types[type];
  const { company } = useContext(UserContext);
  const db = useFirestore();
  const now = new Date(Date.now());
  const defaultFromDate = new Date(
    now.setMonth(new Date(Date.now()).getMonth() - 1)
  )
    .toISOString()
    .split(':')[0];
  const [fromDate] = useState(defaultFromDate);
  const toDate = new Date(Date.now());
  toDate.setDate(toDate.getDate() + 1);
  const filters = [
    where('companyId', '==', `${company?.NO_ID_FIELD}`),
    user && where('userId', '==', `${user.NO_ID_FIELD}`),
    where('type', '==', type),
    where('createdAt', '>', fromDate),
    where('createdAt', '<=', toDate.toISOString().split('T')[0])
  ].filter(filter => filter);
  let indexesQuery = query(collection(db, 'none'));
  if (company?.NO_ID_FIELD) {
    indexesQuery = query(collection(db, 'indexes'), ...filters);
  }
  const { data: raw = [] } = useFirestoreCollectionData(indexesQuery);
  const indexesLength = raw?.filter(({ value }) => value !== null).length;
  const average =
    !!indexesLength &&
    Math.round(
      raw?.reduce((total, { value = 0 }) => total + value, 0) /
        (indexesLength || 1) || 0
    );

  return (
    <Card className={className}>
      <Card.Header className="pb-0">
        <h6 className="m-0 w-50">
          <span className="text-capitalize">{title}</span> en {company?.name}
        </h6>
      </Card.Header>
      <Card.Body
        as={Flex}
        alignItems="end"
        justifyContent="between"
        className={classNames('pt-0', bodyClassName)}
      >
        <div>
          <h2 className="fw-normal text-700 mb-1 lh-1">{average || '-'}%</h2>
        </div>
        <Face
          value={average || 60}
          className="position-absolute bottom-0 end-0 mb-n3 w-150px h-150px"
        />
      </Card.Body>
    </Card>
  );
};

Totals.propTypes = {
  bodyClassName: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  user: PropTypes.object
};

export default Totals;
