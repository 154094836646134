import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { GaugeChart } from 'echarts/charts';
import Avatar from 'components/common/Avatar';
import IndexGraph from 'components/charts/IndexGraph';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { CanvasRenderer } from 'echarts/renderers';
import { Card } from 'react-bootstrap';
import { getColor, groupBy } from 'helpers/utils';
import { ReportsContext } from 'context/Context';
import types from 'components/dashboards/types.json';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  GaugeChart,
  CanvasRenderer,
  LegendComponent
]);

const alerts = {
  happiness: '< 70',
  environment: '< 70',
  fellowship: '< 70',
  motivation: '< 70',
  satisfaction: '< 70',
  stress: '> 70'
};

const Notification = ({
  id,
  name,
  avatar,
  department,
  isLast,
  type,
  value
}) => {
  let color =
    (isNaN(value) && 'none') ||
    (value > 75 && getColor('success')) ||
    (value > 60 && getColor('warning')) ||
    getColor('danger');

  if (type === 'stress') {
    color =
      (isNaN(value) && 'none') ||
      (value > 80 && getColor('danger')) ||
      (value > 60 && getColor('warning')) ||
      getColor('success');
  }

  return (
    <li className={classNames({ 'border-bottom border-200': !isLast })}>
      <Flex alignItems="center" className="position-relative">
        <div className="p-3 flex-grow-1">
          <Flex alignItems="center" className="position-relative">
            <Avatar size="xl" src={avatar} width="60" name={name} alt={name} />
            <div className="flex-1 ms-3">
              <h6 className="mb-0 fw-semi-bold">
                <Link
                  className="text-dark stretched-link"
                  to={`/user/profile/${id}`}
                >
                  {name}
                </Link>
              </h6>
              <p className="fs--2 mb-0 text-500">{department}</p>
            </div>
          </Flex>
        </div>
        <div>
          <SoftBadge pill bg="primary" className="text-capitalize">
            {types[type]}
          </SoftBadge>
        </div>
        <div className="p-3 align-middle">
          <IndexGraph title={value} color={color} data={value} />
        </div>
      </Flex>
    </li>
  );
};

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  department: PropTypes.string.isRequired,
  isLast: PropTypes.bool,
  value: PropTypes.number.isRequired,
  type: PropTypes.string
};

const Alerts = () => {
  const { department: departmentProp } = useParams();
  const { indexes = [], partners = [] } = useContext(ReportsContext);

  const data = Object.entries(
    groupBy(
      // eslint-disable-next-line no-unused-vars
      indexes.filter(({ type, value }) => eval(`value ${alerts[type]}`)),
      'userId'
    )
  )
    .map(([userId, indexes]) => {
      const user = partners.find(({ id }) => id === userId);
      if (!user || (departmentProp && user?.department !== departmentProp)) {
        return;
      }
      const index = indexes
        .sort((i1, i2) => (i1.createdAt < i2.createdAt ? -1 : 1))
        .pop();
      return { ...user, ...index };
    })
    .filter(data => data)
    .sort(({ value: v1 }, { value: v2 }) => (v1 > v2 ? -1 : 1));

  return (
    <Card className="shadow-none">
      {data.length ? (
        <Card.Body className="p-0">
          <ul className="mb-0 list-unstyled">
            {data.map((item, index) => (
              <Notification
                key={`Notification-${item.id}`}
                {...item}
                isLast={data.length - 1 === index}
              />
            ))}
          </ul>
        </Card.Body>
      ) : (
        <Card.Body className="p-0 overflow-hidden" style={{ height: '21rem' }}>
          <div className="px-3 py-2 flex-grow-1">
            <p className="fs--1 ms-1 mb-0 text-500">No hay alertas</p>
          </div>
        </Card.Body>
      )}
    </Card>
  );
};

export default Alerts;
