import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row } from 'react-bootstrap';
import RankingPodium from 'components/company/rankings/RankingPodium';
import RankingUserList from 'components/company/rankings/RankingUserList';
import SectionTitle from 'components/company/rankings/SectionTitle';
import appreciationTitles from 'components/company/appreciations/titles.json';
import { RankingContext, UserContext } from 'context/Context';
import { groupBy } from 'helpers/utils';

const AppreciationGroup = ({ appreciations, icon }) => {
  const { setModalData, setIsOpenObjectivesModal } = useContext(RankingContext);
  const { partners = [] } = useContext(UserContext);
  const title = appreciationTitles[icon] || '';
  const appreciationsByUser = Object.entries(groupBy(appreciations, 'targetId'))
    .map(([targetId, data]) => ({
      count: data?.reduce((total, { value }) => total + value, 0),
      user: partners?.find(({ NO_ID_FIELD }) => NO_ID_FIELD === targetId)
    }))
    .filter(({ user }) => user)
    .sort((u1, u2) => (u1.count > u2.count ? -1 : 1));

  return (
    <>
      <SectionTitle
        icon={icon}
        title={title}
        subtitle={`Ranking de empleados por ${
          appreciationTitles?.[icon]?.toLowerCase() || ''
        } ${icon} recibidos`}
        className="mb-4 mt-6"
      />
      <Row className="g-3 mb-3">
        <Col xs={12} lg={appreciationsByUser.length > 3 ? 8 : 12}>
          <Card className="h-100">
            <Card.Body>
              <RankingPodium
                data={appreciationsByUser}
                unit={`${title} ${icon} recibidos`.toLowerCase()}
              />
            </Card.Body>
          </Card>
        </Col>
        {appreciationsByUser.length > 3 && (
          <Col xs={12} lg={4}>
            <Card className="h-100">
              <Card.Body>
                <RankingUserList data={appreciationsByUser} unit={`${title}`} />
              </Card.Body>
              <Card.Footer className="d-grid bg-light p-0 border-top text-center">
                <Button
                  variant="link"
                  size="sm"
                  className="w-100 py-2"
                  onClick={() => {
                    setModalData({
                      data: appreciationsByUser,
                      title: `Ranking de empleados por ${title} recibidos`,
                      unit: `${title} ${icon} recibidos`
                    });
                    setIsOpenObjectivesModal(true);
                  }}
                >
                  Ver todo
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

AppreciationGroup.propTypes = {
  appreciations: PropTypes.array,
  icon: PropTypes.string
};

const RankingAppreciations = () => {
  const { appreciations = [] } = useContext(RankingContext);

  const appreciationsByIcon = Object.entries(groupBy(appreciations, 'icon'))
    .map(([icon, appreciations]) => ({
      appreciations,
      count: appreciations?.length,
      icon
    }))
    .sort((u1, u2) => (u1.count > u2.count ? -1 : 1));

  return (
    <>
      {appreciationsByIcon.map(({ appreciations, icon }) => (
        <AppreciationGroup
          key={`Ranking-Appreciation-${icon}`}
          appreciations={appreciations}
          icon={icon}
        />
      ))}
    </>
  );
};

export default RankingAppreciations;
