import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/Context';
import { isSignInWithEmailLink } from 'firebase/auth';
import { useAuth } from 'reactfire';
import { useForm } from 'react-hook-form';

const LoginForm = ({ hasLabel }) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    watch
  } = useForm({
    email: '',
    password: '',
    confirmPassword: '',
    remember: false
  });
  const auth = useAuth();
  const { signInWithEmail } = useContext(UserContext);
  const { email, password, confirmPassword, remember } = watch();

  const onSubmit = async data => {
    const { email, password, remember } = data;
    if (isSignInWithEmailLink(auth, window.location.href)) {
      try {
        await signInWithEmail(
          email,
          password,
          remember
            ? {
                expires: 14
              }
            : {}
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email' : ''}
          value={email}
          type="email"
          isInvalid={errors.email}
          {...register('email', { required: 'Escribe tu email' })}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Contraseña</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Contraseña' : ''}
          value={password}
          type="password"
          isInvalid={errors.password}
          {...register('password', {
            required: 'Escribe una contraseña',
            minLength: {
              value: 6,
              message: 'La contraseña debe tener al menos 6 caracteres'
            }
          })}
        />
        <Form.Control.Feedback type="invalid">
          {errors.password?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Confirma la contraseña</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirma la contraseña' : ''}
          value={confirmPassword}
          type="password"
          isInvalid={errors.confirmPassword}
          {...register('confirmPassword', {
            required: 'Confirma la contraseña',
            validate: value =>
              value === watch('password') || 'Las contraseñas no coinciden'
          })}
        />
        <Form.Control.Feedback type="invalid">
          {errors.confirmPassword?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              checked={remember}
              {...register('remember')}
            />
            <Form.Check.Label className="mb-0 text-700">
              Recuérdame
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link className="fs--1 mb-0" to="/authentication/forgot-password">
            ¿Has olvidado la contraseña?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button type="submit" color="primary" className="mt-3 w-100">
          Inicia sesión
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
