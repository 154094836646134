import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import IndexGraph from 'components/charts/IndexGraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { getColor } from 'helpers/utils';
import { Draggable } from 'react-beautiful-dnd';
import AppContext, { AssistantContext } from 'context/Context';
import { Typewriter } from 'react-simple-typewriter';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

const TaskDropMenu = ({ id }) => {
  const { removeCandidate } = useContext(AssistantContext);

  const {
    config: { isRTL }
  } = useContext(AppContext);

  const handleRemoveTaskCard = () => {
    removeCandidate(id);
  };

  return (
    <Dropdown
      onClick={e => e.stopPropagation()}
      align="end"
      className="font-sans-serif"
    >
      <Dropdown.Toggle
        variant="falcon-default"
        size="sm"
        className="kanban-item-dropdown-btn hover-actions dropdown-caret-none"
      >
        <FontAwesomeIcon icon="ellipsis-h" transform="shrink-2" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="py-2" align={isRTL ? 'start' : 'end'}>
        <Dropdown.Item onClick={handleRemoveTaskCard} className="text-danger">
          Eliminar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const CandidateCard = ({ task: candidate, index }) => {
  let { id, createdAt, state, title, data = {}, offerId } = candidate;
  const { completion = {} } = data;
  let { info, compatibility, personality = [] } = completion;
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const { offers = [], showCandidateModal } = useContext(AssistantContext);
  const statusLabels = {
    loading: {
      text: (
        <Typewriter
          words={[
            'Generando candidato',
            'La IA está trabajando',
            'Puede tardar unos minutos'
          ]}
          loop={true}
          cursor={!isRTL ? true : false}
          cursorStyle="|"
          typeSpeed={70}
          deleteSpeed={50}
          delaySpeed={1000}
        />
      ),
      type: 'secondary'
    }
  };
  state =
    typeof completion !== 'object' && state === 'success' ? 'loading' : state;
  const statusLabel = statusLabels[state];
  const labels = [
    statusLabel,
    ...personality.slice(0, 3).map(tag => {
      const text = tag.replace(/^-/, '').trim().split(' ')?.[0];
      return {
        type: 'primary',
        text,
        title: tag
      };
    })
  ].filter(labels => labels);

  compatibility = compatibility ? parseInt(compatibility) : '-';
  let compatibilityColor =
    (isNaN(compatibility) && 'none') ||
    (compatibility > 75 && getColor('success')) ||
    (compatibility >= 60 && getColor('warning')) ||
    getColor('danger');

  const handleModalOpen = () => {
    showCandidateModal(candidate);
  };
  // styles we need to apply on draggables
  const getItemStyle = isDragging => ({
    cursor: isDragging ? 'grabbing' : 'pointer',
    transform: isDragging ? 'rotate(-2deg)' : ''
  });

  const { title: offerTitle = '' } =
    offers?.find(({ NO_ID_FIELD }) => NO_ID_FIELD === offerId) || {};

  return (
    <Draggable draggableId={`task${id}`} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
          className="kanban-item"
        >
          <Card
            style={getItemStyle(snapshot.isDragging)}
            className="kanban-item-card hover-actions-trigger"
            onClick={handleModalOpen}
          >
            <Card.Body>
              <div className="position-relative">
                <TaskDropMenu id={id} />
              </div>
              {!!labels?.length && (
                <Flex alignItems="center" wrap="wrap" className="mb-2">
                  {labels.map(({ Label = SoftBadge, text, type, ...rest }) => (
                    <Label
                      key={text}
                      bg={type}
                      className="py-1 me-1 mb-1 text-truncate"
                      {...rest}
                    >
                      {text}
                    </Label>
                  ))}
                </Flex>
              )}
              <Flex>
                {title && <Avatar name={title} className="me-2" />}
                <div className="w-0 flex-grow-1">
                  <p className="mb-0 fw-medium font-sans-serif">{title}</p>
                  <p className="mb-0 text-500 font-sans-serif text-truncate">
                    {info}
                  </p>
                </div>
              </Flex>
              <div className="kanban-item-footer cursor-default">
                <div>
                  <div className="text-500">
                    <Flex alignItems="center" className="me-2">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Offerta de trabajo</Tooltip>}
                      >
                        <span>
                          <FontAwesomeIcon icon="briefcase" className="me-1" />
                        </span>
                      </OverlayTrigger>
                      <span>{offerTitle}</span>
                    </Flex>
                  </div>
                  <div className="text-500 mt-1">
                    <Flex alignItems="center" className="me-2">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Fecha de creación</Tooltip>}
                      >
                        <span>
                          <FontAwesomeIcon icon="clock" className="me-1" />
                        </span>
                      </OverlayTrigger>
                      <span>
                        {dayjs(createdAt).format('D MMMM YYYY, HH:mm')}
                      </span>
                    </Flex>
                  </div>
                </div>
                <div>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Compatibilidad</Tooltip>}
                  >
                    <div>
                      <IndexGraph
                        title={compatibility}
                        className="d-flex align-items-center justify-content-center"
                        titleClassName="position-absolute fs--2 mt-1"
                        color={compatibilityColor}
                        data={isNaN(compatibility) ? 0 : compatibility}
                        svgOptions={{
                          width: '36',
                          height: '36',
                          viewBox: '0 0 120 120'
                        }}
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

TaskDropMenu.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

CandidateCard.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    avatar: PropTypes.string,
    title: PropTypes.string,
    createdAt: PropTypes.string,
    state: PropTypes.string,
    offerId: PropTypes.string,
    data: PropTypes.object
  }),
  index: PropTypes.number
};

export default CandidateCard;
