import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { UserContext } from 'context/Context';
import ProductImage from './ProductImage';
import { getCurrencyFormat } from 'helpers/utils';

const ProductGrid = ({ product, ...rest }) => {
  const { me } = useContext(UserContext);
  const {
    NO_ID_FIELD,
    title,
    description,
    price,
    finalPrice,
    img,
    isNew,
    url
  } = product;
  const files = Array.isArray(img) ? img.map(src => ({ src })) : [{ src: img }];
  const urlWithUser = `${url}${url?.match(/\?/) ? '&' : '?'}clickref=${
    me?.NO_ID_FIELD
  }`;

  return (
    <Col className="mb-4" {...rest}>
      <Flex
        direction="column"
        justifyContent="between"
        className="border rounded-3 h-100"
      >
        <div className="overflow-hidden">
          <ProductImage
            name={title}
            id={NO_ID_FIELD}
            isNew={isNew}
            files={files}
            layout="grid"
          />
          <div className="p-3">
            <h5 className="fs-0">{title}</h5>
            <p className="fs--1 m-0">{description}</p>
            <Row>
              <Col xs={12} md="auto">
                <p className="fs--2 text-500 mt-2 mb-0">PRECIO ORIGINAL</p>
                <p className="mt-2 text-decoration-line-through">
                  {getCurrencyFormat(price)}
                </p>
              </Col>
              <Col xs={12} md="auto">
                <p className="fs--2 text-500 mt-2 mb-0">POR SER DE HAPPYFY</p>
                <h5 className="mt-2 fs-2 fw-bold text-primary">
                  {getCurrencyFormat(finalPrice)}
                </h5>
              </Col>
            </Row>
          </div>
        </div>
        <div className="p-3 pt-0">
          <Button
            variant="falcon-default w-100"
            as={Link}
            to={urlWithUser}
            target="_blank"
          >
            Ir a la web
          </Button>
        </div>
      </Flex>
    </Col>
  );
};

ProductGrid.propTypes = {
  product: PropTypes.shape({
    NO_ID_FIELD: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.string,
    description: PropTypes.string,
    finalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    url: PropTypes.string.isRequired,
    img: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    isNew: PropTypes.bool
  })
};

export default ProductGrid;
