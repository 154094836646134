import React, { useContext } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { RankingContext, UserContext } from 'context/Context';
import RankingPodium from 'components/company/rankings/RankingPodium';
import RankingUserList from 'components/company/rankings/RankingUserList';
import SectionTitle from 'components/company/rankings/SectionTitle';
import { groupBy } from 'helpers/utils';

const RankingObjectives = () => {
  const {
    objectives = [],
    setModalData,
    setIsOpenObjectivesModal
  } = useContext(RankingContext);
  const { company, partners = [] } = useContext(UserContext);
  const { apps = [] } = company || {};

  if (!apps.includes('objectives')) {
    return null;
  }

  const objectivesByUser = Object.entries(groupBy(objectives, 'userPath'))
    .map(([path, data]) => ({
      count: data?.length,
      user: partners?.find(({ ref }) => ref?.path === path)
    }))
    .filter(({ user }) => user)
    .sort((u1, u2) => (u1.count > u2.count ? -1 : 1));

  return (
    <>
      <SectionTitle
        icon="trophy"
        title="Objetivos"
        subtitle="Ranking de empleados por objetivos cumplidos"
        transform="shrink-4"
        className="mb-4 mt-6"
      />
      <Row className="g-3 mb-3">
        <Col xs={12} lg={objectivesByUser.length > 3 ? 8 : 12}>
          <Card className="h-100">
            <Card.Body>
              <RankingPodium
                data={objectivesByUser}
                unit="objetivo(s) cumplido(s)"
              />
            </Card.Body>
          </Card>
        </Col>
        {objectivesByUser.length > 3 && (
          <Col xs={12} lg={4}>
            <Card className="h-100">
              <Card.Body>
                <RankingUserList
                  data={objectivesByUser}
                  unit="objetivo(s) cumplido(s)"
                />
              </Card.Body>
              <Card.Footer className="d-grid bg-light p-0 border-top text-center">
                <Button
                  variant="link"
                  size="sm"
                  className="w-100 py-2"
                  onClick={() => {
                    setModalData({
                      data: objectivesByUser,
                      title: 'Ranking de empleados por objetivos cumplidos',
                      unit: 'objetivo(s) cumplido(s)'
                    });
                    setIsOpenObjectivesModal(true);
                  }}
                >
                  Ver todo
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default RankingObjectives;
