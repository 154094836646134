import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const FeedUrl = ({ url: urlAddress }) => {
  const [metadata, setMetadata] = useState();
  const { image, title, description } = metadata || {};
  const getMetadata = async () => {
    const result = await fetch(
      `${process.env.REACT_APP_FIREBASE_URL}/getMetadata?url=${urlAddress}`
    );
    const json = (await result.json()) || {};
    const { og = {} } = json;
    setMetadata(og);
  };

  useEffect(() => {
    !metadata && getMetadata();
  }, []);

  return (
    <a
      className="text-decoration-none"
      href={urlAddress}
      target="_blank"
      rel="noreferrer"
    >
      {!!image && <img className="img-fluid rounded" src={image} alt="" />}
      <small className="d-block my-1 text-uppercase text-700">
        {urlAddress}
      </small>
      {title && <h6 className="fs-0 mb-0">{title}</h6>}
      {!!description && <p className="fs--1 mb-0 text-700">{description}</p>}
    </a>
  );
};

FeedUrl.propTypes = {
  url: PropTypes.string.isRequired
};
export default FeedUrl;
