import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Button, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleBarReact from 'simplebar-react';
import FalconDropzone from 'components/common/FalconDropzone';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import Tooltip from 'components/common/Tooltip';
import { FilesContext, UserContext } from 'context/Context';
import { getUnique } from 'helpers/utils';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';

const UploadPayoffs = () => {
  const { createFile } = useContext(FilesContext);
  const { partners = [] } = useContext(UserContext);
  const [files, setFiles] = useState([]);
  const [now] = useState(new Date());
  const [format] = useState({
    title: 'AÑO-MES-DNI.pdf',
    value: 'YYYY-MM-nif',
    example: `${now.getFullYear()}-${now.getMonth() < 10 ? '0' : ''}${
      now.getMonth() + 1
    }-000000A.pdf`
  });

  const partnerFiles = partners
    ?.map(partner => {
      const fileInfo =
        files.find(({ path }) => {
          const [, nif] = path?.match(/[\d]{4}-[\d]{2}-(.*).pdf/i) || [];
          return partner?.nif === nif;
        }) || {};
      const { file } = fileInfo;
      return { ...partner, fileInfo, hasFile: !!file };
    })
    .sort((f1, f2) => {
      if (f1.hasFile === f2.hasFile) {
        return f1.name < f2.name ? -1 : 1;
      }
      return f1.hasFile && !f2.hasFile ? 1 : -1;
    });

  const handleChange = (filesInfo, filesItems) => {
    const items = filesItems.map(file => {
      const { path, lastModified, lastModifiedDate, name, size, type } = file;
      return { file, path, lastModified, lastModifiedDate, name, size, type };
    });

    setFiles(getUnique([...files, ...items], 'path'));
  };

  const handleCreate = async event => {
    event.stopPropagation();
    await Promise.all(
      partnerFiles
        .filter(({ hasFile }) => hasFile)
        .map(({ fileInfo, ...user }) => {
          const { file, ...info } = fileInfo;
          return createFile(file, {
            ...info,
            type: 'payroll',
            userId: user?.NO_ID_FIELD
          });
        })
    );
    setFiles([]);
  };

  return (
    <FalconDropzone
      className="h-auto py-6"
      files={files}
      hide={!!files?.length}
      onChange={handleChange}
      placeholder={
        <>
          <div className="mb-3">
            <FontAwesomeIcon
              icon="file-invoice"
              size="2x"
              className="ms-n3 bg-white border-start border-2 border-white"
            />
            <FontAwesomeIcon
              icon="file-invoice"
              size="2x"
              className="ms-n2 bg-white border-start border-2 border-white"
            />
            <FontAwesomeIcon
              icon="file-invoice"
              size="2x"
              className="ms-n2 position-absolute bg-white border-start border-2 border-white"
            />
            <Avatar
              src={cloudUpload}
              className="ms-2 mt-2 position-absolute bg-transparent"
              mediaClass="bg-300 p-1"
              size="l"
            />
          </div>
          <p className="fs--1 mb-1">
            Arrastra aquí las nóminas de los empleados. El nombre de los
            archivos tienen que tener este formato:
          </p>
          <strong className="fs--1 mb-0">{format?.value}</strong>
          <div>
            <strong className="fs--1 mb-0">P. ej. {format?.example}</strong>
          </div>
        </>
      }
      preview={
        <>
          <SimpleBarReact className="py-1 max-h-300px">
            <ListGroup variant="flush">
              {partnerFiles.map(
                ({
                  NO_ID_FIELD,
                  avatar,
                  department,
                  name,
                  fileInfo,
                  hasFile
                }) => {
                  const { file } = fileInfo || {};
                  return (
                    <ListGroup.Item
                      key={`File-${file?.name || name}`}
                      as={Flex}
                      alignItems="center"
                      className="cursor-default gap-2 border-200 position-relative"
                    >
                      <Avatar src={avatar} name={name} />
                      <div className="ps-2 text-start flex-grow-1 w-0">
                        <Link
                          to={`/user/profile/${NO_ID_FIELD}`}
                          className="text-800"
                        >
                          <h6 className="mb-0 text-truncate">{name}</h6>
                        </Link>
                        <p className="mb-0 fs--2">{department}</p>
                      </div>
                      <div className="ps-2">
                        {file && (
                          <OverlayTrigger
                            trigger={['hover', 'focus']}
                            overlay={
                              <Popover
                                body
                                placement="auto"
                                className="max-w-100"
                              >
                                <iframe
                                  className="min-w-500px min-vh-50"
                                  src={URL.createObjectURL(file)}
                                />
                              </Popover>
                            }
                          >
                            <Button variant="falcon-default" size="sm">
                              <FontAwesomeIcon icon="eye" />
                            </Button>
                          </OverlayTrigger>
                        )}
                      </div>
                      <div>
                        <Tooltip
                          title={classNames({
                            'Archivo detectado': hasFile,
                            [`La nómina de ${name} no está en la lista de nóminas para subir.`]:
                              !hasFile
                          })}
                        >
                          <FontAwesomeIcon
                            icon={classNames({
                              'check-circle': hasFile,
                              'exclamation-circle': !hasFile
                            })}
                            className={classNames({
                              'text-success': hasFile,
                              'text-warning': !hasFile
                            })}
                          />
                        </Tooltip>
                      </div>
                    </ListGroup.Item>
                  );
                }
              )}
            </ListGroup>
          </SimpleBarReact>
          <Button
            variant="falcon-success"
            className="w-100 border"
            onClick={handleCreate}
          >
            Subir {partnerFiles.filter(({ hasFile }) => hasFile)?.length}{' '}
            nóminas
          </Button>
        </>
      }
    />
  );
};

export default UploadPayoffs;
