import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Flex from 'components/common/Flex';
import { Alert, Card } from 'react-bootstrap';
import { BenefitsContext } from 'context/Context';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Avatar from 'components/common/Avatar';
import { getCurrencyFormat } from 'helpers/utils';

const columns = [
  {
    accessor: 'title',
    cellProps: {
      className: 'white-space-nowrap',
      style: { maxWidth: '23rem' }
    },
    Cell: rowData => {
      const { avatar, icon } = rowData.row.original;
      return (
        <Flex alignItems="center" className="gap-3 position-relative">
          <Avatar
            mediaClass={classNames({
              'align-items-center justify-content-center fs-0 bg-success': icon
            })}
            src={avatar}
            icon={icon}
          />
        </Flex>
      );
    }
  },
  {
    accessor: 'description',
    Header: 'Descripción',
    headerProps: {
      className: 'fw-medium'
    },
    Cell: rowData => {
      const { createdAt, description } = rowData.row.original;
      const date = dayjs(createdAt).format('D MMMM YYYY');
      return (
        <>
          <p className="text-500 m-0">{date}</p>
          <h6>{description}</h6>
        </>
      );
    }
  },
  {
    accessor: 'amount',
    Header: 'Cantidad',
    headerProps: {
      className: 'fw-medium text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { amount, description, subtotal } = rowData.row.original;
      let benefit = amount
        ? getCurrencyFormat(amount)
        : description.match(/^[^(]*/)[0];
      benefit =
        amount > 0 ? `+ ${benefit}` : amount < 0 ? `-${benefit}` : benefit;
      return (
        <>
          <span
            className={classNames('d-block fw-bold', {
              'text-success': amount > 0,
              'text-danger': amount < 0
            })}
          >
            {benefit}
          </span>
          <small>{getCurrencyFormat(subtotal)}</small>
        </>
      );
    }
  }
];

const Transactions = ({ bodyClassName }) => {
  const { transactions: data = [] } = useContext(BenefitsContext);
  const transactions = data
    .sort((t1, t2) => (t1.createdAt < t2.createdAt ? -1 : 1))
    .reduce((transactions, transaction) => {
      const { subtotal = 0 } = transactions[0] || {};
      const { amount } = transaction;
      return [{ ...transaction, subtotal: subtotal + amount }, ...transactions];
    }, [])
    .sort((t1, t2) => (t1.createdAt > t2.createdAt ? -1 : 1));

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={transactions}
        pagination
        perPage={20}
      >
        <Card.Body
          className={classNames(bodyClassName, {
            'p-0': transactions.length
          })}
        >
          {!transactions.length ? (
            <Alert variant="info">
              <h5 className="alert-heading">No hay transacciones</h5>
            </Alert>
          ) : (
            <AdvanceTable
              table
              headerClassName="d-none"
              rowClassName="align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          )}
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={transactions.length}
            table
            rowInfo
            navButtons
          />
        </Card.Footer>
      </AdvanceTableWrapper>
    </>
  );
};

Transactions.propTypes = {
  bodyClassName: PropTypes.string
};

export default Transactions;
