import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fromBlob, urlToBlob } from 'image-resize-compress';
import { ref, uploadBytes } from '@firebase/storage';
import { useStorage } from 'reactfire';
import { Card } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import Background from 'components/common/Background';
import FalconDropzone from 'components/common/FalconDropzone';
import { UserContext } from 'context/Context';

const ProfileBannerHeader = ({ id, avatar, coverSrc, className, name }) => {
  const { me, refreshCompanyAvatar } = useContext(UserContext);
  const [newAvatar, setNewAvatar] = useState();
  const storage = useStorage();

  const handleSelectAvatar = async (none, [file]) => {
    if (!id) {
      return;
    }
    urlToBlob(file);
    const compressedFile = await fromBlob(file, 80, 0, 0, 'png');
    setNewAvatar(compressedFile);
    try {
      const avatarRef = ref(storage, `companies/${id}/logo.png`);
      await uploadBytes(avatarRef, compressedFile);
      refreshCompanyAvatar();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card.Header
      className={classNames(className, 'position-relative min-vh-25 mb-7')}
    >
      <Background
        image={coverSrc || ''}
        className="rounded-3 rounded-bottom-0"
      />
      {id === me?.NO_ID_FIELD || me?.type === 'admin' ? (
        <FalconDropzone
          className="avatar-profile border-0 p-0 avatar-5xl rounded-circle"
          files={[newAvatar]}
          onChange={handleSelectAvatar}
          multiple={false}
          accept="image/*"
          placeholder={
            <div className="position-relative">
              <Avatar
                size="5xl"
                name={name?.trim() || ''}
                src={
                  newAvatar?.base64 ||
                  newAvatar?.src ||
                  (newAvatar && URL.createObjectURL(newAvatar)) ||
                  avatar
                }
                mediaClass="img-thumbnail shadow-sm"
              />
              <div className="position-absolute top-0 overflow-hidden p-0 avatar-5xl rounded-circle">
                <div className="position-absolute bottom-0 w-100 bg-black bg-opacity-50 pb-3">
                  <span className="fs--1 text-white">Editar</span>
                </div>
              </div>
            </div>
          }
        />
      ) : (
        <Avatar
          size="5xl"
          className="avatar-profile"
          src={avatar}
          name={name}
          mediaClass="img-thumbnail shadow-sm"
        />
      )}
    </Card.Header>
  );
};

const ProfileBannerBody = ({ children }) => {
  return <Card.Body className="position-relative">{children}</Card.Body>;
};

const ProfileBanner = ({ children }) => {
  return <Card className="mb-3">{children}</Card>;
};

ProfileBanner.Header = ProfileBannerHeader;
ProfileBanner.Body = ProfileBannerBody;

ProfileBannerHeader.propTypes = {
  id: PropTypes.string,
  avatar: PropTypes.string,
  coverSrc: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string
};

ProfileBannerBody.propTypes = {
  children: PropTypes.node.isRequired
};

ProfileBanner.propTypes = {
  children: PropTypes.node.isRequired
};

export default ProfileBanner;
