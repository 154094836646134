import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Lottie from 'lottie-react';
import { collection, orderBy, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { MAX_APPRECIATIONS } from 'components/company/appreciations/AppreciationProvider';
import SoftBadge from 'components/common/SoftBadge';
import Tooltip from 'components/common/Tooltip';
import FilesProvider from 'components/company/files/FilesProvider';
import { FeedbackContext, FilesContext, UserContext } from 'context/Context';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { GaugeChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import requirements from 'components/user/profile/requirements.json';
import check from 'assets/img/animated-icons/check-primary-light.json';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  GaugeChart,
  CanvasRenderer,
  LegendComponent
]);

export const BadgeCountAppreciations = props => {
  const db = useFirestore();
  const [thisMonth] = useState(new Date(new Date().setDate(1)));
  const { type } = props;
  const [value, setValue] = useState(0);
  const { company, me } = useContext(UserContext);
  let badgeQuery = query(collection(db, 'none'));
  if (company?.NO_ID_FIELD && me?.NO_ID_FIELD) {
    badgeQuery = query(
      collection(db, 'appreciations'),
      where('companyId', '==', company?.NO_ID_FIELD),
      where('senderId', '==', me?.NO_ID_FIELD),
      where('createdAt', '>=', thisMonth.toISOString())
    );
  }

  const { data: myAppreciations = [] } = useFirestoreCollectionData(badgeQuery);

  useEffect(() => {
    const badgeValue =
      MAX_APPRECIATIONS -
      myAppreciations.reduce((total, { value }) => total + value, 0);
    setValue(badgeValue);
  }, [myAppreciations?.length]);

  return (
    (value && (
      <SoftBadge pill bg={type} className="ms-2">
        <span className="fs--2">{value}</span>
      </SoftBadge>
    )) ||
    null
  );
};

BadgeCountAppreciations.propTypes = {
  type: PropTypes.string
};

export const BadgeCountCalendar = props => {
  const db = useFirestore();
  const { filterType, type } = props;
  const [value, setValue] = useState(0);
  const { company, me } = useContext(UserContext);
  let badgeQuery = query(collection(db, 'none'));

  if (company?.NO_ID_FIELD && me?.NO_ID_FIELD) {
    const filter = [filterType && where('type', '==', filterType)].filter(
      filter => filter
    );
    if (filterType === 'time-off') {
      filter.push(where('approved', '==', null));
    }

    badgeQuery = query(
      collection(db, 'calendar'),
      where('companyId', '==', company?.NO_ID_FIELD),
      ...filter
    );
  }

  const { data = [] } = useFirestoreCollectionData(badgeQuery);

  useEffect(() => {
    let _data = [...data];
    if (filterType === 'event') {
      _data = _data.filter(
        ({ assistance }) =>
          !assistance?.some(({ ref }) => ref?.path === me?.ref?.path)
      );
    }
    if (filterType === 'objective') {
      _data = _data.filter(
        ({ participants, start, state = 'on-course' }) =>
          (me?.type === 'admin' ||
            !participants ||
            participants.some(ref => ref?.path === me?.ref?.path)) &&
          (state === 'waiting' ||
            (start < new Date().toISOString() &&
              (!state || state === 'on-course')))
      );
    }
    if (filterType === 'time-off') {
      _data = _data.filter(
        ({ participants }) =>
          me?.type === 'admin' ||
          !participants ||
          participants.some(ref => ref?.path === me?.ref?.path)
      );
    }

    const badgeValue = _data.length;
    setValue(badgeValue);
  }, [data, me?.NO_ID_FIELD]);

  return (
    (value && (
      <SoftBadge pill bg={type} className="ms-2">
        <span className="fs--2">{value}</span>
      </SoftBadge>
    )) ||
    null
  );
};

BadgeCountCalendar.propTypes = {
  filterType: PropTypes.string,
  type: PropTypes.string
};

export const BadgeCountChat = props => {
  const { type = 'accent' } = props;
  const { me } = useContext(UserContext);
  const value =
    me?.unreadMessages?.reduce((all, { unread }) => all + unread, 0) || 0;

  return (
    (value && (
      <SoftBadge pill bg={type} className="ms-2">
        <span className="fs--2">{value}</span>
      </SoftBadge>
    )) ||
    null
  );
};

BadgeCountChat.propTypes = {
  type: PropTypes.string
};

export const BadgeCountFeedback = props => {
  const { type = 'accent' } = props;
  const { feedback } = useContext(FeedbackContext);
  const value = feedback?.filter(
    ({ feedback, status }) => feedback?.length > 4 && status !== 'closed'
  )?.length;

  return (
    (value && (
      <SoftBadge pill bg={type} className="ms-2">
        <span className="fs--2">{value}</span>
      </SoftBadge>
    )) ||
    null
  );
};

BadgeCountFeedback.propTypes = {
  type: PropTypes.string
};

const getOptions = ({ color = 'primary', data }) => ({
  series: [
    {
      animation: true,
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      radius: '100%',
      pointer: {
        show: false
      },
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
        itemStyle: {
          color: getColor(color)
        }
      },
      axisLine: {
        lineStyle: {
          width: 3,
          color: [[1, getColor('gray-300')]]
        }
      },
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      },
      data: [
        {
          value: data,
          detail: {
            show: false
          }
        }
      ]
    }
  ]
});

export const BadgeCountProfileWithProviders = ({ show, user }) => {
  const [value, setValue] = useState(0);
  const [tooltip, setTooltip] = useState([]);
  const { files } = useContext(FilesContext);
  const { me } = useContext(UserContext);
  user = user || me;
  const {
    avatar,
    birthday,
    description,
    lastQuestionnaireAt: questionaire,
    nif,
    phone,
    profile
  } = user || {};
  const {
    description: professionalDescription,
    interests,
    skills
  } = profile || {};
  const cv = files?.find(({ type }) => type === 'cv');
  const items = {
    avatar,
    birthday,
    cv,
    description,
    interests,
    nif,
    phone,
    professionalDescription,
    questionaire,
    skills
  };
  const { texts } = requirements;

  useEffect(() => {
    const completedItems =
      Object.entries(items).filter(([, value]) =>
        value && Array.isArray(value) ? value.length : value
      ) || [];
    const notCompletedItems =
      Object.entries(items).filter(([, value]) =>
        value && Array.isArray(value) ? !value.length : !value
      ) || [];
    const value = (completedItems.length * 100) / Object.entries(items).length;
    setValue(value);
    setTooltip(notCompletedItems.map(([key]) => key));
  }, [...Object.values(items)]);

  if (me?.NO_ID_FIELD !== user?.NO_ID_FIELD && me?.type !== 'admin') {
    return;
  }

  return (
    (typeof value === 'number' && (
      <div className="ms-2 d-inline-block">
        <Tooltip
          placement="right"
          title={
            <div className="text-start p-2">
              <h5 className="text-white m-0">{parseInt(value)}% completado</h5>
              {!!tooltip?.length && (
                <>
                  <hr className="opacity-50" />
                  <ul className="list-unstyled m-0">
                    {tooltip?.map(item => (
                      <li key={`Incomplete-${item}`} className="mb-2">
                        <FontAwesomeIcon
                          className="me-2"
                          icon="exclamation-triangle"
                        />
                        {texts[item] || ''}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          }
          tooltipClass="max-w-500px"
        >
          {value < 100 ? (
            <ReactEChartsCore
              echarts={echarts}
              className="m-auto"
              option={getOptions({ data: value })}
              style={{ height: '1rem', width: '1rem' }}
            />
          ) : (
            (show && (
              <Lottie
                animationData={check}
                className="mx-n3 my-n4 p-2 w-60px"
                loop={false}
              />
            )) ||
            []
          )}
        </Tooltip>
      </div>
    )) ||
    null
  );
};

BadgeCountProfileWithProviders.propTypes = {
  show: PropTypes.bool,
  user: PropTypes.object
};

export const BadgeCountProfile = props => {
  return (
    <FilesProvider>
      <BadgeCountProfileWithProviders {...props} />
    </FilesProvider>
  );
};

BadgeCountProfile.propTypes = {
  type: PropTypes.string
};

export const BadgeCountQuestionaires = props => {
  const db = useFirestore();
  const { type } = props;
  const [month] = useState(new Date().toISOString().match(/\d+-\d+/)[0]);
  const [value, setValue] = useState(0);
  const { company, me } = useContext(UserContext);
  let badgeQuery = query(collection(db, 'none'));
  let responsesQuery = query(collection(db, 'none'));
  let happyResponsesQuery = query(collection(db, 'none'));
  if (company?.NO_ID_FIELD && me?.NO_ID_FIELD) {
    const extra = [
      me?.type !== 'admin' && where('userId', '==', me?.NO_ID_FIELD)
    ].filter(filter => filter);
    const extraHappy = [
      me?.type !== 'admin' && orderBy('createdAt', 'desc'),
      me?.type === 'admin' && where('new', '==', true)
    ].filter(filter => filter);
    badgeQuery = query(
      collection(db, 'questionaires'),
      where('companyId', '==', company?.NO_ID_FIELD)
    );
    responsesQuery = query(
      collection(db, 'questionaires_responses'),
      where('companyId', '==', company?.NO_ID_FIELD),
      ...extra
    );
    happyResponsesQuery = query(
      collection(db, 'form_responses'),
      where('companyId', '==', company?.NO_ID_FIELD),
      where('createdAt', '>', month),
      ...extraHappy
    );
  }

  const { data: questionaires = [] } = useFirestoreCollectionData(badgeQuery);
  const { data: responses = [] } = useFirestoreCollectionData(responsesQuery);
  const { data: happyResponses = [] } =
    useFirestoreCollectionData(happyResponsesQuery);

  useEffect(() => {
    const badgeValue =
      me?.type === 'admin'
        ? responses.filter(({ new: isNew }) => isNew).length +
          happyResponses.length
        : questionaires.filter(
            ({ NO_ID_FIELD }) =>
              !responses.some(
                ({ questionaireId }) => questionaireId === NO_ID_FIELD
              )
          ).length +
          (1 -
            happyResponses.filter(({ userId }) => userId === me?.NO_ID_FIELD)
              .length);

    setValue(badgeValue);
  }, [responses?.length, happyResponses?.length, questionaires?.length]);

  return (
    (value && (
      <SoftBadge pill bg={type} className="ms-2">
        <span className="fs--2">{value}</span>
      </SoftBadge>
    )) ||
    null
  );
};

BadgeCountQuestionaires.propTypes = {
  type: PropTypes.string
};

export const BadgeCountReviews = props => {
  const db = useFirestore();
  const { type } = props;
  const [value, setValue] = useState(0);
  const { company, me, partners = [] } = useContext(UserContext);
  let badgeQuery = query(collection(db, 'none'));
  let responsesQuery = query(collection(db, 'none'));
  if (company?.NO_ID_FIELD && me?.NO_ID_FIELD) {
    badgeQuery = query(
      collection(db, 'reviews'),
      where('companyId', '==', company?.NO_ID_FIELD)
    );
    responsesQuery = query(
      collection(db, 'reviews_responses'),
      where('userId', '==', me?.NO_ID_FIELD)
    );
  }

  const { data: reviews = [] } = useFirestoreCollectionData(badgeQuery);
  const { data: responses = [] } = useFirestoreCollectionData(responsesQuery);

  useEffect(() => {
    const usersResponses = reviews
      .map(({ NO_ID_FIELD, type }) => {
        const reviewResponses = responses.filter(
          ({ reviewId }) => reviewId === NO_ID_FIELD
        );
        let users = [me];
        if (type === 'manager') {
          users = partners.filter(
            ({ department, level }) =>
              department === me?.department && level === me?.level - 1
          );
        } else if (type === 'employeeToManager') {
          users = partners.filter(
            ({ department, level }) =>
              department === me?.department && level === me?.level + 1
          );
        }
        return {
          users,
          responses: !users.length ? [] : reviewResponses
        };
      })
      .filter(value => value);

    const badgeValue = usersResponses.reduce(
      (total, { users, responses }) =>
        total + (users.length - responses.length),
      0
    );
    setValue(badgeValue);
  }, [responses?.length, partners?.length, reviews?.length]);

  return (
    (value && (
      <SoftBadge pill bg={type} className="ms-2">
        <span className="fs--2">{value}</span>
      </SoftBadge>
    )) ||
    null
  );
};

BadgeCountReviews.propTypes = {
  type: PropTypes.string
};
