import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  Dropdown,
  FormControl,
  InputGroup,
  Row
} from 'react-bootstrap';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { FeedbackContext } from 'context/Context';

const AllTicketsHeader = ({
  data,
  selectedRowIds,
  globalFilter,
  setGlobalFilter,
  layout,
  handleShow,
  handleTicketsSearch,
  selectedItems
}) => {
  const { setShowNewFeedbackModal, update } = useContext(FeedbackContext);

  const handleClose = () => {
    const selected = Object.keys(selectedRowIds || {}).map(row => data[row]);
    selected.forEach(feedback =>
      update(feedback, {
        status: 'closed'
      })
    );
  };

  return (
    <div className="d-lg-flex justify-content-between">
      <Row className="flex-between-center gy-2 px-x1">
        <Col xs="auto">
          {layout === 'table-view' ? (
            <AdvanceTableSearchBox
              className="w-350px"
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder="Buscar por nombre o sugerencia"
            />
          ) : (
            <InputGroup className="position-relative w-350px">
              <FormControl
                size="sm"
                id="search"
                type="search"
                className="shadow-none"
                placeholder="Buscar por nombre o sugerencia"
                onChange={e => handleTicketsSearch(e.target.value)}
              />
              <Button
                size="sm"
                variant="outline-secondary"
                className="border-300 hover-border-secondary"
              >
                <FontAwesomeIcon icon="search" className="fs--1" />
              </Button>
            </InputGroup>
          )}
        </Col>
      </Row>
      <div className="border-bottom border-200 my-3"></div>
      <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="filter"
          transform="shrink-4"
          iconAlign="middle"
          onClick={handleShow}
          className="d-xl-none"
        >
          <span className="d-none d-sm-inline-block ms-1">Filter</span>
        </IconButton>
        <div
          className="bg-300 mx-3 d-none d-lg-block d-xl-none"
          style={{ width: '1px', height: '29px' }}
        ></div>
        {(selectedRowIds && Object.keys(selectedRowIds).length > 0) ||
        (selectedItems && selectedItems.length > 0) ? (
          <Flex>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="check"
              transform="shrink-2"
              iconAlign="middle"
              className="mx-2"
              onClick={handleClose}
            >
              <span className="d-none d-md-inline-block ms-1">Cerrar</span>
            </IconButton>
          </Flex>
        ) : (
          <div id="orders-actions">
            <Dropdown
              align="end"
              className="btn-reveal-trigger d-inline-block me-2"
            >
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block me-1">
                  {layout === 'table-view' ? 'Tabla' : 'Tarjetas'}
                </span>
                <FontAwesomeIcon icon="chevron-down" transform="shrink-2" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Link
                    className={classNames('dropdown-item', {
                      active: layout === 'table-view'
                    })}
                    to="/feedback/table-view"
                  >
                    Tabla
                  </Link>
                  <Link
                    className={classNames('dropdown-item', {
                      active: layout === 'card-view'
                    })}
                    to="/feedback/card-view"
                  >
                    Tarjetas
                  </Link>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <IconButton
              variant="falcon-primary"
              size="sm"
              icon="plus"
              transform="shrink-3"
              iconAlign="middle"
              onClick={() => setShowNewFeedbackModal(true)}
            >
              <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                Nueva sugerencia
              </span>
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
};

AllTicketsHeader.propTypes = {
  data: PropTypes.array,
  selectedRowIds: PropTypes.object,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  handleShow: PropTypes.func,
  layout: PropTypes.string,
  selectedItems: PropTypes.array,
  handleTicketsSearch: PropTypes.func
};

export default AllTicketsHeader;
