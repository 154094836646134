import React from 'react';
import ProfileBanner from './Banner';
import { Col, Row } from 'react-bootstrap';
import ProfileSettings from './ProfileSettings';
import Admins from './Admins';
import BillingSettings from './BillingSettings';
import Invoices from './Invoices';

const Settings = () => {
  return (
    <>
      <ProfileBanner />
      <Row className="g-3">
        <Col lg={8}>
          <ProfileSettings />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <Admins />
            <BillingSettings />
            <Invoices />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Settings;
