import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card } from 'react-bootstrap';
import Background from 'components/common/Background';
import corner1 from 'assets/img/illustrations/corner-1.png';
import { UserReviewsContext, UserContext } from 'context/Context';

const ReviewIntro = ({ review }) => {
  const { user } = useContext(UserReviewsContext);
  const { me } = useContext(UserContext);
  const { title: rawTitle, description: rawDescription } = review;
  const title = rawTitle?.replace(/{{([^}]+)}}/g, (s, key) => user?.[key]);
  const description = rawDescription?.replace(
    /{{([^}]+)}}/g,
    (s, key) => user?.[key]
  );

  return (
    <Card
      className={classNames('mb-3 py-2', {
        'shadow-none': me?.type === 'admin'
      })}
    >
      <Background image={corner1} className="p-x1 bg-card" />
      <Card.Header className="pb-0">
        <h3 className="mb-0">{title}</h3>
      </Card.Header>
      <Card.Body>
        <span>{description}</span>
      </Card.Body>
    </Card>
  );
};

ReviewIntro.propTypes = {
  review: PropTypes.object
};

export default ReviewIntro;
