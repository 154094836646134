import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {
  Button,
  Card,
  Col,
  Collapse,
  ListGroup,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import { download, getCurrencyFormat } from 'helpers/utils';
import cloudDownload from 'assets/img/icons/cloud-download.svg';

const InvoiceItem = ({ id, date, docNumber, products, total }) => {
  const [isLoading, setLoading] = useState(false);

  const getInvoicePdf = async () => {
    setLoading(true);
    const blob = await (
      await fetch(
        `${process.env.REACT_APP_FIREBASE_URL}/getInvoicePdf?documentId=${id}`
      )
    ).blob();
    setLoading(false);
    download(blob, `Happyfy Factura ${docNumber}.pdf`);
  };

  return (
    <ListGroup.Item className="mx-0 bg-light px-0 fs--1" as={Row}>
      <Flex className="p-0" alignItems="center" justifyContent="between">
        <Col xs={8} className="p-0">
          <h6 className="m-0">
            <span>{products[0]?.name}</span> <span>x{products[0]?.units}</span>
          </h6>
          <span className="text-500">
            {dayjs(date * 1000).format('D MMMM YYYY')}
          </span>
        </Col>
        <Col xs={3} as={Flex} alignItems="center" justifyContent="end">
          <span className="me-1">{getCurrencyFormat(total)}</span>
        </Col>
        <Col xs={1} as={Flex} alignItems="center" justifyContent="end">
          <OverlayTrigger
            overlay={<Tooltip className="position-absolute">Descargar</Tooltip>}
          >
            <Button
              className="p-0"
              size="sm"
              variant="link"
              onClick={!isLoading ? getInvoicePdf : null}
            >
              {isLoading ? (
                <Spinner
                  size="sm"
                  variant="secondary"
                  style={{ width: '1rem', height: '1rem' }}
                />
              ) : (
                <img src={cloudDownload} alt="Download" width={15} />
              )}
            </Button>
          </OverlayTrigger>
        </Col>
      </Flex>
    </ListGroup.Item>
  );
};

const Invoices = () => {
  const { company } = useContext(UserContext);
  const [invoices, setInvoices] = useState([]);
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!company?.NO_ID_FIELD) {
      return;
    }
    (async () => {
      try {
        const { data = [] } = await (
          await fetch(
            `${process.env.REACT_APP_FIREBASE_URL}/getInvoices?companyId=${company?.NO_ID_FIELD}`,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
        ).json();
        setInvoices(data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [company?.NO_ID_FIELD]);

  const sortedInvoices = invoices
    ?.filter?.(({ tags }) => tags.includes('basic'))
    .sort((i1, i2) => (i1.date > i2.date ? -1 : 1));

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Facturas" />
      <Card.Body className="bg-light pb-0">
        <div>
          {sortedInvoices?.length ? (
            <>
              <ListGroup variant="flush">
                {sortedInvoices.slice(0, 3).map(invoice => (
                  <InvoiceItem key={`Invoice-${invoice.id}`} {...invoice} />
                ))}
              </ListGroup>
              <Collapse in={isExpanded}>
                <ListGroup className="border-top" variant="flush">
                  {sortedInvoices.slice(3).map(invoice => (
                    <InvoiceItem key={`Invoice-${invoice.id}`} {...invoice} />
                  ))}
                </ListGroup>
              </Collapse>
              <hr className="mt-0" />
            </>
          ) : (
            <p>-</p>
          )}
        </div>
      </Card.Body>
      <Card.Footer className="bg-light py-0 text-center">
        <Button
          variant="link"
          size="sm"
          className="py-2"
          onClick={() => setExpanded(isExpanded => !isExpanded)}
        >
          {isExpanded ? 'Ver menos' : 'Ver más'}
          <FontAwesomeIcon
            icon={isExpanded ? 'chevron-up' : 'chevron-down'}
            className="fs--2 ms-1"
          />
        </Button>
      </Card.Footer>
    </Card>
  );
};

InvoiceItem.propTypes = {
  id: PropTypes.string,
  date: PropTypes.number,
  docNumber: PropTypes.string,
  products: PropTypes.array,
  total: PropTypes.number
};

export default Invoices;
