import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const Loader = ({ className, style }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      className={classNames('w-100 h-100', className)}
    >
      <Spinner
        animation="grow"
        size="sm"
        style={{ animationDelay: '-0.1s', ...style }}
      />
      <Spinner animation="grow" size="sm" className="mx-1" style={style} />
      <Spinner
        animation="grow"
        size="sm"
        style={{ animationDelay: '0.1s', ...style }}
      />
    </Flex>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};

export default Loader;
