import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Button, Form, Image, ProgressBar } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { Typewriter } from 'react-simple-typewriter';
import { toast } from 'react-toastify';
import SplitLayout from 'layouts/SplitLayout';
import Flex from 'components/common/Flex';
import Tooltip from 'components/common/Tooltip';
import AppContext, { UserContext } from 'context/Context';
import bgImg from 'assets/img/generic/home.jpg';
import logo from 'assets/img/logo.png';

const InitLandingContent = () => {
  const { createCompany, partners, register, updateUser } =
    useContext(UserContext);
  const navigate = useNavigate();
  const [companyId, setCompanyId] = useState();
  const [doneSteps, setDoneSteps] = useState([]);
  const [stepState, setStep] = useState('start');
  const [formData, setFormData] = useState({});
  const { company, email, employees = [] } = formData;
  const user = JSON.parse(Cookies.get('__fbu') || '{}');

  const handleChange = ({ target }) => {
    let { name, value } = target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeEmployees = ({ index, value }) => {
    const _employees = [...employees];
    _employees[index] = {};
    _employees[index].email = value;
    if (!value.length) {
      _employees.splice(index, 1);
    }
    setFormData({ ...formData, employees: _employees });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setStep(0);
  };

  const handleGoToPanel = async e => {
    e.preventDefault();
    navigate('/#welcome');
  };

  const onSubmit = async () => {
    try {
      if (doneSteps.includes(stepState) || !company || !email) {
        return;
      }
      const password = uuid();
      const [name = ''] = email?.match(/[^@]*/) || [];
      await register(email, password, {
        name,
        redirect: false,
        showToast: false
      });
      setDoneSteps(steps => [...steps, stepState]);
      setStep(step => (typeof step === 'number' ? step + 1 : step));
    } catch (error) {
      console.error(error);
      setStep('start');
    }
  };

  const onRegister = async () => {
    try {
      if (
        doneSteps.includes(stepState) ||
        !company ||
        !email ||
        user?.email !== email
      ) {
        return;
      }
      const companyRef = await createCompany({
        apps: ['calendar', 'feedback', 'questionaires', 'time-off'],
        email,
        name: company,
        parentCompanyId: 'FiUzQw1Qn7bEO6ERtkae'
      });
      const { id: companyId } = companyRef;
      setCompanyId(companyId);
      const [name] = email.match(/[^@]*/);
      await updateUser(user, {
        id: user.uid,
        company: companyId,
        company_name: company,
        email,
        firstname: name,
        name,
        type: 'admin'
      });
      setDoneSteps(steps => [...steps, stepState]);
      setStep(step => (typeof step === 'number' ? step + 1 : step));
    } catch (error) {
      console.error(error);
      toast.error(`Ha habido un error`);
      setStep('start');
    }
  };

  const createSubscription = async () => {
    try {
      if (
        doneSteps.includes(stepState) ||
        !company ||
        !email ||
        user?.email !== email
      ) {
        return;
      }
      const quantity = (employees?.length || 0) + 1;
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_URL}/createInitSubscription`,
        {
          method: 'post',
          body: JSON.stringify({ email, name: company, quantity })
        }
      );
      if (response.status !== 200) {
        throw Error();
      }
      setDoneSteps(steps => [...steps, stepState]);
      setStep(step => (typeof step === 'number' ? step + 1 : step));
    } catch (error) {
      console.error(error);
      toast.error(`Ha habido un error`);
      setStep('start');
    }
  };

  const createEmployees = async () => {
    try {
      if (doneSteps.includes(stepState) || !companyId || !email) {
        return;
      }
      let _employees =
        employees?.map(user => {
          const [name = ''] = user?.email?.match(/[^@]*/) || [];
          return {
            ...user,
            name,
            company: companyId,
            company_name: company
          };
        }) || [];
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_URL}/createEmployees`,
        {
          method: 'post',
          body: JSON.stringify({ employees: _employees })
        }
      );
      if (response.status !== 200) {
        throw Error();
      }
      setDoneSteps(steps => [...steps, stepState]);
      setStep(step => (typeof step === 'number' ? step + 1 : step));
    } catch (error) {
      console.error(error);
      toast.error(`Ha habido un error`);
      setStep('start');
    }
  };

  const sendHappyQuestionaires = async () => {
    try {
      if (
        doneSteps.includes(stepState) ||
        !email ||
        user?.email !== email ||
        partners?.length !== employees?.length + 1
      ) {
        return;
      }
      await Promise.all(
        partners.map(async partner => {
          try {
            const { NO_ID_FIELD } = partner;
            await fetch(
              `${process.env.REACT_APP_FIREBASE_URL}/sendHappyQuestionaire?userId=${NO_ID_FIELD}`
            );
          } catch (error) {
            console.error(error);
          }
          return;
        })
      );
      setDoneSteps(steps => [...steps, stepState]);
      setStep('end');
    } catch (error) {
      toast.error(`Ha habido un error al enviar el cuestionario`);
    }
  };

  const steps = [
    {
      text: 'Registrando usuario',
      handle: onSubmit
    },
    {
      text: 'Creando empresa',
      handle: onRegister
    },
    {
      text: 'Creando periodo de prueba',
      handle: createSubscription
    },
    {
      text: 'Creando empleados',
      handle: createEmployees
    },
    {
      text: 'Enviando cuestionario a los empleados'
    }
  ];

  useEffect(() => {
    steps?.[stepState]?.handle?.();
  }, [stepState]);

  useEffect(() => {
    partners?.length &&
      partners?.length === (employees?.length || 0) + 1 &&
      sendHappyQuestionaires();
  }, [partners?.length]);

  return (
    <Form onSubmit={handleSubmit}>
      <div
        className={classNames({
          'opacity-0 pointer-none': stepState !== 'start'
        })}
      >
        <Form.Group className="mb-3" controlId="titleInput">
          <Form.Label className="fs-0">Nombre de la empresa</Form.Label>
          <Form.Control
            type="text"
            name="company"
            required
            onChange={handleChange}
            placeholder="Happyfy"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="titleInput">
          <Form.Label className="fs-0">
            Tu email
            <Tooltip title="Es el email de tu cuenta. Creará un usuario de tipo administrador con el que podrás gestionar la configuración de tu empresa y la gestión de los empleados.">
              <FontAwesomeIcon icon="circle-question" className="ms-2" />
            </Tooltip>
          </Form.Label>
          <Form.Control
            type="email"
            name="email"
            required
            onChange={handleChange}
            placeholder="mi@email.com"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="optionsInput">
          <Form.Label className="fs-0">
            Emails de los empleados
            <Tooltip title="Se creará un usuario de tipo empleado por cada email que pongas, al que se le enviará un correo para hacer un cuestionario de clima laboral.">
              <FontAwesomeIcon icon="circle-question" className="ms-2" />
            </Tooltip>
          </Form.Label>
          <div className="input-group-vertical mb-3">
            {[...(employees?.length ? employees : ['']), ''].map(
              (option, index) => {
                const { text } = option || {};
                return (
                  <Form.Control
                    key={`New-Questionaire-Option-${index}`}
                    type="email"
                    name={`employees-${index}`}
                    required={index < employees.length - 1}
                    value={text}
                    onChange={({ target }) => {
                      const { value } = target;
                      handleChangeEmployees({ index, value });
                    }}
                    placeholder="direccion@email.com"
                  />
                );
              }
            )}
          </div>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            id="acceptCheckbox"
            className="form-check"
          >
            <Form.Check.Input
              type="checkbox"
              name="isAccepted"
              checked={formData?.isAccepted || false}
              onChange={e =>
                setFormData({
                  ...formData,
                  isAccepted: e.target.checked
                })
              }
              required
            />
            <Form.Check.Label className="form-label">
              Acepto los{' '}
              <Link to="/terms" target="_blank">
                términos
              </Link>{' '}
              y la{' '}
              <Link to="/privacy-policy" target="_blank">
                política de privacidad
              </Link>
            </Form.Check.Label>
          </Form.Check>
        </Form.Group>
      </div>
      <div className="text-center mt-3">
        {(stepState === 'start' && (
          <Button variant="primary" type="submit" className="px-4 mx-0">
            Pruébalo gratis
          </Button>
        )) || (
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            className="position-absolute text-center start-0 top-0 pt-6 w-100 h-100"
          >
            {(stepState !== 'end' && (
              <>
                {steps.map(({ text }, index) => (
                  <h6
                    key={`Step-${index}`}
                    className={classNames({
                      'text-400': index > stepState,
                      'fw-bold text-primary': index === stepState
                    })}
                  >
                    {text}
                  </h6>
                ))}
                <ProgressBar
                  now={((stepState + 1) * 100) / steps.length}
                  animated
                  className="rounded-pill mt-4 mx-6 w-50"
                />
              </>
            )) || (
              <>
                <h4 className="mb-3">¡Todo listo!</h4>
                <Button
                  variant="success"
                  className="px-4 mx-0"
                  onClick={handleGoToPanel}
                >
                  Accede al panel
                </Button>
              </>
            )}
          </Flex>
        )}
      </div>
    </Form>
  );
};

const InitLanding = () => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  return (
    <SplitLayout
      bgProps={{
        image: bgImg,
        overlay: '1',
        className: 'bg-position-start blur-3px',
        style: {
          transform: 'scale(1.01)',
          transformOrigin: 'top left',
          margin: '-6px -3px'
        }
      }}
      content={
        <>
          <h1 className="text-white text-center fw-light position-relative">
            Para mis
            <br />
            trabajadores quiero
            <br />
            <span className="fw-bold">
              <Typewriter
                words={['bienestar', 'motivación', 'lo mejor', 'happyfy']}
                loop={1}
                cursor={!isRTL ? true : false}
                cursorStyle="|"
                typeSpeed={100}
                deleteSpeed={100}
                delaySpeed={2000}
              />
            </span>
          </h1>
          <Image src={logo} className="position-relative w-100px mt-3" />
        </>
      }
    >
      <InitLandingContent />
    </SplitLayout>
  );
};

export default InitLanding;
