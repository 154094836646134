import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import AppContext, { ProductContext } from 'context/Context';

const Bottombar = ({ product, inViewport }) => {
  const {
    config: { navbarCollapsed }
  } = useContext(AppContext);
  const { handleRequest } = useContext(ProductContext);

  return (
    <Card
      className={`bottom-bar rounded-0 d-lg-none ${
        inViewport || navbarCollapsed ? 'hide' : 'show'
      }`}
    >
      <Card.Body className="py-2">
        <Button
          variant="primary"
          size="lg"
          className="w-100 fs-0 mt-1"
          onClick={() => handleRequest(product)}
        >
          Solicitar activación
        </Button>
      </Card.Body>
    </Card>
  );
};

Bottombar.propTypes = {
  product: PropTypes.object,
  inViewport: PropTypes.bool
};

export default Bottombar;
