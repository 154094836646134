import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, ListGroup, ProgressBar } from 'react-bootstrap';
import UAParser from 'ua-parser-js';
import DatePicker from 'react-datepicker';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import { GridComponent, TooltipComponent } from 'echarts/components';
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import Popover from 'components/common/Popover';
import SoftBadge from 'components/common/SoftBadge';
import Tooltip from 'components/common/Tooltip';
import GoogleMap from 'components/map/GoogleMap';
import timeOffSubtypes from 'components/user/time-off/subtypes.json';
import { TimeControlContext, UserContext } from 'context/Context';
import {
  getColor,
  getDistance,
  getUnique,
  groupBy,
  rgbaColor
} from 'helpers/utils';
import dayjs from 'dayjs';

echarts.use([
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  SVGRenderer
]);

const defaultMaxTime = 8 * 3600;

export const ActionsHeader = ({ children }) => {
  const { me } = useContext(UserContext);
  return (
    <Flex alignItems="center" justifyContent="between">
      <div>{children}</div>
      {me?.type === 'admin' && (
        <div>
          <AdvanceTableSearchBox className="w-300px" placeholder="Buscar..." />
        </div>
      )}
    </Flex>
  );
};

ActionsHeader.propTypes = {
  children: PropTypes.node
};

export const Alerts = ({
  records,
  day,
  days: daysProp,
  holiday,
  isHoliday,
  isTimeOff,
  maxSeconds,
  startTime,
  timeOff,
  user
}) => {
  const userId = user?.NO_ID_FIELD;
  const alerts = [];
  const days = daysProp || [
    {
      day,
      holiday,
      isHoliday,
      isTimeOff,
      maxSeconds,
      records,
      startTime,
      timeOff
    }
  ];
  const [today] = new Date().toISOString().match(/\d{4}-\d{2}-\d{2}/);

  const absentDays = days?.filter(({ isTimeOff, maxSeconds, records }) => {
    return !isTimeOff && maxSeconds && !records?.length;
  });
  if (absentDays?.length) {
    alerts.push({
      badge: absentDays?.length,
      icon: 'user-times',
      text: 'No hay fichajes'
    });
  }

  const medicalDates = days?.filter(
    ({ timeOff }) => timeOff?.subtype === 'medical'
  );
  if (medicalDates?.length) {
    alerts.push({
      badge: medicalDates?.length,
      icon: 'user-doctor',
      text: 'Ha tenido citas médicas'
    });
  }

  const daysWithTooManyPauses = days?.filter(
    ({ records }) => records?.filter(({ type }) => type === 'in')?.length > 2
  );
  if (daysWithTooManyPauses?.length) {
    alerts.push({
      badge: daysWithTooManyPauses?.length,
      icon: 'stopwatch',
      text: 'Más de 1 pausas'
    });
  }

  const recordsWithoutExit = days?.filter(
    ({ isTimeOff, day, records, startTime }) => {
      const lastRecord = [...(records || [])].pop();
      return (
        startTime &&
        !isTimeOff &&
        maxSeconds > 0 &&
        day !== today &&
        lastRecord?.type !== 'out' &&
        (!absentDays ||
          !absentDays.some(({ day: absentDay }) => absentDay === day))
      );
    }
  );
  if (recordsWithoutExit?.length) {
    alerts.push({
      badge: recordsWithoutExit?.length,
      icon: 'arrow-right-from-bracket',
      text: 'No hay fichaje de salida'
    });
  }

  const recordsWithoutLocation = records?.filter(({ location }) => !location);
  if (recordsWithoutLocation?.length) {
    alerts.push({
      badge: recordsWithoutLocation?.length,
      icon: 'map-marker-alt',
      text: 'Hay fichajes sin localización'
    });
  }

  const mobileRecords = records?.filter(({ info }) => info?.mobile);
  if (mobileRecords?.length) {
    alerts.push({
      badge: mobileRecords?.length,
      icon: 'mobile-alt',
      text: 'Fichaje desde el móvil'
    });
  }

  const remoteRecords = records?.filter(({ location }) => {
    const { location: locationObj } = user || {};
    const { latlng: companyLocation } = locationObj || {};
    const distance =
      location && companyLocation ? getDistance(location, companyLocation) : 0;
    return distance > 80;
  });
  if (remoteRecords?.length) {
    alerts.push({
      badge: remoteRecords?.length,
      icon: 'route',
      text: 'Fichaje fuera de la oficina',
      location:
        !!remoteRecords?.length && remoteRecords.map(({ location }) => location)
    });
  }

  return (
    <Flex direction="column" alignItems="end">
      {alerts.map(({ badge = 0, icon, text, location }, index) => (
        <SoftBadge
          bg="accent"
          key={`Alert-${userId}-${index}`}
          className={classNames(
            'bg-transparent position-relative mb-1 shadow-none'
          )}
          pill
        >
          {text}
          {badge > 0 && (
            <Popover
              content={
                location && (
                  <Flex>
                    {location.map((location, index) => (
                      <GoogleMap
                        key={`${JSON.stringify(location)}-${index}`}
                        initialCenter={location}
                        zoom={14}
                        className="d-flex flex-column rounded-soft position-relative"
                        mapClassName="flex-grow-1"
                        options={{
                          mapTypeControl: false,
                          streetViewControl: false,
                          fullscreenControl: false
                        }}
                        style={{
                          minWidth: '30.75rem',
                          minHeight: '18.75rem'
                        }}
                      />
                    ))}
                  </Flex>
                )
              }
            >
              <SoftBadge
                bg="accent"
                className="cursor-pointer fs--2 ms-2 me-n1"
                pill
              >
                <FontAwesomeIcon icon={icon} className="me-1 w-16px" />
                <span className="d-inline-block w-16px">{badge}</span>
              </SoftBadge>
            </Popover>
          )}
        </SoftBadge>
      ))}
    </Flex>
  );
};

Alerts.propTypes = {
  day: PropTypes.string,
  days: PropTypes.array,
  holiday: PropTypes.object,
  isHoliday: PropTypes.bool,
  isTimeOff: PropTypes.bool,
  maxSeconds: PropTypes.number,
  records: PropTypes.array,
  startTime: PropTypes.string,
  timeOff: PropTypes.object,
  user: PropTypes.object
};

export const DayBalance = props => {
  const { day } = props;

  if (!day) {
    return null;
  }
  const days = [props];
  return <DaysBalance {...props} days={days} />;
};

DayBalance.propTypes = {
  day: PropTypes.string,
  endTime: PropTypes.string,
  records: PropTypes.array
};

export const DaysBalance = ({ days }) => {
  const balance = days.reduce(
    (
      total,
      { endTime, holiday, maxSeconds, records: dayRecords = [], timeOff }
    ) => {
      const { subtype } = timeOff || {};
      const subtypeData =
        (subtype && timeOffSubtypes.find(({ value }) => value === subtype)) ||
        {};
      maxSeconds = holiday || subtypeData.extra ? 0 : maxSeconds;
      const lastRecord = [...(dayRecords || [])].pop();
      const isToday =
        !!lastRecord &&
        new Date(lastRecord?.createdAt)
          .toISOString()
          .match(/\d{4}-\d{2}-\d{2}/)?.[0] ===
          new Date().toISOString().match(/\d{4}-\d{2}-\d{2}/)?.[0];
      const isWorking = isToday && lastRecord?.type !== 'out';
      const lastRecordAt = isWorking
        ? new Date()
        : lastRecord?.type === 'out'
        ? new Date(lastRecord?.createdAt || endTime)
        : new Date(endTime);
      const { diff } = dayRecords?.reduce(
        ({ diff, prev }, { createdAt, type }) => {
          const time =
            type === 'in' ? dayjs(prev).diff(createdAt, 'seconds') || 0 : 0;
          return {
            diff: diff + time,
            prev: new Date(createdAt)
          };
        },
        { diff: 0, prev: lastRecordAt }
      ) || { diff: 0 };
      let balance = diff - maxSeconds;
      if (timeOff) {
        balance = subtypeData?.extra === false && balance < 0 ? 0 : balance;
      }
      return total + balance;
    },
    0
  );

  let totalSeconds = Math.abs(balance);
  let hours = Math.floor(totalSeconds / 3600) || 0;
  totalSeconds = totalSeconds - hours * 3600;
  let minutes = Math.floor(totalSeconds / 60) || 0;
  totalSeconds = totalSeconds - minutes * 60;
  if (totalSeconds >= 60) {
    minutes += 1;
    if (minutes >= 60) {
      minutes = 0;
      hours += 1;
    }
  }
  const sign = !hours && !minutes ? '' : balance < 0 ? '-' : '+';

  return !sign ? null : (
    <div>
      <strong>
        {sign} {hours}h {minutes}m
      </strong>
    </div>
  );
};

DaysBalance.propTypes = {
  days: PropTypes.array
};

export const Device = React.memo(
  ({ record }) => {
    const { me } = useContext(UserContext);
    const { info } = record;

    if (me.type !== 'admin' || !info) {
      return null;
    }

    const { mobile, platform, userAgent } = info;
    const parser = new UAParser(userAgent);
    const browser = parser.getBrowser();
    const device = parser.getDevice();

    return (
      <Popover
        content={
          <>
            <h5 className="mb-2">Info</h5>
            <h6 className="mb-0">Navegador</h6>
            {Object.values(browser)
              .filter(data => data)
              .join(', ')}
            <h6 className="mb-0 mt-2">Dispositivo</h6>
            {[
              ...new Set([
                mobile ? 'Móvil' : 'Escritorio',
                ...(Object.values(device)?.filter(data => data) || []),
                platform
              ])
            ].join(', ')}
          </>
        }
      >
        <FontAwesomeIcon
          className="position-relative opacity-50 hover-opacity-100 cursor-pointer ms-1 z-index-1016"
          icon={classNames({
            'mobile-alt': mobile,
            desktop: !mobile
          })}
        />
      </Popover>
    );
  },
  () => true
);

Device.propTypes = {
  record: PropTypes.object
};

const tooltipFormatter = params => {
  const { name, value } = params[0];
  const label = dayjs(name)
    .calendar(null, {
      sameDay: '[Hoy]',
      lastDay: '[Ayer]',
      lastWeek: 'dddd',
      sameElse: 'dddd, D MMMM'
    })
    .replace('.', '');
  let totalSeconds = value;
  const hours = Math.floor(totalSeconds / 3600) || 0;
  totalSeconds = totalSeconds - hours * 3600;
  const minutes = Math.floor(totalSeconds / 60) || 0;
  totalSeconds = totalSeconds - minutes * 60;

  return `
    <div>
      <h6 class="text-200 mb-0 d-flex align-items-center">
        <span class="text-capitalize">${label}</span>:<strong class="text-white ms-2">${hours}h ${minutes}m</strong>
      </h6>
    </div>
  `;
};

const barWidth = 8;
const barGap = 2;
const getOptions = (
  { color: colorProp = 'primary', data, labels },
  options = {}
) => ({
  ...(options || {}),
  tooltip: {
    // confine: 'true',
    formatter: tooltipFormatter,
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
      axis: 'auto',
      animation: 'auto',
      animationDurationUpdate: 200,
      animationEasingUpdate: 'exponentialOut'
    },
    x: 0,
    y: 0,
    z: 60,
    show: true,
    showContent: true,
    triggerOn: 'mousemove|click',
    alwaysShowContent: false,
    displayMode: 'single',
    renderMode: 'auto',
    confine: null,
    showDelay: 0,
    hideDelay: 100,
    transitionDuration: 0.4,
    enterable: false,
    backgroundColor: rgbaColor(getColor('dark'), 0.8),
    // shadowBlur: 10,
    // shadowColor: rgba(0, 0, 0, 0.2),
    // shadowOffsetX: 1,
    // shadowOffsetY: 2,
    borderRadius: 4,
    borderWidth: 0,
    padding: null,
    ...(options?.tooltip || {})
  },
  xAxis: {
    type: 'category',
    axisLabel: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLine: {
      show: false
    },
    data: labels,
    ...(options?.xAxis || {})
  },
  yAxis: {
    type: 'value',
    scale: false,
    boundaryGap: false,
    axisLabel: {
      show: false
    },
    splitLine: {
      show: false
    },
    min: 0,
    ...(options?.yAxis || {})
  },
  series: [
    {
      type: 'bar',
      barCategoryGap: `${barGap}px`,
      barWidth: `${barWidth}px`,
      data: data.map(({ borderRadius, value, maxSeconds }) => {
        let color = colorProp;
        let borderColor = getColor('primary');
        let borderWidth = 0;
        if (value > maxSeconds + 60) {
          color = 'warning';
        }
        return {
          itemStyle: {
            borderColor,
            borderRadius,
            borderWidth,
            color: getColor(color)
          },
          value
        };
      }),
      itemStyle: {
        borderRadius: [barWidth, barWidth, 0, 0],
        color: getColor('gray-300')
      }
    }
  ],
  grid: {
    right: '0px',
    left: '0px',
    bottom: 0,
    top: 0,
    ...(options?.grid || {})
  }
});

export const HoursChart = ({ className, days }) => {
  const chartRef = useRef(null);
  const chartRef2 = useRef(null);
  const values =
    days?.map(({ day, maxSeconds, records = [] }) => {
      const lastRecord = [...(records || [])].pop();
      const lastRecordAt =
        lastRecord?.type === 'out'
          ? new Date(lastRecord?.createdAt)
          : new Date();
      const { diff: value } = records?.reduce(
        ({ diff, prev }, { createdAt, type }) => {
          const time =
            type === 'in' ? dayjs(prev).diff(createdAt, 'seconds') || 0 : 0;
          return {
            diff: diff + time,
            prev: new Date(createdAt)
          };
        },
        { diff: 0, prev: lastRecordAt }
      ) || { diff: 0 };
      return { day, maxSeconds, value };
    }) || [];
  let labels = values.map(({ day }) => day);
  let data = values;

  const options = {
    yAxis: {
      max: Math.max(...(days?.map(({ maxSeconds }) => maxSeconds) || [1])) - 1
    }
  };

  return (
    <div
      className={classNames('position-relative', className)}
      style={{
        height: '3rem',
        width: `${(data?.length || 0) * (barWidth + barGap)}px`
      }}
    >
      <ReactEChartsCore
        className="position-relative z-index-1016"
        ref={chartRef}
        echarts={echarts}
        option={getOptions({ data, labels }, options)}
        opts={{ renderer: 'svg' }}
        style={{
          height: '100%',
          width: '100%'
        }}
      />
      <ReactEChartsCore
        className="position-absolute bottom-0"
        ref={chartRef2}
        echarts={echarts}
        option={getOptions(
          {
            color: 'gray-300',
            data: data.map(() => ({
              borderRadius: 0,
              value: 2,
              maxSeconds: 2
            })),
            labels
          },
          {
            tooltip: false
          }
        )}
        style={{
          height: '2px',
          width: '100%'
        }}
      />
    </div>
  );
};

HoursChart.propTypes = {
  className: PropTypes.string,
  days: PropTypes.array
};

export const Location = React.memo(
  ({ record }) => {
    const { me } = useContext(UserContext);
    const { location } = record || {};

    if (me.type !== 'admin' || !location) {
      return null;
    }

    return (
      <Popover
        content={
          <>
            <h5 className="mb-2">Localización</h5>
            <GoogleMap
              initialCenter={location}
              zoom={14}
              className="d-flex flex-column rounded-soft position-relative"
              mapClassName="flex-grow-1"
              options={{
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false
              }}
              style={{
                minWidth: '30.75rem',
                minHeight: '18.75rem'
              }}
            />
          </>
        }
      >
        <FontAwesomeIcon
          className="position-relative opacity-50 hover-opacity-100 cursor-pointer ms-1 z-index-1016"
          icon="map-marker-alt"
        />
      </Popover>
    );
  },
  () => true
);

Location.propTypes = {
  record: PropTypes.object
};

export const DayTimeControl = ({
  day,
  isToday,
  records,
  startTime,
  endTime
}) => {
  const [, update] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(
      () =>
        update(time =>
          dayjs(time).diff(new Date(), 'minutes') ? new Date() : time
        ),
      1000
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!records?.length) {
    return null;
  }
  const now = new Date().toISOString();
  const maxTime = now < endTime ? now : endTime;
  const maxDuration = dayjs(endTime).diff(startTime, 'seconds');
  let recordsData = [...(records || [])].sort((r1, r2) =>
    r1.createdAt < r2.createdAt ? -1 : 1
  );

  const data = [
    {
      type: 'init',
      createdAt: startTime
    },
    ...recordsData,
    { type: 'end' }
  ].filter(data => data);

  const nextDay = new Date(day);
  nextDay.setDate(nextDay.getDate() + 1);

  return (
    <div className="position-relative">
      <Flex
        justifyContent="between"
        className="position-absolute w-100 top-100 mt-1"
      >
        {startTime && (
          <div className="badge-soft-secondary bg-transparent fs--2 fw-semi-bold p-0">
            <strong>{dayjs(startTime).format('H:mm')}</strong>
          </div>
        )}
        {endTime && (
          <div className="badge-soft-secondary bg-transparent fs--2 fw-semi-bold p-0">
            <strong>{dayjs(endTime).format('H:mm')}</strong>
          </div>
        )}
      </Flex>
      <ProgressBar
        min={new Date(startTime).getTime()}
        max={new Date(endTime).getTime()}
        className="bg-transparent shadow-none overflow-visible"
      >
        {data
          .map((record, index) => {
            const { createdAt, type } = record;
            const next = data[index + 1];
            let start = createdAt;
            let end =
              next?.createdAt || (isToday ? new Date().toISOString() : maxTime);

            if (isToday && type === 'end') {
              let prev = data[index - 1];
              prev = prev?.type === 'out' ? prev : new Date().toISOString();
              start = prev?.createdAt;
              end = endTime;
            }

            const value = dayjs(end).diff(start, 'seconds');
            const startStr = dayjs(start).format('H:mm');
            const endStr = dayjs(end).format('H:mm');

            return (
              <ProgressBar
                key={`Bar-Info-${index}`}
                label={
                  <Flex justifyContent="between">
                    {type === 'in' && (
                      <>
                        <div className="badge-soft-success bg-transparent fs--1 fw-semi-bold me-2 p-0">
                          <strong>{startStr}</strong>
                          <Device record={record} />
                          <Location record={record} />
                        </div>
                        {next && next?.type !== 'end' && (
                          <div className="badge-soft-yellow bg-transparent fs--1 fw-semi-bold p-0">
                            <strong>{endStr}</strong>
                            <Device record={next} />
                            <Location record={next} />
                          </div>
                        )}
                      </>
                    )}
                  </Flex>
                }
                className="rounded-0 overflow-visible min-w-fit-content"
                variant="transparent"
                min={0}
                max={maxDuration}
                now={value}
              />
            );
          })
          .filter(bar => bar)}
      </ProgressBar>
      <ProgressBar
        min={0}
        max={maxDuration}
        className="mt-1"
        style={{ height: 8 }}
      >
        {data
          .map((record, index) => {
            const { createdAt, type } = record;
            const next = data[index + 1];
            let start = createdAt;
            let end =
              next?.createdAt || (isToday ? new Date().toISOString() : maxTime);

            if (isToday && type === 'end') {
              let prev = data[index - 1];
              prev = prev?.type === 'out' ? prev : new Date().toISOString();
              start = prev?.createdAt;
              end = endTime;
            }

            const value = dayjs(end).diff(start, 'seconds');
            const isAnimated =
              isToday && next?.type === 'end' && ['in', 'out'].includes(type);
            return (
              <ProgressBar
                key={`Bar-${index}`}
                animated={isAnimated}
                className={classNames({
                  'rounded-start-0': index !== 1,
                  'rounded-end-0':
                    !isAnimated &&
                    index + (next?.type === 'out' ? 1 : 0) < data.length - 2
                })}
                variant={classNames({
                  success: type === 'in',
                  yellow: type === 'out' && next?.type !== 'end',
                  transparent:
                    type === 'init' || type === 'end' || next?.type === 'end'
                })}
                min={0}
                max={maxDuration}
                now={value}
              />
            );
          })
          .filter(bar => bar)}
      </ProgressBar>
    </div>
  );
};

DayTimeControl.propTypes = {
  day: PropTypes.string,
  isToday: PropTypes.bool,
  records: PropTypes.array,
  maxSeconds: PropTypes.number,
  startTime: PropTypes.string,
  endTime: PropTypes.string
};

const History = ({ data }) => {
  const { partners } = useContext(UserContext);
  return (
    <ListGroup variant="flush">
      {data?.map(({ author, createdAt, from, to }) => {
        const user = partners?.find(
          ({ NO_ID_FIELD }) => NO_ID_FIELD === author.id
        );
        const { avatar, name } = user || {};
        return (
          <ListGroup.Item
            key={`History-${createdAt}`}
            className="bg-transparent border-700 text-white"
          >
            <div className="text-500">
              {dayjs(createdAt)
                .calendar(null, {
                  sameDay: '[Hoy], H:mm',
                  lastDay: '[Ayer], H:mm',
                  lastWeek: 'dddd, H:mm',
                  sameElse: 'ddd, D MMM YYYY'
                })
                .replace('.', '')}
            </div>
            <Flex alignItems="center">
              <Avatar className="me-2" src={avatar} name={name} size="s" />
              <span>{name}</span>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="center"
              className="fw-bold w-100"
            >
              {from && <div>{dayjs(from).format('HH:mm')}</div>}
              {(from || to) && (
                <FontAwesomeIcon icon="arrow-right" className="mx-2" />
              )}
              {to && <div>{dayjs(to).format('HH:mm')}</div>}
            </Flex>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

History.propTypes = {
  data: PropTypes.array
};

export const DayTimeControlInputs = ({
  day,
  isToday,
  records = [],
  startTime,
  endTime,
  user
}) => {
  const { createEntry, updateEntry } = useContext(TimeControlContext);
  const { me } = useContext(UserContext);
  const [changes, setChanges] = useState([]);
  const [date, setDate] = useState();
  const { NO_ID_FIELD: userId } = user;
  const now = new Date().toISOString();
  const maxTime = now < endTime ? now : endTime;
  const recordsData = records;
  const lastRecord = [...recordsData].pop();
  const data = [
    {
      type: 'init',
      createdAt: startTime
    },
    ...getUnique(
      [
        ...recordsData.map(record => {
          const change = changes.find(
            ({ NO_ID_FIELD }) => NO_ID_FIELD === record?.NO_ID_FIELD
          );
          return change || record;
        }),
        ...changes
      ],
      'NO_ID_FIELD'
    ),
    { type: 'end' }
  ].filter(data => data);
  const entries = data;
  const nextDay = new Date(day);
  nextDay.setDate(nextDay.getDate() + 1);

  const handleBlur = async recordParam => {
    const recordData = recordsData.find(
      ({ NO_ID_FIELD }) => NO_ID_FIELD === recordParam.NO_ID_FIELD
    );
    const record = recordData || recordParam;
    const now = new Date().toISOString();
    const { createdAt: prevCreatedAt, history: _history = [] } = record;
    const defaultTime =
      (record?.type === 'in'
        ? startTime || '06:00:00'
        : endTime || '16:00:00'
      )?.match(/(\d{2}):(\d{2}):(\d{2})/) || [];

    const time =
      (date?.toISOString() || lastRecord?.createdAt)?.match(
        /(\d{2}):(\d{2}):(\d{2})/
      ) || defaultTime;
    let seconds = parseInt(time[3]) + 1;
    seconds = `${
      seconds < 10 ? `0${seconds}` : seconds >= 60 ? '00' : seconds
    }`;
    let minutes = parseInt(time[2]) + (parseInt(seconds) >= 60 ? 1 : 0);
    minutes = `${minutes < 10 ? `0${minutes}` : minutes}`;
    const hours = time[1];

    const createdAt = `${day}T${hours}:${minutes}:${seconds}.000Z`;

    const extraParams = {};
    if (!!recordData && prevCreatedAt) {
      extraParams.history = [
        ..._history,
        { author: me?.ref, createdAt: now, from: prevCreatedAt, to: createdAt }
      ];
    }

    setChanges(changes => {
      const updatedChanges = changes?.map(change =>
        change?.NO_ID_FIELD === record?.NO_ID_FIELD
          ? { ...record, ...extraParams, createdAt }
          : change
      );
      const newChange = !changes?.some(
        ({ NO_ID_FIELD }) => NO_ID_FIELD === record?.NO_ID_FIELD
      ) && { ...record, ...extraParams, createdAt };
      return [...updatedChanges, newChange].filter(change => change);
    });
  };

  const handleChange = async record => {
    let { createdAt, history, type } = record;
    if (!createdAt) {
      const [defaultTime] =
        (record?.type === 'in' ? startTime : endTime)?.match(
          /\d{2}:\d{2}:\d{2}/
        ) || [];
      const [time = defaultTime] =
        date?.toISOString().match(/\d{2}:\d{2}:\d{2}/) || [];
      createdAt = `${day}T${time}.000Z`;
    }
    records?.some(({ NO_ID_FIELD }) => NO_ID_FIELD === record?.NO_ID_FIELD)
      ? await updateEntry(record, { createdAt, history, type })
      : await createEntry({ createdAt, type, userId });
  };

  const handleCancel = async () => {
    setDate();
    setChanges([]);
  };

  const handleSave = async () => {
    await Promise.all(changes?.map(record => handleChange(record)));
    setDate();
    setChanges([]);
  };

  return (
    <div className="position-relative">
      {entries
        ?.map((recordData, index) => {
          const record =
            (data?.length > 2 &&
              !['init', 'end'].includes(recordData.type) &&
              changes?.find(
                ({ NO_ID_FIELD }) => NO_ID_FIELD === recordData.NO_ID_FIELD
              )) ||
            recordData;
          const { createdAt, type, history: startHistory } = record;
          if (type !== 'in') {
            return;
          }

          const next = entries[index + 1] || data[index + 1];
          let start = createdAt || '';
          const { history: endHistory } = next || {};
          let end =
            next?.createdAt || (isToday ? new Date().toISOString() : maxTime);
          if (isToday && type === 'end') {
            let prev = entries[index - 1] || data[index - 1];
            prev = prev?.type === 'out' ? prev : new Date().toISOString();
            start = prev?.createdAt || '';
            end = endTime || '';
          }

          return (
            <Flex
              key={`Time-Info-${index}`}
              alignItems="center"
              justifyContent="center"
              className="mb-2"
            >
              {startHistory && (
                <Tooltip
                  className="mt-n5"
                  tooltipClass="max-w-500px"
                  title={<History data={startHistory} />}
                >
                  <div className="position-absolute ms-n3 z-index-2">
                    <SoftBadge pill>
                      <FontAwesomeIcon icon="pen" />
                    </SoftBadge>
                  </div>
                </Tooltip>
              )}
              <DatePicker
                disabled={me?.type !== 'admin'}
                selected={start && new Date(start)}
                showTimeSelect
                showTimeSelectOnly
                showPopperArrow={false}
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="HH:mm"
                placeholderText="--:--"
                onCalendarClose={() =>
                  handleBlur({
                    ...record,
                    NO_ID_FIELD: record?.NO_ID_FIELD || uuid(),
                    userId: record?.userId || userId,
                    type: 'in'
                  })
                }
                onChange={date => setDate(date)}
                className="form-control form-control-sm w-70px text-center"
              />
              <FontAwesomeIcon icon="arrow-right" className="mx-2" />
              <DatePicker
                disabled={me?.type !== 'admin'}
                selected={end && new Date(end)}
                showTimeSelect
                showTimeSelectOnly
                showPopperArrow={false}
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="HH:mm"
                placeholderText="--:--"
                onCalendarClose={() =>
                  handleBlur({
                    ...next,
                    NO_ID_FIELD: next?.NO_ID_FIELD || uuid(),
                    userId: next?.userId || userId,
                    type: 'out'
                  })
                }
                onChange={date => setDate(date)}
                className={classNames(
                  'form-control form-control-sm w-70px text-center',
                  {
                    'text-danger': !next?.createdAt
                  }
                )}
              />
              {endHistory && (
                <Tooltip
                  className="mt-n5"
                  tooltipClass="max-w-500px"
                  title={<History data={endHistory} />}
                >
                  <div className="position-absolute ms-n3 z-index-2">
                    <SoftBadge pill>
                      <FontAwesomeIcon icon="pen" />
                    </SoftBadge>
                  </div>
                </Tooltip>
              )}
            </Flex>
          );
        })
        .filter(bar => bar)}
      {!!changes?.length && (
        <div className="mb-2">
          <ButtonGroup>
            <Button
              variant="link"
              className="text-600 hover-1000 text-decoration-none rounded-pill fs--1"
              size="sm"
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button
              className="rounded-pill fs--1"
              size="sm"
              onClick={handleSave}
            >
              Guardar cambios
            </Button>
          </ButtonGroup>
        </div>
      )}
      {me?.type === 'admin' && (
        <Button
          disabled={
            changes?.length && changes[changes.length - 1]?.type === 'in'
          }
          variant="falcon-default rounded-pill fs--1"
          size="sm"
          onClick={() =>
            handleBlur({ type: 'in', NO_ID_FIELD: uuid(), userId })
          }
        >
          Añadir fichaje
        </Button>
      )}
    </div>
  );
};

DayTimeControlInputs.propTypes = {
  day: PropTypes.string,
  isToday: PropTypes.bool,
  records: PropTypes.array,
  maxSeconds: PropTypes.number,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  user: PropTypes.object
};

export const UserCell = ({ user }) => {
  let { NO_ID_FIELD, avatar, department, name } = user || {};
  const usersId = [NO_ID_FIELD];

  return (
    <Flex alignItems="center">
      <Avatar size="xl" src={avatar} name={name} />
      <div className="ps-2 text-start">
        <Link
          to={`/user/time-control/custom/${usersId}`}
          className="text-800 p-0 stretched-link"
        >
          <h6 className="mb-0">{name}</h6>
        </Link>
        <p className="mb-0 fs--2">{department}</p>
      </div>
    </Flex>
  );
};

UserCell.propTypes = {
  user: PropTypes.object
};

export const WorkingDay = ({ days, holiday, maxSeconds, timeOff }) => {
  const { subtype } = timeOff || {};
  const subtypeData =
    timeOffSubtypes.find(({ value }) => value === subtype) || {};
  maxSeconds =
    holiday || (subtypeData?.extra && !subtypeData?.working) ? 0 : maxSeconds;

  let totalSeconds = isNaN(maxSeconds)
    ? (days?.length || 1) * defaultMaxTime
    : maxSeconds;
  let hours = Math.floor(totalSeconds / 3600) || 0;
  totalSeconds = totalSeconds - hours * 3600;
  let minutes = Math.floor(totalSeconds / 60) || 0;
  totalSeconds = totalSeconds - minutes * 60;
  if (totalSeconds >= 60) {
    minutes += 1;
    if (minutes >= 60) {
      minutes = 0;
      hours += 1;
    }
  }
  const title = holiday?.title || (!subtypeData?.working && subtypeData?.label);
  return (
    <div className="pb-2 mt-n1">
      <strong>
        {title ||
          `${hours}h ${minutes}m${
            subtypeData?.working
              ? ` (${subtypeData?.label}${
                  subtypeData?.time
                    ? ` ${dayjs(timeOff?.start).format('HH:mm')} - ${dayjs(
                        timeOff?.end
                      ).format('HH:mm')}`
                    : ''
                })`
              : ''
          }`}
      </strong>
    </div>
  );
};

WorkingDay.propTypes = {
  days: PropTypes.array,
  holiday: PropTypes.object,
  maxSeconds: PropTypes.number,
  timeOff: PropTypes.object
};

export const WorkingDayDuration = ({ day, endTime, records, ...props }) => {
  if (!day) {
    return null;
  }
  const days = [{ day, records, endTime }];
  return <WorkingDaysDuration {...props} days={days} records={records} />;
};

WorkingDayDuration.propTypes = {
  day: PropTypes.string,
  endTime: PropTypes.string,
  records: PropTypes.array
};

export const WorkingDaysDuration = ({
  className,
  isHoliday,
  days,
  maxSeconds,
  progressBarClass,
  records = [],
  showExtra = true
}) => {
  showExtra = isHoliday ? false : showExtra;
  const [, update] = useState(new Date());
  const lastRecord = [...records].pop();
  const isToday =
    !!lastRecord &&
    new Date(lastRecord?.createdAt)
      .toISOString()
      .match(/\d{4}-\d{2}-\d{2}/)?.[0] ===
      new Date().toISOString().match(/\d{4}-\d{2}-\d{2}/)?.[0];
  const isWorking = isToday && lastRecord?.type !== 'out';
  const workingDuration = days.reduce(
    (total, { day, endTime, records: dayRecords = [] }) => {
      const isToday =
        day === new Date().toISOString().match(/\d{4}-\d{2}-\d{2}/)?.[0];
      const lastRecord = [...(dayRecords || [])].pop();
      const isWorkingDay = isToday && lastRecord?.type !== 'out';
      const lastRecordAt = isWorkingDay
        ? new Date()
        : lastRecord?.type === 'out'
        ? new Date(lastRecord?.createdAt || endTime)
        : new Date(endTime);
      if (isWorkingDay && isToday) {
        dayRecords = [
          ...dayRecords,
          { createdAt: new Date().toISOString(), type: 'out' }
        ];
      }
      const { diff } = dayRecords?.reduce(
        ({ diff, prev }, { createdAt, type }) => {
          const time =
            type === 'in' ? dayjs(prev).diff(createdAt, 'seconds') || 0 : 0;
          return {
            diff: diff + time,
            prev: new Date(createdAt)
          };
        },
        { diff: 0, prev: lastRecordAt }
      ) || { diff: 0 };
      return total + diff;
    },
    0
  );

  const maxTime = isNaN(maxSeconds)
    ? (days?.length || 1) * defaultMaxTime
    : maxSeconds;
  const isReached = workingDuration > maxTime;
  let remainingTime = maxTime;
  let totalSeconds = workingDuration || 0;
  let hours = showExtra
    ? Math.min(
        Math.floor(totalSeconds / 3600) || 0,
        Math.floor(remainingTime / 3600)
      )
    : Math.floor(totalSeconds / 3600) || 0;
  totalSeconds = totalSeconds - hours * 3600;
  remainingTime = remainingTime - hours * 3600;
  let extraHours = 0;
  let extraMinutes = 0;
  let minutes =
    showExtra && isReached
      ? Math.floor(remainingTime / 60)
      : Math.floor(totalSeconds / 60) || 0;
  (!showExtra || !isReached) && totalSeconds >= 60 && (extraMinutes += 1);
  totalSeconds = totalSeconds - minutes * 60;
  if (isReached) {
    extraHours = Math.floor(totalSeconds / 3600) || 0;
    totalSeconds = totalSeconds - extraHours * 3600;
    extraMinutes = Math.floor(totalSeconds / 60) || 0;
    totalSeconds >= 60 && (extraMinutes += 1);
  } else if (totalSeconds >= 60) {
    minutes += 1;
    if (minutes >= 60) {
      minutes = 0;
      hours += 1;
    }
  }

  useEffect(() => {
    if (!isToday) {
      return;
    }
    const interval = setInterval(
      () =>
        update(time =>
          dayjs(time).diff(new Date(), 'minutes') ? new Date() : time
        ),
      1000
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  return !isHoliday || workingDuration ? (
    <div className={className}>
      <div>
        <strong>
          {hours}h {minutes}m
        </strong>
        {showExtra && isReached && !!(extraHours || extraMinutes) && (
          <SoftBadge bg="danger" pill className="bg-transparent text-danger">
            + {extraHours}h {extraMinutes}m
          </SoftBadge>
        )}
      </div>
      <div>
        <ProgressBar
          className={classNames('mt', progressBarClass)}
          style={{ height: 8 }}
        >
          <ProgressBar
            animated={isToday && isWorking}
            variant={classNames({
              primary: showExtra || (workingDuration - maxTime) / 60 < 1,
              warning: !showExtra && (workingDuration - maxTime) / 60 >= 1
            })}
            className="rounded-end-0"
            now={workingDuration}
            max={maxSeconds || 100}
          />
        </ProgressBar>
      </div>
    </div>
  ) : null;
};

WorkingDaysDuration.propTypes = {
  className: PropTypes.string,
  isHoliday: PropTypes.bool,
  day: PropTypes.string,
  days: PropTypes.array,
  endTime: PropTypes.string,
  maxSeconds: PropTypes.number,
  progressBarClass: PropTypes.string,
  records: PropTypes.array,
  showExtra: PropTypes.bool
};

export const WorkingState = ({ isWorking }) => {
  return (
    <SoftBadge
      pill
      bg={classNames({
        success: isWorking,
        danger: !isWorking
      })}
    >
      {classNames({
        Trabajando: isWorking,
        Ausente: !isWorking
      })}
    </SoftBadge>
  );
};

WorkingState.propTypes = {
  isWorking: PropTypes.bool
};

export const WorkingWeek = ({ isWorking, records, schedule }) => {
  const [{ userId } = {}] = records || [{}];
  const { maxHours } = schedule || {};
  const hoursPerWeek = parseInt(maxHours || 0);

  if (!records?.length) {
    return null;
  }

  const thisWeekdata = Object.entries(
    groupBy(
      records?.map(record => {
        const [day] = record?.createdAt?.match(/\d{4}-\d{2}-\d{2}/) || [];
        return { ...record, day };
      }) || [],
      'day'
    )
  ).map(([day, dayRecords]) => {
    const lastRecord = [...(dayRecords || [])].pop();
    const lastRecordAt =
      lastRecord?.type === 'in' ? new Date() : new Date(lastRecord?.createdAt);
    const { diff: seconds } = dayRecords?.reduce(
      ({ diff, prev }, { createdAt, type }) => {
        const time =
          type === 'in' ? dayjs(prev).diff(createdAt, 'seconds') || 0 : 0;
        return {
          diff: diff + time,
          prev: new Date(createdAt)
        };
      },
      { diff: 0, prev: lastRecordAt }
    ) || { diff: 0 };
    return { day, seconds };
  });

  return (
    <div>
      <Flex wrap="nowrap">
        {thisWeekdata.map(({ day, seconds }, index) => {
          let totalSeconds = seconds;
          const hours = Math.floor(totalSeconds / 3600) || 0;
          totalSeconds = totalSeconds - hours * 3600;
          const minutes = Math.floor(totalSeconds / 60) || 0;
          totalSeconds = totalSeconds - minutes * 60;
          const percent = (seconds * 100) / (hoursPerWeek * 3800);
          return (
            <div
              key={`Day-Week-${userId}-${index}`}
              className={classNames({ 'ms-2': index > 0 })}
              style={{ minWidth: `${percent}%` }}
            >
              <strong className="text-capitalize me-1 fs--2">
                {dayjs(day).format('dd').substr(0, 1)}:
              </strong>
              <strong className="fs--1">
                {hours}h {minutes}m
              </strong>
            </div>
          );
        })}
      </Flex>
      <ProgressBar className="bg-300 mt-1" style={{ height: 8 }}>
        {thisWeekdata.map(({ day, seconds }, index) => {
          const percent = Math.round((seconds * 100) / (hoursPerWeek * 3800));
          const isToday =
            day === new Date().toISOString().match(/\d{4}-\d{2}-\d{2}/)?.[0];
          return (
            <ProgressBar
              key={`Day-Week-Progress-${userId}-${index}`}
              animated={isToday && isWorking}
              className={classNames('rounded-start-0', {
                'rounded-end-0': index < thisWeekdata.length - 1,
                'border-start': index > 0
              })}
              now={percent}
            />
          );
        })}
      </ProgressBar>
    </div>
  );
};

WorkingWeek.propTypes = {
  isWorking: PropTypes.bool,
  records: PropTypes.array,
  schedule: PropTypes.object
};

export const WorkingWeekDuration = props => {
  const { days, endTime, isWorking, maxSeconds, records } = props;
  const lastRecord = [...(records || [])].pop();
  const [, update] = useState(new Date());

  const lastRecordAt = isWorking
    ? new Date()
    : lastRecord?.type === 'out'
    ? new Date(lastRecord?.createdAt || endTime)
    : new Date(endTime);
  const { diff } = records?.reduce(
    ({ diff, prev }, { createdAt, type }) => {
      const time =
        type === 'in' ? dayjs(prev).diff(createdAt, 'seconds') || 0 : 0;
      return {
        diff: diff + time,
        prev: new Date(createdAt)
      };
    },
    { diff: 0, prev: lastRecordAt }
  ) || { diff: 0 };
  const maxTime = isNaN(maxSeconds)
    ? (days?.length || 1) * defaultMaxTime
    : maxSeconds;
  const isReached = diff > maxTime;
  let remainingTime = maxTime;
  let totalSeconds = diff || 0;
  const hours = Math.min(
    Math.floor(totalSeconds / 3600) || 0,
    Math.floor(remainingTime / 3600)
  );
  totalSeconds = totalSeconds - hours * 3600;
  remainingTime = remainingTime - hours * 3600;
  const minutes = isReached
    ? Math.floor(remainingTime / 60)
    : Math.floor(totalSeconds / 60) || 0;
  totalSeconds = totalSeconds - minutes * 60;
  let extraHours = 0;
  let extraMinutes = 0;
  if (isReached) {
    extraHours = Math.floor(totalSeconds / 3600) || 0;
    totalSeconds = totalSeconds - extraHours * 3600;
    extraMinutes = Math.floor(totalSeconds / 60) || 0;
  }

  useEffect(() => {
    const interval = setInterval(
      () =>
        update(time =>
          dayjs(time).diff(new Date(), 'minutes') ? new Date() : time
        ),
      1000
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <div>
        <strong>
          {hours}h {minutes}m
        </strong>
        {isReached && !!(extraHours || extraMinutes) && (
          <SoftBadge bg="danger" pill className="bg-transparent text-danger">
            + {extraHours}h {extraMinutes}m
          </SoftBadge>
        )}
      </div>
      <div>
        <ProgressBar
          animated={isWorking}
          className="mt-1"
          now={diff}
          max={maxSeconds}
          style={{ height: 8 }}
        />
      </div>
    </div>
  );
};

WorkingWeekDuration.propTypes = {
  days: PropTypes.array,
  endTime: PropTypes.string,
  isWorking: PropTypes.bool,
  maxSeconds: PropTypes.number,
  records: PropTypes.array
};
