import React from 'react';
import { Alert, Card, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Background from 'components/common/Background';
import corner1 from 'assets/img/illustrations/corner-1.png';

const PricingTable = () => {
  return (
    <Row className="flex-center">
      <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
        <Helmet>
          <script
            async
            src="https://js.stripe.com/v3/pricing-table.js"
          ></script>
        </Helmet>
        <Card>
          <Card.Body>
            <Alert variant="primary" className="text-center">
              <Background image={corner1} className="p-x1" />
              <Alert.Heading>
                Prueba Happyfy GRATIS durante 14 días
              </Alert.Heading>
              <p className="m-0">
                Puedes cancelar tu suscripción en cualquier momento
              </p>
            </Alert>
            <stripe-pricing-table
              pricing-table-id="prctbl_1MRxRSIZw3S88a4240AktE02"
              publishable-key="pk_live_51Lec66IZw3S88a42z12ESCRXy9V4eIlpF64QDSA7hEdZ3mcyhzjoleggeEjqbS9RDSrXMp2DBfGCxhojzu3bwlFm004B3hSlqB"
            ></stripe-pricing-table>
          </Card.Body>
        </Card>

        <div className="text-end">
          <a href="/" className="text-500 mt-3">
            Omitir suscripción
          </a>
        </div>
      </Col>
    </Row>
  );
};

export default PricingTable;
