import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  updateDoc,
  where
} from '@firebase/firestore';
import dayjs from 'dayjs';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import {
  CalendarContext,
  TimeControlContext,
  UserContext
} from 'context/Context';
import {
  download,
  getDayStartTime,
  getDayEndTime,
  getDaySeconds,
  getUserSchedule,
  groupBy,
  isMobile
} from 'helpers/utils';
import timeOffSubtypes from 'components/user/time-off/subtypes.json';

const TimeControlProvider = ({ avoidQuery, children }) => {
  const { timeOff: timesOff = [] } = useContext(CalendarContext);
  const { company, me, partners, schedules } = useContext(UserContext);
  const { NO_ID_FIELD: companyId } = company;
  const [filters, setFilters] = useState({});
  const [isShownlocationModal, showLocationModal] = useState(false);
  const [now] = useState(new Date());
  const [day] = now.toISOString().match(/\d{4}-\d{2}-\d{2}/);
  const yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);
  const db = useFirestore();
  const date = new Date();
  const monday = new Date();
  const daytoset = 1;
  const currentDay = date.getDay();
  const distance = daytoset - currentDay;
  monday.setDate(date.getDate() + distance);
  const [mondayISO] = monday?.toISOString()?.match(/\d{4}-\d{2}-\d{2}/) || [];

  const { usersId, period } = filters || {};
  const { start = monday, end = yesterday } = period || {};

  let todayRecordsQuery = query(collection(db, 'none'));
  let weekRecordsQuery = query(collection(db, 'none'));
  let recordsQuery = query(collection(db, 'none'));

  if (companyId && me?.NO_ID_FIELD) {
    todayRecordsQuery = query(
      collection(db, 'time_control'),
      where('companyId', '==', companyId),
      where('createdAt', '>=', day),
      where('createdAt', '<=', `${day}T23:59:59.999Z`),
      orderBy('createdAt', 'asc')
    );
    if (!avoidQuery) {
      weekRecordsQuery = query(
        collection(db, 'time_control'),
        ...[
          where('companyId', '==', companyId),
          me?.type !== 'admin' && where('userId', '==', me?.NO_ID_FIELD),
          where('createdAt', '>=', mondayISO),
          orderBy('createdAt', 'asc')
        ].filter(filter => filter)
      );
      recordsQuery = query(
        collection(db, 'time_control'),
        ...[
          where('companyId', '==', companyId),
          me?.type === 'admin'
            ? usersId?.length && where('userId', 'in', usersId)
            : usersId?.length && where('userId', '==', me?.NO_ID_FIELD),
          start &&
            where(
              'createdAt',
              '>=',
              start?.toISOString()?.match(/\d{4}-\d{2}-\d{2}/)?.[0]
            ),
          (end || start) &&
            where(
              'createdAt',
              '<=',
              `${
                (end || start)?.toISOString()?.match(/\d{4}-\d{2}-\d{2}/)?.[0]
              }T23:59:59.999Z`
            ),
          orderBy('createdAt', 'asc')
        ].filter(filter => filter)
      );
    }
  }

  const { data: todayRecords = [], status } =
    useFirestoreCollectionData(todayRecordsQuery);
  const { data: weekRecords = [] } =
    useFirestoreCollectionData(weekRecordsQuery);
  const { data: records = [], status: recordsStatus } =
    useFirestoreCollectionData(recordsQuery);
  const isReady =
    companyId &&
    me?.NO_ID_FIELD &&
    status === 'success' &&
    recordsStatus === 'success';

  const myTodayRecords = todayRecords
    .filter(({ userId }) => userId === me?.NO_ID_FIELD)
    .sort((r1, r2) => (r1.createdAt < r2.createdAt ? -1 : 1));
  const myWeekRecords = weekRecords.filter(
    ({ userId }) => userId === me?.NO_ID_FIELD
  );

  const createEntry = async params => {
    const createdAt = new Date().toISOString();
    const data = {
      companyId,
      createdAt,
      userId: me?.NO_ID_FIELD,
      info: {
        userAgent: window.navigator?.userAgent,
        mobile: window.navigator?.userAgentData?.mobile || isMobile(),
        platform:
          window.navigator?.userAgentData?.platform ||
          window.navigator?.platform,
        vendor: window.navigator?.vendor
      },
      ...params
    };
    await addDoc(collection(db, 'time_control'), data);
  };

  const deleteEntry = async ({ NO_ID_FIELD }) => {
    try {
      await deleteDoc(doc(db, 'time_control', NO_ID_FIELD));
    } catch (error) {
      console.error(error);
    }
  };

  const updateEntry = async (timeControl, params) => {
    try {
      await updateDoc(
        doc(db, 'time_control', timeControl?.NO_ID_FIELD),
        params
      );
    } catch (error) {
      console.error(error);
    }
  };

  const exportToCsv = () => {
    const recordsWithDay = records.map(record => {
      const [day] = record.createdAt.match(/\d{4}-\d{2}-\d{2}/);
      return { ...record, day };
    });
    const recordsByUser = Object.entries(groupBy(recordsWithDay, 'userId'));

    const entriesWithData = recordsByUser?.reduce(
      (entries, [userId, records]) => {
        const recordsByDay = groupBy(records, 'day');
        const days = Object.entries(recordsByDay).map(([day, records]) => {
          return {
            day,
            records
          };
        });
        return [...entries, { days, records, userId }];
      },
      []
    );

    const data = partners
      ?.filter(
        ({ NO_ID_FIELD }) => !usersId?.length || usersId.includes(NO_ID_FIELD)
      )
      .map(user => {
        const userTimesOff = timesOff.filter(
          ({ participants }) =>
            !participants ||
            participants.some(ref => ref?.path === user?.ref?.path)
        );
        const { NO_ID_FIELD: userId, location } = user;
        const { holidays = [] } = location || {};
        const schedule = getUserSchedule(schedules, user);
        const userData =
          entriesWithData?.find(entry => entry?.userId === userId) || {};

        let days = [];
        let currentDay = new Date(start);
        while (
          currentDay &&
          end &&
          currentDay?.toISOString() <= end?.toISOString()
        ) {
          const isToday =
            currentDay ===
            new Date().toISOString().match(/\d{4}-\d{2}-\d{2}/)?.[0];
          const data = userData?.days?.find(
            ({ day }) => day === dayjs(currentDay).format('YYYY-MM-DD')
          ) || { day: dayjs(currentDay).format('YYYY-MM-DD') };
          const holiday = holidays.find(
            ({ start }) =>
              dayjs(start).format('YYYY-MM-DD') ===
              dayjs(currentDay).format('YYYY-MM-DD')
          );
          const timeOff = userTimesOff?.find(
            ({ start, end }) =>
              dayjs(currentDay).format('YYYY-MM-DD') >=
                dayjs(start).format('YYYY-MM-DD') &&
              dayjs(currentDay).format('YYYY-MM-DD') <=
                dayjs(end).format('YYYY-MM-DD')
          );
          const subtypeData =
            timeOffSubtypes.find(({ value }) => value === timeOff?.subtype) ||
            {};
          const { working } = subtypeData;
          const isHoliday = !!holiday;
          const isTimeOff = !!timeOff;
          const maxSeconds =
            isHoliday || (!working && isTimeOff)
              ? 0
              : getDaySeconds(schedule, new Date(currentDay));
          const startTime =
            isHoliday || (!working && isTimeOff)
              ? ''
              : getDayStartTime(schedule, new Date(currentDay));
          const endTime =
            isHoliday || (!working && isTimeOff)
              ? ''
              : getDayEndTime(schedule, new Date(currentDay));
          days = [
            ...days,
            {
              ...data,
              startTime,
              endTime,
              holiday,
              timeOff,
              isHoliday,
              isTimeOff,
              isToday,
              maxSeconds,
              user
            }
          ];
          currentDay.setDate(currentDay.getDate() + 1);
        }

        const totalMaxSeconds = days?.reduce(
          (total, { maxSeconds }) => total + maxSeconds,
          0
        );
        return { ...userData, days, maxSeconds: totalMaxSeconds, user, userId };
      });

    const rows = data
      .map(({ user, days }) => {
        const { name } = user;

        const userRecords = days
          .map(({ day, holiday, maxSeconds, records, timeOff }) => {
            const subtypeData =
              timeOffSubtypes.find(({ value }) => value === timeOff?.subtype) ||
              {};
            const schedule = getUserSchedule(schedules, user);
            const endTime = getDayEndTime(schedule, new Date(day));

            return !records?.length
              ? [
                  [
                    name,
                    dayjs(day).format('DD-MM-YYYY'),
                    maxSeconds,
                    0,
                    subtypeData?.extra === false ? 0 : -maxSeconds,
                    '',
                    '',
                    '',
                    holiday?.title || subtypeData?.label
                  ]
                ]
              : records
                  ?.map(({ createdAt, type }, index) => {
                    if (type === 'out') {
                      return;
                    }
                    const now = new Date().toISOString();
                    const next = records[index + 1] || { createdAt: endTime };
                    const start = createdAt;
                    const maxTime = now < endTime ? now : endTime;
                    const end = next?.createdAt || maxTime;
                    const durationSeconds =
                      dayjs(end).diff(start, 'seconds') + 1;
                    const startStr = dayjs(start).format('HH:mm');
                    const endStr = dayjs(end).format('HH:mm');
                    const lastRecord = [...records].pop();
                    const lastRecordAt =
                      lastRecord?.type === 'out'
                        ? new Date(lastRecord?.createdAt)
                        : new Date(maxTime);
                    const { diff: dayDurationSeconds } =
                      records?.reduce(
                        ({ diff, prev }, { createdAt, type }) => {
                          const time =
                            type === 'in'
                              ? dayjs(prev).diff(createdAt, 'seconds') || 0
                              : 0;
                          return {
                            diff: diff + time,
                            prev: new Date(createdAt)
                          };
                        },
                        { diff: 0, prev: lastRecordAt }
                      ) || {};
                    const dayDesviationSeconds =
                      subtypeData?.extra === false
                        ? 0
                        : dayDurationSeconds - maxSeconds;
                    const notes =
                      holiday?.title || subtypeData?.label
                        ? `${subtypeData?.label}${
                            subtypeData?.time
                              ? ` ${dayjs(timeOff?.start).format(
                                  'HH:mm'
                                )} - ${dayjs(timeOff?.end).format('HH:mm')}`
                              : ''
                          }`
                        : '';

                    return [
                      name,
                      dayjs(day).format('DD-MM-YYYY'),
                      maxSeconds,
                      dayDurationSeconds,
                      `'${dayDesviationSeconds}`,
                      startStr,
                      endStr,
                      durationSeconds,
                      notes
                    ];
                  })
                  .filter(rows => rows);
          })
          .filter(rows => rows)
          .reduce((totalRows, rows) => [...totalRows, ...rows], []);

        const { total: totalScheduleSeconds } = userRecords.reduce(
          (
            { name: lastName, day: lastDay, total = 0 },
            [name, day, duration]
          ) => {
            const durationToAdd =
              lastName !== name || lastDay !== day ? duration : 0;
            return { name, day, total: total + durationToAdd };
          },
          {}
        );
        const { total: totalDurationSeconds } = userRecords.reduce(
          (
            { name: lastName, day: lastDay, total = 0 },
            [name, day, , duration]
          ) => {
            const durationToAdd =
              lastName !== name || lastDay !== day ? duration : 0;
            return { name, day, total: total + durationToAdd };
          },
          {}
        );
        const { total: totalDesviationSeconds } = userRecords.reduce(
          (
            { name: lastName, day: lastDay, total = 0 },
            [name, day, , , desviation]
          ) => {
            const desviationToAdd =
              lastName !== name || lastDay !== day ? desviation : 0;
            return { name, day, total: total + desviationToAdd };
          },
          {}
        );
        const totalRecord = [
          name,
          'TOTAL',
          totalScheduleSeconds,
          totalDurationSeconds,
          totalDesviationSeconds,
          '',
          '',
          '',
          ''
        ];
        return [...userRecords, totalRecord];
      })
      .reduce((totalRows, rows) => [...totalRows, ...rows], []);

    const rowsStr = rows.reduce((totalRows, row, index) => {
      const thisRow = [...row];
      const [
        name,
        day,
        scheduleDuration,
        duration,
        desviation,
        ,
        ,
        subDuration,
        notes
      ] = thisRow;
      const [lastName, lastDay] = rows?.[index - 1] || [];
      let seconds = scheduleDuration;
      let hours = Math.floor(seconds / 3600) || 0;
      seconds = seconds - hours * 3600;
      let minutes = Math.floor(seconds / 60) || 0;
      const scheduleStr = `${hours < 10 ? '0' : ''}${hours}:${
        minutes < 10 ? '0' : ''
      }${minutes}`;
      seconds = duration;
      hours = Math.floor(seconds / 3600) || 0;
      seconds = seconds - hours * 3600;
      minutes = Math.floor(seconds / 60) || 0;
      const durationStr = `${hours < 10 ? '0' : ''}${hours}:${
        minutes < 10 ? '0' : ''
      }${minutes}`;
      seconds = Math.abs(desviation);
      hours = Math.floor(seconds / 3600) || 0;
      seconds = seconds - hours * 3600;
      minutes = Math.floor(seconds / 60) || 0;
      const sign = !hours && !minutes ? '' : desviation < 0 ? '-' : '+';
      const desviationStr = `${sign}${hours < 10 ? '0' : ''}${hours}:${
        minutes < 10 ? '0' : ''
      }${minutes}`;
      seconds = subDuration;
      hours = Math.floor(seconds / 3600) || 0;
      seconds = seconds - hours * 3600;
      minutes = Math.floor(seconds / 60) || 0;
      const subDurationStr = `${hours < 10 ? '0' : ''}${hours}:${
        minutes < 10 ? '0' : ''
      }${minutes}`;
      thisRow[0] = lastName === name ? '' : name;
      thisRow[1] = lastName === name && lastDay === day ? '' : day;
      thisRow[2] = lastName === name && lastDay === day ? '' : scheduleStr;
      thisRow[3] = lastName === name && lastDay === day ? '' : durationStr;
      thisRow[4] = lastName === name && lastDay === day ? '' : desviationStr;
      thisRow[7] = subDuration ? subDurationStr : '';
      thisRow[8] = notes;
      return [...totalRows, thisRow.join(';')];
    }, []);
    const csvText = `Nombre;Día;Estimado;Trabajado;Desviación;Entrada;Salida;Duración;Comentarios\r\n${rowsStr.join(
      `\r\n`
    )}`;
    const csvBlob = new Blob([csvText], { type: 'text/csv;charset=utf-8;' });
    download(
      csvBlob,
      `fichajes_${dayjs(start).format('YYYY-MM-DD')}_${dayjs(end).format(
        'YYYY-MM-DD'
      )}.csv`
    );
  };

  // console.log('TIME CONTROL >>>', myTodayRecords);

  return (
    <TimeControlContext.Provider
      value={{
        isReady,
        isShownlocationModal,
        showLocationModal,
        weekRecords,
        todayRecords,
        myWeekRecords,
        myTodayRecords,
        records,
        createEntry,
        deleteEntry,
        updateEntry,
        filters,
        setFilters,
        exportToCsv
      }}
    >
      {children}
    </TimeControlContext.Provider>
  );
};

TimeControlProvider.propTypes = {
  avoidQuery: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default TimeControlProvider;
