import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

dayjs.extend(relativeTime);
dayjs.locale('es');

export const ticketBadges = [
  { content: 'Reciente', value: 'recent', type: 'success' },
  { content: 'Atrasada', value: 'overdue', type: 'danger' },
  { content: 'Respondido', value: 'responded', type: 'info' },
  { content: 'Cerrado', value: 'closed', type: 'secondary' }
];

export const ticketPriorities = [
  {
    color: 'danger',
    data: 100,
    title: 'Urgente',
    value: 'urgent'
  },
  {
    color: 'warning',
    data: 75,
    title: 'Alta',
    value: 'high'
  },
  {
    color: 'info',
    data: 50,
    title: 'Media',
    value: 'medium'
  },
  {
    color: 'success',
    data: 25,
    title: 'Baja',
    value: 'low'
  }
];

export const ticketTypes = [
  { color: 'primary', title: 'Pregunta', value: 'question' },
  { color: 'danger', title: 'Incidente', value: 'incident' },
  { color: 'warning', title: 'Problema', value: 'problem' },
  { color: 'info', title: 'Característica', value: 'feature' },
  { color: 'succcess', title: 'Reconocimiento', value: 'appreciation' },
  { color: 'yellow', title: 'Sugerencia', value: 'suggestion' },
  { color: 'secondary', title: 'Otro', value: 'other' }
];
