import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Lottie from 'lottie-react';
import { Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import Loader from 'components/common/Loader';
import SoftBadge from 'components/common/SoftBadge';
import Tooltip from 'components/common/Tooltip';
import EmployeesProvider from 'components/company/employees/EmployeesProvider';
import QuestionaireProvider from 'components/company/questionaires/QuestionaireProvider';
import { QuestionairesContext, UserContext } from 'context/Context';
import { getUnique } from 'helpers/utils';
import check from 'assets/img/animated-icons/check-primary-light.json';

const ResendButton = ({ partners, percentage, responses }) => {
  const [loading, setLoading] = useState(false);

  const handleSendQuestionaire = async () => {
    setLoading(true);
    const usersToRemind = partners.filter(
      ({ NO_ID_FIELD }) =>
        !responses.some(({ userId }) => userId === NO_ID_FIELD)
    );

    try {
      await Promise.all(
        usersToRemind.map(async ({ NO_ID_FIELD }) => {
          try {
            await fetch(
              `${process.env.REACT_APP_FIREBASE_URL}/sendHappyQuestionaire?userId=${NO_ID_FIELD}`
            );
          } catch (error) {
            console.error(error);
          }
          return;
        })
      );

      toast.success(`Cuestionario enviado`);
    } catch (error) {
      toast.error(`Ha habido un error al enviar el cuestionario`);
    }
    setLoading(false);
  };

  return percentage === 100 ? (
    <Tooltip
      className="position-absolute end-0 bottom-0 m-2"
      title="Todos los empleados han contestado al cuestionario. ¡Enhorabuena!"
    >
      <Lottie animationData={check} className="w-60px" loop={false} />
    </Tooltip>
  ) : (
    <Tooltip title="Reenvía el cuestionario a los empleados que faltan por contestar">
      <IconButton
        variant="falcon-default"
        size="sm"
        icon="paper-plane"
        onClick={handleSendQuestionaire}
        transform="shrink-3"
      >
        {loading ? (
          <Loader
            className="p-1"
            style={{ width: '0.75rem', height: '0.75rem' }}
          />
        ) : (
          'Recordar'
        )}
      </IconButton>
    </Tooltip>
  );
};

ResendButton.propTypes = {
  partners: PropTypes.array,
  percentage: PropTypes.number,
  responses: PropTypes.array
};

const QuestionaireParticipation = ({ className }) => {
  const { happyResponses: responsesRaw = [], month: _month } =
    useContext(QuestionairesContext);
  const { partners = [] } = useContext(UserContext);
  const month = dayjs(_month).format('MMMM');
  const responses = getUnique(responsesRaw, 'userId');
  let percentage = Math.round(
    ((responses?.length || 0) / partners.length) * 100
  );
  return (
    <Card className={className}>
      <Card.Header className="pb-0">
        <h6 className="m-0">Participación del cuestionario de {month}</h6>
      </Card.Header>
      <Card.Body
        as={Flex}
        alignItems="end"
        justifyContent="between"
        className="pt-0"
      >
        <div>
          <h2 className="fw-normal text-700 mb-1 lh-1">{percentage}%</h2>
          <SoftBadge bg="success" className="ms-1 fs--2">
            {responses?.length || 0}/{partners.length}
          </SoftBadge>
        </div>
        <div className="text-end">
          <EmployeesProvider avoidQuery={true}>
            <ResendButton
              partners={partners}
              percentage={percentage}
              responses={responses}
            />
          </EmployeesProvider>
        </div>
      </Card.Body>
    </Card>
  );
};

QuestionaireParticipation.propTypes = {
  className: PropTypes.string
};

const QuestionaireParticipationWithProvider = props => {
  return (
    <QuestionaireProvider>
      <QuestionaireParticipation {...props} />
    </QuestionaireProvider>
  );
};

export default QuestionaireParticipationWithProvider;
