import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Modal } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import Popover from 'components/common/Popover';
import Tooltip from 'components/common/Tooltip';
import AppContext, { ChatContext, UserContext } from 'context/Context';

const styles = {
  control: base => ({
    ...base,
    borderRadius: '1.375rem',
    fontFamily:
      'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol'
  }),
  option: base => ({
    ...base,
    fontFamily:
      'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol'
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  })
};

const Menu = ({ children, ...rest }) => {
  return (
    <components.Menu
      {...rest}
      className="overflow-hidden min-w-300px mt-0 border-top border-200 rounded-top-0 shadow"
    >
      {children}
    </components.Menu>
  );
};

Menu.propTypes = {
  children: PropTypes.node
};

const Option = ({ data, isFocused, isSelected, label, ...rest }) => {
  const { deleteChecklistTemplate, me, value } = data;

  const handleDeleteTemplate = async event => {
    event.stopPropagation();
    await deleteChecklistTemplate(value);
    toast.success(`El checklist "${label}" se ha eliminado correctamente`);
  };

  return (
    <components.Option
      {...rest}
      className={classNames('m-0 d-flex align-items-center', {
        'bg-primary': isSelected,
        'bg-soft-primary': isFocused && !isSelected,
        'border-bottom': me?.type === 'admin'
      })}
    >
      <div className="flex-grow-1">
        <span
          className={classNames('m-0', {
            'fw-semi-bold': me?.type === 'admin',
            'text-white': isSelected,
            'text-dark': isFocused && !isSelected
          })}
        >
          {label}
        </span>
      </div>
      {me?.type === 'admin' && (
        <Button
          variant="falcon-danger"
          className="p-0"
          onClick={handleDeleteTemplate}
        >
          <Tooltip className="py-1 px-3" title="Eliminar">
            <FontAwesomeIcon icon="trash" />
          </Tooltip>
        </Button>
      )}
    </components.Option>
  );
};

Option.propTypes = {
  data: PropTypes.object,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string
};

const NewChecklistModal = ({ isOpen, setIsOpen, create }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const {
    checklistTemplates,
    createChecklistTemplate,
    updateChecklistTemplate,
    deleteChecklistTemplate
  } = useContext(ChatContext);
  const { me } = useContext(UserContext);
  const [isOpenChecklistTemplate, setIsOpenChecklistTemplate] = useState();
  const [formData = {}, setFormData] = useState();
  const { items = [], title } = formData || {};
  const checklistTemplatesOptions = checklistTemplates?.map(
    ({ NO_ID_FIELD, title }) => ({
      label: title,
      value: NO_ID_FIELD,
      deleteChecklistTemplate,
      me
    })
  );
  const checklistTemplate = checklistTemplates.find(
    checklist => checklist?.title === title
  );

  const handleClose = () => {
    setIsOpen(false);
    setFormData();
  };

  const handleChangeTitle = ({ target }) => {
    let { value } = target;
    setFormData((formData = {}) => ({ ...formData, title: value }));
  };

  const handleChangeOptions = ({ index, value }) => {
    const _items = [...items];
    _items[index] = {};
    _items[index].text = value;
    if (!value.length) {
      _items.splice(index, 1);
    }
    setFormData((formData = {}) => ({ ...formData, items: _items }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    create(formData);
    setIsOpen(false);
  };

  const handleSelectTemplate = ({ value }) => {
    const { NO_ID_FIELD, ...data } = checklistTemplates.find(
      ({ NO_ID_FIELD }) => NO_ID_FIELD === value
    );
    NO_ID_FIELD && setFormData(data);
  };

  const handleSaveAsTemplate = async event => {
    event.preventDefault();
    event.stopPropagation();
    if (checklistTemplate) {
      await updateChecklistTemplate(checklistTemplate, formData);
      setIsOpenChecklistTemplate(false);
    } else {
      await createChecklistTemplate(formData);
    }
    toast.success(`El checklist "${title}" se ha guardado correctamente`);
  };

  return (
    <Modal show={isOpen} onHide={handleClose} contentClassName="border">
      <Form onSubmit={handleSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-x1 border-bottom-0"
        >
          <Modal.Title as="h5" className="flex-grow-1">
            Nuevo checklist
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light pt-0">
          <Form.Group>
            <Select
              menuPlacement="auto"
              options={checklistTemplatesOptions}
              value={
                checklistTemplate
                  ? {
                      label: checklistTemplate?.title,
                      value: checklistTemplate?.NO_ID_FIELD
                    }
                  : null
              }
              placeholder="Utilizar plantilla"
              classNamePrefix="react-select"
              onChange={handleSelectTemplate}
              menuPortalTarget={document.body}
              styles={styles}
              components={{ Menu, Option }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Body className="p-x1">
          <Form.Group className="mb-3" controlId="titleInput">
            <Form.Label className="fs-0">Título</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={title}
              required
              onChange={handleChangeTitle}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="itemsInput">
            <Form.Label className="fs-0">Tarea</Form.Label>
            <div className="input-group-vertical mb-3">
              {[...items, ''].map((option, index) => {
                const { text } = option || {};
                return (
                  <Form.Control
                    key={`New-Questionaire-Option-${index}`}
                    type="text"
                    name={`options-${index}`}
                    required={index < items.length - 1}
                    value={text}
                    onChange={({ target }) => {
                      const { value } = target;
                      handleChangeOptions({ index, value });
                    }}
                  />
                );
              })}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-x1 border-top-0">
          <Popover
            content={
              <>
                <div>
                  El checklist "{title}" existe. ¿Quieres sobrescribirlo?
                </div>
                <div>
                  <Button
                    variant="primary"
                    className="mt-3 px-4"
                    onClick={handleSaveAsTemplate}
                  >
                    Sobrescribir
                  </Button>
                </div>
              </>
            }
            show={isOpenChecklistTemplate}
          >
            <Button
              variant="falcon-default"
              disabled={!title || !items?.length}
              onClick={event => {
                checklistTemplate
                  ? setIsOpenChecklistTemplate(true)
                  : handleSaveAsTemplate(event);
              }}
            >
              Guardar como plantilla
            </Button>
          </Popover>
          <Button variant="primary" type="submit" className="px-4">
            Crear checklist
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

NewChecklistModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired
};

export default NewChecklistModal;
