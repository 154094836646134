import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Form } from 'react-bootstrap';
import Countdown from 'components/common/Countdown';
import Flex from 'components/common/Flex';
import { ChatContext, UserContext } from 'context/Context';
import dayjs from 'dayjs';
import { doc } from '@firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';

const ObjectiveMessage = ({ message }) => {
  const db = useFirestore();
  const { updateChat, updateMessage } = useContext(ChatContext);
  const { me } = useContext(UserContext);
  const { NO_ID_FIELD, chat, chatId, extra } = message || {};
  const { calendarRef } = extra || {};
  const { data: calendarData } = useFirestoreDocData(
    calendarRef || doc(db, 'none', 'none')
  );
  const { title, reward, start: date, state, validated } = calendarData || {};
  const isPast = dayjs(date).diff(new Date(), 'seconds') < 0;

  const handleValidate = useCallback(
    async event => {
      const { target } = event;
      const { check: validated } = target;
      await updateMessage(NO_ID_FIELD, { extra: { ...extra, validated } });
      await updateChat(chatId, {
        updatedAt: new Date().toISOString()
      });
    },
    [chat, extra]
  );

  return (
    <>
      <h5 className="mb-1">{title}</h5>
      {calendarData && (
        <div className="d-grid mb-3">
          <Button
            as={Link}
            to={`/company/objectives/${calendarData.NO_ID_FIELD}`}
            variant="falcon-primary"
            className="rounded-pill"
            size="sm"
          >
            <span>Ver más detalles</span>
            <FontAwesomeIcon icon="angle-right" className="fs--2 ms-1" />
          </Button>
        </div>
      )}
      <Flex alignItems="center" className="mb-2">
        <FontAwesomeIcon icon="calendar-day" className="me-2" />
        <div className="fs--1">{dayjs(date).format('dddd, D MMMM YYYY')}</div>
      </Flex>
      <Flex alignItems="center" className="mb-2">
        <FontAwesomeIcon icon="trophy" className="me-2" />
        <div className="fs--1">{reward}</div>
      </Flex>

      {date && (!state || state === 'on-course') && <Countdown date={date} />}
      {!validated && (
        <Form.Check
          className="my-2"
          type="switch"
          id="defaultSwitch"
          label="Validado"
          value={validated}
          disabled={me?.type !== 'admin'}
          onChange={handleValidate}
        />
      )}

      <Badge
        className="p-2 fs--1 w-100"
        pill
        bg={classNames({
          success: state === 'success',
          danger: state === 'fail',
          warning:
            state === 'waiting' ||
            (isPast && (!state || state === 'on-course')),
          secondary: !isPast && (!state || state === 'on-course')
        })}
      >
        {classNames({
          Conseguido: state === 'success',
          'No conseguido': state === 'fail',
          'En espera':
            state === 'waiting' ||
            (isPast && (!state || state === 'on-course')),
          'En curso': !isPast && (!state || state === 'on-course')
        })}
      </Badge>
    </>
  );
};

ObjectiveMessage.propTypes = {
  message: PropTypes.object
};

export default ObjectiveMessage;
