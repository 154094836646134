import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { Card, Col, Offcanvas, Row } from 'react-bootstrap';
import { columns } from 'components/dashboards/support-desk/unsolved-tickets/TicketsLayout';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import FeedbackProvider from 'components/feedback/FeedbackProvider';
import AllTicketsHeader from './AllTicketsHeader';
import TicketFilteringForm from './TicketFilteringForm';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { FeedbackContext, UserContext } from 'context/Context';
import anonymousAvatar from 'assets/img/icons/blank-profile.svg';

const TableView = () => {
  const [show, setShow] = useState(false);
  const { breakpoints } = useBreakpoints();
  const { feedback = [], filters } = useContext(FeedbackContext);
  const { me, partners = [] } = useContext(UserContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const feedbackWithUser = feedback
    .filter(ticket => {
      const { feedback, status } = ticket;
      return filters ? true : feedback?.length > 4 && status !== 'closed';
    })
    .map(item => {
      const {
        comments = [],
        createdAt,
        priority = 'medium',
        status: statusProp,
        userId
      } = item;
      const user = partners.find(({ id } = {}) => id === userId) || {
        avatar: anonymousAvatar,
        name: 'Anónimo'
      };
      const diff = dayjs().diff(new Date(createdAt), 'days');
      const isResponded = comments?.some(({ userId: uid }) => uid !== userId);
      const status =
        statusProp ||
        (isResponded && 'responded') ||
        (diff > 3 ? 'overdue' : 'recent');
      return { ...item, priority, status, user };
    })
    .filter(ticket => {
      const show =
        filters &&
        Object.entries(filters).map(([key, value]) => ticket[key] === value);
      return !filters || !show?.some(show => !show);
    });

  return (
    <Row className="gx-3">
      <Col xxl={10} xl={9}>
        <AdvanceTableWrapper
          columns={columns}
          data={feedbackWithUser}
          selection={me?.type === 'admin'}
          selectionColumnWidth={me?.type === 'admin' ? 52 : 0}
          sortable
          pagination
          perPage={20}
          rowCount={feedbackWithUser.length}
        >
          <Card>
            <Card.Header className="border-bottom border-200 px-0">
              <AllTicketsHeader
                data={feedbackWithUser}
                table
                layout="table-view"
                handleShow={handleShow}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-light text-800 border-bottom border-200 align-middle"
                rowClassName="position-relative btn-reveal-trigger align-middle"
                tableProps={{
                  size: 'sm',
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </Col>
      <Col xxl={2} xl={3}>
        {breakpoints.down('xl') ? (
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            className="dark__bg-card-dark"
          >
            <Offcanvas.Header closeButton className="bg-light">
              <h6 className="fs-0 mb-0 fw-semi-bold">Filter</h6>
            </Offcanvas.Header>
            <TicketFilteringForm />
          </Offcanvas>
        ) : (
          <TicketFilteringForm />
        )}
      </Col>
    </Row>
  );
};

const TableViewWithProvider = props => {
  return (
    <FeedbackProvider>
      <TableView {...props} />
    </FeedbackProvider>
  );
};

export default TableViewWithProvider;
