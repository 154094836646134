import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import Calendar from 'components/common/Calendar';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'react-bootstrap';
import { CalendarContext, UserContext } from 'context/Context';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import SoftBadge from 'components/common/SoftBadge';

dayjs.locale('es');

const Objective = ({
  className,
  color = 'secondary',
  details = {},
  isLast,
  ...rest
}) => {
  const [now] = useState(new Date().toISOString());
  const { me, partners = [] } = useContext(UserContext);
  const { update } = useContext(CalendarContext);
  const {
    NO_ID_FIELD,
    start,
    title,
    participants = [],
    new: isNew,
    state,
    validated
  } = details;
  const day = dayjs(start).format('DD');
  const month = dayjs(start).format('MMM');
  const limit = dayjs(start).format('dddd, DD MMMM YYYY');
  const users = participants?.map(ref => {
    const user =
      partners.find(partner => partner?.ref?.path === ref?.path) || {};
    return user;
  });

  const handleClickAction = async state => {
    await update(details, { state });
    const els = document.querySelectorAll(`[data-id="${NO_ID_FIELD}"]`);
    els?.forEach(el => {
      el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.remove(
        'highlight'
      );
    });
  };
  const handleValidate = async validated => {
    await update(details, { validated });
    const els = document.querySelectorAll(`[data-id="${NO_ID_FIELD}"]`);
    els?.forEach(el => {
      el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.remove(
        'highlight'
      );
    });
  };

  return (
    <div className={className} {...rest}>
      {users?.length && !users.some(({ id }) => id === me?.NO_ID_FIELD) && (
        <Flex justifyContent="end" alignItems="center" className="mb-2">
          <AvatarGroup className="justify-content-end ms-2" max={5}>
            {users?.map(({ id, avatar, name }) => {
              return (
                <Avatar
                  key={`Objective-${NO_ID_FIELD}-Avatar-${id}`}
                  src={avatar && avatar}
                  name={name && name}
                />
              );
            })}
          </AvatarGroup>
        </Flex>
      )}
      <Flex>
        <Calendar day={day} month={month} color={color} />
        <div className="px-3 flex-grow-1">
          <h6 className="fs-0 mb-0">
            <Link
              to={`/company/objectives/${NO_ID_FIELD}`}
              className={`text-${color}`}
            >
              <span className="me-1">{title}</span>
              {isNew && (
                <SoftBadge pill bg={color}>
                  Nuevo
                </SoftBadge>
              )}
            </Link>
          </h6>
          <p className="mb-1">
            {!participants?.length ? (
              <span className="text-700">Para toda la empresa</span>
            ) : (
              users.map(({ id: NO_ID_FIELD, name }, index) => {
                return (
                  <span key={`Objective-Participant-${NO_ID_FIELD || index}`}>
                    <Link
                      to={`/user/profile/${NO_ID_FIELD}`}
                      className={classNames('text-700', {
                        'ps-1': index > 0
                      })}
                    >
                      {name || index}
                    </Link>
                    {index < participants.length - 1 && <span>,</span>}
                  </span>
                );
              })
            )}
          </p>

          {limit && <p className="text-1000 mb-0">Vencimiento: {limit}</p>}
        </div>
        {validated === null && me?.type === 'admin' && (
          <div className="ms-3">
            <ButtonGroup vertical>
              <Button
                variant="falcon-success"
                size="sm"
                onClick={() => handleValidate(true)}
              >
                Validar
              </Button>
              <Button
                className="text-nowrap"
                variant="falcon-danger"
                size="sm"
                onClick={() => handleValidate(false)}
              >
                No validar
              </Button>
            </ButtonGroup>
          </div>
        )}
        {now >= start &&
          !['success', 'fail'].includes(state) &&
          me?.type === 'admin' && (
            <div className="ms-3">
              <ButtonGroup vertical>
                <Button
                  variant="falcon-success"
                  size="sm"
                  onClick={() => handleClickAction('success')}
                >
                  Conseguido
                </Button>
                <Button
                  className="text-nowrap"
                  variant="falcon-danger"
                  size="sm"
                  onClick={() => handleClickAction('fail')}
                >
                  No conseguido
                </Button>
              </ButtonGroup>
            </div>
          )}
      </Flex>
      {!isLast && <div className="border-dashed border-bottom my-3"></div>}
    </div>
  );
};

Objective.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  details: PropTypes.shape({
    calendar: PropTypes.shape(Calendar.propTypes),
    title: PropTypes.string.isRequired,
    organizerId: PropTypes.string,
    time: PropTypes.string,
    place: PropTypes.string,
    location: PropTypes.string,
    duration: PropTypes.string,
    interested: PropTypes.string,
    badge: PropTypes.object
  }),
  isLast: PropTypes.bool
};

export default Objective;
