import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card } from 'react-bootstrap';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import Objective from 'components/company/objectives/Objective';
import { CalendarContext, UserContext } from 'context/Context';

const Objectives = ({ bodyClassName, cardTitle, exclude, header, ...rest }) => {
  const [now] = useState(new Date().toISOString());
  const { objectives: raw } = useContext(CalendarContext);
  const { me } = useContext(UserContext);
  const objectives = raw?.filter(
    ({ NO_ID_FIELD, participants, start, state, validated }) =>
      (!exclude || !exclude.includes(NO_ID_FIELD)) &&
      start > now &&
      validated &&
      (participants === null ||
        participants.some(ref => ref?.path === me?.ref?.path)) &&
      !['waiting', 'success', 'fail'].includes(state)
  );

  return (
    <Card {...rest}>
      {header || <FalconCardHeader title={cardTitle} light />}
      <Card.Body className={classNames('fs--1 overflow-auto', bodyClassName)}>
        {!objectives?.length && <span>No hay objetivos</span>}
        {objectives?.map((objective, index) => (
          <Objective
            key={`Objective-${objective.NO_ID_FIELD}`}
            details={objective}
            isLast={index === objectives?.length - 1}
          />
        ))}
      </Card.Body>
      <FalconCardFooterLink
        title="Todos los objetivos"
        to="/company/objectives"
        size="sm"
      />
    </Card>
  );
};

Objectives.propTypes = {
  bodyClassName: PropTypes.string,
  cardTitle: PropTypes.string,
  exclude: PropTypes.array,
  header: PropTypes.node,
  objectives: PropTypes.arrayOf(PropTypes.shape(Objective.propTypes))
};

export default Objectives;
