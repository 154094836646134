import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import EventDetailHeader from 'components/company/events/detail/EventDetailHeader';
import { CalendarContext } from 'context/Context';
import createMarkup from 'helpers/createMarkup';

const FeedEvent = ({ event: eventId, text }) => {
  const { events = [] } = useContext(CalendarContext);
  const event =
    events?.find(({ NO_ID_FIELD }) => NO_ID_FIELD === eventId) || {};

  return (
    <>
      <EventDetailHeader event={event} isFeed={true} />
      {!!text && (
        <p
          className="card-body pt-0"
          dangerouslySetInnerHTML={createMarkup(text)}
        />
      )}
    </>
  );
};

FeedEvent.propTypes = {
  event: PropTypes.string,
  text: PropTypes.string
};

export default FeedEvent;
