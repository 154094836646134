import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import VerifiedBadge from 'components/common/VerifiedBadge';
import { Col, Row } from 'react-bootstrap';
import ProfileBanner from './ProfileBanner';
import { UserContext } from 'context/Context';

const Banner = () => {
  const { company, myCompany } = useContext(UserContext);
  const { avatar: myCompanyAvatar, name: myCompanyName } = myCompany || {};
  const { NO_ID_FIELD, avatar, name } = company || {};

  return (
    <ProfileBanner>
      <ProfileBanner.Header
        id={NO_ID_FIELD}
        avatar={avatar}
        name={name}
        coverSrc={myCompanyAvatar}
      />
      <ProfileBanner.Body>
        <Row>
          <Col lg={8}>
            <h4 className="mb-1">
              {name} <VerifiedBadge />
            </h4>
            <h5 className="fs-0 fw-normal">{myCompanyName}</h5>
            <div className="border-dashed border-bottom my-4 d-lg-none" />
          </Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

Banner.propTypes = {
  company: PropTypes.object
};

export default Banner;
