import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { Link } from 'react-router-dom';
import FeedDropdown from './FeedDropdown';
import { UserContext } from 'context/Context';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

dayjs.extend(relativeTime);
dayjs.locale('es');

const FeedCardHeader = ({ feed }) => {
  const { createdAt, content, privacy } = feed;
  const { company, me } = useContext(UserContext);
  const { avatar, name } = company || {};
  const time = dayjs(createdAt)
    .calendar(null, {
      sameDay: `[${dayjs().to(dayjs(createdAt))}]`,
      lastDay: '[ayer]',
      lastWeek: 'dddd',
      sameElse: 'ddd, D MMM'
    })
    .replace('.', '');
  const { event, images, url, video } = content || {};
  const topic =
    images?.length > 1 ? (
      <>
        un <span className="text-primary">álbum</span>
      </>
    ) : images?.length ? (
      <>
        una <span className="text-primary">imagen</span>
      </>
    ) : video ? (
      <>
        un <span className="text-primary">video</span>
      </>
    ) : url ? (
      <>
        una <span className="text-primary">url</span>
      </>
    ) : (
      ''
    );

  if (event && privacy !== 'admin' && me?.type === 'admin') {
    return (
      <div className="position-absolute top-0 end-0 mt-2 me-3 z-index-1016">
        <FeedDropdown feed={feed} variant="falcon-default" />
      </div>
    );
  }

  return (
    <Card.Header
      className={classNames({
        'bg-light': !event || (privacy === 'admin' && me?.type === 'admin'),
        'position-absolute visually-hidden w-100 z-index-1016':
          event && privacy !== 'admin',
        'z-index-1016 mb-n1':
          event && privacy === 'admin' && me?.type === 'admin'
      })}
    >
      <Row className="justify-content-between">
        <Col>
          {(!event || (privacy === 'admin' && me?.type === 'admin')) && (
            <Flex>
              <Avatar size="2xl" src={avatar} name={name} className={status} />
              <div className="flex-1 align-self-center ms-2">
                <p className="mb-1 lh-1">
                  <Link className="fw-semi-bold" to="/me">
                    {name}
                  </Link>
                  {!!topic && (
                    <span className="ms-1">ha publicado {topic}</span>
                  )}
                </p>
                <p className="mb-0 fs--1 text-600 text-capitalize">
                  {time}
                  {privacy === 'admin' && (
                    <>
                      {' '}
                      • <FontAwesomeIcon icon="lock" className="text-warning" />
                    </>
                  )}
                </p>
              </div>
            </Flex>
          )}
        </Col>
        <Col
          xs="auto"
          className={classNames({
            'position-absolute visible': event && privacy !== 'admin'
          })}
        >
          <FeedDropdown feed={feed} />
        </Col>
      </Row>
    </Card.Header>
  );
};

FeedCardHeader.propTypes = {
  feed: PropTypes.object
};

export default FeedCardHeader;
