import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import equal from 'fast-deep-equal';
import { collection, doc, query, where, orderBy } from '@firebase/firestore';
import { getDownloadURL, ref } from '@firebase/storage';
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
  useStorage
} from 'reactfire';
import { ReportsContext } from 'context/Context';
import { groupBy } from 'helpers/utils';
import dayjs from 'dayjs';
import anonymousAvatar from 'assets/img/icons/blank-profile.svg';
import types from 'components/dashboards/types.json';

const alerts = {
  happiness: '< 70',
  environment: '< 70',
  fellowship: '< 70',
  motivation: '< 70',
  satisfaction: '< 70',
  stress: '> 70'
};

const reportNames = {
  happiness: 'felicidad',
  feedback: 'sugerencias',
  events: 'eventos',
  objectives: 'objetivos'
};

const ReportsProvider = ({ children }) => {
  let {
    companyId,
    report,
    date: dateProp,
    start: startProp,
    end: endProp
  } = useParams();

  const [dateMonthProp] = (dateProp || new Date().toISOString()).match(
    /\d{4}-\d{2}/
  );
  report = report || 'happiness';

  const [date] = useState(new Date(dateMonthProp));
  const [thisMonth] = date.toISOString().match(/\d{4}-\d{2}/);
  const nextDate = new Date(date);
  nextDate.setMonth(nextDate.getMonth() + 1);
  const [nextMonth] = new Date(nextDate).toISOString().match(/\d{4}-\d{2}/);
  const prevYear = new Date(date);
  prevYear.setFullYear(prevYear.getFullYear() - 1);
  const [yearDate] = new Date(prevYear)
    .toISOString()
    .match(/\d{4}-\d{2}-\d{2}/);
  const [lastYear] = useState(yearDate);
  const [start] = useState(startProp || thisMonth);
  const [end] = useState(endProp || nextMonth);
  let startPrevDate = new Date(start);
  if (dateProp) {
    startPrevDate.setMonth(startPrevDate.getMonth() - 1);
  } else {
    const diff = dayjs(end).diff(start, 'days');
    startPrevDate.setDate(startPrevDate.getDate() - diff);
  }
  startPrevDate = startPrevDate.toISOString().match(/\d{4}-\d{2}/)[0];

  const db = useFirestore();
  const storage = useStorage();

  const [company, setCompany] = useState();
  const [partners, setPartners] = useState([]);
  const [events, setEvents] = useState([]);
  const [objectives, setObjectives] = useState([]);

  let companyRef = doc(db, 'none', 'none');
  let partnersQuery = query(collection(db, 'none'));
  let logsQuery = query(collection(db, 'none'));
  let activityQuery = query(collection(db, 'none'));
  let indexesQuery = query(collection(db, 'none'));
  let feedbackQuery = query(collection(db, 'none'));
  let eventsQuery = query(collection(db, 'none'));
  let objectivesQuery = query(collection(db, 'none'));

  if (companyId) {
    companyRef = doc(db, 'companies', companyId);
    partnersQuery = query(
      collection(db, 'users'),
      where('company', '==', companyId)
    );

    activityQuery = query(
      collection(db, 'app_activity'),
      where('companyId', '==', `${companyId}`),
      where('createdAt', '>=', startPrevDate),
      where('createdAt', '<', end)
    );

    logsQuery = query(
      collection(db, 'events'),
      where('companyId', '==', `${companyId}`),
      where('timeline', '==', true),
      where('createdAt', '>=', start),
      where('createdAt', '<', end),
      orderBy('createdAt', 'asc')
    );

    indexesQuery = query(
      collection(db, 'indexes'),
      where('companyId', '==', `${companyId}`),
      where('createdAt', '>=', startProp ? start : lastYear),
      where('createdAt', '<', end)
    );

    feedbackQuery = query(
      collection(db, 'feedback'),
      where('companyId', '==', `${companyId}`),
      where('createdAt', '>=', start),
      where('createdAt', '<', end),
      orderBy('createdAt', 'asc')
    );

    const commonFilters = [
      where('companyId', '==', companyId),
      where('createdAt', '>=', start),
      where('createdAt', '<', end)
    ];
    const eventFilters = [...commonFilters, where('type', '==', 'event')];
    const objectiveFilters = [
      ...commonFilters,
      where('type', '==', 'objective')
    ];
    eventsQuery = query(collection(db, 'calendar'), ...eventFilters);
    objectivesQuery = query(collection(db, 'calendar'), ...objectiveFilters);
  }

  let { data: companyRaw } = useFirestoreDocData(companyRef);
  const { data: partnersRaw = [] } = useFirestoreCollectionData(partnersQuery);

  const { data: eventsRaw = [] } = useFirestoreCollectionData(eventsQuery);
  const { data: objectivesRaw = [] } =
    useFirestoreCollectionData(objectivesQuery);

  let { data: activity = [] } = useFirestoreCollectionData(activityQuery);
  let { data: logs = [] } = useFirestoreCollectionData(logsQuery);
  let { data: historicIndexes = [] } = useFirestoreCollectionData(indexesQuery);
  let { data: feedback = [] } = useFirestoreCollectionData(feedbackQuery);
  const indexes = historicIndexes.filter(
    ({ createdAt }) => createdAt >= start && createdAt < end
  );
  const setCompanyInfo = async company => {
    if (!companyId || company?.avatar) {
      return;
    }
    try {
      const avatar =
        company?.avatar ||
        (await getDownloadURL(
          ref(storage, `/companies/${companyId}/logo.png`)
        ));

      setCompany({ ...company, NO_ID_FIELD: companyId, avatar });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setCompanyInfo(companyRaw);
  }, [companyRaw]);

  const setPartnersInfo = async partners => {
    const _partners = await Promise.all(
      partners?.map(async data => {
        const currentPartner = partners?.find(({ id }) => id === doc.id);
        try {
          const avatar =
            currentPartner?.avatar ||
            data?.avatar?.base64 ||
            (await getDownloadURL(
              ref(storage, `/users/${data?.id}/avatar.jpeg`)
            ));
          return {
            id: data.id,
            ...data,
            avatar,
            ref: currentPartner?.ref || doc(db, 'users', data?.NO_ID_FIELD)
          };
        } catch (error) {
          //console.error(error);
          return {
            id: data.id,
            ...data,
            avatar: '',
            ref: currentPartner?.ref || doc(db, 'users', data?.NO_ID_FIELD)
          };
        }
      }) || []
    );
    !equal(_partners, partners) && setPartners(_partners);
  };

  useEffect(() => {
    setPartnersInfo(partnersRaw);
  }, [partnersRaw]);

  useEffect(() => {
    (async () => {
      const _events = (
        await Promise.all(
          eventsRaw?.map(async event => {
            const { assistance = [] } = event;
            return assistance
              .filter(({ assist }) => assist === true)
              .map(({ ref }) => ({
                userPath: ref?.path,
                ...event
              }));
          }) || []
        )
      ).reduce((all, assistance) => [...all, ...assistance], []);
      !equal(events, _events) && setEvents(_events);
    })();
  }, [eventsRaw]);

  useEffect(() => {
    (async () => {
      const _objectives = (
        await Promise.all(
          objectivesRaw?.map(async objective => {
            const { participants = [] } = objective;
            const _participants = participants?.length
              ? participants
              : partners.map(({ ref }) => ref);
            return _participants.map(user => ({
              userPath: user?.path,
              ...objective
            }));
          }) || []
        )
      ).reduce((all, participants) => [...all, ...participants], []);
      !equal(objectives, _objectives) && setObjectives(_objectives);
    })();
  }, [objectivesRaw]);

  // USE ---------------------------------------------
  const useData = activity.filter(({ createdAt }) => createdAt >= start);
  const lastUseData = activity.filter(({ createdAt }) => createdAt < start);

  const diff = useData.length - lastUseData.length;
  const diffPercentage = `${diff > 0 ? '+' : '-'}${Math.abs(
    Math.round((diff * 100) / (lastUseData.length || 1))
  )}%`;
  // console.log('*** USE ***');
  // console.log(
  //   JSON.stringify({ use: useData.length, variation: diffPercentage })
  // );
  // -------------------------------------------------

  // TOTALS ------------------------------------------
  const totalsData = Object.entries(
    Object.entries(groupBy(indexes, 'type')).reduce((data, [key, indexes]) => {
      const average =
        indexes?.reduce((total, { value = 0 }) => total + value, 0) /
          (indexes?.length || 1) || 0;
      return {
        ...data,
        [key]: Math.round(average, 10)
      };
    }, {})
  ).map(([type, value]) => `${types[type]}: ${value}`);
  // console.log('*** TOTALS ***');
  // console.log(JSON.stringify(totalsData));
  // -------------------------------------------------

  // ACTIVITY -----------------------------------------
  const logsData = logs
    .map(({ title, text }) => `- ${title}: ${text}`)
    .join('\n');
  // console.log('*** ACTIVITY ***');
  // console.log(JSON.stringify(logsData));
  // -------------------------------------------------

  // INDEXES -----------------------------------------
  const indexesData = Object.entries(groupBy(indexes, 'userId'))
    .map(([userId, indexes]) => {
      const user = partners.find(({ id } = {}) => id === userId);
      if (!user) {
        return;
      }
      const { name } = user;
      const userIndexes = Object.entries(groupBy(indexes, 'type')).reduce(
        (data, [type, indexes]) => {
          const filteredIndexes = indexes.filter(index => index.type === type);
          const average = Math.round(
            filteredIndexes.reduce((total, { value }) => total + value, 0) /
              filteredIndexes.length
          );
          return {
            ...data,
            [type]: Math.round(average, 10)
          };
        },
        {}
      );
      return { name, indexes: userIndexes };
    })
    .filter(data => data)
    .map(({ name, indexes }) => {
      const indexesTxt = Object.entries(indexes)
        .map(([key, value]) => `${types[key]} (${value}%)`)
        .join(', ');
      return `- ${name}:\n${indexesTxt}`;
    })
    .join('\n');

  const departmentsData = Object.entries(
    groupBy(
      indexes.map(index => ({
        ...partners.find(({ id } = {}) => id === index.userId),
        ...index
      })),
      'department'
    )
  )
    .map(([department, indexes]) => {
      const userIndexes = Object.entries(groupBy(indexes, 'type')).reduce(
        (data, [type, indexes]) => {
          const filteredIndexes = indexes.filter(index => index.type === type);
          const average = Math.round(
            filteredIndexes.reduce((total, { value }) => total + value, 0) /
              filteredIndexes.length
          );
          return {
            ...data,
            [type]: Math.round(average, 10)
          };
        },
        {}
      );
      return { department, indexes: userIndexes };
    })
    .filter(data => data)
    .map(({ department, indexes }) => {
      const indexesTxt = Object.entries(indexes)
        .map(([key, value]) => `${types[key]} (${value}%)`)
        .join(', ');
      return `- ${department}:\n${indexesTxt}`;
    })
    .join('\n');

  // console.log('*** INDEXES ***');
  // console.log(JSON.stringify(indexesData));
  // console.log(JSON.stringify(departmentsData));
  // -------------------------------------------------

  // ALERTS ------------------------------------------
  const alertsData = Object.entries(
    groupBy(
      // eslint-disable-next-line no-unused-vars
      indexes.filter(({ type, value }) => eval(`value ${alerts[type]}`)),
      'userId'
    )
  )
    .map(([userId, indexes]) => {
      const user = partners.find(({ id }) => id === userId);
      if (!user) {
        return;
      }
      const { name, department } = user;
      const index = indexes
        .sort((i1, i2) => (i1.createdAt < i2.createdAt ? -1 : 1))
        .pop();
      const { type, value } = index;
      return { name, department, type, value };
    })
    .filter(data => data)
    .sort(({ value: v1 }, { value: v2 }) => (v1 > v2 ? -1 : 1))
    .map(
      ({ name, department, type, value }) =>
        `- ${name} (${department}): ${types[type]} (${value})`
    )
    .join('\n');
  // console.log('*** ALERTS ***');
  // console.log(JSON.stringify(alertsData));
  // -------------------------------------------------

  // FEEDBACK -----------------------------------------
  const feedbackData = feedback
    .map(data => {
      const { feedback, userId } = data;
      const user =
        userId === 'anonymous'
          ? { avatar: anonymousAvatar, name: 'Anónimo' }
          : partners.find(({ id } = {}) => id === userId) || {};
      const { name, department } = user;
      return {
        name,
        department,
        feedback
      };
    })
    .filter(({ feedback, name }) => feedback && name)
    .map(
      ({ name, department, feedback }) =>
        `- ${name}${department ? ` (${department})` : ''}: ${feedback}`
    )
    .join('\n');
  // console.log('*** FEEDBACK ***');
  // console.log(JSON.stringify(feedbackData));
  // -------------------------------------------------

  // EVENTS -----------------------------------------
  const eventsData = Object.entries(groupBy(events, 'userPath'))
    .map(([path, data]) => ({
      count: data?.length,
      user: partners?.find(({ ref }) => ref?.path === path)
    }))
    .filter(({ user }) => user)
    .sort((u1, u2) => (u1.count > u2.count ? -1 : 1))
    .map(({ count, user }) => `- ${user.name} ha asistido a ${count} eventos`)
    .join('\n');
  // console.log('*** EVENTS ***');
  // console.log(JSON.stringify(eventsData));
  // -------------------------------------------------

  // OBJECTIVES --------------------------------------
  const objectivesData = Object.entries(
    groupBy(
      objectives.filter(({ state }) => state === 'success'),
      'userPath'
    )
  )
    .map(([path, data]) => ({
      count: data?.length,
      user: partners?.find(({ ref }) => ref?.path === path)
    }))
    .filter(({ user }) => user)
    .sort((u1, u2) => (u1.count > u2.count ? -1 : 1))
    .map(({ count, user }) => `- ${user.name} ha conseguido ${count} objetivos`)
    .join('\n');
  // console.log('*** OBJECTIVES ***');
  // console.log(JSON.stringify(objectivesData));
  // -------------------------------------------------

  const reportName = reportNames[report];
  const startDate = new Date(start || date);
  let endDate = new Date(end);
  endDate.setDate(endDate.getDate() - 1);
  let title = `Informe de ${reportName ? `${reportName} de` : ''} ${dayjs(
    startDate
  ).format('MMMM')}`;
  let titleDate = `${dayjs(startDate).format('DD.MM.YYYY')} - ${dayjs(
    endDate
  ).format('DD.MM.YYYY')}`;
  let titleWithDate = title;
  if (startProp) {
    title = `Informe ${reportName ? `de ${reportName}` : ''}:`;
    titleWithDate = `${title} ${titleDate}`;
  }

  console.log(`
${titleWithDate?.toUpperCase()} DE ${company?.name?.toUpperCase()}

USO DE HAPPYFY:
${useData?.length} accesos (${diffPercentage})

TOTALES:
${totalsData}

ACTIVIDAD:
${logsData}

EMPLEADOS:
${indexesData}

DEPARTAMENTOS:
${departmentsData}

ALERTAS:
${alertsData}

SUGERENCIAS:
${feedbackData}

EVENTOS:
${eventsData}

OBJETIVOS:
${objectivesData}
`);

  // console.log('REPORT >>>', {
  //   company,
  //   partners,
  //   activity,
  //   feedback,
  //   logs,
  //   indexes,
  //   historicIndexes,
  //   events,
  //   objectives,
  //   report,
  //   start,
  //   end
  // });

  return (
    <ReportsContext.Provider
      value={{
        company,
        partners,
        activity,
        feedback,
        logs,
        indexes,
        historicIndexes,
        events,
        objectives,
        report,
        date,
        start,
        end
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

ReportsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  date: PropTypes.object
};

export default ReportsProvider;
