import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form } from 'react-bootstrap';
import { collection, query, orderBy } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import FeedReactions from './FeedReactions';
import FeedActionButtons from './FeedActionButtons';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import Comments from './Comments';
import { FeedContext, UserContext } from 'context/Context';

const FeedCardFooter = ({ feed }) => {
  const { createComment } = useContext(FeedContext);
  const { me } = useContext(UserContext);
  const [showComment, setShowComment] = useState(false);
  const [comment, setComment] = useState('');
  const input = useRef();

  const db = useFirestore();
  const commentsQuery = feed?.NO_ID_FIELD
    ? query(
        collection(db, 'feeds', feed?.NO_ID_FIELD, 'comments'),
        orderBy('createdAt', 'asc')
      )
    : query(collection(db, 'none'));
  const { data: comments = [] } = useFirestoreCollectionData(commentsQuery);
  const dataWithComments = { ...feed, comments };

  const submitComment = e => {
    e.preventDefault();
    createComment(dataWithComments, {
      content: comment,
      userId: me?.NO_ID_FIELD
    });
    setComment('');
  };

  useEffect(() => {
    showComment && input?.current?.focus();
  }, [showComment]);

  return (
    <Card.Footer className="bg-light pt-0">
      <FeedReactions feed={dataWithComments} setShowComment={setShowComment} />
      <FeedActionButtons
        feed={dataWithComments}
        input={input}
        setShowComment={setShowComment}
      />
      {showComment && (
        <>
          <Form onSubmit={submitComment}>
            <Flex alignItems="center" className="border-top border-200 pt-3">
              <Avatar src={me?.avatar} name={me?.name} size="xl" />
              <Form.Control
                ref={input}
                type="text"
                className="rounded-pill ms-2 fs--1"
                placeholder="Escribe un comentario..."
                value={comment}
                onChange={e => setComment(e.target.value)}
              />
            </Flex>
          </Form>
          <Comments feed={dataWithComments} />
        </>
      )}
    </Card.Footer>
  );
};

FeedCardFooter.propTypes = {
  feed: PropTypes.object
};

export default FeedCardFooter;
