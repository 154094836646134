import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { UserContext } from 'context/Context';

const ProductGrid = ({ product }) => {
  const { company = {}, updateCompany, subscription } = useContext(UserContext);
  const { apps: activeApps = [] } = company;
  const { free, id, description, disabled, icon, title } = product;
  const isActive = activeApps.includes(id);

  const handleClick = event => {
    event.stopPropagation();
    const _activeApps = isActive
      ? [...activeApps].filter(app => app !== id)
      : [...activeApps, id];
    updateCompany(company, { apps: _activeApps });
  };

  return (
    <>
      <Card className="h-100">
        <Card.Header as={Flex}>
          <SoftBadge bg="primary" className="w-60px me-2 px-2 py-2">
            <FontAwesomeIcon icon={icon} size="xl" />
          </SoftBadge>
          <h5 className="fs-0 mt-2 d-flex align-items-center justify-content-between text-nowrap w-100">
            <Link
              to={`/marketplace/details/${id}`}
              className="stretched-link text-dark text-decoration-none"
            >
              {title}
            </Link>
            <SoftBadge
              bg={classNames({
                success: free,
                secondary: !free && subscription,
                warning: !free && !subscription
              })}
              className="ms-2 text-capitalize"
              pill
            >
              {classNames({
                GRATIS: free,
                INCLUIDO: !free && subscription,
                PRO: !free && !subscription
              })}
            </SoftBadge>
          </h5>
        </Card.Header>
        <Card.Body className="py-0">
          <p className="fs--1 text-800 m-0">{description}</p>
        </Card.Body>
        <Card.Footer className="text-end">
          <Button
            className={classNames('position-relative z-index-2', { disabled })}
            disabled={disabled}
            variant={classNames({
              'falcon-danger': isActive,
              'falcon-success': !isActive
            })}
            size="sm"
            onClick={handleClick}
          >
            <span className="ms-1 d-none d-lg-inline">
              {classNames({
                Desactivar: isActive,
                Activar: !isActive
              })}
            </span>
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
};

ProductGrid.propTypes = {
  product: PropTypes.object
};

export default ProductGrid;
