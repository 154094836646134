import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Overlay, Tooltip as BSTooltip } from 'react-bootstrap';

const Tooltip = ({
  children,
  className,
  placement = 'top',
  title,
  tooltipClass
}) => {
  const [show, setShow] = useState(false);
  const target = useRef();

  return (
    <>
      <div
        ref={target}
        className={classNames('d-inline-block', className)}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        {children}
      </div>
      <Overlay
        container={() => document.body}
        placement={placement}
        show={show && !!title}
        target={target.current}
      >
        <BSTooltip
          className={classNames('position-absolute', tooltipClass)}
          placement="auto"
        >
          {title}
        </BSTooltip>
      </Overlay>
    </>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  placement: PropTypes.string,
  title: PropTypes.node,
  tooltipClass: PropTypes.string
};

export default Tooltip;
