import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup } from 'react-bootstrap';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import Tooltip from 'components/common/Tooltip';
import { UserContext } from 'context/Context';
import ExperienceForm from './ExperienceForm';

const Experience = ({ experience, index, user }) => {
  const { me, updateUser } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const { company, dates = {}, position } = experience;
  const { start, end } = dates;

  const handleDelete = event => {
    event.preventDefault();
    if (me?.type !== 'admin' && me?.NO_ID_FIELD !== user?.NO_ID_FIELD) {
      return;
    }
    const { profile = {} } = user || {};
    const experience =
      profile?.experience?.filter((exp, i) => i !== index) || [];
    updateUser(user, { 'profile.experience': experience });
  };

  return (
    <ExperienceForm
      show={show}
      setShow={setShow}
      data={experience}
      index={index}
      user={user}
    >
      <Flex>
        <Avatar size="3xl" name={company} />
        <div className="flex-1 position-relative ms-3">
          <h6 className="fs-0 mb-0">{position}</h6>
          <p className="mb-1">
            <Link
              to={`https://www.google.com/search?q=${encodeURIComponent(
                company
              )}`}
              target="_blank"
            >
              {company}
            </Link>
          </p>
          <p className="text-1000 text-capitalize mb-0">
            {dates && (
              <>
                {start &&
                  dayjs(
                    `${start?.year}${start?.month ? `-${start?.month}` : ''}${
                      start?.day ? `-${start?.day}` : ''
                    }`
                  ).format(
                    `${start?.day ? `D ` : ''}${
                      start?.month ? `MMMM ` : ''
                    }YYYY`
                  )}{' '}
                -{' '}
                {start &&
                  (end && !isNaN(end?.year)
                    ? dayjs(
                        `${end?.year}${end?.month ? `-${end?.month}` : ''}${
                          end?.day ? `-${end?.day}` : ''
                        }`
                      ).format(
                        `${end?.day ? `D ` : ''}${
                          end?.month ? `MMMM ` : ''
                        }YYYY`
                      )
                    : 'Presente')}
              </>
            )}
          </p>
          <div className="border-dashed border-bottom my-3" />
        </div>
        {me?.NO_ID_FIELD === user?.NO_ID_FIELD && (
          <div>
            <ButtonGroup>
              <Button
                variant="falcon-default"
                className="p-0"
                onClick={() => setShow(show => !show)}
              >
                <Tooltip className="py-1 px-3" title="Editar">
                  <FontAwesomeIcon
                    icon={classNames({
                      pencil: !show,
                      times: show
                    })}
                  />
                </Tooltip>
              </Button>
              <Button
                variant="falcon-danger"
                className="p-0"
                onClick={handleDelete}
              >
                <Tooltip className="py-1 px-3" title="Eliminar">
                  <FontAwesomeIcon icon="trash" />
                </Tooltip>
              </Button>
            </ButtonGroup>
          </div>
        )}
      </Flex>
    </ExperienceForm>
  );
};
Experience.propTypes = {
  experience: PropTypes.shape({
    company: PropTypes.string,
    dates: PropTypes.object,
    position: PropTypes.string
  }),
  index: PropTypes.number,
  user: PropTypes.object
};
export default Experience;
