import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Card, Col, Row } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Tooltip from 'components/common/Tooltip';
import { UserContext } from 'context/Context';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

dayjs.extend(relativeTime);
dayjs.locale('es');

const UserCell = ({ user = {} }) => {
  let { avatar, department, name } = user;

  return (
    <Flex alignItems="center">
      <Avatar size="2xl" src={avatar} name={name || 'H'} />
      <div className="ms-3">
        <h6 className="mb-0 fw-semi-bold">{name}</h6>
        <span className="text-500">{department}</span>
      </div>
    </Flex>
  );
};

const Participants = ({ review = {}, responses = [] }) => {
  const { type } = review;
  const { partners = [] } = useContext(UserContext);
  let users = [...partners];
  if (type === 'manager') {
    users = partners.filter(
      ({ department, level }) =>
        level !== 0 &&
        partners.some(
          p => p?.department === department && p?.level === level - 1
        )
    );
  }
  if (type === 'employeeToManager') {
    users = partners.filter(({ department, level }) =>
      partners.some(p => p?.department === department && p?.level === level + 1)
    );
  }
  const usersWithResponses = users.map(user => {
    const { NO_ID_FIELD } = user;
    const response = responses.filter(({ userId }) => userId === NO_ID_FIELD);
    const { createdAt: responseDate = '' } = response || {};
    return { ...user, response, responseDate };
  });

  const columns = [
    {
      accessor: 'name',
      Header: 'Nombre',
      headerProps: { className: 'd-inline-block' },
      cellProps: { className: 'border-0 w-0' },
      Cell: rowData => {
        const user = rowData.row.original;
        return <UserCell user={user} />;
      }
    },
    {
      accessor: 'department',
      Header: 'Departamento',
      headerProps: { className: 'd-inline-block' },
      cellProps: { className: 'd-none' },
      Cell: rowData => {
        return rowData.row.original.department;
      }
    },
    {
      accessor: 'responseDate',
      Header: 'Respondido',
      cellProps: { className: 'border-0 w-100' },
      Cell: rowData => {
        const user = rowData.row.original;
        let { response } = user;

        let users = [user];
        if (type === 'manager') {
          users = partners.filter(
            ({ department, level }) =>
              department === user?.department && level === user?.level - 1
          );
        }
        if (type === 'employeeToManager') {
          users = partners.filter(
            ({ department, level }) =>
              department === user?.department && level === user?.level + 1
          );
        }

        return (
          <div>
            {type !== 'manager' ? (
              (response.length &&
                response.map(r => {
                  let { NO_ID_FIELD, createdAt } = r || {};
                  const date =
                    createdAt && dayjs(createdAt).format('D MMMM YYYY');
                  return (
                    <SoftBadge
                      key={`Response-${NO_ID_FIELD}`}
                      bg="success"
                      className="me-2"
                    >
                      Respondido el {date}
                    </SoftBadge>
                  );
                })) || (
                <SoftBadge bg="warning" className="me-2" pill>
                  Sin responder
                </SoftBadge>
              )
            ) : (
              <Flex alignItems="center">
                <SoftBadge bg="success" className="ms-2">
                  {response?.length || 0}/{users.length}
                </SoftBadge>
                <AvatarGroup className="ms-2" max={100}>
                  {users.map(user => {
                    let { NO_ID_FIELD: userId, avatar, name } = user || {};
                    const userResponse = response?.find(
                      ({ targetId }) => targetId === userId
                    );
                    let { NO_ID_FIELD: responseId, createdAt } =
                      userResponse || {};
                    const date =
                      createdAt && dayjs(createdAt).format('D MMMM YYYY');
                    return userResponse ? (
                      <Tooltip
                        key={`Response-${responseId}-Target-${userId}`}
                        title={
                          <>
                            <strong className="d-block">{name}:</strong>
                            <span>Respondido el {date}</span>
                          </>
                        }
                        className="position-relative avatar-xl z-index-1 hover-z-index-1016"
                      >
                        <Avatar
                          className="hover-z-index-1016"
                          src={avatar}
                          name={name}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        key={`Response-${responseId}-Target-${userId}`}
                        title={
                          <>
                            <strong className="d-block">{name}:</strong>
                            <span>Sin evaluar</span>
                          </>
                        }
                        className="position-relative avatar-xl z-index-1 hover-z-index-1016"
                      >
                        <Flex
                          className="bg-dark rounded-circle"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Avatar
                            src={avatar}
                            name={name}
                            className="opacity-50 hover-opacity-100 hover-z-index-1016"
                          />
                          <FontAwesomeIcon
                            icon="exclamation-triangle"
                            className="position-absolute text-warning pointer-none"
                          />
                        </Flex>
                      </Tooltip>
                    );
                  })}
                </AvatarGroup>
              </Flex>
            )}
          </div>
        );
      }
    }
  ];

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={usersWithResponses.sort(
          ({ responseDate: d1 }, { responseDate: d2 }) =>
            (d1 && !d2) || d1 < d2 ? -1 : 1
        )}
        sortable
        pagination
        perPage={20}
      >
        <Flex className="bg-light p-3 w-100">
          <Row className="w-100">
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox table placeholder="Buscar..." />
            </Col>
          </Row>
        </Flex>
        <AdvanceTable
          table
          headerClassName="bg-light border-light overflow-hidden text-900 text-nowrap align-middle"
          rowClassName="bg-white align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100"
          tableProps={{
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        {!partners.length && (
          <div className="bg-white p-3 border-bottom border-200">
            <Alert variant="info" className="mb-0">
              <h5 className="alert-heading">No hay participantes</h5>
            </Alert>
          </div>
        )}
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={partners.length}
            table
            rowInfo
            navButtons
          />
        </Card.Footer>
      </AdvanceTableWrapper>
    </>
  );
};

UserCell.propTypes = {
  user: PropTypes.object
};

Participants.propTypes = {
  review: PropTypes.object,
  responses: PropTypes.array
};

export default Participants;
