import React from 'react';
import UsersUsingHappyfy from 'components/dashboards/UsersUsingHappyfy';
import Alerts from 'components/dashboards/Alerts';
import IndexCharts from 'components/charts/IndexCharts';
import MostHappyUsers from './MostHappyUsers';
import Departments from 'components/dashboards/Departments';
import RecentActivity from 'components/dashboards/RecentActivity';
import FeedbackTrendingWords from 'components/charts/FeedbackTrendingWords';
import FeedProvider from 'components/company/feed/FeedProvider';
import CreatePost from 'components/company/feed/CreatePost';
import Custom from './Custom';
import MonthlyUseOfHappyfy from './MonthlyUseOfHappyfy';
import MyNextEvents from './MyNextEvents';
import MyObjectives from './MyObjectives';
import NewFeedback from './NewFeedback';
import NextHoliday from './NextHoliday';
import NextTimesOff from './NextTimesOff';
import PendingEvents from './PendingEvents';
import PendingObjectives from './PendingObjectives';
import PendingTimesOff from './PendingTimesOff';
import QuestionaireParticipation from './QuestionaireParticipation';
import TodayTimeControl from './TodayTimeControl';
import TimeControlPartners from './TimeControlPartners';
import Totals from './Totals';
import UserFeedbacks from './UserFeedbacks';

const type = 'happiness';

const Admin = () => {
  const apps = {
    default: [
      {
        key: 'MonthlyUseOfHappyfy',
        content: <MonthlyUseOfHappyfy className="w-100 h-100" />,
        size: 1
      },
      {
        key: 'UsersUsingHappyfy',
        content: <UsersUsingHappyfy className="w-100 h-100" />,
        size: 1
      }
    ],
    events: [
      {
        key: 'PendingEvents',
        content: <PendingEvents className="w-100 h-100 flex-grow-1" />,
        size: 2
      },
      {
        key: 'NextEvents',
        content: <MyNextEvents className="w-100 h-100" />,
        size: 2
      }
    ],
    feed: [
      {
        key: 'FeedProvider',
        content: (
          <FeedProvider>
            <CreatePost className="w-100 h-100" />
          </FeedProvider>
        ),
        size: 2
      }
    ],
    feedback: [
      {
        key: 'NewFeedback',
        content: <NewFeedback className="w-100 h-100" />,
        size: 2
      },
      {
        key: 'UserFeedbacks',
        content: <UserFeedbacks className="w-100 h-100 flex-grow-1" />,
        size: 2
      },
      {
        key: 'FeedbackTrendingWords',
        content: <FeedbackTrendingWords className="w-100 h-100 flex-grow-1" />,
        size: 3
      }
    ],
    objectives: [
      {
        key: 'PendingObjectives',
        content: (
          <PendingObjectives
            key="PendingObjectives"
            className="w-100 h-100 flex-grow-1"
          />
        ),
        size: 2
      },
      {
        key: 'MyObjectives',
        content: (
          <MyObjectives
            key="MyObjectives"
            className="w-100 h-100 flex-grow-1"
          />
        ),
        size: 2
      }
    ],
    questionaires: [
      {
        key: 'Totals',
        content: <Totals className="w-100 h-100" type={type} />,
        size: 1
      },
      {
        key: 'QuestionaireParticipation',
        content: <QuestionaireParticipation className="w-100 h-100" />,
        size: 1
      },
      {
        key: 'Alerts',
        content: (
          <Alerts
            className="w-100 h-100"
            bodyWrapperClassName="flex-grow-1 min-vh-25"
            showType={true}
          />
        ),
        size: 2
      },
      {
        key: 'IndexCharts',
        content: <IndexCharts className="w-100 h-100" />,
        size: 2
      },
      {
        key: 'MostHappyUsers',
        content: <MostHappyUsers className="w-100 h-100" />,
        size: 2
      },
      {
        key: 'Departments',
        content: <Departments className="w-100 h-100" type={type} />,
        size: 2
      },
      {
        key: 'RecentActivity',
        content: <RecentActivity className="w-100 h-100" />,
        size: 2
      }
    ],
    'time-control': [
      {
        key: 'TodayTimeControl',
        content: <TodayTimeControl className="w-100 h-100" />,
        size: 1
      },
      {
        key: 'TimeControlPartners',
        content: <TimeControlPartners className="w-100 h-100 flex-grow-1" />,
        size: 2
      },
      {
        key: 'NextHoliday',
        content: <NextHoliday className="w-100 h-100" />,
        size: 1
      }
    ],
    'time-off': [
      {
        key: 'NextTimesOff',
        content: <NextTimesOff className="w-100 h-100" />,
        size: 2
      },
      {
        key: 'PendingTimesOff',
        content: <PendingTimesOff className="w-100 h-100" />,
        size: 2
      }
    ]
  };

  return <Custom apps={apps} />;
};

export default Admin;
