import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Card, ProgressBar } from 'react-bootstrap';
import { ReportsContext } from 'context/Context';

const UsersUsingHappyfy = () => {
  const { department: departmentProp } = useParams();
  const { partners: companyPartners = [] } = useContext(ReportsContext);
  const partners = companyPartners.filter(
    ({ department }) => !departmentProp || department === departmentProp
  );
  const using = partners.filter(
    ({ happiness, lastQuestionnaireAt, token }) =>
      typeof happiness === 'number' || token || lastQuestionnaireAt
  ).length;
  let percentage = Math.round((using * 100) / partners.length);
  if (isNaN(percentage)) {
    percentage = 0;
  }

  return (
    <Card className="border border-3 h-100 shadow-none">
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">Empleados en Happyfy</h6>
      </Card.Header>
      <Card.Body>
        <ProgressBar
          now={percentage}
          key="UsersUsingHappyfy-ProgressBar"
          style={{ height: '6px' }}
          className="lazy-load rounded-pill mt-1 mb-2"
        />

        <p className="mb-0 text-primary">{percentage}% completado</p>
        <p className="mb-0 fs--2 text-500">
          {using} de {partners?.length}
        </p>
      </Card.Body>
    </Card>
  );
};

export default UsersUsingHappyfy;
