import React, { useContext, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Card, Row, Col, Dropdown, Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Calendar from 'components/common/Calendar';
import EmojiMart from 'components/common/EmojiMart';
import { Link } from 'react-router-dom';
import { CalendarContext, UserContext } from 'context/Context';
import dayjs from 'dayjs';
import { useJsApiLoader } from '@react-google-maps/api';

const EventDetailHeader = ({ event = {}, isFeed }) => {
  const libraries = useRef(['geocode', 'places']);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: libraries.current
  });
  const { company, me } = useContext(UserContext);
  const { update } = useContext(CalendarContext);
  const [eventBanner, setEventBanner] = useState();
  const {
    NO_ID_FIELD,
    assistance = [],
    placeId,
    placeImageIndex = 0,
    reactions: rawReactions = [],
    start,
    end,
    title
  } = event;
  const day = dayjs(start).format('DD');
  const month = dayjs(start).format('MMM');
  const { name: organizer = '' } = company || {};
  const reactions = Object.entries(
    rawReactions.reduce((all, { reaction }) => {
      return { ...all, [reaction]: (all?.[reaction] || 0) + 1 };
    }, {})
  );
  const { assist } =
    assistance.find(({ ref }) => ref?.id === me?.ref?.id) || {};
  const assistText =
    assist === true
      ? 'Voy'
      : assist === false
      ? 'No voy'
      : assist === null
      ? 'No lo sé'
      : 'Asistencia';

  const handleReaction = async ({ native: reaction }) => {
    const createdAt = new Date().toISOString();
    const reactions = [
      {
        createdAt,
        ref: me?.ref,
        reaction
      },
      ...(rawReactions.filter(({ ref }) => ref?.path !== me?.ref?.path) || [])
    ];
    update(event, { reactions });
  };

  const handleAssist = async assist => {
    const createdAt = new Date().toISOString();
    const _assistance = [
      {
        assist,
        createdAt,
        ref: me?.ref
      },
      ...(assistance.filter(({ ref }) => ref?.path !== me?.ref?.path) || [])
    ];
    update(event, { assistance: _assistance });
  };

  useMemo(() => {
    if (eventBanner || !placeId || !window.google?.maps?.places) {
      return;
    }
    const placeService = new window.google.maps.places.PlacesService(
      document.createElement('div')
    );
    placeService.getDetails({ placeId }, (place, status) => {
      if (status === 'OK') {
        const { photos } = place;
        const url = photos?.[placeImageIndex]?.getUrl();
        setEventBanner(url);
      }
    });
  }, [isFeed, placeId, window.google?.maps?.places]);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Card className={classNames('p-0 mb-3', { 'shadow-none': isFeed })}>
      {!!eventBanner && (
        <img
          className={classNames('card-img-top fit-cover', {
            'max-vh-25': isFeed,
            'max-vh-50': !isFeed
          })}
          src={eventBanner}
          alt=""
        />
      )}
      <Card.Body>
        <Row className="flex-center">
          <Col>
            <Flex>
              <Calendar day={day} month={month} color="info" />
              <div className="fs--1 ms-2 flex-1">
                {isFeed ? (
                  <h5 className="fs-0 text-capitalize">
                    <Link
                      className="text-900"
                      to={`/company/events/${NO_ID_FIELD}`}
                    >
                      {title}
                    </Link>
                  </h5>
                ) : (
                  <h5 className="fs-0 text-capitalize">{title}</h5>
                )}
                <p className="mb-1 text-capitalize">
                  Organizado por
                  <span className="ms-1 text-primary">{organizer}</span>
                </p>
                <span className="fs-0 text-warning fw-semi-bold">
                  {dayjs(start).format('HH:mm')}
                  {end && <> - {dayjs(end).format('HH:mm')}</>}
                </span>
              </div>
            </Flex>
          </Col>
          <Col md="auto" className="mt-4 mt-md-0 max-w-400px">
            <Flex
              wrap="wrap"
              justifyContent="end"
              className={classNames('gap-2', { 'mt-2': isFeed })}
            >
              <EmojiMart
                onEmojiSelect={handleReaction}
                size={classNames({ sm: isFeed })}
              />
              {reactions.map(([key, value], index) => (
                <Button
                  key={`Reaction-${NO_ID_FIELD}-${index}`}
                  variant="falcon-default"
                  onClick={() => handleReaction({ native: key })}
                  size={classNames({ sm: isFeed })}
                >
                  {key} <span className="ms-1">{value}</span>
                </Button>
              ))}
              <Dropdown className="z-index-1016" align="end">
                <Dropdown.Toggle
                  className="dropdown-caret-none px-4 px-sm-5"
                  variant={
                    typeof assist === 'undefined' ? 'falcon-default' : 'primary'
                  }
                  size={classNames({ sm: isFeed })}
                >
                  {assistText}
                </Dropdown.Toggle>
                <Dropdown.Menu className="py-2">
                  <Dropdown.Item
                    active={assist === true}
                    onClick={() => handleAssist(true)}
                  >
                    Voy
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={assist === false}
                    onClick={() => handleAssist(false)}
                  >
                    No voy
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={assist === null}
                    onClick={() => handleAssist(null)}
                  >
                    No lo sé
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

EventDetailHeader.propTypes = {
  event: PropTypes.object,
  isFeed: PropTypes.bool
};

export default EventDetailHeader;
