import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popover from 'components/common/Popover';
import AppContext from 'context/Context';
import Picker from '@emoji-mart/react';

export default function EmojiMart({ buttonClassName, size, ...props }) {
  const emojiPicker = useRef();
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [message, setMessage] = useState('');

  const addEmoji = e => {
    let emoji = e.native;
    setMessage(message + emoji);
  };

  return (
    <div className="position-relative z-index-1016">
      <Popover
        body
        arrowProps={{ styles: { display: 'none' } }}
        placement="auto"
        className="max-w-100"
        popoverClass="border-0 bg-transparent w-0 h-0 ms-n3 mt-2"
        content={
          <Picker
            ref={emojiPicker}
            locale={'es'}
            onEmojiSelect={addEmoji}
            theme={isDark ? 'dark' : 'light'}
            previewPosition="none"
            skinTonePosition="none"
            {...props}
          />
        }
      >
        <Button
          className={buttonClassName}
          variant="falcon-primary"
          size={size}
        >
          <FontAwesomeIcon icon={['far', 'laugh-beam']} transform="" />
        </Button>
      </Popover>
    </div>
  );
}

EmojiMart.propTypes = {
  buttonClassName: PropTypes.string,
  size: PropTypes.string
};

export { EmojiMart };
