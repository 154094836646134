import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';

const TextQuestion = ({ id, register = () => {} }) => {
  return (
    <Form.Control
      id={id}
      as="textarea"
      placeholder="Escribe tu respuesta"
      {...register(id)}
    />
  );
};

const NumberQuestion = ({
  id,
  setValue = () => {},
  watch = () => {},
  ...rest
}) => {
  const { values } = rest;

  const active = watch(id);

  const handleVote = value => {
    setValue(id, value);
  };

  return (
    <div id={id}>
      <Flex alignItems="center">
        <span>0</span>
        <FontAwesomeIcon icon="arrow-right" className="m-1 fs--1" />
        <span>{rest[0]}</span>
      </Flex>
      <Flex alignItems="center">
        <span>{values.length - 1}</span>
        <FontAwesomeIcon icon="arrow-right" className="m-1 fs--1" />
        <span>{rest[values.length - 1]}</span>
      </Flex>
      <Flex className="mt-3" wrap="wrap">
        {values.map((value, index) => {
          return (
            <Button
              key={`Value-${id}-${index}`}
              className="m-1"
              variant={index === active ? 'primary' : 'outline-primary'}
              onClick={() => handleVote(index)}
            >
              {index}
            </Button>
          );
        })}
      </Flex>
    </div>
  );
};

const questionTypes = {
  text: TextQuestion,
  number: NumberQuestion
};

const Question = props => {
  const { question, type } = props;
  const QuestionType = questionTypes[type] || 'div';

  return (
    <Card className="mb-3">
      <Card.Header className="pb-0">
        <h5 className="mb-0">{question}</h5>
      </Card.Header>
      <Card.Body>
        <QuestionType {...props} />
      </Card.Body>
    </Card>
  );
};
const Questionaire = ({ questionaire, ...rest }) => {
  const { definitions } = questionaire;
  return (
    <>
      {definitions?.map((item, index) => (
        <Question
          key={`Question-${index}`}
          id={`entry-${index}`}
          {...item}
          {...rest}
        />
      ))}
    </>
  );
};

TextQuestion.propTypes = {
  id: PropTypes.string,
  register: PropTypes.func
};

NumberQuestion.propTypes = {
  id: PropTypes.string,
  setValue: PropTypes.func,
  watch: PropTypes.func
};

Question.propTypes = {
  question: PropTypes.string,
  type: PropTypes.string
};

Questionaire.propTypes = {
  questionaire: PropTypes.object
};

export default Questionaire;
