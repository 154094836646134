import React, { useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Card, Form } from 'react-bootstrap';
import { Range } from 'react-range';
import { useForm } from 'react-hook-form';
import { components } from 'react-select';
import Lottie from 'lottie-react';
import EmployeeSelector from 'components/common/EmployeeSelector';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { MAX_APPRECIATIONS } from './AppreciationProvider';
import { AppreciationContext, UserContext } from 'context/Context';
import confetti from 'assets/img/animated-icons/confetti.json';

const RangeSlider = ({ externalRef, setValue, value = 0 }) => {
  const { remaining = MAX_APPRECIATIONS } = useContext(AppreciationContext);
  if (!remaining) {
    return null;
  }

  return (
    <div className="px-5 py-2 mb-2">
      <Range
        ref={externalRef}
        step={1}
        min={0}
        max={remaining}
        values={[value]}
        onChange={values => setValue('value', values[0])}
        renderTrack={({ props, children }) => {
          const {
            props: { 'aria-valuenow': value, 'aria-valuemax': max }
          } = children[0];
          const percent = parseInt((value * 100) / max, 10);
          return (
            <div
              {...props}
              style={{
                // eslint-disable-next-line react/prop-types
                ...props.style,
                height: '6px'
              }}
            >
              <div className="h-100 bg-200 position-absolute start-0 end-0 mx-n5 rounded-5" />
              <div
                className="h-100 bg-primary position-absolute start-0 end-0 mx-n5 rounded-5"
                style={{ width: `calc(${percent}% + 32px)` }}
              />
              {children}
            </div>
          );
        }}
        renderThumb={({ props, value }) => (
          <div {...props} className="outline-none">
            <Button
              variant="falcon-default"
              className="fs-0 rounded-5"
              style={{ minWidth: '100px' }}
              size="sm"
            >
              <span className="me-1">{value}</span>
              👏🏼
            </Button>
          </div>
        )}
      />
    </div>
  );
};

RangeSlider.propTypes = {
  externalRef: PropTypes.object,
  setValue: PropTypes.func,
  value: PropTypes.number
};

const Menu = ({ children, ...rest }) => {
  return (
    <components.Menu
      {...rest}
      className="overflow-hidden mt-0 border-top border-200 rounded-top-0 shadow"
    >
      {children}
    </components.Menu>
  );
};

Menu.propTypes = {
  children: PropTypes.node
};

const Option = ({ data, isFocused, isSelected, ...rest }) => {
  const { avatar, label } = data;
  return (
    <components.Option
      {...rest}
      className={classNames('m-0', {
        'bg-primary text-white': isSelected,
        'bg-soft-primary text-dark': isFocused && !isSelected
      })}
    >
      <Flex alignItems="center">
        <Avatar className="me-2" size="s" src={avatar} name={label} />
        <span
          className={classNames({
            'text-white': isSelected
          })}
        >
          {label}
        </span>
      </Flex>
    </components.Option>
  );
};

Option.propTypes = {
  data: PropTypes.object,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool
};

const Placeholder = ({ selectProps, ...rest }) => {
  const { placeholder } = selectProps;
  return (
    <components.Placeholder {...rest} className="my-1">
      <Flex alignItems="center">
        <Avatar className="me-2" size="s" name="" />
        <span>{placeholder}</span>
      </Flex>
    </components.Placeholder>
  );
};

Placeholder.propTypes = {
  selectProps: PropTypes.object
};

const SingleValue = ({ data, ...rest }) => {
  const { avatar, label } = data;
  return (
    <components.SingleValue {...rest}>
      <Flex alignItems="center">
        <Avatar className="me-2" size="s" src={avatar} name={label} />
        <span>{label}</span>
      </Flex>
    </components.SingleValue>
  );
};

SingleValue.propTypes = {
  data: PropTypes.object
};

const styles = {
  control: base => ({
    ...base,
    fontFamily:
      'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',
    boxShadow: 'none !important',
    fontSize: '0.8rem',
    borderRadius: 0,
    border: 'none !important',
    paddingLeft: '1.25rem'
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  }),
  input: base => ({ ...base, paddingLeft: '28px', boxShadow: 'none' })
};

const CreateAppreciation = () => {
  const { create } = useContext(AppreciationContext);
  const { me, partners = [] } = useContext(UserContext);
  const [isCreated, setCreated] = useState();
  const { handleSubmit, ...form } = useForm();
  const { register, reset, setValue, watch } = form;
  const { targetId, text, value } = watch();
  const range = useRef();
  const select = useRef();
  const textWrapper = useRef();
  const options = partners
    .map(({ NO_ID_FIELD, avatar, name }) => ({
      avatar,
      label: name,
      value: NO_ID_FIELD
    }))
    .filter(({ value }) => value !== me?.NO_ID_FIELD);

  const { remaining = 100 } = useContext(AppreciationContext);

  const handleTargetChange = data => {
    const { value = '' } = data || {};
    setValue('targetId', value);
  };

  const onSubmitData = async data => {
    if (!value) {
      const thumb = range.current.thumbRefs?.[0]?.current?.firstElementChild;
      thumb?.classList.add('horizontal-slide');
      setTimeout(() => {
        thumb?.classList.remove('horizontal-slide');
      }, 600);
      return;
    }
    if (!targetId) {
      select.current.focus();
      return;
    }
    if (!text) {
      textWrapper.current?.firstElementChild?.focus?.();
      return;
    }
    setCreated(true);
    await create({ ...data, icon: '👏🏼' });
    reset();
    select.current?.clearValue();
    setTimeout(() => setCreated(false), 5000);
  };

  return (
    <>
      {isCreated && (
        <div className="position-absolute w-100 h-100 overflow-hidden pointer-none z-index-2">
          <Lottie
            animationData={confetti}
            className="position-absolute bottom-0 mb-5"
            loop={false}
          />
        </div>
      )}
      <Form onSubmit={handleSubmit(onSubmitData)}>
        <Card.Body className="p-0 border-bottom border-200">
          <Flex direction="column" className="g-3">
            <div className="border-bottom border-200 p-3 pb-2 px-x1 rounded-3 rounded-bottom-0 overflow-hidden">
              <RangeSlider {...form} externalRef={range} value={value} />
              {remaining === 0 && (
                <div className="text-400 fs--1">
                  Has gastado todos los reconocimientos este mes
                </div>
              )}
            </div>
            <Form.Group className="border-bottom border-200">
              <EmployeeSelector
                externalRef={select}
                options={options}
                placeholder="¿A quién quieres reconocer?"
                onChange={handleTargetChange}
                isDisabled={!remaining}
                components={{ Menu, Option, Placeholder, SingleValue }}
                styles={styles}
              />
            </Form.Group>
            <Form.Group ref={textWrapper}>
              <Form.Control
                as="textarea"
                className="bg-white dark__bg-1100 px-x1 resize-none shadow-none rounded-0 border-0"
                disabled={!remaining}
                placeholder="Escríbele algo"
                rows={3}
                {...register('text')}
              />
            </Form.Group>
          </Flex>
        </Card.Body>
        <Card.Footer alignItems="center" as={Flex}>
          <div className="flex-1"></div>
          <Button
            disabled={!remaining}
            type="submit"
            className="px-4 px-sm-5 w-100"
            size="sm"
          >
            Aplaudir
          </Button>
        </Card.Footer>
      </Form>
    </>
  );
};

export default CreateAppreciation;
