import React, { useContext } from 'react';
import ChatContentHeader from './ChatContentHeader';
import ChatContentBody from './ChatContentBody';
import MessageTextArea from './MessageTextArea';
import { ChatContext } from 'context/Context';
import Flex from 'components/common/Flex';
import illustration from 'assets/img/icons/spot-illustrations/1.svg';

const ChatContent = () => {
  const { currentChat } = useContext(ChatContext);

  return (
    <div className="card-chat-content tab-content w-0">
      <Flex className="h-100" direction="column">
        {(currentChat && (
          <>
            <ChatContentHeader chat={currentChat} />
            <ChatContentBody chat={currentChat} className="h-0 flex-grow-1" />
            <MessageTextArea />
          </>
        )) || (
          <Flex
            direction="column"
            className="bg-250 h-100"
            alignItems="center"
            justifyContent="center"
          >
            <img className="w-50" src={illustration} alt="" />
            <h4 className="mt-3 fw-normal">Happyfy chat</h4>
            <p className="text-center px-3">
              Envía y recibe mensajes con tus compañeros de empresa
            </p>
          </Flex>
        )}
      </Flex>
    </div>
  );
};

export default ChatContent;
