import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Card, ListGroup } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';

const styles = {
  control: base => ({
    ...base,
    borderRadius: '1.375rem'
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  })
};

const Menu = ({ children, ...rest }) => {
  return (
    <components.Menu
      {...rest}
      className="overflow-hidden min-w-300px mt-0 border-top border-200 shadow"
    >
      {children}
    </components.Menu>
  );
};

Menu.propTypes = {
  children: PropTypes.node
};

const Admin = ({ user }) => {
  const { me, updateUser } = useContext(UserContext);
  const { NO_ID_FIELD, avatar, name } = user;

  const handleDelete = async () => {
    updateUser(user, { type: 'employee' });
  };

  return (
    <ListGroup.Item className="fs--1 bg-light px-0">
      <Flex alignItems="center" justifyContent="between">
        <Flex alignItems="center">
          <Avatar src={avatar} name={name} className="me-2" />
          {name}
        </Flex>
        {me?.NO_ID_FIELD !== NO_ID_FIELD && (
          <Flex alignItems="center">
            <Button
              variant="falcon-default"
              className="hover-danger"
              size="sm"
              onClick={handleDelete}
            >
              <FontAwesomeIcon icon="trash" />
            </Button>
          </Flex>
        )}
      </Flex>
    </ListGroup.Item>
  );
};

const Admins = () => {
  const { partners = [], updateUser } = useContext(UserContext);
  const [inputVisibility, setInputVisibility] = useState(false);
  const [newAdmins, setNewAdmins] = useState([]);
  const admins = partners?.filter(({ type }) => type === 'admin');

  const handleChange = newAdmins => {
    setNewAdmins(newAdmins);
  };

  const handleAdd = async () => {
    await Promise.all(
      newAdmins.map(({ label: name, value: NO_ID_FIELD }) => {
        const user = { NO_ID_FIELD, name };
        updateUser(user, { type: 'admin' });
      })
    );
    setInputVisibility(false);
    setNewAdmins([]);
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Administradores" />
      <Card.Body className="bg-light pb-0">
        <div>
          <ListGroup variant="flush">
            {admins.map(admin => (
              <Admin key={`Admin-${admin?.NO_ID_FIELD}`} user={admin} />
            ))}
          </ListGroup>
          {inputVisibility && (
            <>
              <Select
                classNamePrefix="react-select"
                className="w-100 mt-3 mb-2"
                isClearable
                isMulti
                isSearchable
                placeholder="Selecciona..."
                name="color"
                menuPortalTarget={document.body}
                options={partners
                  ?.filter(({ type }) => type !== 'admin')
                  .map(({ NO_ID_FIELD, name }) => ({
                    label: name,
                    value: NO_ID_FIELD
                  }))}
                onChange={handleChange}
                components={{ Menu }}
                styles={styles}
              />
              <ButtonGroup className="w-100 mb-3">
                <Button
                  variant="outline-secondary"
                  onClick={() => setInputVisibility(false)}
                >
                  Cancelar
                </Button>
                <Button className="w-100" onClick={handleAdd}>
                  Añadir
                </Button>
              </ButtonGroup>
            </>
          )}
        </div>
      </Card.Body>
      {!inputVisibility && (
        <Card.Footer className="bg-light py-0 text-center">
          <Button
            variant="link"
            size="sm"
            className="py-2"
            onClick={() => setInputVisibility(true)}
          >
            <FontAwesomeIcon icon="plus" className="fs--2 me-1" />
            Añadir
          </Button>
        </Card.Footer>
      )}
    </Card>
  );
};

Admin.propTypes = {
  user: PropTypes.object
};

export default Admins;
