import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap';
import Calendar from 'components/common/Calendar';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import Popover from 'components/common/Popover';
import SoftBadge from 'components/common/SoftBadge';
import Tooltip from 'components/common/Tooltip';
import { CalendarContext, UserContext } from 'context/Context';
import ProofButton from './ProofButton';
import timeOffSubtypes from 'components/user/time-off/subtypes.json';

dayjs.locale('es');

const TimeOff = ({
  className,
  color,
  details = {},
  isLast,
  showUser = true,
  user: userProp,
  ...rest
}) => {
  const { me, partners = [] } = useContext(UserContext);
  const {
    timeOff,
    deleteItem,
    setIsOpenNewTimeOffModal,
    setEditingEvent,
    update
  } = useContext(CalendarContext);
  const [extraParams, setExtraParams] = useState({});
  const [now] = useState(new Date().toISOString());
  const {
    NO_ID_FIELD,
    approved = null,
    comment,
    days,
    start,
    end,
    participants = [],
    subtype
  } = details;
  const participant = participants?.[0];
  const user =
    userProp ||
    partners.find(partner => partner.ref?.path === participant?.path) ||
    {};
  const { avatar, department, location, name } = user;
  const { holidays = [] } = location || {};
  const startDay = dayjs(start).format('DD');
  const startMonth = dayjs(start).format('MMM');
  let _end = new Date(new Date(end).setDate(new Date(end).getDate() + 1));
  while (
    _end &&
    ([6, 0].includes(_end.getDay()) ||
      holidays?.some(
        ({ start }) =>
          dayjs(start).format('YYYY-MM-DD') === dayjs(_end).format('YYYY-MM-DD')
      ))
  ) {
    _end.setDate(_end.getDate() + 1);
  }
  const endDate = dayjs(_end).format('dddd, DD MMMM YYYY');
  const departmentPartners = partners?.filter(
    partner => partner.department === department
  );
  const departmentPartnersWithTimeOff = departmentPartners?.filter(partner => {
    const partnerTimeOff = timeOff?.filter(({ participants }) =>
      participants?.some(ref => ref?.path === partner?.ref?.path)
    );
    const hasMatch = partnerTimeOff.some(
      timeOff =>
        (timeOff.start >= start && timeOff.start <= end) ||
        (timeOff.end >= start && timeOff.end <= end)
    );
    return hasMatch;
  });
  const departmentPartnersWorking =
    (departmentPartners?.length || 0) -
    (departmentPartnersWithTimeOff?.length || 0);
  const hasAlert =
    participants?.length &&
    me?.type === 'admin' &&
    departmentPartnersWorking < 2;
  const alertText = `El departamento de ${department?.toLowerCase()} se queda con ${departmentPartnersWorking} empleados`;

  const handleClickAction = async approved => {
    await update(details, { approved, ...extraParams });
    setExtraParams({});
    const els = document.querySelectorAll(`[data-id="${NO_ID_FIELD}"]`);
    els?.forEach(el => {
      el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.remove(
        'highlight'
      );
    });
  };

  const handleEdit = () => {
    setEditingEvent(details);
    setIsOpenNewTimeOffModal(true);
  };

  const handleDelete = async () => {
    await deleteItem(details);
    const els = document.querySelectorAll(`[data-id="${NO_ID_FIELD}"]`);
    els?.forEach(el => {
      el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.remove(
        'highlight'
      );
    });
  };

  const subtypeData =
    timeOffSubtypes.find(({ value }) => value === subtype) || {};

  return (
    <div id={`${NO_ID_FIELD}`} className={className} {...rest}>
      <Row>
        <Col xs={12} sm="auto" className="flex-grow-1">
          <Flex className="mb-3">
            <Calendar day={startDay} month={startMonth} color={color} />
            <div className="px-3 w-0 flex-grow-1">
              {showUser && (avatar || name) && (
                <Flex alignItems="center" className="mb-1">
                  <h6 className="m-0">{name}</h6>
                  <Avatar
                    src={avatar}
                    name={name}
                    className="ms-2 my-n1"
                    size="m"
                  />
                </Flex>
              )}
              <h6 className="m-0">
                {!subtypeData?.time
                  ? `${days} ${days > 1 ? 'días' : 'día'}`
                  : `${dayjs(start).format('H:mm')} - ${dayjs(end).format(
                      'H:mm'
                    )}`}{' '}
                {subtype !== 'vacation' && (
                  <SoftBadge
                    bg={subtypeData?.color}
                    className="ms-2 max-w-100 text-truncate"
                    pill
                  >
                    {subtypeData?.label}
                  </SoftBadge>
                )}
              </h6>
              {!subtypeData?.time && (
                <p className="fs--1 m-0">Vuelve el {endDate}</p>
              )}
              {!participants?.length && (
                <SoftBadge pill>Toda la empresa</SoftBadge>
              )}
              {hasAlert && (
                <Tooltip title={alertText}>
                  <SoftBadge
                    bg={classNames({
                      danger: departmentPartnersWorking === 0,
                      warning: departmentPartnersWorking > 0
                    })}
                    className="mt-1 cursor-default"
                    pill
                  >
                    {new Array(2 - departmentPartnersWorking)
                      .fill()
                      .map((_, index) => (
                        <FontAwesomeIcon
                          key={`Exclamation-${NO_ID_FIELD}-${index}`}
                          icon="exclamation"
                        />
                      ))}
                    <span className="ms-1">{departmentPartnersWorking}</span>
                  </SoftBadge>
                </Tooltip>
              )}
            </div>
          </Flex>
        </Col>
        <Col xs={12} sm="auto">
          {(me?.type === 'admin' ||
            user?.NO_ID_FIELD === me?.NO_ID_FIELD ||
            hasAlert) && (
            <Flex alignItems="start" className="w-100">
              <ButtonGroup vertical className="w-100">
                {approved === null && me?.type === 'admin' && (
                  <>
                    <Button
                      variant="falcon-success"
                      size="sm"
                      onClick={() => handleClickAction(true)}
                    >
                      Aprobar
                    </Button>
                    <Popover
                      className="btn btn-falcon-danger btn-sm"
                      popoverClass="max-w-100"
                      content={
                        <>
                          <Form.Control
                            as="textarea"
                            className="resize-none"
                            value={extraParams?.comment || ''}
                            rows={5}
                            onChange={({ target }) =>
                              setExtraParams(params => ({
                                ...params,
                                comment: target.value
                              }))
                            }
                            placeholder="Deja algún comentario"
                            autoFocus
                          />
                          <Button
                            className="w-100 mt-3"
                            variant="danger"
                            onClick={() => handleClickAction(false)}
                          >
                            Denegar
                          </Button>
                        </>
                      }
                    >
                      Denegar
                    </Popover>
                  </>
                )}
                {subtypeData?.proof && (
                  <ProofButton timeOff={details} user={user} />
                )}
                {((user?.NO_ID_FIELD === me?.NO_ID_FIELD &&
                  now < start &&
                  approved !== false) ||
                  me?.type === 'admin') && (
                  <>
                    {approved === null && me?.type === 'admin' && (
                      <hr className="h-0 w-100 m-0 border border-300 border-2" />
                    )}
                    <Button
                      variant="falcon-default"
                      size="sm"
                      onClick={handleEdit}
                    >
                      Editar
                    </Button>
                    <Button
                      variant="falcon-danger"
                      size="sm"
                      onClick={handleDelete}
                    >
                      Eliminar
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </Flex>
          )}
        </Col>
      </Row>
      {comment && (
        <div className="mt-2">
          <SoftBadge
            bg="secondary"
            className="text-start text-wrap py-3 rounded-4 w-100"
          >
            {comment}
          </SoftBadge>
        </div>
      )}
      {!isLast && <div className="border-dashed border-bottom my-3"></div>}
    </div>
  );
};

TimeOff.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  details: PropTypes.shape({
    approved: PropTypes.bool,
    calendar: PropTypes.shape(Calendar.propTypes),
    title: PropTypes.string.isRequired,
    organizerId: PropTypes.string,
    time: PropTypes.string,
    place: PropTypes.string,
    location: PropTypes.string,
    duration: PropTypes.string,
    interested: PropTypes.string,
    badge: PropTypes.object
  }),
  isLast: PropTypes.bool,
  isPast: PropTypes.bool,
  showUser: PropTypes.bool,
  user: PropTypes.object
};

export default TimeOff;
