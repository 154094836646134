import React, { useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import classNames from 'classnames';
import Logo from 'components/common/Logo';
import { useLocation } from 'react-router-dom';

const NavbarTop = () => {
  const { pathname } = useLocation();
  const isChat = pathname.includes('chat');

  const [showDropShadow, setShowDropShadow] = useState(false);

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  return (
    <Navbar
      className={classNames('navbar-glass fs--1 navbar-top sticky-kit', {
        'navbar-glass-shadow': showDropShadow && !isChat
      })}
    >
      <Logo at="navbar-top" width={128} id="topLogo" />
    </Navbar>
  );
};

export default NavbarTop;
