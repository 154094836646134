import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import Tooltip from 'components/common/Tooltip';
import Item from './Item';
import AddItem from './AddItem';
import { UserContext } from 'context/Context';
import interestsList from 'data/interests.js';

const Interests = ({ className, color, user }) => {
  const { me } = useContext(UserContext);
  const { profile = {} } = user || {};
  const { interests = [] } = profile;

  return (
    <Card className={className}>
      <FalconCardHeader
        title="Intereses"
        light
        endEl={
          <Tooltip title="Los intereses no se mostrarán en tu perfil público. Sirven para poder ofrecerte beneficios que te puedan interesar.">
            <div>
              <FontAwesomeIcon icon="question-circle" className="mx-2" />
            </div>
          </Tooltip>
        }
      />
      <Card.Body>
        <Flex wrap="wrap">
          {interests.map(tag => (
            <Item
              key={`Interests-${tag}`}
              color={color}
              profileKey="interests"
              text={tag}
              user={user}
            />
          ))}
          {(me?.type === 'admin' || me?.NO_ID_FIELD === user?.NO_ID_FIELD) && (
            <AddItem
              color={color}
              options={interestsList}
              placeholder="Intereses"
              profileKey="interests"
              user={user}
            />
          )}
        </Flex>
      </Card.Body>
    </Card>
  );
};

Interests.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  user: PropTypes.object
};

export default Interests;
