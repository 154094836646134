import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import likeActive from 'assets/img/illustrations/like-active.png';
import likeInactive from 'assets/img/illustrations/like-inactive.png';
import commentActive from 'assets/img/illustrations/comment-active.png';
import commentInActive from 'assets/img/illustrations/comment-inactive.png';
import { FeedContext, UserContext } from 'context/Context';

const FeedActionButtons = ({ feed, input, setShowComment }) => {
  const { comments = [], likes = [] } = feed;
  const { updateFeed } = useContext(FeedContext);
  const { me } = useContext(UserContext);
  const isCommented = comments?.some(
    ({ userId }) => userId === me?.NO_ID_FIELD
  );
  const like = likes?.some(({ userId }) => userId === me?.NO_ID_FIELD);

  const toggleLiked = () => {
    const _likes = like
      ? likes.filter(({ userId }) => userId !== me?.NO_ID_FIELD)
      : [
          ...(likes || []),
          { userId: me?.NO_ID_FIELD, createdAt: new Date().toISOString() }
        ];
    updateFeed(feed, { likes: _likes });
  };
  return (
    <Row className="g-0 fw-semi-bold text-center py-2 fs--1">
      <Col xs="auto">
        <Flex
          alignItems="center"
          className="rounded text-700 me-3 cursor-pointer"
          onClick={toggleLiked}
        >
          <img src={like ? likeActive : likeInactive} alt="" width="20" />
          <span className="ms-1">Like</span>
        </Flex>
      </Col>
      <Col xs="auto">
        <Flex
          alignItems="center"
          className="rounded text-700 me-3 cursor-pointer"
          onClick={() => {
            setShowComment(true);
            input?.current?.focus();
          }}
        >
          <img
            src={isCommented ? commentActive : commentInActive}
            alt=""
            width="20"
          />
          <span className="ms-1">Comentar</span>
        </Flex>
      </Col>
    </Row>
  );
};

FeedActionButtons.propTypes = {
  feed: PropTypes.object,
  input: PropTypes.object,
  setShowComment: PropTypes.func
};

export default FeedActionButtons;
