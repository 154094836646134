import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, Row } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import EmployeeSelector from 'components/common/EmployeeSelector';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import {
  ticketBadges,
  ticketPriorities,
  ticketTypes
} from 'components/feedback';
import { FeedbackContext, UserContext } from 'context/Context';
import { getColor } from 'helpers/utils';

const PrioritySelect = ({ title, color, data, className, style }) => {
  return (
    <div
      style={style}
      className={classNames('d-flex align-items-center gap-2', className)}
    >
      <div style={{ '--falcon-circle-progress-bar': data }}>
        <svg
          className="circle-progress-svg"
          width="26"
          height="26"
          viewBox="0 0 120 120"
        >
          <circle
            className="progress-bar-rail"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            strokeWidth="12"
          ></circle>
          <circle
            className="progress-bar-top"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            stroke={getColor(color)}
            strokeWidth="12"
          ></circle>
        </svg>
      </div>
      <h6 className="mb-0 text-700">{title}</h6>
    </div>
  );
};

PrioritySelect.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export const AgentSelect = ({
  feedback,
  className,
  placeholder = 'Sin encargado',
  style,
  onChange,
  ...props
}) => {
  const { update } = useContext(FeedbackContext);
  const { me, partners = [] } = useContext(UserContext);
  const { agent } = feedback || {};
  const user = partners?.find(({ NO_ID_FIELD }) => NO_ID_FIELD === agent);

  const handleChangeEmployee = option => {
    const { value } = option;
    onChange ? onChange(option) : update(feedback, { agent: value });
  };

  return (
    <EmployeeSelector
      className={classNames('text-nowrap', className, {
        'bg-light': me?.type !== 'admin'
      })}
      isClearable={false}
      isDisabled={!onChange && me?.type !== 'admin'}
      value={
        user && {
          avatar: user?.avatar,
          label: user?.name,
          value: user?.NO_ID_FIELD
        }
      }
      onChange={handleChangeEmployee}
      placeholder={placeholder}
      style={style}
      {...props}
    />
  );
};

AgentSelect.propTypes = {
  feedback: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func
};

export const columns = [
  {
    accessor: 'user.name',
    Header: 'Empleado',
    headerProps: { style: { height: '46px' } },
    cellProps: (cell, cellData) => {
      const { totalLeft } = cellData.cell.column || {};

      return {
        className: classNames('p-0 white-space-nowrap pe-3 pe-xxl-4', {
          'ps-3': totalLeft === 0
        })
      };
    },
    Cell: rowData => {
      const { user } = rowData.row.original;
      const { NO_ID_FIELD, department, name, avatar } = user;
      return (
        <Flex
          alignItems="center"
          className="position-relative py-2 pe-2 z-index-2"
        >
          <Avatar src={avatar} name={name} size="xl" className="me-2" />
          <div>
            <h6 className="mb-0">
              <Link
                to={`/feedback/contact-details/${NO_ID_FIELD}`}
                className="stretched-link text-900 z-index-2"
              >
                {name}
              </Link>
            </h6>
            <span className="text-500">{department}</span>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'feedback',
    Header: 'Sugerencia',
    headerProps: {
      className: 'border-0 d-inline-flex h-46px align-items-center'
    },
    Cell: rowData => {
      const { NO_ID_FIELD, createdAt, feedback } = rowData.row.original;
      return (
        <>
          <h6 className="mb-0">
            <Link
              to={`/feedback/tickets-preview/${NO_ID_FIELD}`}
              className="stretched-link fw-semi-bold"
            >
              {`${feedback.substr(0, 60)}${feedback.length > 60 ? '...' : ''}`}
            </Link>
          </h6>
          <span className="text-500">
            {dayjs(createdAt)
              .calendar(null, {
                sameDay: `[${dayjs().to(dayjs(createdAt))}]`,
                lastDay: '[ayer], HH:mm',
                lastWeek: 'dddd, HH:mm',
                sameElse: 'ddd, D MMM, HH:mm'
              })
              .replace('.', '')}
          </span>
        </>
      );
    }
  },
  {
    accessor: 'createdAt',
    Header: 'Fecha de creación',
    headerProps: {
      className:
        'ms-2 border-0 d-inline-flex h-46px align-items-center flex-grow-1'
    },
    cellProps: { className: 'd-none' },
    Cell: rowData => {
      return rowData.row.original.createdAt;
    }
  },
  {
    accessor: 'tags',
    Header: 'Tags',
    headerProps: { className: 'd-none' },
    cellProps: { className: 'd-none' },
    Cell: rowData => {
      const { NO_ID_FIELD, tags } = rowData.row.original;
      return (
        !!tags?.length && (
          <Flex className="gap-1 mt-2">
            {tags.map(tag => (
              <SoftBadge
                key={`Feedback-${NO_ID_FIELD}-Tag-${tag}`}
                bg="secondary"
              >
                {tag}
              </SoftBadge>
            ))}
          </Flex>
        )
      );
    }
  },
  {
    accessor: 'status',
    Header: 'Estado',
    cellProps: {
      className: 'p-0 fs-0'
    },
    Cell: rowData => {
      const { original: feedback } = rowData.row;
      const { status } = feedback;
      const { content, type } =
        ticketBadges.find(({ value }) => value === status) || {};
      return (
        <div className="py-2 pe-2">
          <SoftBadge bg={type} className="me-2">
            {content}
          </SoftBadge>
        </div>
      );
    }
  },
  {
    accessor: 'type',
    Header: 'Tipo',
    cellProps: {
      className: 'p-0 fs-0'
    },
    Cell: rowData => {
      const { original: feedback } = rowData.row;
      const { type } = feedback;
      const { color, title } =
        ticketTypes.find(({ value }) => value === type) || {};
      return (
        <div className="py-2 pe-2">
          <SoftBadge bg={color} className="me-2">
            {title}
          </SoftBadge>
        </div>
      );
    }
  },
  {
    accessor: 'priority',
    Header: 'Prioridad',
    cellProps: {
      className: 'p-0'
    },
    Cell: rowData => {
      const { priority = 'medium' } = rowData.row.original;
      const { color, data, title } =
        ticketPriorities.find(({ value }) => value === priority) || {};
      return (
        <div className="py-2 pe-2">
          <PrioritySelect title={title} color={color} data={data} />
        </div>
      );
    }
  },
  {
    accessor: 'agent',
    Header: 'Encargado',
    cellProps: {
      className: 'p-0'
    },
    Cell: rowData => {
      return (
        <div className="position-relative p-2 z-index-2">
          <AgentSelect
            feedback={rowData.row.original}
            className="w-auto ms-auto"
          />
        </div>
      );
    }
  }
];

export const CardLayout = ({ data, isSelectedItem, toggleSelectedItem }) => {
  return (
    <div className="d-flex flex-column gap-3">
      {data.slice(0, 12).map((ticket, index) => {
        let {
          feedback,
          priority: priorityProp,
          status: statusProp,
          user
        } = ticket;
        let title = `${feedback.substr(0, 30)}`;
        title = title < feedback ? `${title}...` : title;
        const { avatar, name } = user;
        const priority =
          ticketPriorities.find(({ value }) => value === priorityProp) || {};
        const status =
          ticketBadges.find(({ value }) => value === statusProp) || {};

        return (
          <div
            key={index}
            className="bg-white dark__bg-1100 d-md-flex d-xl-inline-block d-xxl-flex align-items-center p-x1 rounded-3 shadow-sm card-view-height w-100"
          >
            <div className="d-flex align-items-start align-items-sm-center">
              <Form.Check
                type="checkbox"
                id="inboxBulkSelect"
                className="fs-0 form-check me-2 me-xxl-3 mb-0"
              >
                <Form.Check.Input
                  type="checkbox"
                  checked={isSelectedItem(ticket.id)}
                  onChange={() => toggleSelectedItem(ticket.id)}
                />
              </Form.Check>
              <Link
                to="/feedback/contact-details"
                className="d-none d-sm-block"
              >
                <Avatar src={avatar} name={name} size="3xl" />
              </Link>
              <div className="ms-1 ms-sm-3">
                <p className="fw-semi-bold mb-3 mb-sm-2">
                  <Link to="/feedback/tickets-preview">{title}</Link>
                </p>
                <Row className="align-items-center gx-0 gy-2">
                  <Col xs="auto" className="me-2">
                    <h6 className="mb-0">
                      <Link
                        to="/feedback/contact-details"
                        className="text-800 d-flex align-items-center gap-1"
                      >
                        <FontAwesomeIcon
                          icon="user"
                          transform="shrink-3 up-1"
                        />
                        <span>{ticket.name}</span>
                      </Link>
                    </h6>
                  </Col>
                  <Col xs="auto" className="lh-1 me-3">
                    <SoftBadge bg={status?.type}>{status?.content}</SoftBadge>
                  </Col>
                  <Col xs="auto">
                    <h6 className="mb-0 text-500">{ticket.date}</h6>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="border-bottom mt-4 mb-x1"></div>
            <div className="d-flex justify-content-between ms-auto">
              <PrioritySelect
                title={priority?.title}
                color={priority?.color}
                data={priority?.data}
                className="ms-md-4 ms-xl-0"
                style={{ width: '7.5rem' }}
              />
              <AgentSelect feedback={ticket} style={{ width: '9.375rem' }} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

CardLayout.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  isSelectedItem: PropTypes.func,
  toggleSelectedItem: PropTypes.func
};
