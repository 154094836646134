import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Tab, Tabs } from 'react-bootstrap';
import { collection, doc, query, where } from '@firebase/firestore';
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData
} from 'reactfire';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Tooltip from 'components/common/Tooltip';
import ReviewProvider from 'components/company/reviews/ReviewProvider';
import UserReviewDetail from 'components/user/reviews/detail';
import { UserContext } from 'context/Context';
import Header from './Header';
import Analytics from './Analytics';
import Participants from './Participants';
import Questions from './Questions';
import Responses from './Responses';
import Settings from './Settings';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

dayjs.extend(relativeTime);
dayjs.locale('es');

const ReviewDetailContent = () => {
  const { me, partners = [] } = useContext(UserContext);
  const { reviewId, key = 'settings' } = useParams();
  const [activeKey, setActiveKey] = useState(key);
  const navigate = useNavigate();
  const ref = useRef();
  const db = useFirestore();
  const reviewRef = doc(db, 'reviews', reviewId);
  const userResponseRef = query(
    collection(db, 'reviews_responses'),
    where('reviewId', '==', reviewId)
  );
  const { data: review } = useFirestoreDocData(reviewRef);
  const { data: responses = [] } = useFirestoreCollectionData(userResponseRef);
  const { createdAt, title, description, type } = review || {};
  const time = dayjs().to(dayjs(createdAt));

  const handleSelect = key => {
    setActiveKey(key);
    navigate(`/company/reviews/detail/${reviewId}/${key}`);
  };

  let users = [me];
  if (type === 'manager') {
    users = partners.filter(
      ({ department, level }) =>
        department === me?.department && level === me?.level - 1
    );
  }
  if (type === 'employeeToManager') {
    users = partners.filter(
      ({ department, level }) =>
        department === me?.department && level === me?.level + 1
    );
  }
  const undone =
    users.length -
    responses.filter(({ userId }) => userId === me?.NO_ID_FIELD).length;

  useEffect(() => {
    setActiveKey(key);
  }, [key]);

  return (
    <>
      <Header review={review} externalRef={ref} />
      <Card>
        <Card.Header>
          <Row>
            <Col md as={Flex}>
              <div className="pe-x1 pt-1">
                <Avatar name={title} size="2xl" />
              </div>
              <div className="flex-1">
                <h5 className="mb-0">{title}</h5>
                <span className="text-500">{description}</span>
              </div>
            </Col>
            <Col md="auto" className="fs--1 ps-6 ps-md-3 text-end">
              <div>{time}</div>
              <div className="text-500">{responses.length} respuesta(s)</div>
            </Col>
          </Row>
        </Card.Header>
        {review && (
          <Tabs
            className="bg-white ps-6"
            activeKey={activeKey}
            onSelect={handleSelect}
          >
            <Tab eventKey="settings" title="Configuración">
              <Settings review={review} hasResponses={responses.length > 0} />
            </Tab>
            <Tab eventKey="questions" title="Preguntas">
              <Questions review={review} hasResponses={responses.length > 0} />
            </Tab>
            {!!users.length && (
              <Tab
                eventKey="review"
                title={
                  <Flex alignItems="center">
                    Evaluación
                    {!!undone && (
                      <Tooltip
                        title={
                          <>
                            <strong>{undone}</strong> empleado(s) por evaluar
                          </>
                        }
                      >
                        <SoftBadge bg="warning" pill className="ms-1">
                          <FontAwesomeIcon
                            icon="exclamation-triangle"
                            className="me-1"
                          />
                          {undone}
                        </SoftBadge>
                      </Tooltip>
                    )}
                  </Flex>
                }
              >
                <UserReviewDetail review={review} responses={responses} />
              </Tab>
            )}
            <Tab eventKey="participants" title="Participantes">
              <Participants review={review} responses={responses} />
            </Tab>
            <Tab eventKey="responses" title="Respuestas">
              <Responses review={review} responses={responses} />
            </Tab>
            <Tab eventKey="analytics" title="Analíticas">
              <Analytics review={review} responses={responses} />
            </Tab>
          </Tabs>
        )}
      </Card>
    </>
  );
};

const ReviewDetail = () => {
  return (
    <ReviewProvider>
      <ReviewDetailContent />
    </ReviewProvider>
  );
};

export default ReviewDetail;
