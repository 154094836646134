import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import Tooltip from 'components/common/Tooltip';
import { UserContext } from 'context/Context';

const AIButton = ({
  className,
  variant = 'warning',
  onClick: handleClick,
  tooltip,
  tooltipClass
}) => {
  const { me } = useContext(UserContext);
  const Tag = handleClick ? Button : SoftBadge;

  if (me?.type !== 'admin') {
    return null;
  }

  return (
    <Tooltip title={tooltip} tooltipClass={tooltipClass}>
      <div className={classNames('d-inline-block z-index-2', className)}>
        <Tag
          className={classNames(`badge badge-soft-${variant} border-0`, {
            'rounded-pill': !!handleClick
          })}
          bg={variant}
          variant={variant}
          onClick={handleClick || null}
        >
          <FontAwesomeIcon icon="magic" className="me-2" />
          <span className="d-inline-block">IA</span>
        </Tag>
      </div>
    </Tooltip>
  );
};

AIButton.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tooltipClass: PropTypes.string
};

export default AIButton;
