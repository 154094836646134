import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import AppContext from 'context/Context';
import NavbarTop from 'components/questionaire-viewer/NavbarTop';
import Footer from 'components/footer/Footer';

const QuestionaireLayout = () => {
  const location = useLocation();
  const { hash, pathname } = location;

  const {
    config: { isFluid }
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        const card = element?.parentElement?.parentElement;
        if (element) {
          card?.classList.add(...['border-4']);
          setTimeout(() => {
            card?.classList.remove(...['border-4']);
          }, 1000);
          element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }
    }, 0);
  }, [hash]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <div className="content">
        <NavbarTop />
        {/*------ Main Routes ------*/}
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default QuestionaireLayout;
