import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import { IndexesContext, UserContext } from 'context/Context';
import { getUnique, groupBy } from 'helpers/utils';
import classNames from 'classnames';
import types from './types.json';

const Departments = ({
  data: raw,
  end = 10,
  header,
  bodyClassName,
  type,
  ...rest
}) => {
  const title = types[type];
  const { indexes = [] } = useContext(IndexesContext);
  raw = raw || indexes;
  const { partners = [] } = useContext(UserContext);
  const departments = Object.entries(groupBy(partners, 'department'))
    .map(([department, users]) => ({ name: department, users }))
    .filter(data => data);
  const dataByUser = Object.entries(groupBy(raw, 'userId'))
    .map(([userId, indexes]) => {
      const user = partners.find(({ id } = {}) => id === userId);
      if (!user) {
        return;
      }
      const data = indexes
        .sort((i1, i2) => (i1.createdAt < i2.createdAt ? -1 : 1))
        .map(({ createdAt, value }) => ({ createdAt, value }))
        .pop();
      return { ...user, data };
    })
    .filter(data => data);
  const dataByDepartment = Object.entries(groupBy(dataByUser, 'department'))
    .map(([department, users]) => {
      department = department === 'undefined' ? '' : department;
      const average = Math.round(
        users.reduce((total, { data }) => total + data.value, 0) / users.length
      );
      return { name: department, value: average, users };
    })
    .sort(({ value: v1 }, { value: v2 }) => (v1 > v2 ? -1 : 1));
  const allDepartments = getUnique(
    [...dataByDepartment, ...departments],
    'name'
  );

  return (
    <Card as={Flex} direction="column" {...rest}>
      <SimpleBarReact className={classNames('h-100', bodyClassName)}>
        {header || (
          <FalconCardHeader
            className="sticky-top"
            title={`Departamentos con más ${title}`}
            titleTag="h6"
          />
        )}
        <Card.Body className="py-2">
          {allDepartments
            .sort((p1, p2) =>
              (p1.value || 0) > (p2.value || 0)
                ? -1
                : (p1.value || 0) < (p2.value || 0)
                ? 1
                : p1.name < p2.name
                ? -1
                : 1
            )
            .slice(0, end)
            .map(({ name, ...props }, index) => (
              <Department
                {...props}
                key={`Department-${name}`}
                name={name}
                isLast={index === allDepartments.length - 1}
              />
            ))}
        </Card.Body>
        <FalconCardFooterLink
          className="sticky-bottom"
          title="Todos los empleados"
          to="/company/employees"
          size="sm"
        />
      </SimpleBarReact>
    </Card>
  );
};

const Department = ({ name, users = [], value, isLast }) => (
  <Flex className={classNames('align-items-center', { 'mb-3': !isLast })}>
    <Avatar name={name} size="2xl" />
    <div className="ms-3 flex-grow-1 w-0">
      {name && <h6 className="mb-0 fw-semi-bold">{name}</h6>}
      <p className="text-500 fs--2 mb-0">
        {users.map(({ NO_ID_FIELD, firstname }, index) => (
          <span key={`Name-${NO_ID_FIELD}`}>
            <Link className="text-500" to={`/user/profile/${NO_ID_FIELD}`}>
              {firstname}
            </Link>
            {index < users.length - 1 && ', '}
          </span>
        ))}
      </p>
    </div>
    <AvatarGroup className="justify-content-end">
      {users.map(({ avatar, name, id }) => {
        return (
          <Avatar
            key={`Department-Avatar-${id}`}
            src={avatar && avatar}
            name={name && name}
            className="border border-3 rounded-circle border-light bg-light"
          />
        );
      })}
    </AvatarGroup>
    <div className="text-end" style={{ width: 40 }}>
      <h5 className="mb-0">{value}</h5>
    </div>
  </Flex>
);

Department.propTypes = {
  name: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  value: PropTypes.number,
  isLast: PropTypes.bool
};

Departments.propTypes = {
  data: PropTypes.array,
  end: PropTypes.number,
  header: PropTypes.node,
  bodyClassName: PropTypes.string,
  type: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape(Department.propTypes))
};

export default Departments;
