import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthSimpleLayout from './AuthSimpleLayout';
import is from 'is_js';
import MainLayout from './MainLayout';

import QuestionaireLayout from './QuestionaireLayout';
import LegalLayout from './LegalLayout';
import ErrorLayout from './ErrorLayout';
import FileLayout from './FileLayout';
import OnBoardingLayout from './OnBoardingLayout';
import PrintsLayout from './PrintsLayout';
import ReportsLayout from './ReportsLayout';
import Landing from 'components/pages/landing/Landing';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import Soon from 'components/errors/Soon';

import PrivacyPolicy from 'components/legal/PrivacyPolicy';
import Terms from 'components/legal/Terms';

import QuestionaireViewer from 'components/questionaire-viewer';
import HappyQuestionaireViewer from 'components/happy-questionaire-viewer';

import Report from 'components/report';
import DownloadReport from 'components/report/DownloadReport';

import InitLanding from 'components/authentication/InitLanding';
import Login from 'components/authentication/simple/Login';
import LoginWithLink from 'components/authentication/simple/LoginWithLink';
import Logout from 'components/authentication/simple/Logout';
import Registration from 'components/authentication/simple/Registration';
import ForgetPassword from 'components/authentication/simple/ForgetPassword';
import PasswordReset from 'components/authentication/simple/PasswordReset';
import ConfirmMail from 'components/authentication/simple/ConfirmMail';
import LockScreen from 'components/authentication/simple/LockScreen';

import HappinessDashboard from 'components/dashboards/happiness';
import EnvironmentDashboard from 'components/dashboards/environment';
import FellowshipDashboard from 'components/dashboards/fellowship';
import MotivationDashboard from 'components/dashboards/motivation';
import SatisfactionDashboard from 'components/dashboards/satisfaction';
import StressDashboard from 'components/dashboards/stress';
import AppContext from 'context/Context';

import Root from 'components/root';
import Home from 'components/home';
import Products from 'components/marketplace/product/Products';
import ProductDetails from 'components/marketplace/product/details';

import Offers from 'components/company/assistant/offers';
import CrmRecruitment from 'components/company/assistant/crm';
import Calendar from 'components/company/calendar';
import Chat from 'components/company/chat';
import Feed from 'components/company/feed';
import Employees from 'components/company/employees';
import CreateEmployees from 'components/company/employees/create';
import Rankings from 'components/company/rankings';
import TableView from 'components/feedback/tickets-layout/TableView';
import CardView from 'components/feedback/tickets-layout/CardView';
import ContactDetails from 'components/feedback/contact-details/ContactDetails';
import TicketsPreview from 'components/feedback/tickets-preview/TicketsPreview';
import FeedbackReports from 'components/feedback/reports/Reports';
import Questionaires from 'components/company/questionaires';
import QuestionaireDetail from 'components/company/questionaires/detail';
import HappyQuestionaireDetail from 'components/company/questionaires/happy-detail';
import PrintQuestionaire from 'components/company/questionaires/print';
import QuestionaireHappyfy from 'components/company/questionaires/detail/Happyfy';
import CreateQuestionaire from 'components/company/questionaires/create';
import Reviews from 'components/company/reviews';
import ReviewDetail from 'components/company/reviews/detail';
import PrintReview from 'components/company/reviews/print';
import CreateReview from 'components/company/reviews/create';
import Appreciations from 'components/company/appreciations';
import Events from 'components/company/events';
import EventDetail from 'components/company/events/detail';
import Objectives from 'components/company/objectives';
import ObjectivesDetail from 'components/company/objectives/detail';
import Reports from 'components/company/reports';
import DepartmentReports from 'components/company/reports/DepartmentReports';

import CompanyProfile from 'components/company/profile';

import TimeControl from 'components/user/time-control';
import Benefits from 'components/user/benefits';
import Profile from 'components/user/profile';
import UserQuestionaires from 'components/user/questionaires';
import UserQuestionaireDetail from 'components/user/questionaires/detail';
import UserQuestionaireDetailHappyfy from 'components/user/questionaires/detail/happyfy';
import UserReviews from 'components/user/reviews';
import UserReviewDetail from 'components/user/reviews/detail';
import TimeOff from 'components/user/time-off';
import Files from 'components/user/files';

import Cashback from 'components/benefits/cashback';
import Discounts from 'components/benefits/discounts';
import Exclusive from 'components/benefits/exclusive';

import Subscription from 'components/subscription';
import SubscriptionPlans from 'components/subscription/PricingTable';
import Welcome from 'components/welcome';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  return (
    <>
      <Routes>
        <Route path="landing" element={<Landing />} />
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>

        <Route element={<LegalLayout />}>
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<Terms />} />
        </Route>
        {/*- ------------- Authentication ---------------------------  */}

        <Route path="init" element={<InitLanding />} />
        <Route element={<AuthSimpleLayout />}>
          <Route path="authentication/login" element={<Login />} />
          <Route path="authentication/register" element={<Registration />} />
          <Route path="authentication/logout" element={<Logout />} />
          <Route path="authentication/welcome" element={<LoginWithLink />} />
          <Route
            path="authentication/forgot-password"
            element={<ForgetPassword />}
          />
          <Route
            path="authentication/reset-password"
            element={<PasswordReset />}
          />
          <Route path="authentication/confirm-mail" element={<ConfirmMail />} />
          <Route path="authentication/lock-screen" element={<LockScreen />} />
        </Route>

        {/* //--- Questionaire  */}
        <Route element={<QuestionaireLayout />}>
          <Route
            path="hq/:base64JsonStr"
            element={<HappyQuestionaireViewer />}
          />
          <Route
            path="hq/:base64JsonStr/*"
            element={<HappyQuestionaireViewer />}
          />
          <Route path="q/:base64JsonStr" element={<QuestionaireViewer />} />
        </Route>

        {/* //--- Reports  */}
        <Route element={<ReportsLayout />}>
          <Route
            path="r/:companyId/department/:department"
            element={<Report />}
          />
          <Route
            path="r/:companyId/department/:department/:date"
            element={<Report />}
          />
          <Route path="r/:companyId/:report/:start/:end" element={<Report />} />
          <Route path="r/:companyId/:report/:date" element={<Report />} />
          <Route path="r/:companyId/:date" element={<Report />} />
          <Route path="r/:companyId" element={<Report />} />
        </Route>

        {/* //--- Prints  */}
        <Route element={<PrintsLayout />}>
          <Route
            path="company/:companyId/questionaires/:questionaireId/print"
            element={<PrintQuestionaire />}
          />
          <Route
            path="company/:companyId/reviews/:reviewId/print"
            element={<PrintReview />}
          />
        </Route>

        {/* //--- Download reports  */}
        <Route element={<FileLayout />}>
          <Route
            path="report/:month/:department"
            element={<DownloadReport />}
          />
          <Route path="report/:month" element={<DownloadReport />} />
        </Route>

        {/* //--- On boarding  */}
        <Route element={<OnBoardingLayout />}>
          <Route path="/welcome" element={<Welcome />} />
          <Route path="welcome/*" element={<Welcome />} />
        </Route>

        {/* //--- MainLayout Starts  */}

        <Route element={<MainLayout />}>
          <Route path="/" element={<Root />} />
          <Route path="/home" element={<Home />} />
          <Route path="/marketplace" element={<Products />} />
          <Route path="/marketplace/:layout" element={<Products />} />
          <Route path="/marketplace/details" element={<ProductDetails />} />
          <Route
            path="/marketplace/details/:productId"
            element={<ProductDetails />}
          />

          {/*Dashboard*/}
          <Route path="/feed" element={<Feed />} />
          <Route path="/dashboard/happiness" element={<HappinessDashboard />} />
          <Route
            path="/dashboard/environment"
            element={<EnvironmentDashboard />}
          />
          <Route
            path="/dashboard/fellowship"
            element={<FellowshipDashboard />}
          />
          <Route
            path="/dashboard/motivation"
            element={<MotivationDashboard />}
          />
          <Route
            path="/dashboard/satisfaction"
            element={<SatisfactionDashboard />}
          />
          <Route path="/dashboard/stress" element={<StressDashboard />} />

          {/*Company*/}
          <Route path="/feed" element={<Feed />} />
          <Route path="company/employees" element={<Employees />} />
          <Route path="company/rankings" element={<Rankings />} />
          <Route
            path="company/employees/create"
            element={<CreateEmployees />}
          />

          <Route path="feedback/s/:search" element={<TableView />} />
          <Route path="feedback" element={<TableView />} />
          <Route path="feedback/table-view" element={<TableView />} />
          <Route path="feedback/card-view" element={<CardView />} />
          <Route path="feedback/contact-details" element={<ContactDetails />} />
          <Route
            path="feedback/contact-details/:userId"
            element={<ContactDetails />}
          />
          <Route path="feedback/tickets-preview" element={<TicketsPreview />} />
          <Route
            path="feedback/tickets-preview/:feedbackId"
            element={<TicketsPreview />}
          />
          <Route path="feedback/reports" element={<FeedbackReports />} />

          <Route path="company/questionaires" element={<Questionaires />} />
          <Route
            path="company/happy-questionaire/detail"
            element={<HappyQuestionaireDetail />}
          />
          <Route
            path="company/happy-questionaire/detail/:key"
            element={<HappyQuestionaireDetail />}
          />
          <Route
            path="company/questionaires/detail"
            element={<QuestionaireDetail />}
          />
          <Route
            path="company/questionaires/detail/:questionaireId"
            element={<QuestionaireDetail />}
          />
          <Route
            path="company/questionaires/detail/:questionaireId/:key"
            element={<QuestionaireDetail />}
          />
          <Route
            path="company/questionaires/happyfy"
            element={<QuestionaireHappyfy />}
          />
          <Route path="user/questionaires" element={<UserQuestionaires />} />
          <Route
            path="user/questionaires/detail"
            element={<UserQuestionaireDetail />}
          />
          <Route
            path="user/questionaires/detail/:questionaireId"
            element={<UserQuestionaireDetail />}
          />
          <Route
            path="user/questionaires/happyfy"
            element={<UserQuestionaireDetailHappyfy />}
          />
          <Route
            path="company/questionaires/create"
            element={<Questionaires creation={true} />}
          />
          <Route
            path="company/questionaires/create/templates"
            element={<Questionaires templates={true} />}
          />
          <Route
            path="company/questionaires/create/templates/:templateId"
            element={<CreateQuestionaire />}
          />
          <Route
            path="company/questionaires/create/blank"
            element={<CreateQuestionaire />}
          />

          <Route path="company/reviews" element={<Reviews />} />
          <Route path="company/reviews/detail" element={<ReviewDetail />} />
          <Route
            path="company/reviews/detail/:reviewId"
            element={<ReviewDetail />}
          />
          <Route
            path="company/reviews/detail/:reviewId/:key"
            element={<ReviewDetail />}
          />
          <Route path="user/reviews" element={<UserReviews />} />
          <Route path="user/reviews/detail" element={<UserReviewDetail />} />
          <Route
            path="user/reviews/detail/:reviewId"
            element={<UserReviewDetail />}
          />
          <Route
            path="company/reviews/create"
            element={<Reviews creation={true} />}
          />
          <Route
            path="company/reviews/create/templates"
            element={<Reviews templates={true} />}
          />
          <Route
            path="company/reviews/create/templates/:templateId"
            element={<CreateReview />}
          />
          <Route
            path="company/reviews/create/blank"
            element={<CreateReview />}
          />

          <Route path="company/appreciations" element={<Appreciations />} />
          <Route path="company/chat" element={<Chat />} />
          <Route path="company/chat/:chatId" element={<Chat />} />
          <Route path="company/chat/user/:userId" element={<Chat />} />
          <Route path="company/calendar" element={<Calendar />} />
          <Route path="company/assistant/offers" element={<Offers />} />
          <Route path="company/assistant/crm" element={<CrmRecruitment />} />
          <Route
            path="company/assistant/crm/:key"
            element={<CrmRecruitment />}
          />
          <Route path="company/events" element={<Events />} />
          <Route path="company/events/:eventId" element={<EventDetail />} />
          <Route path="company/objectives" element={<Objectives />} />
          <Route
            path="company/objectives/:objectiveId"
            element={<ObjectivesDetail />}
          />
          <Route path="user/time-off" element={<TimeOff />} />
          <Route path="user/documents" element={<Files />} />
          <Route path="company/reports" element={<Reports />} />
          <Route path="department/reports" element={<DepartmentReports />} />
          <Route path="company/profile" element={<CompanyProfile />} />

          {/*User*/}
          <Route path="user/time-control" element={<TimeControl />} />
          <Route path="user/time-control/:key" element={<TimeControl />} />
          <Route
            path="user/time-control/:key/:userId"
            element={<TimeControl />}
          />
          <Route path="user/partners" element={<Employees />} />
          <Route path="me" element={<Profile />} />
          <Route path="user/profile" element={<Profile />} />
          <Route path="user/profile/:userId" element={<Profile />} />
          <Route path="user/profile/:userId/:key" element={<Profile />} />
          <Route path="user/benefits" element={<Benefits />} />
          <Route path="user/benefits/:key" element={<Benefits />} />

          {/*Subscription*/}
          <Route path="subscription" element={<Subscription />} />
          <Route path="subscription/plans" element={<SubscriptionPlans />} />

          {/*Benefits*/}
          <Route path="benefits/cashback" element={<Cashback />} />
          <Route path="benefits/discounts" element={<Discounts />} />
          <Route path="benefits/exclusive" element={<Exclusive />} />
          <Route path="benefits/local" element={<Soon />} />
          <Route path="benefits/subscriptions" element={<Soon />} />
          <Route path="benefits/payroll" element={<Soon />} />
        </Route>

        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
