import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Cookies from 'js-cookie';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import { download } from 'helpers/utils';
import report from 'assets/img/icons/spot-illustrations/6.svg';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

const DownloadReport = () => {
  const navigate = useNavigate();
  const { company } = useContext(UserContext);
  const { department, month } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const monthStr = dayjs(month).format('MMMM YYYY');
  const departmentParam = department ? `&department=${department}` : '';
  const departmentStr = department ? `-${department}` : '';
  const user = JSON.parse(Cookies.get('__fbu') || '{}');
  const isSignedIn = !!user.uid;

  useEffect(() => {
    let mounted = true;
    if (!company?.NO_ID_FIELD || isLoading || downloaded) {
      return;
    }

    (async () => {
      setLoading(true);
      const blob = await (
        await fetch(
          `${process.env.REACT_APP_FIREBASE_URL}/createReport?company=${company?.NO_ID_FIELD}&date=${month}${departmentParam}`
        )
      ).blob();
      mounted && download(blob, `happyfy${departmentStr}-${month}.pdf`);
      toast.success(
        `Descarga completada, se ha guardado el informe de ${monthStr} con el nombre happyfy-${month}.pdf`,
        { position: 'bottom-center' }
      );
      setTimeout(() => {
        navigate('/');
      }, 5000);
      setDownloaded(true);
      setLoading(false);
    })();

    return () => {
      mounted = false;
    };
  }, [company?.NO_ID_FIELD]);

  if (!isSignedIn) {
    return (
      <Navigate
        to={{
          ...location,
          pathname: '/authentication/login'
        }}
        replace={true}
        state={{ from: location.pathname }}
      />
    );
  }

  return (
    <Flex direction="column" alignItems="center" justifyContent="center">
      <h1 className="text-center">
        Informe de {monthStr}
        {department && ` (${department})`}
      </h1>
      {isLoading && (
        <Flex
          className="position-absolute"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner
            variant="secondary"
            className="bg-white position-absolute bg-opacity-75"
            style={{ width: '8rem', height: '8rem' }}
          />
          <h5 className="position-absolute">Generando</h5>
        </Flex>
      )}
      {downloaded && (
        <Avatar
          icon="check"
          size="5xl"
          className="position-absolute"
          mediaClass="bg-success d-flex align-items-center justify-content-center"
        />
      )}
      <img src={report} className="img-fluid" alt="" />
    </Flex>
  );
};

export default DownloadReport;
