import React, { useContext } from 'react';
import classNames from 'classnames';
import { Navigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import ProductBanner from './ProductBanner';
import ProductFeatures from './ProductFeatures';
import ProductContent from './ProductContent';
import ProductSteps from './ProductSteps';
import ProductReviews from './ProductReviews';
import SimilarProducts from './SimilarProducts';
import ProductOptions from './ProductOptions';
import { BenefitsContext, ProductContext } from 'context/Context';
import { collection, query, orderBy } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

const ProductDetails = () => {
  const {
    productsState: { products }
  } = useContext(ProductContext);
  const { all = [] } = useContext(BenefitsContext);
  const { productId } = useParams();
  const productData =
    all?.find(({ stripeId }) => stripeId === productId) ||
    products.find(product => product.id === productId);
  const { features, content, options } = productData || {};
  const product = {
    ...productData,
    logo: productData?.logo || productData?.images?.[0]
  };
  const db = useFirestore();
  let reviewsQuery = query(collection(db, 'none'));
  if (product?.NO_ID_FIELD) {
    reviewsQuery = query(
      collection(db, 'apps', product?.NO_ID_FIELD, 'reviews'),
      orderBy('createdAt', 'desc')
    );
  }
  const { data: reviews = [] } = useFirestoreCollectionData(reviewsQuery);

  return product ? (
    <>
      <ProductBanner product={{ ...product, reviews }} />
      <Row className="g-lg-3">
        <Col lg={8} className="order-1 order-lg-0">
          <ProductFeatures data={features} />
          <ProductContent data={content} />
          <ProductOptions data={options} />
          <ProductReviews product={{ ...product, reviews }} />
          <SimilarProducts />
        </Col>
        <Col lg={4}>
          <div
            className={classNames('sticky-sidebar pe-xl-4 pe-xxl-7 mt-3', {
              'mt-xl-n10': product?.NO_ID_FIELD,
              'mt-xl-n8': !product?.NO_ID_FIELD
            })}
          >
            <ProductSteps product={product} />
          </div>
        </Col>
      </Row>
    </>
  ) : (
    <Navigate to={`/marketplace`} />
  );
};

export default ProductDetails;
