import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import CardDropdown from 'components/common/CardDropdown';
import { getSize } from 'helpers/utils';
import { Card, Dropdown } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import IconButton from 'components/common/IconButton';
import { getDownloadURL, getStorage, ref } from '@firebase/storage';
import { useFirebaseApp } from 'reactfire';

const CsvDropZone = ({ setData }) => {
  const [csv, setCsv] = useState();
  const app = useFirebaseApp();

  const loadCsv = csv => {
    setCsv(csv);
    const reader = new FileReader();
    reader.readAsText(csv);
    reader.onload = function (event) {
      const { result } = event.target;
      const rows = result.split('\r\n');
      const body = rows.map(row => row.split(';'));
      const header = body.shift();
      const users = body.map(userData => {
        return userData
          .filter(data => data)
          .reduce((user, value, index) => {
            const key = header[index];
            return { ...user, [key]: value.trim() };
          }, {});
      });
      setData({ users });
    };
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.csv',
    onDrop: ([file]) => loadCsv(file)
  });

  const handleDownloadTemplate = async () => {
    const storage = getStorage(app);
    const templateUrl = await getDownloadURL(
      ref(storage, 'templates/employees.csv')
    );
    window.open(templateUrl);
  };

  return (
    <Card className="sticky-sidebar">
      <FalconCardHeader
        title="Desde CSV"
        titleTag="h5"
        endEl={
          <IconButton
            variant="falcon-primary"
            size="sm"
            icon="download"
            iconClassName="ms-1"
            onClick={handleDownloadTemplate}
          >
            Plantilla
          </IconButton>
        }
      />
      <Card.Body>
        <div {...getRootProps({ className: 'dropzone-area py-6' })}>
          <input name="uploadedFiles" {...getInputProps({ multiple: false })} />
          <Flex justifyContent="center">
            <img src={cloudUpload} alt="" width={25} className="me-2" />
            <p className="fs--1 mb-0 text-700">Suelta aquí tu archivo .csv</p>
          </Flex>
        </div>
        {csv && (
          <div>
            <Flex
              alignItems="center"
              className="py-3 btn-reveal-trigger"
              key={csv.path}
            >
              <Flex
                justifyContent="between"
                alignItems="center"
                className="ms-3 flex-1"
              >
                <div>
                  <h6>{csv.path}</h6>
                  <Flex className="position-relative" alignItems="center">
                    <p className="mb-0 fs--1 text-400 line-height-1">
                      <strong>{getSize(csv.size)}</strong>
                    </p>
                  </Flex>
                </div>
              </Flex>
              <CardDropdown>
                <div className="py-2">
                  <Dropdown.Item
                    className="text-danger"
                    onClick={() => setCsv()}
                  >
                    Eliminar
                  </Dropdown.Item>
                </div>
              </CardDropdown>
            </Flex>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

CsvDropZone.propTypes = {
  setData: PropTypes.func
};

export default CsvDropZone;
