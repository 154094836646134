import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import QuestionaireReward from './QuestionaireReward';
import Questionaire from './Questionaire';
import QuestionaireOutcome from './QuestionaireOutcome';
import HappyQuestionaireProvider from './HappyQuestionaireProvider';
import { HappyQuestionairesContext, UserContext } from 'context/Context';

const HappyQuestionaireViewerContent = () => {
  const { defaults, me } = useContext(UserContext);
  let { finishHappyQuestionaire } = useContext(HappyQuestionairesContext);
  const [readOnly, setReadOnly] = useState(false);
  const navigate = useNavigate();

  const { lastQuestionnaireAt } = me;
  const lastQuestionnaireDate = new Date(lastQuestionnaireAt);
  const isRewardable =
    lastQuestionnaireAt &&
    lastQuestionnaireDate.getMonth() !== new Date().getMonth();

  const { happy_questionaire: questionaire = {} } = defaults || {};
  const { handleSubmit, ...rest } = useForm();

  const onSubmitData = async data => {
    setReadOnly(true);
    const answers = questionaire?.definitions?.map(
      (def, index) => data[`entry-${index}`]
    );

    if (
      answers.some((response, index) => {
        const isUndefined = typeof response === 'undefined';
        if (isUndefined) {
          const url = `${window.location.href}/#entry-${index}`;
          navigate(url);
        }
        return isUndefined;
      })
    ) {
      return;
    }
    finishHappyQuestionaire({
      definitions: questionaire?.definitions,
      answers,
      user: me
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitData)}>
      {isRewardable && (
        <div className="p-3">
          <QuestionaireReward />
        </div>
      )}
      <Questionaire {...rest} questionaire={questionaire} />
      <QuestionaireOutcome
        questionaire={questionaire}
        readOnly={readOnly}
        isRewardable={isRewardable}
      />
    </Form>
  );
};

const HappyQuestionaireViewer = () => {
  return (
    <HappyQuestionaireProvider>
      <HappyQuestionaireViewerContent />
    </HappyQuestionaireProvider>
  );
};

export default HappyQuestionaireViewer;
