import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Experience from './Experience';
import ExperienceForm from './ExperienceForm';
import { UserContext } from 'context/Context';

const Experiences = ({ className, user }) => {
  const { me } = useContext(UserContext);
  const { profile = {} } = user || {};
  const { experience: data = [] } = profile;
  const [show, setShow] = useState(false);

  return (
    <>
      <Card className={className}>
        <FalconCardHeader title="Experiencia" light />
        <Card.Body className="fs--1">
          {data.map((experience, index) => (
            <Experience
              key={`Experience-${index}`}
              experience={experience}
              index={index}
              user={user}
            />
          ))}
          {me?.NO_ID_FIELD === user?.NO_ID_FIELD && (
            <ExperienceForm show={show} setShow={setShow} user={user} />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

Experiences.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object
};

export default Experiences;
