import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFirestoreDocData } from 'reactfire';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import { getColorByName } from 'helpers/utils';

const referrerMessagePreview = {
  checklist: title => (
    <>
      <FontAwesomeIcon icon="list-check" />
      <span>{` Checklist: ${title}`}</span>
    </>
  ),
  deleted: () => (
    <>
      <FontAwesomeIcon icon="ban" />{' '}
      <span style={{ fontStyle: 'italic' }}>Mensaje eliminado</span>
    </>
  ),
  event: title => (
    <>
      <FontAwesomeIcon icon="flag" />
      <span>{` Evento: ${title}`}</span>
    </>
  ),
  image: () => (
    <>
      <FontAwesomeIcon icon="camera" />
      <span>{' Foto'}</span>
    </>
  ),
  objective: title => (
    <>
      <FontAwesomeIcon icon="trophy" />
      <span>{` Objetivo: ${title}`}</span>
    </>
  ),
  questionaire: title => (
    <>
      <FontAwesomeIcon icon="mobile" />
      <span>{` Encuesta: ${title}`}</span>
    </>
  )
};

const ReferrerBubble = ({ referrer, ...props }) => {
  const { happybot, partners = [] } = useContext(UserContext);
  const { data = {} } = useFirestoreDocData(referrer);
  let { from, text, type, extra } = data;
  const { title = text } = extra || {};

  const { name } =
    [happybot, ...partners].find(
      partner => partner?.ref?.path === from?.path
    ) || {};

  const color = getColorByName(name);
  const textToShow = referrerMessagePreview[type]?.(title) || title;
  return (
    <Flex
      className={classNames(
        'position-relative bg-light mb-1 px-2 py-1 fs--2 rounded-2 overflow-hidden',
        {
          'cursor-pointer': props?.onClick
        }
      )}
      {...props}
    >
      <div
        className="position-absolute top-0 start-0 bottom-0 ps-1"
        style={{ backgroundColor: color }}
      />
      <div>
        <h6 className="m-0 fs--2" style={{ color }}>
          {name}
        </h6>
        <p className="fs--2 m-0">{textToShow}</p>
      </div>
    </Flex>
  );
};
ReferrerBubble.propTypes = {
  referrer: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default ReferrerBubble;
