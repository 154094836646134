import React, { useContext, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { UserContext } from 'context/Context';

const ClearIndicator = ({ children, className, ...rest }) => {
  const { selectProps } = rest;
  const { size } = selectProps;
  return (
    <components.ClearIndicator
      {...rest}
      className={classNames(className, { 'p-0': size === 'sm' })}
    >
      {children}
    </components.ClearIndicator>
  );
};

ClearIndicator.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

const Control = ({ children, className, ...rest }) => {
  const { selectProps } = rest;
  const { size } = selectProps;
  return (
    <components.Control
      {...rest}
      className={classNames(className, {
        'form-control-sm px-2': size === 'sm'
      })}
    >
      {children}
    </components.Control>
  );
};

Control.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

const DropdownIndicator = ({ children, className, ...rest }) => {
  const { selectProps } = rest;
  const { size } = selectProps;
  return (
    <components.DropdownIndicator
      {...rest}
      className={classNames(className, {
        'p-0': size === 'sm'
      })}
    >
      {children}
    </components.DropdownIndicator>
  );
};

DropdownIndicator.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

const Input = ({ children, className, ...rest }) => {
  const { selectProps } = rest;
  const { size } = selectProps;
  return (
    <components.Input
      {...rest}
      className={classNames(className, {
        'form-control-sm p-0 min-h-0': size === 'sm'
      })}
    >
      {children}
    </components.Input>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

const Menu = ({ children, ...rest }) => {
  return (
    <components.Menu
      {...rest}
      className="overflow-hidden mt-0 border-top border-200 rounded-top-0 shadow"
    >
      {children}
    </components.Menu>
  );
};

Menu.propTypes = {
  children: PropTypes.node
};

const MultiValue = ({ children, className, ...rest }) => {
  const { selectProps } = rest;
  const { size } = selectProps;
  return (
    <components.MultiValue
      {...rest}
      className={classNames(className, {
        'm-0': size === 'sm'
      })}
    >
      {children}
    </components.MultiValue>
  );
};

MultiValue.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

const Option = ({ data, isFocused, isSelected, ...rest }) => {
  const { avatar, label } = data;
  return (
    <components.Option
      {...rest}
      className={classNames('m-0', {
        'bg-primary text-white': isSelected,
        'bg-soft-primary text-dark': isFocused && !isSelected
      })}
    >
      <Flex alignItems="center">
        <Avatar className="me-2" size="s" src={avatar} name={label} />
        <span
          className={classNames({
            'text-white': isSelected
          })}
        >
          {label}
        </span>
      </Flex>
    </components.Option>
  );
};

Option.propTypes = {
  data: PropTypes.object,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool
};

const styles = {
  control: base => ({
    ...base,
    borderRadius: '1.375rem',
    fontFamily:
      'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',
    paddingLeft: '1.25rem'
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  }),
  input: base => ({ ...base, paddingLeft: '28px', boxShadow: 'none' })
};

const ValueContainer = ({ children, className, ...rest }) => {
  const { selectProps } = rest;
  const { size } = selectProps;
  return (
    <components.ValueContainer
      {...rest}
      className={classNames(className, { 'p-0': size === 'sm' })}
    >
      {children}
    </components.ValueContainer>
  );
};

ValueContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

const EmployeeDepartmentsSelector = ({ externalRef, ...props }) => {
  const { departments, partners = [] } = useContext(UserContext);
  const select = externalRef || useRef();
  const options = props?.options || [
    ...departments.map(({ name }) => ({
      isDepartment: true,
      label: name,
      value: name
    })),
    ...partners.map(({ NO_ID_FIELD, avatar, name }) => ({
      avatar,
      label: name,
      value: NO_ID_FIELD
    }))
  ];

  return (
    <Select
      ref={select}
      options={options}
      classNamePrefix="react-select"
      isClearable
      isSearchable
      menuPortalTarget={document.body}
      styles={styles}
      components={{
        ClearIndicator,
        Control,
        DropdownIndicator,
        Input,
        Menu,
        Option,
        ValueContainer
      }}
      {...props}
    />
  );
};

EmployeeDepartmentsSelector.propTypes = {
  externalRef: PropTypes.object,
  options: PropTypes.array
};

export default EmployeeDepartmentsSelector;
