import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import html2pdf from 'html2pdf.js';
import { Button, CloseButton, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typewriter } from 'react-simple-typewriter';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import AppContext, { AIContext, UserContext } from 'context/Context';
import logo from 'assets/img/logo.png';
import dayjs from 'dayjs';

const ActionButton = ({ action }) => {
  const { ai } = useContext(AIContext);
  const { context = [], prompt, text } = action;

  const handleClickAction = () => {
    ai({ context, prompt: prompt || text });
  };

  return (
    <Button
      className="rounded-pill badge-soft-primary border-0"
      variant="primary"
      onClick={handleClickAction}
    >
      <FontAwesomeIcon icon="magic" className="me-2" />
      {text}
    </Button>
  );
};

ActionButton.propTypes = { action: PropTypes.object };

export default function AIModal() {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const { me } = useContext(UserContext);
  const { avatar, name } = me || {};
  const {
    ai,
    actions,
    content = [],
    filename = `happyfy-ia-${dayjs().format('YYYY-MM-DD')}`,
    show,
    setShow
  } = useContext(AIContext);
  const [showInput, setShowInput] = useState(false);
  const [text, setText] = useState('');
  const lastMessage = content?.[content?.length - 1];
  const ref = useRef();
  const messagesEndRef = useRef();

  const handleClose = () => {
    setShow(false);
    setShowInput(false);
    setText('');
  };

  const handlePrint = () => {
    const { current: element } = ref;
    const opt = {
      filename,
      html2canvas: {
        scale: 2
      },
      image: { type: 'jpeg', quality: 0.98 },
      jsPDF: { unit: 'cm', format: 'A4', orientation: 'portrait' },
      margin: [1.9, 1.9, 1.9, 1.32],
      pagebreak: { mode: 'css' }
    };

    html2pdf().set(opt).from(element).save();
  };

  const handleSubmit = event => {
    event.preventDefault();
    ai({ prompt: text });
    setShowInput(false);
    setText('');
  };

  useEffect(() => {
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [content?.length, showInput]);

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header className="bg-white dark__bg-1100 sticky-top">
        <div></div>
        <Flex alignItems="center">
          <Button
            variant="link"
            className="py-0 text-black opacity-50 hover-opacity-75 d-flex align-items-center"
            onClick={handlePrint}
          >
            <FontAwesomeIcon icon="print" className="fs-1" />
          </Button>
          <CloseButton onClick={handleClose} />
        </Flex>
      </Modal.Header>
      <Modal.Body ref={ref} className="markdown p-0">
        {content
          ?.filter(({ role }) => role !== 'system')
          .map(({ role, content }, index) => {
            const [, marketplaceSearch] =
              content?.match(/Smartbox.*"(.*)"/) || [];
            return (
              <div
                key={`AI-Messages-${index}`}
                className={classNames('p-4', {
                  'bg-light fw-semi-bold': role === 'user',
                  'border-top': index > 0
                })}
              >
                <Flex className="mb-3">
                  <Avatar
                    src={classNames({
                      [logo]: role === 'assistant',
                      [avatar]: role === 'user'
                    })}
                    name={name}
                    size="xl"
                  />
                  <div className="flex-1 mt-1 ms-2 fs--1">
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {content}
                    </ReactMarkdown>
                    {marketplaceSearch && (
                      <div>
                        <Link
                          to={`/marketplace?q=${encodeURIComponent(
                            marketplaceSearch
                          )}`}
                        >
                          <Button
                            variant="falcon-primary"
                            className="rounded-pill"
                            size="sm"
                            onClick={handleClose}
                          >
                            Ver en marketplace
                          </Button>
                        </Link>
                      </div>
                    )}
                  </div>
                </Flex>
              </div>
            );
          })}
      </Modal.Body>
      {lastMessage?.role !== 'assistant' ? (
        <Modal.Body className="p-0">
          <div className="p-4 border-top">
            <Flex className="mb-3">
              <Avatar src={logo} name="Happyfy IA" size="xl" />
              <SoftBadge
                pill
                bg="secondary"
                className="ms-2 d-flex align-items-center"
              >
                <Typewriter
                  words={['Leyendo...', 'Pensando...', 'Generando respuesta']}
                  loop={false}
                  cursor={!isRTL ? true : false}
                  cursorStyle="|"
                  typeSpeed={50}
                  deleteSpeed={20}
                  delaySpeed={4500}
                />
              </SoftBadge>
            </Flex>
          </div>
        </Modal.Body>
      ) : (
        <>
          <Modal.Footer className="bg-light">
            <h6>¿Qué deseas hacer...?</h6>
          </Modal.Footer>
          <Modal.Footer className="bg-light border-0 pt-0">
            {actions?.map((action, index) => {
              action = typeof action === 'string' ? { text: action } : action;
              return (
                <ActionButton key={`${action}-${index}`} action={action} />
              );
            })}
            <Button
              className={classNames(
                'rounded-pill badge-soft-primary border-0',
                {
                  'opacity-50': showInput
                }
              )}
              variant="primary"
              onClick={() => setShowInput(show => !show)}
            >
              <FontAwesomeIcon icon="magic" className="me-2" />
              Escribir una pregunta
            </Button>
          </Modal.Footer>
          {showInput && (
            <Modal.Footer className="bg-light border-0 pt-0">
              <Flex className="w-100">
                <Avatar
                  src={me?.avatar}
                  name={me?.name}
                  className="me-2"
                  size="l"
                />
                <div className="flex-1 fs--1">
                  <div className="position-relative border rounded mb-3">
                    <Form onSubmit={handleSubmit}>
                      <Form.Control
                        className="border-0 resize-none rounded-bottom-0 position-relative z-index-2"
                        value={text}
                        onFocus={() => focus(true)}
                        onChange={({ target }) => setText(target.value)}
                        autoFocus
                      />
                      <Flex
                        alignItems="center"
                        justifyContent="end"
                        className="bg-300 p-2"
                      >
                        <Button color="primary" type="submit">
                          <FontAwesomeIcon icon="magic" className="me-2" />
                          Enviar
                        </Button>
                      </Flex>
                    </Form>
                  </div>
                </div>
              </Flex>
            </Modal.Footer>
          )}
        </>
      )}
      <div ref={messagesEndRef} />
    </Modal>
  );
}
