import React, { forwardRef, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import { download } from 'helpers/utils';

dayjs.locale('es');
registerLocale('es', es);
setDefaultLocale('es');

const data = [
  {
    icon: 'laugh',
    color: 'accent',
    report: 'happiness',
    title: 'Felicidad',
    text: 'Exporta las gráficas de los índices de fe'
  },
  {
    icon: 'comment-medical',
    color: 'primary',
    report: 'feedback',
    title: 'Sugerencias',
    text: 'Exporta las sugerencias de tus empleados'
  },
  {
    icon: 'calendar-day',
    color: 'info',
    report: 'events',
    title: 'Eventos',
    text: 'Exporta la lista de eventos'
  },
  {
    icon: 'trophy',
    color: 'success',
    report: 'objectives',
    title: 'Objetivos',
    text: 'Exporta la lista de objetivos'
  }
];

const FormControl = forwardRef(({ value, onClick }, ref) => (
  <Form.Control onClick={onClick} value={value} size="sm" ref={ref} readOnly />
));

FormControl.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func
};

const Generation = ({ color, icon, report, title, text, start, end }) => {
  const [isLoading, setLoading] = useState(false);
  const { company: { NO_ID_FIELD: companyId } = {} } = useContext(UserContext);

  const generateReport = async () => {
    setLoading(true);
    const [startDate] = start.toISOString().match(/\d{4}-\d{2}-\d{2}/);
    const [endDate] = end.toISOString().match(/\d{4}-\d{2}-\d{2}/);
    const blob = await (
      await fetch(
        `${process.env.REACT_APP_FIREBASE_URL}/createReport?company=${companyId}&start=${startDate}&end=${endDate}&report=${report}`
      )
    ).blob();
    const startStr = start?.toISOString().split('T')[0].replace(/-/g, '.');
    const endStr = end?.toISOString().split('T')[0].replace(/-/g, '.');
    download(blob, `happyfy-${report}-${startStr}-${endStr}.pdf`);
    setLoading(false);
  };

  return (
    <Flex className="position-relative">
      <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
        {isLoading ? (
          <Spinner
            size="sm"
            variant={color}
            style={{ width: '1rem', height: '1rem' }}
          />
        ) : (
          <FontAwesomeIcon icon={icon} className={`text-${color}`} />
        )}
      </div>
      <div className="flex-1">
        <Button variant="link p-0" onClick={!isLoading ? generateReport : null}>
          <h6 className="mb-0">
            {title}
            {isLoading && <span className="text-500"> - Generando</span>}
          </h6>
        </Button>
        <p className="mb-0 fs--2 text-500 ">{text}</p>
      </div>
    </Flex>
  );
};

Generation.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  report: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  start: PropTypes.object,
  end: PropTypes.object
};

const Generations = ({ className }) => {
  const [defaultStart] = new Date(
    new Date().setMonth(new Date().getMonth() - 3)
  )
    .toISOString()
    .match(/\d{4}-\d{2}/);
  const [[start, end], setDates] = useState([
    new Date(`${defaultStart}-01`),
    new Date()
  ]);

  const handleChangeCustom = dates => {
    const [start, end] = dates;
    setDates([start, end]);
  };

  const isUntilToday = useCallback(date => {
    return date.toISOString() < new Date().toISOString();
  }, []);

  return (
    <Card className={classNames(className)}>
      <Card.Header className="pb-0">
        <h6>Selecciona las fechas...</h6>
      </Card.Header>
      <Card.Body className="pt-0">
        <Row>
          <Col xs={12} sm={6} lg={3}>
            <DatePicker
              selected={start}
              onChange={handleChangeCustom}
              startDate={start}
              endDate={end}
              selectsRange
              filterDate={isUntilToday}
              customInput={<FormControl />}
              dateFormat="dd MMM yy"
              className="form-control form-control-sm ms-2"
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Header className="pb-0">
        <h6>Generar informe de...</h6>
      </Card.Header>
      <Card.Body>
        <Row className="g-4">
          {data.map((generation, index) => (
            <Col xs={12} sm={6} lg={3} key={`Report-Generation-${index}`}>
              <Generation {...generation} start={start} end={end} />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

Generations.propTypes = {
  className: PropTypes.string
};

export default Generations;
