import React, { useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, getColorByName, rgbaColor } from 'helpers/utils';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { BarChart, PieChart } from 'echarts/charts';
import { ReviewsContext } from 'context/Context';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

dayjs.extend(relativeTime);
dayjs.locale('es');

const optionsGraphColors = ['blue', 'red', 'cyan', 'green', 'yellow'];
const OptionsGraph = ({
  extra = {},
  responses: reviewResponses = [],
  index
}) => {
  let { options = [] } = extra;
  options = options.filter(option => option);
  const responses = reviewResponses.map(({ responses }) => responses[index]);
  const chartRef = useRef(null);
  const data = options.map((name, index) => ({
    value: responses.filter(response => response === name).length,
    name,
    itemStyle: {
      color: optionsGraphColors[index]
        ? getColor(optionsGraphColors[index])
        : getColorByName(name)
    }
  }));

  const getOption = () => ({
    legend: {
      orient: 'vertical',
      left: 'left',
      textStyle: {
        color: getColor('gray-600')
      }
    },
    series: [
      {
        type: 'pie',
        radius: window.innerWidth < 530 ? '45%' : '60%',
        label: {
          color: getColor('gray-700'),
          formatter: params => `${params.name}: ${params.value}`,
          show: true
        },
        center: ['50%', '55%'],
        data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: rgbaColor(getColor('gray-600'), 0.5)
          }
        }
      }
    ],
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    }
  });

  //------- Responsive on window resize -------

  const updateDimensions = () => {
    if (window.innerWidth < 530) {
      chartRef.current.getEchartsInstance().setOption({
        series: [{ radius: '45%' }]
      });
    } else
      chartRef.current.getEchartsInstance().setOption({
        series: [{ radius: '60%' }]
      });
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <div>
      <ReactEChartsCore
        echarts={echarts}
        option={getOption()}
        ref={chartRef}
        style={{ height: '20rem' }}
      />
    </div>
  );
};

const TextGraph = ({ responses: reviewResponses = [], index }) => {
  const { partners = [] } = useContext(ReviewsContext);

  const userResponses = reviewResponses.map(userResponse => {
    const { userId, responses } = userResponse;
    const user = partners.find(({ id } = {}) => id === userId) || {};
    const { avatar, name } = user;
    const response = responses[index];
    return {
      avatar,
      response,
      name
    };
  });

  const columns = [
    {
      accessor: 'name',
      cellProps: {
        className: 'w-0 ps-6 border-0'
      },
      Cell: rowData => {
        const { avatar, name } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <Avatar name={name || 'H'} rounded="2" size="m" src={avatar} />
            <h6 className="ms-2 mb-0 fw-semi-bold">{name}</h6>
          </Flex>
        );
      }
    },
    {
      accessor: 'response',
      cellProps: {
        className: 'w-100 border-0 text-wrap'
      },
      Cell: rowData => {
        return rowData.row.original.response || '-';
      }
    }
  ];
  return (
    <div className="ms-n6">
      <AdvanceTableWrapper
        columns={columns}
        data={userResponses}
        sortable
        pagination
        perPage={100}
        refresh={true}
      >
        <AdvanceTable
          table
          headerClassName="d-none"
          rowClassName="bg-white align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100"
          tableProps={{
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        {!userResponses.length && <div className="p-3">Sin respuestas</div>}
      </AdvanceTableWrapper>
    </div>
  );
};

const VoteGraph = ({ extra = {}, responses: reviewResponses = [], index }) => {
  const { min = {}, max = {} } = extra;
  const { value: minValue } = min;
  const { value: maxValue } = max;
  const values = new Array(maxValue - minValue + 1).fill(0).map((x, index) => {
    return parseInt(minValue) + index;
  });
  const responses = reviewResponses.map(({ responses }) => responses[index]);
  const labels = values;
  const data = values.map(
    value => responses.filter(response => response === value).length
  );

  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    xAxis: {
      type: 'category',
      data: labels,
      axisLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'solid'
        }
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('gray-400'),
        formatter: value => value.substring(0, 3),
        margin: 15
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        show: true,
        color: getColor('gray-400'),
        margin: 15
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      minInterval: 1,
      axisTick: { show: false, stepSize: 1 },
      axisLine: { show: false }
    },
    series: [
      {
        type: 'bar',
        name: 'Total',
        data,
        label: {
          show: true,
          formatter: '{c}',
          color: '#fff'
        },
        lineStyle: { color: getColor('primary') },
        itemStyle: {
          color: getColor('primary'),
          borderRadius: [3, 3, 0, 0]
        },
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true
        }
      }
    ],
    grid: { right: '3%', left: '10%', bottom: '10%', top: '5%' }
  });
  return (
    <div>
      <ReactEChartsCore
        echarts={echarts}
        option={getOption()}
        style={{ height: '18.75rem' }}
      />
    </div>
  );
};

const graphTypes = {
  options: OptionsGraph,
  text: TextGraph,
  vote: VoteGraph
};

const Analytics = ({ review, responses }) => {
  const { NO_ID_FIELD, definitions = [] } = review || {};
  const reviewIndexes = definitions?.reduce((nums, { type }) => {
    const last = nums[nums.length - 1];
    let thisIndex =
      type === 'divider'
        ? type
        : last === 'divider'
        ? nums[nums.length - 2] + 1 || 1
        : last + 1;
    if (type !== 'divider' && isNaN(thisIndex)) {
      thisIndex = 1;
    }
    return [...nums, thisIndex];
  }, []);

  return (
    <>
      <Card.Header className="ps-4 py-3 mt-5 mb-4 bg-200">
        <h5 className="ms-4">Analytics</h5>
      </Card.Header>
      <Card.Body>
        {definitions.map((definition, index) => {
          const { question, type } = definition;
          const GraphType = graphTypes[type] || null;
          const num = reviewIndexes[index];
          return (
            <Flex
              key={`Questionaire-${NO_ID_FIELD}-Graph-${index}`}
              className={classNames('mb-3 pb-3 border-bottom', {
                'mt-5': type === 'divider' && index !== 0
              })}
              justifyContent="start"
            >
              <div className="pe-x1 pt-2">
                <Avatar
                  name={type === 'divider' ? '' : `${num}`}
                  isExact={true}
                  mediaClass="bg-primary bg-opacity-25"
                  size="2xl"
                />
              </div>
              <div className="w-100 pt-3">
                <h6
                  className={classNames({
                    'fs-1': type === 'divider',
                    'mt-1': type !== 'divider'
                  })}
                >
                  {question}
                </h6>
                {GraphType && (
                  <>
                    <hr className="border-200" />
                    <GraphType
                      {...definition}
                      index={index}
                      responses={responses}
                    />
                  </>
                )}
              </div>
            </Flex>
          );
        })}
      </Card.Body>
    </>
  );
};

OptionsGraph.propTypes = {
  extra: PropTypes.object,
  index: PropTypes.number,
  responses: PropTypes.array
};

TextGraph.propTypes = {
  index: PropTypes.number,
  responses: PropTypes.array
};

VoteGraph.propTypes = {
  extra: PropTypes.object,
  index: PropTypes.number,
  responses: PropTypes.array
};

Analytics.propTypes = {
  review: PropTypes.object,
  responses: PropTypes.array
};

export default Analytics;
