import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ActionButton from 'components/common/ActionButton';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import Question from 'components/company/questionaires/Question';
import { QuestionairesContext } from 'context/Context';
import { toast } from 'react-toastify';

dayjs.extend(relativeTime);
dayjs.locale('es');

const Questions = ({ questionaire, hasResponses }) => {
  const { update } = useContext(QuestionairesContext);
  const { definitions } = questionaire;
  const { handleSubmit, ...form } = useForm();
  const { reset } = form;

  const onSubmitData = data => {
    update(data);
    toast.success('Cuestionario creado');
  };

  const createQuestion = () => {
    form.setValue('definitions', [...definitions, { type: 'vote' }]);
  };

  useEffect(() => {
    reset(questionaire);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmitData)}>
      <Card.Body className="border-top bg-light border-bottom border-200">
        {definitions?.map((question, index) => (
          <Question
            key={`Question-${index}`}
            {...form}
            question={question}
            index={index}
            hasResponses={hasResponses}
            isLast={index === definitions.length}
          />
        ))}
        {!hasResponses && (
          <div className="ms-1">
            <ActionButton
              tooltip="Crear pregunta"
              icon="plus"
              handleClick={createQuestion}
            />
          </div>
        )}
      </Card.Body>
      <Card.Footer>
        {hasResponses ? (
          <small>Al haber respuestas ya no se puede modificar</small>
        ) : (
          <Button type="submit" variant="primary" size="lg">
            Guardar
          </Button>
        )}
      </Card.Footer>
    </Form>
  );
};

Questions.propTypes = {
  questionaire: PropTypes.object,
  hasResponses: PropTypes.bool
};

export default Questions;
