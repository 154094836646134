import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Col, Dropdown, Form, Image, Row } from 'react-bootstrap';
import Compressor from 'compressorjs';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ref, uploadBytes } from '@firebase/storage';
import { useStorage } from 'reactfire';
import calendar from 'assets/img/icons/spot-illustrations/calendar.svg';
import image from 'assets/img/icons/spot-illustrations/image.svg';
import Flex from 'components/common/Flex';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import { CalendarContext, FeedContext, UserContext } from 'context/Context';
import dayjs from 'dayjs';
import dayjsCalendar from 'dayjs/plugin/calendar';

dayjs.extend(dayjsCalendar);

const styles = {
  control: base => ({
    ...base,
    borderRadius: '1.375rem',
    minHeight: 32
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  })
};

const CreatePostForm = ({ setState }) => {
  const storage = useStorage();
  const { createFeed, updateFeed } = useContext(FeedContext);
  const { company } = useContext(UserContext);
  const [event, setEvent] = useState();
  const [hashtags, setHashtags] = useState('');
  const [images, setImages] = useState([]);
  const [privacy, setPrivacy] = useState('public');
  const [text, setText] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    let _text = text;
    if (!_text && !images.length && !event) {
      return;
    }
    const urls =
      _text?.match(
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g
      ) || [];
    const content = {};

    await Promise.all(
      urls?.map(
        url =>
          new Promise(resolve => {
            _text = _text.replace(url, '');
            if (url.match(/(youtube.com|youtu.be)/)) {
              content.video = url;
              resolve();
              return;
            }
            const img = document.createElement('img');
            img.src = url;
            img.onload = () => {
              content.images = [...(content?.images || []), url];
              resolve();
              return;
            };
            const video = document.createElement('video');
            video.src = url;
            video.onload = () => {
              content.video = url;
              resolve();
              return;
            };
            content.url = url;
            resolve();
          })
      )
    );

    event && (content.event = event);
    _text && (content.text = _text);
    images && (content.images = images.map(({ file }) => file.name));
    const { id: feedId } = await createFeed({ content, hashtags, privacy });

    const _images = await Promise.all(
      images?.map(async ({ file }) => {
        const path = `companies/${company?.NO_ID_FIELD}/feeds/${feedId}/${file.name}`;
        const imageRef = ref(storage, path);
        await uploadBytes(imageRef, file);
        return path;
      })
    );

    if (_images.length) {
      updateFeed({ NO_ID_FIELD: feedId }, { 'content.images': _images });
    }

    setEvent();
    setHashtags('');
    setImages([]);
    setPrivacy('public');
    setText('');
  };

  const handleAppendImage = ({ target }) => {
    const { files } = target;
    Object.values(files).forEach(file => {
      new Compressor(file, {
        quality: 0.8,
        maxWidth: 1200,
        maxHeight: 1200,
        success(result) {
          setTimeout(
            () =>
              setImages(images => [
                ...images,
                {
                  file: result,
                  url: URL.createObjectURL(result)
                }
              ]),
            10
          );
        }
      });
    });
  };

  const handleAppendEvent = event => {
    setEvent(event);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Control
        as="textarea"
        rows={4}
        placeholder="¿Qué quieres publicar?"
        className="shadow-none rounded-0 resize-none border-y-0 px-x1 border-200"
        value={text}
        onChange={e => setText(e.target.value)}
      />
      <Form.Group
        as={Flex}
        alignItems="center"
        className="ps-x1 border border-200"
        controlId="hashtag"
      >
        <Form.Label className="text-nowrap mb-0 me-2">
          <FontAwesomeIcon icon="plus" className="me-1 fs--2" />
          <span className="fw-medium fs--1">Añade hashtags</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="#evento#empresa"
          className="border-0 fs--1  shadow-none"
          onChange={e => setHashtags(e.target.value)}
        />
      </Form.Group>

      <Row className="g-0 mt-3 px-x1 pb-3 justify-content-between">
        <Col>
          <AppendImagesButton images={images} onChange={handleAppendImage} />
          <AppendEventButton event={event} onChange={handleAppendEvent} />
          <AppreciationButton setState={setState} />
          {!!images?.length && (
            <FalconLightBoxGallery className="mt-3" images={images}>
              {setImgIndex => (
                <Row className="g-2">
                  {images.map((image, index) => (
                    <Col
                      key={`Image-${image?.file?.name}`}
                      xs={2}
                      className="position-relative"
                    >
                      <ImagePreview
                        image={image}
                        index={index}
                        setImgIndex={setImgIndex}
                        setImages={setImages}
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </FalconLightBoxGallery>
          )}
        </Col>
        <Col xs="auto">
          <Dropdown className="d-inline-block me-1">
            <Dropdown.Toggle
              variant="none"
              size="sm"
              className="px-1 shadow-none"
            >
              <FontAwesomeIcon
                icon={classNames({
                  lock: privacy === 'admin',
                  'globe-americas': privacy === 'public'
                })}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-2" align="end">
              <Dropdown.Item
                className={classNames({ active: privacy === 'public' })}
                onClick={() => setPrivacy('public')}
              >
                Toda la empresa
              </Dropdown.Item>
              <Dropdown.Item
                className={classNames({ active: privacy === 'admin' })}
                onClick={() => setPrivacy('admin')}
              >
                Sólo admins
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button size="sm" className="px-4 px-sm-5" type="submit">
            Crear
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const ImagePreview = ({ image, index, setImgIndex, setImages }) => {
  const { file, url } = image;
  const { name } = file;
  const handleDeleteImage = () => {
    setImages(images => images?.filter(img => img?.file?.name !== name));
  };

  return (
    <>
      <Image src={url} fluid rounded onClick={() => setImgIndex(index)} />
      <Button
        variant="falcon-danger"
        className="rounded-pill position-absolute top-0 end-0 mt-n1"
        size="sm"
        onClick={handleDeleteImage}
      >
        <FontAwesomeIcon icon="trash" />
      </Button>
    </>
  );
};

const Option = props => {
  const {
    children,
    className,
    cx,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps
  } = props;

  const [title] = children.match(/[^[]*/);
  const [, date] = children.match(/\[\[(.*)]]/);
  return (
    <div
      ref={innerRef}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected
        },
        'cursor-pointer',
        className
      )}
      {...innerProps}
    >
      {title} <span className="text-500"> - {date}</span>
    </div>
  );
};

Option.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  cx: PropTypes.func,
  isDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  innerRef: PropTypes.object,
  innerProps: PropTypes.object
};

const SingleValue = props => {
  const { children, className, cx, innerRef, innerProps } = props;

  const [title] = children.match(/[^[]*/);
  const [, date] = children.match(/\[\[(.*)]]/);
  return (
    <span
      ref={innerRef}
      className={cx({}, 'position-absolute', className)}
      {...innerProps}
    >
      {title} <span className="text-500"> - {date}</span>
    </span>
  );
};

SingleValue.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  cx: PropTypes.func,
  innerRef: PropTypes.object,
  innerProps: PropTypes.object
};

const AppendEventButton = ({ event: eventId, onChange: handleChange }) => {
  const { events = [] } = useContext(CalendarContext);
  const [show, setShowSelect] = useState(false);
  const event = events?.find(({ NO_ID_FIELD }) => NO_ID_FIELD === eventId);
  const { title } = event || {};

  const handleEventChange = selected => {
    const { value } = selected || {};
    setShowSelect(false);
    handleChange(value);
  };

  return (
    <>
      <Button
        variant={classNames({ light: !event, primary: event })}
        size="sm"
        className="rounded-pill shadow-none d-inline-flex align-items-center fs--1 mb-1 me-1"
        onClick={() => setShowSelect(show => !show)}
      >
        <img src={calendar} alt="" width={17} />
        <span className="ms-2 d-none d-md-inline-block">
          {title || 'Evento'}
        </span>
      </Button>
      <Select
        menuPlacement="auto"
        options={events
          ?.sort(({ start: s1 }, { start: s2 }) => (s1 > s2 ? -1 : 1))
          .map(({ NO_ID_FIELD, title, start }) => ({
            label: `${title} [[${dayjs(start)
              .calendar(null, {
                sameDay: '[Hoy]',
                lastDay: '[Ayer]',
                nextDay: '[Mañana]',
                lastWeek: 'dddd [pasado]',
                nextWeek: 'dddd [que viene]',
                sameElse: 'dddd, D MMMM'
              })
              .replace('.', '')}]]`,
            value: NO_ID_FIELD
          }))}
        isClearable={true}
        backspaceRemovesValue={true}
        placeholder="Selecciona el evento"
        menuPortalTarget={document.body}
        classNamePrefix="react-select"
        className={classNames('form-select-sm p-0 mt-3', { 'd-none': !show })}
        onChange={handleEventChange}
        components={{ Option, SingleValue }}
        styles={styles}
      />
    </>
  );
};

const AppreciationButton = ({ setState }) => {
  const { myCompany } = useContext(UserContext);
  const { apps: activeApps = [] } = myCompany || {};
  if (!activeApps.includes('appreciations')) {
    return null;
  }

  return (
    <>
      <Button
        variant="light"
        size="sm"
        className="rounded-pill shadow-none d-inline-flex align-items-center fs--1 mb-1 me-1"
        onClick={() => setState('appreciation')}
      >
        👏🏼
        <span className="ms-2 d-none d-md-inline-block">Aplaudir</span>
      </Button>
    </>
  );
};

const AppendImagesButton = ({ images, onChange: handleChange }) => {
  const inputFile = useRef();

  useEffect(() => {
    if (!images?.length && inputFile?.current?.value) {
      inputFile.current.value = null;
    }
  }, [images]);

  return (
    <>
      <Button
        variant={classNames({ light: !images?.length, primary: images.length })}
        size="sm"
        className="rounded-pill shadow-none d-inline-flex align-items-center fs--1 mb-1 me-1"
        onClick={e => e.currentTarget.nextElementSibling.click()}
      >
        <img src={image} alt="" width={17} />
        <span className="ms-2 d-none d-md-inline-block">Imagen</span>
      </Button>
      <Form.Control
        ref={inputFile}
        type="file"
        className="d-none"
        multiple
        accept="image/*"
        onChange={handleChange}
      />
    </>
  );
};

ImagePreview.propTypes = {
  image: PropTypes.object,
  index: PropTypes.number,
  setImgIndex: PropTypes.func,
  setImages: PropTypes.func
};

AppendEventButton.propTypes = {
  event: PropTypes.object,
  onChange: PropTypes.func
};

AppreciationButton.propTypes = {
  setState: PropTypes.func
};

AppendImagesButton.propTypes = {
  images: PropTypes.array,
  onChange: PropTypes.func
};

CreatePostForm.propTypes = {
  setState: PropTypes.func
};

export default CreatePostForm;
