import { getUnique } from 'helpers/utils';

export const employeesReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'FILTER':
      return {
        ...state,
        currentFilter: payload,
        employees: state.allEmployees.filter(employee => {
          if (payload === 'all') {
            return true;
          } else if (['archive', 'star'].includes(payload)) {
            return employee[payload];
          } else {
            return employee.department === payload;
          }
        })
      };
    case 'BULK_UPDATE':
      return {
        ...state,
        allEmployees: state.allEmployees.map(employee =>
          payload.ids.includes(employee.id)
            ? { ...employee, [payload.key]: payload.value }
            : employee
        )
      };
    case 'UPDATE':
      return {
        ...state,
        employees: getUnique(
          [
            ...state.employees
              .map(employee => {
                const employeeUpdated = payload.find(
                  ({ id }) => id === employee.id
                );
                return employeeUpdated
                  ? { ...employee, ...employeeUpdated }
                  : null;
              })
              .filter(employee => employee),
            ...(payload || [])
          ],
          'id'
        ),
        allEmployees: getUnique(
          [
            ...state.allEmployees.map(employee => {
              const employeeUpdated = payload.find(
                ({ id }) => id === employee.id
              );
              return employeeUpdated
                ? { ...employee, ...employeeUpdated }
                : employee;
            }),
            ...(payload || [])
          ],
          'id'
        )
      };
    case 'UPDATE_DEPARTMENTS':
      return {
        ...state,
        departments: payload,
        filters: [...new Set([...state.filters, '', ...payload])]
      };
    case 'ARCHIVE':
      return {
        ...state,
        allEmployees: state.allEmployees.map(employee =>
          payload.includes(employee.id)
            ? { ...employee, archive: true }
            : employee
        ),
        employees: state.employees.filter(
          employee => !payload.includes(employee.id)
        )
      };
    case 'DELETE':
      return {
        ...state,
        allEmployees: state.employees.filter(
          employee => !payload.includes(employee.id)
        ),
        employees: state.employees.filter(
          employee => !payload.includes(employee.id)
        )
      };
    case 'READ':
      return {
        ...state,
        employees: state.employees.map(employee =>
          payload.includes(employee.id)
            ? { ...employee, read: !employee.read }
            : employee
        )
      };
    case 'SNOOZE':
      return {
        ...state,
        allEmployees: state.employees.map(employee =>
          payload.includes(employee.id)
            ? { ...employee, snooze: true }
            : employee
        )
      };
    case 'RESET':
      return {
        ...state,
        employees: state.allEmployees,
        currentFilter: 'all'
      };
    default:
      return state;
  }
};
