import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import routes from 'routes/routes';
import ls from 'local-storage';
import AppContext, { UserContext } from 'context/Context';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import PastDueBanner from 'components/banner/PastDueBanner';
import TrialBanner from 'components/banner/TrialBanner';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import Footer from 'components/footer/Footer';
import BenefitsProvider from 'components/benefits/BenefitsProvider';
import CalendarProvider from 'components/company/calendar/CalendarProvider';
import FeedbackProvider from 'components/feedback/FeedbackProvider';
import ChatOverAll from 'components/company/chat/ChatOverAll';
import ChatProvider from 'components/company/chat/ChatProvider';
import IndexesProvider from 'components/dashboards/IndexesProvider';
import ProductProvider from 'components/marketplace/ProductProvider';
import AIProvider from 'components/AIProvider';
import UserProvider from 'components/UserProvider';
import UpgradeModal from 'components/common/UpgradeModal';
import Root from 'components/root';

const getRole = (pathname, routes) => {
  const route = routes.find(({ to }) => to === pathname);
  if (route) {
    const { level, role } = route;
    return { level, role };
  }
  const response = routes.reduce(({ role, level } = {}, route) => {
    const { children } = route;
    return (
      ((role || level) && { role, level }) ||
      (children && getRole(pathname, children))
    );
  }, undefined);
  return response;
};

const IndexesProviderManager = ({ children }) => {
  const { company } = useContext(UserContext);
  const { apps = [] } = company || {};
  return apps.includes('questionaires') ? (
    <IndexesProvider>{children}</IndexesProvider>
  ) : (
    children
  );
};

IndexesProviderManager.propTypes = {
  children: PropTypes.node
};

const FeedbackProviderManager = ({ children }) => {
  const { company } = useContext(UserContext);
  const { apps = [] } = company || {};
  return apps.includes('feedback') ? (
    <FeedbackProvider>{children}</FeedbackProvider>
  ) : (
    children
  );
};

FeedbackProviderManager.propTypes = {
  children: PropTypes.node
};

const ChatProviderManager = ({ children }) => {
  const { company } = useContext(UserContext);
  const { apps = [] } = company || {};
  return apps.includes('chat') ? (
    <ChatProvider>{children}</ChatProvider>
  ) : (
    children
  );
};

ChatProviderManager.propTypes = {
  children: PropTypes.node
};

const CalendarProviderManager = ({ children }) => {
  const { company } = useContext(UserContext);
  const { apps = [] } = company || {};
  return apps.includes('calendar') ||
    apps.includes('time-off') ||
    apps.includes('events') ||
    apps.includes('objectives') ? (
    <CalendarProvider>{children}</CalendarProvider>
  ) : (
    children
  );
};

CalendarProviderManager.propTypes = {
  children: PropTypes.node
};

const MainLayout = () => {
  const { me, upgradeModal, setUpgradeModal } = useContext(UserContext);
  const location = useLocation();
  const { hash, pathname } = location;
  const isKanban = pathname.includes('assistant');
  const isChat = pathname.includes('chat');
  const isCalendar = pathname.includes('calendar');
  const user = JSON.parse(Cookies.get('__fbu') || '{}');
  const isSignedIn = !!user.uid;

  const {
    addToRecentlyBrowsed,
    config: { isFluid, navbarPosition },
    setConfig
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    addToRecentlyBrowsed(pathname);

    setConfig('isFluid', true);
    setConfig('isNavbarVerticalCollapsed', false);

    const _hsq = (window._hsq = window._hsq || []);
    const { NO_ID_FIELD: id, email, name } = me || {};
    me && _hsq.push(['identify', { id, email, name }]);
    _hsq.push(['setPath', pathname]);
    _hsq.push(['trackPageView']);

    // document
    //   .getElementById('hubspot-messages-iframe-container')
    //   ?.classList?.toggle('visually-hidden', pathname.includes('/chat'));
  }, [pathname]);

  if (!isSignedIn) {
    return (
      <Navigate
        to={{
          ...location,
          pathname: '/authentication/login',
          state: { from: location }
        }}
        replace={true}
      />
    );
  }

  const { level, role } = getRole(pathname, routes) || {};
  const isRolePermitted = !role || role === me?.type;
  const isLevelPermitted = !level || level === me?.level;
  if (me?.type && (!isRolePermitted || !isLevelPermitted)) {
    return (
      <Navigate
        to={{
          ...location,
          pathname: '/home',
          state: { from: location }
        }}
        replace={true}
      />
    );
  }

  if (!me?.type) {
    return <Root />;
  }

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/26657745.js"
        ></script>
      </Helmet>
      <PastDueBanner />
      <BenefitsProvider>
        <IndexesProviderManager>
          <ProductProvider>
            <AIProvider>
              <FeedbackProviderManager>
                <ChatProviderManager>
                  <CalendarProviderManager>
                    <div
                      className={classNames('transition-base', {
                        'container-fluid': isFluid,
                        container: !isFluid
                      })}
                    >
                      {(navbarPosition === 'vertical' ||
                        navbarPosition === 'combo') && <NavbarVertical />}
                      <UpgradeModal
                        data={upgradeModal}
                        setData={setUpgradeModal}
                      />

                      <div
                        className={classNames('content', {
                          'pb-0': isKanban || isChat || isCalendar,
                          'd-flex flex-column':
                            pathname === '/home' &&
                            (ls('home') || []).length === 1
                        })}
                      >
                        <NavbarTop />
                        {/*------ Main Routes ------*/}
                        <TrialBanner />
                        <Outlet />
                        <ChatOverAll />
                        {!isKanban && !isChat && !isCalendar && <Footer />}
                      </div>
                    </div>
                  </CalendarProviderManager>
                </ChatProviderManager>
              </FeedbackProviderManager>
            </AIProvider>
          </ProductProvider>
        </IndexesProviderManager>
      </BenefitsProvider>
    </>
  );
};

const MainLayoutWithUser = props => {
  return (
    <UserProvider>
      <MainLayout {...props} />
    </UserProvider>
  );
};

export default MainLayoutWithUser;
