import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { addDoc, collection, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { AppreciationContext, UserContext } from 'context/Context';

export const MAX_APPRECIATIONS = 100;

const AppreciationProvider = ({ children }) => {
  const { company, me } = useContext(UserContext);
  const [thisMonth] = useState(new Date(new Date().setDate(1)));

  const db = useFirestore();
  let dataQuery = query(collection(db, 'none'));
  let targetQuery = query(collection(db, 'none'));
  if (company?.NO_ID_FIELD && me?.NO_ID_FIELD) {
    const filters = [
      where('companyId', '==', company?.NO_ID_FIELD),
      where('createdAt', '>=', thisMonth.toISOString()),
      me?.type !== 'admin' && where('senderId', '==', me?.NO_ID_FIELD)
    ].filter(filter => filter);
    dataQuery = query(collection(db, 'appreciations'), ...filters);
    if (me?.type !== 'admin') {
      targetQuery = query(
        collection(db, 'appreciations'),
        where('companyId', '==', company?.NO_ID_FIELD),
        where('targetId', '==', me?.NO_ID_FIELD),
        where('createdAt', '>=', thisMonth.toISOString())
      );
    }
  }
  const { data: appreciations = [] } = useFirestoreCollectionData(dataQuery);
  const { data: _appreciationsToMe = [] } =
    useFirestoreCollectionData(targetQuery);
  const myAppreciations = appreciations
    .filter(({ senderId }) => senderId === me?.NO_ID_FIELD)
    .sort(({ createdAt: c1 }, { createdAt: c2 }) => (c1 > c2 ? -1 : 1));
  let appreciationsToMe = _appreciationsToMe?.length
    ? _appreciationsToMe
    : appreciations.filter(({ targetId }) => targetId === me?.NO_ID_FIELD);
  appreciationsToMe = appreciationsToMe.sort(
    ({ createdAt: c1 }, { createdAt: c2 }) => (c1 > c2 ? -1 : 1)
  );
  const spent = myAppreciations.reduce((total, { value }) => total + value, 0);
  const remaining = MAX_APPRECIATIONS - spent;

  const create = async params => {
    try {
      const createdAt = new Date(Date.now()).toISOString();
      const data = {
        companyId: company?.NO_ID_FIELD,
        createdAt,
        senderId: me?.NO_ID_FIELD,
        ...params
      };
      await addDoc(collection(db, 'appreciations'), data);
    } catch (error) {
      console.error(error);
    }
  };

  // console.log('APPRECIATIONS >>>', {
  //   myAppreciations,
  //   appreciationsToMe,
  //   spent,
  //   remaining
  // });

  return (
    <AppreciationContext.Provider
      value={{
        appreciations,
        appreciationsToMe,
        create,
        myAppreciations,
        remaining,
        spent
      }}
    >
      {children}
    </AppreciationContext.Provider>
  );
};

AppreciationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppreciationProvider;
