import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { UserContext } from 'context/Context';
import { collection, doc, getDoc, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { getUnique } from 'helpers/utils';
import {
  BadgeCountAppreciations,
  BadgeCountCalendar,
  BadgeCountChat,
  BadgeCountFeedback,
  BadgeCountProfile,
  BadgeCountQuestionaires,
  BadgeCountReviews
} from 'components/BadgeCount';

const badgeCountTypes = {
  appreciations: BadgeCountAppreciations,
  calendar: BadgeCountCalendar,
  chat: BadgeCountChat,
  feedback: BadgeCountFeedback,
  profile: BadgeCountProfile,
  questionaires: BadgeCountQuestionaires,
  reviews: BadgeCountReviews
};

const BadgeCount = props => {
  const { collection: collectionName, filter: filterProp, type } = props;
  const { company, me } = useContext(UserContext);
  const [extra, setExtra] = useState(0);
  const db = useFirestore();
  let badgeValue = 0;
  let badgeQuery = query(collection(db, 'none'));
  let companyQuery = query(collection(db, 'none'));
  let filter = [...(filterProp?.map(items => where(...items)) || [])].filter(
    filter => filter
  );

  if (company?.NO_ID_FIELD) {
    badgeQuery = query(
      collection(db, collectionName),
      !['benefits', 'programmes'].includes(collectionName) &&
        where('companyId', '==', company?.NO_ID_FIELD),
      ...filter,
      ...[
        collectionName === 'calendar' &&
          me?.type === 'employee' &&
          where('participants', 'array-contains', me?.ref)
      ].filter(filter => filter)
    );

    if (collectionName === 'calendar' && me?.type === 'employee') {
      companyQuery = query(
        collection(db, collectionName),
        where('companyId', '==', company?.NO_ID_FIELD),
        where('participants', '==', null),
        ...filter
      );
    }
  }

  const BadgeCountType = badgeCountTypes[collectionName];
  if (BadgeCountType) {
    return <BadgeCountType {...props} />;
  }

  const { data: raw = [] } = useFirestoreCollectionData(badgeQuery);
  let data = [...raw];
  const { data: aux = [] } = useFirestoreCollectionData(companyQuery);
  data = getUnique([...data, ...aux], 'NO_ID_FIELD');
  badgeValue = data?.length || badgeValue;
  badgeValue = badgeValue + extra;

  useEffect(() => {
    if (
      collectionName !== 'benefits' ||
      !company?.NO_ID_FIELD ||
      !me?.NO_ID_FIELD
    ) {
      return;
    }
    (async () => {
      try {
        const { benefits: companyBenefits = [] } = await (
          await getDoc(doc(db, 'companies', company?.NO_ID_FIELD))
        ).data();
        const { benefits: userBenefits = [] } = await (
          await getDoc(doc(db, 'users', me?.NO_ID_FIELD))
        ).data();
        setExtra((companyBenefits?.length || 0) + (userBenefits?.length || 0));
      } catch (error) {
        console.error(error);
      }
    })();
  }, [
    collectionName,
    company?.NO_ID_FIELD,
    company?.benefits,
    me?.NO_ID_FIELD,
    me?.benefits
  ]);

  return (
    (badgeValue && (
      <SoftBadge pill bg={type} className="ms-2">
        <span className="fs--2">{badgeValue}</span>
      </SoftBadge>
    )) ||
    null
  );
};

const NavbarVerticalMenuItem = ({ route }) => {
  const { badge, icon, name } = route;
  const { company, me } = useContext(UserContext);
  const [values, setValues] = useState({
    unreadMessages:
      me?.unreadMessages?.reduce((all, { unread }) => all + unread, 0) || 0,
    companyId: company?.NO_ID_FIELD
  });
  const { collection: badgeCollection, text, type } = badge || {};
  let badgeValue = text?.replace(/{{([^}]+)}}/g, (s, key) => values?.[key]);

  useEffect(() => {
    setValues(values => ({
      ...values,
      unreadMessages:
        me?.unreadMessages?.reduce((all, { unread }) => all + unread, 0) || 0
    }));
  }, [me?.unreadMessages]);

  useEffect(() => {
    setValues(values => ({ ...values, companyId: company?.NO_ID_FIELD }));
  }, [company?.NO_ID_FIELD]);

  badgeValue = isNaN(badgeValue) ? badgeValue : parseInt(badgeValue);

  return (
    <Flex alignItems="center">
      {icon && (
        <span className="nav-link-icon">
          <FontAwesomeIcon icon={icon} />
        </span>
      )}
      <span className="nav-link-text ps-1 text-nowrap text-truncate">
        {name}
      </span>
      {badgeCollection ? (
        <BadgeCount {...badge} />
      ) : (
        !!badgeValue && (
          <SoftBadge pill bg={type} className="ms-2">
            <span className="fs--2">{badgeValue}</span>
          </SoftBadge>
        )
      )}
    </Flex>
  );
};

// prop-types
const routeShape = {
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};
routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));

BadgeCount.propTypes = {
  collection: PropTypes.string.isRequired,
  filter: PropTypes.array,
  type: PropTypes.string
};

NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape(routeShape).isRequired
};

export default React.memo(NavbarVerticalMenuItem);
