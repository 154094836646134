import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Row, Col, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Tooltip from 'components/common/Tooltip';
import QuestionaireProvider from 'components/company/questionaires/QuestionaireProvider';
import Header from './Header';
import Analytics from './Analytics';
import Participants from './Participants';
import Questions from './Questions';
import Responses from './Responses';
import Settings from './Settings';
import HappyQuestionaire from './HappyQuestionaire';
import { QuestionairesContext, UserContext } from 'context/Context';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import logo from 'assets/img/logo.png';

dayjs.extend(relativeTime);
dayjs.locale('es');

const QuestionaireDetailContent = () => {
  const { me, defaults } = useContext(UserContext);
  const { happyResponses: responses } = useContext(QuestionairesContext);
  const { key = 'settings' } = useParams();
  const [activeKey, setActiveKey] = useState(key);
  const navigate = useNavigate();
  const { happy_questionaire: questionaire = {} } = defaults || {};
  const { createdAt, title, description } = questionaire || {};
  const time = dayjs().to(dayjs(createdAt));

  const handleSelect = key => {
    setActiveKey(key);
    navigate(`/company/happy-questionaire/detail/${key}`);
  };

  useEffect(() => {
    setActiveKey(key);
  }, [key]);
  const undone = !responses.some(({ userId }) => userId === me?.NO_ID_FIELD);

  return (
    <>
      <Header questionaire={questionaire} />
      <Card>
        <Card.Header>
          <Row>
            <Col md as={Flex}>
              <div className="pe-x1 pt-1">
                <Avatar src={logo} size="2xl" />
              </div>
              <div className="flex-1">
                <h5 className="mb-0">{title}</h5>
                <span className="text-500">{description}</span>
              </div>
            </Col>
            <Col md="auto" className="fs--1 ps-6 ps-md-3 text-end">
              <div>{time}</div>
              <div className="text-500">{responses.length} respuesta(s)</div>
            </Col>
          </Row>
        </Card.Header>
        {questionaire && (
          <Tabs
            className="bg-white ps-6"
            activeKey={activeKey}
            onSelect={handleSelect}
          >
            <Tab eventKey="settings" title="Configuración">
              <Settings
                questionaire={questionaire}
                hasResponses={responses.length > 0}
              />
            </Tab>
            <Tab eventKey="questions" title="Preguntas">
              <Questions
                questionaire={questionaire}
                hasResponses={responses.length > 0}
              />
            </Tab>
            <Tab
              eventKey="questionaire"
              title={
                <Flex alignItems="center">
                  Cuestionario
                  {!!undone && (
                    <Tooltip title="Por contestar" className="ms-1">
                      <SoftBadge bg="warning" pill>
                        <FontAwesomeIcon icon="exclamation-triangle" />
                      </SoftBadge>
                    </Tooltip>
                  )}
                </Flex>
              }
            >
              <HappyQuestionaire
                questionaire={questionaire}
                responses={responses}
              />
            </Tab>
            <Tab eventKey="participants" title="Participantes">
              <Participants questionaire={questionaire} responses={responses} />
            </Tab>
            <Tab eventKey="responses" title="Respuestas">
              <Responses questionaire={questionaire} responses={responses} />
            </Tab>
            <Tab eventKey="analytics" title="Analíticas">
              <Analytics questionaire={questionaire} responses={responses} />
            </Tab>
          </Tabs>
        )}
      </Card>
    </>
  );
};

const QuestionaireDetail = () => {
  return (
    <QuestionaireProvider>
      <QuestionaireDetailContent />
    </QuestionaireProvider>
  );
};

export default QuestionaireDetail;
