import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import GoogleMap from 'components/map/GoogleMap';
import AppContext from 'context/Context';

const MapDark = ({ children, latlng }) => {
  return (
    <GoogleMap
      initialCenter={latlng}
      mapStyle="AssassianCreed"
      darkStyle="Cobalt"
      className="vh-50 rounded-soft mt-5"
      zoom={16}
    >
      {children}
    </GoogleMap>
  );
};
const MapLight = ({ children, latlng }) => {
  return (
    <GoogleMap
      initialCenter={latlng}
      mapStyle="Default"
      className="vh-50 rounded-soft mt-5"
      zoom={16}
    >
      {children}
    </GoogleMap>
  );
};
const EventDetailsGmap = props => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  return <>{isDark ? <MapDark {...props} /> : <MapLight {...props} />}</>;
};

MapDark.propTypes = {
  children: PropTypes.node,
  latlng: PropTypes.object
};

MapLight.propTypes = {
  children: PropTypes.node,
  latlng: PropTypes.object
};

export default EventDetailsGmap;
