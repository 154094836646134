import React from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { collection, doc, query, where } from '@firebase/firestore';
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData
} from 'reactfire';
import { Helmet } from 'react-helmet';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import ReviewProvider from './ReviewProvider';
import Analytics from './Analytics';
import Responses from './Responses';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

dayjs.extend(relativeTime);
dayjs.locale('es');

const ReviewDetailContent = () => {
  const { reviewId } = useParams();
  const db = useFirestore();
  const reviewRef = doc(db, 'reviews', reviewId);
  const userResponseRef = query(
    collection(db, 'reviews_responses'),
    where('reviewId', '==', reviewId)
  );
  const { data: review } = useFirestoreDocData(reviewRef);
  const { data: responses = [] } = useFirestoreCollectionData(userResponseRef);
  const { title, description } = review || {};

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {review &&
        ((
          <div className="my-4">
            <Card className="border-bottom shadow-none pb-3 mb-4">
              <Flex>
                <div className="pe-x1 pt-1">
                  <Avatar name={title} size="2xl" />
                </div>
                <div className="flex-1">
                  <h5 className="mb-0">{title}</h5>
                  <span className="text-500">{description}</span>
                </div>
              </Flex>
            </Card>
            <div>
              <Analytics review={review} responses={responses} />
              <Responses review={review} responses={responses} />
            </div>
          </div>
        ) ||
          null)}
    </>
  );
};

const ReviewDetail = () => {
  return (
    <ReviewProvider>
      <ReviewDetailContent />
    </ReviewProvider>
  );
};

export default ReviewDetail;
