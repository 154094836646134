import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Background from 'components/common/Background';
import corner1 from 'assets/img/illustrations/corner-1.png';
import BenefitsProvider from 'components/benefits/BenefitsProvider';
import Flex from 'components/common/Flex';
import { BenefitsContext, CalendarContext, UserContext } from 'context/Context';
import { Card } from 'react-bootstrap';
import { getCurrencyFormat } from 'helpers/utils';
import CountUp from 'react-countup';

const WalletContent = ({
  className,
  headerClassName,
  amountClassName,
  showRewards = true
}) => {
  const { me } = useContext(UserContext);
  const { total } = useContext(BenefitsContext);
  const { objectives = [] } = useContext(CalendarContext);
  const rewards = objectives
    .filter(
      ({ participants, state }) =>
        (!participants ||
          participants.some(ref => ref?.path === me?.ref?.path)) &&
        state === 'success'
    )
    .sort(({ createdAt: d1 }, { createdAt: d2 }) => (d1 > d2 ? -1 : 1));

  return (
    <>
      <Card className={classNames('bg-soft-primary', className)}>
        <Background image={corner1} className="d-none d-md-block bg-card" />
        <Card.Body className="position-relative pb-0">
          <h5 className={classNames('text-primary mb-0', headerClassName)}>
            Monedero
          </h5>
        </Card.Body>
        <Card.Body className="position-relative pt-2">
          <div
            className={classNames('fw-normal text-primary', amountClassName)}
          >
            <CountUp
              start={0}
              end={total}
              decimals={2}
              duration={1}
              formattingFn={useCallback(value => {
                return getCurrencyFormat(value);
              }, [])}
            />
          </div>
        </Card.Body>
      </Card>
      {showRewards && (
        <Card className={classNames('bg-soft-primary', className)}>
          <Background image={corner1} className="d-none d-md-block bg-card" />
          <Card.Body className="position-relative">
            <h5 className={classNames('text-primary mb-0', headerClassName)}>
              Recompensas canjeables
            </h5>
          </Card.Body>
          <Card.Body className="position-relative pt-2">
            <div className={classNames('fw-normal text-primary')}>
              {(!rewards.length && (
                <h6 className="text-primary">Todavía no tienes recompensas</h6>
              )) ||
                rewards.map(({ NO_ID_FIELD, reward }) => (
                  <Flex key={`Reward-${NO_ID_FIELD}`}>
                    <FontAwesomeIcon icon="check" size="sm" className="me-2" />
                    <h6 className="text-primary">{reward}</h6>
                  </Flex>
                ))}
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

const Wallet = props => {
  return (
    <BenefitsProvider>
      <WalletContent {...props} />
    </BenefitsProvider>
  );
};

WalletContent.propTypes = {
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  amountClassName: PropTypes.string,
  showRewards: PropTypes.bool
};

export default Wallet;
