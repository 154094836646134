import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import AnniversaryNotice from './AnniversaryNotice';
import BirthdatNotice from './BirthdatNotice';
import dayjs from 'dayjs';

const Birthdays = () => {
  const { partners = [] } = useContext(UserContext);
  const usersWithBirthday = partners
    ?.map(user => {
      let { birthday } = user;
      const date = birthday && (birthday.toDate?.() || new Date(birthday));
      const bithdayDiff =
        birthday &&
        dayjs(new Date(date.setFullYear(new Date().getFullYear()))).diff(
          new Date(),
          'days'
        ) + 1;
      return { ...user, birthday: date, bithdayDiff };
    })
    .filter(({ bithdayDiff }) => typeof bithdayDiff === 'number');
  const usersWithAnniversaries = partners
    ?.map(user => {
      let { startAt } = user;
      const anniversaryDate =
        startAt && (startAt.toDate?.() || new Date(startAt));
      const workAnniversaryDiff =
        startAt &&
        dayjs(
          new Date(anniversaryDate.setFullYear(new Date().getFullYear()))
        ).diff(new Date(), 'days');
      const workAnniversaryYears =
        startAt &&
        dayjs(`${new Date().getFullYear()}`).diff(
          `${new Date(startAt).getFullYear()}`,
          'years'
        );
      return {
        ...user,
        anniversaryDate,
        workAnniversaryDiff,
        workAnniversaryYears
      };
    })
    .filter(
      ({ workAnniversaryDiff }) => typeof workAnniversaryDiff === 'number'
    );
  let nextUserBirthdays = usersWithBirthday.filter(({ bithdayDiff }) => {
    return bithdayDiff > -3 && bithdayDiff < 3;
  });
  let nextUserAnniversaries = usersWithAnniversaries.filter(
    ({ workAnniversaryDiff }) => {
      return workAnniversaryDiff > -3 && workAnniversaryDiff < 3;
    }
  );

  if (nextUserBirthdays?.length < 4) {
    nextUserBirthdays = usersWithBirthday.filter(({ bithdayDiff }) => {
      return bithdayDiff > -3 && bithdayDiff < 15;
    });
  }
  if (nextUserAnniversaries?.length < 4) {
    nextUserAnniversaries = usersWithAnniversaries.filter(
      ({ workAnniversaryDiff }) => {
        return workAnniversaryDiff > -3 && workAnniversaryDiff < 15;
      }
    );
  }
  nextUserAnniversaries = nextUserAnniversaries.sort(
    ({ workAnniversaryDiff: b1 }, { workAnniversaryDiff: b2 }) =>
      b1 < b2 ? -1 : 1
  );
  nextUserBirthdays = nextUserBirthdays.sort(
    ({ birthdayMonth: b1 }, { birthdayMonth: b2 }) => (b1 < b2 ? -1 : 1)
  );

  return (
    <>
      {!nextUserBirthdays.length && (
        <Card className="mb-3">
          <Card.Body className="fs--1">
            <Flex>
              <FontAwesomeIcon icon="gift" className="fs-0 text-warning" />
              <div className="ms-2 flex-1">
                No hay aniversarios próximamente
              </div>
            </Flex>
          </Card.Body>
        </Card>
      )}
      {nextUserBirthdays.map(user => (
        <BirthdatNotice
          key={`Birthday-${user?.NO_ID_FIELD}`}
          user={user}
          className="mb-3"
        />
      ))}
      {nextUserAnniversaries.map(user => (
        <AnniversaryNotice
          key={`Anniversary-${user?.NO_ID_FIELD}`}
          user={user}
          className="mb-3"
        />
      ))}
    </>
  );
};

export default Birthdays;
