import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from './Flex';
import classNames from 'classnames';

const DropdownItemFilter = ({
  filter,
  currentFilter,
  className,
  children,
  isMulti,
  ...rest
}) => {
  return (
    <Dropdown.Item
      as={Flex}
      justifyContent="between"
      className={classNames('cursor-pointer', className)}
      {...rest}
    >
      <>{children}</>
      {((isMulti &&
        currentFilter.some(({ value }) => value === filter?.value)) ||
        filter.value === currentFilter.value) && (
        <FontAwesomeIcon
          className="ms-2"
          icon="check"
          transform="down-4 shrink-4"
        />
      )}
    </Dropdown.Item>
  );
};

const DropdownFilter = ({
  filters,
  handleFilter,
  currentFilter,
  icon,
  isMulti,
  menuParams,
  ...rest
}) => {
  return (
    <Dropdown
      className="font-sans-serif me-2"
      autoClose="outside"
      style={{ '--falcon-dropdown-content': 'none' }}
      {...rest}
    >
      <Dropdown.Toggle
        variant="falcon-default"
        className="text-600 dropdown-caret-none d-flex align-items-center"
        size="sm"
      >
        {((isMulti && !!currentFilter.length) || currentFilter) && (
          <span className="me-2 text-truncate max-w-100px overflow-hidden">
            {(isMulti && currentFilter.map(({ title }) => title))?.join(', ') ||
              currentFilter.title}
          </span>
        )}
        <FontAwesomeIcon icon={icon} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="border py-2" {...menuParams}>
        {filters.map((filter, index) => (
          <DropdownItemFilter
            key={`DropdownItemFilter-${index}`}
            currentFilter={currentFilter}
            onClick={() => {
              handleFilter(filter);
            }}
            filter={filter}
            isMulti={isMulti}
          >
            {filter.title}
          </DropdownItemFilter>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DropdownItemFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  currentFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  isMulti: PropTypes.bool
};

DropdownFilter.propTypes = {
  filters: PropTypes.array,
  handleFilter: PropTypes.func.isRequired,
  currentFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  icon: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  menuParams: PropTypes.object
};

export default DropdownFilter;
