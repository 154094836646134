import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Event from 'components/company/events/Event';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CalendarContext, UserContext } from 'context/Context';

export const PendingTimesOff = ({ avoidPastEvents, className }) => {
  const { events = [], setIsOpenNewEventModal } = useContext(CalendarContext);
  const { me } = useContext(UserContext);
  const data = events.map(event => {
    const { assistance = [] } = event;
    const { assist } =
      assistance.find(({ ref }) => ref?.path === me?.ref?.path) || {};
    const state =
      assist === null
        ? 'maybe'
        : assist === true
        ? 'going'
        : assist === false
        ? 'not-going'
        : 'waiting';
    return { ...event, assistance, state };
  });
  const myEvents = data.filter(
    ({ participants }) =>
      !participants || participants.some(ref => ref?.path === me?.ref?.path)
  );
  const myWaitingEvents = (me?.type === 'admin' ? data : myEvents).filter(
    ({ start, state }) =>
      state === 'waiting' &&
      (!avoidPastEvents || start > new Date().toISOString())
  );

  const handleEnter = timeOff => {
    const { NO_ID_FIELD } = timeOff;
    const els = document.querySelectorAll(`[data-id="${NO_ID_FIELD}"]`);
    els?.forEach(el => {
      el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.add(
        'highlight'
      );
    });
  };

  const handleLeave = timeOff => {
    const { NO_ID_FIELD } = timeOff;
    const els = document.querySelectorAll(`[data-id="${NO_ID_FIELD}"]`);
    els?.forEach(el => {
      el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.remove(
        'highlight'
      );
    });
  };

  return (
    <Card className={className}>
      <Card.Body className="fs--1">
        {!myWaitingEvents?.length && (
          <Flex
            className="h-100 p-3 text-center"
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              icon="calendar-day"
              className="fs-2 text-400 mb-2"
            />
            <p>No hay eventos pendientes de decidir la asistencia</p>
            {me?.type === 'admin' && (
              <Button
                variant="falcon-default"
                className="rounded-pill"
                size="sm"
                onClick={() => setIsOpenNewEventModal(true)}
              >
                Crear evento
              </Button>
            )}
          </Flex>
        )}
        {myWaitingEvents?.map((details, index) => (
          <Event
            key={`Event-${details.NO_ID_FIELD}`}
            color="info"
            details={details}
            isLast={index === myWaitingEvents.length - 1}
            onMouseEnter={() => handleEnter(details)}
            onMouseLeave={() => handleLeave(details)}
          />
        ))}
      </Card.Body>
    </Card>
  );
};

PendingTimesOff.propTypes = {
  avoidPastEvents: PropTypes.bool,
  className: PropTypes.string
};

export default PendingTimesOff;
