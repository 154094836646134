import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import AppreciationProvider from 'components/company/appreciations/AppreciationProvider';
import AdminAppreciations from './AdminAppreciations';
import AppreciationsUse from './AppreciationsUse';
import UserAppreciations from './UserAppreciations';
import { UserContext } from 'context/Context';

const AppreciationsContent = () => {
  const { me } = useContext(UserContext);

  return (
    <>
      <Row className="g-3 mt-3">
        <Col xs={12} lg={8} xxl={9} className="order-1 mt-3 mt-lg-0">
          <UserAppreciations />
          {me?.type === 'admin' && <AdminAppreciations />}
        </Col>
        <Col xs={12} lg={4} xxl={3} className="mt-0 order-0 order-lg-2">
          <div className="sticky-sidebar">
            <AppreciationsUse />
          </div>
        </Col>
      </Row>
    </>
  );
};

const AppreciationsWithProvider = props => {
  return (
    <AppreciationProvider>
      <AppreciationsContent {...props} />
    </AppreciationProvider>
  );
};

export default AppreciationsWithProvider;
