import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import AppreciationProvider from 'components/company/appreciations/AppreciationProvider';
import { AppreciationContext, UserContext } from 'context/Context';

const Appreciation = ({ appreciation, isLast }) => {
  const { me, partners = [] } = useContext(UserContext);
  const { senderId, text, value } = appreciation;
  const sender = partners.find(({ NO_ID_FIELD }) => NO_ID_FIELD === senderId);
  const { name: senderName } = sender || {};
  const LinkOrSpan = me?.type === 'admin' ? Link : 'span';
  const linkParams =
    me?.type === 'admin' ? { to: `/user/profile/${senderId}` } : {};

  return (
    <Flex
      className={classNames('fs--1', {
        'border-bottom border-200 pb-2 mb-2': !isLast
      })}
    >
      👏🏼
      <div className="ms-2 flex-1">
        <LinkOrSpan className="fw-semi-bold" {...linkParams}>
          {senderName}
        </LinkOrSpan>{' '}
        te ha enviado <strong>{value}</strong> aplausos
        <p className="text-500 m-0">{text}</p>
      </div>
    </Flex>
  );
};

const Appreciations = () => {
  const { appreciationsToMe = [] } = useContext(AppreciationContext);
  if (!appreciationsToMe.length) {
    return null;
  }
  return (
    <Card className="mb-3">
      <SimpleBarReact className="max-h-400px">
        <Card.Body>
          {appreciationsToMe.map((appreciation, index) => (
            <Appreciation
              key={`Appreciation-${appreciation?.NO_ID_FIELD}`}
              appreciation={appreciation}
              isLast={index === appreciationsToMe.length - 1}
            />
          ))}
        </Card.Body>
      </SimpleBarReact>
    </Card>
  );
};

const AppreciationsWithProvider = props => {
  return (
    <AppreciationProvider>
      <Appreciations {...props} />
    </AppreciationProvider>
  );
};

Appreciation.propTypes = {
  appreciation: PropTypes.object,
  isLast: PropTypes.bool
};

export default AppreciationsWithProvider;
