import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import CreateAppreciation from 'components/company/appreciations/CreateAppreciation';

const NewAppreciation = ({ className }) => {
  return (
    <Card className={className}>
      <div className="overflow-hidden rounded-4">
        <Card.Header>
          <h6 className="m-0">Reconoce a un compañero</h6>
        </Card.Header>
        <Card.Body className="p-0">
          <CreateAppreciation />
        </Card.Body>
      </div>
    </Card>
  );
};

NewAppreciation.propTypes = {
  className: PropTypes.string
};

export default NewAppreciation;
