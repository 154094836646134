import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { OnBoardingContext, UserContext } from 'context/Context';
import InputField from 'components/common/InputField';

const UserProfile = ({ bodyClassName, className, headerClassName }) => {
  const { setStep } = useContext(OnBoardingContext);
  const { authUser, me = {}, updateUser } = useContext(UserContext);
  const { displayName, email: emailAuth, uid } = authUser || {};
  const { email } = me;
  const [formData, setFormData] = useState({ type: 'admin' });
  const data = { ...me, ...formData };
  const [isLoading, setLoading] = useState(false);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const params = { [name]: value };
    if (['firstname', 'lastname'].includes(name)) {
      const nameParams = {
        firstname: formData?.firstname,
        lastname: formData?.lastname
      };
      nameParams[name] = value;
      params.name = `${nameParams?.firstname} ${nameParams?.lastname}`;
    }
    setFormData({
      ...formData,
      ...params
    });
  };

  const handleSubmit = async event => {
    setLoading(true);
    event.preventDefault();

    if (formData?.email && formData?.email !== email) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FIREBASE_URL}/updateEmail`,
          {
            method: 'POST',
            body: JSON.stringify({
              userId: uid,
              email: formData?.email
            })
          }
        );
        if (response.status !== 200) {
          throw Error();
        }
      } catch (error) {
        console.error(error);
      }
    }

    await updateUser(me, { ...formData, id: uid });
    setLoading(false);
    setStep(step => step + 1);
  };

  useEffect(() => {
    const [firstname, ...lastNameArr] = displayName?.split(' ') || [];
    const lastname = lastNameArr.join(' ');
    setFormData(formData => ({
      ...formData,
      email: emailAuth,
      firstname,
      name: displayName,
      lastname
    }));
  }, [displayName, emailAuth]);

  return (
    <Row className="flex-center">
      <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
        <Card className={className}>
          <FalconCardHeader
            title="Información personal"
            className={headerClassName}
          />
          <Card.Body className={classNames(bodyClassName)}>
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={4} controlId="firstName">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={data?.firstname || ''}
                    name="firstname"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} lg={8} controlId="lastName">
                  <Form.Label>Apellidos</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={data?.lastname || ''}
                    name="lastname"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={6} controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder=""
                    value={data?.email || ''}
                    name="email"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} lg={6} controlId="phone">
                  <Form.Label>Teléfono</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={data?.phone || ''}
                    name="phone"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={6} controlId="birthday">
                  <InputField
                    type="date"
                    label="Fecha de nacimiento"
                    name="birthday"
                    setValue={(name, value) => {
                      value = new Date(value).toISOString();
                      handleChange({ target: { name, value } });
                    }}
                    datepickerProps={{
                      dateFormat: 'dd/MM/yyyy',
                      required: true,
                      selected:
                        (data?.birthday?.toMillis?.() || data?.birthday) &&
                        new Date(data?.birthday?.toMillis?.() || data?.birthday)
                    }}
                    formControlProps={{
                      placeholder: '01/01/1986',
                      icon: 'calendar-alt'
                    }}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={6} controlId="nif">
                  <Form.Label>NIF</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="00000000A"
                    value={data?.nif || ''}
                    name="nif"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Row>

              <div className="text-end mt-5">
                <Button variant="primary" type="submit">
                  {isLoading && (
                    <Spinner
                      size="sm"
                      className="me-2"
                      style={{ width: '1rem', height: '1rem' }}
                    />
                  )}
                  Siguiente
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

UserProfile.propTypes = {
  bodyClassName: PropTypes.string,
  className: PropTypes.string,
  headerClassName: PropTypes.string
};

export default UserProfile;
