import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Card } from 'react-bootstrap';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import TimeOff from 'components/user/time-off/TimeOff';
import { CalendarContext } from 'context/Context';

const NextTimesOff = ({ className }) => {
  const { timeOff: data = [] } = useContext(CalendarContext);
  const [today] = new Date().toISOString().match(/\d{4}-\d{2}-\d{2}/) || [];
  const nextTimesOff = data.filter(
    ({ approved, start }) => approved && start > today
  );
  return (
    <Card className={className}>
      <div className="overflow-hidden rounded-4 min-h-100">
        <SimpleBarReact className="h-100">
          <Card.Header className="sticky-top">
            <h6 className="m-0">Próximas ausencias</h6>
          </Card.Header>
          <Card.Body className="flex-grow-1 fs--1">
            {!nextTimesOff?.length && <p>No hay ausencias</p>}
            {nextTimesOff?.map((timeOff, index) => (
              <TimeOff
                key={`TimeOff-${timeOff?.NO_ID_FIELD}`}
                details={timeOff}
                isLast={index === nextTimesOff.length - 1}
              />
            ))}
          </Card.Body>
          <FalconCardFooterLink
            className="sticky-bottom"
            title="Todas las ausencias"
            to="/user/time-off"
            size="sm"
          />
        </SimpleBarReact>
      </div>
    </Card>
  );
};

NextTimesOff.propTypes = {
  className: PropTypes.string
};

export default NextTimesOff;
