import React, { useContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { ProductContext, UserContext } from 'context/Context';
import { productReducer } from 'reducers/productReducer';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  query,
  updateDoc
} from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import texts from './product/texts.json';

const ProductProvider = ({ children }) => {
  const db = useFirestore();
  const initData = {
    sortBy: 'name',
    order: 'desc',
    filters: [],
    initProducts: [],
    products: [],
    primaryProducts: []
  };
  const [productFilters] = useState([
    {
      id: 'apps',
      label: 'apps',
      options: [
        {
          label: 'gratuitas',
          type: 'checkbox',
          value: 'free',
          name: 'free'
        },
        {
          label: 'pro',
          type: 'checkbox',
          value: 'pro',
          name: 'pro'
        }
      ].sort(({ label: l1 }, { label: l2 }) => l1 - l2)
    }
  ]);
  const [productsState, productsDispatch] = useReducer(
    productReducer,
    initData
  );
  const { company } = useContext(UserContext);
  const { apps: activeApps } = company;
  const appsQuery = query(collection(db, 'apps'));
  const { data: all = [] } = useFirestoreCollectionData(appsQuery);

  const createReview = async (product, params) => {
    try {
      const now = new Date().toISOString();
      const data = {
        createdAt: now,
        ...params
      };
      const newReviewRef = await addDoc(
        collection(db, 'apps', product?.NO_ID_FIELD, 'reviews'),
        data
      );
      return newReviewRef;
    } catch (error) {
      console.error(error);
    }
  };

  const updateReview = async (product, review, params) => {
    try {
      const now = new Date().toISOString();
      const data = {
        updatedAt: now,
        ...params
      };
      await updateDoc(
        doc(db, 'apps', product?.NO_ID_FIELD, 'reviews', review?.NO_ID_FIELD),
        data
      );
    } catch (error) {
      console.error(error);
    }
  };

  const deleteReview = async (product, review) => {
    try {
      await deleteDoc(
        doc(db, 'apps', product?.NO_ID_FIELD, 'reviews', review?.NO_ID_FIELD)
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      const products = all.map(product => {
        const { free } = product;
        const { title, description } = texts[product?.NO_ID_FIELD];
        return {
          ...product,
          id: product?.NO_ID_FIELD,
          description,
          tags: [
            free ? 'free' : 'pro',
            ...new Set(
              product?.tags?.split(',').map(tag => tag.trim().toLowerCase()) ||
                []
            )
          ],
          title
        };
      });
      productsDispatch({ type: 'RESET', payload: { products } });
    })();
  }, [all]);

  // console.log('MARKETPLACE >>>', all);

  return (
    <ProductContext.Provider
      value={{
        activeApps,
        apps: all,
        createReview,
        updateReview,
        deleteReview,
        productFilters,
        productsState,
        productsDispatch
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

ProductProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ProductProvider;
