import React, { useContext, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { UserContext } from 'context/Context';

const Menu = ({ children, ...rest }) => {
  return (
    <components.Menu
      {...rest}
      className="overflow-hidden min-w-300px mt-0 border-top border-200 rounded-top-0 shadow"
    >
      {children}
    </components.Menu>
  );
};

Menu.propTypes = {
  children: PropTypes.node
};

const Option = ({ data, isFocused, isSelected, ...rest }) => {
  const { avatar, label } = data;
  return (
    <components.Option
      {...rest}
      className={classNames('m-0', {
        'bg-primary text-white': isSelected,
        'bg-soft-primary text-dark': isFocused && !isSelected
      })}
    >
      <Flex alignItems="center">
        <Avatar className="me-2" size="s" src={avatar} name={label} />
        <span
          className={classNames({
            'text-white': isSelected
          })}
        >
          {label}
        </span>
      </Flex>
    </components.Option>
  );
};

Option.propTypes = {
  data: PropTypes.object,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool
};

const Placeholder = ({ selectProps, ...rest }) => {
  const { placeholder } = selectProps;
  return (
    <components.Placeholder {...rest} className="my-1">
      <Flex alignItems="center">
        <Avatar className="me-2" size="s" name="" />
        <span>{placeholder}</span>
      </Flex>
    </components.Placeholder>
  );
};

Placeholder.propTypes = {
  selectProps: PropTypes.object
};

const SingleValue = ({ data, ...rest }) => {
  const { avatar, label } = data;
  return (
    <components.SingleValue {...rest}>
      <Flex alignItems="center">
        <Avatar className="me-2" size="s" src={avatar} name={label} />
        <span>{label}</span>
      </Flex>
    </components.SingleValue>
  );
};

SingleValue.propTypes = {
  data: PropTypes.object
};

const styles = {
  control: base => ({
    ...base,
    fontFamily:
      'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',
    paddingLeft: '1.25rem',
    borderRadius: '1.375rem'
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  }),
  input: base => ({ ...base, paddingLeft: '28px', boxShadow: 'none' })
};

const EmployeeSelector = ({ externalRef, ...props }) => {
  const { partners = [] } = useContext(UserContext);
  const select = externalRef || useRef();
  const options =
    props?.options ||
    partners.map(({ NO_ID_FIELD, avatar, name }) => ({
      avatar,
      label: name,
      value: NO_ID_FIELD
    }));

  return (
    <Select
      ref={select}
      options={options}
      classNamePrefix="react-select"
      isClearable
      isSearchable
      menuPortalTarget={document.body}
      openMenuOnFocus
      styles={styles}
      components={{ Menu, Option, Placeholder, SingleValue }}
      {...props}
    />
  );
};

EmployeeSelector.propTypes = {
  externalRef: PropTypes.object,
  options: PropTypes.array
};

export default EmployeeSelector;
