import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import UserList from 'components/common/UserList';
import Objectives from 'components/user/profile/Objectives';
import { CalendarContext, UserContext } from 'context/Context';

const ObjectiveDetailAside = ({ objective = {} }) => {
  const { update } = useContext(CalendarContext);
  const { me, partners } = useContext(UserContext);
  const {
    NO_ID_FIELD,
    participants: participantsRaw = [],
    start,
    validated
  } = objective;
  const participants = participantsRaw?.map(({ ref }) =>
    partners.find(partner => partner?.ref?.path === ref?.path)
  );

  const handleValidate = async validated => {
    update(objective, { validated });
  };

  return (
    <div className="sticky-sidebar">
      <Card className="mb-3 fs--1">
        <Card.Body>
          {me?.type === 'admin' && validated !== null && (
            <>
              <h6>Validación</h6>
              <Dropdown className="z-index-1016 mb-3">
                <Dropdown.Toggle
                  className="px-4 px-sm-5"
                  variant={
                    validated === true
                      ? 'success'
                      : validated === false
                      ? 'danger'
                      : 'falcon-default'
                  }
                >
                  {validated === null && (
                    <FontAwesomeIcon className="me-2" icon={['far', 'clock']} />
                  )}
                  {validated ? 'Validado' : 'No validado'}
                </Dropdown.Toggle>
                <Dropdown.Menu className="py-2">
                  <Dropdown.Item
                    active={validated === null}
                    onClick={() => handleValidate(null)}
                  >
                    <span>Pendiente de validar</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={validated === true}
                    onClick={() => handleValidate(true)}
                  >
                    Validado
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={validated === false}
                    onClick={() => handleValidate(false)}
                  >
                    No validado
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
          <h6>Fecha y hora</h6>
          <p className="mb-1">
            {dayjs(start).format('dddd, DD MMMM YYYY, hh:mm A')}
          </p>
          <Link to="#!">Añadir al calendario</Link>
          {/* <Countdown className="mt-3" date={start} /> */}
        </Card.Body>
      </Card>
      {!!participants?.length && (
        <Card className="mb-3 fs--1">
          <Card.Body>
            <h6>Participantes</h6>
            <UserList
              className="shadow-none"
              users={participants}
              tableProps={{
                pagination: true,
                perPage: 10
              }}
            />
          </Card.Body>
        </Card>
      )}
      <Objectives
        bodyClassName="pt-2"
        header={
          <Card.Header className="pb-0">
            <h6>Próximos objetivos</h6>
          </Card.Header>
        }
        exclude={[NO_ID_FIELD]}
      />
    </div>
  );
};

ObjectiveDetailAside.propTypes = {
  objective: PropTypes.object
};

export default ObjectiveDetailAside;
