import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import ProductGrid from './ProductGrid';
import Flex from 'components/common/Flex';
import Search from 'components/common/Search';
import { collection, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

const week = new Date();
week.setDate(week.getDate() - 7);

const Discounts = ({ grid, gridClass, header, footer, limit }) => {
  const db = useFirestore();
  const [search, setSearch] = useState('');

  const productsQuery = query(
    collection(db, 'programmes'),
    where('source', '==', 'discount')
  );
  const { data: products = [] } = useFirestoreCollectionData(productsQuery);
  const totalItems = products.length;
  let filteredProducts = products
    .map(product => {
      const isNew = product.start > week.toISOString();
      return { ...product, isNew };
    })
    .filter(({ description = '', title = '' }) => {
      const normalized =
        `${title
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')}${description
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')}` || '';
      const normalizedQuery =
        search
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '') || '';
      return normalized.includes(normalizedQuery);
    })
    .sort(({ start: start1 = 0 }, { start: start2 = 0 }) =>
      start1 > start2 ? -1 : 1
    );
  if (limit) {
    filteredProducts = filteredProducts.slice(0, limit);
  }
  return (
    <>
      {!header && (
        <Card className="mb-3">
          <Card.Header>
            <h5>Descuentos</h5>
            <Row className="flex-between-center">
              <Col
                sm="auto"
                as={Flex}
                alignItems="center"
                className="mb-2 mb-sm-0"
              >
                <h6 className="mb-0">
                  {search ? (
                    <>
                      {filteredProducts.length} productos{' '}
                      <span className="text-500">
                        ({totalItems} productos en total)
                      </span>
                    </>
                  ) : (
                    <>{totalItems} productos</>
                  )}
                </h6>
              </Col>
              <Col sm="auto">
                <Row className="gx-2 align-items-center">
                  <Col xs="auto">
                    <Form as={Row} className="gx-2">
                      <Col xs="auto">
                        <Search onChange={setSearch} />
                      </Col>
                    </Form>
                  </Col>
                  <Col xs="auto" className="pe-0"></Col>
                </Row>
              </Col>
            </Row>
          </Card.Header>
        </Card>
      )}
      <Card className="mb-3">
        {header}
        <Card.Body className="pb-0">
          <Row className={gridClass}>
            {filteredProducts.map((product, index) => (
              <ProductGrid
                product={product}
                key={`Product-${product.NO_ID_FIELD}`}
                sm={12}
                md={6}
                lg={4}
                {...grid}
                index={index}
              />
            ))}
          </Row>
        </Card.Body>
        {footer}
      </Card>
    </>
  );
};

Discounts.propTypes = {
  grid: PropTypes.object,
  gridClass: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
  limit: PropTypes.number
};

export default Discounts;
