import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Card } from 'react-bootstrap';
import Calendar from 'components/common/Calendar';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';

const NextHoliday = ({ className }) => {
  const { me } = useContext(UserContext);
  const [now] = useState(new Date());
  const { location } = me || {};
  const { holidays = [] } = location || {};
  const [nextHoliday] =
    holidays.filter(({ start }) => start > now.toISOString()) || [];
  const { start, title } = nextHoliday || {};
  const day = start ? dayjs(start).format('D') : '';
  const month = start ? dayjs(start).format('MMM') : '';

  return (
    <Card className={className}>
      <div className="overflow-hidden rounded-4">
        <Card.Header>
          <h6 className="m-0">Próximo día festivo</h6>
        </Card.Header>
        <Card.Body
          direction="column"
          alignItems="center"
          justifyContent="center"
          as={Flex}
          className="pt-0"
        >
          {nextHoliday ? (
            <>
              <Calendar day={day} month={month} />
              <div className="px-3 mt-3">
                <h6 className="fs-1 fw-normal">{title}</h6>
              </div>
            </>
          ) : (
            <div className="px-3 mt-3">
              <h6 className="fs-1 fw-normal">No hay festivo</h6>
            </div>
          )}
        </Card.Body>
      </div>
    </Card>
  );
};

NextHoliday.propTypes = {
  className: PropTypes.string
};

export default NextHoliday;
