import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { ReportsContext } from 'context/Context';
import RankingPodium from './RankingPodium';
import RankingUserList from './RankingUserList';
import { groupBy } from 'helpers/utils';

const RankingObjectives = () => {
  const { department: departmentProp } = useParams();
  const { partners = [], objectives: raw = [] } = useContext(ReportsContext);

  const objectives = raw.filter(({ state }) => state === 'success');
  const objectivesByUser = Object.entries(groupBy(objectives, 'userPath'))
    .map(([path, data]) => ({
      count: data?.length,
      user: partners?.find(({ ref }) => ref?.path === path)
    }))
    .filter(
      ({ user }) =>
        (!departmentProp && user) || user?.department === departmentProp
    )
    .sort((u1, u2) => (u1.count > u2.count ? -1 : 1));

  return (
    <>
      <Row className="g-3 mb-3">
        <RankingPodium data={objectivesByUser} unit="objetivo(s) cumplido(s)" />
        <RankingUserList
          data={objectivesByUser}
          unit="objetivo(s) cumplido(s)"
        />
      </Row>
    </>
  );
};

export default RankingObjectives;
