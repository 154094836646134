import React from 'react';
import RankingsProvider from './RankingsProvider';
import RankingAppreciations from './RankingAppreciations';
import RankingEvents from './RankingEvents';
import RankingObjectives from './RankingObjectives';

const Rankings = () => {
  return (
    <RankingsProvider>
      <RankingAppreciations />
      <RankingObjectives />
      <RankingEvents />
    </RankingsProvider>
  );
};

export default Rankings;
