import React, { useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext, {
  CalendarContext,
  ChatContext,
  UserContext
} from 'context/Context';
import Picker from '@emoji-mart/react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Form } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import { updateDoc } from '@firebase/firestore';
import { ref, uploadBytes } from '@firebase/storage';
import { useStorage } from 'reactfire';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import ReferrerBubble from './message/ReferrerBubble';

const optionArray = [
  { title: 'Bienvenida', type: 'welcome' },
  { title: 'Checklist', type: 'checklist' },
  { title: 'Encuesta', type: 'questionaire' },
  { title: 'Evento', type: 'event', role: 'admin' },
  { title: 'Felicitar', type: 'congrat' },
  { title: 'Imagen', type: 'image' },
  { title: 'Objetivo', type: 'objective', role: 'admin' }
];

const MessageTextArea = () => {
  const storage = useStorage();
  const {
    createMessage,
    currentChat,
    setScrollToBottom,
    referrer,
    setReferrer,
    setIsOpenNewChecklistModal,
    setIsOpenNewGreetingModal,
    setIsOpenNewQuestionaireModal
  } = useContext(ChatContext);
  const { setIsOpenNewEventModal, setIsOpenNewObjectiveModal } =
    useContext(CalendarContext);
  const { me } = useContext(UserContext);
  const [previewEmoji, setPreviewEmoji] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('text');
  const {
    config: { isDark }
  } = useContext(AppContext);
  const imageRef = useRef();

  const addEmoji = event => {
    let emoji = event.native;
    setMessage(message + emoji);
    setPreviewEmoji(false);
  };

  const handleSubmit = event => {
    event?.preventDefault();
    let newMessage = {
      text: `${message.replace(/(?:\r\n|\r|\n)/g, '<br>')}`,
      chatId: currentChat?.NO_ID_FIELD,
      chat: currentChat?.ref,
      status: 'delivered',
      type
    };
    createMessage(newMessage);
    setMessage('');
    setType('text');
    setScrollToBottom(true);
  };

  const handleKeyDown = event => {
    const { key, shiftKey } = event;
    if (!shiftKey && key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit();
    }
  };

  const handleAdd = option => {
    const { type } = option;
    switch (type) {
      case 'checklist':
        setIsOpenNewChecklistModal(true);
        break;
      case 'questionaire':
        setIsOpenNewQuestionaireModal(true);
        break;
      case 'event':
        setIsOpenNewEventModal(true);
        break;
      case 'image':
        imageRef.current.click();
        break;
      case 'objective':
        setIsOpenNewObjectiveModal(true);
        break;
      case 'congrat':
      case 'welcome':
        setIsOpenNewGreetingModal(type);
        break;
      default:
    }
  };

  const handleCreateImageMessage = async event => {
    const { target } = event;
    const { files = [] } = target;
    const image = files[0];
    if (!image) {
      return;
    }
    try {
      let newMessage = {
        text: '',
        chatId: currentChat?.NO_ID_FIELD,
        chat: currentChat?.ref,
        status: 'delivered',
        type: 'image'
      };
      const doc = await createMessage(newMessage);
      const imageRef = ref(
        storage,
        `chat/${currentChat?.NO_ID_FIELD}/${doc.id}.jpeg`
      );
      await uploadBytes(imageRef, image);
      await updateDoc(doc, { uploaded: true });
      imageRef.current.value = null;
    } catch (error) {
      console.error(error);
    }
    setMessage('');
    setType('text');
    setTimeout(() => {
      setScrollToBottom(true);
    }, 100);
  };

  return (
    <>
      <div className="p-2 bg-250">
        <input
          type="file"
          className="d-none"
          ref={imageRef}
          onChange={handleCreateImageMessage}
          multiple={false}
          accept="image/*"
        />
        <Form className="chat-editor-area" onSubmit={handleSubmit}>
          {referrer && (
            <Flex alignItems="center" className="p-1">
              <div className="flex-grow-1">
                <ReferrerBubble referrer={referrer} />
              </div>
              <FalconCloseButton
                className="ms-3 me-2"
                onClick={() => setReferrer()}
              />
            </Flex>
          )}
          <Flex alignItems="center">
            <Button
              variant="link"
              className="emoji-icon"
              onClick={event => {
                event.stopPropagation();
                setPreviewEmoji(preview => !preview);
              }}
            >
              <FontAwesomeIcon icon={['far', 'laugh-beam']} size="lg" />
            </Button>

            {previewEmoji && (
              <div className="chat-emoji-picker" dir="ltr">
                <Picker
                  locale={'es'}
                  onEmojiSelect={addEmoji}
                  onClickOutside={() => setPreviewEmoji(false)}
                  theme={isDark ? 'dark' : 'light'}
                  previewPosition="none"
                  skinTonePosition="none"
                />
              </div>
            )}

            <Dropdown className="ms-1">
              <Dropdown.Toggle
                variant="send"
                size="sm"
                className="dropdown-caret-none shadow-none"
              >
                <FontAwesomeIcon icon="plus" size="lg" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="text-nowrap me-n3 py-2">
                {optionArray
                  .filter(({ role }) => me?.type === role || !role)
                  .map((option, index) => {
                    const { title } = option;
                    return (
                      <Dropdown.Item
                        key={`Add-Message-Option-${index}`}
                        className="fs-0"
                        onClick={() => handleAdd(option)}
                      >
                        {title}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>

            <TextareaAutosize
              minRows={1}
              maxRows={6}
              value={message}
              placeholder="Escribe aquí tu mensaje"
              onKeyDown={handleKeyDown}
              onChange={({ target }) => setMessage(target.value)}
              className="form-control outline-none resize-none rounded-3 border-0 emojiarea-editor shadow-none"
            />

            <Button
              variant="send"
              size="sm"
              className={classNames('ms-3 shadow-none', {
                'text-primary': message.length > 0
              })}
              type="submit"
            >
              <FontAwesomeIcon icon="paper-plane" size="lg" />
            </Button>
          </Flex>
        </Form>
      </div>
    </>
  );
};

MessageTextArea.propTypes = {
  chat: PropTypes.object
};

export default MessageTextArea;
