import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { GaugeChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Tooltip from 'components/common/Tooltip';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  GaugeChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = ({ color = 'primary', data }) => ({
  series: [
    {
      animation: color !== 'gray-500',
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      radius: '90%',
      pointer: {
        show: false
      },
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
        itemStyle: {
          color: getColor(color)
        }
      },
      axisLine: {
        lineStyle: {
          width: 8,
          color: [[1, getColor('gray-200')]]
        }
      },
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      },
      data: [
        {
          value: data,
          detail: {
            offsetCenter: ['7%', '4%']
          }
        }
      ],
      detail: {
        width: 50,
        height: 14,
        fontFamily: 'Poppins',
        fontSize: 28,
        fontWeight: 500,
        color: getColor('gray-500'),
        formatter: isNaN(data) ? `${data}%` : '{value}%'
      }
    }
  ]
});

const Compatibility = ({ className, user }) => {
  const { profile = {} } = user || {};
  const { compatibility: data = '-' } = profile;

  let color =
    (isNaN(data) && 'none') ||
    (data >= 70 && 'success') ||
    (data >= 50 && 'warning') ||
    'danger';

  return (
    <Card className={classNames('h-100', className)}>
      <FalconCardHeader
        title="Compatibilidad"
        light
        endEl={
          <Tooltip title="Compatibilidad con el puesto de trabajo en la empresa">
            <div>
              <FontAwesomeIcon icon="question-circle" className="mx-2" />
            </div>
          </Tooltip>
        }
      />
      <Card.Body>
        <div>
          <ReactEChartsCore
            echarts={echarts}
            className="m-auto"
            option={getOptions({ color, data })}
            style={{ height: '8rem', width: '8rem' }}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

Compatibility.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object
};

export default Compatibility;
