import React, { useContext, useEffect } from 'react';
import LogoutContent from 'components/authentication/LogoutContent';
import { UserContext } from 'context/Context';

const Logout = () => {
  const { signOut } = useContext(UserContext);

  useEffect(() => {
    signOut();
  }, []);

  return (
    <div className="text-center">
      <LogoutContent />
    </div>
  );
};

export default Logout;
