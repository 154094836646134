import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReportsContext } from 'context/Context';

dayjs.locale('es');

const Activity = ({
  activity: {
    title,
    text,
    icon = 'check',
    createdAt,
    color: status = 'primary'
  },
  isLast
}) => {
  const time = dayjs(createdAt).format('DD MMMM YYYY');
  return (
    <Row
      className={classNames(
        'g-3 recent-activity-timeline recent-activity-timeline-past',
        {
          [`recent-activity-timeline-${status}`]: status !== 'accent',
          'pb-x1': !isLast
        }
      )}
    >
      <Col xs="auto" className="ps-4 ms-2">
        <div className="ps-2">
          <div className="icon-item icon-item-sm rounded-circle bg-200 shadow-none">
            <FontAwesomeIcon icon={icon} className={`text-${status}`} />
          </div>
        </div>
      </Col>
      <Col>
        <Row className={classNames('g-3', { 'border-bottom pb-x1': !isLast })}>
          <Col>
            <h6 className="text-800 mb-1">{title}</h6>
            <p className="fs--1 text-600 mb-0">{text}</p>
          </Col>
          <Col xs="auto">
            <p className="fs--2 text-500 mb-0">{time}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const RecentActivity = ({ className }) => {
  const { department: departmentProp } = useParams();
  const { logs: rawLogs = [], partners = [] } = useContext(ReportsContext);
  const logs = rawLogs
    .map(data => {
      const { userId } = data;
      const { department } =
        partners.find(({ id } = {}) => id === userId) || {};
      if (!department) {
        return data;
      }
      return { ...data, department };
    })
    .filter(
      ({ department }) => !departmentProp || department === departmentProp
    );
  return (
    <Card
      className={classNames('shadow-none', className, {
        'border border-3': !logs.length
      })}
    >
      <Card.Body className={classNames({ 'ps-0': !!logs.length })}>
        {!logs.length && 'No hay datos'}
        {logs.map((activity, index) => (
          <Activity
            key={`Activity-${activity.NO_ID_FIELD}`}
            activity={activity}
            isLast={index === logs.length - 1}
          />
        ))}
      </Card.Body>
    </Card>
  );
};

Activity.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    createdAt: PropTypes.string.isRequired,
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
  }),
  isLast: PropTypes.bool
};

RecentActivity.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string
};

export default RecentActivity;
