import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import { ReportsContext } from 'context/Context';
import classNames from 'classnames';
import { groupBy } from 'helpers/utils';

const MostHappyUsers = ({ end = 10, type = 'happiness', ...rest }) => {
  const { partners = [], indexes = [] } = useContext(ReportsContext);

  const dataByUser = Object.entries(groupBy(indexes, 'userId'))
    .map(([userId, indexes]) => {
      const user = partners.find(({ id } = {}) => id === userId);
      if (!user) {
        return;
      }
      const filteredIndexes = indexes.filter(index => index.type === type);
      const happiness = Math.round(
        filteredIndexes.reduce((total, { value }) => total + value, 0) /
          filteredIndexes.length
      );
      return { ...user, happiness };
    })
    .filter(data => data);

  return (
    <Card {...rest} className="border border-3 shadow-none">
      <Card.Body>
        {!dataByUser.length && 'No hay datos de empleados'}
        {dataByUser
          .sort((p1, p2) =>
            (p1.happiness || 0) > (p2.happiness || 0) ? -1 : 1
          )
          .slice(0, end)
          .map(({ id, ref, ...rest }, index) => (
            <HappyUser
              {...rest}
              _ref={ref}
              id={id}
              key={`HappyUser-${id}`}
              isLast={index === partners.length - 1}
            />
          ))}
      </Card.Body>
    </Card>
  );
};

const HappyUser = ({ id, avatar, name, department, happiness, isLast }) => {
  return (
    <Flex className={classNames('align-items-center', { 'mb-3': !isLast })}>
      <Avatar src={avatar} name={name} size="2xl" />
      <div className="ms-3 flex-grow-1 w-0">
        <h6 className="mb-0 fw-semi-bold">
          <Link className="text-900" to={`/user/profile/${id}`}>
            {name}
          </Link>
        </h6>
        <p className="text-500 fs--2 mb-0">{department}</p>
      </div>
      <div>
        <h5 className="mb-0">{happiness}</h5>
      </div>
    </Flex>
  );
};

HappyUser.propTypes = {
  id: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  department: PropTypes.string,
  happiness: PropTypes.number,
  isLast: PropTypes.bool
};

MostHappyUsers.propTypes = {
  data: PropTypes.array,
  end: PropTypes.number,
  type: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape(HappyUser.propTypes))
};

export default MostHappyUsers;
