import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KanbanColumnHeader from './KanbanColumnHeader';
import CandidateCard from './CandidateCard';
import AddCandidateForm from './AddCandidateForm';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import StrictModeDroppable from './StrictModeDroppable';

const CandidatesColumn = ({ kanbanColumnItem }) => {
  const { id, name, items } = kanbanColumnItem;
  const [showForm, setShowForm] = useState(false);
  const formViewRef = useRef(null);

  useEffect(() => {
    formViewRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [showForm]);

  return (
    <div className={classNames('kanban-column', { 'form-added': showForm })}>
      <KanbanColumnHeader
        column={kanbanColumnItem}
        id={id}
        title={
          <>
            <SoftBadge bg="success" className="me-1">
              <FontAwesomeIcon icon="magic" className="me-1" />
              IA
            </SoftBadge>
            {name}
          </>
        }
        itemCount={items.length}
        help="Genera fichas de candidatos a partir del currículum y descrubre la coompatibilidad con la oferta que has creado."
      />
      <StrictModeDroppable droppableId={`${id}`} type="KANBAN">
        {provided => (
          <>
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              id="Column-candidates"
              className="kanban-items-container scrollbar"
            >
              {items.map((task, index) => (
                <CandidateCard key={task.id} index={index} task={task} />
              ))}
              <AddCandidateForm showForm={showForm} setShowForm={setShowForm} />
              {provided.placeholder}
              <div ref={formViewRef}></div>
            </div>
            {!showForm && (
              <div className="kanban-column-footer">
                <IconButton
                  size="sm"
                  variant="link"
                  className="d-block w-100 btn-add-card text-decoration-none text-600"
                  icon="plus"
                  iconClassName="me-2"
                  onClick={() => setShowForm(true)}
                >
                  Añadir candidato
                </IconButton>
              </div>
            )}
          </>
        )}
      </StrictModeDroppable>
    </div>
  );
};

CandidatesColumn.propTypes = {
  kanbanColumnItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string,
    items: PropTypes.arrayOf(CandidateCard.propTypes.task),
    unit: PropTypes.string
  })
};

export default CandidatesColumn;
