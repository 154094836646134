import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { ChatContext } from 'context/Context';

const ChatContentHeader = ({ chat }) => {
  const { setIsOpenChatList, setIsOpenChatInfo } = useContext(ChatContext);
  const {
    avatar,
    name,
    participants = [],
    status = 'offline',
    type
  } = chat || {};
  const users =
    type !== 'user' &&
    new Intl.ListFormat('es').format(
      participants?.map(({ firstname, name }) => firstname || name || '')
    );

  return (
    <div className="chat-content-header bg-250">
      <Row
        as={Flex}
        alignItems="center"
        className="flex-between-center m-0 w-100"
      >
        <Col xs={12} as={Flex} alignItems="center" className="p-0">
          <div
            className="pe-3 text-700 d-md-none contacts-list-show cursor-pointer"
            onClick={() => setIsOpenChatList?.(true)}
          >
            <FontAwesomeIcon icon="chevron-left" />
          </div>
          <div
            className="min-w-0 w-100 cursor-pointer"
            onClick={() => setIsOpenChatInfo(isOpenChatInfo => !isOpenChatInfo)}
          >
            <Flex alignItems="center">
              <Avatar className="me-2" src={avatar} name={name} size="xl" />
              <div className="w-0 flex-grow-1">
                <h5 className="mb-0 text-truncate fs-0">{name}</h5>
                <div
                  className={classNames('fs--2 text-600 text-truncate', {
                    'fw-bold': status === 'online'
                  })}
                >
                  {classNames({
                    Offline: status === 'offline',
                    Online: status === 'online',
                    [users]: type !== 'user'
                  })}
                </div>
              </div>
            </Flex>
          </div>
        </Col>
      </Row>
    </div>
  );
};

ChatContentHeader.propTypes = {
  chat: PropTypes.object
};

export default ChatContentHeader;
