import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'tinymce/skins/ui/oxide/skin.css';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { EmployeesContext, UserContext } from 'context/Context';
import EmployeesProvider from 'components/company/employees/EmployeesProvider';
import CsvDropZone from './CsvDropZone';
import ActionButton from 'components/common/ActionButton';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Loader from 'components/common/Loader';
import PersonalForm from './PersonalForm';

const EmployeesCreationContent = ({ bodyClassName }) => {
  const [isCreating, setCreating] = useState(false);
  const { company, setUpgradeModal, subscription } = useContext(UserContext);
  const {
    employeesState: { allEmployees },
    handleCreation
  } = useContext(EmployeesContext);
  const navigate = useNavigate();
  const { handleSubmit, ...form } = useForm();
  const watching = form.watch();
  const { users = [] } = watching;

  const onSubmitData = async data => {
    setCreating(true);
    let { users: employees = [] } = data;
    try {
      employees = employees.map(employee => {
        const { birthday, startAt } = employee;
        if (birthday) {
          const date = new Date(birthday);
          const month = date.getMonth() + 1;
          const day = date.getDate();
          employee.birthday = `${date.getFullYear()}-${
            month < 10 ? `0${month}` : month
          }-${day < 10 ? `0${day}` : day}`;
          const [, birthdayMonth = ''] =
            employee.birthday.match(/\d{4}-(\d{2}-\d{2})/) || [];
          employee.birthdayMonth = birthdayMonth;
        }
        if (startAt) {
          const date = new Date(startAt);
          const month = date.getMonth() + 1;
          const day = date.getDate();
          employee.startAt = `${date.getFullYear()}-${
            month < 10 ? `0${month}` : month
          }-${day < 10 ? `0${day}` : day}`;
          const [, workAnniversaryMonth = ''] =
            employee.startAt.match(/\d{4}-(\d{2}-\d{2})/) || [];
          employee.workAnniversaryMonth = workAnniversaryMonth;
        }
        return {
          ...employee,
          name: `${employee.firstname} ${employee.lastname}`,
          company: company?.NO_ID_FIELD,
          company_name: company?.name,
          type: 'employee'
        };
      });
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_URL}/createEmployees`,
        {
          method: 'post',
          body: JSON.stringify({ employees })
        }
      );
      if (response.status !== 200) {
        throw Error();
      }
      const { users: usersCreated } = await response.json();
      const emailsInUse = usersCreated.filter(({ error }) =>
        ['auth/email-already-in-use', 'auth/email-already-exists'].includes(
          error?.code
        )
      );
      if (emailsInUse?.length) {
        toast.error(
          `Estos emails ya están dados de alta en otra empresa: ${emailsInUse
            .map(({ email }) => email)
            .join(', ')}.
            Antes de poder añadirlos se deben eliminar en la empresa actual.`
        );
      }
      employees = employees.map(user => {
        const _user = usersCreated.find(({ email }) => email === user.email);
        return { ...user, ..._user };
      });
      await Promise.all(employees.map(user => handleCreation(user)));

      if (usersCreated?.length - emailsInUse?.length > 0) {
        toast.success('Empleados creados');
      }
      setTimeout(() => {
        navigate('/company/employees');
      }, 1000);
    } catch (error) {
      console.error(error);
      toast.error('Ha habido un error al crear los usuarios.');
    }
    setCreating(false);
  };

  const createUser = () => {
    form.setValue('users', [...users, {}]);
  };

  useEffect(() => {
    document
      .querySelector('.card-footer')
      ?.scrollIntoView({ block: 'end', behavior: 'smooth' });

    if (
      typeof subscription !== 'undefined' &&
      (allEmployees?.length || 0) + (users?.length || 0) >
        (subscription?.quantity || 0)
    ) {
      setUpgradeModal({
        open: true,
        message:
          'Para poder añadir más empleados, primero debes ampliar el límite de empleados de tu suscripción'
      });
    }
  }, [users]);

  return (
    <Form onSubmit={handleSubmit(onSubmitData)} className={bodyClassName}>
      <Row className="g-3 mb-3">
        <Col>
          <Card className="mb-3 sticky-sidebar">
            <FalconCardHeader
              title="Crear empleado"
              titleTag="h5"
              endEl={
                <div className="ms-1">
                  <ActionButton
                    tooltip=""
                    icon="plus"
                    handleClick={createUser}
                  />
                </div>
              }
            />
            {!users.length && (
              <Card.Body className="pt-0 text-500">
                Puedes crear empleados añadiendo desde el icono + o desde un
                archivo .csv
              </Card.Body>
            )}
          </Card>
          {users.map((user = {}, index) => (
            <PersonalForm
              key={`User-${index}`}
              {...form}
              user={user}
              index={index}
              isLast={index === users.length}
            />
          ))}
          <Card>
            <Card.Footer>
              <Row className="flex-between-center">
                <Col md>
                  <h5 className="mb-2 mb-md-0">
                    {!users.length
                      ? 'Primero añade algún empleado'
                      : `Vas a crear ${users.length} empleados`}
                  </h5>
                </Col>
                <Col xs="auto">
                  <Button
                    type="submit"
                    disabled={!users.length || isCreating}
                    variant="primary"
                    size="lg"
                  >
                    {isCreating ? (
                      <Loader style={{ width: '1rem', height: '1rem' }} />
                    ) : (
                      'Crear'
                    )}
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
        <Col xxl={3}>
          <CsvDropZone setData={form.reset} />
        </Col>
      </Row>
    </Form>
  );
};

const EmployeeCreation = () => {
  return (
    <EmployeesProvider>
      <EmployeesCreationContent />
    </EmployeesProvider>
  );
};

ActionButton.propTypes = {
  tooltip: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  variant: PropTypes.string
};

EmployeesCreationContent.propTypes = {
  bodyClassName: PropTypes.string
};

export default EmployeeCreation;
