import React, { useContext } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';

const BillingSettings = () => {
  const { subscription } = useContext(UserContext);
  const { plan = {}, quantity: quantityProp = 1 } = subscription || {};
  const { product = {} } = plan;
  const { images = [], name } = product;

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Subscripción" />
      <Card.Body className="bg-light">
        <Flex alignItems="center" justifyContent="between">
          <Flex alignItems="center">
            <Avatar
              size="xl"
              rounded="soft"
              src={images?.[0]}
              name="FREE"
              isExact
              maxChars={4}
            />
            <h5 className="ms-2 me-4">{name || 'Gratuito'}</h5>
          </Flex>
          <Flex alignItems="center">
            <h5>x{quantityProp}</h5>
            <FontAwesomeIcon icon="user" className="ms-1 mb-1" />
          </Flex>
        </Flex>
      </Card.Body>
      <FalconCardFooterLink
        title="Ver suscripción"
        to="/subscription"
        size="sm"
      />
    </Card>
  );
};

export default BillingSettings;
