import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  OverlayTrigger,
  Popover,
  Row,
  Spinner
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import { OnBoardingContext, UserContext } from 'context/Context';
import GoogleMap from 'components/map/GoogleMap';

const CompanyProfile = () => {
  const {
    me,
    company,
    departments = [],
    createDepartment,
    deleteDepartment,
    locations = [],
    createLocation,
    updateLocation,
    deleteLocation,
    updateCompany,
    updateUser
  } = useContext(UserContext);
  const { setStep } = useContext(OnBoardingContext);
  const [newDepartmentName, setNewDepartmentName] = useState('');
  const [newLocation, setNewLocation] = useState({});
  const [formData, setFormData] = useState({
    parentCompanyId: 'FiUzQw1Qn7bEO6ERtkae'
  });
  const data = { ...formData };
  const [isLoading, setLoading] = useState(false);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleChangeNewDepartment = ({ target }) => {
    const { value } = target;
    setNewDepartmentName(value);
  };

  const handleCreateNewDepartment = () => {
    createDepartment(newDepartmentName);
    setNewDepartmentName('');
  };
  const handleDeleteDepartment = departmentId => {
    deleteDepartment(departmentId);
  };

  const handleSearchLocation = (place, location) => {
    let {
      formatted_address: address,
      geometry,
      name,
      place_id: placeId
    } = place;
    const { location: placeLocation } = geometry;
    const latlng = placeLocation?.toJSON();
    if (!location?.NO_ID_FIELD) {
      setNewLocation({ address, placeId, name, location: latlng });
      return;
    }
    updateLocation(location, { address, placeId, name, location: latlng });
  };

  const handleCreateNewLocation = () => {
    createLocation(newLocation);
    setNewLocation({});
  };
  const handleDeleteLocation = locationId => {
    deleteLocation(locationId);
  };

  const handleSubmit = async event => {
    try {
      setLoading(true);
      event.preventDefault();
      const createdAt = new Date().toISOString();
      const companyRef = await updateCompany(company, {
        ...formData,
        createdAt
      });
      const params = {
        company_name: formData?.name
      };
      if (companyRef) {
        params.company = companyRef.id;
      }
      await updateUser(me, params);
      setLoading(false);

      document
        .querySelector('#DepartmentsWelcome')
        ?.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const { avatar, ref, ...rest } = company;
    setFormData(formData => ({ ...formData, ...rest }));
  }, [company]);

  return (
    <Row as={Flex} justifyContent="center" className="g-3">
      <Col xs={12} xl={5}>
        <Card>
          <FalconCardHeader title="Información de la empresa" />
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3 g-3">
                <Form.Group as={Col} controlId="firstName">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Happyfy"
                    value={data?.name || ''}
                    name="name"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={6} controlId="email">
                  <Form.Label>Email de administración</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="email@happyfy.com"
                    value={data?.email || ''}
                    name="email"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} lg={6} controlId="phone">
                  <Form.Label>Teléfono</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="000000000"
                    value={data?.phone || ''}
                    name="phone"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={6} controlId="billingName">
                  <Form.Label>Nombre fiscal</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Happyfy S.L."
                    value={data?.billingName || ''}
                    name="billingName"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} lg={6} controlId="billingCif">
                  <Form.Label>CIF</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="00000000A"
                    value={data?.billingCif || ''}
                    name="billingCif"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Row>

              <Form.Group className="mb-3" controlId="billingAddress">
                <Form.Label>Dirección fiscal</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="C/ Nombre de la calle, 0"
                  value={data?.billingAddress || ''}
                  name="billingAddress"
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="intro">
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={13}
                  placeholder="Escribe aquí una breve descripción de la empresa"
                  value={data?.description || ''}
                  name="description"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <div className="text-end">
                {company?.NO_ID_FIELD ? (
                  <Button variant="outline-primary" type="submit">
                    {isLoading && (
                      <Spinner
                        size="sm"
                        className="me-2"
                        style={{ width: '1rem', height: '1rem' }}
                      />
                    )}
                    Modificar
                  </Button>
                ) : (
                  <Button variant="primary" type="submit">
                    {isLoading && (
                      <Spinner
                        size="sm"
                        className="me-2"
                        style={{ width: '1rem', height: '1rem' }}
                      />
                    )}
                    Crear empresa
                  </Button>
                )}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={12} xl={3}>
        <Card
          id="DepartmentsWelcome"
          className={classNames({
            'opacity-50': !company?.NO_ID_FIELD
          })}
        >
          <FalconCardHeader title="Departamentos" />
          <Card.Body>
            <Form.Group className="mb-3" controlId="heading">
              <ListGroup>
                {departments.map(({ NO_ID_FIELD, name }) => (
                  <ListGroup.Item key={`Department-Item-${NO_ID_FIELD}`}>
                    <Flex justifyContent="between" alignItems="center">
                      {name}
                      <Button
                        variant="outline-danger"
                        className="border-0"
                        size="sm"
                        onClick={() => handleDeleteDepartment(NO_ID_FIELD)}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </Button>
                    </Flex>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item>
                  <Flex justifyContent="between" alignItems="center">
                    <Form.Control
                      type="text"
                      placeholder="Nuevo departamento"
                      value={newDepartmentName}
                      onChange={handleChangeNewDepartment}
                    />
                    <Button
                      className="ms-3"
                      size="sm"
                      onClick={handleCreateNewDepartment}
                      disabled={!company?.NO_ID_FIELD}
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  </Flex>
                </ListGroup.Item>
              </ListGroup>
            </Form.Group>
          </Card.Body>
        </Card>
        <Card
          className={classNames('mt-3', {
            'opacity-50': !company?.NO_ID_FIELD
          })}
        >
          <FalconCardHeader title="Localizaciones" />
          <Card.Body>
            <Form.Group className="mb-3" controlId="heading">
              <ListGroup>
                {locations.map(location => {
                  const { NO_ID_FIELD, name, address, latlng } = location;
                  return (
                    <ListGroup.Item key={`Location-Item-${NO_ID_FIELD}`}>
                      <Flex justifyContent="between" alignItems="center">
                        {name}
                        <Flex>
                          <OverlayTrigger
                            trigger="click"
                            overlay={
                              <Popover className="max-w-100 max-h-100">
                                <Popover.Body>
                                  {latlng ? (
                                    <GoogleMap
                                      key={`Map-Location-${NO_ID_FIELD}`}
                                      initialCenter={
                                        latlng || {
                                          lat: 38.83631704135949,
                                          lng: -0.6023632417617178
                                        }
                                      }
                                      zoom={15}
                                      className="d-flex flex-column rounded-soft position-relative"
                                      searchClassName="mb-3"
                                      mapClassName="flex-grow-1"
                                      onSearch={place =>
                                        handleSearchLocation(place, location)
                                      }
                                      style={{
                                        minWidth: '30.75rem',
                                        minHeight: '18.75rem'
                                      }}
                                    >
                                      <h5 className="text-700">{name}</h5>
                                      <p className="fs--1 mb-0">{address}</p>
                                    </GoogleMap>
                                  ) : (
                                    <>
                                      <h5 className="text-700">{name}</h5>
                                      <p className="fs--1 mb-0">{address}</p>
                                    </>
                                  )}
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <Button
                              variant="outline-secondary"
                              className="border-0"
                              size="sm"
                            >
                              <FontAwesomeIcon icon="map-marker-alt" />
                            </Button>
                          </OverlayTrigger>
                          <Button
                            variant="outline-danger"
                            className="border-0"
                            size="sm"
                            onClick={() => handleDeleteLocation(NO_ID_FIELD)}
                          >
                            <FontAwesomeIcon icon="trash" />
                          </Button>
                        </Flex>
                      </Flex>
                    </ListGroup.Item>
                  );
                })}
                <ListGroup.Item>
                  <Flex justifyContent="between" alignItems="center">
                    <GoogleMap
                      initialCenter={{
                        lat: 38.83631704135949,
                        lng: -0.6023632417617178
                      }}
                      className="w-100 d-flex flex-column rounded-soft position-relative"
                      mapClassName="d-none"
                      onSearch={handleSearchLocation}
                    />
                    <Button
                      className="ms-3"
                      size="sm"
                      onClick={handleCreateNewLocation}
                      disabled={!company?.NO_ID_FIELD}
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  </Flex>
                </ListGroup.Item>
              </ListGroup>
            </Form.Group>
          </Card.Body>
        </Card>
        <Card className="mt-3">
          <Card.Body className="text-end">
            <Button
              variant="primary"
              disabled={!company?.NO_ID_FIELD}
              onClick={() => setStep(step => step + 1)}
            >
              Siguiente
            </Button>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default CompanyProfile;
