import React, { useContext, useRef } from 'react';
import { Card } from 'react-bootstrap';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { AppreciationContext } from 'context/Context';
import { getColor, groupBy } from 'helpers/utils';
import CreateAppreciation from './CreateAppreciation';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = data => ({
  color: data.map(({ color }) => color),
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: function (params) {
      return `<span class="text-400">${params.data.name}</span><h5 class="text-center">${params.data.value}</h5>`;
    }
  },
  legend: {
    show: false
  },
  series: [
    {
      name: 'Total',
      type: 'pie',
      radius: ['100%', '67%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: false
      },
      emphasis: {
        // scale: false
      },
      labelLine: {
        show: false
      },
      data
    }
  ]
});

const AppreciationsChart = () => {
  const { myAppreciations, remaining, spent } = useContext(AppreciationContext);
  const chartRef = useRef(null);
  const appreciationsByIcon = Object.entries(
    groupBy(myAppreciations, 'icon')
  ).map(([name, total]) => ({
    color: getColor('primary'),
    name,
    value: total.reduce((total, { value }) => total + value, 0)
  }));
  const data = [
    ...appreciationsByIcon,
    { color: getColor('gray-300'), name: 'Disponibles', value: remaining }
  ];

  return (
    <div className="position-relative py-2">
      <ReactEChartsCore
        ref={chartRef}
        echarts={echarts}
        option={getOptions(data)}
        style={{ height: '12.5rem' }}
      />
      <div className="position-absolute top-50 start-50 translate-middle text-center">
        <p className="fs--1 mb-0 text-400 font-sans-serif fw-medium">
          Enviados
        </p>
        <p className="fs-3 mb-0 font-sans-serif fw-medium mt-n2">{spent}</p>
      </div>
    </div>
  );
};

const AppreciationsUse = () => {
  return (
    <Card>
      <FalconCardHeader
        title="Uso de reconocimientos"
        titleTag="h5"
        className="bg-light"
      />
      <Card.Body className="border-bottom border-200">
        <AppreciationsChart />
      </Card.Body>
      <CreateAppreciation />
    </Card>
  );
};

export default AppreciationsUse;
