import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from 'context/Context';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';

const Item = ({ color = 'primary', icon, profileKey: key, text, user }) => {
  const { me, updateUser } = useContext(UserContext);

  const handleRemove = event => {
    event.preventDefault();
    if (me?.type !== 'admin' && me?.NO_ID_FIELD !== user?.NO_ID_FIELD) {
      return;
    }
    const { profile = {} } = user || {};
    profile[key] = profile[key].filter(item => item !== text);
    updateUser(user, { profile });
  };

  return (
    <SoftBadge
      bg={color}
      className="cursor-default me-1 mb-1 text-wrap hover-actions-trigger overflow-hidden text-start lh-base"
    >
      <Flex alignItems="center">
        {icon && <FontAwesomeIcon icon={icon} className="me-2" />}
        <div className="flex-1 lh-base">{text?.replace(/^-/, '').trim()}</div>
        <div className={`hover-actions shadow end-0 h-100 badge-soft-${color}`}>
          {(me?.type === 'admin' || me?.NO_ID_FIELD === user?.NO_ID_FIELD) && (
            <Flex
              alignItems="center"
              className="cursor-pointer px-2 py-1 h-100 hover-opacity-50"
              onClick={handleRemove}
            >
              <FontAwesomeIcon icon="trash" />
            </Flex>
          )}
        </div>
      </Flex>
    </SoftBadge>
  );
};

Item.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  profileKey: PropTypes.string,
  text: PropTypes.node,
  user: PropTypes.object
};

export default Item;
