import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import AppContext, { UserContext } from 'context/Context';
import UserList from 'components/common/UserList';

const greetingModalTitle = {
  congrat: 'felicitación',
  welcome: 'bienvenida'
};

const greetingModalTexts = {
  congrat: 'Muchas gracias por dar siempre lo mejor de ti.',
  welcome: 'Nos alegramos mucho de tenerte en el equipo.'
};

const NewGreetingModal = ({
  isOpen,
  setIsOpen,
  create,
  participants,
  type
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { me, partners = [] } = useContext(UserContext);
  const [formData, setFormData] = useState({
    text: greetingModalTexts[type] || '',
    type
  });
  const { extra, text } = formData;
  const { target = [] } = extra || {};
  const users = participants
    ?.filter(({ ref }) => ref?.path !== me?.ref?.path)
    .map(({ ref }) => {
      const user =
        partners.find(partner => partner?.ref?.path === ref?.path) || {};
      return user;
    });
  const usersName = target
    .map(({ ref }) => {
      const { name } = users.find(u => u?.ref?.path === ref?.path) || {};
      return name;
    })
    .filter(name => name)
    .join(', ');

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChange = useCallback(({ target }) => {
    let { name, value } = target;
    setFormData(formData => ({ ...formData, [name]: value }));
  }, []);

  const handleChangeSelection = useCallback(users => {
    handleChange({
      target: {
        name: 'extra',
        value: { target: users.map(({ ref }) => ({ ref })) }
      }
    });
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    formData.text = `${formData?.text} ${usersName}`;
    create(formData);
    setIsOpen(false);
  };

  useEffect(() => {
    setFormData(data => ({
      ...data,
      text: greetingModalTexts[type] || '',
      type
    }));
  }, [type]);

  return (
    <Modal show={isOpen} onHide={handleClose} contentClassName="border">
      <Form onSubmit={handleSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-x1 border-bottom-0"
        >
          <Modal.Title as="h5">Nueva {greetingModalTitle[type]}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          <Form.Group className="mb-3" controlId="titleInput">
            <Form.Label className="fs-0">¿Qué quieres decir?</Form.Label>
            <Form.Control
              type="text"
              name="text"
              value={`${text} ${usersName}`}
              required
              onChange={handleChange}
              as="textarea"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="fs-0">Elije a las personas</Form.Label>
            <UserList
              className="shadow-none"
              onChangeSelection={handleChangeSelection}
              users={users}
              tableProps={{
                search: true,
                sortable: true,
                selection: true,
                selectionColumnWidth: 28,
                pagination: true,
                perPage: 10
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-x1 border-top-0">
          <Button variant="primary" type="submit" className="px-4 mx-0">
            Crear {greetingModalTitle[type]}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

NewGreetingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  participants: PropTypes.array,
  type: PropTypes.string
};

export default NewGreetingModal;
