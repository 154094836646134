import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';

const GreetingMessage = ({ bg, message, title }) => {
  const { partners = [] } = useContext(UserContext);
  let { extra = {}, text = '' } = message || {};
  let { target = [{}] } = extra || {};
  return (
    <Flex
      direction="column"
      alignItems="center"
      className="bgrepe"
      style={{
        minHeight: '360px',
        backgroundImage: `url('${bg}')`,
        backgroundSize: 'auto 100%',
        backgroundPosition: 'center top 50px',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <h5 className="text-center">{title}</h5>
      <div className="m-2 text-center">{text}</div>
      <Flex
        className="w-100 mx-5 z-index-1"
        alignItems="center"
        justifyContent="center"
      >
        <AvatarGroup className="justify-content-end" max={10}>
          {target.map(({ ref }) => {
            const user =
              partners.find(partner => partner?.ref?.path === ref?.path) || {};
            const { id, avatar, name } = user;
            return (
              <Avatar
                key={`Greeting-Avatar-${id}`}
                src={avatar && avatar}
                name={name && name}
                size="3xl"
              />
            );
          })}
        </AvatarGroup>
      </Flex>
    </Flex>
  );
};

GreetingMessage.propTypes = {
  bg: PropTypes.string,
  message: PropTypes.object,
  title: PropTypes.string
};

export default GreetingMessage;
