import React, { useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import { Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Calendar from 'components/common/Calendar';
import Flex from 'components/common/Flex';
import {
  DayTimeControl,
  WorkingDay,
  WorkingDayDuration,
  WorkingWeek
} from './Components';
import {
  CalendarContext,
  TimeControlContext,
  UserContext
} from 'context/Context';
import {
  getDaySeconds,
  getDayStartTime,
  getDayEndTime,
  getUserSchedule,
  groupBy
} from 'helpers/utils';
import dayjs from 'dayjs';
import timeOffSubtypes from 'components/user/time-off/subtypes.json';

const columns = [
  {
    accessor: 'day',
    Header: 'Día',
    headerProps: {
      className: 'py-3 w-0 text-center'
    },
    cellProps: (cell, rowData) => {
      const { day } = rowData.cell.row.original;
      return {
        className: classNames('w-0', {
          'bg-light border-bottom-0': !day
        })
      };
    },
    Cell: rowData => {
      const { day: date } = rowData.row.original;
      if (!date) {
        return null;
      }
      const weekDay = dayjs(date).format('dddd');
      const day = dayjs(date).format('DD');
      const month = dayjs(date).format('MMM');

      return (
        <Flex
          direction="column"
          alignItems="center"
          className="scale-75 fw-normal text-center"
        >
          <strong className="text-uppercase">{weekDay}</strong>
          <Calendar className="w-auto" day={day} month={month} />
        </Flex>
      );
    }
  },
  {
    accessor: 'workingDay',
    Header: 'Jornada',
    cellProps: (cell, rowData) => {
      const { day } = rowData.cell.row.original;
      return {
        className: classNames('w-0', {
          'bg-light border-bottom-0': !day
        })
      };
    },
    Cell: rowData => {
      const { original } = rowData.row;
      // eslint-disable-next-line no-unused-vars
      const { ref, ...rest } = original;
      return <WorkingDay {...rest} />;
    }
  },
  {
    accessor: 'todayTime',
    Header: 'Tiempo trabajado',
    cellProps: (cell, rowData) => {
      const { day } = rowData.cell.row.original;
      return {
        className: classNames('w-0', {
          'bg-light border-bottom-0': !day
        })
      };
    },
    Cell: rowData => {
      const { original } = rowData.row;
      // eslint-disable-next-line no-unused-vars
      const { ref, ...rest } = original;
      return <WorkingDayDuration {...rest} />;
    }
  },
  {
    accessor: 'records',
    Header: 'Control horario',
    cellProps: (cell, rowData) => {
      const { day } = rowData.cell.row.original;
      return {
        className: classNames({
          'bg-light border-bottom-0': !day
        })
      };
    },
    Cell: rowData => {
      const { original: data } = rowData.row;
      const { day } = data;

      return day ? <DayTimeControl {...data} /> : <WorkingWeek {...data} />;
    }
  }
];

const WeekTimeControl = () => {
  const { timeOff } = useContext(CalendarContext);
  const { myWeekRecords: raw = [] } = useContext(TimeControlContext);
  const { me, schedules } = useContext(UserContext);
  const { location } = me || {};
  const { holidays = [] } = location || {};
  const userTimesOff =
    timeOff?.filter(
      ({ participants }) =>
        !participants || participants.some(ref => ref?.path === me?.ref?.path)
    ) || [];
  const [now] = useState(new Date());
  const records = [...raw].sort((item1, item2) =>
    item1.day > item2.day ? -1 : 1
  );
  const innerRef = useRef();
  const recordsWithDay = records.map(record => {
    const [day] = record.createdAt.match(/\d{4}-\d{2}-\d{2}/);
    return { ...record, day };
  });
  const schedule = getUserSchedule(schedules, me);
  const recordsByDay = Object.entries(groupBy(recordsWithDay, 'day'));
  const lastRecord = [...records].pop();
  const isWorking = lastRecord?.type === 'in';
  const nowISO = now.toISOString().replace(/\d{4}-/, '');
  const period = schedule?.periods
    ?.reverse?.()
    ?.find(
      ({ end, start }) =>
        nowISO >= new Date(start).toISOString().replace(/\d{4}-/, '') &&
        nowISO <= new Date(end).toISOString().replace(/\d{4}-/, '')
    );
  const weekDays = period?.time?.reduce(
    (periodDays, { days }) => [...new Set([...periodDays, ...days])],
    []
  );
  const firstDay = new Date();
  const lastDay = new Date();
  let firstDayToSet = weekDays?.[0];
  firstDayToSet = typeof firstDayToSet === 'undefined' ? 1 : firstDayToSet;
  let lastDayToSet = [...(weekDays || [])]?.pop();
  lastDayToSet = typeof lastDayToSet === 'undefined' ? 5 : lastDayToSet;
  const todayDay = now.getDay();
  const distanceToFirstDay = firstDayToSet - todayDay;
  const distanceToLastDay = lastDayToSet - todayDay;
  firstDay.setDate(now.getDate() + distanceToFirstDay);
  lastDay.setDate(now.getDate() + distanceToLastDay);
  let days = [];
  let currentDay = new Date(firstDay);
  while (
    currentDay &&
    lastDay &&
    currentDay?.toISOString() <= lastDay?.toISOString()
  ) {
    const [day, records] =
      recordsByDay?.find(
        ([day]) => day === dayjs(currentDay).format('YYYY-MM-DD')
      ) || [];
    const data = day
      ? { day, records }
      : {
          day: dayjs(currentDay).format('YYYY-MM-DD')
        };
    const holiday = holidays.find(
      ({ start }) =>
        dayjs(start).format('YYYY-MM-DD') ===
        dayjs(currentDay).format('YYYY-MM-DD')
    );
    const isHoliday = !!holiday;
    const endTime = isHoliday ? '' : getDayEndTime(schedule, currentDay);
    const maxSeconds = isHoliday ? 0 : getDaySeconds(schedule, currentDay);
    days = [...days, { ...data, endTime, isHoliday, holiday, maxSeconds }];
    currentDay.setDate(currentDay.getDate() + 1);
  }

  const data = days
    .map(({ day, records }) => {
      const isToday =
        day === new Date().toISOString().match(/\d{4}-\d{2}-\d{2}/)?.[0];
      const holiday = holidays.find(
        ({ start }) =>
          dayjs(start).format('YYYY-MM-DD') === dayjs(day).format('YYYY-MM-DD')
      );
      const timeOff = userTimesOff.find(
        ({ start, end }) =>
          dayjs(day).format('YYYY-MM-DD') >=
            dayjs(start).format('YYYY-MM-DD') &&
          dayjs(day).format('YYYY-MM-DD') <= dayjs(end).format('YYYY-MM-DD')
      );
      const subtypeData =
        timeOffSubtypes.find(({ value }) => value === timeOff?.subtype) || {};
      const { working } = subtypeData;
      const isHoliday = !!holiday;
      const isTimeOff = !!timeOff;
      const startTime = isHoliday
        ? ''
        : getDayStartTime(schedule, new Date(day));
      const endTime =
        isHoliday || (!working && isTimeOff)
          ? ''
          : getDayEndTime(schedule, new Date(day));
      const maxSeconds =
        isHoliday || (!working && isTimeOff)
          ? 0
          : getDaySeconds(schedule, new Date(day));
      return {
        day,
        holiday,
        isHoliday,
        isTimeOff,
        isToday,
        isWorking,
        maxSeconds,
        records,
        timeOff,
        startTime,
        endTime
      };
    })
    .sort((item1, item2) => (item1.day < item2.day ? -1 : 1));
  const weekData = {
    records,
    maxSeconds: data.reduce((total, { maxSeconds }) => total + maxSeconds, 0),
    isWorking,
    schedule
  };

  return (
    <>
      <AdvanceTableWrapper
        innerRef={innerRef}
        columns={columns}
        data={[...data, weekData]}
        pagination
        perPage={20}
      >
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName={classNames(
              'bg-light text-900 text-nowrap align-middle'
            )}
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
      </AdvanceTableWrapper>
      <Card.Footer className="bg-light pb-0" />
    </>
  );
};

export default WeekTimeControl;
