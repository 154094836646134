import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import face0 from 'assets/img/animated-icons/face0.json';
import face1 from 'assets/img/animated-icons/face1.json';
import face2 from 'assets/img/animated-icons/face2.json';
import face3 from 'assets/img/animated-icons/face3.json';
import face4 from 'assets/img/animated-icons/face4.json';
import face5 from 'assets/img/animated-icons/face5.json';
import face6 from 'assets/img/animated-icons/face6.json';

const animations = [
  face0,
  face0,
  face1,
  face1,
  face2,
  face3,
  face4,
  face5,
  face6
];

const Face = ({ value, ...rest }) => {
  if (typeof value !== 'number') {
    return null;
  }
  const index = parseInt((value * animations.length) / 100);
  const animation = animations[index] || animations[animations.length - 1];

  return (
    <div className="position-relative">
      <Lottie animationData={animation} loop={false} {...rest} />
    </div>
  );
};

Face.propTypes = {
  value: PropTypes.number.isRequired
};

export default Face;
