import React, { useContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';
import { UserReviewsContext, UserContext } from 'context/Context';
import { reviewReducer } from 'reducers/reviewReducer';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  query,
  updateDoc,
  where
} from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

const ReviewProvider = ({ children }) => {
  const [user, setUser] = useState();
  const { company } = useContext(UserContext);
  const db = useFirestore();
  const [month, setMonth] = useState(
    new Date().toISOString().match(/\d+-\d+/)[0]
  );
  const endMonth = new Date(`${month}-10`);
  new Date(endMonth.setMonth(endMonth.getMonth() + 1));

  let reviewQuery = query(collection(db, 'none'));
  const templatesQuery = query(collection(db, 'reviews_templates'));
  if (company?.NO_ID_FIELD) {
    reviewQuery = query(
      collection(db, 'reviews'),
      where('companyId', '==', `${company?.NO_ID_FIELD}`)
    );
  }
  let { data: raw = [] } = useFirestoreCollectionData(reviewQuery);
  let { data: templates = [] } = useFirestoreCollectionData(templatesQuery);

  const [reviewState, reviewDispatch] = useReducer(reviewReducer, {
    reviews: raw,
    allReviews: raw,
    filters: [
      { label: 'Todos', value: 'all' },
      { label: 'Favoritos', value: 'star' },
      { label: 'Archivados', value: 'archived' }
    ],
    currentFilter: 'all'
  });

  const create = async params => {
    try {
      const createdAt = new Date(Date.now()).toISOString();
      const data = {
        companyId: company?.NO_ID_FIELD,
        createdAt,
        ...params
      };
      await addDoc(collection(db, 'reviews'), data);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteReview = async id => {
    try {
      await deleteDoc(doc(db, 'reviews', id));
    } catch (error) {
      console.error(error);
    }
  };

  const update = async (NO_ID_FIELD, params) => {
    try {
      const ref = doc(db, 'reviews', NO_ID_FIELD);
      await updateDoc(ref, params);
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   company?.NO_ID_FIELD &&
  //     reviewDispatch({ type: 'RESET_QUESTIONAIRES', payload: [] });
  // }, [company?.NO_ID_FIELD]);

  useEffect(() => {
    if (equal(raw, reviewState?.allReviews)) {
      return;
    }
    reviewDispatch({ type: 'UPDATE', payload: raw });
  }, [raw]);

  return (
    <UserReviewsContext.Provider
      value={{
        reviewState,
        reviewDispatch,
        create,
        update,
        deleteReview,
        month,
        setMonth,
        templates,
        user,
        setUser
      }}
    >
      {children}
    </UserReviewsContext.Provider>
  );
};

ReviewProvider.propTypes = { children: PropTypes.node };

export default ReviewProvider;
