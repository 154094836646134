import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { Card } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import Tooltip from 'components/common/Tooltip';
import { useNavigate } from 'react-router-dom';
import { QuestionairesContext } from 'context/Context';
import { toast } from 'react-toastify';
import { download } from 'helpers/utils';

const ItemButton = ({
  tooltip,
  icon,
  className,
  buttonClass,
  onClick,
  ...rest
}) => {
  return (
    <div className={className}>
      <Tooltip title={tooltip}>
        <IconButton
          className={buttonClass}
          variant="falcon-default"
          size="sm"
          icon={icon}
          onClick={onClick}
          {...rest}
        />
      </Tooltip>
    </div>
  );
};

const DetailHeader = ({ questionaire }) => {
  const { deleteQuestionaire, update } = useContext(QuestionairesContext);
  const navigate = useNavigate();
  const [printing, setPrinting] = useState(false);
  const { NO_ID_FIELD: questionaireId, archived, title } = questionaire || {};

  const handleArchive = async () => {
    await update(questionaireId, { archived: !archived });
    toast.success(
      !archived ? 'Cuestionario archivado' : 'Cuestionario desarchivado'
    );
  };

  const handleDelete = async () => {
    await deleteQuestionaire(questionaireId);
    toast.success('Cuestionario eliminado');
  };

  const handlePrint = async () => {
    try {
      setPrinting(true);
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_URL}/printQuestionaire?questionaireId=${questionaireId}`
      );
      setPrinting(false);
      const blob = await response.blob();
      download(blob, `${title}.pdf`);
      if (response.status !== 200) {
        throw Error();
      }
    } catch (error) {
      setPrinting(false);
      toast.error(`Ha habido un error al imprimir el cuestionario`);
    }
  };

  const handleSend = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_URL}/sendQuestionaire?questionaireId=${questionaireId}`
      );
      if (response.status !== 200) {
        throw Error();
      }
      toast.success(`Evaluación enviada`);
    } catch (error) {
      toast.error(`Ha habido un error al enviar la evaluación`);
    }
  };

  return (
    <Card className="mb-3">
      <Card.Body as={Flex} alignItems="center">
        <ItemButton
          tooltip="Volver a las encuestas"
          icon="arrow-left"
          onClick={() => {
            navigate('/company/questionaires');
          }}
        />
        <span className="mx-1 mx-sm-2 text-300">|</span>
        <ItemButton
          tooltip={archived ? 'Desarchivar' : 'Archivar'}
          variant={archived ? 'warning' : 'falcon-default'}
          icon="archive"
          onClick={handleArchive}
        />
        <ItemButton
          tooltip="Enviar a los que no han contestado"
          icon="paper-plane"
          className="ms-1 ms-sm-2 d-none d-sm-inline-block"
          onClick={handleSend}
        />
        <ItemButton
          tooltip="Imprimir"
          icon="print"
          disabled={printing}
          className="ms-1 ms-sm-2 d-none d-sm-inline-block"
          onClick={handlePrint}
        />
        <ItemButton
          tooltip="Delete"
          icon="trash"
          className="ms-1 ms-sm-2"
          variant="danger"
          onClick={handleDelete}
        />
      </Card.Body>
    </Card>
  );
};

ItemButton.propTypes = {
  tooltip: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  buttonClass: PropTypes.string,
  onClick: PropTypes.func
};

DetailHeader.propTypes = {
  questionaire: PropTypes.object
};

export default DetailHeader;
