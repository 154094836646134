import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { ChatContext } from 'context/Context';

const ChatCreatorHeader = () => {
  const { setIsOpenNewChat } = useContext(ChatContext);

  return (
    <div className="chat-content-header bg-250">
      <Row className="w-100">
        <Col xs={2}>
          <Button
            className="p-0 shadow-none"
            variant="send"
            onClick={() => setIsOpenNewChat(false)}
          >
            <FontAwesomeIcon icon="arrow-left" />
          </Button>
        </Col>
        <Col xs={10} as={Flex} alignItems="center">
          <h5 className="mb-0 text-truncate fs-0">Nuevo chat</h5>
        </Col>
      </Row>
    </div>
  );
};

export default ChatCreatorHeader;
