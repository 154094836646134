import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { fromBlob, urlToBlob } from 'image-resize-compress';
import { UserContext } from 'context/Context';
import { Card, Col, Form, Row } from 'react-bootstrap';
import InputField from 'components/common/InputField';
import FalconCloseButton from 'components/common/FalconCloseButton';
import FalconDropzone from 'components/common/FalconDropzone';
import Avatar from 'components/common/Avatar';
import CreatableSelect from 'react-select/creatable';

const PersonalForm = ({
  register,
  errors,
  reset,
  setValue,
  watch,
  index,
  user
}) => {
  const { departments = [], createDepartment } = useContext(UserContext);
  const { firstname = '', lastname = '' } = user;
  const [avatar, setAvatar] = useState();
  const name = `${firstname} ${lastname}`.trim() || 'Lidia López';
  const watching = watch();
  const { users } = watching;

  const handleSelectAvatar = async (none, [file]) => {
    urlToBlob(file);
    const compressedFile = await fromBlob(file, 80, 350, 350, 'jepg');
    setAvatar(compressedFile);
    setValue(`users.${index}.avatar`, compressedFile);
  };

  const deleteUser = () => {
    const data = {
      ...watching,
      users: users.filter((def, i) => i !== index)
    };
    reset(data);
  };

  return (
    <Card className="w-100 mb-3" data-index={index}>
      <Card.Body>
        <Row className="mb-3 py-3 mt-n3 position-relative bg-light rounded">
          <Col xs={12} md="auto">
            <FalconDropzone
              className="m-auto mb-3 p-0 overflow-hidden avatar-5xl rounded-circle"
              files={avatar}
              onChange={handleSelectAvatar}
              multiple={false}
              accept="image/*"
              placeholder={
                <div className="position-relative">
                  <Avatar
                    size="5xl"
                    name={name.trim() || ''}
                    src={
                      avatar?.base64 ||
                      avatar?.src ||
                      (avatar && URL.createObjectURL(avatar))
                    }
                  />
                  {!avatar && (
                    <div className="position-absolute bottom-0 w-100 bg-black bg-opacity-50 pb-3">
                      <span className="fs--1 text-white">Editar</span>
                    </div>
                  )}
                </div>
              }
            />
          </Col>
          <Col xs={12} sm={8}>
            <Row>
              <Col>
                <InputField
                  label="Nombre"
                  name="firstname"
                  placeholder=""
                  errors={errors}
                  formGroupProps={{
                    className: 'mb-3'
                  }}
                  formControlProps={{
                    ...register(`users.${index}.firstname`, { required: true })
                  }}
                />
              </Col>
              <Col>
                <InputField
                  label="Apellidos"
                  name="lastname"
                  placeholder=""
                  errors={errors}
                  formGroupProps={{
                    className: 'mb-3'
                  }}
                  formControlProps={{
                    ...register(`users.${index}.lastname`, { required: true })
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputField
                  label="Email"
                  name="email"
                  errors={errors}
                  formControlProps={{
                    placeholder: '',
                    ...register(`users.${index}.email`, { required: true })
                  }}
                />
              </Col>
              <Col>
                <Form.Label>Departamento</Form.Label>
                <CreatableSelect
                  options={departments.map(({ name }) => ({
                    label: name,
                    value: name
                  }))}
                  placeholder="Selecciona el departamento"
                  classNamePrefix="react-select"
                  onChange={({ value }) =>
                    setValue(`users.${index}.department`, value)
                  }
                  onCreateOption={createDepartment}
                  menuPortalTarget={document.body}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} sm={6} md={4}>
            <InputField
              label="NIF"
              name="nif"
              errors={errors}
              formGroupProps={{
                className: 'mb-3'
              }}
              formControlProps={{
                placeholder: '00000000A',
                ...register(`users.${index}.nif`, { required: true })
              }}
            />
          </Col>
          <Col xs={12} sm={6} md={4}>
            <InputField
              type="number"
              label={
                <>
                  Teléfono <small className="text-400">(opcional)</small>
                </>
              }
              name="phone"
              errors={errors}
              formGroupProps={{
                className: 'mb-3'
              }}
              formControlProps={{
                className: 'input-spin-none',
                placeholder: '555 55 55 55',
                ...register(`users.${index}.phone`)
              }}
            />
          </Col>
          <Col xs={12} sm={6} md={4}>
            <InputField
              type="date"
              label="Fecha de nacimiento"
              name="birthday"
              errors={errors}
              setValue={setValue}
              datepickerProps={{
                dateFormat: 'dd/MM/yyyy'
              }}
              formGroupProps={{
                className: 'mb-3'
              }}
              formControlProps={{
                placeholder: '01/01/1986',
                icon: 'calendar-alt',
                required: true
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <InputField
              label="Dirección"
              name="address"
              errors={errors}
              formGroupProps={{
                className: 'mb-3'
              }}
              formControlProps={{
                placeholder: 'C/ Jacinto Benavente 21, 4 8',
                ...register(`users.${index}.address`, { required: true })
              }}
            />
          </Col>
        </Row>
      </Card.Body>
      <FalconCloseButton
        noOutline
        className="position-absolute top-0 end-0 m-3"
        onClick={deleteUser}
      />
    </Card>
  );
};

PersonalForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  reset: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  index: PropTypes.number,
  user: PropTypes.object
};

export default PersonalForm;
