import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  addDoc,
  collection,
  doc,
  limit,
  query,
  where
} from '@firebase/firestore';
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData
} from 'reactfire';
import QuestionaireProvider from '../QuestionaireProvider';
import QuestionaireIntro from './QuestionaireIntro';
import Questionaire from './Questionaire';
import QuestionaireOutcome from './QuestionaireOutcome';
import { UserContext } from 'context/Context';
import { toast } from 'react-toastify';

const QuestionaireViewerContent = () => {
  const location = useLocation();
  const { hash } = location;
  let { me } = useContext(UserContext);
  const { questionaireId } = useParams();
  const db = useFirestore();

  const { NO_ID_FIELD: userId } = me || {};
  const navigate = useNavigate();

  let userResponseRef = query(collection(db, 'none'));
  let questionaireRef = doc(db, 'none', 'none');
  if (userId && questionaireId) {
    questionaireRef = doc(db, 'questionaires', questionaireId);
    userResponseRef = query(
      collection(db, 'questionaires_responses'),
      where('userId', '==', userId),
      where('questionaireId', '==', questionaireId),
      limit(1)
    );
  }
  const { data: questionaire = {} } = useFirestoreDocData(questionaireRef);
  const { data: response = [] } = useFirestoreCollectionData(userResponseRef);

  const { companyId: questionaireCompanyId = null, definitions = [] } =
    questionaire;
  const { responses = [] } = response?.[0] || {};
  const readOnly = !!response?.[0];
  const { handleSubmit, ...rest } = useForm();
  const { setValue } = rest;

  useEffect(() => {
    responses.forEach((value, index) => {
      setValue(`entry-${index}`, value);
    });
  }, [responses]);

  const onSubmitData = async data => {
    const responses = Object.entries({
      ...definitions.reduce(
        (all, { type }, index) => ({
          ...all,
          [`entry-${index}`]: type === 'divider' || undefined
        }),
        {}
      ),
      ...data
    })
      .sort(([key1], [key2]) => (parseInt(key1) < parseInt(key2) ? -1 : 1))
      .map(([, value]) => value);

    if (
      !responses.length ||
      responses.some((response, index) => {
        const isUndefined = typeof response === 'undefined';
        if (isUndefined) {
          const url = `#entry-${index}`;
          navigate(url);
        }
        return isUndefined;
      })
    ) {
      return;
    }

    const createdAt = new Date(Date.now()).toISOString();
    const responseData = {
      companyId: questionaireCompanyId,
      createdAt,
      questionaireId,
      userId,
      responses,
      new: true
    };
    try {
      await addDoc(collection(db, 'questionaires_responses'), responseData);
      toast.success('Cuestionario enviado');
      setTimeout(() => {
        navigate('/user/questionaires');
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        const card = element?.parentElement?.parentElement;
        if (element) {
          card?.classList.add(...['border-4']);
          setTimeout(() => {
            card?.classList.remove(...['border-4']);
          }, 1000);
          element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }
    }, 0);
  }, [hash]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmitData)}>
        {me?.type !== 'admin' && (
          <QuestionaireIntro questionaire={questionaire} />
        )}
        <Questionaire
          {...rest}
          questionaire={questionaire}
          readOnly={readOnly}
        />
        <QuestionaireOutcome questionaire={questionaire} readOnly={readOnly} />
      </Form>
    </>
  );
};

const QuestionaireViewer = () => {
  const { me } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  useEffect(() => {
    me?.type === 'admin' && pathname.includes('/user/questionaires/detail')
      ? navigate(`${pathname.replace('/user/', '/company/')}/questionaire`)
      : me?.type !== 'admin' &&
        pathname.includes('/company/questionaires/detail')
      ? navigate(
          pathname
            .replace('/company/', '/user/')
            .replace(/\/(questionaire|analytics)$/, '')
        )
      : null;
  }, [me?.type, pathname]);

  return (
    <QuestionaireProvider>
      <QuestionaireViewerContent />
    </QuestionaireProvider>
  );
};

export default QuestionaireViewer;
