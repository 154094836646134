import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Picker from '@emoji-mart/react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AppContext, { ChatContext, UserContext } from 'context/Context';

const ChatMessageOptions = ({ message }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { NO_ID_FIELD, from, reactions = [] } = message || {};
  const { setReferrer, updateMessage } = useContext(ChatContext);
  const { me } = useContext(UserContext);
  const [previewEmoji, setPreviewEmoji] = useState(false);
  const [actions] = useState(
    [
      {
        tooltip: 'Reaccionar',
        icon: ['far', 'laugh-beam'],
        onClick: event => {
          event.stopPropagation();
          setPreviewEmoji(!previewEmoji);
        }
      },
      {
        tooltip: 'Responder',
        icon: 'reply',
        onClick: () => {
          setReferrer(message);
        }
      },
      from?.path === me?.ref?.path && {
        tooltip: 'Eliminar',
        icon: 'trash',
        onClick: event => {
          event.stopPropagation();
          updateMessage(NO_ID_FIELD, { type: 'deleted' });
        }
      }
    ].filter(action => action)
  );

  const addEmoji = event => {
    let reaction = event.native;
    const createdAt = new Date().toISOString();
    const _reactions = [
      ...reactions.filter(({ user }) => user?.path !== me?.ref?.path),
      {
        createdAt,
        reaction,
        user: me?.ref
      }
    ];

    updateMessage(NO_ID_FIELD, { reactions: _reactions });
    setPreviewEmoji(false);
  };

  return (
    <>
      <ul className="hover-actions position-relative list-inline mb-0 text-400 mx-2">
        {actions.map(action => (
          <li
            key={action.tooltip}
            className="list-inline-item cursor-pointer chat-option-hover"
          >
            <OverlayTrigger overlay={<Tooltip>{action.tooltip}</Tooltip>}>
              <div>
                <FontAwesomeIcon
                  icon={action.icon}
                  className="d-inline-block fs-0"
                  onClick={action.onClick || (() => {})}
                />
              </div>
            </OverlayTrigger>
          </li>
        ))}
      </ul>
      {previewEmoji && (
        <div className="chat-emoji-picker z-index-1016" dir="ltr">
          <Picker
            locale={'es'}
            onEmojiSelect={addEmoji}
            onClickOutside={() => setPreviewEmoji(false)}
            theme={isDark ? 'dark' : 'light'}
            previewPosition="none"
            skinTonePosition="none"
          />
        </div>
      )}
    </>
  );
};

ChatMessageOptions.propTypes = {
  message: PropTypes.object
};

export default ChatMessageOptions;
