import React, { useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import dayjs from 'dayjs';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import {
  Alerts,
  DaysBalance,
  HoursChart,
  UserCell,
  WorkingDay,
  WorkingDaysDuration
} from './Components';
import CustomTimeControlDetail from './CustomTimeControlDetail';
import {
  CalendarContext,
  TimeControlContext,
  UserContext
} from 'context/Context';
import {
  getDayStartTime,
  getDayEndTime,
  getDaySeconds,
  getUserSchedule,
  groupBy
} from 'helpers/utils';
import timeOffSubtypes from 'components/user/time-off/subtypes.json';

const columns = [
  {
    accessor: 'name',
    Header: 'Nombre',
    headerProps: {
      className: 'py-3 min-w-220px w-220px'
    },
    Cell: rowData => {
      return <UserCell {...rowData.row.original} />;
    }
  },
  {
    accessor: 'maxSeconds',
    headerProps: {
      className: 'text-center'
    },
    Header: 'Tiempo teórico',
    cellProps: {
      className: 'text-center'
    },
    Cell: rowData => {
      return <WorkingDay {...rowData.row.original} />;
    }
  },
  {
    accessor: 'seconds',
    headerProps: {
      className: 'text-center'
    },
    Header: 'Tiempo trabajado',
    cellProps: {
      className: 'text-center'
    },
    Cell: rowData => {
      return (
        <WorkingDaysDuration
          className="w-100px m-auto"
          progressBarClass={classNames({
            'bg-300': rowData.data?.length === 1
          })}
          {...rowData.row.original}
          showExtra={false}
        />
      );
    }
  },
  {
    accessor: 'balanceTime',
    headerProps: {
      className: 'text-center'
    },
    Header: 'Balance',
    cellProps: {
      className: 'text-center'
    },
    Cell: rowData => {
      return <DaysBalance {...rowData.row.original} />;
    }
  },
  {
    accessor: 'records',
    headerProps: {
      className: 'text-center'
    },
    Header: 'Distribución de horas',
    cellProps: {
      className: 'text-center'
    },
    Cell: rowData => {
      return <HoursChart className="m-auto" {...rowData.row.original} />;
    }
  },
  {
    accessor: 'alerts',
    headerProps: {
      className: 'text-end'
    },
    Header: 'Alertas',
    cellProps: {
      className: 'text-end w-0'
    },
    Cell: rowData => {
      return <Alerts {...rowData.row.original} />;
    }
  }
];

const perPage = 20;
const CustomTimeControl = () => {
  const {
    filters,
    records: raw = [],
    setFilters
  } = useContext(TimeControlContext);
  const { userId } = useParams();
  const { timeOff: timesOff } = useContext(CalendarContext);
  const { me, partners, schedules } = useContext(UserContext);
  const innerRef = useRef();
  const recordsWithDay = raw.map(record => {
    const [day] = record.createdAt.match(/\d{4}-\d{2}-\d{2}/);
    return { ...record, day };
  });
  const recordsByUser = Object.entries(groupBy(recordsWithDay, 'userId'));

  const entriesWithData = recordsByUser?.reduce(
    (entries, [userId, records]) => {
      const recordsByDay = groupBy(records, 'day');
      const days = Object.entries(recordsByDay).map(([day, records]) => ({
        day,
        records
      }));
      return [...entries, { days, records, userId }];
    },
    []
  );

  const { usersId = [] } = filters || {};
  const { period } = filters || {};
  const { start, end } = period || {};

  const data = partners
    ?.filter(({ NO_ID_FIELD }) =>
      me?.type !== 'admin'
        ? NO_ID_FIELD === me?.NO_ID_FIELD
        : !usersId?.length || usersId.includes(NO_ID_FIELD)
    )
    .map(user => {
      const { NO_ID_FIELD: userId, location } = user;
      const { holidays = [] } = location || {};
      const userTimesOff = timesOff.filter(
        ({ participants }) =>
          !participants ||
          participants.some(ref => ref?.path === user?.ref?.path)
      );
      const schedule = getUserSchedule(schedules, user);
      const userData =
        entriesWithData?.find(entry => entry?.userId === userId) || {};
      let days = [];
      let currentDay = new Date(start);
      while (
        currentDay &&
        end &&
        currentDay?.toISOString() <= end?.toISOString()
      ) {
        const isToday =
          currentDay ===
          new Date().toISOString().match(/\d{4}-\d{2}-\d{2}/)?.[0];
        const data = userData?.days?.find(
          ({ day }) => day === dayjs(currentDay).format('YYYY-MM-DD')
        ) || { day: dayjs(currentDay).format('YYYY-MM-DD') };
        const holiday = holidays.find(
          ({ start }) =>
            dayjs(start).format('YYYY-MM-DD') ===
            dayjs(currentDay).format('YYYY-MM-DD')
        );
        const timeOff = userTimesOff?.find(
          ({ start, end }) =>
            dayjs(currentDay).format('YYYY-MM-DD') >=
              dayjs(start).format('YYYY-MM-DD') &&
            dayjs(currentDay).format('YYYY-MM-DD') <=
              dayjs(end).format('YYYY-MM-DD')
        );
        const subtypeData =
          timeOffSubtypes.find(({ value }) => value === timeOff?.subtype) || {};
        const { extra, working } = subtypeData;
        const isHoliday = !!holiday;
        const isTimeOff = !!timeOff;
        const startTime =
          isHoliday || (!working && isTimeOff && extra)
            ? ''
            : getDayStartTime(schedule, new Date(currentDay));
        const endTime =
          isHoliday || (!working && isTimeOff && extra)
            ? ''
            : getDayEndTime(schedule, new Date(currentDay));
        let maxSeconds =
          isHoliday || (!working && isTimeOff && extra)
            ? 0
            : getDaySeconds(schedule, new Date(currentDay));
        days = [
          ...days,
          {
            ...data,
            startTime,
            endTime,
            holiday,
            timeOff,
            isHoliday,
            isTimeOff,
            isToday,
            maxSeconds,
            user
          }
        ];
        currentDay.setDate(currentDay.getDate() + 1);
      }

      const totalMaxSeconds = days?.reduce(
        (total, { maxSeconds }) => total + maxSeconds,
        0
      );

      return {
        ...userData,
        days,
        maxSeconds: totalMaxSeconds,
        schedule,
        user
      };
    });

  useEffect(() => {
    const today = new Date();
    const monday = new Date();
    const daytoset = 1;
    const currentDay = today.getDay();
    const distance = daytoset - currentDay;
    monday.setDate(today.getDate() + distance);
    const period = {
      start: monday,
      end: today
    };
    setFilters(filters => ({ ...filters, period }));
  }, []);

  useEffect(() => {
    setFilters(filters => ({
      ...filters,
      usersId: [userId].filter(userId => userId)
    }));
  }, [userId]);

  return (
    <>
      <AdvanceTableWrapper
        innerRef={innerRef}
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={perPage}
      >
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName={classNames(
              'bg-light text-900 text-nowrap align-middle'
            )}
            rowClassName={classNames(
              'align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100',
              {
                'bg-light': usersId?.length === 1
              }
            )}
            tableProps={{
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        {usersId?.length > 1 && (
          <Card.Footer className="bg-light pb-0">
            {perPage < data.length && (
              <AdvanceTableFooter
                rowCount={data.length}
                table
                rowInfo
                navButtons
              />
            )}
          </Card.Footer>
        )}
      </AdvanceTableWrapper>
      {(usersId?.length === 1 || data?.length === 1) && (
        <CustomTimeControlDetail {...(data?.[0] || {})} />
      )}
    </>
  );
};

export default CustomTimeControl;
