import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import equal from 'fast-deep-equal';
import { ReviewsContext } from 'context/Context';
import { reviewReducer } from 'reducers/reviewReducer';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  updateDoc,
  where
} from '@firebase/firestore';
import { getDownloadURL, ref } from '@firebase/storage';
import {
  useFirestore,
  useFirestoreCollectionData,
  useStorage
} from 'reactfire';

const ReviewProvider = ({ children }) => {
  const { companyId } = useParams();
  const [partners, setPartners] = useState([]);
  const db = useFirestore();
  const storage = useStorage();
  const [month, setMonth] = useState(
    new Date().toISOString().match(/\d+-\d+/)[0]
  );
  const endMonth = new Date(`${month}-10`);
  new Date(endMonth.setMonth(endMonth.getMonth() + 1));

  let usersQuery = query(collection(db, 'none'));
  let happyResponseQuery = query(collection(db, 'none'));
  let reviewQuery = query(collection(db, 'none'));
  const templatesQuery = query(collection(db, 'reviews_templates'));
  if (companyId) {
    usersQuery = query(
      collection(db, 'users'),
      where('company', '==', companyId),
      orderBy('name', 'desc')
    );
    happyResponseQuery = query(
      collection(db, 'form_responses'),
      where('companyId', '==', companyId),
      where('createdAt', '>', month),
      where('createdAt', '<', endMonth.toISOString().match(/\d+-\d+/)[0]),
      orderBy('createdAt', 'desc')
    );
    reviewQuery = query(
      collection(db, 'reviews'),
      where('companyId', '==', `${companyId}`)
    );
  }
  const { data: partnersRaw = [] } = useFirestoreCollectionData(usersQuery);
  const { data: happyResponses = [] } =
    useFirestoreCollectionData(happyResponseQuery);
  let { data: raw = [] } = useFirestoreCollectionData(reviewQuery);
  let { data: templates = [] } = useFirestoreCollectionData(templatesQuery);

  const [reviewState, reviewDispatch] = useReducer(reviewReducer, {
    reviews: raw,
    allReviews: raw,
    filters: [
      { label: 'Todos', value: 'all' },
      { label: 'Favoritos', value: 'star' },
      { label: 'Archivados', value: 'archived' }
    ],
    currentFilter: 'all'
  });

  const create = async params => {
    try {
      const createdAt = new Date(Date.now()).toISOString();
      const data = {
        companyId: companyId,
        createdAt,
        ...params
      };
      await addDoc(collection(db, 'reviews'), data);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteReview = async id => {
    try {
      await deleteDoc(doc(db, 'reviews', id));
    } catch (error) {
      console.error(error);
    }
  };

  const update = async (NO_ID_FIELD, params) => {
    try {
      const ref = doc(db, 'reviews', NO_ID_FIELD);
      await updateDoc(ref, params);
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   companyId &&
  //     reviewDispatch({ type: 'RESET_QUESTIONAIRES', payload: [] });
  // }, [companyId]);

  useEffect(() => {
    if (equal(raw, reviewState?.allReviews)) {
      return;
    }
    reviewDispatch({ type: 'UPDATE', payload: raw });
  }, [raw]);

  const handlePartnersSnapshot = async partnersRaw => {
    const _partners = await Promise.all(
      partnersRaw?.map(async data => {
        const currentPartner = partners?.find(({ id }) => id === doc.id);
        try {
          const avatar =
            currentPartner?.avatar ||
            data?.avatar?.base64 ||
            (await getDownloadURL(
              ref(storage, `/users/${data?.id}/avatar.jpeg`)
            ));
          return {
            id: data.id,
            ...data,
            avatar,
            ref: currentPartner?.ref || doc(db, 'users', data?.NO_ID_FIELD)
          };
        } catch (error) {
          //console.error(error);
          return {
            id: data.id,
            ...data,
            avatar: '',
            ref: currentPartner?.ref || doc(db, 'users', data?.NO_ID_FIELD)
          };
        }
      }) || []
    );
    !equal(_partners, partners) && setPartners(_partners);
  };

  useEffect(() => {
    handlePartnersSnapshot(partnersRaw);
  }, [partnersRaw]);

  return (
    <ReviewsContext.Provider
      value={{
        reviewState,
        reviewDispatch,
        create,
        update,
        deleteReview,
        happyResponses,
        month,
        partners,
        setMonth,
        templates
      }}
    >
      {children}
    </ReviewsContext.Provider>
  );
};

ReviewProvider.propTypes = { children: PropTypes.node };

export default ReviewProvider;
