import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Typewriter } from 'react-simple-typewriter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { Draggable } from 'react-beautiful-dnd';
import AppContext, { AssistantContext } from 'context/Context';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

const TaskDropMenu = ({ offer }) => {
  const { removeOffer, showOfferModal } = useContext(AssistantContext);

  const {
    config: { isRTL }
  } = useContext(AppContext);

  const handleRemoveTaskCard = () => {
    removeOffer(offer?.id);
  };

  return (
    <Dropdown
      onClick={e => e.stopPropagation()}
      align="end"
      className="font-sans-serif"
    >
      <Dropdown.Toggle
        variant="falcon-default"
        size="sm"
        className="kanban-item-dropdown-btn hover-actions dropdown-caret-none"
      >
        <FontAwesomeIcon icon="ellipsis-h" transform="shrink-2" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="py-2" align={isRTL ? 'start' : 'end'}>
        <Dropdown.Item onClick={() => showOfferModal(offer)}>Ver</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleRemoveTaskCard} className="text-danger">
          Eliminar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const OfferCard = ({ task: offer, index }) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  let { id, createdAt, state, title, data = {} } = offer;
  const { completion } = data;
  const statusLabels = {
    loading: {
      text: (
        <Typewriter
          words={[
            'Generando oferta',
            'La IA está trabajando',
            'Puede tardar unos minutos'
          ]}
          loop={true}
          cursor={!isRTL ? true : false}
          cursorStyle="|"
          typeSpeed={70}
          deleteSpeed={50}
          delaySpeed={1000}
        />
      ),
      type: 'secondary'
    }
  };
  const statusLabel = statusLabels[state];
  const labels = [statusLabel].filter(labels => labels);
  const { showOfferModal } = useContext(AssistantContext);

  const handleModalOpen = () => {
    showOfferModal(offer);
  };

  // styles we need to apply on draggables
  const getItemStyle = isDragging => ({
    cursor: isDragging ? 'grabbing' : 'pointer',
    transform: isDragging ? 'rotate(-2deg)' : ''
  });

  return (
    <Draggable draggableId={`task${id}`} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
          className="kanban-item"
        >
          <Card
            style={getItemStyle(snapshot.isDragging)}
            className="kanban-item-card hover-actions-trigger"
            onClick={handleModalOpen}
          >
            <Card.Body>
              <div className="position-relative">
                <TaskDropMenu offer={offer} />
              </div>
              {!!labels?.length && (
                <div className="mb-2">
                  {labels.map(({ Label = SoftBadge, text, type }) => (
                    <Label key={text} bg={type} className="py-1 me-1 mb-1">
                      {text}
                    </Label>
                  ))}
                </div>
              )}
              <div>
                <p className="mb-0 fw-medium font-sans-serif">{title}</p>
                <p className="mb-0 text-500 font-sans-serif text-truncate">
                  {completion?.[0]?.text}
                </p>
              </div>
              <div className="kanban-item-footer cursor-default">
                <div className="text-500">
                  <span className="me-2">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Fecha de creación</Tooltip>}
                    >
                      <span>
                        <FontAwesomeIcon icon="clock" className="me-1" />
                      </span>
                    </OverlayTrigger>
                    <span>{dayjs(createdAt).format('D MMMM YYYY, HH:mm')}</span>
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

OfferCard.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    avatar: PropTypes.string,
    createdAt: PropTypes.string,
    data: PropTypes.object,
    state: PropTypes.string,
    title: PropTypes.string.isRequired,
    department: PropTypes.string
  }),
  index: PropTypes.number
};

TaskDropMenu.propTypes = {
  offer: OfferCard.propTypes.task
};

export default OfferCard;
