import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { collection, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { ReportsContext, UserContext } from 'context/Context';
import { groupBy } from 'helpers/utils';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

dayjs.locale('es');

const ReportsProvider = ({ children }) => {
  const { company } = useContext(UserContext);
  const db = useFirestore();
  let indexesQuery = query(collection(db, 'none'));
  if (company?.NO_ID_FIELD) {
    indexesQuery = query(
      collection(db, 'indexes'),
      where('companyId', '==', `${company?.NO_ID_FIELD}`),
      where('type', '==', 'happiness'),
      where(
        'createdAt',
        '<=',
        new Date(Date.now()).toISOString().match(/\d{4}-\d{2}/)[0]
      )
    );
  }
  let { data: raw = [] } = useFirestoreCollectionData(indexesQuery);

  raw = raw.map(data => {
    const { createdAt } = data;
    let date = new Date(createdAt).toISOString().split('T')[0];
    date = date.split('-');
    date.pop();
    date = date.join('-');
    return { ...data, date };
  });
  const rawByDate = Object.entries(groupBy(raw, 'date'))
    .sort(([key1], [key2]) => (key1 < key2 ? -1 : 1))
    .map(([key, value]) => ({ [key]: value?.length }))
    .reduce((result, date) => ({ ...result, ...date }), {});

  const reports = Object.keys(rawByDate)
    .sort((d1, d2) => (d1 > d2 ? -1 : 1))
    .map(value => ({
      label: dayjs(value).format('MMMM YYYY'),
      value
    }));

  // console.log('REPORTS >>>', reports);

  return (
    <ReportsContext.Provider
      value={{
        reports
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

ReportsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ReportsProvider;
