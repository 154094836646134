import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Overlay, Popover as BSPopover } from 'react-bootstrap';

const Popover = ({
  arrowProps,
  children,
  className,
  placement = 'top',
  content,
  popoverClass,
  show: showProp = false
}) => {
  const [show, setShow] = useState(showProp);
  const target = useRef();

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);
  return (
    <>
      <div
        ref={target}
        className={classNames('d-inline-block', className)}
        onClick={() => setShow(show => !show)}
      >
        {children}
      </div>
      <Overlay
        rootClose
        arrowProps={arrowProps}
        onHide={() => setShow(false)}
        container={() => document.body}
        placement={placement}
        show={show && !!content}
        target={target.current}
        className="z-index-1030"
      >
        {props => (
          <BSPopover
            body
            className={classNames('max-w-100', popoverClass)}
            {...props}
          >
            {content}
          </BSPopover>
        )}
      </Overlay>
    </>
  );
};

Popover.propTypes = {
  arrowProps: PropTypes.object,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  placement: PropTypes.string,
  content: PropTypes.node,
  popoverClass: PropTypes.string,
  show: PropTypes.bool
};

export default Popover;
