import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import 'dayjs/locale/es';

dayjs.extend(calendar);

const BirthdayNotice = ({ user, className }) => {
  const { NO_ID_FIELD, birthday, bithdayDiff, name } = user || {};
  const profileLink = `/user/profile/${NO_ID_FIELD}`;
  const diffText = dayjs(birthday)
    .calendar(null, {
      sameDay: '[es hoy]',
      nextDay: '[es mañana]',
      nextWeek: '[es el] dddd',
      lastDay: '[fue ayer]',
      lastWeek: '[fue el] dddd',
      sameElse: '[es el] dddd, D [de] MMMM'
    })
    .replace('.', '');

  return (
    <>
      <Card className={className}>
        <Card.Body className="fs--1">
          <Flex>
            🎂
            <div className="ms-2 flex-1">
              El cumpleaños de{' '}
              <Link className="fw-semi-bold" to={profileLink}>
                {name}
              </Link>{' '}
              {diffText}
              {bithdayDiff > 1 && (
                <SoftBadge bg="warning" pill className="ms-2">
                  dentro de {bithdayDiff} días
                </SoftBadge>
              )}
            </div>
          </Flex>
        </Card.Body>
      </Card>
    </>
  );
};

BirthdayNotice.propTypes = {
  user: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default BirthdayNotice;
