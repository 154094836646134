import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { collection, orderBy, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { BenefitsContext, UserContext } from 'context/Context';
import happyfy from 'assets/img/logo.png';

const BenefitsProvider = ({ children, userId }) => {
  const { company, me } = useContext(UserContext);
  userId = userId || me?.NO_ID_FIELD;

  const db = useFirestore();
  let benefitsQuery = query(collection(db, 'benefits'));
  let transactionsQuery = query(collection(db, 'none'));
  if (me?.NO_ID_FIELD) {
    transactionsQuery = query(
      collection(db, 'transactions'),
      where('userId', '==', userId),
      orderBy('createdAt', 'desc')
    );
  }
  const { data: all = [] } = useFirestoreCollectionData(benefitsQuery);
  const allSorted = all.sort(
    (
      { NO_ID_FIELD: id1, active: a1, name: n1 },
      { NO_ID_FIELD: id2, active: a2, name: n2 }
    ) => {
      const active1 =
        (a1 === 'default' && '1') ||
        (company?.benefits?.includes(id1) && '2') ||
        (me?.benefits?.includes(id1) && '3');
      const active2 =
        (a2 === 'default' && '1') ||
        (company?.benefits?.includes(id2) && '2') ||
        (me?.benefits?.includes(id2) && '3');
      const name1 = `${active1}-${n1}`;
      const name2 = `${active2}-${n2}`;
      return name1 < name2 ? -1 : 1;
    }
  );

  const { data = [] } = useFirestoreCollectionData(transactionsQuery);
  const transactions = data.map(transaction => {
    const { img } = transaction;
    let avatar =
      img === 'wallet/happyfy.png'
        ? happyfy
        : img.match(/\./)
        ? img
        : undefined;
    let icon = img.match(/\./) ? undefined : img;

    return {
      ...transaction,
      avatar,
      icon
    };
  });
  const total = transactions.reduce((total, { amount }) => total + amount, 0);

  // console.log('BENEFITS >>>', status, transactions, total);

  return (
    <BenefitsContext.Provider
      value={{
        all: allSorted,
        transactions,
        total
      }}
    >
      {children}
    </BenefitsContext.Provider>
  );
};

BenefitsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  userId: PropTypes.string
};

export default BenefitsProvider;
