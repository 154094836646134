import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Card, Col, Row } from 'react-bootstrap';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { AppreciationContext, UserContext } from 'context/Context';
import { groupBy } from 'helpers/utils';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import appreciationTitles from './titles.json';

dayjs.extend(relativeTime);
dayjs.locale('es');

const UserCell = ({ arrow, className, user = {} }) => {
  const { id, avatar, department, name } = user;
  return (
    <Flex alignItems="center" className={className}>
      <Avatar size="xl" src={avatar} name={name} />
      <div className="ps-2">
        <Link to={`/user/profile/${id}`} className="text-800">
          <h6 className="mb-0">{name}</h6>
        </Link>
        <p className="mb-0 fs--2">{department}</p>
      </div>
      {arrow && (
        <FontAwesomeIcon
          icon="arrow-right"
          className="ms-3 text-primary opacity-50"
        />
      )}
    </Flex>
  );
};

UserCell.propTypes = {
  arrow: PropTypes.bool,
  className: PropTypes.string,
  user: PropTypes.object
};

const AllAppreciations = ({ icon, appreciations }) => {
  const { partners = [] } = useContext(UserContext);
  const data = appreciations
    .map(appreciation => {
      const { senderId, targetId } = appreciation;
      const sender = partners.find(
        ({ NO_ID_FIELD }) => NO_ID_FIELD === senderId
      );
      const { name: senderName } = sender || {};
      const target = partners.find(
        ({ NO_ID_FIELD }) => NO_ID_FIELD === targetId
      );
      const { name: targetName } = target || {};
      return { ...appreciation, sender, senderName, target, targetName };
    })
    .filter(({ sender, target }) => sender && target)
    .sort(({ createdAt: c1 }, { createdAt: c2 }) => (c1 > c2 ? -1 : 1));

  const columns = [
    {
      accessor: 'senderName',
      Header: 'De',
      cellProps: {
        className: 'w-0 border-end-0'
      },
      Cell: rowData => {
        const { sender } = rowData.row.original;
        return <UserCell arrow={true} user={sender} />;
      }
    },
    {
      accessor: 'targetName',
      Header: 'A',
      cellProps: {
        className: 'border-start-0'
      },
      Cell: rowData => {
        const { target } = rowData.row.original;
        return <UserCell className="me-4" user={target} />;
      }
    },
    {
      accessor: 'createdAt',
      Header: 'Fecha',
      Cell: rowData => {
        const { createdAt } = rowData.row.original;
        const time = dayjs(createdAt)
          .calendar(null, {
            sameDay: `[${dayjs().to(dayjs(createdAt))}]`,
            lastDay: '[ayer], HH:mm',
            lastWeek: 'dddd, HH:mm',
            sameElse: 'ddd, D MMM, HH:mm'
          })
          .replace('.', '');
        return time;
      }
    },
    {
      accessor: 'value',
      Header: 'Cantidad',
      Cell: rowData => {
        const { value } = rowData.row.original;
        return (
          <h5 className="mb-0">
            {value} {icon}
          </h5>
        );
      }
    }
  ];

  return (
    <Card>
      <Card.Body className="p-0">
        <div className="rounded-3 overflow-hidden">
          <AdvanceTableWrapper
            columns={columns}
            data={data}
            sortable
            pagination
            perPage={20}
          >
            <Row className="g-3 m-0 px-2 bg-light">
              <Col>
                <h5 className="m-0">
                  {icon} {appreciationTitles[icon] || ''}
                </h5>
              </Col>
              <Col sm={6}>
                <AdvanceTableSearchBox
                  data={data}
                  table
                  placeholder="Buscar..."
                />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-light overflow-hidden text-900 text-nowrap align-middle"
              rowClassName="bg-white dark__bg-1100 white-space-nowrap btn-reveal-trigger hover-bg-100"
              tableProps={{
                className: 'fs--1 mb-0'
              }}
            />
            {!data.length && (
              <div className="bg-white dark__bg-1100 p-3 border-bottom border-200">
                <Alert variant="info" className="mb-0">
                  <h5 className="alert-heading">No hay respuestas</h5>
                </Alert>
              </div>
            )}
            {data.length > 20 && (
              <AdvanceTableFooter
                className="bg-light p-3"
                rowCount={data.length}
                table
                rowInfo
                navButtons
              />
            )}
          </AdvanceTableWrapper>
        </div>
      </Card.Body>
    </Card>
  );
};

const UsersAppreciations = () => {
  const { appreciations } = useContext(AppreciationContext);
  const { partners } = useContext(UserContext);
  const data = partners?.map(user => {
    const sentAppreciations =
      appreciations?.filter(({ senderId }) => senderId === user?.NO_ID_FIELD) ||
      [];
    const recievedAppreciations = appreciations.filter(
      ({ targetId }) => targetId === user?.NO_ID_FIELD
    );
    const sent = Object.entries(groupBy(sentAppreciations, 'icon')).map(
      ([icon, appreciations]) => ({
        icon,
        total: appreciations.reduce((total, { value }) => total + value, 0)
      })
    );
    const totalSent = sentAppreciations.reduce(
      (total, { value }) => total + value,
      0
    );
    const recieved = Object.entries(groupBy(recievedAppreciations, 'icon')).map(
      ([icon, appreciations]) => ({
        icon,
        total: appreciations.reduce((total, { value }) => total + value, 0)
      })
    );
    const totalRecieved = recievedAppreciations.reduce(
      (total, { value }) => total + value,
      0
    );
    return {
      user,
      sent,
      recieved,
      totalSent,
      totalRecieved
    };
  });
  const columns = [
    {
      accessor: 'name',
      Header: 'Nombre',
      cellProps: {
        className: 'w-0 border-end-0'
      },
      Cell: rowData => {
        const { user } = rowData.row.original;
        return <UserCell user={user} />;
      }
    },
    {
      accessor: 'totalSent',
      Header: 'Enviados',
      cellProps: {
        className: 'w-0 border-end-0'
      },
      Cell: rowData => {
        const { sent } = rowData.row.original;
        if (!sent.length) {
          return <h5 className="text-danger">0</h5>;
        }

        return (
          <>
            {sent.map(({ icon, total }, index) => (
              <h5 key={`Total-${icon}-${index}`}>
                {total} {icon}
              </h5>
            ))}
          </>
        );
      }
    },
    {
      accessor: 'totalRecieved',
      Header: 'Recibidos',
      cellProps: {
        className: 'w-0 border-end-0'
      },
      Cell: rowData => {
        const { recieved } = rowData.row.original;
        if (!recieved.length) {
          return <h5 className="text-danger">0</h5>;
        }

        return (
          <>
            {recieved.map(({ icon, total }, index) => (
              <h5 key={`Total-${icon}-${index}`}>
                {total} {icon}
              </h5>
            ))}
          </>
        );
      }
    }
  ];

  return (
    <Card>
      <Card.Body className="p-0">
        <div className="rounded-3 overflow-hidden">
          <AdvanceTableWrapper
            columns={columns}
            data={data}
            sortable
            pagination
            perPage={20}
          >
            <Row className="g-3 m-0 px-2 bg-light">
              <Col>
                <h5 className="m-0">Reconocimientos por usuario</h5>
              </Col>
              <Col sm={6}>
                <AdvanceTableSearchBox
                  data={data}
                  table
                  placeholder="Buscar..."
                />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-light overflow-hidden text-900 text-nowrap align-middle"
              rowClassName="bg-white white-space-nowrap btn-reveal-trigger hover-bg-100"
              tableProps={{
                className: 'fs--1 mb-0'
              }}
            />
            {data.length > 20 && (
              <AdvanceTableFooter
                className="bg-light p-3"
                rowCount={data.length}
                table
                rowInfo
                navButtons
              />
            )}
          </AdvanceTableWrapper>
        </div>
      </Card.Body>
    </Card>
  );
};

const AppreciationsGroup = ({ icon, appreciations }) => {
  return (
    <>
      <div className="mt-3">
        <AllAppreciations icon={icon} appreciations={appreciations} />
      </div>
    </>
  );
};

const AdminAppreciations = () => {
  const { appreciations = [] } = useContext(AppreciationContext);
  const appreciationsByIcon = groupBy(appreciations, 'icon');

  return (
    <>
      <div className="mt-3">
        <UsersAppreciations />
      </div>
      {Object.entries(appreciationsByIcon).map(
        ([icon, appreciations], index) => (
          <AppreciationsGroup
            key={`Appreciations-${index}`}
            icon={icon}
            appreciations={appreciations}
          />
        )
      )}
    </>
  );
};

AllAppreciations.propTypes = {
  icon: PropTypes.string,
  appreciations: PropTypes.array
};
AppreciationsGroup.propTypes = {
  icon: PropTypes.string,
  appreciations: PropTypes.array
};

export default AdminAppreciations;
