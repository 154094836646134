import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import GroupMember from './GroupMember';
import Tooltip from 'components/common/Tooltip';
import { UserContext } from 'context/Context';

const KanbanHeader = ({ title, itemCount, help }) => {
  const { partners = [] } = useContext(UserContext);
  return (
    <Row className="gx-0 kanban-header rounded-2 px-x1 py-2 mt-2 mb-3">
      <Col className="d-flex align-items-center">
        <h5 className="mb-0">{title}</h5>
        {typeof itemCount !== 'undefined' && (
          <h5 className="fs-0 mb-0">
            <span className="text-500 ms-1">({itemCount})</span>
          </h5>
        )}
        {help && (
          <Tooltip title={help} placement="bottom">
            <FontAwesomeIcon icon="question-circle" className="mx-2" />
          </Tooltip>
        )}
        <div className="vertical-line vertical-line-400 position-relative h-100 mx-3"></div>
        <GroupMember
          avatarSize="l"
          users={partners.filter(({ type }) => type === 'admin')}
          showMember={4}
          className="d-none d-md-block"
        />
      </Col>
    </Row>
  );
};

KanbanHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  itemCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  help: PropTypes.string
};
export default KanbanHeader;
