import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { collection, limit, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import Flex from 'components/common/Flex';
import QuestionaireIntro from './QuestionaireIntro';
import QuestionaireReward from './QuestionaireReward';
import Questionaire from './Questionaire';
import QuestionaireOutcome from './QuestionaireOutcome';
import HappyQuestionaireProvider from './HappyQuestionaireProvider';
import {
  HappyQuestionairesContext,
  OnBoardingContext,
  UserContext
} from 'context/Context';

const HappyQuestionaireViewerContent = () => {
  let { finishHappyQuestionaire } = useContext(HappyQuestionairesContext);
  const [readOnly, setReadOnly] = useState(false);
  const { setStep } = useContext(OnBoardingContext);
  const { company, me: user } = useContext(UserContext);
  const navigate = useNavigate();
  const db = useFirestore();
  const { apps = [] } = company || {};
  const { lastQuestionnaireAt } = user;
  const lastQuestionnaireDate = new Date(lastQuestionnaireAt);
  const isRewardable =
    apps.includes('questionaires') &&
    (!lastQuestionnaireAt ||
      lastQuestionnaireDate.getMonth() !== new Date().getMonth());

  const defaultsRef = query(
    collection(db, 'defaults'),
    where('current', '==', true),
    limit(1)
  );
  const { data: defaults = [] } = useFirestoreCollectionData(defaultsRef);
  const { happy_questionaire: questionaire = {} } = defaults?.[0] || {};
  const { handleSubmit, ...rest } = useForm();

  const onSubmitData = async data => {
    setReadOnly(true);
    const answers = questionaire?.definitions?.map(
      (def, index) => data[`entry-${index}`]
    );

    if (
      answers.some((response, index) => {
        const isUndefined = typeof response === 'undefined';
        if (isUndefined) {
          const url = `/welcome/#entry-${index}`;
          navigate(url);
          setReadOnly(false);
        }
        return isUndefined;
      })
    ) {
      return;
    }
    await finishHappyQuestionaire({
      definitions: questionaire?.definitions,
      answers,
      user
    });

    setStep(step => step + 1);
  };

  return (
    <Row as={Flex} justifyContent="center" className="g-3">
      <Col xs={12} xl={6}>
        <Form onSubmit={handleSubmit(onSubmitData)}>
          <QuestionaireIntro questionaire={questionaire} user={user} />
          {isRewardable && <QuestionaireReward />}
          <Questionaire {...rest} questionaire={questionaire} />
          <QuestionaireOutcome
            questionaire={questionaire}
            readOnly={readOnly}
            isRewardable={isRewardable}
          />
        </Form>
      </Col>
    </Row>
  );
};

const HappyQuestionaireViewer = () => {
  return (
    <HappyQuestionaireProvider>
      <HappyQuestionaireViewerContent />
    </HappyQuestionaireProvider>
  );
};

export default HappyQuestionaireViewer;
