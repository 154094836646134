import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SimpleBarReact from 'simplebar-react';
import { Badge } from 'react-bootstrap';
import ChatContentBodyIntro from './ChatContentBodyIntro';
import Message from './message';
import Flex from 'components/common/Flex';
import Loader from 'components/common/Loader';
import { ChatContext, UserContext } from 'context/Context';
import dayjs from 'dayjs';
import useVisibilityObserver from 'hooks/useVisibilityObserver';
import calendar from 'dayjs/plugin/calendar';

dayjs.extend(calendar);

const ChatContentBody = ({ chat, className }) => {
  const { me, resetUnreadMessages } = useContext(UserContext);
  const { unreadMessages } = me || {};
  const { unread = 0 } =
    unreadMessages?.find(({ ref }) => ref?.path === chat?.ref?.path) || {};
  let lastDate = null;
  let lastUser = null;
  const prevFirstMessageRef = useRef();
  const messagesEndRef = useRef();
  const loadingRef = useRef();
  const {
    currentChat,
    fetchMoreMessages,
    hasAllMessagesLoaded,
    messages = [],
    scrollToBottom
  } = useContext(ChatContext);
  const { NO_ID_FIELD: currentChatId } = currentChat || {};
  const { isVisible: isAtBottom, observer: bottomObserver } =
    useVisibilityObserver(messagesEndRef);
  const { isVisible: inViewport, observer: loadingObserver } =
    useVisibilityObserver(loadingRef, '100px');

  useEffect(() => {
    return () => {
      bottomObserver &&
        messagesEndRef.current &&
        bottomObserver.unobserve(messagesEndRef.current);
    };
  }, [bottomObserver]);

  useEffect(() => {
    return () => {
      loadingObserver &&
        loadingRef.current &&
        loadingObserver.unobserve(loadingRef.current);
    };
  }, [loadingObserver]);

  useEffect(() => {
    let mounted = true;
    setTimeout(() => {
      mounted && currentChatId && inViewport && fetchMoreMessages();
    }, 100);
    return () => {
      mounted = false;
    };
  }, [inViewport, currentChatId, messages.length]);

  useEffect(() => {
    resetUnreadMessages(chat);
  }, [resetUnreadMessages]);

  useEffect(() => {
    messagesEndRef?.current?.scrollIntoView();

    return () => {
      resetUnreadMessages(chat);
    };
  }, []);

  useEffect(() => {
    if (isAtBottom || !prevFirstMessageRef.current) {
      messagesEndRef?.current?.scrollIntoView();
      setTimeout(() => messagesEndRef?.current?.scrollIntoView(), 100);
      setTimeout(() => messagesEndRef?.current?.scrollIntoView(), 200);
    } else {
      prevFirstMessageRef?.current?.scrollIntoView();
      setTimeout(() => prevFirstMessageRef?.current?.scrollIntoView(), 100);
    }
  }, [scrollToBottom, messages.length]);

  return (
    <Flex className={classNames('', className)} style={{ display: 'inherit' }}>
      <SimpleBarReact className="w-100 h-100">
        {!messages.length && (
          <>
            <ChatContentBodyIntro chat={chat} />
            <div className="flex-grow-1" />
          </>
        )}

        <div ref={loadingRef} className="pt-3">
          {!hasAllMessagesLoaded && <Loader />}
        </div>

        {messages?.map((message, index) => {
          const { from, createdAt } = message;
          let date = dayjs(createdAt)
            .calendar(null, {
              sameDay: '[Hoy]',
              lastDay: '[Ayer]',
              lastWeek: 'dddd',
              sameElse: 'ddd, D MMM'
            })
            .replace('.', '');
          const year = new Date(createdAt).getFullYear();
          date = year === new Date().getFullYear() ? date : `${date} ${year}`;
          return (
            <div
              key={`Message-${message.NO_ID_FIELD}`}
              ref={index === 9 ? prevFirstMessageRef : null}
            >
              {date !== lastDate && (
                <div className="text-center sticky-top mt-3">
                  <Badge pill bg="secondary">
                    {date}
                  </Badge>
                </div>
              )}
              {index === messages.length - unread && (
                <div className="bg-light mt-2 mb-3 p-1 text-center">
                  <Badge
                    pill
                    bg="white"
                    text="dark"
                    className="p-2 fs--1 fw-normal"
                  >
                    Tienes {unread} mensajes por leer
                  </Badge>
                </div>
              )}
              <Message
                chat={chat}
                message={message}
                next={messages?.[index + 1]}
                isSameDate={date === lastDate}
                isSameUser={from?.path === lastUser?.path}
              />
              {(() => {
                lastDate = date;
                lastUser = from;
              })()}
            </div>
          );
        })}
        <div ref={messagesEndRef} className="p-1" />
      </SimpleBarReact>
    </Flex>
  );
};

ChatContentBody.propTypes = {
  chat: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default ChatContentBody;
