import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { UserContext } from 'context/Context';
import ProfileBanner from '../Banner';
import ProfileDescription from './ProfileDescription';
import { Card, Col, Row, Tab } from 'react-bootstrap';
import Totals from 'components/charts/Totals';
import IndexRadar from 'components/charts/IndexRadar';
import IndexCharts from 'components/charts/IndexCharts';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FilesProvider from 'components/company/files/FilesProvider';
import Events from 'components/company/events';
import Objectives from 'components/company/objectives';
import Alerts from 'components/dashboards/Alerts';
import TimeOff from 'components/user/time-off';
import Education from './Education';
import Experiences from './Experiences';
import DepartmentPartners from './DepartmentPartners';
import DangerZone from './DangerZone';
import { FilesContent } from 'components/user/files';
import BenefitsProvider from 'components/benefits/BenefitsProvider';
import ActiveBenefits from '../benefits/ActiveBenefits';
import Compatibility from './Compatibility';
import Interests from './Interests';
import Schedule from './Schedule';
import Skills from './Skills';
import Pros from './Pros';
import Cons from './Cons';
import Personality from './Personality';
import Sizes from './Sizes';
import ProfileSettings from './ProfileSettings';
import AccountSettings from './AccountSettings';
import ChangePassword from './ChangePassword';

const AdminContent = ({ user }) => {
  const { me } = useContext(UserContext);
  const { NO_ID_FIELD: userId } = user || {};
  return (
    <Tab.Content className="mb-3">
      <Tab.Pane eventKey="profile" title="Perfil">
        <Row className="g-3">
          <Col lg={8}>
            <ProfileSettings className="mb-3" user={user} />
            {(user?.profile?.description ||
              (me?.type === 'admin' && user?.profile?.compatibility)) && (
              <Row className="g-3 mb-3">
                <Col md={true}>
                  {user?.profile?.description && (
                    <ProfileDescription className="h-100" user={user} />
                  )}
                </Col>
                {me?.type === 'admin' && user?.profile?.compatibility && (
                  <Col md={3}>
                    <Compatibility user={user} />
                  </Col>
                )}
              </Row>
            )}
            {user?.profile?.skills && (
              <Skills className="mb-3" color="primary" user={user} />
            )}
            {user?.profile?.experiences && (
              <Experiences className="mb-3" user={user} />
            )}
            {user?.profile?.education && <Education user={user} />}
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar">
              {me?.type === 'admin' && (
                <Alerts
                  bodyClassName="bg-light"
                  className="mb-3 overflow-hidden"
                  header={<FalconCardHeader title="Alertas" />}
                  showType={true}
                  user={user}
                />
              )}
              <AccountSettings user={user} />
              <ChangePassword user={user} />
            </div>
          </Col>
        </Row>
      </Tab.Pane>

      <Tab.Pane eventKey="personal" title="Personal">
        <div className="mt-3">
          <Interests className="mb-3" user={user} />

          {me?.type === 'admin' && (
            <>
              <Personality className="mb-3" color="info" user={user} />
              <Row className="g-3 mb-3">
                <Col md={6}>
                  <Pros className="h-100" color="success" user={user} />
                </Col>
                <Col md={6}>
                  <Cons className="h-100" color="danger" user={user} />
                </Col>
              </Row>
            </>
          )}
          <Sizes className="mb-3" user={user} />
        </div>
      </Tab.Pane>

      <Tab.Pane eventKey="benefits" title="Beneficios">
        <Card className="mt-3">
          <FalconCardHeader title="Beneficios activos de Happyfy" light />
          <ActiveBenefits user={user} />
        </Card>
      </Tab.Pane>

      <Tab.Pane eventKey="documents" title="Documentos">
        <div className="mt-3">
          <FilesProvider>
            <FilesContent userId={userId} offsetPx={-150} />
          </FilesProvider>
        </div>
      </Tab.Pane>

      <Tab.Pane eventKey="schedule" title="Horario">
        <div className="mt-3">
          <Schedule className="h-100" user={user} />
        </div>
      </Tab.Pane>

      <Tab.Pane eventKey="events" title="Eventos">
        <div className="mt-3">
          <Events userId={userId} />
        </div>
      </Tab.Pane>

      <Tab.Pane eventKey="objectives" title="Objetivos">
        <div className="mt-3">
          <Objectives userId={userId} />
        </div>
      </Tab.Pane>

      <Tab.Pane eventKey="time-off" title="Ausencias">
        <div className="mt-3">
          <TimeOff userId={userId} />
        </div>
      </Tab.Pane>

      <Tab.Pane eventKey="analytics" title="Analíticas">
        <Row className="g-3 mb-3">
          <Col xs={12} sm={6}>
            <Totals
              className="h-100"
              header={<FalconCardHeader title="Felicidad" light />}
              type="happiness"
              user={user}
            />
          </Col>
          <Col xs={12} sm={6}>
            <IndexRadar
              user={user}
              header={
                <FalconCardHeader title="Estado actual del empleado" light />
              }
            />
          </Col>
        </Row>
        <IndexCharts bodyClassName="min-h-200px" user={user} />
      </Tab.Pane>
    </Tab.Content>
  );
};

AdminContent.propTypes = {
  user: PropTypes.object
};

const EmployeeContent = ({ user }) => {
  const { profile = {} } = user || {};
  const { description, education, experience, skills } = profile;

  return (
    <>
      {description && <ProfileDescription className="mt-3" user={user} />}
      {skills && <Skills className="mt-3" color="primary" user={user} />}
      {experience && <Experiences className="mt-3" user={user} />}
      {education && <Education className="mt-3" user={user} />}
    </>
  );
};

EmployeeContent.propTypes = {
  user: PropTypes.object
};

const Profile = () => {
  const { company = {}, me, partners = [] } = useContext(UserContext);
  const { userId = me?.NO_ID_FIELD, key = 'profile' } = useParams();
  const [activeKey, setActiveKey] = useState(key);

  const user = partners.find(({ id }) => id === userId) || me;
  const { department } = user || {};

  const handleSelect = key => {
    setActiveKey(key);
  };

  useEffect(() => {
    setActiveKey(key);
  }, [key]);

  return (
    <>
      <Tab.Container
        transition={false}
        activeKey={activeKey}
        className="border-0 position-relative mt-n6 px-3 pb-2"
        onSelect={handleSelect}
        mountOnEnter
      >
        <ProfileBanner company={company} user={user} />

        {me?.type === 'admin' || me?.NO_ID_FIELD === userId ? (
          <AdminContent user={user} />
        ) : (
          <EmployeeContent user={user} />
        )}

        <Row className="g-3 mt-0">
          <Col className="mt-0">
            <DepartmentPartners department={department} />
          </Col>
          {me?.type === 'admin' && (
            <Col className="mt-0" xs={12} md={4}>
              <DangerZone />
            </Col>
          )}
        </Row>
      </Tab.Container>
    </>
  );
};

const ProfileWithBenefits = props => {
  const { userId } = useParams();
  return (
    <BenefitsProvider userId={userId}>
      <Profile {...props} />
    </BenefitsProvider>
  );
};

export default ProfileWithBenefits;
