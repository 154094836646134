import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { ProductContext } from 'context/Context';

export const ProductsPagination = ({ pagination, setProductsPerPage }) => {
  const { isLoading } = useContext(ProductContext);
  const {
    paginationState: {
      totalItems,
      itemsPerPage,
      currentPage,
      canNextPage,
      canPreviousPage,
      paginationArray
    },
    nextPage,
    prevPage,
    goToPage,
    setItemsPerPage
  } = pagination;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <>
      {canNextPage && (
        <Card>
          <Card.Body>
            <Row className="g-3 flex-center justify-content-sm-between">
              <Col xs="auto" as={Flex} alignItems="center">
                <small className="d-none d-lg-block me-2">Mostrar:</small>
                <Form.Select
                  size="sm"
                  value={itemsPerPage}
                  onChange={({ target }) => {
                    setItemsPerPage(target.value);
                    setProductsPerPage(target.value);
                  }}
                  style={{ maxWidth: '4.875rem' }}
                >
                  <option value={24}>24</option>
                  <option value={60}>60</option>
                  <option value={120}>120</option>
                  <option value={totalItems}>All</option>
                </Form.Select>
              </Col>
              <Col xs="auto" as={Flex}>
                <div>
                  <Button
                    variant="falcon-default"
                    size="sm"
                    disabled={!canPreviousPage}
                    onClick={prevPage}
                    className="me-2"
                    trigger="focus"
                  >
                    <FontAwesomeIcon icon="chevron-left" />
                  </Button>
                </div>

                <ul className="pagination mb-0">
                  {paginationArray.slice(0, 3).map(page => (
                    <li
                      key={page}
                      className={classNames({
                        active: currentPage === page
                      })}
                    >
                      <Button
                        size="sm"
                        variant="falcon-default"
                        className="page me-2"
                        onClick={() => goToPage(page)}
                      >
                        {page}
                      </Button>
                    </li>
                  ))}
                  {paginationArray.length - 3 > 3 && (
                    <>
                      <span className="me-2">...</span>
                      {paginationArray
                        .slice(paginationArray.length - 3)
                        .map(page => (
                          <li
                            key={page}
                            className={classNames({
                              active: currentPage === page
                            })}
                          >
                            <Button
                              size="sm"
                              variant="falcon-default"
                              className="page me-2"
                              onClick={() => goToPage(page)}
                            >
                              {page}
                            </Button>
                          </li>
                        ))}
                    </>
                  )}
                </ul>
                {isLoading && (
                  <div>
                    <Button
                      variant="falcon-default"
                      className="me-2"
                      disabled={true}
                      size="sm"
                    >
                      <Spinner
                        size="sm"
                        variant="primary"
                        style={{
                          width: '1rem',
                          height: '1rem',
                          marginTop: '2px'
                        }}
                      />
                    </Button>
                  </div>
                )}
                <div>
                  <Button
                    variant="falcon-default"
                    size="sm"
                    disabled={!canNextPage}
                    onClick={nextPage}
                    trigger="focus"
                  >
                    <FontAwesomeIcon icon="chevron-right" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

ProductsPagination.propTypes = {
  pagination: PropTypes.object,
  setProductsPerPage: PropTypes.func
};

export default ProductsPagination;
