import React, { useContext } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import BubbleChart from 'components/common/BubbleChart';
import { getColor } from 'helpers/utils';
import { ReportsContext } from 'context/Context';

dayjs.locale('es');
registerLocale('es', es);
setDefaultLocale('es');

const articles = ['el', 'la', 'los', 'las', 'un', 'una', 'unos', 'unas'];
const conjunctions = ['y', 'o', 'pero', 'que', 'porque', 'si', 'cuando'];
const prepositions = [
  'a',
  'ante',
  'bajo',
  'cabe',
  'con',
  'contra',
  'de',
  'desde',
  'durante',
  'en',
  'entre',
  'hacia',
  'hasta',
  'mediante',
  'para',
  'por',
  'según',
  'sin',
  'so',
  'sobre',
  'tras',
  'versus',
  'vía'
];
const pronouns = [
  'yo',
  'tú',
  'él',
  'nosotros',
  'ustedes',
  'ellos',
  'que',
  'quien',
  'me',
  'mi',
  'su'
];
const other = [
  ' ',
  '.',
  ',',
  'del',
  'mas',
  'muy',
  'si',
  'no',
  'nos',
  'nada',
  'más',
  'se',
  'ya',
  ')',
  '(',
  'esta',
  'este',
  'esto',
  'esa',
  'ese',
  'eso',
  'etc'
];
const wordBlackList = [
  ...articles,
  ...conjunctions,
  ...pronouns,
  ...prepositions,
  ...other
];

const FeedbackTrendingWords = () => {
  const { feedback: raw = [] } = useContext(ReportsContext);
  const data = Object.entries(
    raw
      .filter(({ feedback }) => feedback)
      .reduce((total, entry) => {
        const { feedback } = entry;
        const words = `${feedback}`
          .replace(/[.,]/g, ' ')
          .split(' ')
          .filter(
            word =>
              word &&
              word.length > 1 &&
              word.length < 23 &&
              !wordBlackList.includes(word.toLowerCase())
          )
          .map(word => ({
            [word.toLowerCase()]: (total?.[word.toLowerCase()] || 0) + 1
          }))
          .reduce((words, word) => {
            const [key, value] = Object.entries(word)?.[0] || [];
            return { ...words, [key]: value };
          }, {});
        return { ...total, ...words };
      }, {})
  )
    .filter(([name, value]) => name && value > 1)
    .map(([name, v]) => {
      return { name, v, color: getColor('blue') };
    })
    .sort(({ v: v1 }, { v: v2 }) => (v1 > v2 ? -1 : 1))
    .splice(0, 40);

  if (!data?.length) {
    return null;
  }

  return (
    <div className="mt-n4 mb-4">
      <BubbleChart data={data} />
    </div>
  );
};

export default FeedbackTrendingWords;
