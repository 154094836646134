import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { Alert, Card } from 'react-bootstrap';
import { BenefitsContext, UserContext } from 'context/Context';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Avatar from 'components/common/Avatar';
import SoftBadge from 'components/common/SoftBadge';

const columns = [
  {
    accessor: 'name',
    cellProps: (none, { cell }) => {
      const { active } = cell.row.original;
      return {
        className: classNames('white-space-nowrap', { 'bg-light': !active }),
        style: { maxWidth: '23rem' }
      };
    },
    Cell: rowData => {
      const {
        active,
        color = 'bg-primary',
        logo,
        icon,
        name
      } = rowData.row.original;

      return (
        <Flex
          alignItems="center"
          className={classNames('gap-3 position-relative', {
            'opacity-50': !active
          })}
        >
          <Avatar
            mediaClass={classNames({
              [`align-items-center justify-content-center fs-0`]: icon,
              [`${color}`]: !logo
            })}
            src={logo}
            icon={icon}
            name={(!icon && name) || undefined}
          />
          <h6 className="m-0">{name}</h6>
          {active && (
            <SoftBadge
              pill
              bg={classNames({
                secondary: active === 'default',
                primary: active === 'company',
                accent: active === 'user'
              })}
            >
              {classNames({
                'Activa en tu plan': active === 'default',
                'Activa para toda la empresa': active === 'company',
                'Activa para ti': active === 'user'
              })}
            </SoftBadge>
          )}
        </Flex>
      );
    }
  },
  {
    accessor: 'active',
    headerProps: {
      className: 'fw-medium text-end'
    },
    cellProps: (none, { cell }) => {
      const { active } = cell.row.original;
      return {
        className: classNames('text-end', { 'bg-light': !active }),
        style: { maxWidth: '23rem' }
      };
    },
    Cell: rowData => {
      const { active } = rowData.row.original;
      return (
        <Avatar
          className={classNames({
            'bg-transparent': active,
            'bg-light opacity-50': !active
          })}
          mediaClass={classNames(
            'align-items-center justify-content-center fs-0',
            {
              'bg-transparent text-success': active,
              'bg-light text-secondary': !active
            }
          )}
          icon={classNames({ check: active, minus: !active })}
        />
      );
    }
  }
];

const ActiveBenefits = ({ bodyClassName, user }) => {
  const { all = [] } = useContext(BenefitsContext);
  const { company, me } = useContext(UserContext);
  const { benefits: userBenefits = [] } = user || me || {};
  const { benefits: companyBenefits = [] } = company || {};
  const benefits = all.map(data => {
    return {
      ...data,
      active:
        data?.active ||
        (companyBenefits.includes(data?.NO_ID_FIELD) && 'company') ||
        (userBenefits.includes(data?.NO_ID_FIELD) && 'user')
    };
  });

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={benefits}
        pagination
        perPage={20}
        refresh={true}
      >
        <Card.Body
          className={classNames(bodyClassName, {
            'p-0': benefits.length
          })}
        >
          {!benefits.length ? (
            <Alert variant="info">
              <h5 className="alert-heading">No hay beneficios</h5>
            </Alert>
          ) : (
            <AdvanceTable
              table
              headerClassName="d-none"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          )}
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={benefits.length}
            table
            rowInfo
            navButtons
          />
        </Card.Footer>
      </AdvanceTableWrapper>
    </>
  );
};

ActiveBenefits.propTypes = {
  bodyClassName: PropTypes.string,
  user: PropTypes.object
};

export default ActiveBenefits;
