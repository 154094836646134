import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';

const OptionsQuestion = ({
  id,
  extra = {},
  setValue = () => {},
  watch = () => {},
  readOnly
}) => {
  let { maxResponses = 1, options = [] } = extra;
  options = options.filter(option => option);

  const reponse = watch(id);
  const active = reponse?.split('|||');

  const handleSelect = value => {
    const response = [
      ...(active?.filter(val => val !== value) || []),
      !active?.includes(value) && value
    ]
      .filter(resp => resp)
      .splice(0, maxResponses)
      .join('|||');
    setValue(id, response);
  };

  return (
    <div id={id}>
      {options.map((option, index) => {
        return (
          <div
            key={`Option-${id}-${index}`}
            className="d-grid d-sm-inline-block"
          >
            <Button
              className="m-1"
              disabled={readOnly}
              variant={active?.includes(option) ? 'primary' : 'outline-primary'}
              onClick={() => handleSelect(option)}
            >
              {option}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

const TextQuestion = ({ id, readOnly, register = () => {} }) => {
  return (
    <Form.Control
      id={id}
      as="textarea"
      placeholder="Escribe tu respuesta"
      disabled={readOnly}
      {...register(id)}
    />
  );
};

const VoteQuestion = ({
  id,
  extra = {},
  setValue = () => {},
  watch = () => {},
  readOnly
}) => {
  const { min = {}, max = {} } = extra;
  const { value: minValue = 0, title: minTitle = '' } = min;
  const { value: maxValue = 0, title: maxTitle = '' } = max;

  const active = watch(id);

  const handleVote = value => {
    setValue(id, value);
  };

  return (
    <div id={id}>
      <Flex alignItems="center">
        <span>{minValue}</span>
        <FontAwesomeIcon icon="arrow-right" className="m-1 fs--1" />
        <span>{minTitle}</span>
      </Flex>
      <Flex alignItems="center">
        <span>{maxValue}</span>
        <FontAwesomeIcon icon="arrow-right" className="m-1 fs--1" />
        <span>{maxTitle}</span>
      </Flex>
      <Flex className="mt-3" wrap="wrap">
        {new Array(maxValue - minValue + 1).fill(0).map((x, index) => {
          const value = parseInt(minValue) + index;
          return (
            <Button
              key={`Value-${id}-${index}`}
              className="m-1"
              disabled={readOnly}
              variant={value === active ? 'primary' : 'outline-primary'}
              onClick={() => handleVote(value)}
            >
              {value}
            </Button>
          );
        })}
      </Flex>
    </div>
  );
};

const questionTypes = {
  options: OptionsQuestion,
  text: TextQuestion,
  vote: VoteQuestion
};

const Question = props => {
  const { me } = useContext(UserContext);
  const { question, type } = props;
  const QuestionType = questionTypes[type] || null;

  return (
    <Card
      className={classNames('mb-3', {
        'shadow-none': me?.type === 'admin' || type === 'divider',
        'bg-transparent': type === 'divider'
      })}
    >
      <Card.Header
        className={classNames({
          'pb-0': !!QuestionType,
          'mt-3': type === 'divider',
          'px-0': type === 'divider' && me?.type !== 'admin'
        })}
      >
        <h5
          className={classNames('mb-0 lh-base', {
            'fs-0 fw-normal': type !== 'divider',
            'border-bottom pb-3': type === 'divider'
          })}
        >
          {question}
        </h5>
      </Card.Header>
      {QuestionType && (
        <Card.Body className={classNames({ 'pt-3': !!QuestionType })}>
          <QuestionType {...props} />
        </Card.Body>
      )}
    </Card>
  );
};

const Questionaire = ({ questionaire, readOnly, ...rest }) => {
  const { definitions } = questionaire;
  return (
    <>
      {definitions?.map((item, index) => (
        <Question
          key={`Question-${index}`}
          id={`entry-${index}`}
          readOnly={readOnly}
          {...item}
          {...rest}
        />
      ))}
    </>
  );
};

OptionsQuestion.propTypes = {
  id: PropTypes.string,
  extra: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  readOnly: PropTypes.bool
};

TextQuestion.propTypes = {
  id: PropTypes.string,
  register: PropTypes.func,
  readOnly: PropTypes.bool
};

VoteQuestion.propTypes = {
  id: PropTypes.string,
  extra: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  readOnly: PropTypes.bool
};

Question.propTypes = {
  question: PropTypes.string,
  type: PropTypes.string
};

Questionaire.propTypes = {
  questionaire: PropTypes.object,
  readOnly: PropTypes.bool
};

export default Questionaire;
