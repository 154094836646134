export const shirts = [
  { label: 'Camiseta S', value: 'Camiseta S' },
  { label: 'Camiseta M', value: 'Camiseta M' },
  { label: 'Camiseta L', value: 'Camiseta L' },
  { label: 'Camiseta XL', value: 'Camiseta XL' },
  { label: 'Camiseta 2XL', value: 'Camiseta 2XL' },
  { label: 'Camiseta 3XL', value: 'Camiseta 3XL' }
];

export const hoodies = [
  { label: 'Sudadera S', value: 'Sudadera S' },
  { label: 'Sudadera M', value: 'Sudadera M' },
  { label: 'Sudadera L', value: 'Sudadera L' },
  { label: 'Sudadera XL', value: 'Sudadera XL' },
  { label: 'Sudadera 2XL', value: 'Sudadera 2XL' },
  { label: 'Sudadera 3XL', value: 'Sudadera 3XL' }
];

export const trousers = [
  { label: 'Pantalones 38', value: 'Pantalones 38' },
  { label: 'Pantalones 40', value: 'Pantalones 40' },
  { label: 'Pantalones 42', value: 'Pantalones 42' },
  { label: 'Pantalones 44', value: 'Pantalones 44' },
  { label: 'Pantalones 46', value: 'Pantalones 46' },
  { label: 'Pantalones 48', value: 'Pantalones 48' },
  { label: 'Pantalones 50', value: 'Pantalones 50' },
  { label: 'Pantalones 52', value: 'Pantalones 52' },
  { label: 'Pantalones 54', value: 'Pantalones 54' },
  { label: 'Pantalones 56', value: 'Pantalones 56' },
  { label: 'Pantalones 58', value: 'Pantalones 58' }
];

export const shorts = [
  { label: 'Bermudas 38', value: 'Bermudas 38' },
  { label: 'Bermudas 40', value: 'Bermudas 40' },
  { label: 'Bermudas 42', value: 'Bermudas 42' },
  { label: 'Bermudas 44', value: 'Bermudas 44' },
  { label: 'Bermudas 46', value: 'Bermudas 46' },
  { label: 'Bermudas 48', value: 'Bermudas 48' },
  { label: 'Bermudas 50', value: 'Bermudas 50' },
  { label: 'Bermudas 52', value: 'Bermudas 52' },
  { label: 'Bermudas 54', value: 'Bermudas 54' },
  { label: 'Bermudas 56', value: 'Bermudas 56' },
  { label: 'Bermudas 58', value: 'Bermudas 58' }
];

export const securityShoes = [
  { label: 'Botas de seguridad 35', value: 'Botas de seguridad 35' },
  { label: 'Botas de seguridad 36', value: 'Botas de seguridad 36' },
  { label: 'Botas de seguridad 37', value: 'Botas de seguridad 37' },
  { label: 'Botas de seguridad 38', value: 'Botas de seguridad 38' },
  { label: 'Botas de seguridad 39', value: 'Botas de seguridad 39' },
  { label: 'Botas de seguridad 40', value: 'Botas de seguridad 40' },
  { label: 'Botas de seguridad 41', value: 'Botas de seguridad 41' },
  { label: 'Botas de seguridad 42', value: 'Botas de seguridad 42' },
  { label: 'Botas de seguridad 43', value: 'Botas de seguridad 43' },
  { label: 'Botas de seguridad 44', value: 'Botas de seguridad 44' },
  { label: 'Botas de seguridad 45', value: 'Botas de seguridad 45' },
  { label: 'Botas de seguridad 46', value: 'Botas de seguridad 46' },
  { label: 'Botas de seguridad 47', value: 'Botas de seguridad 47' },
  { label: 'Botas de seguridad 48', value: 'Botas de seguridad 48' },
  { label: 'Botas de seguridad 49', value: 'Botas de seguridad 49' },
  { label: 'Botas de seguridad 50', value: 'Botas de seguridad 50' },
  { label: 'Botas de seguridad 52', value: 'Botas de seguridad 52' }
];

export const sizes = [
  {
    label: 'Camisetas',
    options: shirts
  },
  {
    label: 'Sudaderas',
    options: hoodies
  },
  {
    label: 'Pantalones',
    options: trousers
  },
  {
    label: 'Bermudas',
    options: shorts
  },
  {
    label: 'Botas de seguridad',
    options: securityShoes
  }
];

export default sizes;
