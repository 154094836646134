import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import PendingObjectivesContent from 'components/company/objectives/PendingObjectives';

const PendingObjectives = ({ className }) => {
  return (
    <Card className={className}>
      <Card.Header>
        <h6 className="m-0">Objetivos pendientes de revisar</h6>
      </Card.Header>
      <Card.Body className="pt-0 overflow-auto">
        <PendingObjectivesContent className="m-n3 h-100 shadow-none" />
      </Card.Body>
    </Card>
  );
};

PendingObjectives.propTypes = {
  className: PropTypes.string
};

export default PendingObjectives;
