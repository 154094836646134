import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from 'context/Context';
import { collection, limit, orderBy, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { RadarChart as RadarEChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor } from 'helpers/utils';
import types from 'components/dashboards/types.json';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  RadarEChart,
  CanvasRenderer,
  LegendComponent
]);

const tooltipFormatter = ({ labels, ...params }) => {
  return `<div class="fs--1 text-600">
    ${labels
      .map((key, index) => {
        const value = params.value[index];
        return `<div>
            <strong class="text-capitalize">
              ${types[key]}
            </strong>: ${value}
            <br />
          </div>`;
      })
      .join('')}
  </div>`;
};

const getOptions = indexes => ({
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: params =>
      tooltipFormatter({ labels: Object.keys(indexes), ...params })
  },
  radar: {
    splitNumber: 10,
    radius: '60%',
    axisLine: {
      show: true,
      symbol: 'circle',
      symbolSize: [8, 8],
      lineStyle: {
        color: getColor('gray-400')
      }
    },
    splitArea: {
      show: false
    },
    splitLine: {
      lineStyle: {
        color: getColor('gray-300')
      }
    },
    axisName: {
      color: getColor('gray-600'),
      fontWeight: 500
    },
    indicator: Object.keys(indexes).map(type => ({
      name: types[type].replace(/./, s => s.toUpperCase()),
      max: 100
    }))
  },
  series: [
    {
      type: 'radar',
      symbol: 'pin',
      data: [
        {
          value: Object.values(indexes),
          name: 'Indexes',
          areaStyle: {
            color: rgbaColor(getColor('primary'), 0.24)
          },
          symbol: 'circle',
          symbolSize: 8,
          itemStyle: {
            color: getColor('primary')
          }
        }
      ]
    }
  ],
  grid: {
    top: 0,
    bottom: '100px'
  }
});

const RadarChart = ({ user }) => {
  const { me } = useContext(UserContext);
  user = user || me;
  const db = useFirestore();
  let indexesQuery = query(collection(db, 'none'));
  if (user?.id) {
    const indexesCollection = collection(db, 'indexes');
    indexesQuery = Object.keys(types)
      .map(type => ({
        [type]: query(
          indexesCollection,
          where('companyId', '==', `${user?.company}`),
          where('type', '==', type),
          where('userId', '==', `${user?.id}`),
          orderBy('createdAt', 'desc'),
          limit(1)
        )
      }))
      .reduce((indexes, typeQuery) => ({ ...indexes, ...typeQuery }), {});
  }
  const { data: happiness } = useFirestoreCollectionData(
    indexesQuery.happiness || indexesQuery
  );
  const { data: environment } = useFirestoreCollectionData(
    indexesQuery.environment || indexesQuery
  );
  const { data: fellowship } = useFirestoreCollectionData(
    indexesQuery.fellowship || indexesQuery
  );
  const { data: motivation } = useFirestoreCollectionData(
    indexesQuery.motivation || indexesQuery
  );
  const { data: satisfaction } = useFirestoreCollectionData(
    indexesQuery.satisfaction || indexesQuery
  );
  const { data: stress } = useFirestoreCollectionData(
    indexesQuery.stress || indexesQuery
  );

  const indexes = {
    happiness: happiness?.[0]?.value,
    environment: environment?.[0]?.value,
    fellowship: fellowship?.[0]?.value,
    motivation: motivation?.[0]?.value,
    satisfaction: satisfaction?.[0]?.value,
    stress: stress?.[0]?.value
  };

  return (
    <>
      <ReactEChartsCore
        className="m-auto"
        echarts={echarts}
        option={getOptions(indexes)}
        style={{ height: '18rem', width: '24.30rem' }}
      />
    </>
  );
};

RadarChart.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node,
  user: PropTypes.object
};

export default RadarChart;
