import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import { getDownloadURL, ref } from '@firebase/storage';
import { useStorage } from 'reactfire';
import FalconLightBox from 'components/common/FalconLightBox';

const ImageMessage = ({ message }) => {
  const storage = useStorage();
  const [src, setSrc] = useState();
  const { NO_ID_FIELD, chatId, uploaded } = message;

  useMemo(() => {
    (async () => {
      const url = await getDownloadURL(
        ref(storage, `/chat/${chatId}/${NO_ID_FIELD}.jpeg`)
      );
      setSrc(url);
    })();
  }, [uploaded]);

  return (
    <FalconLightBox image={src}>
      <Image src={src} fluid rounded className="max-vh-25" />
    </FalconLightBox>
  );
};

ImageMessage.propTypes = {
  message: PropTypes.object
};

export default ImageMessage;
