import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor } from 'helpers/utils';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const tooltipFormatter = (params, title) => {
  const average = params[0]?.value || 0;
  const current = params[1]?.value || 0;
  const prev = params[2]?.value || 0;
  let diff = isNaN(prev) ? 0 : current - prev;
  let percentage = Math.min(
    100,
    Math.max(
      0,
      diff === 0 ? 0 : Math.abs(Math.round((diff * 100) / (prev || 1)))
    )
  );
  if (typeof params[2]?.value === 'undefined') {
    diff = isNaN(average) ? 0 : current - average;
    percentage = Math.min(
      100,
      Math.max(
        0,
        diff === 0 ? 0 : Math.abs(Math.round((diff * 100) / (average || 1)))
      )
    );
  }

  const color = diff < 0 ? 'danger' : 'success';
  const perTemp = `
    <div class="d-flex align-items-center ms-2">
      <h6 class="fs--2 mb-0 ms-1 fw-semi-bold text-${color}">
        <span style="color:${getColor(color)};">${
    diff < 0 ? '&#9660' : '&#9650'
  }</span> 
        ${Math.abs(percentage)} %
      </h6>
    </div>
  `;
  return `<div>
        <h5 class='mb-0 fs--2 text-600'>${title}</h5>
        <p class='mb-0 fs--2 text-600'>${params[1].axisValue} vs la media</p>
        <div class="d-flex align-items-center">
          <p class='mb-0 fs--1'>
            <h5 class='text-primary'>${current}</h5>
            ${prev ? `<h5 class='text-gray-400 fs--1'>${prev}</h5>` : ''}
          </p>
          ${percentage === 0 ? '' : perTemp}
        </div>
      </div>`;
};

const getOptions = ({ title, labels, data }) => ({
  color: getColor('white'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    },
    formatter: params => tooltipFormatter(params, title)
  },
  xAxis: {
    type: 'category',
    data: labels,
    axisLabel: {
      color: getColor('gray-600'),
      align: 'left',
      fontSize: 11,
      padding: [0, 0, 0, 5],
      showMaxLabel: false
    },
    axisLine: {
      lineStyle: {
        color: getColor('gray-200')
      }
    },
    axisTick: {
      show: true,
      length: 20,
      lineStyle: {
        color: getColor('gray-200')
      }
    },
    boundaryGap: false
  },
  yAxis: {
    position: 'right',
    axisPointer: { type: 'none' },
    axisTick: 'none',
    splitLine: {
      lineStyle: {
        color: getColor('gray-200')
      }
    },
    axisLine: { show: false },
    axisLabel: { color: getColor('gray-600') }
  },
  series: [
    {
      type: 'line',
      data: data?.[0],
      symbol: 'none',
      lineStyle: {
        width: 1,
        color: getColor('info')
      }
    },
    {
      type: 'line',
      data: data?.[1],
      symbol: 'circle',
      symbolSize: 10,
      showSymbol: data?.[1]?.length <= 1,
      smooth: true,
      animation: true,
      itemStyle: {
        backgroundColor: getColor('gray-100'),
        borderColor: getColor('primary'),
        borderWidth: 2
      },
      emphasis: {
        scale: true
      },
      lineStyle: {
        color: getColor('primary')
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    },
    data?.[2] && {
      type: 'line',
      data: data?.[2],
      symbol: 'circle',
      symbolSize: 10,
      showSymbol: data?.[2].length <= 1,
      smooth: true,
      animation: true,
      itemStyle: {
        backgroundColor: getColor('gray-100'),
        borderColor: getColor('gray-400'),
        borderWidth: 1
      },
      emphasis: {
        scale: true
      },
      lineStyle: {
        color: getColor('gray-400'),
        type: 'dashed',
        width: 1,
        zIndex: -1
      }
    }
  ],
  grid: {
    containLabel: true,
    right: '5px',
    left: 0,
    bottom: 0,
    top: '10px'
  }
});

const IndexChart = ({ title, labels, data }) => {
  const chartRef = useRef(null);

  return (
    <ReactEChartsCore
      ref={chartRef}
      echarts={echarts}
      option={getOptions({ title, labels, data })}
      style={{ height: '12rem' }}
    />
  );
};

IndexChart.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.array)
};

export default IndexChart;
