import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert, Card, Col, Form, Row } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { doc, updateDoc } from '@firebase/firestore';
import { useFirestore } from 'reactfire';
import { QuestionairesContext, UserContext } from 'context/Context';
import { getUnique } from 'helpers/utils';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

dayjs.extend(relativeTime);
dayjs.locale('es');

const TextResponse = ({ value: { answer: value } = {} }) => {
  return <div className="text-wrap">{value}</div>;
};
const VoteResponse = ({ value: { answer: value } = {} }) => {
  return <h6 className="m-0 me-2">{value}</h6>;
};

const UserCell = ({ response = {}, user = {} }) => {
  const { createdAt } = response;
  const time = dayjs().to(dayjs(createdAt));
  let { avatar, name, status } = user;
  status = ['offline', 'online'].includes(status) ? status : 'offline';

  return (
    <Flex alignItems="center">
      <Avatar
        className={`status-${status}`}
        size="2xl"
        src={avatar}
        name={name || 'H'}
      />
      <div className="ms-3">
        <h6 className="mb-0 fw-semi-bold">{name}</h6>
        <p className="fs--2 mb-0 text-500">{time}</p>
      </div>
    </Flex>
  );
};

const responseTypes = {
  text: TextResponse,
  number: VoteResponse
};

const ResponseCell = ({ type, ...rest }) => {
  const ResponseType = responseTypes[type];
  if (!ResponseType) {
    return null;
  }
  return <ResponseType {...rest} />;
};

const HappyfyResponses = ({ questionaire }) => {
  const { happyResponses = [], setMonth } = useContext(QuestionairesContext);
  const { partners = [] } = useContext(UserContext);
  const { definitions = [] } = questionaire || {};
  const uniqueResponses = getUnique(happyResponses, 'userId');
  const db = useFirestore();

  const responses = uniqueResponses.map(response => {
    const { userId } = response;
    const user = partners.find(({ id } = {}) => id === userId) || {};
    const { name } = user;
    return {
      ...response,
      name
    };
  });
  const columns = [
    {
      accessor: 'name',
      Header: '',
      Cell: rowData => {
        const { userId } = rowData.row.original;
        const user = partners.find(({ id } = {}) => id === userId) || {};
        return <UserCell response={rowData.row.original} user={user} />;
      }
    },
    ...definitions
      .filter(({ type }) => type !== 'divider')
      .map((definition, index) => {
        const { question } = definition;
        return {
          accessor: `responses.${index}`,
          Header: question,
          Cell: rowData => {
            return (
              <ResponseCell
                {...definition}
                value={rowData.row.original.responses[index]}
              />
            );
          }
        };
      })
  ];

  const handleChangePeriod = event => {
    const { currentTarget } = event;
    const { value } = currentTarget;
    const date = new Date();
    date.setMonth(date.getMonth() + parseInt(value));
    const [month] = date.toISOString().match(/\d+-\d+/);
    setMonth(month);
  };

  useEffect(() => {
    happyResponses
      .filter(({ new: isNew }) => isNew)
      .forEach(({ NO_ID_FIELD }) => {
        const ref = doc(db, 'form_responses', NO_ID_FIELD);
        updateDoc(ref, { new: false });
      });
  }, [happyResponses]);

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={responses}
        sortable
        pagination
        perPage={20}
      >
        <Flex className="bg-white p-3 pt-0 w-100">
          <Row className="w-100">
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox table placeholder="Buscar..." />
            </Col>
            <Col sm={6} lg="auto">
              <Form.Select
                defaultValue={0}
                size="sm"
                className="me-2"
                onChange={handleChangePeriod}
              >
                <option value={0}>Este mes</option>
                <option value={-1}>Mes pasado</option>
                {new Array(10).fill(0).map((val, index) => {
                  const date = new Date();
                  date.setMonth(date.getMonth() - (index + 2));
                  let month = dayjs(date).format('MMMM [de] YYYY');
                  month = `${month[0].toUpperCase()}${month.substr(1)}`;
                  return (
                    <option
                      key={`Questionaire-Period-${index}`}
                      value={-(index + 2)}
                      className="text-capitalize"
                    >
                      {month}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>
        </Flex>
        <AdvanceTable
          table
          headerClassName="bg-200 overflow-hidden text-900 text-nowrap align-middle"
          rowClassName="bg-white align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100"
          tableProps={{
            bordered: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        {!responses.length && (
          <div className="bg-white p-3 border-bottom border-200">
            <Alert variant="info" className="mb-0">
              <h5 className="alert-heading">No hay respuestas</h5>
            </Alert>
          </div>
        )}
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={responses.length}
            table
            rowInfo
            navButtons
          />
        </Card.Footer>
      </AdvanceTableWrapper>
    </>
  );
};

TextResponse.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
VoteResponse.propTypes = {
  extra: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
};

ResponseCell.propTypes = {
  type: PropTypes.string
};

UserCell.propTypes = {
  response: PropTypes.object,
  user: PropTypes.object
};

HappyfyResponses.propTypes = {
  questionaire: PropTypes.object
};

export default HappyfyResponses;
