import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import Objective from './Objective';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import PendingObjectives from './PendingObjectives';
import AppContext, { CalendarContext, UserContext } from 'context/Context';
import { getUnique } from 'helpers/utils';

const renderObjectiveContent = eventInfo => {
  const { event = {}, partners, timeText } = eventInfo;
  const { allDay, extendedProps, title } = event;
  const { NO_ID_FIELD, participants, state, type } = extendedProps;
  let titleToShow = title;

  if (type === 'objective' && participants?.length) {
    titleToShow = `${titleToShow} - ${participants
      .map(ref => {
        const user = partners.find(partner => partner.ref?.path === ref?.path);
        const { name } = user || {};
        return name;
      })
      .join(', ')}`;
  }

  return (
    <Flex
      data-id={NO_ID_FIELD}
      alignItems="center"
      className="w-100"
      title={titleToShow}
    >
      {type === 'objective' && state && state !== 'on-course' && (
        <FontAwesomeIcon
          className={classNames('me-1', {
            'text-yellow': state === 'waiting',
            'text-success': state === 'success',
            'text-danger': state === 'fail'
          })}
          icon={classNames({
            'exclamation-circle': state !== 'success' && state !== 'fail',
            'check-circle': state === 'success',
            'times-circle': state === 'fail'
          })}
        />
      )}
      {timeText && timeText !== '0' && (
        <small className="fw-bold fc-event-time">{timeText}h.</small>
      )}
      <span
        className={classNames('w-0 flex-grow-1 fc-event-title', {
          'fw-semi-bold': allDay,
          'text-white': type === 'time-off'
        })}
      >
        {titleToShow}
      </span>
    </Flex>
  );
};

export const Objectives = ({ userId }) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const {
    objectives: data = [],
    setIsOpenNewObjectiveModal,
    setModalObjectiveContent,
    setIsOpenObjectiveModal,
    setScheduleStartDate,
    setScheduleEndDate
  } = useContext(CalendarContext);
  const { partners = [] } = useContext(UserContext);
  const { me } = useContext(UserContext);
  const calendarRef = useRef();
  const [calendarApi, setCalendarApi] = useState({});
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const userObjectives = (
    userId
      ? data.filter(
          ({ participants }) =>
            !participants || participants?.[0]?.id === userId
        )
      : data.filter(
          ({ participants }) =>
            !participants || participants?.[0]?.path === me?.ref?.path
        )
  ).sort((o1, o2) => (o1.start > o2.start ? -1 : 1));
  const myCurrentObjectives = userObjectives
    .filter(
      ({ state = 'on-course', validated }) => state === 'on-course' && validated
    )
    .sort((o1, o2) => (o1.start < o2.start ? -1 : 1));
  const waitingObjectives = (
    me?.type === 'admin' && !userId ? data : userObjectives
  ).filter(
    ({ start, state, validated }) =>
      state === 'waiting' ||
      validated === null ||
      (start < new Date().toISOString() && (!state || state === 'on-course'))
  );
  const succeededObjectives = userObjectives.filter(
    ({ state }) => state === 'success'
  );
  const failedObjectives = userObjectives.filter(
    ({ state }) => state === 'fail'
  );

  const objectiveTimeFormat = {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: true,
    meridiem: true
  };

  const handleObjectiveClick = info => {
    setModalObjectiveContent(info);
    setIsOpenObjectiveModal(true);
  };

  const handleEnter = objective => {
    const { NO_ID_FIELD } = objective;
    const els = document.querySelectorAll(`[data-id="${NO_ID_FIELD}"]`);
    els?.forEach(el => {
      el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.add(
        'highlight'
      );
    });
  };

  const handleLeave = objective => {
    const { NO_ID_FIELD } = objective;
    const els = document.querySelectorAll(`[data-id="${NO_ID_FIELD}"]`);
    els?.forEach(el => {
      el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.remove(
        'highlight'
      );
    });
  };

  useEffect(() => {
    const api = calendarRef?.current?.getApi();
    api && setCalendarApi(api);
  }, [calendarRef?.current]);

  setTimeout(() => {
    calendarApi?.updateSize?.();
  }, 0);

  return (
    <>
      <Card>
        <Card.Header
          as={Flex}
          justifyContent="between"
          alignItems="center"
          className="bg-light"
        >
          <div />
          <IconButton
            variant="yellow"
            iconClassName="me-2"
            icon="plus"
            transform="shrink-3"
            size="sm"
            onClick={() => {
              setIsOpenNewObjectiveModal(isOpen => !isOpen);
            }}
          >
            Crear objetivo
          </IconButton>
        </Card.Header>
        <Row className="g-3 m-0">
          <Col xs={12} lg={5} className="m-0">
            <Card.Body className="fs--1">
              <Card className="border text-center mb-5 shadow-none">
                <Card.Header className="border-bottom">
                  <h6>
                    {!userId || userId === me?.NO_ID_FIELD
                      ? 'Mis objetivos'
                      : `Objetivos de ${me?.name}`}
                  </h6>
                  <span>
                    Del 1 de enero al 31 de diciembre de{' '}
                    {new Date().getFullYear()}
                  </span>
                </Card.Header>
                <Card.Body>
                  <Flex justifyContent="between" className="text-nowrap">
                    <div className="w-0 flex-grow-1 pe-2">
                      <h4 className="text-yellow">
                        {waitingObjectives.length}
                      </h4>
                      <h6
                        className="text-truncate text-uppercase m-0"
                        title="A la espera"
                      >
                        A la espera
                      </h6>
                    </div>
                    <div className="w-0 flex-grow-1 pe-2">
                      <h4 className="text-500">{myCurrentObjectives.length}</h4>
                      <h6
                        className="text-truncate text-uppercase m-0"
                        title="En curso"
                      >
                        En curso
                      </h6>
                    </div>
                    <div className="w-0 flex-grow-1 pe-2">
                      <h4 className="text-success">
                        {succeededObjectives.length}
                      </h4>
                      <h6
                        className="text-truncate text-uppercase m-0"
                        title="Conseguidos"
                      >
                        Conseguidos
                      </h6>
                    </div>
                    <div className="w-0 flex-grow-1">
                      <h4 className="text-danger">{failedObjectives.length}</h4>
                      <h6
                        className="text-truncate text-uppercase m-0"
                        title="No conseguidos"
                      >
                        No conseguidos
                      </h6>
                    </div>
                  </Flex>
                </Card.Body>
              </Card>
              <h5 className="fs-3">
                <FontAwesomeIcon icon="trophy" className="text-yellow" />
              </h5>
              <h5>Objetivos pendientes de revisar</h5>
              <PendingObjectives className="border mt-3 mb-5 shadow-none" />
              <h5 className="fs-3">
                <FontAwesomeIcon icon="trophy" className="text-500" />
              </h5>
              <h5>Objetivos en curso</h5>
              <Card className="border mt-3 mb-5 shadow-none">
                <Card.Body>
                  {!myCurrentObjectives.length && 'No hay objetivos en curso'}
                  {myCurrentObjectives.map((details, index) => (
                    <Objective
                      key={`Objective-${details.NO_ID_FIELD}`}
                      details={details}
                      isLast={index === myCurrentObjectives.length - 1}
                      onMouseEnter={() => handleEnter(details)}
                      onMouseLeave={() => handleLeave(details)}
                    />
                  ))}
                </Card.Body>
              </Card>
              <h5 className="fs-3">
                <FontAwesomeIcon icon="trophy" className="text-success" />
              </h5>
              <h5>Objetivos cumplidos</h5>
              <Card className="border mt-3 mb-5 shadow-none">
                <Card.Body>
                  {!succeededObjectives.length && 'No hay objetios cumplidos'}
                  {succeededObjectives.map((details, index) => (
                    <Objective
                      key={`Objective-${details.NO_ID_FIELD}`}
                      color="success"
                      details={details}
                      isLast={index === succeededObjectives.length - 1}
                      onMouseEnter={() => handleEnter(details)}
                      onMouseLeave={() => handleLeave(details)}
                    />
                  ))}
                </Card.Body>
              </Card>
              <h5 className="fs-3">
                <FontAwesomeIcon icon="trophy" className="text-danger" />
              </h5>
              <h5>Objetivos no conseguidos</h5>
              <Card className="border mt-3 mb-5 shadow-none">
                <Card.Body>
                  {!failedObjectives.length && 'No hay objetivos fallidos'}
                  {failedObjectives.map((details, index) => (
                    <Objective
                      color="danger"
                      key={`Objective-${details.NO_ID_FIELD}`}
                      details={details}
                      isLast={index === failedObjectives.length - 1}
                      onMouseEnter={() => handleEnter(details)}
                      onMouseLeave={() => handleLeave(details)}
                    />
                  ))}
                </Card.Body>
              </Card>
            </Card.Body>
          </Col>
          <Col xs={12} lg={7} className="objectives-calendar m-0 bg-200">
            <div className="sticky-sidebar p-3">
              <FullCalendar
                firstDay={1}
                headerToolbar={{
                  start: '',
                  center: 'title',
                  end: ''
                }}
                ref={calendarRef}
                plugins={[multiMonthPlugin, interactionPlugin]}
                locale="es"
                initialView="multiMonthYear"
                themeSystem="bootstrap"
                dayHeaderFormat={{ weekday: 'narrow' }}
                dayMaxObjectives={9}
                direction={isRTL ? 'rtl' : 'ltr'}
                multiMonthMaxColumns={4}
                multiMonthMinWidth={160}
                selectable={me?.type === 'admin'}
                selectMirror
                select={info => {
                  const end = new Date(
                    new Date(info.end).setDate(new Date(info.end).getDate() - 1)
                  );
                  if (
                    [6, 0].includes(info.start.getDay()) &&
                    [6, 0].includes(end.getDay())
                  ) {
                    return;
                  }
                  setIsOpenNewObjectiveModal(true);
                  setScheduleStartDate(info.start);
                  setScheduleEndDate(end);
                }}
                eventTimeFormat={objectiveTimeFormat}
                eventClick={handleObjectiveClick}
                events={getUnique(
                  [...userObjectives, ...waitingObjectives],
                  'NO_ID_FIELD'
                ).map(item => {
                  let { className } = item;
                  return {
                    ...item,
                    allDay: true,
                    className
                  };
                })}
                eventClassNames={data => {
                  const { event } = data || {};
                  const { extendedProps } = event || {};
                  const { state } = extendedProps || {};
                  const stateClass = `objective-${state}`;
                  return [stateClass];
                }}
                eventContent={params =>
                  renderObjectiveContent({ ...params, partners })
                }
                moreLinkContent=""
                moreLinkClassNames=""
              />
              <Flex
                className="gap-2"
                alignItems="center"
                justifyContent="center"
                wrap="wrap"
              >
                <SoftBadge alignItems="center" bg="light" pill>
                  <FontAwesomeIcon
                    className="border border-2 border-500 text-light rounded-circle me-2"
                    icon="circle"
                  />
                  <span>En curso</span>
                </SoftBadge>
                <SoftBadge alignItems="center" bg="light" pill>
                  <FontAwesomeIcon
                    className="border border-2 border-yellow text-light rounded-circle me-2"
                    icon="circle"
                  />
                  <span>A la espera</span>
                </SoftBadge>
                <SoftBadge alignItems="center" bg="light" pill>
                  <FontAwesomeIcon
                    className="border border-2 border-success text-light rounded-circle me-2"
                    icon="circle"
                  />
                  <span>Conseguido</span>
                </SoftBadge>
                <SoftBadge alignItems="center" bg="light" pill>
                  <FontAwesomeIcon
                    className="border border-2 border-danger text-light rounded-circle me-2"
                    icon="circle"
                  />
                  <span>No conseguido</span>
                </SoftBadge>
              </Flex>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

Objectives.propTypes = {
  userId: PropTypes.string
};

export default Objectives;
