import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { version } from 'config';
import { addDoc, collection, doc, updateDoc } from '@firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { HappyQuestionairesContext } from 'context/Context';
import { toast } from 'react-toastify';
import { getCurrencyFormat, isMobile } from 'helpers/utils';
import AppContext from 'context/Context';

let startDate = new Date();
let elapsedTime = 0;
let isRegistered = false;

const HappyQuestionaireProvider = ({ children }) => {
  const { ip } = useContext(AppContext);
  const navigate = useNavigate();
  const [userId, setUserId] = useState();
  const [messageId, setMessageId] = useState();
  const db = useFirestore();
  let userRef = doc(db, 'none', 'none');
  if (userId) {
    userRef = doc(db, 'users', userId);
  }
  const { data: user = {} } = useFirestoreDocData(userRef);
  const { company: companyId } = user || {};
  let companyRef = doc(db, 'none', 'none');
  if (companyId) {
    companyRef = doc(db, 'companies', companyId);
  }
  const { data: company = {} } = useFirestoreDocData(companyRef);

  const createEvent = data => {
    try {
      const { createdAt = new Date(Date.now()).toISOString() } = data;
      addDoc(collection(db, 'events'), {
        ...data,
        createdAt
      });
    } catch (error) {
      console.error('Error creating index:', error);
    }
  };

  const createFeedback = async data => {
    const {
      companyId,
      createdAt = new Date(Date.now()).toISOString(),
      feedback,
      userId
    } = data;
    try {
      await addDoc(collection(db, 'feedback'), {
        companyId,
        createdAt,
        updatedAt: createdAt,
        feedback,
        type: 'happy-questionaire',
        userId,
        new: true
      });

      const eventData = {
        color: 'primary',
        companyId,
        createdAt: new Date(Date.now()).toISOString(),
        text: feedback,
        timeline: true,
        title: 'Nueva sugerencia registrada',
        icon: 'comment-medical',
        userId
      };
      createEvent(eventData);
      return { companyId, createdAt, feedback, userId, new: true };
    } catch (error) {
      console.error('Error creating feedback:', error);
    }
  };

  const createIndex = data => {
    try {
      if (isNaN(data?.value)) {
        return;
      }
      const { createdAt = new Date(Date.now()).toISOString() } = data;
      addDoc(collection(db, 'indexes'), {
        ...data,
        createdAt
      });
    } catch (error) {
      console.error('Error creating event:', error);
    }
  };

  const finishHappyQuestionaire = async ({ definitions, answers, user }) => {
    try {
      const {
        company: companyId,
        id: userId,
        lastQuestionnaireAt: userLastQuestionnaireAt
      } = user;
      const submitionDate = new Date(Date.now());
      const submittedAt = submitionDate.toISOString();
      let score = 0;

      const responses = definitions.map(
        ({ question, source, type, values }, i) => {
          let answer = answers[i];
          if (type === 'number') {
            score += values[answer] || 0;
            const value = answer * 10;
            createIndex({
              createdAt: submittedAt,
              companyId,
              type: source,
              userId,
              value
            });
            answer = isNaN(answer) ? answer : `${value}%`;
          } else if (type === 'text') {
            answer && createFeedback({ companyId, feedback: answer, userId });
          }
          return { question, answer };
        }
      );

      let happiness =
        (score /
          definitions.filter(({ type }, i) => {
            let answer = answers[i];
            return type === 'number' && !isNaN(answer);
          }).length) *
        10;
      happiness = isNaN(happiness) ? null : Math.round(happiness);
      let stress =
        answers[definitions.findIndex(({ source }) => source === 'stress')] *
        10;
      stress = isNaN(stress) ? null : stress;

      !isNaN(happiness) &&
        createIndex({
          createdAt: submittedAt,
          companyId,
          type: 'happiness',
          userId,
          value: happiness
        });

      addDoc(collection(db, 'form_responses'), {
        companyId,
        createdAt: submittedAt,
        new: true,
        payload: { origin: 'web' },
        responses,
        userId
      });

      const lastQuestionnaireDate = new Date(userLastQuestionnaireAt);
      const isRewardable =
        company?.apps?.includes('questionaires') &&
        lastQuestionnaireDate.getMonth() !== new Date().getMonth();

      if (isRewardable) {
        const amount = 1;
        const dateDescription = new Intl.DateTimeFormat('es', {
          month: 'long',
          year: 'numeric'
        }).format(submitionDate);
        addDoc(collection(db, 'transactions'), {
          amount,
          createdAt: submittedAt,
          currency: 'EUR',
          description: `Cuestionario de ${dateDescription}`,
          img: 'wallet/happyfy.png',
          icon: 'wallet/plus.png',
          userId,
          new: true
        });
        const amountTxt = getCurrencyFormat(amount);
        const title = amount
          ? `${amountTxt} añadidos a tu monedero`
          : 'Nueva recompensa';
        addDoc(collection(db, 'notifications'), {
          body: `Cuestionario de ${dateDescription}`,
          createdAt: submittedAt,
          target: userId,
          title,
          type: 'user',
          data: {
            screen: 'Wallet'
          }
        });
      }

      // Updates
      updateDoc(doc(db, 'users', userId), {
        happiness,
        stress,
        lastSubmition: submittedAt,
        lastQuestionnaireAt: submittedAt
      });

      messageId &&
        updateDoc(doc(db, 'chat_messages', messageId), {
          'extra.answers': responses,
          'extra.submittedAt': submittedAt
        });

      const eventData = {
        color: 'accent',
        companyId,
        createdAt: submittedAt,
        text: user.name,
        timeline: true,
        title: 'Cuestionario Happyfy finalizado',
        icon: 'laugh',
        userId
      };
      createEvent(eventData);
      toast.success('Cuestionario Happyfy finalizado');
      setTimeout(() => {
        navigate(isRewardable ? '/user/benefits/transactions' : '/');
      }, 3000);
    } catch (error) {
      console.error(error);
      toast.error();
    }
  };

  const handleWindowFocus = () => {
    if (!userId) {
      return;
    }
    startDate = new Date();
    updateDoc(doc(db, 'users', userId), {
      status: 'online'
    });
  };
  const handleWindowBlur = () => {
    if (!userId) {
      return;
    }
    const endDate = new Date();
    const spentTime = endDate.getTime() - startDate.getTime();
    elapsedTime += spentTime;
    updateDoc(doc(db, 'users', userId), {
      status: 'offline'
    });
  };

  const handleWindowClose = async () => {
    const endDate = new Date();
    const spentTime = endDate.getTime() - startDate.getTime();
    elapsedTime += spentTime;
    try {
      await addDoc(collection(db, 'users', userId, 'time'), {
        createdAt: endDate.toISOString(),
        value: elapsedTime
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.addEventListener('focus', handleWindowFocus);
    window.addEventListener('blur', handleWindowBlur);
    window.addEventListener('beforeunload', handleWindowClose);
    return () => {
      window.removeEventListener('focus', handleWindowFocus);
      window.removeEventListener('blur', handleWindowBlur);
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [userId]);

  useEffect(() => {
    if (isRegistered || !userId || !ip || !version) {
      return;
    }
    isRegistered = true;
    fetch(`${process.env.REACT_APP_FIREBASE_URL}/registerActivity`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        createdAt: new Date(Date.now()).toISOString(),
        web_info: {
          userAgent: window.navigator?.userAgent,
          mobile: window.navigator?.userAgentData?.mobile || isMobile(),
          platform:
            window.navigator?.userAgentData?.platform ||
            window.navigator?.platform,
          vendor: window.navigator?.vendor,
          version
        },
        lang: window.navigator.language,
        ip,
        userId
      })
    });
  }, [ip, userId, version]);

  return (
    <HappyQuestionairesContext.Provider
      value={{
        company,
        user,
        finishHappyQuestionaire,
        setMessageId,
        setUserId
      }}
    >
      {children}
    </HappyQuestionairesContext.Provider>
  );
};

HappyQuestionaireProvider.propTypes = { children: PropTypes.node };

export default HappyQuestionaireProvider;
