import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import ReactPlayer from 'react-player/youtube';
import createMarkup from 'helpers/createMarkup';
import classNames from 'classnames';
import FeedBirthday from './FeedBirthday';
import FeedEvent from './FeedEvent';
import FeedImages from './FeedImages';
import FeedRanking from './FeedRanking';
import FeedUrl from './FeedUrl';
import FeedWorkAnniversary from './FeedWorkAnniversary';

const FeedCardContent = ({ feed }) => {
  const { content = {}, createdAt } = feed || {};
  const {
    birthday,
    text,
    images,
    event,
    ranking,
    url,
    video,
    workAnniversary
  } = content;

  return (
    <Card.Body className={classNames({ 'p-0': !!event })}>
      {!!text && !event && <p dangerouslySetInnerHTML={createMarkup(text)} />}
      {!!birthday && <FeedBirthday userIds={birthday} />}
      {!!workAnniversary && (
        <FeedWorkAnniversary createdAt={createdAt} userIds={workAnniversary} />
      )}
      {!!event && <FeedEvent event={event} text={text} />}
      {!!url && <FeedUrl url={url} />}
      {!!images && <FeedImages images={images} />}
      {!!video && (
        <ReactPlayer
          url={video}
          controls={true}
          className="react-player max-w-640px mt-2"
        />
      )}
      {!!ranking && <FeedRanking ranking={ranking} />}
    </Card.Body>
  );
};

FeedCardContent.propTypes = {
  feed: PropTypes.object
};

export default FeedCardContent;
