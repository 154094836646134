import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AIButton from 'components/common/AIButton';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import IconItem from 'components/common/icon/IconItem';
import SoftBadge from 'components/common/SoftBadge';
import { AIContext, EmployeesContext, UserContext } from 'context/Context';
import EmployeesProvider from './EmployeesProvider';
import IconButton from 'components/common/IconButton';
import Tooltip from 'components/common/Tooltip';
import ActionsDropdown from './ActionsDropdown';
import FilterDropdown from './FilterDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getColor, getTimeDuration, getUnique, groupBy } from 'helpers/utils';
import types from 'components/dashboards/types.json';
import redflagTypes from 'components/dashboards/redflagTypes.json';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

dayjs.extend(relativeTime);
dayjs.locale('es');

const ALERTS = {
  happiness: '< 70',
  environment: '< 70',
  fellowship: '< 70',
  motivation: '< 70',
  satisfaction: '< 70',
  stress: '> 70'
};

const REDFLAGS = {
  burnout: ['happiness', 'stress'],
  conflict: ['happiness', 'environment', 'fellowship'],
  absent: ['motivation', 'satisfaction'],
  sick: ['happiness', 'environment', 'stress'],
  performance: ['motivation', 'stress']
};

const InviteToQuestionaire = ({ diff, link }) => {
  const [tooltipText, setTooltipText] = useState(
    'Copiar link del cuestionario'
  );

  const handleCopyText = () => {
    navigator.clipboard.writeText(link);
    setTooltipText('✅ Link copiado');
  };

  return (
    <>
      <Tooltip title={tooltipText}>
        <div>
          <IconItem
            onMouseLeave={() => setTooltipText('Copiar link del cuestionario')}
            onClick={handleCopyText}
            icon={['far', 'copy']}
            size="sm"
            className={classNames('btn rounded-3 me-2 fs--2', {
              'border border-3 border-primary': diff > 0
            })}
          />
        </div>
      </Tooltip>
    </>
  );
};

const IndexGraph = ({ title, color, data, className, style }) => {
  return (
    <div
      style={style}
      className={classNames('d-flex align-items-center gap-2', className)}
    >
      <div style={{ '--falcon-circle-progress-bar': data }}>
        <svg
          className="circle-progress-svg"
          width="26"
          height="26"
          viewBox="0 0 120 120"
        >
          <circle
            className="progress-bar-rail"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            strokeWidth="12"
          ></circle>
          <circle
            className="progress-bar-top"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            stroke={color}
            strokeWidth="12"
          ></circle>
        </svg>
      </div>
      <h6 className="mb-0 text-700">{title}</h6>
    </div>
  );
};

IndexGraph.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

const UserCell = ({ user }) => {
  const { me, updateUser } = useContext(UserContext);
  let {
    id,
    avatar,
    department,
    level: userLevel = 0,
    location,
    name,
    status
  } = user;
  const [level, setLevel] = useState(userLevel || 0);
  status = ['offline', 'online'].includes(status) ? status : 'offline';

  const handleChangeLevel = () => {
    const _level = level === 2 ? 0 : level + 1;
    setLevel(_level);
    updateUser(user, { level: _level });
  };

  useEffect(() => {
    setLevel(userLevel);
  }, [userLevel]);

  return (
    <div>
      <Flex alignItems="center">
        <Avatar
          className={`status-${status}`}
          size="xl"
          src={avatar}
          name={name}
        />
        <div className="ps-2">
          <Flex alignItems="center">
            <Link to={`/user/profile/${id}`} className="text-800">
              <h6 className="mb-0">{name}</h6>
            </Link>
            {me?.type === 'admin' && (
              <Tooltip
                title={
                  <>
                    <Flex
                      alignItems="center"
                      className={classNames({
                        'fw-bold text-decoration-underline': level === 0
                      })}
                    >
                      <FontAwesomeIcon
                        icon={['far', 'star']}
                        className="text-400 me-2"
                      />
                      -
                    </Flex>
                    <Flex
                      alignItems="center"
                      className={classNames({
                        'fw-bold text-decoration-underline': level === 1
                      })}
                    >
                      <FontAwesomeIcon icon="star" className="text-400 me-2" />
                      Middle manager
                    </Flex>
                    <Flex
                      alignItems="center"
                      className={classNames({
                        'fw-bold text-decoration-underline': level == 2
                      })}
                    >
                      <FontAwesomeIcon
                        icon="star"
                        className="text-yellow me-2"
                      />
                      Manager
                    </Flex>
                  </>
                }
              >
                <div className="ms-1">
                  <FontAwesomeIcon
                    onClick={handleChangeLevel}
                    icon={level !== 0 ? 'star' : ['far', 'star']}
                    className={classNames(
                      {
                        'text-yellow': level === 2,
                        'text-400': level === 1 || level === 0
                      },
                      'cursor-pointer'
                    )}
                  />
                </div>
              </Tooltip>
            )}
          </Flex>
          <p className="mb-0 fs--2">{department}</p>
          <p className="mb-0 fs--2">{location?.name}</p>
        </div>
      </Flex>
    </div>
  );
};

UserCell.propTypes = {
  user: PropTypes.object
};

const Redflag = ({ data }) => {
  let { NO_ID_FIELD, lastQuestionnaireAt, redflagsData = [] } = data;
  const monthsDiff = parseInt(
    getTimeDuration(dayjs(lastQuestionnaireAt), dayjs(Date.now()), 'M')
  );

  return (
    <>
      {!redflagsData.length ? (
        <Tooltip
          title={classNames({
            'Sin riesgos': monthsDiff === 0,
            'Sin riesgos, aunque hace más de 1 mes que no se obtienen datos':
              monthsDiff > 0
          })}
        >
          <SoftBadge
            pill
            bg={classNames({
              success: monthsDiff === 0,
              danger: monthsDiff > 0
            })}
            className="text-capitalize"
          >
            <FontAwesomeIcon icon="check" />
          </SoftBadge>
        </Tooltip>
      ) : (
        <>
          {redflagsData.sort().map(redflag => {
            return (
              <div key={`Redflag-${NO_ID_FIELD}-${redflag}`}>
                <Badge pill bg="danger" className="text-capitalize">
                  <FontAwesomeIcon
                    icon={redflagTypes[redflag]?.icon}
                    className="me-1"
                  />
                  {redflagTypes[redflag]?.title}
                </Badge>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

Redflag.propTypes = {
  data: PropTypes.object
};

const employeeColumns = [
  {
    accessor: 'name',
    Header: 'Nombre',
    headerProps: { className: 'py-3 d-inline-block' },
    Cell: rowData => {
      return <UserCell user={rowData.row.original} />;
    }
  },
  {
    accessor: 'department',
    Header: 'Departamento',
    headerProps: { className: 'd-inline-block' },
    cellProps: { className: 'd-none' },
    Cell: rowData => rowData.row.original.department
  },
  {
    accessor: 'location.name',
    Header: 'Localización',
    headerProps: { className: 'd-inline-block' },
    cellProps: { className: 'd-none' },
    Cell: rowData => rowData.row.original.location?.name
  }
];

const adminColumns = [
  {
    accessor: 'name',
    Header: 'Nombre',
    headerProps: { className: 'py-3 ps-0 d-inline-block' },
    cellProps: {
      className: 'ps-0'
    },
    Cell: rowData => {
      return <UserCell user={rowData.row.original} />;
    }
  },
  {
    accessor: 'department',
    Header: 'Departamento',
    headerProps: { className: 'd-inline-block' },
    cellProps: { className: 'd-none' },
    Cell: rowData => rowData.row.original.department
  },
  {
    accessor: 'location.name',
    Header: 'Localización',
    headerProps: { className: 'd-inline-block w-0' },
    cellProps: { className: 'd-none' },
    Cell: rowData => rowData.row.original.location?.name
  },
  {
    accessor: 'redflags',
    Header: 'Riesgos',
    Cell: rowData => {
      return <Redflag data={rowData.row.original} />;
    }
  },
  {
    accessor: 'alerts',
    Header: 'Alertas',
    Cell: rowData => {
      let {
        NO_ID_FIELD,
        ai,
        alertsData = [],
        department,
        lastQuestionnaireAt,
        name
      } = rowData.row.original;
      const alertsTxt = alertsData
        .map(({ type, value }) => `${types[type]} (${value}%)`)
        .join(', ');

      const handleAI = () => {
        const actions = [
          'Dame más soluciones',
          {
            prompt:
              'Diséñame un plan de acción por semanas para mejorar esta métrica en 1 mes',
            text: 'Diséñame un plan de acción'
          }
        ];
        const context = [
          {
            role: 'system',
            content:
              'Eres un experto en recursos humanos de una empresa donde se cuidan mucho a sus empleados.'
          },
          {
            role: 'system',
            content: `A continuación te voy a plantear un caso real del que tienes que hacer una lista en formato tabla de 2 posibles soluciones. Las soluciones deben ser específicas para mejorar su estado en las métricas indicadas (Alguna podrían ser con coste económico). Además dime 1 caja de Smartbox que mejor se adapte. Enúnciame primero en negrita lo que vas a hacer. Sé conciso y evita ser genérico.`
          }
        ];
        const prompt = `${name} del departamento de ${department} tiene estas alertas: ${alertsTxt} a fecha de ${dayjs().format(
          'MMMM [de] YYYY'
        )}`;
        const filename = `happyfy-ia-${name
          .toLowerCase()
          .replace(/ /g, '-')}--${alertsData
          .map(({ type }) => type)
          .join('-')}.pdf`;
        ai({ actions, context, prompt, filename });
      };

      const monthsDiff = parseInt(
        getTimeDuration(dayjs(lastQuestionnaireAt), dayjs(Date.now()), 'M')
      );

      return (
        <>
          {!alertsData.length ? (
            <Tooltip
              title={classNames({
                'Sin alertas': monthsDiff === 0,
                'Sin alertas, aunque hace más de 1 mes que no se obtienen datos':
                  monthsDiff > 0
              })}
            >
              <SoftBadge
                pill
                bg={classNames({
                  success: monthsDiff === 0,
                  danger: monthsDiff > 0
                })}
                className="text-capitalize"
              >
                <FontAwesomeIcon icon="check" />
              </SoftBadge>
            </Tooltip>
          ) : (
            <>
              {alertsData
                .sort(({ type: t1 }, { type: t2 }) =>
                  types[t1] < types[t2] ? -1 : 1
                )
                .map(({ type }) => {
                  return (
                    <div key={`Alert-${NO_ID_FIELD}-${type}`}>
                      <SoftBadge pill bg="danger" className="text-capitalize">
                        {types[type]}
                      </SoftBadge>
                    </div>
                  );
                })}
              <AIButton
                onClick={handleAI}
                tooltip="¿Cómo podría solucionar la(s) alerta(s)?"
              />
            </>
          )}
        </>
      );
    }
  },
  {
    accessor: 'happiness',
    Header: 'Felicidad',
    cellProps: {
      className: 'pe-4'
    },
    Cell: rowData => {
      let { happiness = '-' } = rowData.row.original;
      if (happiness === null) {
        happiness = '-';
      }
      const color =
        (isNaN(happiness) && 'none') ||
        (happiness > 75 && getColor('success')) ||
        (happiness > 60 && getColor('warning')) ||
        getColor('danger');
      return <IndexGraph title={happiness} color={color} data={happiness} />;
    }
  },
  {
    accessor: 'stress',
    Header: 'Estrés',
    cellProps: {
      className: 'pe-4'
    },
    Cell: rowData => {
      let { stress = '-' } = rowData.row.original;
      if (stress === null) {
        stress = '-';
      }
      const color =
        (isNaN(stress) && 'none') ||
        (stress > 80 && getColor('danger')) ||
        (stress > 60 && getColor('warning')) ||
        getColor('success');
      return <IndexGraph title={stress} color={color} data={stress} />;
    }
  },
  {
    accessor: 'lastQuestionnaireAt',
    Header: 'Último cuestionario',
    cellProps: {
      className: 'pe-4'
    },
    Cell: rowData => {
      const user = rowData.row.original;
      const { id: userId, lastQuestionnaireAt } = user;
      const params = btoa(JSON.stringify({ userId }));
      const link = `${process.env.REACT_APP_FRONT_URL}/hq/${params}`;

      const daysDiff = getTimeDuration(
        dayjs(lastQuestionnaireAt),
        dayjs(Date.now()),
        'D'
      );
      const monthsDiff = getTimeDuration(
        dayjs(lastQuestionnaireAt),
        dayjs(Date.now()),
        'M'
      );
      const color = isNaN(monthsDiff)
        ? 'secondary'
        : monthsDiff >= 1
        ? 'danger'
        : daysDiff >= 15
        ? 'warning'
        : 'success';
      if (!lastQuestionnaireAt) {
        return (
          <Flex alignItems="center">
            <SoftBadge pill bg={color} className="me-2">
              Nunca
            </SoftBadge>
            <InviteToQuestionaire link={link} />
          </Flex>
        );
      }
      return (
        <Flex alignItems="center">
          <SoftBadge pill bg={color} className="me-2">
            {dayjs().to(dayjs(lastQuestionnaireAt))}
          </SoftBadge>
          <InviteToQuestionaire link={link} />
        </Flex>
      );
    }
  },
  {
    accessor: 'Action',
    Header: 'Acción',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    disableSortBy: true,
    Cell: rowData => {
      const user = rowData.row.original;
      let { NO_ID_FIELD, me } = user;
      return (
        <div className="ms-5">
          <div className="hover-actions bg-100">
            <IconItem
              tag={Button}
              icon={['far', 'edit']}
              size="sm"
              className="btn rounded-3 me-2 fs--2"
              variant="default"
              as={Link}
              to={`/user/profile/${NO_ID_FIELD}`}
            />
            {me?.NO_ID_FIELD !== NO_ID_FIELD && (
              <IconItem
                tag={Button}
                icon={['far', 'comment']}
                size="sm"
                className="btn rounded-3 me-2 fs--2"
                variant="default"
                as={Link}
                to={`/company/chat/user/${NO_ID_FIELD}`}
              />
            )}
          </div>
          <ActionsDropdown
            btnRevealClass="btn-reveal-sm"
            drop="start"
            user={user}
          />
        </div>
      );
    }
  }
];

function BulkAction() {
  const {
    employeesState: { filters, currentFilter }
  } = useContext(EmployeesContext);

  return (
    <Col xs="auto" as={Flex} className="align-items-center">
      <FilterDropdown filters={filters} currentFilter={currentFilter} />
    </Col>
  );
}

const ActionButton = ({
  tooltip,
  icon,
  handleClick,
  variant = 'falcon-default'
}) => (
  <Tooltip title={tooltip}>
    <Button variant={variant} onClick={handleClick} className="shdaow-none">
      <FontAwesomeIcon icon={icon} />
    </Button>
  </Tooltip>
);

const EmployeesContent = () => {
  const { partners = [] } = useContext(UserContext);

  return (
    <AdvanceTableWrapper
      columns={employeeColumns}
      data={partners}
      sortable
      pagination
      perPage={20}
    >
      <Card>
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <AdvanceTableSearchBox table placeholder="Buscar..." />
            </Col>
            <Col></Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-light text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100"
            tableProps={{
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={partners.length}
            table
            rowInfo
            navButtons
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

const EmployeesAdminContent = () => {
  const {
    employeesState: { employees = [] },
    indexes
  } = useContext(EmployeesContext);
  const { me } = useContext(UserContext);
  const { ai } = useContext(AIContext);
  const indexesByUser = groupBy(indexes, 'userId');
  const data = employees.map(user => {
    const { NO_ID_FIELD, happiness, stress } = user;
    const alertsData = getUnique(
      indexesByUser?.[NO_ID_FIELD] || [],
      'type'
    ).filter(
      // eslint-disable-next-line no-unused-vars
      ({ type, value }) => eval(`value ${ALERTS[type]}`)
    );
    const redflagsData = Object.entries(REDFLAGS)
      .filter(([, redflags]) => {
        return !redflags.some(
          redflag => !alertsData.some(({ type }) => type === redflag)
        );
      })
      .map(([key]) => key);
    return {
      ...user,
      ai,
      happiness: happiness !== null ? happiness : '-',
      stress: stress !== null ? stress : '-',
      me,
      alerts: alertsData.length,
      alertsData,
      redflags: alertsData.length + redflagsData.length * 10,
      redflagsData
    };
  });

  return (
    <AdvanceTableWrapper
      columns={adminColumns}
      data={data}
      selection
      selectionColumnWidth={28}
      sortable
      pagination
      perPage={20}
    >
      <Card>
        <Card.Header>
          <Row className="align-items-center">
            <BulkAction />
            <Col>
              <AdvanceTableSearchBox table placeholder="Buscar..." />
            </Col>
            <Col></Col>
            <Col xs="auto">
              <IconButton
                variant="falcon-primary"
                size="sm"
                icon="plus"
                as={Link}
                to="/company/employees/create"
                transform="shrink-3"
              >
                Crear
              </IconButton>
            </Col>
            <Col xs="auto" className="ps-0">
              <ActionsDropdown table />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100"
            tableProps={{
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={employees.length}
            table
            rowInfo
            navButtons
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

const Employees = () => {
  const { me } = useContext(UserContext);
  return (
    <EmployeesProvider>
      {me?.type === 'admin' ? <EmployeesAdminContent /> : <EmployeesContent />}
    </EmployeesProvider>
  );
};

InviteToQuestionaire.propTypes = {
  diff: PropTypes.number,
  link: PropTypes.string.isRequired
};

ActionButton.propTypes = {
  tooltip: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  variant: PropTypes.string
};

export default Employees;
