import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import { Button, Col } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import { UserContext } from 'context/Context';
import ProductImage from './ProductImage';

const CommissionRange = ({ id, commissionRange }) => {
  return (
    <>
      {commissionRange.map(({ type, min, max }, index) => (
        <SoftBadge
          key={`${id}-${index}`}
          bg={classNames({
            primary: type === 'percentage',
            accent: type === 'amount'
          })}
          pill
          className="me-1"
        >
          {(type === 'percentage' &&
            !!max &&
            `${min}%
          ${min !== max ? ` - ${max}%` : ''}`) ||
            (type === 'amount' &&
              !!max &&
              `${min}€
          ${min !== max ? ` - ${max}€` : ''}`)}
        </SoftBadge>
      ))}
    </>
  );
};

const ProductGrid = ({ product, ...rest }) => {
  const { me } = useContext(UserContext);
  const { NO_ID_FIELD, commissionRange, name, clickThroughUrl, img, isNew } =
    product;
  const files = [{ src: img }];

  return (
    <Col className="mb-4" {...rest}>
      <Flex
        direction="column"
        justifyContent="between"
        className="border rounded-3 h-100"
      >
        <div className="overflow-hidden">
          <ProductImage
            name={name}
            id={NO_ID_FIELD}
            isNew={isNew}
            files={files}
            layout="grid"
          />
          <div className="p-3">
            <h5 className="fs-0">{name}</h5>
            <CommissionRange
              id={NO_ID_FIELD}
              commissionRange={commissionRange}
            />
          </div>
        </div>
        <div className="p-3 pt-0">
          <Button
            variant="falcon-default w-100"
            as={Link}
            to={`${clickThroughUrl}&clickref=${me?.NO_ID_FIELD}`}
            target="_blank"
          >
            Ir a la web
          </Button>
        </div>
      </Flex>
    </Col>
  );
};

CommissionRange.propTypes = {
  id: PropTypes.string,
  commissionRange: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

ProductGrid.propTypes = {
  product: PropTypes.shape({
    NO_ID_FIELD: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    commissionRange: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    clickThroughUrl: PropTypes.string.isRequired,
    img: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    isNew: PropTypes.bool
  })
};

export default ProductGrid;
