import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Card, Form } from 'react-bootstrap';
import {
  ticketBadges,
  ticketPriorities,
  ticketTypes
} from 'components/feedback';
import { AgentSelect } from 'components/dashboards/support-desk/unsolved-tickets/TicketsLayout';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { FeedbackContext } from 'context/Context';

const PropertiesFilteringForm = ({ feedback }) => {
  const { update } = useContext(FeedbackContext);
  const {
    priority: priorityProp,
    status: statusProp,
    tags,
    type: typeProp
  } = feedback || {};
  const status = ticketBadges.find(({ value }) => value === statusProp) || {};
  const priority =
    ticketPriorities.find(({ value }) => value === priorityProp) || {};
  const type = ticketTypes.find(({ value }) => value === typeProp) || {};

  const handleChange = ({ target }) => {
    const { name, value } = target;
    update(feedback, { [name]: value });
  };

  return (
    <Card>
      <Card.Header className="bg-light">
        <h6 className="mb-0">Propiedades</h6>
      </Card.Header>
      <Card.Body>
        <Form>
          <div className="mb-2 mt-n2">
            <Form.Label className="mb-1 fs--1">Estado</Form.Label>
            <Form.Select
              size="sm"
              name="status"
              value={statusProp}
              className={classNames({
                [`bg-soft-${status?.type} border-${status?.type}`]: status?.type
              })}
              onChange={handleChange}
            >
              {ticketBadges.map(({ content, value }) => (
                <option key={`Status-${value}`} value={value}>
                  {content}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs--1">Prioridad</Form.Label>
            <Form.Select
              size="sm"
              name="priority"
              value={priorityProp}
              className={classNames({
                [`bg-soft-${priority?.color} border-${priority?.color}`]:
                  priority?.color
              })}
              onChange={handleChange}
            >
              {ticketPriorities.map(({ title, value }) => (
                <option key={`Priority-${value}`} value={value}>
                  {title}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs--1">Tipo</Form.Label>
            <Form.Select
              size="sm"
              name="type"
              value={typeProp}
              className={classNames({
                [`bg-soft-${type?.color} border-${type?.color}`]: type?.color
              })}
              onChange={handleChange}
            >
              <option>-</option>
              {ticketTypes.map(({ title, value }) => (
                <option key={`Type-${value}`} value={value}>
                  {title}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs--1">Encargado</Form.Label>
            <AgentSelect feedback={feedback} className="w-auto ms-auto" />
          </div>
          {!!tags?.length && (
            <div>
              <Form.Label className="mb-1 mt-2 fs--1">Tags</Form.Label>
              <Flex className="gap-2">
                {tags?.map(tag => (
                  <SoftBadge key={`Tag-${tag}`} bg="secondary">
                    {tag}
                  </SoftBadge>
                ))}
              </Flex>
            </div>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

PropertiesFilteringForm.propTypes = {
  feedback: PropTypes.object,
  user: PropTypes.object
};

export default PropertiesFilteringForm;
