import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, CloseButton } from 'react-bootstrap';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import { AssistantContext } from 'context/Context';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { download } from 'helpers/utils';

const CVAttachment = ({ candidate, attachment, isLast }) => {
  const { getCVDownloadUrl } = useContext(AssistantContext);
  const [url, setUrl] = useState('');
  const [nestedModal, setNestedModal] = useState(false);

  const handleDownload = async () => {
    const url = await getCVDownloadUrl(candidate);
    setUrl(url);
    url && download(url, candidate?.title);
  };

  const handleView = async () => {
    const url = await getCVDownloadUrl(candidate);
    setUrl(url);
    setNestedModal(true);
  };

  return (
    <>
      <Flex alignItems="center" className={classNames({ 'mb-3': !isLast })}>
        <div className="bg-attachment me-3">
          <FontAwesomeIcon icon={['far', 'file-pdf']} className="me-2" />
          <span className="text-uppercase fw-bold">{attachment.type}</span>
        </div>

        <div className="flex-1 fs--2">
          <h6 className="mb-1">
            <Link className="text-decoration-none" onClick={handleView}>
              {attachment.title}
            </Link>
          </h6>
          <Link className="link-600 fw-semi-bold" onClick={handleView}>
            Ver
          </Link>
          <span className="mx-1">|</span>
          <Link className="link-600 fw-semi-bold" onClick={handleDownload}>
            Descargar
          </Link>
          <p className="mb-0">
            Subido el{' '}
            {dayjs(attachment.date).format('D MMMM YYYY [a las] HH:mm')}
          </p>
        </div>
      </Flex>

      <Modal
        show={nestedModal}
        size="xl"
        onHide={() => setNestedModal(false)}
        className="video-popup"
        contentClassName="bg-transparent border-0 min-vh-75 align-items-center justify-content-center overflow-hidden"
        backdropClassName="video-popup-backdrop"
        centered
      >
        <CloseButton
          variant="white"
          onClick={() => setNestedModal(false)}
          className="video-popup-close-btn"
        />
        <Modal.Body className="d-flex flex-column p-0 rounded overflow-hidden w-100 h-100">
          <iframe className="flex-grow-1 w-100 h-100" src={url} />
        </Modal.Body>
      </Modal>
    </>
  );
};

const ModalAttachmentContent = ({ candidate, attachments }) => {
  const images = attachments.map(item => item.image);

  return (
    <>
      <FalconLightBoxGallery images={images}>
        {setImgIndex => (
          <>
            {attachments.map((attachment, index) => (
              <CVAttachment
                candidate={candidate}
                setImgIndex={setImgIndex}
                key={attachment.id}
                attachment={attachment}
                index={index}
                isLast={attachments.length - 1 === index}
              />
            ))}
          </>
        )}
      </FalconLightBoxGallery>
    </>
  );
};

CVAttachment.propTypes = {
  candidate: PropTypes.object,
  attachment: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string,
    src: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    type: PropTypes.string
  }),
  isLast: PropTypes.bool,
  setImgIndex: PropTypes.func,
  index: PropTypes.number
};

ModalAttachmentContent.propTypes = {
  candidate: PropTypes.object,
  attachments: PropTypes.array
};

export default ModalAttachmentContent;
