import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FalconLink from 'components/common/FalconLink';
import Slider from 'react-slick';
import ProductGrid from '../ProductGrid';
import { ProductContext } from 'context/Context';

const sliderSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1540,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const SimilarProducts = () => {
  const {
    productsState: { products }
  } = useContext(ProductContext);
  return (
    <Card className="mb-3">
      <FalconCardHeader title="Otros productos" className="bg-light" />
      <Card.Body className="py-0">
        <Slider
          {...sliderSettings}
          className="full-height-slider slick-slider-arrow-inner"
        >
          {products.map(product => (
            <ProductGrid product={product} key={product.id} />
          ))}
        </Slider>
      </Card.Body>
      <Card.Footer className="text-end py-2 bg-light">
        <FalconLink
          title="Volver al marketplace"
          to="/marketplace"
          icon="external-link-alt"
          className="fw-medium"
        />
      </Card.Footer>
    </Card>
  );
};

export default SimilarProducts;
