import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert, Card, Col, Row } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { doc, updateDoc } from '@firebase/firestore';
import { useFirestore } from 'reactfire';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import anonymousAvatar from 'assets/img/icons/blank-profile.svg';

dayjs.extend(relativeTime);
dayjs.locale('es');

const OptionsResponse = ({ value }) => {
  return <div className="text-wrap">{value.split('|||').join(', ')}</div>;
};

const TextResponse = ({ value }) => {
  return <div className="text-wrap">{value}</div>;
};

const VoteResponse = ({ extra = {}, value }) => {
  const { max = {} } = extra;
  const { value: maxValue } = max;
  return (
    <div>
      <Flex alignItems="center" justifyContent="start">
        <h6 className="m-0 me-2">{value}</h6>{' '}
        <Rating
          start={0}
          stop={maxValue}
          initialRating={value}
          readonly={true}
          emptySymbol={
            <FontAwesomeIcon
              icon="circle"
              className="text-300"
              transform="shrink-3"
            />
          }
          fullSymbol={
            <FontAwesomeIcon
              icon="circle"
              className="text-primary"
              transform="shrink-3"
            />
          }
        />
      </Flex>
    </div>
  );
};

const UserCell = ({ response = {}, user = {} }) => {
  const { createdAt } = response;
  const time = dayjs().to(dayjs(createdAt));
  let { avatar, name } = user;

  return (
    <Flex alignItems="center">
      <Avatar size="2xl" src={avatar} name={name || 'H'} />
      <div className="ms-3">
        <h6 className="mb-0 fw-semi-bold">{name}</h6>
        <p className="fs--2 mb-0 text-500">{time}</p>
      </div>
    </Flex>
  );
};

const responseTypes = {
  options: OptionsResponse,
  text: TextResponse,
  vote: VoteResponse
};

const ResponseCell = ({ type, ...rest }) => {
  const ResponseType = responseTypes[type];
  if (!ResponseType) {
    return null;
  }
  return <ResponseType {...rest} />;
};

const Responses = ({ questionaire, responses }) => {
  const { anonymous, definitions } = questionaire;
  const db = useFirestore();
  const { partners = [] } = useContext(UserContext);
  const userResponses = responses.map(response => {
    const { responses: _responses, userId } = response;
    const responses = _responses.filter(response => response !== true);
    const user = anonymous
      ? {
          avatar: anonymousAvatar,
          name: 'Anónimo'
        }
      : partners.find(({ id } = {}) => id === userId) || {};
    const { name } = user;
    return {
      ...response,
      responses,
      name
    };
  });

  const columns = [
    {
      accessor: 'name',
      Header: '',
      Cell: rowData => {
        const { userId } = rowData.row.original;
        const user = anonymous
          ? {
              avatar: anonymousAvatar,
              name: 'Anónimo'
            }
          : partners.find(({ id } = {}) => id === userId) || {};
        return <UserCell response={rowData.row.original} user={user} />;
      }
    },
    ...definitions
      .filter(({ type }) => type !== 'divider')
      .map((definition, index) => {
        const { question } = definition;
        return {
          accessor: `responses.${index}`,
          Header: question,
          Cell: rowData => {
            return (
              <ResponseCell
                {...definition}
                value={rowData.row.original.responses[index]}
              />
            );
          }
        };
      })
  ];

  useEffect(() => {
    responses
      .filter(({ new: isNew }) => isNew)
      .forEach(({ NO_ID_FIELD }) => {
        const ref = doc(db, 'questionaires_responses', NO_ID_FIELD);
        updateDoc(ref, { new: false });
      });
  }, [responses]);

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={userResponses}
        sortable
        pagination
        perPage={20}
      >
        <Flex className="bg-light p-3 pb-0 w-100">
          <Row className="w-100">
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox table placeholder="Buscar..." />
            </Col>
          </Row>
        </Flex>
        <AdvanceTable
          table
          headerClassName="bg-light border-light overflow-hidden text-900 text-nowrap align-middle"
          rowClassName="bg-white align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        {!userResponses.length && (
          <div className="bg-white p-3 border-bottom border-200">
            <Alert variant="info" className="mb-0">
              <h5 className="alert-heading">No hay respuestas</h5>
            </Alert>
          </div>
        )}
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={userResponses.length}
            table
            rowInfo
            navButtons
          />
        </Card.Footer>
      </AdvanceTableWrapper>
    </>
  );
};

OptionsResponse.propTypes = {
  value: PropTypes.string
};
TextResponse.propTypes = {
  value: PropTypes.string
};
VoteResponse.propTypes = {
  extra: PropTypes.object,
  value: PropTypes.number
};

ResponseCell.propTypes = {
  type: PropTypes.string
};

UserCell.propTypes = {
  response: PropTypes.object,
  user: PropTypes.object
};

Responses.propTypes = {
  questionaire: PropTypes.object,
  responses: PropTypes.array
};

export default Responses;
