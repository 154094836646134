import React, { forwardRef, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Form, ListGroup, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { UserContext } from 'context/Context';

const FormControl = forwardRef(({ value, onClick }, ref) => (
  <Form.Control onClick={onClick} readOnly ref={ref} value={value} />
));

FormControl.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func
};

// const eventTimeFormat = {
//   hour: 'numeric',
//   minute: '2-digit',
//   omitZeroMinute: true,
//   meridiem: true
// };

const LockModal = ({ lock, isOpen, setIsOpen }) => {
  const { company, updateCompany } = useContext(UserContext);
  const { vacationLock = [] } = company || {};
  const [dates, setLockDates] = useState();
  const [title, setTitle] = useState('Bloqueo de vacaciones');

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAdd = async () => {
    const start = dates?.[0]?.toISOString();
    const end = dates?.[1]?.toISOString() || start;
    let _vacationLock = [...vacationLock].filter(
      ({ start: _start, end: _end }) => _start !== start && _end !== end
    );
    _vacationLock = [..._vacationLock, { start, end, title }];
    updateCompany(company, { vacationLock: _vacationLock });
    handleClose();
  };

  useEffect(() => {
    const dates = lock?.start &&
      lock?.end && [new Date(lock?.start), new Date(lock?.end)];
    const title = lock?.title || 'Bloqueo de vacaciones';
    setLockDates(dates);
    setTitle(title);
  }, [lock]);

  return (
    <Modal
      centered
      backdropClassName="z-index-1060"
      className="z-index-1065"
      contentClassName="border"
      show={isOpen}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>{lock ? 'Editar' : 'Añadir'} días de bloqueo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label className="fs-0">Fecha</Form.Label>
          <DatePicker
            selected={dates?.[0]}
            startDate={dates?.[0]}
            endDate={dates?.[1]}
            onChange={dates => {
              setLockDates(dates);
            }}
            selectsRange
            className="form-control"
            placeholderText="d MMMM - d MMMM"
            dateFormat="d MMMM"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="LockInput">Título</Form.Label>
          <Form.Control
            id="LockInput"
            value={title}
            onFocus={() => focus(true)}
            onChange={({ target }) => setTitle(target.value)}
            autoFocus
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleAdd}>Crear bloqueo</Button>
      </Modal.Footer>
    </Modal>
  );
};

LockModal.propTypes = {
  lock: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
};

const VacationLock = ({ className, showHeader = true }) => {
  const { company, updateCompany } = useContext(UserContext);
  const { vacationLock = [] } = company || {};
  const [selectedLock, selectLock] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleAddLockClick = () => {
    setIsOpenModal(true);
  };

  const handleEditLockClick = lock => {
    selectLock(lock);
    setIsOpenModal(true);
  };

  const handleDeleteLockClick = lock => {
    const { start, end } = lock;
    let _vacationLock = [...vacationLock].filter(
      ({ start: _start, end: _end }) => _start !== start && _end !== end
    );
    updateCompany(company, { vacationLock: _vacationLock });
  };

  useEffect(() => {
    !isOpenModal && selectLock();
  }, [isOpenModal]);

  return (
    <>
      <LockModal
        lock={selectedLock}
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
      />
      <Card className={className}>
        {showHeader && (
          <FalconCardHeader
            title="Días bloqueados para vacaciones"
            endEl={
              <Button
                className="rounded-pill"
                size="sm"
                variant="falcon-default"
                onClick={handleAddLockClick}
              >
                <FontAwesomeIcon icon="plus" className="me-2" />
                Añadir días
              </Button>
            }
          />
        )}
        <Card.Body
          className={classNames('bg-light', {
            'p-0': !vacationLock?.length
          })}
        >
          <ListGroup>
            {vacationLock.map((lock, index) => (
              <ListGroup.Item key={`VacationLock-Item-${index}`}>
                <Flex justifyContent="between" alignItems="center">
                  <div>
                    {lock?.title}
                    <SoftBadge className="ms-2">
                      {new Date(lock?.start).toLocaleDateString()}
                      {' - '}
                      {new Date(lock?.end).toLocaleDateString()}
                    </SoftBadge>
                  </div>
                  <Flex>
                    <Button
                      variant="outline-secondary"
                      className="border-0"
                      size="sm"
                      onClick={() => handleEditLockClick(lock)}
                    >
                      <FontAwesomeIcon icon="pen-to-square" />
                    </Button>
                    <Button
                      variant="outline-danger"
                      className="border-0"
                      size="sm"
                      onClick={() => handleDeleteLockClick(lock)}
                    >
                      <FontAwesomeIcon icon="trash" />
                    </Button>
                  </Flex>
                </Flex>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
        {!showHeader && (
          <Card.Footer as={Flex} className="bg-light" justifyContent="end">
            <Button
              className="rounded-pill"
              variant="falcon-default"
              onClick={handleAddLockClick}
            >
              <FontAwesomeIcon icon="plus" className="me-2" />
              Añadir días
            </Button>
          </Card.Footer>
        )}
      </Card>
    </>
  );
};

VacationLock.propTypes = {
  className: PropTypes.string,
  showHeader: PropTypes.bool
};

export default VacationLock;
