import { getUnique } from 'helpers/utils';

export const reviewReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'FILTER':
      return {
        ...state,
        currentFilter: payload,
        reviews: state.allReviews.filter(review => {
          if (payload === 'all') {
            return !review?.archived;
          }
          return review[payload];
        })
      };
    case 'BULK_UPDATE':
      return {
        ...state,
        allReviews: state.allReviews.map(review =>
          payload.ids.includes(review.NO_ID_FIELD)
            ? { ...review, [payload.key]: payload.value }
            : review
        )
      };
    case 'UPDATE':
      return {
        ...state,
        reviews: getUnique(
          [
            ...(payload || []),
            ...state.reviews.map(review => {
              const reviewUpdated = payload.find(
                ({ NO_ID_FIELD }) => NO_ID_FIELD === review.NO_ID_FIELD
              );
              return reviewUpdated ? { ...review, ...reviewUpdated } : review;
            })
          ],
          'NO_ID_FIELD'
        ).filter(review => {
          if (state?.currentFilter === 'all') {
            return !review?.archived;
          }
          return review[payload];
        }),
        allReviews: getUnique(
          [
            ...(payload || []),
            ...state.allReviews.map(review => {
              const reviewUpdated = payload.find(
                ({ NO_ID_FIELD }) => NO_ID_FIELD === review.NO_ID_FIELD
              );
              return reviewUpdated ? { ...review, ...reviewUpdated } : review;
            })
          ],
          'NO_ID_FIELD'
        )
      };
    case 'ARCHIVE':
      return {
        ...state,
        allReviews: state.allReviews.map(review =>
          payload.includes(review.NO_ID_FIELD)
            ? { ...review, archived: !review?.archived }
            : review
        ),
        reviews: state.allReviews
          .map(review =>
            payload.includes(review.NO_ID_FIELD)
              ? { ...review, archived: !review?.archived }
              : review
          )
          .filter(({ archived }) => {
            if (state?.currentFilter === 'all') {
              return !archived;
            }
            if (state?.currentFilter === 'archived') {
              return archived;
            }
            return false;
          })
      };
    case 'DELETE':
      return {
        ...state,
        allReviews: state.reviews.filter(
          review => !payload.includes(review.NO_ID_FIELD)
        ),
        reviews: state.reviews.filter(
          review => !payload.includes(review.NO_ID_FIELD)
        )
      };
    case 'RESET':
      return {
        ...state,
        reviews: state.allReviews.filter(review => {
          return !review?.archived;
        }),
        currentFilter: 'all'
      };
    case 'RESET_QUESTIONAIRES':
      return {
        ...state,
        allReviews: payload,
        reviews: payload.filter(review => {
          return !review?.archived;
        }),
        currentFilter: 'all'
      };
    default:
      return state;
  }
};
