import FalconCardHeader from 'components/common/FalconCardHeader';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DangerZone = () => {
  return (
    <Card>
      <FalconCardHeader title="Eliminar empleado" className="bg-light" />
      <Card.Body>
        <p className="fs--1">
          Una vez elimines al empleado, se desvinculará de la empresa.
        </p>
        <Button as={Link} to="#!" variant="falcon-danger" className="w-100">
          Eliminar empleado
        </Button>
      </Card.Body>
    </Card>
  );
};

export default DangerZone;
