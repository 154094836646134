import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Row, Col, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { onSnapshot } from '@firebase/firestore';
import FalconLightBox from 'components/common/FalconLightBox';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import { UserContext } from 'context/Context';
import ChatMessageOptions from './ChatMessageOptions';
import ChecklistMessage from './ChecklistMessage';
import CongratMessage from './CongratMessage';
import DeletedMessage from './DeletedMessage';
import EventMessage from './EventMessage';
import HappyQuestionaireMessage from './HappyQuestionaireMessage';
import ImageMessage from './ImageMessage';
import ObjectiveMessage from './ObjectiveMessage';
import QuestionaireMessage from './QuestionaireMessage';
import TextMessage from './TextMessage';
import WelcomeMessage from './WelcomeMessage';
import ReferrerBubble from './ReferrerBubble';
import { getColorByName } from 'helpers/utils';

const messageType = {
  checklist: ChecklistMessage,
  congrat: CongratMessage,
  deleted: DeletedMessage,
  event: EventMessage,
  happy_questionaire: HappyQuestionaireMessage,
  image: ImageMessage,
  objective: ObjectiveMessage,
  questionaire: QuestionaireMessage,
  text: TextMessage,
  welcome: WelcomeMessage
};

const rowMessages = ['deleted', 'text'];

const Message = ({ chat, message, next, isSameDate, isSameUser }) => {
  const [data, setData] = useState();
  const { happybot, me, partners = [] } = useContext(UserContext);
  const {
    NO_ID_FIELD,
    createdAt,
    from,
    reactions: rawReactions = [],
    referrer,
    status = 'delivered',
    type
  } = data || {};
  const { from: nextFrom } = next || {};
  const isGroup = ['company', 'group'].includes(chat.type);
  const user =
    [happybot, ...partners].find(({ ref }) => ref?.path === from?.path) || {};
  const { avatar, name } = user;
  const color = getColorByName(name);
  const isLeft = from?.path !== me?.ref?.path;
  const reactions = Object.entries(
    rawReactions.reduce((all, { reaction }) => {
      return { ...all, [reaction]: (all?.[reaction] || 0) + 1 };
    }, {})
  );

  const MessageType = messageType?.[type] || 'div';

  const scrollToMessage = referrer => {
    const message = document.querySelector(
      `.chat-message[data-id="${referrer?.id}"]`
    );
    message?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    message?.classList?.add('highlight');
    setTimeout(() => {
      message?.classList?.remove('highlight');
    }, 2000);
  };

  const handleChanges = async doc => {
    const data = await doc.data();
    setData({ NO_ID_FIELD: doc.id, ...data });
  };

  useEffect(() => {
    onSnapshot(message.ref, handleChanges);
  }, []);

  return (
    <Flex
      className={classNames('p-3', {
        'd-block': !isLeft,
        'pt-0': isSameUser && isSameDate,
        'pb-0': from?.path === nextFrom?.path
      })}
    >
      {isLeft && (isGroup || from?.id === happybot.id) && (
        <div style={{ width: '36px' }}>
          {(!isSameUser || !isSameDate) && (
            <Avatar size="l" className="me-2" src={avatar} />
          )}
        </div>
      )}
      <div
        className={classNames('flex-1', {
          'd-flex justify-content-end': !isLeft
        })}
      >
        <div
          className={classNames('w-xxl-75', {
            'w-100': !isLeft
          })}
        >
          <Flex
            alignItems="center"
            className={classNames('hover-actions-trigger', {
              'flex-end-center': !isLeft,
              'align-items-center': isLeft
            })}
          >
            {!isLeft && <ChatMessageOptions message={data} />}
            {data?.attachments ? (
              <div className="chat-message chat-gallery">
                {data?.text && (
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: data?.text ? data?.text : data
                    }}
                  />
                )}
                <FalconLightBoxGallery images={data?.attachments}>
                  {setImgIndex => (
                    <Row className="mx-n1">
                      {data?.attachments.map((img, index) => {
                        return (
                          <Col
                            xs={6}
                            md={4}
                            className="px-1"
                            style={{ minWidth: 50 }}
                            key={index}
                          >
                            <Image
                              fluid
                              rounded
                              className="mb-2 cursor-pointer"
                              src={img}
                              alt=""
                              onClick={() => setImgIndex(index)}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                </FalconLightBoxGallery>
              </div>
            ) : (
              <>
                <div
                  className={classNames('p-2 rounded chat-message', {
                    'bg-200': isLeft,
                    'bg-mybubble': !isLeft,
                    'mb-4': !!reactions.length
                  })}
                  data-id={NO_ID_FIELD}
                >
                  {isLeft &&
                    (isGroup || from?.id === happybot.id) &&
                    (!isSameUser || !isSameDate) && (
                      <h6 style={{ color }}>{name}</h6>
                    )}
                  {referrer && (
                    <ReferrerBubble
                      referrer={referrer}
                      onClick={() => scrollToMessage(referrer)}
                    />
                  )}
                  <Flex
                    wrap="wrap"
                    alignItems={rowMessages.includes(type) ? 'end' : ''}
                    direction={rowMessages.includes(type) ? 'row' : 'column'}
                  >
                    <div
                      className={classNames('max-w-100', {
                        'flex-grow-1': rowMessages.includes(type)
                      })}
                    >
                      <MessageType message={data} />
                    </div>
                    {data?.attachment && (
                      <FalconLightBox image={data?.attachment}>
                        <Image
                          fluid
                          rounded
                          src={data?.attachment}
                          width={150}
                          alt=""
                        />
                      </FalconLightBox>
                    )}

                    <div
                      className={classNames(
                        'text-500 ms-2 mt-2 mb-n1 fs--2 text-end'
                      )}
                    >
                      {dayjs(createdAt).format('HH:mm')}
                      {!isLeft && !!data && !!status && (
                        <FontAwesomeIcon
                          icon={classNames({
                            'check-double':
                              status === 'seen' || status === 'sent',
                            check: status === 'delivered'
                          })}
                          className="ms-2"
                        />
                      )}
                    </div>
                  </Flex>
                  {!!reactions.length && (
                    <Flex
                      className="mt-1 mb-n4"
                      justifyContent={isLeft ? 'start' : 'end'}
                    >
                      <Badge
                        pill
                        bg="white"
                        className="border shadow-sm"
                        style={{ paddingTop: '6px' }}
                      >
                        {reactions.map(([key, value], index) => (
                          <span key={`Reaction-${NO_ID_FIELD}-${index}`}>
                            <span
                              className={classNames('fs--1', {
                                'ms-1': index > 0
                              })}
                            >
                              {key}
                            </span>
                            {value > 1 && (
                              <span className="text-dark ms-1">{value}</span>
                            )}
                          </span>
                        ))}
                      </Badge>
                    </Flex>
                  )}
                </div>
              </>
            )}
            {isLeft && <ChatMessageOptions message={data} />}
          </Flex>
        </div>
      </div>
    </Flex>
  );
};
Message.propTypes = {
  chat: PropTypes.object.isRequired,
  message: PropTypes.object,
  next: PropTypes.object,
  isSameDate: PropTypes.bool,
  isSameUser: PropTypes.bool
};

export default Message;
