import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import { UserContext } from 'context/Context';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { diff } from 'deep-object-diff';

const defaultColumns = [
  {
    accessor: 'name',
    Header: 'Nombre',
    headerProps: {
      className: 'py-3 ps-0 min-w-220px w-220px'
    },
    cellProps: {
      className: 'ps-0'
    },
    Cell: rowData => {
      const user = rowData.row.original;
      let { id, avatar, department, name } = user;
      return (
        <Flex alignItems="center">
          <Avatar size="xl" src={avatar} name={name} />
          <div className="ps-2 text-start">
            <Link to={`/user/profile/${id}`} className="text-800">
              <h6 className="mb-0">{name}</h6>
            </Link>
            <p className="mb-0 fs--2">{department}</p>
          </div>
        </Flex>
      );
    }
  }
];

const UserSelection = ({ onChangeSelection, page, selectedRowIds }) => {
  // const [selectedRowIds, setSelectedRowIds] = useState(selectedRowIdsProp);
  // useEffect(() => {
  //   setSelectedRowIds(selectedRowIdsProp);
  // }, []);
  useEffect(() => {
    const selection = Object.keys(selectedRowIds)
      .map(selectedId => page?.find(({ id }) => id === selectedId)?.original)
      .filter(user => user);
    onChangeSelection?.(selection);
  }, [selectedRowIds]);
  return null;
};

const UserList = React.memo(
  ({
    innerRef,
    onChangeSelection,
    users,
    headerClassName,
    extraColumns = [],
    refresh = false,
    tableProps: { search, ...tableProps } = {},
    ...rest
  }) => {
    const { partners = [] } = useContext(UserContext);
    users = users || partners;

    const columns = [
      ...defaultColumns.map(column => ({
        ...column,
        headerProps: {
          ...(column?.headerProps || {}),
          className: `${column?.headerProps?.className || ''} ${
            tableProps?.sortable ? '' : 'd-none'
          }`.trim()
        }
      })),
      ...extraColumns
    ];

    return (
      <AdvanceTableWrapper
        innerRef={innerRef}
        refresh={refresh}
        columns={columns}
        data={users}
        {...tableProps}
      >
        <Card {...rest}>
          {search && (
            <Card.Header className="pt-0 px-0">
              <Row className="align-items-center">
                <Col>
                  <AdvanceTableSearchBox table placeholder="Buscar..." />
                </Col>
              </Row>
            </Card.Header>
          )}
          <UserSelection table onChangeSelection={onChangeSelection} />
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName={classNames(
                'bg-200 text-900 text-nowrap align-middle',
                headerClassName
              )}
              rowClassName="align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          {tableProps?.pagination && tableProps.perPage < users.length && (
            <Card.Footer>
              <AdvanceTableFooter
                rowCount={users.length}
                table
                rowInfo
                navButtons
              />
            </Card.Footer>
          )}
        </Card>
      </AdvanceTableWrapper>
    );
  },
  (prev, next) => {
    const usersDiffs = diff(prev.users, next.users);
    const render =
      Object.values(usersDiffs).some(
        obj => Object.keys(obj).length > 1 || !obj.status
      ) || prev.onChangeSelection !== next.onChangeSelection;
    return !render;
  }
);

UserSelection.propTypes = {
  onChangeSelection: PropTypes.func,
  page: PropTypes.array,
  selectedRowIds: PropTypes.object
};

UserList.propTypes = {
  innerRef: PropTypes.object,
  onChangeSelection: PropTypes.func,
  headerClassName: PropTypes.string,
  extraColumns: PropTypes.array,
  refresh: PropTypes.bool,
  tableProps: PropTypes.object,
  users: PropTypes.array
};

export default UserList;
