import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import RadarChart from './RadarChart';

const IndexRadar = ({ header, user, ...rest }) => {
  return (
    <Card {...rest}>
      {header || (
        <Card.Header className="pb-0">
          <h6 className="mb-0">Índices del empleado</h6>
        </Card.Header>
      )}
      <Card.Body className="p-0">
        <RadarChart user={user} />
      </Card.Body>
    </Card>
  );
};

IndexRadar.propTypes = {
  header: PropTypes.node,
  user: PropTypes.object
};

export default IndexRadar;
