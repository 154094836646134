import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import * as d3 from 'd3';
import { diff } from 'deep-object-diff';

const ForceGraph = ({ nodes, size }) => {
  const [animatedNodes, setAnimatedNodes] = useState([]);
  const navigate = useNavigate();
  const { width, height } = size;

  const radiusScale = value => {
    const fx = d3
      .scaleSqrt()
      .range([15, 55])
      .domain([
        0.95 *
          d3.min(nodes, item => {
            return item.v;
          }),
        1.05 *
          d3.max(nodes, item => {
            return item.v;
          })
      ]);
    return fx(value);
  };

  // Tooltip
  const showTooltip = function (e, item) {
    e.target.classList.add('opacity-75');
    d3.select('.d3-tooltip').transition().duration(200);
    d3.select('.d3-tooltip')
      .style('opacity', 1)
      .style('border', `1px solid ${item.color}`)
      .text(item.name + ': ' + item.v)
      .style('left', e.clientX - 40 + 'px')
      .style('top', e.clientY - 40 + 'px');
  };

  const moveTooltip = function (e) {
    e.target.classList.add('opacity-75');
    d3.select('.d3-tooltip')
      .style('opacity', 1)
      .style('left', e.clientX - 40 + 'px')
      .style('top', e.clientY - 40 + 'px');
  };

  const hideTooltip = function (e) {
    e.target.classList.remove('opacity-75');
    d3.select('.d3-tooltip').transition().duration(200).style('opacity', 0);
  };

  useEffect(() => {
    const simulation = d3
      .forceSimulation()
      .velocityDecay(0.7)
      .force('x', d3.forceX().strength(0.05))
      .force('y', d3.forceY().strength(0.05))
      .force(
        'collide',
        d3.forceCollide(d => radiusScale(d.v) + 4)
      );

    simulation.on('tick', () => {
      setAnimatedNodes([...simulation.nodes()]);
    });

    simulation.nodes([...nodes]);

    simulation.alpha(0.01).restart();

    return () => simulation.stop();
  }, [nodes]);

  const max = nodes.reduce((max, { v }) => (v > max ? v : max), 0);

  const handleClick = word => {
    navigate(`/feedback/s/${word}`);
  };

  return (
    <>
      {animatedNodes.map(node => {
        const radius = radiusScale(node.v);
        return (
          <g
            key={`Topic-${node.name}-${node.color}`}
            className="overflow-hidden"
            transform={`translate(${width / 2 + node.x}, ${
              height / 2 + node.y
            })`}
            onMouseOver={e => showTooltip(e, node)}
            onMouseMove={e => moveTooltip(e)}
            onMouseLeave={e => hideTooltip(e)}
            style={{
              cursor: 'pointer'
            }}
            onClick={() => handleClick(node.name)}
          >
            <circle
              r={radius}
              fill={node.color}
              strokeWidth="0"
              style={{ opacity: (node.v + max / 4) / max }}
            />
            <text
              dy="4"
              className="position-absolute"
              fill="#fff"
              textAnchor="middle"
              // fontSize={`${(node.v + max / 2) / max}rem`}
              fontSize={`${radius / (5 * node.name.length + 10)}rem`}
              fontWeight="normal"
              style={{ pointerEvents: 'none' }}
            >
              {node.name}
            </text>
          </g>
        );
      })}
    </>
  );
};

const BubbleChart = React.memo(
  ({ data = [] }) => {
    const maxValue = data.reduce((max, { v }) => Math.max(max, v), 0);
    const maxItems = data.filter(({ v }) => v === maxValue);
    const width = ((data.length + maxItems.length + 16) * 388) / 45;
    const height = ((data.length + maxItems.length + 16) * 448) / 45;

    return (
      <div className="position-relative w-100">
        <div className="d3-tooltip"></div>
        <svg
          className="packed-bubble-svg h-100 w-100"
          viewBox={'0 0 ' + width + ' ' + height}
        >
          <style>{`
            .position-absolute {
              position: absolute;
            }
            .overflow-hidden {
              overflow: hidden;
            }
          `}</style>
          <ForceGraph nodes={data} size={{ width, height }} />
        </svg>
      </div>
    );
  },
  (prev, next) => {
    const diffs = diff(prev?.data, next?.data);
    const diffsData = Object.values(diffs)
      .map(obj => {
        const values = obj && Object.values(obj).filter(value => value);
        return values?.length ? values : undefined;
      })
      .filter(values => values);
    const render = !!diffsData?.length;
    return !render;
  }
);

ForceGraph.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  size: PropTypes.object
};

BubbleChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default BubbleChart;
