import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import UserProvider from 'components/UserProvider';
import 'dayjs/locale/es';

const PrintsLayout = () => {
  return (
    <>
      <div className="container-fluid">
        <Outlet />
      </div>
    </>
  );
};

const PrintsLayoutWithContext = () => {
  return (
    <UserProvider>
      <style>{`
      body { background: white !important; }

      @media print {
        @page {
          size: A4;
        }

        section, tr, td, .card.border, .recent-activity-timeline {
          break-inside: avoid;
        }

        .section-title {
          break-before: always;
        }
      }
      `}</style>
      <PrintsLayout />
    </UserProvider>
  );
};

PrintsLayout.propTypes = {
  date: PropTypes.object
};

export default PrintsLayoutWithContext;
