import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import { ReportsContext } from 'context/Context';
import { groupBy } from 'helpers/utils';

const Departments = ({ className, end = 10, ...rest }) => {
  const { partners = [], indexes = [] } = useContext(ReportsContext);
  const dataByUser = Object.entries(groupBy(indexes, 'userId'))
    .map(([userId, indexes]) => {
      const user = partners.find(({ id } = {}) => id === userId);
      if (!user) {
        return;
      }
      const data = indexes
        .sort((i1, i2) => (i1.createdAt < i2.createdAt ? -1 : 1))
        .map(({ createdAt, value }) => ({ createdAt, value }))
        .pop();
      return { ...user, data };
    })
    .filter(data => data);
  const dataByDepartment = Object.entries(groupBy(dataByUser, 'department'))
    .map(([department, users]) => {
      const average = Math.round(
        users.reduce((total, { data }) => total + data.value, 0) / users.length
      );
      return { name: department, value: average, users };
    })
    .sort(({ value: v1 }, { value: v2 }) => (v1 > v2 ? -1 : 1));

  return (
    <Card
      className={classNames('border border-3 shadow-none', className)}
      {...rest}
    >
      <Card.Body>
        {!dataByDepartment.length && 'No hay datos de departamentos'}
        {dataByDepartment
          .sort((p1, p2) => (p1.value > p2.value ? -1 : 1))
          .slice(0, end)
          .map(({ name, ...props }, index) => (
            <Department
              {...props}
              key={`Department-${name}`}
              name={name}
              isLast={index === dataByDepartment.length - 1}
            />
          ))}
      </Card.Body>
    </Card>
  );
};

const Department = ({ name, users, value, isLast }) => (
  <Flex className={classNames('align-items-center', { 'mb-3': !isLast })}>
    <Avatar name={name} size="2xl" />
    <div className="ms-3 flex-grow-1 w-0">
      <h6 className="mb-0 fw-semi-bold">{name}</h6>
      <p className="text-500 fs--2 mb-0">
        {users.map(({ NO_ID_FIELD, firstname }, index) => (
          <span key={`Name-${NO_ID_FIELD}`}>
            <Link className="text-500" to={`/user/profile/${NO_ID_FIELD}`}>
              {firstname}
            </Link>
            {index < users.length - 1 && ', '}
          </span>
        ))}
      </p>
    </div>
    <AvatarGroup className="justify-content-end">
      {users.map(({ avatar, name, id }) => {
        return (
          <Avatar
            key={`Department-Avatar-${id}`}
            src={avatar && avatar}
            name={name && name}
            className="border border-3 rounded-circle border-light bg-light"
          />
        );
      })}
    </AvatarGroup>
    <div className="text-end" style={{ width: 40 }}>
      <h5 className="mb-0">{value}</h5>
    </div>
  </Flex>
);

Department.propTypes = {
  name: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  value: PropTypes.number,
  isLast: PropTypes.bool
};

Departments.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  end: PropTypes.number,
  header: PropTypes.node,
  type: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape(Department.propTypes))
};

export default Departments;
