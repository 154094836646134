import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';
import { Card } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import { AppreciationContext, UserContext } from 'context/Context';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

dayjs.extend(relativeTime);
dayjs.locale('es');

const UserCell = ({ user = {} }) => {
  const { NO_ID_FIELD, avatar, department, name } = user;
  return (
    <Flex alignItems="center">
      <Avatar size="xl" src={avatar} name={name} />
      <div className="ps-2">
        <Link to={`/user/profile/${NO_ID_FIELD}`} className="text-800">
          <h6 className="mb-0 text-800">{name}</h6>
        </Link>
        <p className="mb-0 fs--2">{department}</p>
      </div>
    </Flex>
  );
};

UserCell.propTypes = {
  user: PropTypes.object
};

const Appreciation = ({ appreciation, userIdKey, isLast }) => {
  const { partners = [] } = useContext(UserContext);
  const { createdAt, text, value } = appreciation;
  const userId = appreciation[userIdKey];
  const user = partners.find(({ NO_ID_FIELD }) => NO_ID_FIELD === userId);
  const time = dayjs(createdAt)
    .calendar(null, {
      sameDay: `[${dayjs().to(dayjs(createdAt))}]`,
      lastDay: '[ayer], HH:mm',
      lastWeek: 'dddd, HH:mm',
      sameElse: 'ddd, D MMM, HH:mm'
    })
    .replace('.', '');

  return (
    <Flex
      className={classNames('fs--1', {
        'border-bottom border-200 pb-2 mb-2': !isLast
      })}
    >
      <div className="ms-1 ps-3 flex-1">
        <UserCell user={user} />
        <div className="mt-1 ps-5 ms-n2">
          <div className="bg-light rounded text-600 p-2 w-100">{text}</div>
          <span className="fs--2 text-500">{time}</span>
        </div>
      </div>
      <div className="me-3">
        <h5 className="min-w-60px text-end">{value} 👏🏼</h5>
      </div>
    </Flex>
  );
};

const MyAppreciations = () => {
  const { myAppreciations = [] } = useContext(AppreciationContext);

  return (
    <Card>
      <FalconCardHeader
        title="Reconocimientos enviados"
        titleTag="h5"
        className="bg-light"
      />
      {(!myAppreciations.length && (
        <Card.Body className="fs--1 text-800">
          No has enviado ningún reconocimento todavía este mes
        </Card.Body>
      )) || (
        <SimpleBarReact>
          <Card.Body className="px-0">
            {myAppreciations.map((appreciation, index) => (
              <Appreciation
                key={`Appreciation-${appreciation.NO_ID_FIELD}`}
                appreciation={appreciation}
                userIdKey="targetId"
                isLast={index === myAppreciations.length - 1}
              />
            ))}
          </Card.Body>
        </SimpleBarReact>
      )}
    </Card>
  );
};

const AppreciationsToMe = () => {
  const { appreciationsToMe = [] } = useContext(AppreciationContext);
  return (
    <Card>
      <FalconCardHeader
        title="Reconocimientos recibidos"
        titleTag="h5"
        className="bg-light"
      />
      {(!appreciationsToMe.length && (
        <Card.Body className="fs--1 text-800">
          No te han enviado ningún reconocimento todavía este mes
        </Card.Body>
      )) || (
        <SimpleBarReact>
          <Card.Body className="px-0">
            {appreciationsToMe.map((appreciation, index) => (
              <Appreciation
                key={`Appreciation-${appreciation.NO_ID_FIELD}`}
                appreciation={appreciation}
                userIdKey="senderId"
                isLast={index === appreciationsToMe.length - 1}
              />
            ))}
          </Card.Body>
        </SimpleBarReact>
      )}
    </Card>
  );
};

const UserAppreciations = () => {
  return (
    <>
      <div>
        <MyAppreciations />
      </div>
      <div className="mt-3">
        <AppreciationsToMe />
      </div>
    </>
  );
};

Appreciation.propTypes = {
  appreciation: PropTypes.object,
  userIdKey: PropTypes.string,
  isLast: PropTypes.bool
};

export default UserAppreciations;
