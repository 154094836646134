import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IndexGraph = ({
  title,
  color,
  data,
  className,
  titleClassName,
  svgOptions = {},
  circleOptions = {},
  style
}) => {
  return (
    <div style={style} className={classNames('text-center', className)}>
      <div style={{ '--falcon-circle-progress-bar': data }}>
        <svg
          className="circle-progress-svg"
          width="26"
          height="26"
          viewBox="0 0 120 120"
          {...svgOptions}
        >
          <circle
            className="progress-bar-rail"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            strokeWidth="12"
            {...circleOptions}
          ></circle>
          <circle
            className="progress-bar-top"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            stroke={color}
            strokeWidth="12"
            {...circleOptions}
          ></circle>
        </svg>
      </div>
      <h6
        className={classNames('mb-0', titleClassName, {
          'text-700': !titleClassName?.includes('text-')
        })}
      >
        {title}
      </h6>
    </div>
  );
};

IndexGraph.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number
  ]),
  color: PropTypes.string,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  svgOptions: PropTypes.object,
  circleOptions: PropTypes.object,
  style: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default IndexGraph;
