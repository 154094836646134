import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Alert, Button, Image, Modal } from 'react-bootstrap';
import { UserContext } from 'context/Context';
import image from 'assets/img/icons/spot-illustrations/4.svg';

const AdminModal = ({ link, show, setShow, status }) => {
  return (
    <Modal backdrop="static" centered show={show}>
      <Image src={image} className="px-6 pt-3" />
      <Modal.Body className="p-5">
        {status === 'canceled' ? (
          <div>
            <div className="text-center">
              <Alert.Heading>Necesitas estar suscrito a Happyfy</Alert.Heading>
              <p className="mb-0">
                Este plan ya no continuará hasta que se añada un método de pago.
              </p>
            </div>
            <Button
              className="mt-5 rounded-pill text-nowrap w-100"
              as={Link}
              to={link}
              size="lg"
              target="_blank"
            >
              Suscríbete
            </Button>
          </div>
        ) : (
          <div>
            <div className="text-center">
              <Alert.Heading>Tu plan ha sido suspendido</Alert.Heading>
              <p className="mb-0">
                Ha habido un problema al realizar el cobro.
              </p>
              <p>Verifica tu tarjeta o añade un método de pago válido.</p>
              <p className="small mt-5">
                Se van a realizar 4 intentos de cobro durante esta semana. Si
                tras los reintentos la situación no ha cambiado se cancelará la
                suscripción
              </p>
            </div>
            <Button
              className="mt-1 rounded-pill text-nowrap w-100"
              as={Link}
              to={link}
              size="lg"
              target="_blank"
            >
              Actualiza la información de pago
            </Button>
            <div className="text-center">
              <Button variant="link" onClick={() => setShow(null)}>
                Cerrar
              </Button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

AdminModal.propTypes = {
  link: PropTypes.string,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  status: PropTypes.string
};

const EmployeeModal = ({ show, setShow, status }) => {
  return (
    <Modal backdrop="static" centered show={show}>
      <Image src={image} className="px-6 pt-3" />
      <Modal.Body className="p-5">
        <div>
          <div className="text-center">
            <Alert.Heading>
              Happyfy va a ser desactivado próximamente
            </Alert.Heading>
            <p>
              Para asegurar que este servicio continúe disponible y operativo,
              es necesario completar una acción pendiente relacionada con su
              renovación.
            </p>
            <p className="fw-bold">Avisa a tu responsable de RRHH.</p>
            {status === 'past_due' && (
              <Button
                className="mt-5 rounded-pill text-nowrap w-100"
                size="lg"
                onClick={() => setShow(null)}
              >
                Entendido
              </Button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

EmployeeModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  status: PropTypes.string
};

const TrialBanner = () => {
  const [link, setLink] = useState();
  const [show, setShow] = useState(false);
  const { company, me, subscription } = useContext(UserContext);
  const { status } = subscription || {};

  const refreshRedirectionLink = async () => {
    try {
      const redirection = await (
        await fetch(
          `${process.env.REACT_APP_FIREBASE_URL}/redirectToBillingPortal`,
          {
            method: 'POST',
            body: JSON.stringify({
              companyId: company?.NO_ID_FIELD
            })
          }
        )
      ).text();

      setLink(redirection);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setShow(false);
  }, [company?.NO_ID_FIELD]);

  useEffect(() => {
    !link && show && status && refreshRedirectionLink();

    if (show === null) {
      return;
    }

    setShow(['canceled', 'past_due'].includes(status));
  }, [link, show, status]);

  if (show === null) {
    return null;
  }

  return me?.type === 'admin' ? (
    <AdminModal link={link} show={show} setShow={setShow} status={status} />
  ) : (
    <EmployeeModal show={show} setShow={setShow} status={status} />
  );
};

export default TrialBanner;
