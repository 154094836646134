import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import AppContext, { UserContext } from 'context/Context';
import Countdown from 'components/common/Countdown';
import Flex from 'components/common/Flex';

const getCircleStackIcon = (icon, transform) => (
  <span className="fa-stack ms-n1 me-3">
    <FontAwesomeIcon icon="circle" className="text-200 fa-stack-2x" />
    <FontAwesomeIcon
      icon={icon}
      transform={transform ?? ''}
      className="text-primary fa-stack-1x"
      inverse
    />
  </span>
);

const EventModalMediaContent = ({ icon, heading, content, children }) => (
  <Flex className="mt-3">
    {getCircleStackIcon(icon)}
    <div className="flex-1">
      <h6>{heading}</h6>
      {children || <p className="mb-0 text-justify">{content}</p>}
    </div>
  </Flex>
);

const CalendarObjectiveModal = ({
  setIsOpenModal,
  isOpenModal,
  modalContent
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { partners = [] } = useContext(UserContext);

  const handleClose = () => {
    setIsOpenModal(!isOpenModal);
  };

  const { title, end, start } = isOpenModal && modalContent.event;
  const { NO_ID_FIELD, description, participants, reward } =
    isOpenModal && modalContent.event.extendedProps;

  return (
    <Modal
      show={isOpenModal}
      onHide={handleClose}
      contentClassName="border"
      centered
    >
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className="bg-light px-x1 border-bottom-0"
      >
        <Modal.Title>
          <h5 className="mb-0">{title}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-x1 pb-x1 pt-1 fs--1">
        {description && (
          <EventModalMediaContent
            icon="align-left"
            heading="Descripción"
            content={description}
          />
        )}
        {participants?.length && (
          <EventModalMediaContent
            icon="users"
            heading="Participantes"
            content={participants.map((ref, index) => {
              const user = partners.find(
                partner => partner.ref?.path === ref?.path
              );
              const { NO_ID_FIELD, name } = user || {};
              return (
                <>
                  <Link
                    to={`/user/profile/${NO_ID_FIELD}`}
                    className={classNames('text-700', {
                      'ps-1': index > 0
                    })}
                  >
                    {name}
                  </Link>
                  {index < participants.length - 1 && <span>,</span>}
                </>
              );
            })}
          />
        )}
        {(end || start) && (
          <EventModalMediaContent icon="calendar-check" heading="Fecha y hora">
            <p className="mb-1">
              {dayjs(start).format('dddd, DD MMMM YYYY, hh:mm A')}
              {end && (
                <>
                  -<br />
                  {dayjs(end).format('dddd, MMMM DD, YYYY, hh:mm A')}
                </>
              )}
            </p>
            <Countdown date={start} />
          </EventModalMediaContent>
        )}
        {reward && (
          <EventModalMediaContent
            icon="align-left"
            heading="Recompensa"
            content={reward}
          />
        )}
      </Modal.Body>
      <Modal.Footer className="bg-light px-x1 border-top-0">
        <Button
          as={Link}
          to={`/company/objectives/${NO_ID_FIELD}`}
          variant="falcon-primary"
          size="sm"
        >
          <span>Ver más detalles</span>
          <FontAwesomeIcon icon="angle-right" className="fs--2 ms-1" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EventModalMediaContent.propTypes = {
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string,
  children: PropTypes.node
};

CalendarObjectiveModal.propTypes = {
  setIsOpenModal: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  modalContent: PropTypes.object.isRequired
};

export default CalendarObjectiveModal;
