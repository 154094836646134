import React, { forwardRef, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, Col, Form, Row, Tab, Nav } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import { collection, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import IndexChart from './IndexChart';
import AIButton from 'components/common/AIButton';
import Flex from 'components/common/Flex';
import { AIContext, UserContext } from 'context/Context';
import { groupBy } from 'helpers/utils';
import types from 'components/dashboards/types.json';

dayjs.locale('es');
registerLocale('es', es);
setDefaultLocale('es');

const TabTitle = ({ title, value, icon, diff, diffColor }) => (
  <div className="p-2 text-center cursor-pointer">
    <h6 className="text-800 fs--2 text-nowrap text-capitalize">{title}</h6>
    <Flex alignItems="baseline" className="gap-1 mb-1">
      <h5 className="mb-0">{isNaN(value) ? value : `${value}%`}</h5>
      {diff !== 0 && (
        <h6 className={classNames(`mb-0 fw-semi-bold text-${diffColor}`)}>
          <FontAwesomeIcon icon={icon} className="fs--2 me-1 ms-2" />
          <span className="fs--1">{diff}%</span>
        </h6>
      )}
    </Flex>
  </div>
);

const FormControl = forwardRef(({ value, onClick }, ref) => (
  <Form.Control onClick={onClick} readOnly ref={ref} size="sm" value={value} />
));

const IndexCharts = ({ bodyClassName, className, type, user }) => {
  const { ai } = useContext(AIContext);
  const { company } = useContext(UserContext);
  const db = useFirestore();
  const now = new Date(Date.now());
  const defaultCompareWith = 'yearMonth';
  const defaultStartDate = new Date(
    new Date(now.setMonth(new Date(Date.now()).getMonth() - 11))
      .toISOString()
      .match(/\d+-\d+/)[0]
  );
  const defaultEndDate = null;
  const { firstname, name, description } = user || {};
  // let auxDate = new Date(defaultStartDate);
  // const defaultPrevStartDate = new Date(
  //   new Date(auxDate.setMonth(new Date(auxDate).getMonth() - 11))
  //     .toISOString()
  //     .match(/\d+-\d+/)[0]
  // );
  // auxDate = new Date(defaultStartDate);
  // const defaultPrevEndDate = new Date(
  //   new Date(auxDate.setMonth(new Date(auxDate).getMonth() + 1))
  //     .toISOString()
  //     .match(/\d+-\d+/)[0]
  // );
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  // const [prevStartDate] = useState(defaultPrevStartDate);
  // const [prevEndDate] = useState(defaultPrevEndDate);
  const [compareWith, setCompareWith] = useState(defaultCompareWith);

  const filter = [];
  type && filter.push(where('type', '==', type));
  user?.id && filter.push(where('userId', '==', user?.id));
  startDate &&
    filter.push(where('createdAt', '>', startDate.toISOString().split('T')[0]));
  endDate &&
    filter.push(where('createdAt', '<=', endDate.toISOString().split(':')[0]));
  company?.NO_ID_FIELD &&
    filter.push(where('companyId', '==', `${company?.NO_ID_FIELD}`));
  let indexesQuery = query(collection(db, 'none'));
  if (company?.NO_ID_FIELD) {
    indexesQuery = query(collection(db, 'indexes'), ...filter);
  }
  let { data: raw = [] } = useFirestoreCollectionData(indexesQuery);

  // const prevFilter = [];
  // type && prevFilter.push(where('type', '==', type));
  // prevStartDate &&
  //   prevFilter.push(
  //     where('createdAt', '>', prevStartDate.toISOString().split('T')[0])
  //   );
  // prevEndDate &&
  //   prevFilter.push(
  //     where('createdAt', '<=', prevEndDate.toISOString().split(':')[0])
  //   );
  // const prevIndexesQuery = query(
  //   indexesCollection,
  //   where('companyId', '==', `${company?.NO_ID_FIELD}`),
  //   ...prevFilter
  // );
  // let { data: prevRaw = [] } = useFirestoreCollectionData(prevIndexesQuery);

  raw = raw.map(data => {
    const { createdAt } = data;
    const [date] = new Date(createdAt).toISOString().match(/\d+-\d+/);
    return { ...data, date };
  });
  // prevRaw = prevRaw.map(data => {
  //   const { createdAt } = data;
  //   const [date] = new Date(createdAt).toISOString().match(/\d+-\d+/);
  //   return { ...data, date };
  // });

  const rawByType = Object.entries(groupBy(raw, 'type'))
    .sort(([key1], [key2]) => (key1 < key2 ? -1 : 1))
    .map(([key, values]) => ({ [key]: values }))
    .reduce((result, date) => ({ ...result, ...date }), {});
  // const prevRawByType = Object.entries(groupBy(prevRaw, 'type'))
  //   .sort(([key1], [key2]) => (key1 < key2 ? -1 : 1))
  //   .map(([key, values]) => ({ [key]: values }))
  //   .reduce((result, date) => ({ ...result, ...date }), {});
  // const prevValues = Object.values(prevRawByType);
  const chartDataByType = Object.entries(rawByType)
    .map(([type, values]) => {
      const byDate = groupBy(values, 'date');
      let data = Object.values(byDate).map(values =>
        Math.round(
          values.reduce((total, { value }) => total + value, 0) / values.length
        )
      );
      // const prevByDate = groupBy(prevValues[index], 'date');
      // let prevData = Object.values(prevByDate).map(values =>
      //   Math.round(
      //     values.reduce((total, { value }) => total + value, 0) / values.length
      //   )
      // );
      const average = Math.round(
        data.reduce((total, value) => total + value, 0) / data.length
      );
      // data = [Array(data.length).fill(average), data, prevData];
      data = [Array(data.length).fill(average), data];
      const labels = Object.keys(byDate).map(key => {
        const date = new Date(key);
        const format =
          date.getFullYear() === new Date().getFullYear() ? 'MMM' : 'MMM YYYY';
        return dayjs(date).format(format);
      });
      return { type, labels, data, title: types[type] };
    })
    .reduce((data, { type, ...rest }) => ({ ...data, [type]: rest }), {});

  const { happiness } = chartDataByType;
  if (!type) {
    delete chartDataByType.happiness;
  }
  let chartData = {
    ...(type ? {} : { happiness }),
    ...chartDataByType
  };

  const handleChangePeriod = event => {
    const { currentTarget } = event;
    const { value } = currentTarget;
    const now = new Date(Date.now());
    const startDate = new Date(
      new Date(now.setMonth(new Date(Date.now()).getMonth() - (value - 1)))
        .toISOString()
        .match(/\d+-\d+/)[0]
    );

    setStartDate(startDate);
    setEndDate(null);
  };

  const handleChangeCompareWith = event => {
    const { currentTarget } = event;
    const { value } = currentTarget;
    setCompareWith(value);
  };

  const handleChangeCustom = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  if (typeof Object.values(chartData)[0] === 'undefined') {
    chartData = {
      happiness: {
        color: 'gray-500',
        data: [
          [75, 75, 75],
          [60, 70, 96]
        ],
        labels: [],
        title: 'felicidad'
      }
    };
  }

  const handleAI = () => {
    const chartDataKeys = Object.keys(chartData);
    const chartDataTypes = chartDataKeys.map(key => types[key]);
    const indexes = new Intl.ListFormat('es', {
      style: 'long',
      type: 'conjunction'
    }).format(chartDataTypes);
    const data = Object.entries(chartData).map(([key, values]) => {
      const { labels, data } = values;
      return `**${types[key]}**:\n${labels.map(
        (label, index) => `${label}: ${data?.[1]?.[index]}`
      )}`;
    });
    const _name = name || company?.name;
    const actions = [
      {
        context: [
          {
            role: 'system',
            content:
              'Si se pueden mejorar de alguna manera, da algún ejemplo práctico.'
          }
        ],
        text: '¿Cómo se pueden mejorar?'
      }
    ];
    const context = [
      {
        role: 'system',
        content:
          'Eres un experto analista de datos con años de experiencia, así que sabes tomar decisiones acertadas. Yo soy el manager de la plantilla.'
      },
      {
        role: 'system',
        content: `Estos son los datos a analizar de ${_name} en cuanto a ${indexes}: ${data.join(
          '\n\n'
        )}.`
      },
      {
        role: 'system',
        content: `Sé breve, muy conciso, y evita ser genérico. No redactes la respuesta en un modo demasiado formal. Enuncia y sé lo más gráfico posible a la hora de formatear la respuesta, incluso puedes utilizar listas y/o tablas.`
      },
      (description || company?.description) && {
        role: 'system',
        content: `Esta es la descripción de ${_name}: ${
          description || company?.description
        }`
      },
      {
        role: 'system',
        content: `Cuando te diga a fecha de ${dayjs().format(
          'MMMM [de] YYYY'
        )} obvialo. Cuando te diga que analices hazme una tabla enumerando puntos positivos y negativos de las métricas de ${_name}. Después, haces un resumen de las métricas${
          name ? ` en el caso concreto de ${firstname}` : ''
        }.`
      }
    ].filter(message => message);
    const prompt = `Analiza las métricas de ${indexes} de ${_name} a fecha de ${dayjs().format(
      'MMMM [de] YYYY'
    )}.`;
    const filename = `happyfy-ia-${_name
      .toLowerCase()
      .replace(/ /g, '-')}--${chartDataKeys.join('-')}.pdf`;
    ai({ context, prompt, actions, filename });
  };

  return (
    <Card as={Flex} direction="column" className={className}>
      <div className="position-absolute top-0 end-0 mt-6 me-3">
        <AIButton
          variant="info"
          className="position-relative z-index-2"
          onClick={handleAI}
          tooltip="Analiza las métricas de la gráfica"
          tooltipClass="min-w-220px"
        />
      </div>
      <Tab.Container id="audience-tab" defaultActiveKey={type || 'happiness'}>
        <SimpleBarReact>
          <Card.Header className="p-0">
            <Nav className="nav-tabs border-0 flex-nowrap chart-tab">
              {Object.entries(chartData).map(([key, value = {}]) => {
                const { color, data: arr, title } = value;
                const data = arr?.[1] || [];
                const prevData = arr?.[2] || [];
                const current = Math.round(
                  data.reduce((total, value) => total + value, 0) / data.length
                );

                const prev = Math.round(
                  prevData.reduce((total, value) => total + value, 0) /
                    prevData.length
                );
                const diff = isNaN(prev) ? 0 : current - prev;
                const diffPercentage = Math.min(
                  100,
                  Math.max(
                    0,
                    diff === 0
                      ? 0
                      : Math.abs(Math.round((diff * 100) / (prev || 1)))
                  )
                );
                const icon =
                  diff === 0 ? 'minus' : diff > 0 ? 'caret-up' : 'caret-down';
                const diffColor =
                  diff === 0
                    ? 'info'
                    : diff > 0
                    ? key === 'stress'
                      ? 'danger'
                      : 'success'
                    : key === 'stress'
                    ? 'success'
                    : 'danger';

                return (
                  <Nav.Item
                    key={`HappinessTabTitle-${key}`}
                    className="text-center"
                  >
                    <Nav.Link className="mb-0" eventKey={key}>
                      <TabTitle
                        title={`${title}`}
                        value={color === 'gray-500' ? '-' : current}
                        icon={icon}
                        diff={diffPercentage}
                        diffColor={diffColor}
                      />
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </Card.Header>
        </SimpleBarReact>

        <Card.Body
          className={classNames('h-0 min-h-200px flex-grow-1', bodyClassName)}
        >
          <Tab.Content className="h-100">
            {Object.entries(chartData).map(([key, value]) => {
              return (
                <Tab.Pane
                  key={`HappinessTabPane-${key}`}
                  unmountOnExit
                  eventKey={key}
                  className="h-100"
                >
                  <IndexChart type={key} {...value} />
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </Card.Body>
      </Tab.Container>

      <Card.Footer className="bg-light py-2">
        <Row className="g-0 flex-between-center">
          <Col xs={6} xxl={7}>
            <Flex>
              <Form.Select
                defaultValue={12}
                size="sm"
                className="me-2"
                onChange={handleChangePeriod}
              >
                <option value={1}>Último mes</option>
                <option value={3}>Últimos 3 meses</option>
                <option value={12}>Últimos 12 meses</option>
              </Form.Select>

              <Form.Select
                defaultValue={compareWith}
                size="sm"
                className="me-2"
                onChange={handleChangeCompareWith}
              >
                <option value="prevPeriod">Periodo anterior</option>
                <option value="prevYear">Mismo periodo del año anterior</option>
              </Form.Select>
            </Flex>
          </Col>
          <Col xs={6} xxl={5}>
            <DatePicker
              selected={startDate}
              onChange={handleChangeCustom}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              customInput={<FormControl />}
              dateFormat="dd MMM yy"
              className="form-control form-control-sm ms-2"
            />
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

FormControl.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func
};

TabTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  diff: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  diffColor: PropTypes.string
};

IndexCharts.propTypes = {
  bodyClassName: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  user: PropTypes.object
};

export default IndexCharts;
