import React, { useContext } from 'react';
import classNames from 'classnames';
import { Col, Row, CloseButton } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import AppContext, { ChatContext } from 'context/Context';

const ChatInfoHeader = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { currentChat, setIsOpenChatInfo } = useContext(ChatContext);
  const { type } = currentChat || {};

  return (
    <div className="chat-content-header bg-250">
      <Row className="w-100">
        <Col xs={2}>
          <CloseButton
            variant={isDark ? 'white' : undefined}
            size="sm"
            onClick={() => setIsOpenChatInfo(false)}
          />
        </Col>
        <Col xs={10} as={Flex} alignItems="center">
          <h5 className="mb-0 text-truncate fs-0">
            {classNames({
              'Info del contacto': type === 'user',
              'Info del grupo': type !== 'user'
            })}
          </h5>
        </Col>
      </Row>
    </div>
  );
};

export default ChatInfoHeader;
