import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  limit,
  orderBy,
  query,
  updateDoc,
  where
} from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { FeedbackContext, UserContext } from 'context/Context';
import anonymousAvatar from 'assets/img/icons/blank-profile.svg';
import UserFeedback from './UserFeedback';
import { getUnique } from 'helpers/utils';

const NewFeedbackModal = ({ show, setShow }) => {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <UserFeedback />
    </Modal>
  );
};

NewFeedbackModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func
};

const FeedbackProvider = ({ children, limit: limitProp }) => {
  const db = useFirestore();
  const { company, me, partners = [] } = useContext(UserContext);
  const [showNewFeedbackModal, setShowNewFeedbackModal] = useState(false);
  const [filters, setFilter] = useState();

  let feedbackQuery = query(collection(db, 'none'));
  let feedbackAgentQuery = query(collection(db, 'none'));
  if (company?.NO_ID_FIELD && me?.NO_ID_FIELD) {
    const filters = [
      where('companyId', '==', company?.NO_ID_FIELD),
      me?.type !== 'admin' && where('userId', '==', me?.NO_ID_FIELD),
      limitProp && limit(limitProp)
    ].filter(filter => filter);
    feedbackQuery = query(
      collection(db, 'feedback'),
      ...filters,
      orderBy('createdAt', 'desc')
    );

    if (me?.type !== 'admin') {
      const filters = [
        where('companyId', '==', company?.NO_ID_FIELD),
        where('agent', '==', me?.NO_ID_FIELD)
      ].filter(filter => filter);
      feedbackAgentQuery = query(
        collection(db, 'feedback'),
        ...filters,
        orderBy('updatedAt', 'desc')
      );
    }
  }
  const { data: feedbacksRaw = [] } = useFirestoreCollectionData(feedbackQuery);
  const { data: feedbacksAgent = [] } =
    useFirestoreCollectionData(feedbackAgentQuery);

  const allFeedbacks = getUnique(
    [...(feedbacksRaw || []), ...(feedbacksAgent || [])],
    'NO_ID_FIELD'
  ).sort((f1, f2) => (f1.updatedAt > f2.updatedAt ? -1 : 1));

  const createFeedback = async ({ anonymous, ...params }) => {
    const now = new Date().toISOString();
    return await addDoc(collection(db, 'feedback'), {
      ...params,
      companyId: company?.NO_ID_FIELD,
      createdAt: now,
      updatedAt: now,
      userId: anonymous ? 'anonymous' : me?.NO_ID_FIELD,
      new: true
    });
  };

  const createComment = async (feedback, params) => {
    const { NO_ID_FIELD, comments = [] } = feedback;
    const now = new Date().toISOString();
    comments.push({
      ...params,
      createdAt: now
    });
    await updateDoc(doc(db, 'feedback', NO_ID_FIELD), {
      new: true,
      updatedAt: now,
      comments
    });
  };

  const update = async (feedback, params) => {
    const { NO_ID_FIELD } = feedback;
    const now = new Date().toISOString();
    await updateDoc(doc(db, 'feedback', NO_ID_FIELD), {
      updatedAt: now,
      ...params
    });
  };

  const deleteFeedback = async feedback => {
    const { NO_ID_FIELD } = feedback;
    await deleteDoc(doc(db, 'feedback', NO_ID_FIELD));
  };

  const feedback = allFeedbacks
    .map(feedback => {
      const { userId } = feedback;
      const user =
        userId === 'anonymous'
          ? { avatar: anonymousAvatar, name: 'Anónimo' }
          : partners.find(({ id } = {}) => id === userId) || {};
      const { name, department } = user;
      return {
        ...feedback,
        name,
        department
      };
    })
    .filter(({ feedback, name }) => feedback && name);

  useEffect(() => {
    return () => {
      feedbacksRaw
        .filter(({ new: isNew }) => isNew)
        .forEach(({ NO_ID_FIELD }) => {
          const ref = doc(db, 'feedback', NO_ID_FIELD);
          updateDoc(ref, { new: false });
        });
    };
  }, [feedbacksRaw]);

  // console.log('FEEDBACK >>>', feedback);

  return (
    <FeedbackContext.Provider
      value={{
        feedback: feedback.filter(({ feedback }) => feedback?.length > 4),
        createFeedback,
        createComment,
        deleteFeedback,
        update,
        showNewFeedbackModal,
        setShowNewFeedbackModal,
        filters,
        setFilter
      }}
    >
      <NewFeedbackModal
        show={showNewFeedbackModal}
        setShow={setShowNewFeedbackModal}
      />
      {children}
    </FeedbackContext.Provider>
  );
};

FeedbackProvider.propTypes = {
  children: PropTypes.node.isRequired,
  limit: PropTypes.number
};

export default FeedbackProvider;
