import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, ButtonGroup, Col, Form, Row } from 'react-bootstrap';
import ActionButton from 'components/common/ActionButton';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { collection, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import SoftBadge from 'components/common/SoftBadge';
import { QuestionairesContext } from 'context/Context';
import { UserContext } from 'context/Context';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

dayjs.extend(relativeTime);
dayjs.locale('es');

const Questionaire = ({ questionaire, isSelectedItem, toggleSelectedItem }) => {
  const { NO_ID_FIELD, archived, star, createdAt, title, description } =
    questionaire;
  const { partners = [] } = useContext(UserContext);
  const [marked, setMarked] = useState(star);
  const { questionaireDispatch, deleteQuestionaire, update } =
    useContext(QuestionairesContext);
  const time = dayjs(createdAt)
    .calendar(null, {
      sameDay: 'HH:mm',
      lastDay: 'D MMM',
      lastWeek: 'D MMM',
      sameElse: 'D MMM'
    })
    .replace('.', '');

  const db = useFirestore();
  let userResponseQuery = query(collection(db, 'none'));
  if (NO_ID_FIELD) {
    userResponseQuery = query(
      collection(db, 'questionaires_responses'),
      where('questionaireId', '==', NO_ID_FIELD)
    );
  }
  let { data: responses = [] } = useFirestoreCollectionData(userResponseQuery);
  const newResponses = responses.filter(({ new: isNew }) => isNew);
  const hasResponded = responses.length === partners.length;

  useEffect(() => {
    star !== marked && NO_ID_FIELD && update(NO_ID_FIELD, { star: marked });
  }, [marked]);

  const handleSendQuestionaire = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_URL}/sendQuestionaire?questionaireId=${NO_ID_FIELD}`
      );
      if (response.status !== 200) {
        throw Error();
      }
      toast.success(`Cuestionario enviado`);
    } catch (error) {
      toast.error(`Ha habido un error al enviar el cuestionario`);
    }
  };

  const handleActionButtonClick = async type => {
    questionaireDispatch({ type, payload: [NO_ID_FIELD] });
    switch (type) {
      case 'ARCHIVE':
        await update(NO_ID_FIELD, { archived: !archived });
        toast.success(
          !archived ? 'Cuestionario archivado' : 'Cuestionario desarchivado'
        );
        break;
      case 'DELETE':
        deleteQuestionaire(NO_ID_FIELD);
        toast.success('Cuestionario eliminado');
        break;

      default:
        break;
    }
  };

  return (
    <Row
      className={classNames(
        'border-bottom border-200 hover-actions-trigger hover-shadow hover-z-index-1016 py-2 px-1 mx-0 align-items-center',
        {
          'bg-light': hasResponded,
          'fw-bold': !hasResponded,
          'bg-soft-accent': !!newResponses.length
        }
      )}
    >
      <ButtonGroup
        size="sm"
        className="hover-actions end-0 me-3 p-0 email-row-actions w-auto"
      >
        <ActionButton
          tooltip="Enviar"
          icon="paper-plane"
          size="sm"
          handleClick={handleSendQuestionaire}
        />
        <ActionButton
          className="mx-1"
          tooltip={archived ? 'Desarchivar' : 'Archivar'}
          icon="archive"
          size="sm"
          handleClick={() => handleActionButtonClick('ARCHIVE')}
        />
        <ActionButton
          tooltip="Eliminar"
          icon="trash"
          variant="danger"
          size="sm"
          handleClick={() => handleActionButtonClick('DELETE')}
        />
      </ButtonGroup>
      <Col xs="auto" className="d-none d-sm-block align-self-start">
        <Flex alignItems="center">
          {typeof isSelectedItem !== 'undefined' && (
            <Form.Check
              type="checkbox"
              id="inboxBulkSelect"
              className="form-check mb-0 fs-0"
              checked={isSelectedItem(NO_ID_FIELD)}
              onChange={() => toggleSelectedItem(NO_ID_FIELD)}
            />
          )}
          <FontAwesomeIcon
            onClick={() => setMarked(!marked)}
            icon={marked ? 'star' : ['far', 'star']}
            // transform="down-2"
            className={classNames(
              'ms-1',
              { 'text-warning': marked, 'text-300': !marked },
              'cursor-pointer'
            )}
          />
        </Flex>
      </Col>
      <Col className="p-0">
        <Link
          className="d-block inbox-link"
          to={`/company/questionaires/detail/${NO_ID_FIELD}`}
        >
          <Flex alignItems="center">
            <span className="text-dark">{title}</span>
            <SoftBadge bg="success" className="ms-1">
              {responses?.length || 0}/{partners.length}
            </SoftBadge>
            <span className="mx-1 fw-weight">&ndash;</span>
            <span className="w-0 flex-1 text-600 fw-normal overflow-hidden text-truncate text-nowrap">
              {description}
            </span>
          </Flex>
        </Link>
      </Col>
      <Col xs="auto" className="ms-auto align-self-start">
        <small>{time}</small>
        {!!newResponses.length && (
          <div className="text-end mt-1">
            <Badge bg="accent">{newResponses.length} nuevo</Badge>
          </div>
        )}
      </Col>
    </Row>
  );
};

Questionaire.propTypes = {
  questionaire: PropTypes.shape({
    NO_ID_FIELD: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    archived: PropTypes.bool,
    star: PropTypes.bool,
    createdAt: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    badge: PropTypes.string
  }),
  isSelectedItem: PropTypes.func,
  toggleSelectedItem: PropTypes.func.isRequired
};

export default Questionaire;
