import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import PendingEventsContent from 'components/company/events/PendingEvents';

const PendingEvents = ({ className }) => {
  return (
    <Card className={className}>
      <Card.Header>
        <h6 className="m-0">Eventos pendientes</h6>
      </Card.Header>
      <Card.Body className="pt-0 overflow-auto">
        <PendingEventsContent
          className="m-n3 h-100 shadow-none"
          avoidPastEvents={true}
        />
      </Card.Body>
    </Card>
  );
};

PendingEvents.propTypes = {
  className: PropTypes.string
};

export default PendingEvents;
