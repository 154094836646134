import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from 'react-bootstrap';

const ProductFeatures = ({ data }) => {
  if (!data) {
    return;
  }

  return (
    <Card className="mb-3 bg-transparent border shadow-none">
      <Card.Body>
        <Row className="gy-4 text-center text-md-start">
          {data.map(({ id, title, icon, description }) => (
            <Col md={4} key={id}>
              {icon && (
                <FontAwesomeIcon
                  icon={icon}
                  className="mb-3 fs-4 text-primary"
                />
              )}
              <h6 className="fs-0 text-primary">{title}</h6>
              <p className="fs--1 mb-0">{description}</p>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

ProductFeatures.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
};

export default ProductFeatures;
