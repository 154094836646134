import React, { useContext, useEffect, useState } from 'react';
import Partner from './Partner';
import { Button } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import GroupCreatorHeader from './GroupCreatorHeader';
import GroupCreatorInfo from './GroupCreatorInfo';
import ChatContactsSearch from '../ChatContactsSearch';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import { ChatContext, UserContext } from 'context/Context';

const GroupCreator = () => {
  const { happybot, me, partners = [] } = useContext(UserContext);
  const { createChat, isOpenNewGroup, setIsOpenNewChat, setIsOpenNewGroup } =
    useContext(ChatContext);
  const [query, setSearch] = useState('');
  const [avatar, setAvatar] = useState();
  const [name, setName] = useState('');
  const [participants, setParticipants] = useState([me?.ref]);

  const handleToggleParticipant = participantRef => {
    const isParticipant = participants.some(
      ref => ref?.path === participantRef?.path
    );
    const _participants = [
      !isParticipant && participantRef,
      ...participants.filter(ref => ref?.path !== participantRef?.path)
    ].filter(participant => participant);
    setParticipants(_participants);
  };

  const handleCreate = async () => {
    const ref = await createChat({ name, participants, type: 'group' });
    if (!ref) {
      return;
    }
    setAvatar();
    setName('');
    setParticipants([me?.ref]);
    setIsOpenNewChat(false);
    setIsOpenNewGroup(false);
  };

  useEffect(() => {
    if (!isOpenNewGroup) {
      setAvatar();
      setName('');
      setParticipants([me?.ref]);
    }
  }, [isOpenNewGroup]);

  return (
    <div
      className={classNames('group-creator bg-white border-end', {
        show: isOpenNewGroup
      })}
    >
      <GroupCreatorHeader />

      <div className="contacts-list">
        <SimpleBarReact style={{ height: '100%', minWidth: '72px' }}>
          <GroupCreatorInfo
            avatar={avatar}
            setAvatar={setAvatar}
            name={name}
            setName={setName}
          />
          <div className="mt-4 mb-2 ps-4">
            <h6 className="fw-normal text-primary text-uppercase">
              Miembros del grupo
            </h6>
          </div>
          <div className="overflow-auto h-auto">
            <Flex className="px-3" justifyContent="start">
              {participants
                .sort((r1, r2) => {
                  return r1?.path === me?.ref?.path &&
                    r2?.path !== me?.ref?.path
                    ? -1
                    : r1?.path !== me?.ref?.path && r2?.path === me?.ref?.path
                    ? 1
                    : 0;
                })
                .map(participantRef => {
                  const { NO_ID_FIELD, avatar, firstname, name } =
                    [happybot, ...partners].find(
                      partner => partner?.ref?.path === participantRef?.path
                    ) || {};
                  return (
                    <Flex
                      key={`New-Group-Participant-${NO_ID_FIELD}`}
                      className="position-relative px-2"
                      direction="column"
                      alignItems="center"
                      style={{
                        width: '60px',
                        maxWidth: '60px',
                        minWidth: '60px'
                      }}
                    >
                      <Avatar src={avatar} name={name} />
                      {NO_ID_FIELD !== me?.NO_ID_FIELD && (
                        <FalconCloseButton
                          size="sm"
                          className="position-absolute top-0 end-0 me-1 bg-white rounded-3 fs--2 shadow-none"
                          onClick={() =>
                            handleToggleParticipant(participantRef)
                          }
                        />
                      )}
                      <span className="fs--2 text-center w-100 text-truncate overflow-hidden">
                        {firstname || name}
                      </span>
                    </Flex>
                  );
                })}
            </Flex>
          </div>
          <ChatContactsSearch
            className="sticky-top pb-0"
            placeholder="Buscar contactos"
            onChange={query => setSearch(query)}
          />
          <div className="my-3">
            {[happybot, ...partners]
              .filter(
                ({ NO_ID_FIELD, ref } = {}) =>
                  NO_ID_FIELD &&
                  me?.ref?.path !== ref.path &&
                  !participants.some(
                    participantRef => ref?.path === participantRef?.path
                  )
              )
              .filter(({ name = '' }) => {
                const normalizedName =
                  name
                    ?.toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '') || '';
                const normalizedQuery =
                  query
                    ?.toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '') || '';
                return normalizedName.includes(normalizedQuery);
              })
              .sort((p1, p2) => (p1.name < p2.name ? -1 : 1))
              .map((partner, index) => (
                <Partner
                  partner={partner}
                  index={index}
                  key={`Sidebar-Member-${partner?.NO_ID_FIELD}`}
                  onClick={handleToggleParticipant}
                  selected={participants.some(
                    ref => ref?.path === partner?.ref?.path
                  )}
                />
              ))}
          </div>
          {!!name && !!participants?.length && (
            <div className="p-3 pt-0 d-grid sticky-bottom">
              <Button
                className="rounded-pill"
                size="lg"
                variant="falcon-default"
                disabled={
                  !name || !participants?.length || participants?.length < 2
                }
                onClick={handleCreate}
              >
                Crear grupo
              </Button>
            </div>
          )}
        </SimpleBarReact>
      </div>
    </div>
  );
};

export default GroupCreator;
