import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import Tooltip from 'components/common/Tooltip';

const ActionButton = ({
  tooltip,
  icon,
  handleClick,
  variant = 'falcon-default',
  ...props
}) => (
  <Tooltip title={tooltip}>
    <Button {...props} variant={variant} onClick={handleClick}>
      <FontAwesomeIcon icon={icon} />
    </Button>
  </Tooltip>
);

ActionButton.propTypes = {
  tooltip: PropTypes.string,
  icon: PropTypes.string,
  handleClick: PropTypes.func,
  variant: PropTypes.string
};

export default ActionButton;
