import React from 'react';
import PropTypes from 'prop-types';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import {
  AuthProvider,
  FirestoreProvider,
  StorageProvider,
  useFirebaseApp
} from 'reactfire';

const FirebaseProvider = ({ children }) => {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const firestore = getFirestore(app);
  const storage = getStorage(app);

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestore}>
        <StorageProvider sdk={storage}>{children}</StorageProvider>
      </FirestoreProvider>
    </AuthProvider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default FirebaseProvider;
