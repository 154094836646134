import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import Background from 'components/common/Background';
import corner1 from 'assets/img/illustrations/corner-1.png';

const QuestionaireReward = () => {
  return (
    <Alert variant="primary">
      <Background image={corner1} className="p-x1" />
      <Alert.Heading>Este cuestionario tiene recompensa</Alert.Heading>
      <p>
        Al finalizar el cuestionario recibirás{' '}
        <strong className="fs-1 mx-1">1€</strong> en tu cartera de Happyfy
      </p>
    </Alert>
  );
};

QuestionaireReward.propTypes = {
  user: PropTypes.object
};

export default QuestionaireReward;
