import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Form } from 'react-bootstrap';
import { Range } from 'react-range';
import { useForm } from 'react-hook-form';
import Lottie from 'lottie-react';
import EmployeeSelector from 'components/common/EmployeeSelector';
import Flex from 'components/common/Flex';
import AppreciationProvider, {
  MAX_APPRECIATIONS
} from 'components/company/appreciations/AppreciationProvider';
import { AppreciationContext, UserContext } from 'context/Context';
import confetti from 'assets/img/animated-icons/confetti.json';

const RangeSlider = ({ externalRef, setValue, value = 0 }) => {
  const { remaining = MAX_APPRECIATIONS } = useContext(AppreciationContext);
  if (!remaining) {
    return null;
  }

  return (
    <div className="px-5 py-2 mb-2">
      <Range
        ref={externalRef}
        step={1}
        min={0}
        max={remaining}
        values={[value]}
        onChange={values => setValue('value', values[0])}
        renderTrack={({ props, children }) => {
          const {
            props: { 'aria-valuenow': value, 'aria-valuemax': max }
          } = children[0];
          const percent = parseInt((value * 100) / max, 10);
          return (
            <div
              {...props}
              style={{
                // eslint-disable-next-line react/prop-types
                ...props.style,
                height: '6px'
              }}
            >
              <div className="h-100 bg-300 position-absolute start-0 end-0 mx-n5 rounded-5" />
              <div
                className="h-100 bg-primary position-absolute start-0 end-0 mx-n5 rounded-5"
                style={{ width: `calc(${percent}% + 32px)` }}
              />
              {children}
            </div>
          );
        }}
        renderThumb={({ props, value }) => (
          <div {...props} className="outline-none">
            <Button
              variant="falcon-default"
              className="fs-0 rounded-5"
              style={{ minWidth: '100px' }}
              size="sm"
            >
              <span className="me-1">{value}</span>
              👏🏼
            </Button>
          </div>
        )}
      />
    </div>
  );
};

RangeSlider.propTypes = {
  externalRef: PropTypes.object,
  setValue: PropTypes.func,
  value: PropTypes.number
};

const styles = {
  control: base => ({
    ...base,
    fontFamily:
      'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',
    boxShadow: 'none !important',
    fontSize: '0.8rem',
    borderRadius: 0,
    border: 'none !important',
    paddingLeft: '1.25rem'
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  }),
  input: base => ({ ...base, paddingLeft: '28px', boxShadow: 'none' })
};

const CreatePostAppreciation = ({ setState }) => {
  const { create } = useContext(AppreciationContext);
  const { me, partners = [] } = useContext(UserContext);
  const [isCreated, setCreated] = useState();
  const { handleSubmit, ...form } = useForm();
  const { register, reset, setValue, watch } = form;
  const { targetId, text, value } = watch();
  const range = useRef();
  const select = useRef();
  const textWrapper = useRef();
  const options = partners
    .map(({ NO_ID_FIELD, avatar, name }) => ({
      avatar,
      label: name,
      value: NO_ID_FIELD
    }))
    .filter(({ value }) => value !== me?.NO_ID_FIELD);

  const { remaining = 100 } = useContext(AppreciationContext);

  const handleTargetChange = data => {
    const { value = '' } = data || {};
    setValue('targetId', value);
  };

  const onSubmitData = async data => {
    if (!value) {
      const thumb = range.current.thumbRefs?.[0]?.current?.firstElementChild;
      thumb?.classList.add('horizontal-slide');
      setTimeout(() => {
        thumb?.classList.remove('horizontal-slide');
      }, 600);
      return;
    }
    if (!targetId) {
      select.current.focus();
      return;
    }
    if (!text) {
      textWrapper.current?.firstElementChild?.focus?.();
      return;
    }
    setCreated(true);
    await create({ ...data, icon: '👏🏼' });
    close?.();
    reset();
    select.current?.clearValue();
    setTimeout(() => setCreated(false), 5000);
  };

  return (
    <>
      {isCreated && (
        <div className="position-absolute w-100 h-100 overflow-hidden pointer-none z-index-2">
          <Lottie
            animationData={confetti}
            className="position-absolute mx-n6 start-0 end-0 h-100"
            loop={false}
          />
        </div>
      )}
      <Form onSubmit={handleSubmit(onSubmitData)}>
        <Card.Body className="p-0 border-bottom border-200">
          <Flex direction="column" className="g-3">
            <div className="bg-light p-3 pb-2 px-x1 rounded-3 rounded-bottom-0 overflow-hidden">
              <RangeSlider {...form} externalRef={range} value={value} />
              <div className="text-400 fs--1">
                {remaining === 0
                  ? 'Has gastado todos los reconocimientos este mes'
                  : `Te quedan ${remaining} reconocimientos este mes`}
              </div>
            </div>
            <Form.Group className="border-bottom border-200">
              <EmployeeSelector
                externalRef={select}
                options={options}
                placeholder="¿A quién quieres reconocer?"
                onChange={handleTargetChange}
                isDisabled={!remaining}
                styles={styles}
              />
            </Form.Group>
            <Form.Group _ref={textWrapper}>
              <Form.Control
                as="textarea"
                className="bg-white px-x1 resize-none shadow-none rounded-0 border-0"
                disabled={!remaining}
                placeholder="Escríbele algo"
                rows={3}
                {...register('text')}
              />
            </Form.Group>
          </Flex>
        </Card.Body>
        <Card.Footer alignItems="center" as={Flex}>
          <div className="flex-1">
            {setState && (
              <Button
                variant="light"
                size="sm"
                className="rounded-pill shadow-none d-inline-flex align-items-center fs--1 mb-0 me-1"
                onClick={() => setState('post')}
              >
                📝
                <span className="ms-2 d-none d-md-inline-block">
                  Crear post
                </span>
              </Button>
            )}
          </div>
          <Button
            disabled={!remaining}
            type="submit"
            className="px-4 px-sm-5"
            size="sm"
          >
            Aplaudir
          </Button>
        </Card.Footer>
      </Form>
    </>
  );
};

CreatePostAppreciation.propTypes = {
  setState: PropTypes.func
};

const CreatePostAppreciationWithProvider = props => {
  return (
    <AppreciationProvider>
      <CreatePostAppreciation {...props} />
    </AppreciationProvider>
  );
};

export default CreatePostAppreciationWithProvider;
