import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { UserContext } from 'context/Context';

const ForgetPasswordForm = () => {
  const { resetPassword } = useContext(UserContext);
  const [email, setEmail] = useState('');

  const handleSubmit = async event => {
    event.preventDefault();
    if (email) {
      await resetPassword(email);
      toast.success(
        `Se ha enviado un email a ${email} con el link para resetear tu contraseña`,
        {
          theme: 'colored'
        }
      );
    }
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder="E-mail"
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email}>
          Enviar link de reseteo
        </Button>
      </Form.Group>

      <Link className="fs--1 text-600" to="/authentication/login">
        Volver al inicio de sesión
        <span className="d-inline-block ms-1"> &rarr;</span>
      </Link>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
