import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Card } from 'react-bootstrap';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import Event from 'components/company/events/Event';
import { CalendarContext, UserContext } from 'context/Context';

const NextEvents = ({ className }) => {
  const { events: data = [] } = useContext(CalendarContext);
  const { me } = useContext(UserContext);
  const nextEvents = data.filter(
    ({ assistance, start }) =>
      assistance?.some(
        obj => obj?.assist !== false && obj?.ref?.path === me?.ref?.path
      ) && start > new Date().toISOString()
  );
  return (
    <Card className={className}>
      <div className="overflow-hidden rounded-4 min-h-100">
        <SimpleBarReact className="h-100">
          <Card.Header className="sticky-top">
            <h6 className="m-0">Mis próximos eventos</h6>
          </Card.Header>
          <Card.Body className="flex-grow-1 fs--1">
            {!nextEvents?.length && <p>No hay eventos</p>}
            {nextEvents?.map((event, index) => (
              <Event
                key={`Event-${event?.NO_ID_FIELD}`}
                details={event}
                isLast={index === nextEvents.length - 1}
              />
            ))}
          </Card.Body>
          <FalconCardFooterLink
            className="sticky-bottom"
            title="Todos los eventos"
            to="/company/events"
            size="sm"
          />
        </SimpleBarReact>
      </div>
    </Card>
  );
};

NextEvents.propTypes = {
  className: PropTypes.string
};

export default NextEvents;
