import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ObjectiveDetailsGmap from './ObjectiveDetailsGmap';

const ObjectiveDetailContent = ({ objective = {} }) => {
  const { NO_ID_FIELD, address, title, description, tags, latlng, location } =
    objective;

  return (
    <Card>
      <Card.Body>
        {title && <h5 className="fs-0 mb-3">{title}</h5>}
        {description && <p>{description}</p>}
        {tags && (
          <>
            <h5 className="fs-0 mt-5 mb-2 ">Tags </h5>
            {tags.map(({ tag }, index) => (
              <Link
                key={`Tag-${NO_ID_FIELD}-${index}`}
                className="badge border link-secondary text-decoration-none me-1"
              >
                {tag}
              </Link>
            ))}
          </>
        )}

        {latlng && (
          <ObjectiveDetailsGmap latlng={latlng}>
            <h5 className="text-700">{location}</h5>
            <p className="fs--1 mb-0">{address}</p>
          </ObjectiveDetailsGmap>
        )}
      </Card.Body>
    </Card>
  );
};

ObjectiveDetailContent.propTypes = {
  objective: PropTypes.object
};

export default ObjectiveDetailContent;
