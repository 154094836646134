import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import confetti from 'assets/img/animated-icons/confetti.json';
import dayjs from 'dayjs';

const FeedWorkAnniversary = ({ createdAt, userIds = [] }) => {
  userIds = Array.isArray(userIds) ? userIds : [userIds];
  const { company, partners = [] } = useContext(UserContext);
  const { name: companyName } = company || {};
  const users = partners
    ?.filter(({ NO_ID_FIELD }) => userIds.includes(NO_ID_FIELD))
    .map(user => ({
      ...user,
      years: dayjs(`${new Date(createdAt).getFullYear()}`).diff(
        `${new Date(user?.startAt).getFullYear()}`,
        'years'
      )
    }));
  const names = new Intl.ListFormat('es', {
    style: 'long',
    type: 'conjunction'
  }).format(users.map(({ firstname }) => firstname));
  return (
    <>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        className="position-relative"
      >
        <Lottie
          animationData={confetti}
          className="position-absolute mt-n4 w-100 pointer-none z-index-2"
          loop={false}
        />
        <Flex
          alignItems="end"
          justifyContent={classNames({
            center: users?.length > 1,
            end: users?.length === 1
          })}
          className="position-relative"
        >
          <AvatarGroup max={10}>
            {users.map(({ NO_ID_FIELD, avatar, name, years }) => (
              <Flex
                key={`WorkAnniversary-${NO_ID_FIELD}`}
                alignItems="end"
                justifyContent="center"
                className="avatar avatar-5xl"
              >
                <Avatar src={avatar} name={name} size="5xl" />
                <Avatar
                  name={`${years} años`}
                  className="position-absolute z-index-1 ms-0 mb-n3"
                  mediaClass="fs-2"
                  size="2xl"
                  isExact
                />
              </Flex>
            ))}
          </AvatarGroup>
        </Flex>
        <h1 className="mt-3 fs-8">🎉</h1>
        <h5 className="text-center">
          Felicidades {names} por {users.length === 1 ? 'tu' : 'vuestro'}{' '}
          aniversario de trabajo en {companyName}
        </h5>
      </Flex>
    </>
  );
};

FeedWorkAnniversary.propTypes = {
  createdAt: PropTypes.string,
  userIds: PropTypes.array
};

export default FeedWorkAnniversary;
