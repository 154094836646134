import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Form } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { UserContext } from 'context/Context';

const ToDoItems = ({ item, index, length }) => {
  const { deleteTodoItem, updateTodoItem } = useContext(UserContext);
  const { NO_ID_FIELD, color = 'primary' } = item;

  const handleChange = ({ target }) => {
    const { checked: completed } = target;
    updateTodoItem(item, { completed });
  };

  const handleDelete = () => {
    deleteTodoItem(item);
  };

  return (
    <div
      className={classNames(
        'd-flex hover-actions-trigger btn-reveal-trigger gap-3 border-200',
        { 'border-bottom mb-3': index !== length - 1 }
      )}
    >
      <Form.Check className="mb-0">
        <Form.Check.Input
          id={`TodoItem-${NO_ID_FIELD}`}
          type="checkbox"
          defaultChecked={item.completed}
          onChange={handleChange}
          className={classNames(
            `mt- rounded-circle form-check-input-${color}`,
            {
              'form-check-line-through': item.completed
            }
          )}
        />
        <Form.Check.Label
          className="flex-1 pe-3"
          htmlFor={`TodoItem-${NO_ID_FIELD}`}
        >
          <span className="mb-1 text-700 d-block">{item.task}</span>
          <span
            className={classNames(
              'fs--2 text-600 lh-base font-base fw-normal d-block',
              { 'mb-2': index !== length - 1 }
            )}
          >
            {item.title}
          </span>
        </Form.Check.Label>
      </Form.Check>
      <div className="hover-actions end-0">
        <Button
          variant="link"
          className="fs--2 icon-item-sm px-0 text-600"
          onClick={handleDelete}
        >
          <FontAwesomeIcon icon="trash" className="text-danger" />
        </Button>
      </div>
    </div>
  );
};

ToDoItems.propTypes = {
  item: PropTypes.shape({
    NO_ID_FIELD: PropTypes.string.isRequired,
    task: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string,
    completed: PropTypes.bool.isRequired
  }),
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired
};

const ToDoList = ({ previewClassName }) => {
  const { todoList, setShowToDoModal } = useContext(UserContext);

  const handleAddClick = () => {
    setShowToDoModal(true);
  };

  return (
    <Card className={classNames('h-100', previewClassName)}>
      <Card.Header className="d-flex flex-between-center bg-light">
        <h6 className="mb-0">Lista de tareas</h6>
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="plus"
          transform="shrink-3"
          onClick={handleAddClick}
        >
          <span>Crear tarea</span>
        </IconButton>
      </Card.Header>
      <Card.Body className="ticket-todo-list scrollbar">
        {todoList.map((item, index) => (
          <ToDoItems
            key={`Todo-Item-${item.NO_ID_FIELD}`}
            item={item}
            index={index}
            length={todoList.length}
          />
        ))}
      </Card.Body>
    </Card>
  );
};

ToDoList.propTypes = {
  previewClassName: PropTypes.string
};

export default ToDoList;
