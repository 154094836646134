import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import AppContext from 'context/Context';

const NewQuestionaireModal = ({ isOpen, setIsOpen, create }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [formData, setFormData] = useState({});
  const { options = [] } = formData;

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChangeTitle = ({ target }) => {
    let { value } = target;
    setFormData({ ...formData, title: value });
  };

  const handleChangeOptions = ({ index, value }) => {
    const _options = [...options];
    _options[index] = {};
    _options[index].text = value;
    if (!value.length) {
      _options.splice(index, 1);
    }
    setFormData({ ...formData, options: _options });
  };

  const handleSubmit = e => {
    e.preventDefault();
    create(formData);
    setIsOpen(false);
  };

  return (
    <Modal show={isOpen} onHide={handleClose} contentClassName="border">
      <Form onSubmit={handleSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-x1 border-bottom-0"
        >
          <Modal.Title as="h5">Nueva encuesta</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          <Form.Group className="mb-3" controlId="titleInput">
            <Form.Label className="fs-0">Pregunta</Form.Label>
            <Form.Control
              type="text"
              name="title"
              required
              onChange={handleChangeTitle}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="optionsInput">
            <Form.Label className="fs-0">Opciones</Form.Label>
            <div className="input-group-vertical mb-3">
              {[...options, ''].map((option, index) => {
                const { text } = option || {};
                return (
                  <Form.Control
                    key={`New-Questionaire-Option-${index}`}
                    type="text"
                    name={`options-${index}`}
                    required={index < options.length - 1}
                    value={text}
                    onChange={({ target }) => {
                      const { value } = target;
                      handleChangeOptions({ index, value });
                    }}
                  />
                );
              })}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-x1 border-top-0">
          <Button variant="primary" type="submit" className="px-4 mx-0">
            Crear encuesta
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

NewQuestionaireModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired
};

export default NewQuestionaireModal;
