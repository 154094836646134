import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Lottie from 'lottie-react';
import { FeedContext } from 'context/Context';
import Loader from 'components/common/Loader';
import CreatePost from './CreatePost';
import FeedCard from './FeedCard';
import classNames from 'classnames';
import useVisibilityObserver from 'hooks/useVisibilityObserver';
import check from 'assets/img/animated-icons/check-primary-light.json';

export const Posts = ({ className, feeds }) => {
  return (
    <>
      {feeds
        .map(
          (feed, index) =>
            feed?.id && (
              <FeedCard
                key={`Feed-${feed.id}`}
                feed={feed}
                className={classNames(className, {
                  'mb-3': index + 1 !== feeds.length
                })}
              />
            )
        )
        .filter(feed => feed)}
    </>
  );
};

Posts.propTypes = {
  className: PropTypes.string,
  feeds: PropTypes.array
};

const FeedContent = () => {
  const {
    companyId,
    feeds = [],
    fetchMore,
    hasFinished
  } = useContext(FeedContext);
  const targetElRef = useRef();
  const { isVisible: inViewport, observer } = useVisibilityObserver(
    targetElRef,
    '-100px'
  );

  useEffect(() => {
    return () => {
      observer &&
        targetElRef.current &&
        observer.unobserve(targetElRef.current);
    };
  }, [observer]);

  useEffect(() => {
    let mounted = true;
    setTimeout(() => {
      mounted && companyId && inViewport && fetchMore();
    }, 100);
    return () => {
      mounted = false;
    };
  }, [inViewport, companyId, feeds.length]);

  return (
    <>
      <CreatePost className="d-none d-lg-block mb-3" />
      <Posts feeds={feeds} />
      <Card.Footer ref={targetElRef} className="text-center">
        {hasFinished ? (
          <>
            <Lottie
              animationData={check}
              className="m-auto w-25"
              loop={false}
            />
          </>
        ) : (
          <Loader className="pt-5 pb-8" />
        )}
      </Card.Footer>
    </>
  );
};

export default FeedContent;
