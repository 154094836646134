import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import IndexGraph from 'components/charts/IndexGraph';
import { ReportsContext } from 'context/Context';
import { getColor, groupBy } from 'helpers/utils';
import { Card } from 'react-bootstrap';
import types from './types.json';

const columns = [
  {
    accessor: 'name',
    Header: 'Nombre',
    headerProps: {
      className: 'col-4'
    },
    Cell: rowData => {
      let { id, avatar, department, name } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <Avatar size="xl" src={avatar} name={name} />
          <div className="ms-3">
            <Link to={`/user/profile/${id}`} className="text-800">
              <h6 className="mb-0 text-wrap">{name}</h6>
            </Link>
            <p className="m-0 fs--2 text-wrap">{department}</p>
          </div>
        </Flex>
      );
    }
  },
  ...Object.entries(types).map(([accessor, Header]) => {
    return {
      accessor,
      Header,
      headerProps: {
        className: 'text-capitalize text-center text-truncate col-1 px-1'
      },

      Cell: rowData => {
        let { [accessor]: value = '-' } = rowData.row.original;
        if (value === null) {
          value = '-';
        }
        let color =
          (isNaN(value) && 'none') ||
          (value > 75 && getColor('success')) ||
          (value > 60 && getColor('warning')) ||
          getColor('danger');

        if (accessor === 'stress') {
          color =
            (isNaN(value) && 'none') ||
            (value > 80 && getColor('danger')) ||
            (value > 60 && getColor('warning')) ||
            getColor('success');
        }
        return <IndexGraph title={value} color={color} data={value} />;
      }
    };
  })
];

const Employees = () => {
  const { department: departmentProp } = useParams();
  const { partners = [], indexes = [] } = useContext(ReportsContext);

  const dataByUser = Object.entries(groupBy(indexes, 'userId'))
    .map(([userId, indexes]) => {
      const user = partners.find(({ id } = {}) => id === userId);
      if (!user || (departmentProp && user?.department !== departmentProp)) {
        return;
      }
      const indexesByType = Object.entries(groupBy(indexes, 'type')).reduce(
        (all, [key, indexes]) => {
          const value = parseInt(indexes?.pop()?.value);
          return {
            ...all,
            [key]: value !== null ? value : undefined
          };
        },
        {}
      );
      const allTypes = Object.keys(types)
        .map(key => {
          const value = indexesByType[key];
          return [key, value !== null ? value : undefined];
        })
        .reduce((all, [key, value]) => ({ ...all, [key]: value }), {});
      return [userId, allTypes];
    })
    .filter(data => data)
    .reduce((data, [key, value]) => ({ ...data, [key]: value }), {});

  const employees = partners
    ?.map(user => {
      const userIndexes =
        dataByUser[user?.NO_ID_FIELD] ||
        Object.keys(types)
          .map(key => [key, undefined])
          .reduce((all, [key, value]) => ({ ...all, [key]: value }), {});
      return { ...user, ...userIndexes };
    })
    .filter(({ happiness }) => happiness)
    .sort(
      (
        { happiness: h1 = 0, name: name1 },
        { happiness: h2 = 0, name: name2 }
      ) => {
        return `${h1}` > `${h2}`
          ? -1
          : `${h1}` === `${h2}` && name1 < name2
          ? -1
          : 1;
      }
    );

  return (
    <>
      {!employees.length ? (
        <Card className="border border-3 shadow-none">
          <Card.Body>
            <span>No hay datos</span>
          </Card.Body>
        </Card>
      ) : (
        <AdvanceTableWrapper
          columns={columns}
          data={employees}
          pagination
          perPage={999999999}
        >
          <Card className="overflow-hidden shadow-none mb-3">
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-light text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  className: 'fs--1 mb-0',
                  style: { tableLayout: 'fixed' }
                }}
              />
            </Card.Body>
          </Card>
        </AdvanceTableWrapper>
      )}
    </>
  );
};

Employees.propTypes = {
  data: PropTypes.array
};

export default Employees;
