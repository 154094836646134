import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, ListGroup } from 'react-bootstrap';
import ActionButton from 'components/common/ActionButton';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';

const OptionsQuestion = ({
  getValues,
  hasResponses,
  index,
  register,
  reset,
  watching
}) => {
  const [updateTime, setTime] = useState();
  const values = getValues();
  const { definitions } = values;
  const { extra = {} } = definitions?.[index] || {};
  const { maxResponses = 1, options: raw = [] } = extra;
  let options = raw.filter(option => option);
  options = !hasResponses ? [...options, ''] : options;

  const deleteOption = optionIndex => {
    const options = raw.filter((option, i) => option && i !== optionIndex);
    const data = {
      ...watching,
      definitions: definitions.map((def, i) =>
        i === index
          ? {
              ...def,
              extra: {
                ...(def?.extra || {}),
                options
              }
            }
          : def
      )
    };
    setTime(Date.now());
    reset(data);
  };

  return (
    <div className="ms-3 ps-3 border-start">
      <Form.Group className="mb-3">
        <Form.Label>
          Respuestas
          <small className="d-block">
            (El usuario puede elegir {maxResponses}{' '}
            {parseInt(maxResponses) === 1 ? 'opción' : 'opciones'} como máximo)
          </small>
        </Form.Label>
        <Form.Control
          className="w-auto"
          type="number"
          defaultValue={1}
          min={1}
          max={options?.filter(option => option)?.length || 1}
          {...register(`definitions.${index}.extra.maxResponses`)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Opciones</Form.Label>
        <ListGroup>
          {options.map((option, optionIndex) => (
            <ListGroup.Item
              key={`Definition-${index}-option-${optionIndex}-${updateTime}`}
              className="p-0 overflow-hidden"
              as={Flex}
            >
              <Form.Control
                className="border-0 rounded-0 shadow-none pe-5"
                disabled={hasResponses}
                placeholder="Opción"
                {...register(
                  `definitions.${index}.extra.options.${optionIndex}`,
                  {
                    required: optionIndex === 0
                  }
                )}
              />
              {!hasResponses && optionIndex < options.length && (
                <ActionButton
                  className="position-absolute end-0"
                  icon="trash"
                  tooltip="Eliminar opción"
                  variant="link"
                  handleClick={() => deleteOption(optionIndex)}
                />
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Form.Group>
    </div>
  );
};

const TextQuestion = () => {
  return null;
};

const VoteQuestion = ({ register, hasResponses, index }) => {
  return (
    <div className="ms-3 ps-3 border-start">
      <Form.Group className="mb-3">
        <Form.Label>Mínimo</Form.Label>
        <Flex>
          <Form.Control
            disabled={hasResponses}
            placeholder={0}
            defaultValue={0}
            type="number"
            {...register(`definitions.${index}.extra.min.value`, {
              required: true
            })}
          />
          <Form.Control
            disabled={hasResponses}
            className="ms-2"
            placeholder="Título"
            {...register(`definitions.${index}.extra.min.title`, {
              required: true
            })}
          />
        </Flex>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Máximo</Form.Label>
        <Flex>
          <Form.Control
            disabled={hasResponses}
            placeholder={10}
            defaultValue={10}
            type="number"
            {...register(`definitions.${index}.extra.max.value`, {
              required: true
            })}
          />
          <Form.Control
            className="ms-2"
            disabled={hasResponses}
            placeholder="Título"
            {...register(`definitions.${index}.extra.max.title`, {
              required: true
            })}
          />
        </Flex>
      </Form.Group>
    </div>
  );
};

const questionTypes = {
  number: VoteQuestion,
  options: OptionsQuestion,
  text: TextQuestion,
  vote: VoteQuestion
};

export const Question = ({
  question,
  hasResponses,
  index,
  questionIndex,
  ...form
}) => {
  const { register, watch, reset } = form;
  const watching = watch();
  const { definitions = [] } = watching;
  const { type = 'vote' } = definitions[index] || {};

  const QuestionType = questionTypes[type] || null;

  const handleChangeType = event => {
    const { target } = event;
    const { value } = target;
    const data = {
      ...watching,
      definitions: definitions.map((definition, i) => {
        const { question } = definition;
        return i === index ? { question, type: value } : definition;
      })
    };
    reset(data);
  };

  const deleteQuestion = () => {
    const data = {
      ...watching,
      definitions: definitions.filter((def, i) => i !== index)
    };
    reset(data);
  };

  return (
    <Flex className="mb-3 pb-4 border-bottom" justifyContent="start">
      <div className="pe-x1 pt-1">
        <Avatar
          name={type === 'divider' ? '' : `${questionIndex}`}
          isExact={true}
          mediaClass="bg-primary bg-opacity-25"
          size="2xl"
        />
      </div>
      <div className="w-100">
        <Flex alignItems="start" justifyContent="between">
          <Form.Group className="mb-3 me-x1 flex-grow-1">
            <Form.Label>Pregunta</Form.Label>
            <Form.Control
              disabled={hasResponses}
              placeholder="Escribe la pregunta"
              {...register(`definitions.${index}.question`, { required: true })}
            />
          </Form.Group>
          {!hasResponses && (
            <ActionButton
              tooltip="Eliminar pregunta"
              icon="trash"
              variant="danger"
              handleClick={deleteQuestion}
            />
          )}
        </Flex>
        <Flex>
          <Form.Group className="mb-3">
            <Form.Label>Tipo</Form.Label>
            <Form.Select
              value={type}
              disabled={hasResponses}
              onChange={handleChangeType}
            >
              <option value="options">Opciones</option>
              <option value="text">Texto</option>
              <option value="vote">Votación</option>
              <option value="divider">Enunciado</option>
            </Form.Select>
          </Form.Group>
          {QuestionType && (
            <QuestionType
              {...form}
              hasResponses={hasResponses}
              question={question}
              index={index}
            />
          )}
        </Flex>
      </div>
    </Flex>
  );
};

OptionsQuestion.propTypes = {
  getValues: PropTypes.func,
  hasResponses: PropTypes.bool,
  index: PropTypes.number,
  register: PropTypes.func,
  reset: PropTypes.func,
  watching: PropTypes.func
};

VoteQuestion.propTypes = {
  register: PropTypes.func,
  hasResponses: PropTypes.bool,
  index: PropTypes.number
};

Question.propTypes = {
  question: PropTypes.object,
  hasResponses: PropTypes.bool,
  index: PropTypes.number,
  questionIndex: PropTypes.number
};

export default Question;
