import React from 'react';
import { Row, Col } from 'react-bootstrap';
import IndexCharts from 'components/charts/IndexCharts';
import Totals from 'components/charts/Totals';
import Alerts from 'components/dashboards/Alerts';
import Departments from 'components/dashboards/Departments';
import MemberInfo from 'components/dashboards/MemberInfo';

const type = 'stress';

const Dashboard = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={6} xxl={3}>
          <Totals className="h-100" type={type} />
        </Col>
        <Col md={6} xxl={6}>
          <IndexCharts className="h-100" type={type} />
        </Col>
        <Col md={6} xxl={3}>
          <Alerts bodyWrapperClassName="h-0 flex-grow-1" type={type} />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col sm={6} xxl={8}>
          <MemberInfo type={type} />
        </Col>
        <Col sm={6} xxl={4}>
          <Departments className="h-100" type={type} />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
