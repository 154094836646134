import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Nav, Row } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import Cookies from 'js-cookie';
import { Navigate, Outlet } from 'react-router-dom';
import { OnBoardingContext, UserContext } from 'context/Context';
import Footer from 'components/footer/Footer';
import WizardModal from 'components/wizard/WizardModal';
import OnBoardingProvider from 'components/OnBoardingProvider';
import UserProvider from 'components/UserProvider';

const NavbarTop = ({ children }) => {
  const [showDropShadow, setShowDropShadow] = useState(false);

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  return (
    <div
      className={classNames(
        'navbar-glass pt-3 px-3 fs--1 navbar-top sticky-kit',
        {
          'navbar-glass-shadow': showDropShadow
        }
      )}
    >
      <Row className="flex-center">
        <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
          <Logo width={128} />
          {children}
        </Col>
      </Row>
    </div>
  );
};

NavbarTop.propTypes = {
  children: PropTypes.node
};

const NavItem = ({ index, step, setStep, maxStep, icon, label }) => {
  let avatar;
  if (icon === 'user') {
    const { me = {} } = useContext(UserContext);
    avatar = me?.avatar;
  }

  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index,
          'opacity-50': maxStep < index
        })}
        disabled={maxStep < index}
        onClick={() => setStep(index)}
      >
        <span className="nav-item-circle-parent">
          <Flex
            className="nav-item-circle"
            alignItems="center"
            justifyContent="center"
          >
            {avatar ? <Avatar src={avatar} /> : <FontAwesomeIcon icon={icon} />}
          </Flex>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

NavItem.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  maxStep: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

const OnBoardingLayout = () => {
  const user = JSON.parse(Cookies.get('__fbu') || '{}');
  const isSignedIn = !!user.uid;
  const variant = 'rounded';
  const { maxStep, step, setStep } = useContext(OnBoardingContext);

  const [modal, setModal] = useState(false);

  const navItems = [
    {
      icon: 'user',
      label: 'Personal'
    },
    {
      icon: 'building',
      label: 'Empresa'
    },
    {
      icon: 'users',
      label: 'Empleados'
    },
    {
      icon: 'list-alt',
      label: 'Cuestionario'
    },
    {
      icon: 'crown',
      label: 'Suscripción'
    }
  ];

  if (!isSignedIn) {
    return (
      <Navigate
        to={{
          ...location,
          pathname: '/authentication/login',
          state: { from: location }
        }}
        replace={true}
      />
    );
  }

  return (
    <>
      <Section className="py-3">
        <NavbarTop>
          <div className="theme-wizard">
            <Nav className="justify-content-center" variant={variant}>
              {navItems.map((item, index) => (
                <NavItem
                  key={item.label}
                  index={index + 1}
                  step={step}
                  setStep={setStep}
                  maxStep={maxStep}
                  icon={item.icon}
                  label={item.label}
                />
              ))}
            </Nav>
          </div>
        </NavbarTop>
        <div className="content">
          <WizardModal modal={modal} setModal={setModal} />
          <Outlet />
          <Footer />
        </div>
      </Section>
    </>
  );
};

const OnBoardingLayoutWithContext = props => {
  return (
    <UserProvider>
      <OnBoardingProvider>
        <OnBoardingLayout {...props} />
      </OnBoardingProvider>
    </UserProvider>
  );
};

OnBoardingLayout.propTypes = {
  match: PropTypes.object
};

export default OnBoardingLayoutWithContext;
