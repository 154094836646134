import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Image, Row } from 'react-bootstrap';
import { collection, doc, getDoc } from 'firebase/firestore';
import { ref } from 'firebase/storage';
import { useFirestore, useStorage, useStorageDownloadURL } from 'reactfire';
import Avatar from 'components/common/Avatar';
import ErrorBoundary from 'components/common/ErrorBoundary';

const CompanyAvatar = ({ companyId }) => {
  const storage = useStorage();
  const { data: imageURL } = useStorageDownloadURL(
    ref(storage, `companies/${companyId}/logo.png`)
  );

  return (
    <Image src={imageURL} alt="trainer" fluid className="rounded-3 w-100" />
  );
};

const UserInfo = ({ user }) => {
  const db = useFirestore();
  const { company: companyId, name, department, location } = user;
  const [company, setCompany] = useState();

  useEffect(() => {
    if (!companyId) {
      return;
    }
    (async () => {
      const companyDoc = await getDoc(
        doc(collection(db, 'companies'), companyId)
      );
      const company = await companyDoc.data();
      setCompany(company);
    })();
  }, [companyId]);

  return (
    <Card>
      <Card.Body>
        <Row className="g-3 align-items-center">
          <Col md={6} lg={12} className="text-center">
            {company && (
              <ErrorBoundary
                fallback={
                  <Avatar
                    name={company?.name}
                    className="w-100"
                    isExact={true}
                    maxChars={10}
                    rounded="1"
                    size="5xl"
                  />
                }
              >
                <CompanyAvatar companyId={companyId} />
              </ErrorBoundary>
            )}
          </Col>
          <Col md={6} lg={12}>
            <Row className="row-cols-1 g-0">
              <Col className="text-center">
                <h4>{name}</h4>
                <h5 className="mb-1 text-800 fs-0">{department}</h5>
                {location && <p className="mb-0 fs--1"></p>}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

CompanyAvatar.propTypes = {
  companyId: PropTypes.string.isRequired
};
UserInfo.propTypes = {
  user: PropTypes.shape({
    company: PropTypes.string,
    name: PropTypes.string,
    department: PropTypes.string,
    location: PropTypes.string
  })
};

export default UserInfo;
