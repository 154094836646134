import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import Partner from './Partner';
import { UserContext } from 'context/Context';

const DepartmentPartners = ({ department, colBreakpoints }) => {
  const { me, partners = [] } = useContext(UserContext);
  const departmentPartners = partners.filter(
    partner => partner.department === department
  );

  return (
    <Card className="p-0">
      <Card.Header className="bg-light">
        <Flex justifyContent="between">
          <h5 className="mb-0">
            {department} ({departmentPartners.length})
          </h5>
          <Link
            to={classNames({
              '/company/employees': me?.type === 'admin',
              '/user/partners': me?.type === 'employee'
            })}
            className="font-sans-serif"
          >
            Todos los empleados
          </Link>
        </Flex>
      </Card.Header>
      <Card.Body className="bg-light px-1 pb-1 pt-0 fs--1">
        <Row className="bg-white gx-0 gy-1 text-center">
          {departmentPartners.map(partner => (
            <Col
              key={`DepartmentPartner-${partner.id}`}
              xs={6}
              md={4}
              lg={3}
              xxl={2}
            >
              <Partner user={partner} colBreakpoints={colBreakpoints} />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

DepartmentPartners.propTypes = {
  department: PropTypes.string,
  colBreakpoints: PropTypes.object
};

export default DepartmentPartners;
