import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import Chat from './Chat';
import SimpleBarReact from 'simplebar-react';
import ChatSidebarHeader from './ChatSidebarHeader';
import ChatContactsSearch from '../ChatContactsSearch';
import classNames from 'classnames';
import { ChatContext } from 'context/Context';

const ChatSidebar = ({ wrapper }) => {
  const { chats = [], isOpenChatList } = useContext(ChatContext);
  const [query, setSearch] = useState('');

  return (
    <div
      className={classNames('chat-sidebar bg-white', {
        'start-0': isOpenChatList,
        'max-w-100': wrapper === 'overAll',
        'border-end': wrapper !== 'overAll'
      })}
    >
      {wrapper !== 'overAll' && <ChatSidebarHeader />}

      <ChatContactsSearch
        placeholder="Busca el chat"
        onChange={query => setSearch(query)}
      />

      <div className="contacts-list">
        <SimpleBarReact style={{ height: '100%', minWidth: '72px' }}>
          <Nav className="border-0">
            {chats
              .filter(({ name = '' }) => {
                const normalizedName =
                  name
                    ?.toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '') || '';
                const normalizedQuery =
                  query
                    ?.toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '') || '';
                return normalizedName.includes(normalizedQuery);
              })
              .map((chat, index) => (
                <Chat
                  chat={chat}
                  index={index}
                  key={`Sidebar-Chat-${chat?.NO_ID_FIELD}`}
                />
              ))}
          </Nav>
        </SimpleBarReact>
      </div>
    </div>
  );
};

ChatSidebar.propTypes = {
  wrapper: PropTypes.string
};

export default ChatSidebar;
