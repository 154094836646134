import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Form, ListGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import GoogleMap from 'components/map/GoogleMap';
import Popover from 'components/common/Popover';
import { UserContext } from 'context/Context';

const MapModal = ({ closeModal, isOpen }) => {
  const { createLocation } = useContext(UserContext);
  const [newLocation, setNewLocation] = useState({});

  const handleSearchLocation = place => {
    let {
      address_components: addressComponents,
      formatted_address: address,
      geometry,
      name,
      place_id: placeId
    } = place;
    const { location } = geometry || {};
    const latlng = location?.toJSON();

    const locality = addressComponents?.find(({ types }) =>
      types.includes('locality')
    )?.long_name;
    const administrativeAreaLevel1 = addressComponents?.find(({ types }) =>
      types.includes('administrative_area_level_1')
    )?.long_name;
    const administrativeAreaLevel2 = addressComponents?.find(({ types }) =>
      types.includes('administrative_area_level_2')
    )?.long_name;
    const country = addressComponents?.find(({ types }) =>
      types.includes('country')
    )?.long_name;
    setNewLocation({
      address,
      placeId,
      name,
      latlng,
      locality,
      administrativeAreaLevel1,
      administrativeAreaLevel2,
      country
    });
  };

  const handleCreateNewLocation = () => {
    createLocation(newLocation);
    setNewLocation({});
    closeModal();
  };

  return (
    <Modal show={isOpen} onHide={closeModal} contentClassName="border">
      <Modal.Header closeButton>
        <Modal.Title>Nueva localización</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Dirección</Form.Label>
          <GoogleMap
            initialCenter={newLocation?.latlng}
            className="w-100 min-h-300px d-flex flex-column rounded-soft position-relative"
            mapClassName="flex-grow-1 mt-3 rounded border"
            onSearch={handleSearchLocation}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="ms-3"
          disabled={!newLocation?.placeId}
          onClick={handleCreateNewLocation}
        >
          Crear localización
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

MapModal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool
};

const Locations = ({ className }) => {
  const {
    locations,
    deleteLocation,
    selectLocationId,
    setIsOpenHolidaysModal,
    updateLocation
  } = useContext(UserContext);
  const [isMapModalOpen, setMapModalIsOpen] = useState(false);
  const handleClose = () => {
    setMapModalIsOpen(false);
  };

  const handleOpenNewLocationModal = () => {
    setMapModalIsOpen(true);
  };

  const handleDeleteLocation = location => {
    deleteLocation(location);
  };

  const handleLocationHolidaysClick = location => {
    selectLocationId(location?.NO_ID_FIELD);
    setIsOpenHolidaysModal(true);
  };

  const handleSearchLocation = (place, location) => {
    let {
      address_components: addressComponents,
      formatted_address: address,
      geometry,
      name,
      place_id: placeId
    } = place;
    const { location: placeLocation } = geometry || {};
    const latlng = placeLocation?.toJSON();

    const locality = addressComponents?.find(({ types }) =>
      types.includes('locality')
    )?.long_name;
    const administrativeAreaLevel1 = addressComponents?.find(({ types }) =>
      types.includes('administrative_area_level_1')
    )?.long_name;
    const administrativeAreaLevel2 = addressComponents?.find(({ types }) =>
      types.includes('administrative_area_level_2')
    )?.long_name;
    const country = addressComponents?.find(({ types }) =>
      types.includes('country')
    )?.long_name;

    if (!location?.NO_ID_FIELD) {
      updateLocation(location, {
        address,
        placeId,
        name,
        latlng,
        locality,
        administrativeAreaLevel1,
        administrativeAreaLevel2,
        country
      });
    }
  };

  return (
    <>
      <Card className={className}>
        <FalconCardHeader
          title="Localizaciones"
          endEl={
            <Button
              className="rounded-pill"
              size="sm"
              variant="falcon-default"
              onClick={handleOpenNewLocationModal}
            >
              <FontAwesomeIcon icon="plus" className="me-2" />
              Crear localización
            </Button>
          }
        />
        <Card.Body className="bg-light">
          <Form.Group className="mb-3" controlId="heading">
            <ListGroup>
              {locations?.map(location => {
                const { NO_ID_FIELD, address, latlng, locality, name } =
                  location;
                return (
                  <ListGroup.Item
                    key={`Location-Item-${location?.NO_ID_FIELD}`}
                  >
                    <Flex justifyContent="between" alignItems="center">
                      {name}
                      {!!locality &&
                        !name.includes(locality) &&
                        ` (${locality})`}
                      <Flex>
                        <Button
                          variant="outline-secondary"
                          className="border-0"
                          size="sm"
                          onClick={() => handleLocationHolidaysClick(location)}
                        >
                          <FontAwesomeIcon icon="calendar-alt" />
                        </Button>
                        <Popover
                          content={
                            latlng ? (
                              <GoogleMap
                                key={`Map-Location-${NO_ID_FIELD}`}
                                initialCenter={latlng}
                                zoom={15}
                                className="d-flex flex-column rounded-soft position-relative"
                                searchClassName="mb-3"
                                mapClassName="flex-grow-1"
                                onSearch={place =>
                                  handleSearchLocation(place, NO_ID_FIELD)
                                }
                                options={{
                                  backgroundColor: '#fff'
                                }}
                                style={{
                                  backgroundColor: '#fff',
                                  minWidth: '30.75rem',
                                  minHeight: '18.75rem'
                                }}
                              />
                            ) : (
                              <>
                                <h5 className="text-700">{name}</h5>
                                <p className="fs--1 mb-0">{address}</p>
                              </>
                            )
                          }
                          className="max-w-100 max-h-100"
                        >
                          <Button
                            variant="outline-secondary"
                            className="border-0"
                            size="sm"
                          >
                            <FontAwesomeIcon icon="map-marker-alt" />
                          </Button>
                        </Popover>
                        <Button
                          variant="outline-danger"
                          className="border-0"
                          size="sm"
                          onClick={() => handleDeleteLocation(location)}
                        >
                          <FontAwesomeIcon icon="trash" />
                        </Button>
                      </Flex>
                    </Flex>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Form.Group>
        </Card.Body>
      </Card>
      <MapModal closeModal={handleClose} isOpen={isMapModalOpen} />
    </>
  );
};

Locations.propTypes = {
  className: PropTypes.string
};

export default Locations;
