import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { AssistantContext, UserContext } from 'context/Context';
import CreatableSelect from 'react-select/creatable';

const styles = {
  control: base => ({
    ...base,
    borderRadius: '1.375rem',
    minHeight: 32
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  })
};

const organizerOptions = [
  { value: 'es', label: 'Español' },
  { value: 'en', label: 'Inglés' },
  { value: 'fr', label: 'Francés' },
  { value: 'de', label: 'Alemán' },
  { value: 'ch', label: 'Chino' }
];

const LanguagesItem = ({ onChange: handleChange }) => {
  const [value, setValue] = useState([]);

  useEffect(() => {
    const languages = value.map(({ label }) => label);
    handleChange({ languages });
  }, [value]);

  return (
    <div className="mt-2">
      <Select
        menuPlacement="auto"
        closeMenuOnSelect={false}
        options={organizerOptions}
        placeholder="Selecciona los idiomas..."
        menuPortalTarget={document.body}
        isMulti
        classNamePrefix="react-select"
        className="form-select-sm p-0"
        value={value}
        onChange={value => setValue(value)}
        styles={styles}
      />
    </div>
  );
};

LanguagesItem.propTypes = {
  onChange: PropTypes.func
};

const DepartmentItem = ({ onChange: handleChange }) => {
  const { createDepartment, departments = [] } = useContext(UserContext);

  const handleDepartmentChange = ({ value: department }) => {
    handleChange({ department });
  };

  return (
    <div className="mt-2">
      <CreatableSelect
        menuPlacement="auto"
        options={departments.map(({ name }) => ({
          label: name,
          value: name
        }))}
        placeholder="Selecciona el departamento"
        menuPortalTarget={document.body}
        classNamePrefix="react-select"
        className="form-select-sm p-0"
        onChange={handleDepartmentChange}
        onCreateOption={createDepartment}
        styles={styles}
      />
    </div>
  );
};

DepartmentItem.propTypes = {
  onChange: PropTypes.func
};

const CurrentOfferItem = ({ onChange: handleChange }) => {
  const handleLanguageChange = value => {
    const languages = [value].map(({ label }) => label);
    handleChange({ languages });
  };
  const handleTextChange = ({ target: { value: text } }) => {
    handleChange({ text });
  };

  return (
    <>
      <div className="pt-2">
        <Select
          menuPlacement="auto"
          options={organizerOptions}
          placeholder="Selecciona el idioma..."
          menuPortalTarget={document.body}
          classNamePrefix="react-select"
          className="form-select-sm p-0"
          onChange={handleLanguageChange}
          styles={styles}
        />
        <DepartmentItem onChange={handleChange} />
      </div>
      <div className="pt-2">
        <Form.Control
          placeholder="Texto de la oferta"
          size="sm"
          as="textarea"
          onChange={handleTextChange}
        />
      </div>
    </>
  );
};

CurrentOfferItem.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  onChange: PropTypes.func
};

const AddOfferForm = ({ showForm, setShowForm }) => {
  const { offers = [], createOffer } = useContext(AssistantContext);
  const [formData, setFormData] = useState({});
  const [activeKey, setActiveKey] = useState('ia');

  const handleChange = option => setFormData({ ...formData, ...option });

  const handleSelect = key => {
    setActiveKey(key);
  };

  useEffect(() => {
    setFormData({});
  }, [activeKey]);

  return (
    <>
      {showForm && (
        <div
          className={classNames(
            'rounded-3 transition-none card shadow-sm p-3 pt-1 bg-white dark__bg-1000 mb-3',
            {
              'mt-3': !!offers?.length
            }
          )}
        >
          <Form
            onSubmit={event => {
              event.preventDefault();
              const values = Object.values(formData);
              if (
                !values.length ||
                !formData?.department ||
                !formData?.languages?.length ||
                (activeKey === 'text' && !formData?.text)
              ) {
                return;
              }
              createOffer({ ...formData, index: offers.length });
              setShowForm(false);
            }}
          >
            <Tabs activeKey={activeKey} onSelect={handleSelect}>
              <Tab className="pt-2" eventKey="ia" title="Generada con IA">
                <Form.Group>
                  <LanguagesItem
                    key={`Languages-${activeKey}`}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <DepartmentItem
                    key={`Department-${activeKey}`}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Tab>
              <Tab className="pt-2" eventKey="text" title="Existente">
                <Form.Group className="mb-3">
                  <CurrentOfferItem
                    key={`CurrentOffer-${activeKey}`}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Tab>
            </Tabs>

            <Row className="gx-2">
              <Col xs={5}>
                <Button
                  variant="link"
                  size="sm"
                  className="d-block w-100 text-500 text-decoration-none"
                  type="button"
                  onClick={() => setShowForm(false)}
                >
                  Cancelar
                </Button>
              </Col>
              <Col xs={7}>
                <Button
                  variant="primary"
                  size="sm"
                  className="d-block w-100 text-truncate"
                  type="submit"
                >
                  Añadir oferta
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </>
  );
};

AddOfferForm.propTypes = {
  showForm: PropTypes.bool,
  setShowForm: PropTypes.func
};

export default AddOfferForm;
