import React, { useContext } from 'react';
import classNames from 'classnames';
import Calendar from 'components/common/Calendar';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'react-bootstrap';
import { CalendarContext, UserContext } from 'context/Context';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import SoftBadge from 'components/common/SoftBadge';

dayjs.locale('es');

const Event = ({ color = 'info', details = {}, isLast, ...rest }) => {
  const { me } = useContext(UserContext);
  const { deleteItem, update } = useContext(CalendarContext);
  const {
    NO_ID_FIELD,
    assistance,
    start,
    title,
    location,
    duration,
    interested,
    new: isNew
  } = details;
  const day = dayjs(start).format('DD');
  const month = dayjs(start).format('MMM');
  const when = dayjs(start).format('dddd, DD MMMM YYYY');
  const { company } = useContext(UserContext);
  const { name: organizer = '' } = company || {};
  const { assist } =
    assistance?.find(({ ref }) => ref?.path === me?.ref?.path) || {};

  const handleClickAction = async assist => {
    let _assistance = assistance.filter(
      ({ ref }) => ref?.path !== me?.ref.path
    );
    const createdAt = new Date(Date.now()).toISOString();
    _assistance = [..._assistance, { ref: me?.ref, assist, createdAt }];
    await update(details, { assistance: _assistance });
    const els = document.querySelectorAll(`[data-id="${NO_ID_FIELD}"]`);
    els?.forEach(el => {
      el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.remove(
        'highlight'
      );
    });
  };

  const handleDelete = async () => {
    await deleteItem(details);
    const els = document.querySelectorAll(`[data-id="${NO_ID_FIELD}"]`);
    els?.forEach(el => {
      el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.remove(
        'highlight'
      );
    });
  };

  return (
    <Flex {...rest}>
      <Calendar day={day} month={month} color={color} />
      <Flex direction="column" className="flex-1 position-relative ps-3">
        <Flex className="flex-grow-1 fs--1">
          <div className="flex-grow-1">
            <h6 className="fs-0 mb-0">
              <Link
                to={`/company/events/${NO_ID_FIELD}`}
                className={`text-${color}`}
              >
                <span className="me-1">{title}</span>
                {isNew && (
                  <SoftBadge pill bg={color}>
                    Nuevo
                  </SoftBadge>
                )}
              </Link>
            </h6>
            <p className="mb-1">
              Organizado por
              <span className="ms-1 text-primary">{organizer}</span>
            </p>

            {when && <p className="text-1000 mb-0">🗓️ {when}</p>}
            {location && <p className="text-1000 mb-0">📍 {location}</p>}
            {duration && <p className="text-1000 mb-0">🕒 {duration}</p>}
            {interested && <p className="text-1000 mb-0">{interested}</p>}
          </div>

          <div className="ms-3 text-nowrap">
            <ButtonGroup vertical>
              <Button
                variant={classNames({
                  'falcon-success': assist !== true,
                  success: assist === true
                })}
                size="sm"
                onClick={() => handleClickAction(true)}
              >
                Voy
              </Button>
              <Button
                variant={classNames({
                  'falcon-danger': assist !== false,
                  danger: assist === false
                })}
                size="sm"
                onClick={() => handleClickAction(false)}
              >
                No voy
              </Button>
              <Button
                variant={classNames({
                  'falcon-default': assist !== null,
                  yellow: assist === null
                })}
                size="sm"
                onClick={() => handleClickAction(null)}
              >
                No lo sé
              </Button>
              {me?.type === 'admin' && (
                <>
                  <hr className="h-0 w-100 m-0 border border-300 border-2" />
                  <Button
                    variant="falcon-danger"
                    size="sm"
                    onClick={handleDelete}
                  >
                    Eliminar
                  </Button>
                </>
              )}
            </ButtonGroup>
          </div>
        </Flex>
        {!isLast && <div className="border-dashed border-bottom my-3"></div>}
      </Flex>
    </Flex>
  );
};

Event.propTypes = {
  color: PropTypes.string,
  details: PropTypes.shape({
    calendar: PropTypes.shape(Calendar.propTypes),
    title: PropTypes.string.isRequired,
    organizerId: PropTypes.string,
    time: PropTypes.string,
    place: PropTypes.string,
    location: PropTypes.string,
    duration: PropTypes.string,
    interested: PropTypes.string,
    badge: PropTypes.object
  }),
  isLast: PropTypes.bool
};

export default Event;
