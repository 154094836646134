import React, { useContext } from 'react';
import AdminHome from './Admin';
import EmployeeHome from './Employee';
import { UserContext } from 'context/Context';

const Home = () => {
  const { me } = useContext(UserContext);
  return me?.type === 'admin' ? <AdminHome /> : <EmployeeHome />;
};

export default Home;
