import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';
import { useNavigate } from 'react-router-dom';
import { diff } from 'deep-object-diff';
import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where
} from '@firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from '@firebase/storage';
import {
  useFirestore,
  useFirestoreCollectionData,
  useStorage
} from 'reactfire';
import { CloseButton, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AppContext, { ChatContext, UserContext } from 'context/Context';
import NewChecklistModal from './NewChecklistModal';
import NewGreetingModal from './NewGreetingModal';
import NewQuestionaireModal from './NewQuestionaireModal';
import { getUnique } from 'helpers/utils';
import defaultGroup from 'assets/img/chat/default_group.png';

const getNotificationText = {
  checklist: title => `Checklist: ${title}`,
  deleted: () => 'Mensaje eliminado',
  event: title => `Evento: ${title}`,
  happy_questionaire: () => 'Encuesta Happyfy',
  image: () => 'Foto',
  objective: title => `Objetivo: ${title}`,
  questionaire: title => `Encuesta: ${title}`
};

const ChatProvider = ({ children }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const {
    company,
    happybot,
    me,
    partners = [],
    resetUnreadMessages
  } = useContext(UserContext);
  const { NO_ID_FIELD: companyId } = company || {};
  const navigate = useNavigate();
  const [isOpenNewChecklistModal, setIsOpenNewChecklistModal] = useState(false);
  const [isOpenNewQuestionaireModal, setIsOpenNewQuestionaireModal] =
    useState(false);
  const [isOpenNewGreetingModal, setIsOpenNewGreetingModal] = useState(false);
  const [referrer, _setReferrer] = useState();
  const [modalChildren, setModalChildren] = useState();
  const [now] = useState(new Date().toISOString());
  const [show, showModal] = useState();
  const [chats, setChats] = useState();
  const [currentChat, setCurrentChat] = useState();
  const [currentParticipants, setCurrentParticipants] = useState();
  const [textAreaInitialHeight, setTextAreaInitialHeight] = useState(32);
  const [activeChatId, setActiveChatId] = useState();
  const [isOpenNewChat, setIsOpenNewChat] = useState(false);
  const [isOpenNewGroup, setIsOpenNewGroup] = useState(false);
  const [isOpenChatList, setIsOpenChatList] = useState(true);
  const [isOpenChatInfo, setIsOpenChatInfo] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(true);
  const [unread, setUnread] = useState([]);
  const [lastMessage, setLastMessage] = useState();
  const [messages, setMessages] = useState([]);
  const [hasAllMessagesLoaded, setAllMessagesLoaded] = useState(false);

  const db = useFirestore();
  const storage = useStorage();
  let chatsQuery = query(collection(db, 'none'));
  let companyChatQuery = query(collection(db, 'none'));
  let checklistTemplatesQuery = query(collection(db, 'none'));
  let messagesQuery = query(collection(db, 'none'));

  if (companyId && me?.ref) {
    const filters = [
      where('companyId', '==', companyId),
      where('participants', 'array-contains', me?.ref),
      orderBy('updatedAt', 'desc')
    ];
    companyChatQuery = query(
      collection(db, 'chat'),
      where('companyId', '==', companyId),
      where('type', '==', 'company'),
      limit(1)
    );
    chatsQuery = query(collection(db, 'chat'), ...filters);
    checklistTemplatesQuery = query(
      collection(db, 'checklist_templates'),
      where('companyId', '==', companyId),
      orderBy('title', 'asc')
    );

    if (currentChat) {
      messagesQuery = query(
        collection(db, 'chat_messages'),
        where('companyId', '==', company?.NO_ID_FIELD),
        where('chatId', '==', currentChat?.NO_ID_FIELD),
        where('createdAt', '>', now),
        orderBy('createdAt', 'desc')
      );
    }
  }
  const { data: companyChatRaw = [], status: companyChatStatus } =
    useFirestoreCollectionData(companyChatQuery);
  const { data: checklistTemplates = [] } = useFirestoreCollectionData(
    checklistTemplatesQuery
  );
  const { data: chatsRaw = [] } = useFirestoreCollectionData(chatsQuery);
  const { data: listeningMessages = [] } =
    useFirestoreCollectionData(messagesQuery);

  const chatsRawWithCompany = [
    ...companyChatRaw,
    ...chatsRaw.filter(
      ({ deletedTo }) => !deletedTo?.some(ref => ref?.path === me?.ref?.path)
    )
  ]
    .filter(chat => chat)
    .sort((c1, c2) => (c1.updatedAt > c2.updatedAt ? -1 : 1));

  const fetchMoreMessages = async () => {
    if (!currentChat) {
      return;
    }
    const filter = [
      where('companyId', '==', companyId),
      where('chatId', '==', currentChat?.NO_ID_FIELD),
      orderBy('createdAt', 'desc'),
      lastMessage && startAfter(lastMessage),
      limit(10)
    ].filter(data => data);

    const messagesQuery = query(collection(db, 'chat_messages'), ...filter);
    const snapshot = await getDocs(messagesQuery);
    const { docs: messageDocs = [] } = snapshot || {};
    const newMessages = await Promise.all(
      messageDocs.map(async doc => ({
        NO_ID_FIELD: doc.id,
        ref: doc.ref,
        ...(await doc.data())
      }))
    );
    if (newMessages.length) {
      setLastMessage(messageDocs[messageDocs.length - 1]);
      setMessages(oldMessages =>
        getUnique([...oldMessages, ...newMessages], 'NO_ID_FIELD')
      );
      setAllMessagesLoaded(false);
    } else {
      setAllMessagesLoaded(true);
    }
  };

  const createChat = async ({ avatar, ...params }) => {
    const { participants, type } = params;
    if (type !== 'company') {
      const isValid = [...new Set(participants)].length > 1;
      if (!isValid) {
        toast.warning('Añade participantes');
        return;
      }
      const userRef = participants?.find(ref => ref?.path !== me?.ref?.path);
      const chatAlreadyCreated = chats?.find(({ participants, type }) => {
        return (
          type === 'user' &&
          participants.some(ref => ref?.path === me?.ref?.path) &&
          participants.some(ref => ref?.path === userRef?.path)
        );
      });
      if (type === 'user' && chatAlreadyCreated) {
        try {
          const { NO_ID_FIELD } = chatAlreadyCreated;
          const ref = doc(db, 'chat', NO_ID_FIELD);
          await updateDoc(ref, {
            deletedTo: deleteField()
          });
          navigate(`/company/chat/${NO_ID_FIELD}`);
        } catch (error) {
          console.error(error);
        }
        return chatAlreadyCreated;
      }
    }

    try {
      const now = new Date(Date.now()).toISOString();
      const data = {
        companyId: company?.NO_ID_FIELD,
        from: me?.ref,
        createdAt: now,
        updatedAt: now,
        ...params
      };
      const newChatRef = await addDoc(collection(db, 'chat'), data);
      if (type === 'group' && avatar) {
        const avatarRef = ref(storage, `chat/${newChatRef.id}/avatar.jpeg`);
        await uploadBytes(avatarRef, avatar);
      }
      type !== 'company' && navigate(`/company/chat/${newChatRef.id}`);
      return newChatRef;
    } catch (error) {
      console.error(error);
    }
  };

  const createMessage = async params => {
    try {
      const { chat, chatId, text } = params;
      const createdAt = new Date(Date.now()).toISOString();
      const data = {
        companyId: company?.NO_ID_FIELD,
        from: me?.ref,
        createdAt,
        ...(referrer ? { referrer } : {}),
        ...params
      };
      _setReferrer();
      const newMessageRef = await addDoc(collection(db, 'chat_messages'), data);
      await updateChat(chatId, {
        lastMessage: { ref: newMessageRef, text },
        updatedAt: createdAt
      });
      setTimeout(() => {
        setScrollToBottom(true);
      }, 100);

      resetUnreadMessages(chat);
      return newMessageRef;
    } catch (error) {
      console.error(error);
    }
  };

  const createChecklistTemplate = async params => {
    try {
      const createdAt = new Date(Date.now()).toISOString();
      const data = {
        companyId: company?.NO_ID_FIELD,
        from: me?.ref,
        createdAt,
        ...params
      };
      const newChecklistTemplateRef = await addDoc(
        collection(db, 'checklist_templates'),
        data
      );
      return newChecklistTemplateRef;
    } catch (error) {
      console.error(error);
    }
  };

  const updateChecklistTemplate = async (template, params) => {
    try {
      const ref = doc(db, 'checklist_templates', template?.NO_ID_FIELD);
      await updateDoc(ref, params);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteChecklistTemplate = async NO_ID_FIELD => {
    await deleteDoc(doc(db, 'checklist_templates', NO_ID_FIELD));
    return;
  };

  const updateChat = async (chatId, params) => {
    try {
      const ref = doc(db, 'chat', chatId);
      await updateDoc(ref, params);
    } catch (error) {
      console.error(error);
    }
  };

  const updateMessage = async (NO_ID_FIELD, params) => {
    try {
      const ref = doc(db, 'chat_messages', NO_ID_FIELD);
      await updateDoc(ref, params);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteChat = async chat => {
    try {
      if (chat?.type === 'user') {
        const deletedTo = [...(chat?.deletedTo || []), me?.ref].filter(
          ref => ref
        );
        await updateChat(chat?.NO_ID_FIELD, { deletedTo });
        return;
      }

      const participants = chat?.participants.filter(
        ref => ref?.path !== me?.ref?.path
      );
      if (!participants.length) {
        await deleteDoc(doc(db, 'chat', chat?.NO_ID_FIELD));
        return;
      }
      await updateChat(chat?.NO_ID_FIELD, { participants });
    } catch (error) {
      console.error(error);
    }
  };

  const setReferrer = referrer => {
    const { NO_ID_FIELD } = referrer || {};
    const ref = NO_ID_FIELD ? doc(db, 'chat_messages', NO_ID_FIELD) : referrer;
    _setReferrer(ref);
  };

  const handleCreateChecklistMessage = async params => {
    try {
      let newMessage = {
        text: '',
        chatId: currentChat?.NO_ID_FIELD,
        chat: currentChat?.ref,
        extra: {
          ...params
        },
        status: 'delivered',
        type: 'checklist'
      };
      await createMessage(newMessage);
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => {
      setScrollToBottom(true);
    }, 100);
  };

  const handleCreateQuestionaireMessage = async params => {
    try {
      let newMessage = {
        text: '',
        chatId: currentChat?.NO_ID_FIELD,
        chat: currentChat?.ref,
        extra: {
          ...params
        },
        status: 'delivered',
        type: 'questionaire'
      };
      await createMessage(newMessage);
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => {
      setScrollToBottom(true);
    }, 100);
  };

  const handleCreateGreetingMessage = async params => {
    try {
      let newMessage = {
        text: '',
        chatId: currentChat?.NO_ID_FIELD,
        chat: currentChat?.ref,
        status: 'delivered',
        ...params
      };
      await createMessage(newMessage);
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => {
      setScrollToBottom(true);
    }, 100);
  };

  useEffect(() => {
    companyChatStatus === 'success' &&
      !companyChatRaw?.length &&
      createChat({ type: 'company' });
  }, [companyChatStatus, companyChatRaw?.length]);

  useEffect(() => {
    setLastMessage();
    setMessages([]);
  }, [currentChat?.NO_ID_FIELD]);

  useEffect(() => {
    (async () => {
      const _chats = await Promise.all(
        chatsRawWithCompany.map(async chat => {
          const _ref = doc(db, 'chat', chat.NO_ID_FIELD);
          let avatar = defaultGroup;
          let participants = [];
          let status = '';
          let { name, type } = chat;
          try {
            if (type === 'company') {
              const { avatar: companyAvatar, name: companyName } = company;
              avatar = companyAvatar;
              name = companyName;
              participants = partners?.map(({ ref }) => ref);
            } else if (type === 'group') {
              const { avatar: groupAvatar } =
                chats?.find(
                  ({ NO_ID_FIELD } = {}) => NO_ID_FIELD === chat.NO_ID_FIELD
                ) || {};
              if (!groupAvatar || groupAvatar?.match(/$\/static\/media/)) {
                avatar = await getDownloadURL(
                  ref(storage, `/chat/${chat?.NO_ID_FIELD}/avatar.jpeg`)
                );
              } else if (groupAvatar) {
                avatar = groupAvatar;
              }
            } else if (type === 'user') {
              const ref = chat?.participants?.find(
                ref => ref?.path !== me?.ref?.path
              );
              const {
                avatar: userAvatar,
                name: userName,
                status: userStatus
              } = [happybot, ...partners].find(
                partner => partner?.ref?.path === ref?.path
              ) || {};
              avatar = userAvatar;
              name = userName;
              status = userStatus;
            }
          } catch (error) {
            console.error(error);
          }
          return { participants, ...chat, avatar, name, ref: _ref, status };
        })
      );
      if (!equal(chats, _chats)) {
        setChats(_chats);
        currentChat &&
          setCurrentChat?.(
            _chats.find(
              ({ NO_ID_FIELD }) => currentChat.NO_ID_FIELD === NO_ID_FIELD
            )
          );
      }
    })();
  }, [chatsRawWithCompany, happybot, partners]);

  useEffect(() => {
    if (!currentChat) {
      return;
    }
    setCurrentParticipants(
      currentChat.participants?.map(
        ref =>
          [happybot, ...partners].find(
            partner => partner?.ref?.path === ref?.path
          ) || { ref }
      )
    );
  }, [currentChat, partners]);

  const sendNotification = useCallback(
    async ref => {
      if (!ref) {
        return;
      }
      const chatData = (await getDoc(ref)).data();
      const { lastMessage } = chatData;
      const { ref: lastMessageRef, text } = lastMessage;
      const lastMessageData = (await getDoc(lastMessageRef)).data();
      const { extra, from = {}, type: lastMessageType } = lastMessageData;
      const { title: lastMessageTitle } = extra || {};
      const { path } = from;

      let permission = Notification.permission;
      if (permission !== 'granted') {
        permission = await Notification.requestPermission();
      }
      if (permission !== 'granted') {
        return;
      }
      const partner = partners?.find(({ ref }) => ref?.path === path) || {};
      const { name: title, avatar: icon } = partner;
      const body =
        text || getNotificationText?.[lastMessageType]?.(lastMessageTitle);
      new Notification(title, { body, icon });
    },
    [chats, partners]
  );

  useEffect(() => {
    const changes = diff(unread, me?.unreadMessages);
    if (!changes) {
      return;
    }
    Object.keys(changes).forEach(key => {
      const { ref } = me?.unreadMessages?.[key] || {};
      sendNotification(ref);
    });
    setUnread(me?.unreadMessages);
  }, [me?.unreadMessages]);

  // console.log('CHAT >>>', { currentChat, messages, listeningMessages });

  const value = {
    chats,
    checklistTemplates,
    messages: getUnique(
      [
        ...messages,
        ...listeningMessages.map(data => ({
          ...data,
          ref: doc(db, 'chat_messages', data.NO_ID_FIELD)
        }))
      ],
      'NO_ID_FIELD'
    )?.sort((m1, m2) => (m1.createdAt < m2.createdAt ? -1 : 1)),
    createChat,
    updateChat,
    deleteChat,
    createMessage,
    fetchMoreMessages,
    updateMessage,
    createChecklistTemplate,
    updateChecklistTemplate,
    deleteChecklistTemplate,
    activeChatId,
    setActiveChatId,
    textAreaInitialHeight,
    setTextAreaInitialHeight,
    hasAllMessagesLoaded,
    isOpenNewChat,
    setIsOpenNewChat,
    isOpenNewGreetingModal,
    setIsOpenNewGreetingModal,
    isOpenNewChecklistModal,
    setIsOpenNewChecklistModal,
    isOpenNewQuestionaireModal,
    setIsOpenNewQuestionaireModal,
    isOpenNewGroup,
    setIsOpenNewGroup,
    isOpenChatList,
    setIsOpenChatList,
    isOpenChatInfo,
    setIsOpenChatInfo,
    currentChat: currentChat && {
      ...currentChat,
      participants: currentParticipants
    },
    currentParticipants,
    setCurrentChat,
    scrollToBottom,
    setScrollToBottom,
    showModal,
    setModalChildren,
    referrer,
    setReferrer
  };

  return (
    <ChatContext.Provider value={value}>
      <Modal
        show={show}
        onHide={() => showModal(false)}
        contentClassName="border"
        centered
      >
        <Modal.Body>
          <CloseButton
            className="p-3 position-absolute top-0 end-0"
            variant={isDark ? 'white' : undefined}
            onClick={() => showModal(false)}
          />
          {modalChildren}
        </Modal.Body>
      </Modal>
      <NewChecklistModal
        isOpen={isOpenNewChecklistModal}
        setIsOpen={setIsOpenNewChecklistModal}
        create={handleCreateChecklistMessage}
      />
      <NewQuestionaireModal
        isOpen={isOpenNewQuestionaireModal}
        setIsOpen={setIsOpenNewQuestionaireModal}
        create={handleCreateQuestionaireMessage}
      />
      <NewGreetingModal
        isOpen={!!isOpenNewGreetingModal}
        setIsOpen={setIsOpenNewGreetingModal}
        create={handleCreateGreetingMessage}
        participants={currentParticipants}
        type={`${isOpenNewGreetingModal}`}
      />
      {children}
    </ChatContext.Provider>
  );
};

ChatProvider.propTypes = { children: PropTypes.node.isRequired };

export default ChatProvider;
