import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Calendar from 'components/common/Calendar';
import Flex from 'components/common/Flex';
import {
  Alerts,
  DayBalance,
  DayTimeControlInputs,
  WorkingDay,
  WorkingDayDuration
} from './Components';
import { CalendarContext } from 'context/Context';
import { getDaySeconds, getDayStartTime, getDayEndTime } from 'helpers/utils';
import dayjs from 'dayjs';
import timeOffSubtypes from 'components/user/time-off/subtypes.json';

const columns = [
  {
    accessor: 'day',
    headerProps: {
      className: 'py-3 min-w-220px w-220px'
    },
    Header: 'Día',
    cellProps: {
      className: 'py-3 min-w-220px w-220px'
    },
    Cell: rowData => {
      const { day: date } = rowData.row.original;
      if (!date) {
        return null;
      }
      const weekDay = dayjs(date).format('dddd');
      const day = dayjs(date).format('DD');
      const month = dayjs(date).format('MMM');

      return (
        <Flex alignItems="center">
          <div className="scale-75 text-center">
            <Calendar className="w-auto" day={day} month={month} />
          </div>
          <strong className="fs--3 text-uppercase ms-1">{weekDay}</strong>
        </Flex>
      );
    }
  },
  {
    accessor: 'maxSeconds',
    headerProps: {
      className: 'text-center'
    },
    Header: 'Tiempo teórico',
    cellProps: {
      className: 'text-center'
    },
    Cell: rowData => {
      return <WorkingDay {...rowData.row.original} />;
    }
  },
  {
    accessor: 'seconds',
    headerProps: {
      className: 'text-center'
    },
    Header: 'Tiempo trabajado',
    cellProps: {
      className: 'text-center'
    },
    Cell: rowData => {
      return (
        <WorkingDayDuration
          className="w-100px m-auto"
          {...rowData.row.original}
          showExtra={false}
        />
      );
    }
  },
  {
    accessor: 'balanceTime',
    headerProps: {
      className: 'text-center'
    },
    Header: 'Balance',
    cellProps: {
      className: 'text-center'
    },
    Cell: rowData => {
      return <DayBalance {...rowData.row.original} />;
    }
  },
  {
    accessor: 'records',
    headerProps: {
      className: 'text-center'
    },
    Header: 'Distribución de horas',
    cellProps: {
      className: 'text-center'
    },
    Cell: rowData => {
      return <DayTimeControlInputs {...rowData.row.original} />;
    }
  },
  {
    accessor: 'alerts',
    headerProps: {
      className: 'text-end'
    },
    Header: 'Alertas',
    cellProps: {
      className: 'text-end w-0'
    },
    Cell: rowData => {
      return <Alerts {...rowData.row.original} />;
    }
  }
];

const CustomTimeControlDetail = ({ days, schedule, user }) => {
  const { timeOff: timesOff = [] } = useContext(CalendarContext);
  const userTimesOff = timesOff.filter(
    ({ participants }) =>
      !participants || participants.some(ref => ref?.path === user?.ref?.path)
  );
  const { location } = user || {};
  const { holidays = [] } = location || {};
  const innerRef = useRef();
  const data =
    [...(days || [])]
      ?.sort(({ day: day1 }, { day: day2 }) => (day1 < day2 ? -1 : 1))
      .map(({ day, records }) => {
        const isToday =
          day === new Date().toISOString().match(/\d{4}-\d{2}-\d{2}/)?.[0];
        const holiday = holidays.find(
          ({ start }) => dayjs(start).format('YYYY-MM-DD') === day
        );
        const timeOff = userTimesOff.find(
          ({ start, end }) =>
            dayjs(day).format('YYYY-MM-DD') >=
              dayjs(start).format('YYYY-MM-DD') &&
            dayjs(day).format('YYYY-MM-DD') <= dayjs(end).format('YYYY-MM-DD')
        );
        const subtypeData =
          timeOffSubtypes.find(({ value }) => value === timeOff?.subtype) || {};
        const { extra, working } = subtypeData;
        const isHoliday = !!holiday;
        const isTimeOff = !!timeOff;
        const maxSeconds =
          isHoliday || (!working && isTimeOff && extra)
            ? 0
            : getDaySeconds(schedule, new Date(day));
        const startTime =
          isHoliday || (!working && isTimeOff && extra)
            ? ''
            : getDayStartTime(schedule, new Date(day));
        const endTime =
          isHoliday || (!working && isTimeOff && extra)
            ? ''
            : getDayEndTime(schedule, new Date(day));

        return {
          day,
          holiday,
          timeOff,
          isHoliday,
          isTimeOff,
          isToday,
          maxSeconds,
          records,
          startTime,
          endTime,
          user
        };
      })
      .sort((item1, item2) => (item1.day < item2.day ? -1 : 1)) || [];

  return (
    <>
      <AdvanceTableWrapper
        innerRef={innerRef}
        columns={columns}
        data={data}
        pagination
        perPage={9999999}
      >
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-light"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          {!data.length && (
            <div className="border-bottom border-200 fs--1 p-3">
              No hay datos
            </div>
          )}
        </Card.Body>
      </AdvanceTableWrapper>
      <Card.Footer className="bg-light pb-0" />
    </>
  );
};

CustomTimeControlDetail.propTypes = {
  days: PropTypes.array,
  schedule: PropTypes.object,
  user: PropTypes.object
};

export default CustomTimeControlDetail;
