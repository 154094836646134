import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { FeedContext, UserContext } from 'context/Context';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where
} from '@firebase/firestore';
import { deleteObject, listAll, ref } from '@firebase/storage';
import { useFirestore, useStorage } from 'reactfire';

const FeedProvider = ({ children }) => {
  const { company, me } = useContext(UserContext);
  const { NO_ID_FIELD: companyId } = company || {};
  const db = useFirestore();
  const storage = useStorage();
  const location = useLocation();
  const { hash } = location;
  const [feeds, setFeeds] = useState([]);
  const [hasFinished, setHasFinished] = useState(false);
  const [lastPost, setLastPost] = useState();

  const reset = () => {
    setFeeds([]);
    setHasFinished(false);
    setLastPost();
  };

  const fetchMore = async (limitProp = 3) => {
    if (!companyId) {
      return;
    }
    const filter = [
      where('companyId', '==', companyId),
      orderBy('updatedAt', 'desc'),
      lastPost && startAfter(lastPost),
      limit(limitProp)
    ].filter(data => data);
    const feedQuery = query(collection(db, 'feeds'), ...filter);
    const snapshot = await getDocs(feedQuery);
    const { docs: feeds = [] } = snapshot || {};
    if (feeds.length) {
      setLastPost(feeds[feeds.length - 1]);
      setFeeds(oldFeeds => [...oldFeeds, ...feeds]);
    } else {
      setHasFinished(true);
    }
  };

  const createComment = async (feed, params) => {
    try {
      const now = new Date().toISOString();
      const data = {
        createdAt: now,
        ...params
      };
      const newFeedRef = await addDoc(
        collection(db, 'feeds', feed?.NO_ID_FIELD, 'comments'),
        data
      );
      return newFeedRef;
    } catch (error) {
      console.error(error);
    }
  };

  const createFeed = async ({ ...params }) => {
    try {
      const now = new Date().toISOString();
      const data = {
        companyId,
        createdAt: now,
        createdBy: me?.ref,
        updatedAt: now,
        ...params
      };
      const newFeedRef = await addDoc(collection(db, 'feeds'), data);
      return newFeedRef;
    } catch (error) {
      console.error(error);
    }
  };

  const deleteFeed = async ({ NO_ID_FIELD }) => {
    try {
      await deleteDoc(doc(db, 'feeds', NO_ID_FIELD));
      const feedRef = ref(
        storage,
        `companies/${companyId}/feeds/${NO_ID_FIELD}`
      );
      const res = await listAll(feedRef);
      const { items } = res;
      items.forEach(itemRef => deleteObject(itemRef));
    } catch (error) {
      console.error(error);
    }
  };

  const updateComment = async (feed, comment, params) => {
    try {
      await updateDoc(
        doc(db, 'feeds', feed?.NO_ID_FIELD),
        'comments',
        comment?.NO_ID_FIELD,
        params
      );
    } catch (error) {
      console.error(error);
    }
  };

  const updateFeed = async (feed, params) => {
    try {
      await updateDoc(doc(db, 'feeds', feed?.NO_ID_FIELD), params);
    } catch (error) {
      console.error(error);
    }
  };

  const scrollToFeed = () => {
    if (!hash || !feeds?.length) {
      return;
    }
    document
      .querySelector(hash)
      ?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };

  useEffect(() => {
    hash && scrollToFeed();
  }, [hash, feeds?.length]);

  useEffect(() => {
    reset();
  }, [company?.NO_ID_FIELD]);

  return (
    <FeedContext.Provider
      value={{
        companyId,
        createComment,
        createFeed,
        deleteFeed,
        feeds,
        fetchMore,
        hasFinished,
        lastPost,
        scrollToFeed,
        updateComment,
        updateFeed
      }}
    >
      {children}
    </FeedContext.Provider>
  );
};

FeedProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default FeedProvider;
