import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import { collection, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import Flex from 'components/common/Flex';
import { getColor, groupBy, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { UserContext } from 'context/Context';

import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

const getOptions = ({ labels, data }) => ({
  tooltip: {
    trigger: 'axis',
    formatter: '<strong>{b0}</strong> : {c0}'
  },
  xAxis: {
    data: labels
  },
  series: [
    {
      type: 'line',
      data,
      smooth: true,
      lineStyle: {
        width: 3
      },

      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.25)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: {
    bottom: '2%',
    top: '2%',
    right: '10px',
    left: '10px'
  }
});

const MonthlyUseOfHappyfy = ({ className }) => {
  const { company } = useContext(UserContext);
  const firestore = useFirestore();
  const activityCollection = collection(firestore, 'app_activity');
  let now = new Date(Date.now());
  let date = new Date(now.setMonth(now.getMonth() - 6));
  const halfYearAgo = date.toISOString().split(':').shift();
  const activityQuery = query(
    activityCollection,
    where('companyId', '==', `${company?.NO_ID_FIELD}`),
    where('createdAt', '>=', halfYearAgo)
  );
  let { data: raw = [] } = useFirestoreCollectionData(activityQuery);

  raw = raw.map(data => {
    const { createdAt } = data;
    let date = new Date(createdAt).toISOString().split('T')[0];
    date = date.split('-');
    date.pop();
    date = date.join('-');
    return { ...data, date };
  });

  now = new Date(Date.now());
  date = new Date(now.setMonth(now.getMonth() - 1));

  const monthAgo = date.toISOString().split(':').shift();
  const lastMonthData = raw.filter(({ createdAt }) => {
    let date = new Date(createdAt).toISOString().split(':')[0];
    let [monthAgoInit] = monthAgo.match(/\d+-\d+/);
    return date > monthAgoInit && date <= monthAgo;
  });

  const rawByDate = Object.entries(groupBy(raw, 'date'))
    .sort(([key1], [key2]) => (key1 < key2 ? -1 : 1))
    .map(([key, value]) => ({ [key]: value?.length }))
    .reduce((result, date) => ({ ...result, ...date }), {});

  let labels = Object.keys(rawByDate).map(key => {
    const date = new Date(key);
    return new Intl.DateTimeFormat('es', {
      month: 'long',
      year: 'numeric'
    }).format(date);
  });

  let data = Object.values(rawByDate);
  if (data.length === 0) {
    labels = ['', ''];
    data = [0, 0];
  } else if (data.length === 1) {
    labels = [...labels, ...labels];
    data = [...data, ...data];
  }
  const diff = data?.[data.length - 1] - lastMonthData.length;
  const diffPercentage = Math.abs(
    Math.round((diff * 100) / (data?.[data.length - 2] || 1))
  );
  const icon = diff === 0 ? 'minus' : diff > 0 ? 'caret-up' : 'caret-down';
  const diffBg = diff === 0 ? 'info' : diff > 0 ? 'success' : 'danger';

  return (
    <Card className={className}>
      <div className="overflow-hidden rounded-4">
        <Card.Header className="pb-0">
          <h6 className="m-0">Uso mensual de Happyfy</h6>
        </Card.Header>

        <Card.Body
          as={Flex}
          alignItems="end"
          justifyContent="between"
          className="pt-0"
        >
          <div>
            <h2 className="fw-normal text-700 mb-1 lh-1">
              {data[data.length - 1]}
            </h2>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  Comparado con el mes pasado hasta el día de hoy
                </Tooltip>
              }
            >
              <span>
                <SoftBadge pill bg={diffBg} className="me-2 fs--2">
                  <FontAwesomeIcon icon={icon} className="me-1" />
                  {diffPercentage}%
                </SoftBadge>
              </span>
            </OverlayTrigger>
          </div>
          <div className="ps-0">
            <BasicECharts
              echarts={echarts}
              options={getOptions({ labels, data })}
              style={{ width: '8.5rem', height: 98 }}
            />
          </div>
        </Card.Body>
      </div>
    </Card>
  );
};

MonthlyUseOfHappyfy.propTypes = {
  className: PropTypes.string
};

export default MonthlyUseOfHappyfy;
