import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Image, Row } from 'react-bootstrap';
import { ColorExtractor } from 'react-color-extractor';
import CourseDetailsBg from 'assets/img/e-learning/course-details-bg.png';
import playicon from 'assets/img/icons/play.svg';
import beach from 'assets/video/beach.mp4';
import beachPoster from 'assets/video/beach.jpg';
import Background from 'components/common/Background';
import SoftBadge from 'components/common/SoftBadge';
import StarRating from 'components/common/StarRating';
import ModalVideoContent from '../../ModalVideoContent';

const ProductBanner = ({ product }) => {
  const {
    NO_ID_FIELD,
    badge,
    logo,
    description,
    reviews = [],
    title,
    video
  } = product;
  const [showModal, setShowModal] = useState(false);
  const [showExcerpt, setShowExcerpt] = useState(false);
  const [backgroundColor, setMainColor] = useState();
  const rating =
    reviews?.reduce((total, { rating }) => total + rating, 0) /
      reviews?.length || 0;

  const handleColors = colors => {
    setMainColor(colors?.[0]);
  };

  return (
    <Card className="overflow-hidden light mb-3">
      <Card.Body style={{ backgroundColor }}>
        <Background image={CourseDetailsBg} className="rounded-3 opacity-50" />
        <Row>
          <Col xl={8} className="position-relative">
            <Row className="g-3 align-items-center">
              <Col lg={5}>
                <div className="position-relative text-center">
                  <Background image={logo} className="rounded-1" />
                  <ColorExtractor src={logo} getColors={handleColors} />
                  <div
                    onClick={() => video && setShowModal(true)}
                    className="position-relative d-block py-7 py-xl-8 text-center"
                  >
                    {video && <Image src={playicon} alt="" width={60} />}
                  </div>
                  <ModalVideoContent
                    show={showModal}
                    setShow={setShowModal}
                    attachment={{ image: beachPoster, src: beach }}
                  />
                </div>
              </Col>
              <Col lg={7}>
                <h2 className="fw-bold text-white d-flex align-items-center">
                  {title}
                  {badge && (
                    <SoftBadge pill className="ms-2 fs--1 text-capitalize">
                      {badge}
                    </SoftBadge>
                  )}
                </h2>
                {NO_ID_FIELD && (
                  <p className="text-white fw-semi-bold fs--1">
                    <span className="me-1">{rating}</span>
                    <StarRating readonly rating={rating} />
                    <span className="text-400 ms-2">
                      ({`${reviews.length}`.toLocaleString()} reseñas)
                    </span>
                  </p>
                )}
                {description && (
                  <p className="mb-0 fw-medium text-200">
                    {!NO_ID_FIELD || showExcerpt
                      ? description
                      : `${description?.substring(0, 152)}...`}
                    {NO_ID_FIELD && (
                      <Button
                        variant="link"
                        size="sm"
                        className="text-400 p-0"
                        onClick={() => setShowExcerpt(!showExcerpt)}
                      >
                        Ver {showExcerpt ? 'menos' : 'más'}
                      </Button>
                    )}
                  </p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ProductBanner.propTypes = {
  product: PropTypes.object
};

export default ProductBanner;
