import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { collection, limit, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import QuestionaireIntro from './QuestionaireIntro';
import QuestionaireReward from './QuestionaireReward';
import Questionaire from './Questionaire';
import QuestionaireOutcome from './QuestionaireOutcome';
import HappyQuestionaireProvider from './HappyQuestionaireProvider';
import { HappyQuestionairesContext, UserContext } from 'context/Context';
import { toast } from 'react-toastify';

const HappyQuestionaireViewerContent = () => {
  let { finishHappyQuestionaire } = useContext(HappyQuestionairesContext);
  const [readOnly, setReadOnly] = useState(false);
  const { company, me: user } = useContext(UserContext);
  const navigate = useNavigate();
  const db = useFirestore();
  const { apps = [] } = company || {};
  const { lastQuestionnaireAt } = user || {};
  const lastQuestionnaireDate = new Date(lastQuestionnaireAt);
  const isRewardable =
    apps.includes('questionaires') &&
    (!lastQuestionnaireAt ||
      lastQuestionnaireDate.getMonth() !== new Date().getMonth());

  const defaultsRef = query(
    collection(db, 'defaults'),
    where('current', '==', true),
    limit(1)
  );
  const { data: defaults = [] } = useFirestoreCollectionData(defaultsRef);
  const { happy_questionaire: questionaire = {} } = defaults?.[0] || {};
  const { handleSubmit, ...rest } = useForm();

  const onSubmitData = async data => {
    setReadOnly(true);
    const answers = questionaire?.definitions?.map(
      (def, index) => data[`entry-${index}`]
    );

    if (
      answers.some((response, index) => {
        const isUndefined = typeof response === 'undefined';
        if (isUndefined) {
          const url = `/user/questionaires/happyfy/#entry-${index}`;
          navigate(url);
          setReadOnly(false);
        }
        return isUndefined;
      })
    ) {
      return;
    }
    await finishHappyQuestionaire({
      definitions: questionaire?.definitions,
      answers,
      user
    });
    toast.success('Cuestionario enviado');
    setTimeout(() => {
      navigate('/user/questionaires');
    }, 1000);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitData)}>
      <QuestionaireIntro questionaire={questionaire} user={user} />
      {isRewardable && <QuestionaireReward />}
      <Questionaire {...rest} questionaire={questionaire} />
      <QuestionaireOutcome
        questionaire={questionaire}
        readOnly={readOnly}
        isRewardable={isRewardable}
      />
    </Form>
  );
};

const HappyQuestionaireViewer = () => {
  return (
    <HappyQuestionaireProvider>
      <HappyQuestionaireViewerContent />
    </HappyQuestionaireProvider>
  );
};

export default HappyQuestionaireViewer;
