import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChatContext } from 'context/Context';

const ChatSidebarDropdownAction = ({ chat }) => {
  const { deleteChat } = useContext(ChatContext);

  const handleDelete = () => {
    deleteChat(chat);
  };

  return (
    <Dropdown className="dropdown-active-trigger dropdown-chat">
      <Dropdown.Toggle
        id="dropdown-button"
        className="hover-actions text-400 dropdown-caret-none fs-0 z-index-1 "
        variant="link"
        size="sm"
      >
        <FontAwesomeIcon icon="chevron-down" transform="shrink-3 down-4" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="py-2">
        <Dropdown.Item
          className="cursor-pointer text-danger"
          onClick={handleDelete}
        >
          Delete
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

ChatSidebarDropdownAction.propTypes = {
  chat: PropTypes.object.isRequired
};

export default ChatSidebarDropdownAction;
