import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Col, Row } from 'react-bootstrap';
import Conversation from './Conversation';
import PropertiesFilteringForm from './PropertiesFilteringForm';
import TicketsPreviewHeader from './TicketsPreviewHeader';
import ToDoList from 'components/dashboards/support-desk/ToDoList';
import FeedbackProvider from 'components/feedback/FeedbackProvider';
import ContactInfo from './ContactInfo';
import { FeedbackContext, UserContext } from 'context/Context';
import anonymousAvatar from 'assets/img/icons/blank-profile.svg';

const TicketsPreview = () => {
  const { feedbackId } = useParams();
  const { feedback: allFeedbacks = [] } = useContext(FeedbackContext);
  const { me, partners } = useContext(UserContext);
  const feedback = allFeedbacks.find(
    ({ NO_ID_FIELD }) => NO_ID_FIELD === feedbackId
  );
  const {
    agent,
    comments = [],
    createdAt,
    priority = 'medium',
    status: statusProp,
    userId
  } = feedback || {};
  const user = partners.find(({ id } = {}) => id === userId) || {
    avatar: anonymousAvatar,
    name: 'Anónimo'
  };
  const diff = dayjs().diff(new Date(createdAt), 'days');
  const isResponded = comments?.some(({ userId: uid }) => uid !== userId);
  const status =
    statusProp ||
    (isResponded && 'responded') ||
    (diff > 3 ? 'overdue' : 'recent');

  const feedbackData = { ...feedback, priority, status };

  return (
    <Row className="g-3">
      <Col
        xxl={(me?.type !== 'admin' && me?.NO_ID_FIELD !== agent) || 9}
        xl={8}
      >
        <TicketsPreviewHeader feedback={feedbackData} />
        <Conversation feedback={feedbackData} user={user} />
      </Col>
      {(me?.type === 'admin' || me?.NO_ID_FIELD === agent) && (
        <Col xxl={3} xl={4}>
          <Row className="g-3 position-sticky top-0">
            <Col md={6} xl={12}>
              <PropertiesFilteringForm feedback={feedbackData} user={user} />
            </Col>
            {me?.type === 'admin' && (
              <Col md={6} xl={12}>
                <ToDoList previewClassName="preview-todo-list" />
              </Col>
            )}
            <Col xs={12}>
              <ContactInfo feedback={feedbackData} user={user} />
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

const TicketsPreviewWithProvider = props => {
  return (
    <FeedbackProvider>
      <TicketsPreview {...props} />
    </FeedbackProvider>
  );
};

export default TicketsPreviewWithProvider;
