import React from 'react';
import { Card } from 'react-bootstrap';
import ReportsProvider from 'components/company/reports/ReportsProvider';
import Generations from 'components/company/reports/Generations';
import ReportsList from 'components/company/reports/ReportsList';
import Background from 'components/common/Background';
import corner1 from 'assets/img/icons/spot-illustrations/corner-1.png';

const ReportsContent = () => {
  return (
    <>
      <Card className="mb-3">
        <Background
          image={corner1}
          className="d-none d-sm-block bg-card rounded-soft"
        />
        <Card.Header>
          <h5>Informes</h5>
          <h6 className="text-600">
            Aquí puedes generar los informes a demanda. Además podrás descargar
            los informes mensuales.
          </h6>
        </Card.Header>
        <Generations className="bg-transparent shadow-none" />
      </Card>
      <ReportsList />
    </>
  );
};

const Reports = () => {
  return (
    <ReportsProvider>
      <ReportsContent />
    </ReportsProvider>
  );
};

export default Reports;
