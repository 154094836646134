import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Search = ({ className, placeholder = 'Buscar', onChange, onSearch }) => {
  const [search, setSearch] = useState('');

  const handleSubmit = event => {
    event.preventDefault();
    onSearch?.(search);
  };

  return (
    <div className={classNames(className)}>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-0 position-relative d-md-none d-lg-block w-100 h-100">
          <Form.Control
            className="chat-contacts-search shadow-none"
            placeholder={placeholder}
            size="sm"
            value={search}
            onChange={({ target }) => {
              setSearch(target.value);
              onChange?.(target.value);
            }}
          />
          <FontAwesomeIcon icon="search" className="contacts-search-icon" />
        </Form.Group>
        <Button
          variant="transparent"
          size="sm"
          className="d-none d-md-inline-block d-lg-none"
          type="submit"
        >
          <FontAwesomeIcon icon="search" className="fs--1" />
        </Button>
      </Form>
    </div>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func
};

export default Search;
