import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { UserContext } from 'context/Context';
import dayjs from 'dayjs';

const HappyQuestionaireMessage = ({ message }) => {
  const { defaults, me } = useContext(UserContext);
  const { happy_questionaire: happyQuestionaire } = defaults || {};
  const { title } = happyQuestionaire || {};
  const { NO_ID_FIELD, extra } = message || {};
  const { answers, submittedAt } = extra || {};
  const json = { userId: me?.NO_ID_FIELD, messageId: NO_ID_FIELD };
  const params = btoa(JSON.stringify(json));
  const link = `${process.env.REACT_APP_FRONT_URL}/hq/${params}`;
  const text = title?.replace(/{{([^}]+)}}/g, (s, key) => me?.[key]);

  return (
    <>
      <p>{text}</p>
      {(!submittedAt && (
        <div className="d-grid">
          <Button
            variant="outline-primary"
            as={Link}
            to={link}
            size="sm"
            disabled={!!submittedAt}
            target="_blank"
          >
            Contesta al cuestionario de Happyfy
          </Button>
        </div>
      )) || (
        <div>
          {submittedAt === 'never' ? (
            <h6 className="m-0">No has contestado</h6>
          ) : (
            <>
              <h6>
                Contestaste el{' '}
                {dayjs(submittedAt).format('dddd, D MMMM YYYY [a las] HH:mm')}
              </h6>
              <ol className="bg-light m-0 p-3 ps-4 rounded-3">
                {answers?.map(({ answer, question }, index) => (
                  <li key={`Answer-${NO_ID_FIELD}-${index}`}>
                    <div>{question}</div>
                    <h6>{answer || '-'}</h6>
                  </li>
                ))}
              </ol>
            </>
          )}
        </div>
      )}
    </>
  );
};

HappyQuestionaireMessage.propTypes = {
  message: PropTypes.object
};

export default HappyQuestionaireMessage;
