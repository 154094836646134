import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Background from 'components/common/Background';
import { Card, Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UserProvider from 'components/UserProvider';
import AppContext from 'context/Context';
import { ReactComponent as LogoColour } from 'assets/img/illustrations/happyfy.svg';
import { ReactComponent as LogoLight } from 'assets/img/illustrations/happyfy_light.svg';

const SplitLayout = ({ children, bgProps, content }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const Logo = isDark ? LogoLight : LogoColour;

  return (
    <Container fluid>
      <Row className="min-vh-100 bg-100">
        <Col
          xs={6}
          className="d-none d-lg-flex overflow-hidden flex-column align-items-center justify-content-center position-relative"
        >
          {bgProps && <Background {...bgProps} />}
          {content}
        </Col>
        <Col sm={10} md={6} className="px-sm-0 align-self-center mx-auto py-5">
          <Row className="g-0 justify-content-center">
            <Col lg={9} xl={8} className="col-xxl-6">
              <Card>
                <Card.Header className="p-4 pb-0">
                  <Link
                    className="text-white light font-sans-serif fw-bolder fs-4 z-index-1 position-relative"
                    to="/"
                  >
                    <Logo height={40} width={160} />
                  </Link>
                </Card.Header>
                <Card.Body className="p-4">{children}</Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

SplitLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bgProps: PropTypes.shape(Background.propTypes).isRequired,
  content: PropTypes.node
};

const SplitLayoutWithUser = props => {
  return (
    <UserProvider>
      <SplitLayout {...props} />
    </UserProvider>
  );
};

export default SplitLayoutWithUser;
