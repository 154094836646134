import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Calendar = ({ className, month, day, color }) => (
  <div className={classNames('calendar', className)}>
    <span className={classNames('calendar-month', { [`bg-${color}`]: color })}>
      {month}
    </span>
    <span
      className={classNames('calendar-day', {
        [`border-${color}`]: color
      })}
    >
      {day}
    </span>
  </div>
);

Calendar.propTypes = {
  className: PropTypes.string,
  month: PropTypes.string.isRequired,
  day: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  color: PropTypes.string
};

export default Calendar;
