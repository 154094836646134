import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Col, Nav, Row } from 'react-bootstrap';
import ProfileBanner from './ProfileBanner';
import Flex from 'components/common/Flex';
import Tooltip from 'components/common/Tooltip';
import { BadgeCountProfile } from 'components/BadgeCount';
import {
  ChatContext,
  EmployeesContext,
  FilesContext,
  UserContext
} from 'context/Context';
import { getUnique } from 'helpers/utils';
import google from 'assets/img/nav-icons/google.png';
import facebook from 'assets/img/nav-icons/facebook.png';
// import linkedin from 'assets/img/nav-icons/linkedin.png';
import redflagTypes from 'components/dashboards/redflagTypes.json';
import EmployeesProvider from 'components/company/employees/EmployeesProvider';
import FilesProvider from 'components/company/files/FilesProvider';
import requirements from 'components/user/profile/requirements.json';

const ALERTS = {
  happiness: '< 70',
  environment: '< 70',
  fellowship: '< 70',
  motivation: '< 70',
  satisfaction: '< 70',
  stress: '> 70'
};

const REDFLAGS = {
  burnout: ['happiness', 'stress'],
  conflict: ['happiness', 'environment', 'fellowship'],
  absent: ['motivation', 'satisfaction'],
  sick: ['happiness', 'environment', 'stress'],
  performance: ['motivation', 'stress']
};

const SocialLinks = () => {
  const { authUser, linkSocialSignIn, unlinkSocialSignIn } =
    useContext(UserContext);
  const { providerData = [] } = authUser || {};
  const [socialLinks] = useState([
    {
      title: 'Google',
      image: google,
      provider: 'google.com'
    },
    {
      title: 'Facebook',
      image: facebook,
      provider: 'facebook.com'
    }
    // {
    //   title: 'LinkedIn',
    //   image: linkedin,
    //   provider: 'linkedin.com'
    // }
  ]);

  return (
    <Col
      xs={12}
      lg="auto"
      className="position-relative mt-lg-n4 ps-2 ps-lg-3 d-flex flex-column"
    >
      {socialLinks.map(item => {
        const { image, title, provider } = item;
        return (
          <Flex key={`Profile-${title}`} alignItems="center" className="mb-2">
            <Flex alignItems="center" className="min-w-140px">
              <img src={image} alt={title} width={30} className="me-2" />
              <div className="flex-1">
                <h6 className="mb-0">{item.title}</h6>
              </div>
            </Flex>
            {providerData.some(({ providerId }) => providerId === provider) ? (
              <Button
                variant="falcon-danger"
                className="ms-2 min-w-140px"
                size="sm"
                onClick={() => unlinkSocialSignIn(provider)}
              >
                Desvincular
              </Button>
            ) : (
              <Button
                variant="falcon-default"
                className="ms-2 min-w-140px"
                size="sm"
                onClick={() => linkSocialSignIn(provider)}
              >
                Vincular
              </Button>
            )}
          </Flex>
        );
      })}
    </Col>
  );
};

const Redflags = ({ user }) => {
  const { indexes = [] } = useContext(EmployeesContext);
  const indexesByUser = indexes.filter(
    ({ userId }) => userId === user?.NO_ID_FIELD
  );
  const alertsData = getUnique(
    indexesByUser.filter(
      // eslint-disable-next-line no-unused-vars
      ({ type, value }) => eval(`value ${ALERTS[type]}`)
    ),
    'type'
  );
  const redflags = Object.entries(REDFLAGS)
    .filter(([, redflags]) => {
      return !redflags.some(
        redflag => !alertsData.some(({ type }) => type === redflag)
      );
    })
    .map(([key]) => key);

  if (!redflags.length) {
    return null;
  }

  return (
    <Col className="ps-2 ps-lg-3 d-flex align-items-end justify-content-end">
      <Flex
        className="gap-2 flex-1"
        alignItems="end"
        justifyContent="end"
        wrap="wrap"
      >
        {redflags.sort().map(redflag => {
          return (
            <Badge
              key={`Redflag-${user?.NO_ID_FIELD}-${redflag}`}
              bg="danger"
              className="fs-1 text-capitalize"
              size="l"
            >
              <FontAwesomeIcon
                icon={redflagTypes[redflag]?.icon}
                className="me-2"
              />
              {redflagTypes[redflag]?.title}
            </Badge>
          );
        })}
      </Flex>
    </Col>
  );
};

const ProfileMenu = ({ user }) => {
  const { me } = useContext(UserContext);
  const { files } = useContext(FilesContext);
  const { texts } = requirements;
  const {
    avatar,
    birthday,
    description,
    lastQuestionnaireAt: questionaire,
    nif,
    phone,
    profile
  } = user;
  const {
    description: professionalDescription,
    interests,
    skills
  } = profile || {};
  const cv = files?.find(
    ({ type, userId }) => type === 'cv' && userId === user.NO_ID_FIELD
  );
  const items = {
    avatar,
    birthday,
    cv,
    description,
    interests,
    nif,
    phone,
    professionalDescription,
    questionaire,
    skills
  };

  const menu = {
    profile: {
      title: 'Perfil',
      requirements: [
        'birthday',
        'description',
        'nif',
        'phone',
        'professionalDescription',
        'skills'
      ]
    },
    personal: {
      title: 'Personal',
      requirements: ['interests']
    },
    benefits: {
      title: 'Beneficios'
    },
    documents: {
      title: 'Documentos',
      requirements: ['cv']
    },
    schedule: {
      title: 'Horarios'
    },
    events: {
      title: 'Eventos'
    },
    objectives: {
      title: 'Objetivos'
    },
    'time-off': {
      title: 'Ausencias'
    },
    analytics: {
      title: 'Analíticas'
    }
  };

  return (
    <>
      {(me?.type === 'admin' || me?.NO_ID_FIELD === user?.NO_ID_FIELD) && (
        <div className="p-3 border-top border-200">
          <Nav variant="underline">
            {Object.entries(menu).map(([key, { title, requirements }]) => {
              const menuRequirements = requirements?.filter(key =>
                items[key] && Array.isArray(items[key])
                  ? !items[key].length
                  : !items[key]
              );
              return (
                <Nav.Item key={`Menu-Profile-${key}`}>
                  <Nav.Link
                    eventKey={key}
                    to={`/user/profile/${user?.NO_ID_FIELD}/${key}`}
                    as={Link}
                  >
                    {title}
                    {!!menuRequirements?.length && (
                      <Tooltip
                        title={
                          <ul className="list-unstyled m-0 text-start">
                            {menuRequirements?.map((item, index) => (
                              <li
                                key={`Incomplete-${item}`}
                                className={classNames({ 'mt-2': index > 0 })}
                              >
                                <FontAwesomeIcon
                                  className="me-2"
                                  icon="exclamation-triangle"
                                />
                                {texts[item] || ''}
                              </li>
                            ))}
                          </ul>
                        }
                        tooltipClass="max-w-500px ms-1"
                      >
                        <FontAwesomeIcon
                          icon="exclamation-triangle"
                          className="ms-2"
                        />
                      </Tooltip>
                    )}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
        </div>
      )}
    </>
  );
};

const Banner = ({ className, user = {}, edit }) => {
  const { createChat, setIsOpenNewChat } = useContext(ChatContext);
  const { companies, me } = useContext(UserContext);

  user = user?.NO_ID_FIELD ? user : me || {};
  const {
    NO_ID_FIELD: id,
    avatar,
    company: companyId,
    name,
    department,
    ref
  } = user;
  const company =
    companies?.find(({ NO_ID_FIELD }) => NO_ID_FIELD === companyId) || {};
  const { avatar: companyAvatar, name: companyName } = company;

  const handleChat = () => {
    createChat({ participants: [me?.ref, ref], type: 'user' });
    setIsOpenNewChat(false);
  };

  return (
    <ProfileBanner className={className}>
      <ProfileBanner.Header
        id={id}
        avatar={avatar}
        name={name}
        coverSrc={companyAvatar}
        edit={edit}
      />
      <ProfileBanner.Body>
        <Row>
          <Col xs={12} sm={true}>
            <h4 className="mb-1">
              {name} <BadgeCountProfile user={user} show={true} />
            </h4>
            <h5 className="fs-0 fw-normal">{department}</h5>
            <p className="text-500">{companyName}</p>
          </Col>
          {me?.NO_ID_FIELD === user?.NO_ID_FIELD ? (
            <SocialLinks />
          ) : (
            <Col
              xs={12}
              lg="auto"
              className="position-relative ps-2 ps-lg-3 d-flex flex-column"
            >
              {me?.type === 'admin' && (
                <Button
                  variant="falcon-primary"
                  size="sm"
                  className="px-3 mb-2"
                >
                  Enviar notificación
                </Button>
              )}
              <Button
                variant="falcon-default"
                size="sm"
                className="px-3"
                onClick={handleChat}
              >
                Chat
              </Button>
            </Col>
          )}
          {me?.type === 'admin' && (
            <EmployeesProvider>
              <Redflags user={user} />
            </EmployeesProvider>
          )}
        </Row>
      </ProfileBanner.Body>
      <FilesProvider>
        <ProfileMenu user={user} />
      </FilesProvider>
    </ProfileBanner>
  );
};

Redflags.propTypes = {
  user: PropTypes.object
};

ProfileMenu.propTypes = {
  user: PropTypes.object
};

Banner.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  edit: PropTypes.bool
};

export default Banner;
