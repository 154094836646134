import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card } from 'react-bootstrap';
import EducationItem from './EducationItem';
import EducationForm from './EducationForm';
import { UserContext } from 'context/Context';

const Education = ({ className, user }) => {
  const { me } = useContext(UserContext);
  const { profile = {} } = user || {};
  const { education: data = [] } = profile;
  const [show, setShow] = useState(false);

  return (
    <Card className={className}>
      <FalconCardHeader title="Educación" light />
      <Card.Body className="fs--1">
        {data.map((details, index) => (
          <EducationItem
            key={`Education-${index}`}
            details={details}
            index={index}
            user={user}
          />
        ))}
        {me?.NO_ID_FIELD === user?.NO_ID_FIELD && (
          <EducationForm show={show} setShow={setShow} user={user} />
        )}
      </Card.Body>
    </Card>
  );
};

Education.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object
};

export default Education;
