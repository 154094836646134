import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { collection, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { IndexesContext, UserContext } from 'context/Context';

const IndexesProvider = ({ children }) => {
  const { company } = useContext(UserContext);
  const db = useFirestore();
  const month = new Date();
  const defaultFromDate = new Date(
    new Date(month.setMonth(month.getMonth() - 3)).setDate(1)
  )
    .toISOString()
    .split('T')[0];
  const defaultToDate = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split('T')[0];
  const [fromDate] = useState(defaultFromDate);
  const [toDate] = useState(defaultToDate);
  let indexesQuery = query(collection(db, 'none'));
  if (company?.NO_ID_FIELD) {
    indexesQuery = query(
      collection(db, 'indexes'),
      where('companyId', '==', `${company?.NO_ID_FIELD}`),
      where('createdAt', '>=', fromDate),
      where('createdAt', '<=', toDate)
    );
  }
  const { data: indexes = [] } = useFirestoreCollectionData(indexesQuery);

  // console.log('INDEXES >>>', indexes);

  return (
    <IndexesContext.Provider
      value={{
        indexes
      }}
    >
      {children}
    </IndexesContext.Provider>
  );
};

IndexesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default IndexesProvider;
