import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Col, Dropdown, Row, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import TodayTimeControl from './TodayTimeControl';
import EmployeesTimeControlList from './EmployeesTimeControlList';
import Filters from './Filters';
import WeekTimeControl from './WeekTimeControl';
import CustomTimeControl from './CustomTimeControl';
import TimeControlProvider from './TimeControlProvider';
import { TimeControlContext, UserContext } from 'context/Context';

const TimeControlContent = () => {
  const { exportToCsv } = useContext(TimeControlContext);
  const { me } = useContext(UserContext);
  const { key = me?.type === 'admin' ? 'today' : 'week' } = useParams();
  const [activeKey, setActiveKey] = useState(key);
  const navigate = useNavigate();

  const handleSelect = key => {
    setActiveKey(key);
    navigate(`/user/time-control/${key}`);
  };

  const handleExportClick = () => {
    exportToCsv();
  };

  useEffect(() => {
    setActiveKey(key);
  }, [key]);

  return (
    <Row className="g-3">
      <Col xs={{ order: 2 }} lg={{ order: 1 }}>
        <Card className="time-control">
          <FalconCardHeader
            className="bg-light z-index-1016"
            title={
              <Row className="g-3">
                <Col xs={12} sm="auto" className="flex-grow-1">
                  <h5 className="m-0">Control horario</h5>
                </Col>
                {key === 'custom' && (
                  <Col xs={12} sm="auto">
                    <Filters />
                  </Col>
                )}
              </Row>
            }
            titleClass="overflow-visible"
            titleTag="div"
            endEl={
              (key === 'custom' && me?.type === 'admin' && (
                <div>
                  <Dropdown
                    align="end"
                    className="font-sans-serif btn-reveal-trigger"
                  >
                    <Dropdown.Toggle
                      variant="falcon-default"
                      className="ms-3"
                      size="sm"
                    >
                      <FontAwesomeIcon icon="ellipsis-h" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="py-2">
                      <Dropdown.Item onClick={handleExportClick}>
                        Exportar a csv
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )) ||
              null
            }
          />
          <Tabs
            className="sticky-top bg-light"
            activeKey={activeKey}
            onSelect={handleSelect}
          >
            {me?.type === 'admin' && (
              <Tab eventKey="today" title="Fichajes de hoy">
                <EmployeesTimeControlList />
              </Tab>
            )}
            <Tab eventKey="week" title="Mis fichajes de esta semana">
              <WeekTimeControl />
            </Tab>
            <Tab eventKey="custom" title="Fichajes">
              <CustomTimeControl />
            </Tab>
          </Tabs>
        </Card>
      </Col>

      <Col xs={{ offset: 12, order: 1 }} lg="auto">
        <TodayTimeControl />
      </Col>
    </Row>
  );
};

const TimeControl = props => {
  return (
    <TimeControlProvider>
      <TimeControlContent {...props} />
    </TimeControlProvider>
  );
};

export default TimeControl;
