import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import { collection, doc, getDoc } from '@firebase/firestore';
import { getDownloadURL, ref } from '@firebase/storage';
import { useFirestore, useStorage } from 'reactfire';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import confetti from 'assets/img/animated-icons/confetti.json';

const FeedBirthday = ({ userIds = [] }) => {
  const db = useFirestore();
  const storage = useStorage();
  userIds = Array.isArray(userIds) ? userIds : [userIds];
  const { company, partners = [] } = useContext(UserContext);
  const [users, setUsers] = useState([]);

  const names =
    new Intl.ListFormat('es', {
      style: 'long',
      type: 'conjunction'
    }).format(users.map(({ firstname }) => firstname)) || '-';

  const getUsers = async () => {
    const users = (
      await Promise.all(
        userIds.map(async userId => {
          let user = partners.find(({ NO_ID_FIELD }) => NO_ID_FIELD === userId);
          if (!user) {
            try {
              const userDoc = await getDoc(
                doc(collection(db, 'users'), userId)
              );
              let avatar;
              try {
                avatar = await getDownloadURL(
                  ref(storage, `/users/${userId}/avatar.jpeg`)
                );
              } catch (error) {
                console.error(error);
              }
              user = !userDoc
                ? {}
                : {
                    NO_ID_FIELD: userDoc.id,
                    id: userDoc.id,
                    ref: userDoc.ref,
                    ...(await userDoc.data()),
                    avatar,
                    disabled: true
                  };
            } catch (error) {
              console.error(error);
            }
          }
          return user;
        })
      )
    ).filter(user => user);
    setUsers(users);
  };

  useEffect(() => {
    partners?.length > 1 && getUsers();
  }, [company?.NO_ID_FIELD, userIds, partners?.length]);

  return (
    <>
      <Flex direction="column" alignItems="center" justifyContent="center">
        <Lottie
          animationData={confetti}
          className="position-absolute top-0 end-0 bottom-0 start-0 pointer-none z-index-2"
          loop={false}
        />
        <Flex
          alignItems="end"
          justifyContent={classNames({
            center: users?.length > 1,
            end: users?.length === 1
          })}
          className="position-relative"
        >
          <h1
            className={classNames('fs-8 position-absolute z-index-1', {
              'me-n3 mb-n3': users?.length === 1,
              'mb-n4': users?.length > 1
            })}
          >
            🎂
          </h1>
          <AvatarGroup max={10}>
            {users.map(({ NO_ID_FIELD, avatar, disabled, name }) => (
              <Avatar
                className={classNames({ disabled })}
                key={`Birthday-${NO_ID_FIELD}`}
                src={avatar}
                name={name || '-'}
                size="5xl"
              />
            ))}
          </AvatarGroup>
        </Flex>
        <h5 className="mt-4 text-center">¡Feliz cumpleaños {names}!</h5>
      </Flex>
    </>
  );
};

FeedBirthday.propTypes = {
  userIds: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default FeedBirthday;
