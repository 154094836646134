import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import ReportsProvider from 'components/ReportsProvider';
import { Outlet } from 'react-router-dom';
import { ReportsContext } from 'context/Context';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { Helmet } from 'react-helmet';

const reportNames = {
  happiness: 'felicidad',
  feedback: 'sugerencias',
  events: 'eventos',
  objectives: 'objetivos'
};

const ReportsLayout = () => {
  let { department, start: startProp, report } = useParams();
  const reportName = reportNames[report];
  const {
    company: { avatar: companyAvatar, name: companyName } = {},
    date,
    start,
    end
  } = useContext(ReportsContext);
  const startDate = new Date(start || date);
  let endDate = new Date(end);
  endDate.setDate(endDate.getDate() - 1);
  let title = `Informe de ${reportName ? `${reportName} de` : ''} ${dayjs(
    startDate
  ).format('MMMM')}`;
  let titleDate = `${dayjs(startDate).format('DD.MM.YYYY')} - ${dayjs(
    endDate
  ).format('DD.MM.YYYY')}`;
  let departmentStr = department ? ` - ${department}` : '';
  let titleWithDate = title;
  if (startProp) {
    title = `Informe ${reportName ? `de ${reportName}` : ''}:`;
    titleWithDate = `${title} ${titleDate}`;
  }

  return (
    <>
      <Helmet>
        <title>{`${companyName}${departmentStr} - ${titleWithDate}`}</title>
      </Helmet>
      <div className="container-fluid mb-3">
        <Flex direction="column" className="min-vh-100">
          <Flex
            className="flex-grow-1"
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Logo at="navbar-top" width={200} />
            <h2 className="mt-3 mb-8 text-center">
              {title}
              {startProp && (
                <>
                  <br />
                  {titleDate}
                </>
              )}
            </h2>
            <Avatar
              rounded="3"
              className="mb-2"
              src={companyAvatar}
              size="5xl"
            />
            <h5 className="text-center">
              {companyName}
              {department && (
                <>
                  <br />
                  <span className="fs-3 text-black">{department}</span>
                </>
              )}
            </h5>
          </Flex>
        </Flex>
        <Outlet />
      </div>
    </>
  );
};

const ReportsLayoutWithContext = () => {
  return (
    <ReportsProvider>
      <style>{`
      body { background: white !important; }

      @media print {
        @page {
          size: A4;
        }

        section, tr, td, .card.border, .recent-activity-timeline {
          break-inside: avoid;
        }

        .section-title {
          break-before: always;
        }
      }
      `}</style>
      <ReportsLayout />
    </ReportsProvider>
  );
};

ReportsLayout.propTypes = {
  date: PropTypes.object
};

export default ReportsLayoutWithContext;
