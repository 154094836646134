import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Image, Row } from 'react-bootstrap';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import { FeedContext } from 'context/Context';

const FeedGallery = ({ images }) => {
  const { scrollToFeed } = useContext(FeedContext);
  return (
    <FalconLightBoxGallery className="mt-2" images={images}>
      {setImgIndex => (
        <Row className="g-2">
          {images.map((image, index) => (
            <Col key={`Image-${image}`} xs={4} className="position-relative">
              <Image
                src={image}
                className="h-100 w-100 max-w-640px fit-cover"
                rounded
                onClick={() => setImgIndex(index)}
                onLoad={scrollToFeed}
              />
            </Col>
          ))}
        </Row>
      )}
    </FalconLightBoxGallery>
  );
};

FeedGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string)
};

export default FeedGallery;
