import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import Flex from 'components/common/Flex';
import { UserFeedbackContent } from 'components/feedback/UserFeedback';
import FeedbackProvider from 'components/feedback/FeedbackProvider';

const NewFeedback = ({ className }) => {
  return (
    <FeedbackProvider limit={0}>
      <Card className={className}>
        <Flex direction="column" className="h-100 overflow-hidden rounded-4">
          <Card.Header>
            <h6 className="m-0">Comentar o sugerir a la empresa</h6>
          </Card.Header>
          <Card.Body className="pt-0 h-0 flex-grow-1">
            <SimpleBarReact className="h-100">
              <UserFeedbackContent
                buttonClass="px-4 px-sm-5"
                buttonSize="sm"
                className="h-100 d-flex flex-column"
                rows={7}
              />
            </SimpleBarReact>
          </Card.Body>
        </Flex>
      </Card>
    </FeedbackProvider>
  );
};

NewFeedback.propTypes = {
  className: PropTypes.string
};

export default NewFeedback;
