import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { doc, deleteField } from '@firebase/firestore';
import { useFirestore } from 'reactfire';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import UserList from 'components/common/UserList';
import { ChatContext, UserContext } from 'context/Context';

const OptionList = ({ option, index, maxVotes }) => {
  const { partners = [] } = useContext(UserContext);
  const { text, votes } = option;
  return (
    <div className={classNames({ 'mt-3 mb-3': index === 0 })}>
      <Flex
        alignItems="center"
        className={classNames('py-3', { 'border-bottom': !!votes.length })}
      >
        <div
          style={{
            flexGrow: 1,
            fontSize: 16,
            fontWeight: 'bold'
          }}
        >
          {text}
        </div>
        <div className="fs-0 fw-bold">
          {votes.length} {votes.length === 1 ? 'voto' : 'votos'}
        </div>
        {votes.length === maxVotes && (
          <FontAwesomeIcon className="ms-2" icon="star" size={24} />
        )}
      </Flex>
      {!!votes.length && (
        <div style={{ paddingVertical: 8 }}>
          <UserList
            className="shadow-none"
            users={votes.map(({ ref }) => {
              const user =
                partners.find(partner => partner?.ref?.path === ref?.path) ||
                {};
              return user;
            })}
            tableProps={{
              pagination: true,
              perPage: 10
            }}
          />
        </div>
      )}
    </div>
  );
};

const VotesList = ({ options, title }) => {
  const maxVotes = options.reduce((max, { votes = [] }) => {
    return Math.max(max, votes.length);
  }, 0);

  return (
    <>
      <div>
        <div>
          <div className="flex-grow-1 fs-1 fw-bold">{title}</div>
        </div>
        {options
          .sort((o1, o2) => (o1?.votes?.length > o2?.votes?.length ? -1 : 1))
          .map((option, index) => (
            <OptionList
              key={`Modal-Option-${index}`}
              option={option}
              index={index}
              maxVotes={maxVotes}
            />
          ))}
      </div>
    </>
  );
};

const QuestionaireMessage = ({ message }) => {
  const { me, partners = [] } = useContext(UserContext);
  const { updateChat, updateMessage, showModal, setModalChildren } =
    useContext(ChatContext);
  const { NO_ID_FIELD, chat, chatId, extra } = message || {};
  const { title, options = [] } = extra || {};
  const db = useFirestore();

  const handleVote = async optionProp => {
    const newOptions = options.map(option => {
      let { votes = [] } = option;
      if (optionProp.text === option.text) {
        if (votes.map(vote => vote?.ref?.path).includes(me?.ref?.path)) {
          votes = votes.filter(vote => vote?.ref?.path !== me?.ref?.path);
        } else {
          const createdAt = new Date(Date.now()).toISOString();
          votes = [...votes, { ref: me?.ref, createdAt }];
        }
      }
      return {
        ...option,
        votes
      };
    });

    await updateMessage(NO_ID_FIELD, {
      extra: { ...extra, options: newOptions }
    });
    const ref = doc(db, 'chat_messages', NO_ID_FIELD);
    await updateChat(chatId, {
      lastMessage: { ref, text: `${title}: ${me?.name} ha votado` },
      updatedAt: new Date(Date.now()).toISOString()
    });
    if (chat?.type === 'user' && chat?.deletedTo) {
      await updateChat(chatId, {
        deletedTo: deleteField()
      });
    }
  };

  const maxVotes = options.reduce((max, { votes = [] }) => {
    return Math.max(max, votes.length);
  }, 0);

  const handleShowVotes = () => {
    setModalChildren(<VotesList options={[...options]} title={title} />);
    showModal(true);
  };

  return (
    <>
      <h5 className="mb-3">{title}</h5>
      {options.map((option, index) => {
        const { text, votes = [] } = option;
        const voted = votes
          .map(vote => vote?.ref?.path)
          .includes(me?.ref?.path);
        return (
          <div key={`option-${NO_ID_FIELD}-${index}`}>
            <Flex alignItems="start" justifyContent="">
              <Button
                variant="white-outline"
                className={classNames('border border-2 me-2 p-1 rounded-4', {
                  'bg-primary border-primary': voted,
                  'border-400': !voted
                })}
                onClick={() => handleVote(option)}
                style={{ width: '23px', height: '23px' }}
              >
                <Flex alignItems="center" justifyContent="center">
                  <FontAwesomeIcon
                    icon="check"
                    className={classNames('fs--2', {
                      'text-white': voted,
                      'text-200': !voted
                    })}
                  />
                </Flex>
              </Button>
              <div className="flex-grow-1">
                <Flex justifyContent="space-between">
                  <strong
                    className="w-0 fs--1 flex-grow-1"
                    style={{ minWidth: 100, marginTop: '2px' }}
                  >
                    {text}
                  </strong>
                  <div
                    className="me-1"
                    style={{
                      alignItems: 'flex-end'
                    }}
                  >
                    <AvatarGroup className="justify-content-end">
                      {votes.map(({ ref }) => {
                        const user =
                          partners.find(
                            partner => partner?.ref?.path === ref?.path
                          ) || {};
                        const { id, avatar, name } = user;
                        return (
                          <Avatar
                            key={`Questionaire-Avatar-${id}`}
                            src={avatar && avatar}
                            name={name && name}
                            size="s"
                          />
                        );
                      })}
                    </AvatarGroup>
                  </div>
                  <span className="fw-bold">{votes.length}</span>
                </Flex>
                <div className="w-100 rounded-3 mt-1 mb-3 bg-250 overflow-hidden">
                  <div
                    className="h-100 rounded-3 bg-primary py-1"
                    style={{
                      width: `${(votes.length * 100) / maxVotes}%`
                    }}
                  />
                </div>
              </div>
            </Flex>
          </div>
        );
      })}
      <div className="d-grid">
        <Button
          className="rounded-pill fs--1 fw-normal"
          size="sm"
          variant="outline-primary"
          onClick={() => handleShowVotes(true)}
        >
          Ver detalles
        </Button>
      </div>
    </>
  );
};

OptionList.propTypes = {
  option: PropTypes.object,
  index: PropTypes.number,
  maxVotes: PropTypes.number
};

VotesList.propTypes = {
  options: PropTypes.array,
  title: PropTypes.string
};

QuestionaireMessage.propTypes = {
  message: PropTypes.object
};

export default QuestionaireMessage;
