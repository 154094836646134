import React, { useContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { EmployeesContext } from 'context/Context';
import { employeesReducer } from 'reducers/employeesReducer';
import { UserContext } from 'context/Context.js';
import { sendSignInLinkToEmail, sendEmailVerification } from 'firebase/auth';
import { collection, orderBy, query, where } from 'firebase/firestore';
import { doc, updateDoc } from '@firebase/firestore';
import { ref, uploadBytes } from '@firebase/storage';
import {
  useAuth,
  useFirestore,
  useFirestoreCollectionData,
  useStorage
} from 'reactfire';
import { toast } from 'react-toastify';

const EmployeesProvider = ({ avoidQuery, children }) => {
  const { company, departments = [], partners = [] } = useContext(UserContext);
  const [employeesState, employeesDispatch] = useReducer(employeesReducer, {
    employees: partners,
    allEmployees: partners,
    filters: ['all', 'star', 'archive'],
    currentFilter: 'all'
  });
  const auth = useAuth();
  const db = useFirestore();
  const storage = useStorage();

  const defaultFromDate = new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  )
    .toISOString()
    .split('T')[0];
  const defaultToDate = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split('T')[0];
  const [fromDate] = useState(defaultFromDate);
  const [toDate] = useState(defaultToDate);

  let indexesQuery = query(collection(db, 'none'));
  if (!avoidQuery && company?.NO_ID_FIELD) {
    indexesQuery = query(
      collection(db, 'indexes'),
      where('companyId', '==', `${company?.NO_ID_FIELD}`),
      where('createdAt', '>=', fromDate),
      where('createdAt', '<=', toDate),
      orderBy('createdAt', 'desc')
    );
  }
  let { data: indexes = [] } = useFirestoreCollectionData(indexesQuery);

  const handleCreation = async ({ id, avatar, ...params }) => {
    try {
      const { email } = params;
      if (avatar) {
        try {
          const avatarRef = ref(storage, `users/${id}/avatar.jpeg`);
          await uploadBytes(avatarRef, avatar);
        } catch (error) {
          console.error(error);
        }
      }
      sendSignInLink(email);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteEmployee = async id => {
    try {
      await update(id, {
        company: null,
        company_name: null,
        department: null,
        happiness: null,
        stress: null
      });
    } catch (error) {
      console.error(error);
    }
  };

  const update = async (NO_ID_FIELD, params) => {
    try {
      const ref = doc(db, 'users', NO_ID_FIELD);
      await updateDoc(ref, params);
    } catch (error) {
      console.error(error);
    }
  };

  const sendHappyQuestionaireLink = async userId => {
    try {
      const usersId = Array.isArray(userId) ? userId : [userId];
      await Promise.all(
        usersId.map(async userId => {
          const response = await fetch(
            `${process.env.REACT_APP_FIREBASE_URL}/sendHappyQuestionaire?userId=${userId}`
          );
          if (response.status !== 200) {
            throw Error();
          }
        })
      );

      toast.success(`Cuestionario de Happyfy enviado`);
    } catch (error) {
      toast.error(`Ha habido un error al enviar el cuestionario`);
    }
  };

  const sendSignInLink = async email => {
    try {
      const emails = Array.isArray(email) ? email : [email];
      const actionCodeSettings = {
        url: `${process.env.REACT_APP_FRONT_URL}/authentication/welcome`,
        handleCodeInApp: true
      };
      await Promise.all(
        emails.map(async email => {
          sendSignInLinkToEmail(auth, email, actionCodeSettings);
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const sendVerification = async ({ id }) => {
    try {
      const user = partners?.find(partner => partner.id === id);
      sendEmailVerification(user);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      departments.length &&
      departments.some(
        department => !(employeesState.departments || []).includes(department)
      )
    ) {
      employeesDispatch({
        type: 'UPDATE_DEPARTMENTS',
        payload: departments.map(({ name }) => name)
      });
    }
  }, [departments]);

  useEffect(() => {
    employeesDispatch({ type: 'UPDATE', payload: partners });
  }, [partners]);

  return (
    <EmployeesContext.Provider
      value={{
        employeesState,
        employeesDispatch,
        handleCreation,
        indexes,
        sendHappyQuestionaireLink,
        sendSignInLink,
        sendVerification,
        update,
        deleteEmployee
      }}
    >
      {children}
    </EmployeesContext.Provider>
  );
};

EmployeesProvider.propTypes = {
  avoidQuery: PropTypes.bool,
  children: PropTypes.node
};

export default EmployeesProvider;
