import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  Row,
  Spinner
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import MarkdownEditor from 'components/common/MarkdownEditor';
import { UserContext } from 'context/Context';

import Locations from 'components/company/profile/Locations';
import Schedules from 'components/company/profile/Schedules';
import VacationLock from 'components/company/profile/VacationLock';

const ProfileSettings = ({ bodyClassName, className, headerClassName }) => {
  const [isLoading, setLoading] = useState(false);
  const [isUpdated, setUpdated] = useState(false);
  const {
    company,
    departments = [],
    createDepartment,
    deleteDepartment,
    updateCompany
  } = useContext(UserContext);
  const [newDepartmentName, setNewDepartmentName] = useState('');
  const [formData, setFormData] = useState({});
  const data = { ...company, ...formData };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleChangeNewDepartment = ({ target }) => {
    const { value } = target;
    setNewDepartmentName(value);
  };

  const handleCreateNewDepartment = () => {
    createDepartment(newDepartmentName);
    setNewDepartmentName('');
  };
  const handleDeleteDepartment = departmentId => {
    deleteDepartment(departmentId);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      const { email: prevEmail } = company;
      await updateCompany(company, formData);
      const {
        billingAddress: address,
        billingCif: cif,
        description,
        email,
        name,
        phone
      } = formData;
      const data = {};
      address && (data.address = address);
      cif && (data.cif = cif);
      description && (data.description = description);
      email && (data.email = email);
      name && (data.name = name);
      phone && (data.phone = phone);
      if (Object.keys(data).length) {
        const response = await fetch(
          `${process.env.REACT_APP_FIREBASE_URL}/updateStripeCustomer`,
          {
            method: 'POST',
            body: JSON.stringify({
              customerEmail: prevEmail,
              ...data
            })
          }
        );
        if (response.status !== 200) {
          throw Error();
        }
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    setUpdated(true);
  };

  useEffect(() => {
    isUpdated && setTimeout(() => setUpdated(false), 3000);
  }, [isUpdated]);

  return (
    <>
      <Card className={className}>
        <FalconCardHeader title="Información" className={headerClassName} />
        <Card.Body className={classNames('bg-light', bodyClassName)}>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="name">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Happyfy"
                  value={data?.name}
                  name="name"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="email@happyfy.com"
                  value={data?.email}
                  name="email"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="phone">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="000000000"
                  value={data?.phone}
                  name="phone"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="billingName">
                <Form.Label>Nombre fiscal</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Happyfy S.L."
                  value={data?.billingName}
                  name="billingName"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="billingCif">
                <Form.Label>CIF</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="00000000A"
                  value={data?.billingCif}
                  name="billingCif"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="billingAddress">
              <Form.Label>Dirección fiscal</Form.Label>
              <Form.Control
                type="text"
                placeholder="C/ Nombre de la calle, 0"
                value={data?.billingAddress}
                name="billingAddress"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="intro">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                rows={13}
                placeholder="Escribe aquí una breve descripción de la empresa"
                value={data?.description}
                name="description"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="billingAddress">
              <Form.Label>Días de vacaciones por defecto</Form.Label>
              <Form.Control
                type="number"
                placeholder="22"
                value={data?.vacationDays}
                name="vacationDays"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="billingAddress">
              <Form.Label>Política de vacaciones</Form.Label>
              <br />
              <MarkdownEditor
                key={`MarkdownEditor`}
                value={data?.vacationPolitics}
                onChange={value =>
                  setFormData({
                    ...formData,
                    vacationPolitics: value
                  })
                }
              />
            </Form.Group>

            <div className="text-end">
              <Button
                variant={classNames({
                  primary: !isUpdated,
                  success: isUpdated
                })}
                type="submit"
                disabled={!Object.keys(formData).length}
              >
                {(isUpdated && (
                  <FontAwesomeIcon icon="check" className="me-2" />
                )) ||
                  (isLoading && (
                    <Spinner
                      size="sm"
                      className="me-2"
                      style={{ width: '1rem', height: '1rem' }}
                    />
                  ))}
                {isUpdated ? 'Guardado' : 'Guardar'}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <Card className={classNames('mt-3', className)}>
        <FalconCardHeader title="Departamentos" className={headerClassName} />
        <Card.Body className={classNames('bg-light', bodyClassName)}>
          <Form.Group className="mb-3" controlId="heading">
            <ListGroup>
              {departments.map(({ NO_ID_FIELD, name }) => (
                <ListGroup.Item key={`Department-Item-${NO_ID_FIELD}`}>
                  <Flex justifyContent="between" alignItems="center">
                    {name}
                    <Button
                      variant="outline-danger"
                      className="border-0"
                      size="sm"
                      onClick={() => handleDeleteDepartment(NO_ID_FIELD)}
                    >
                      <FontAwesomeIcon icon="trash" />
                    </Button>
                  </Flex>
                </ListGroup.Item>
              ))}
              <ListGroup.Item>
                <Flex justifyContent="between" alignItems="center">
                  <Form.Control
                    type="text"
                    placeholder="Nuevo departamento"
                    value={newDepartmentName}
                    onChange={handleChangeNewDepartment}
                  />
                  <Button
                    className="ms-3"
                    size="sm"
                    onClick={handleCreateNewDepartment}
                  >
                    <FontAwesomeIcon icon="plus" />
                  </Button>
                </Flex>
              </ListGroup.Item>
            </ListGroup>
          </Form.Group>
        </Card.Body>
      </Card>

      <Schedules className="mt-3" />

      <Locations className="mt-3" />

      <VacationLock className="mt-3" />
    </>
  );
};

ProfileSettings.propTypes = {
  bodyClassName: PropTypes.string,
  className: PropTypes.string,
  headerClassName: PropTypes.string
};

export default ProfileSettings;
