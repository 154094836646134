import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import HappyfyResponses from './HappyfyResponses';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Tooltip from 'components/common/Tooltip';
import QuestionaireProvider from 'components/company/questionaires/QuestionaireProvider';
import { QuestionairesContext, UserContext } from 'context/Context';
import { getUnique } from 'helpers/utils';
import { collection, limit, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import logo from 'assets/img/logo.png';
import IconButton from 'components/common/IconButton';

dayjs.extend(relativeTime);
dayjs.locale('es');

const ItemButton = ({ tooltip, icon, className, onClick }) => {
  return (
    <Tooltip title={tooltip}>
      <div className="d-inline-block">
        <IconButton
          variant="falcon-default"
          size="sm"
          icon={icon}
          className={className}
          onClick={onClick}
        />
      </div>
    </Tooltip>
  );
};

ItemButton.propTypes = {
  tooltip: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func
};

const QuestionaryHappyfyContent = () => {
  const { happyResponses = [], month } = useContext(QuestionairesContext);
  const { partners = [] } = useContext(UserContext);
  const navigate = useNavigate();
  const db = useFirestore();
  const defaultsRef = query(
    collection(db, 'defaults'),
    where('current', '==', true),
    limit(1)
  );
  const { data: defaults = [] } = useFirestoreCollectionData(defaultsRef);
  const { happy_questionaire: happyQuestionaire = {} } = defaults?.[0] || {};
  const { subtitle } = happyQuestionaire;
  const time = dayjs(month).format('MMMM');
  const uniqueResponses = getUnique(happyResponses, 'userId');

  return (
    <>
      <Card>
        <Card.Header>
          <Row>
            <Col md as={Flex}>
              <ItemButton
                className="mt-1 me-3"
                tooltip="Volver a las encuestas"
                icon="arrow-left"
                onClick={() => {
                  navigate('/company/questionaires');
                }}
              />
              <Avatar src={logo} size="2xl" />
              <div className="flex-1 ms-2">
                <Flex>
                  <h5 className="mb-0">Cuestionario Happyfy de {time}</h5>{' '}
                  <SoftBadge bg="success" className="ms-2">
                    {uniqueResponses?.length || 0}/{partners.length}
                  </SoftBadge>
                </Flex>
                <span className="text-500">{subtitle}</span>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0 bg-light">
          <HappyfyResponses questionaire={happyQuestionaire} />
        </Card.Body>
      </Card>
    </>
  );
};

const QuestionaryHappyfyDetail = () => {
  return (
    <QuestionaireProvider>
      <QuestionaryHappyfyContent />
    </QuestionaireProvider>
  );
};

export default QuestionaryHappyfyDetail;
