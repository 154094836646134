import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { Badge, Button, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { GaugeChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { Doughnut } from 'react-chartjs-2';
import { CanvasRenderer } from 'echarts/renderers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TimeControlProvider from 'components/user/time-control/TimeControlProvider';
import Avatar from 'components/common/Avatar';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import Loader from 'components/common/Loader';
import {
  CalendarContext,
  TimeControlContext,
  UserContext
} from 'context/Context';
import {
  getColor,
  getCurrentPosition,
  getTodaySeconds,
  getUserSchedule,
  rgbaColor
} from 'helpers/utils';
import dayjs from 'dayjs';
import timeOffSubtypes from 'components/user/time-off/subtypes.json';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  GaugeChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = ({
  color = 'success',
  labels = ['', ''],
  data = [0, 100],
  isReached,
  isWorking,
  records
}) => ({
  options: {
    // rotation: -90,
    // circumference: '180',
    cutout: !records?.length ? '100%' : isWorking ? '90%' : '95%',
    hover: { mode: null },
    plugins: {
      legend: {
        display: false
      },
      tooltip: null
    }
  },
  data: {
    labels,
    datasets: [
      {
        data,
        backgroundColor: [
          getColor(isReached ? 'danger' : isWorking ? color : 'gray-600'),
          rgbaColor(getColor('gray-300'), 0.2)
        ],
        borderWidth: 0
      }
    ]
  }
});

const TimeIn = ({ placeholder, value }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="between"
      className={classNames({ 'opacity-25': !value })}
    >
      <FontAwesomeIcon icon="arrow-right-to-bracket" className="me-2" />
      <span>{value ? dayjs(value).format('H:mm') : placeholder}</span>
    </Flex>
  );
};

const TimeOut = ({ placeholder, value }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="between"
      className={classNames({ 'opacity-25': !value })}
    >
      <FontAwesomeIcon icon="arrow-right-from-bracket" className="me-2" />
      <span>{value ? dayjs(value).format('H:mm') : placeholder}</span>
    </Flex>
  );
};

TimeIn.propTypes = TimeOut.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string
};

const defaultMaxDuration = 8 * 3600;
const ElapsedTime = ({ isLocating, records, todayDuration }) => {
  const { me } = useContext(UserContext);
  const { avatar } = me || {};
  const [now, setNow] = useState(new Date());
  const lastRecord = [...(records || [])].pop();
  const isWorking = lastRecord?.type === 'in';
  const lastRecordAt = isWorking ? now : new Date(lastRecord?.createdAt);
  const { diff: todayTime } =
    records?.reduce(
      ({ diff, prev }, { createdAt, type }) => {
        const time =
          type === 'in' ? dayjs(prev).diff(createdAt, 'seconds') || 0 : 0;
        return {
          diff: diff + time,
          prev: new Date(createdAt)
        };
      },
      { diff: 0, prev: lastRecordAt }
    ) || {};
  const maxTime = todayDuration || defaultMaxDuration;
  const isReached = todayTime > maxTime;
  let remainingTime = maxTime;
  let totalSeconds = todayTime || 0;
  let hours = Math.min(
    Math.floor(totalSeconds / 3600) || 0,
    Math.floor(remainingTime / 3600)
  );
  totalSeconds = totalSeconds - hours * 3600;
  remainingTime = remainingTime - hours * 3600;
  let minutes = isReached
    ? Math.floor(remainingTime / 60)
    : Math.floor(totalSeconds / 60) || 0;
  totalSeconds = totalSeconds - minutes * 60;
  if (totalSeconds > 30) {
    minutes += 1;
    if (minutes >= 60) {
      minutes = 0;
      hours += 1;
    }
  }

  let extraHours = 0;
  let extraMinutes = 0;
  if (isReached) {
    extraHours = Math.floor(totalSeconds / 3600) || 0;
    totalSeconds = totalSeconds - extraHours * 3600;
    extraMinutes = Math.floor(totalSeconds / 60) || 0;
  }

  const data = [Math.min(todayTime, maxTime), Math.max(maxTime - todayTime, 0)];

  useEffect(() => {
    let interval = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Flex
      alignItems="start"
      justifyContent="center"
      className="position-relative d-block transition-base rounded-circle"
    >
      <Flex alignItems="center" justifyContent="center">
        <Doughnut
          className={classNames('position-absolute z-index-2', {
            'visually-hidden': !records?.length
          })}
          {...getOptions({ data, isWorking, records, isReached })}
        />
        <Avatar
          className={classNames(
            'w-80px h-80px position-relative d-block transition-base',
            {
              'opacity-75': !isWorking && records?.length,
              'opacity-50': isWorking
            }
          )}
          mediaClass="w-100 h-100"
          src={avatar}
        />
        <Flex
          direction="column"
          alignItems="center"
          className={classNames(
            'position-absolute text-center text-dark transition-base',
            {
              'opacity-0': !isWorking && !records?.length
            }
          )}
        >
          <span className="fw-bold text-white">
            {hours}h {minutes}m
          </span>
          {isReached && (
            <div className="fs--1 mb-n2">
              <Badge bg="danger" pill>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  className="transition-base position-relative"
                >
                  <span className="me-1">+</span>
                  <span className="me-2">{extraHours}h</span>
                  <span>{extraMinutes}m</span>
                </Flex>
              </Badge>
            </div>
          )}
        </Flex>

        {isLocating && (
          <Flex
            alignItems="center"
            justifyContent="center"
            className="w-80px h-80px rounded-circle position-absolute pointer-none z-index-2 overflow-hidden"
          >
            <div className="w-80px h-80px bg-opacity-50 bg-white fa-beat-fade position-absolute" />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

ElapsedTime.propTypes = {
  isLocating: PropTypes.bool,
  records: PropTypes.array,
  todayDuration: PropTypes.number
};

const TodayTimeControl = ({ className }) => {
  const { timeOff } = useContext(CalendarContext);
  const { createEntry, isReady, myTodayRecords } =
    useContext(TimeControlContext);
  const { company, me, schedules } = useContext(UserContext);
  const [now] = useState(new Date());
  const today = dayjs(now).format('YYYY-MM-DD');
  const { avatar: companyLogo } = company || {};
  const { location } = me || {};
  const { holidays = [] } = location || {};
  const { firstname = '' } = me || {};
  const lastAccess = [...(myTodayRecords || [])].pop();
  const holiday = holidays.find(
    ({ start }) =>
      dayjs(start).format('YYYY-MM-DD') === dayjs(now).format('YYYY-MM-DD')
  );
  const isWorking = lastAccess?.type === 'in';
  const schedule = getUserSchedule(schedules, me);
  const todayDuration = getTodaySeconds(schedule);
  const [isLocating, setIsLocating] = useState(false);
  const myTimeOff =
    timeOff?.filter(
      ({ approved, participants }) =>
        approved &&
        (!participants || participants.some(ref => ref?.path === me?.ref?.path))
    ) || [];
  const todayTimeOff = myTimeOff.find(
    ({ start, end }) =>
      dayjs(start).format('YYYY-MM-DD') <= today &&
      dayjs(end).format('YYYY-MM-DD') >= today
  );
  const subtypeData =
    timeOffSubtypes.find(({ value }) => value === todayTimeOff?.subtype) || {};

  let totalSeconds = todayDuration || 0;
  let totalHours = Math.floor(totalSeconds / 3600) || 0;
  totalSeconds = totalSeconds - totalHours * 3600;
  let totalMinutes = Math.floor(totalSeconds / 60) || 0;
  totalSeconds = totalSeconds - totalMinutes * 60;
  if (totalSeconds > 30) {
    totalMinutes += 1;
    if (totalMinutes >= 60) {
      totalMinutes = 0;
      totalHours += 1;
    }
  }
  const todayTitle =
    holiday?.title ||
    (!subtypeData?.working && subtypeData?.label) ||
    `${totalHours}h ${totalMinutes}m${
      subtypeData?.working ? ` (${subtypeData?.label})` : ''
    }`;

  const handleClick = async () => {
    const [lastAccessCreatedAt] =
      lastAccess?.createdAt.match(/\d{2}:\d{2}:/) || [];
    const [now] = new Date().toISOString().match(/\d{2}:\d{2}:/) || [];
    const sameTime = lastAccessCreatedAt === now;
    if (sameTime) {
      toast.error(
        classNames({
          'Acabas de entrar': isWorking,
          'Acabas de salir': !isWorking
        })
      );
      return;
    }
    setIsLocating(true);
    let location = null;
    try {
      location = await getCurrentPosition();
    } catch (error) {
      console.error(error);
      alert(
        'Para utilizar al máximo las funcionalidades de Happyfy debes habilitar la ubicación del navegador'
      );
    }
    createEntry({ location, type: isWorking ? 'out' : 'in' });
    setIsLocating(false);
  };

  return (
    <Card
      className={classNames(
        'bg-line-chart-gradient overflow-hidden',
        className
      )}
    >
      {companyLogo && (
        <Background
          image={companyLogo}
          style={{ opacity: 0.1, width: '200%', marginLeft: '-60%' }}
        />
      )}
      <Card.Body
        as={Flex}
        justifyContent="between"
        className="position-relative z-index-1 pb-2"
      >
        <div className="p-0 me-3">
          <h6 className="text-white">
            <Trans i18nKey="home.today.hello">
              Hola {firstname},<br />
              tu jornada de hoy
            </Trans>
          </h6>
          <p className="fs--1 fw-normal mt-2 mb-1 text-white">
            {dayjs(now).format('dddd, D MMMM YYYY')}
          </p>
          <h6 className="text-white">{todayTitle}</h6>
        </div>

        <ElapsedTime
          isLocating={isLocating}
          records={myTodayRecords}
          todayDuration={todayDuration}
        />
      </Card.Body>
      <Card.Body className="position-relative pt-0 z-index-1">
        <Button
          className="w-100"
          variant="falcon-default"
          onClick={handleClick}
          size="sm"
          disabled={!isReady || isLocating}
        >
          {(isLocating && (
            <Loader
              className="my-1"
              style={{ margin: '2px 0 1px', width: '0.6rem', height: '0.6rem' }}
            />
          )) ||
            classNames({
              Entrada: !isWorking,
              Salida: isWorking
            })}
        </Button>
      </Card.Body>
    </Card>
  );
};

TodayTimeControl.propTypes = {
  className: PropTypes.string
};

const TodayTimeControlWithProvider = props => {
  return (
    <TimeControlProvider avoidQuery={true}>
      <TodayTimeControl {...props} />
    </TimeControlProvider>
  );
};

export default TodayTimeControlWithProvider;
