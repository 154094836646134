import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FeedbackContext, UserContext } from 'context/Context';
import { deleteField } from '@firebase/firestore';

const TicketsPreviewHeader = ({ feedback }) => {
  const navigate = useNavigate();
  const { status } = feedback;
  const { deleteFeedback, update } = useContext(FeedbackContext);
  const { me } = useContext(UserContext);

  const handleChangeStatus = () => {
    update(feedback, {
      status: status === 'closed' ? deleteField() : 'closed'
    });
  };

  const handleDelete = async () => {
    await deleteFeedback(feedback);
    navigate(-1);
  };

  return (
    <Card>
      <Card.Header className="d-flex flex-between-center">
        <IconButton
          onClick={() => navigate(-1)}
          variant="falcon-default"
          size="sm"
          icon="arrow-left"
        />
        <Flex>
          {(me?.type === 'admin' || me?.NO_ID_FIELD === feedback?.agent) && (
            <IconButton
              variant="falcon-default"
              size="sm"
              icon={classNames({
                'arrow-rotate-right': status === 'closed',
                check: status !== 'closed'
              })}
              transform="shrink-2"
              iconAlign="middle"
              className="mx-2"
              onClick={handleChangeStatus}
            >
              <span className="d-none d-md-inline-block ms-1">
                {classNames({
                  Reabrir: status === 'closed',
                  Cerrar: status !== 'closed'
                })}
              </span>
            </IconButton>
          )}
          {me?.type === 'admin' && (
            <IconButton
              variant="falcon-danger"
              size="sm"
              icon="trash-alt"
              transform="shrink-2"
              iconAlign="middle"
              className="ms-2 d-none d-sm-block"
              onClick={handleDelete}
            >
              <span className="d-none d-md-inline-block ms-1">Eliminar</span>
            </IconButton>
          )}
        </Flex>
      </Card.Header>
    </Card>
  );
};

TicketsPreviewHeader.propTypes = {
  feedback: PropTypes.object
};

export default TicketsPreviewHeader;
