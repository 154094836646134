import React, { useContext } from 'react';
import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { ticketBadges, ticketPriorities } from 'components/feedback';
import { FeedbackContext, UserContext } from 'context/Context';
import { parseUrls } from 'helpers/utils';

dayjs.extend(relativeTime);
dayjs.locale('es');

const Timeline = () => {
  const { me } = useContext(UserContext);
  const { userId = me?.NO_ID_FIELD } = useParams();
  const { feedback = [] } = useContext(FeedbackContext);
  const timeline = feedback
    .filter(f => f.userId === userId)
    .sort((f1, f2) => (f1.createdAt > f2.createdAt ? -1 : 1));

  return (
    <div className="timeline-vertical py-0">
      {timeline.map((item, index) => {
        let {
          NO_ID_FIELD,
          comments = [],
          createdAt,
          feedback,
          icon = 'comment-medical',
          priority: priorityProp = 'medium',
          status: statusProp
        } = item;
        let title = `${feedback.substr(0, 30)}`;
        title = title < feedback ? `${title}...` : title;
        const diff = dayjs().diff(new Date(createdAt), 'days');
        const isResponded = comments?.some(({ userId: uid }) => uid !== userId);
        statusProp =
          statusProp ||
          (isResponded && 'responded') ||
          (diff > 3 ? 'overdue' : 'recent');

        const status =
          ticketBadges.find(({ value }) => value === statusProp) || {};
        const priority =
          ticketPriorities.find(({ value }) => value === priorityProp) || {};

        return (
          <div
            key={`Timeline-Feedback-${NO_ID_FIELD}`}
            className={classNames('timeline-item', {
              'timeline-item-start': index % 2 === 0,
              'timeline-item-end': index % 2 !== 0
            })}
          >
            <div className="timeline-icon icon-item icon-item-lg text-primary border-300">
              <FontAwesomeIcon icon={icon} className="fs-1" />
            </div>
            <Row
              className={` ${
                index % 2 == 0 ? 'timeline-item-start' : 'timeline-item-end'
              }`}
            >
              <Col lg={6} className="timeline-item-time">
                <div>
                  <h6 className="mb-0 text-700">
                    {' '}
                    {dayjs(createdAt).format('YYYY')}
                  </h6>
                  <p className="fs--2 text-500 font-sans-serif">
                    {dayjs(createdAt).format('D MMMM')}
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <div className="timeline-item-content arrow-bg-white">
                  <div className="timeline-item-card bg-white dark__bg-1100">
                    <Link to={`/feedback/tickets-preview/${NO_ID_FIELD}`}>
                      <h5 className="mb-2 hover-primary">{title}</h5>
                    </Link>
                    <p
                      className="border-bottom mb-3 pb-4 text-600 fs--1 text-pre-wrap max-w-100"
                      dangerouslySetInnerHTML={{ __html: parseUrls(feedback) }}
                    />
                    <Flex wrap="wrap" className="pt-2">
                      <h6 className="mb-0 text-600 lh-base">
                        <FontAwesomeIcon
                          icon={['far', 'clock']}
                          className="me-1"
                        />
                        {dayjs(createdAt).format('H:mm')}
                      </h6>
                      <Flex
                        alignItems="center"
                        className="ms-auto me-2 me-sm-x1 me-xl-2 me-xxl-x1"
                      >
                        <div
                          className={`dot me-0 me-sm-2 me-xl-0 me-xxl-2 bg-${priority.color}`}
                        ></div>
                        <h6 className="mb-0 text-700 d-none d-sm-block d-xl-none d-xxl-block">
                          {priority.title}
                        </h6>
                      </Flex>
                      <SoftBadge bg={status.type}>{status.content}</SoftBadge>
                    </Flex>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default Timeline;
