import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import AppContext from 'context/Context';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';

const columns = [
  {
    accessor: 'index',
    Cell: rowData => (
      <Flex alignItems="center" justifyContent="center">
        <SoftBadge pill className="fs--1">
          {rowData.row.original.index}
        </SoftBadge>
      </Flex>
    )
  },
  {
    accessor: 'user.name',
    Cell: rowData => {
      let { id, avatar, department, name } = rowData.row.original.user;
      return (
        <Flex alignItems="center">
          <Avatar size="xl" src={avatar} name={name} />
          <div className="ps-2">
            <Link to={`/user/profile/${id}`} className="text-800">
              <h6 className="mb-0">{name}</h6>
            </Link>
            <span className="text-500">{department}</span>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'user.department',
    cellProps: { className: 'd-none' },
    Cell: rowData => rowData.row.original.user.department
  },
  {
    accessor: 'count',
    Cell: rowData =>
      `${rowData.row.original.count} ${rowData.row.original.unit}`
  }
];

const RankingModal = ({
  data = [],
  title = '',
  unit = '',
  isOpen,
  setIsOpen
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      contentClassName="border"
      centered
    >
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className="bg-light px-x1 border-bottom-0"
      >
        <Modal.Title>
          <h5 className="mb-0">{title}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AdvanceTableWrapper
          columns={columns}
          data={data.map((item, index) => ({
            ...item,
            index: index + 1,
            unit
          }))}
          pagination
          perPage={20}
        >
          <AdvanceTableSearchBox table placeholder="Buscar..." />
          <AdvanceTable
            table
            headerClassName="d-none"
            rowClassName="align-middle white-space-nowrap hover-actions-trigger hover-bg-100"
            tableProps={{
              className: 'fs--1 mt-3 mb-0 overflow-hidden'
            }}
          />
        </AdvanceTableWrapper>
      </Modal.Body>
    </Modal>
  );
};

RankingModal.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  unit: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
};

export default RankingModal;
