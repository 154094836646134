import React, { useContext, useEffect, useState } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { collection, limit, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import UserInfo from './UserInfo';
import QuestionaireIntro from './QuestionaireIntro';
import QuestionaireReward from './QuestionaireReward';
import Questionaire from './Questionaire';
import QuestionaireOutcome from './QuestionaireOutcome';
import HappyQuestionaireProvider from './HappyQuestionaireProvider';
import { HappyQuestionairesContext } from 'context/Context';

const Error = () => <Navigate to="errors/404" replace={true} />;

const HappyQuestionaireViewerContent = () => {
  let { company, finishHappyQuestionaire, setMessageId, setUserId, user } =
    useContext(HappyQuestionairesContext);
  const { apps = [] } = company || {};
  const { lastQuestionnaireAt } = user;
  const [readOnly, setReadOnly] = useState(false);
  let { base64JsonStr } = useParams();
  const navigate = useNavigate();
  const db = useFirestore();
  let params = {};
  try {
    params = JSON.parse(atob(base64JsonStr));
  } catch (error) {
    return <Error />;
  }
  const { messageId, userId } = params;

  useEffect(() => {
    setUserId(userId);
  }, [userId]);

  useEffect(() => {
    setMessageId(messageId);
  }, [messageId]);

  const lastQuestionnaireDate = new Date(lastQuestionnaireAt);
  const isRewardable =
    apps.includes('questionaires') &&
    (!lastQuestionnaireAt ||
      lastQuestionnaireDate.getMonth() !== new Date().getMonth());

  const defaultsRef = query(
    collection(db, 'defaults'),
    where('current', '==', true),
    limit(1)
  );
  const { data: defaults = [] } = useFirestoreCollectionData(defaultsRef);
  const { happy_questionaire: questionaire = {} } = defaults?.[0] || {};
  const { handleSubmit, ...rest } = useForm();

  const onSubmitData = async data => {
    setReadOnly(true);
    const answers = questionaire?.definitions?.map(
      (def, index) => data[`entry-${index}`]
    );

    if (
      answers.some((response, index) => {
        const isUndefined = typeof response === 'undefined';
        if (isUndefined) {
          const url = `${window.location.href}/#entry-${index}`;
          navigate(url);
        }
        return isUndefined;
      })
    ) {
      return;
    }
    finishHappyQuestionaire({
      definitions: questionaire?.definitions,
      answers,
      user
    });
  };

  return (
    <>
      <Row className="g-3">
        <Col lg={4} xl={3}>
          <div className="sticky-sidebar top-navbar-height">
            <UserInfo user={user} />
          </div>
        </Col>
        <Col lg={8} xl={9}>
          <Form onSubmit={handleSubmit(onSubmitData)}>
            <QuestionaireIntro questionaire={questionaire} user={user} />
            {isRewardable && <QuestionaireReward />}
            <Questionaire {...rest} questionaire={questionaire} />
            <QuestionaireOutcome
              questionaire={questionaire}
              readOnly={readOnly}
              isRewardable={isRewardable}
            />
          </Form>
        </Col>
      </Row>
    </>
  );
};

const HappyQuestionaireViewer = () => {
  return (
    <HappyQuestionaireProvider>
      <HappyQuestionaireViewerContent />
    </HappyQuestionaireProvider>
  );
};

export default HappyQuestionaireViewer;
