import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import Tooltip from 'components/common/Tooltip';
import { BadgeCountChat } from 'components/BadgeCount';
import { ChatContext, UserContext } from 'context/Context';
import { Card } from 'react-bootstrap';
import ChatCreator from './creator/ChatCreator';
import ChatSidebar from './sidebar/ChatSidebar';

const ChatOverAllHeader = ({ isOpen, setIsOpen }) => {
  const { isOpenNewChat, setIsOpenNewChat } = useContext(ChatContext);
  const { company, me } = useContext(UserContext);
  const { avatar, name, status } = me || {};
  const { apps: activeApps = [] } = company || {};
  if (!activeApps.includes('chat')) {
    return null;
  }

  const handleOpen = () => setIsOpen(isOpen => !isOpen);

  return (
    <Flex
      alignItems="center"
      className="position-relative border-bottom px-3 py-2"
    >
      <Button
        variant="outline-light"
        className="position-absolute border-0 shadow-none rounded-bottom-0 start-0 end-0 top-0 bottom-0 z-index-0"
        onClick={handleOpen}
      />
      <Avatar
        src={avatar}
        name={name}
        className={classNames('pointer-none', {
          [`status-${status}`]: !!status
        })}
      />
      <div className="position-relative flex-1 px-2 pointer-none">
        <h6 className="m-0 fw-semi-bold">
          {classNames({ Chat: !isOpenNewChat, 'Nuevo chat': isOpenNewChat })}
          <BadgeCountChat />
        </h6>
      </div>
      <Tooltip
        placement="top"
        title={classNames({
          'Lista de chats': isOpenNewChat,
          'Nuevo chat': !isOpenNewChat
        })}
        className="me-2 z-index-1"
      >
        <Button
          variant="send"
          className="border-0 p-1 rounded-5 shadow-none m-0 z-index-1"
          size="sm"
          onClick={() => {
            setIsOpen(true);
            setIsOpenNewChat(isOpenNewChat => !isOpenNewChat);
          }}
        >
          <FontAwesomeIcon
            icon={isOpenNewChat ? ['far', 'comments'] : ['far', 'edit']}
            size="lg"
          />
        </Button>
      </Tooltip>
      <Button
        variant="send"
        className="border-0 pointer-event p-1 rounded-5 shadow-none m-0 z-index-1"
        onClick={handleOpen}
      >
        <FontAwesomeIcon
          icon={classNames({
            'chevron-down': isOpen,
            'chevron-up': !isOpen
          })}
        />
      </Button>
    </Flex>
  );
};

ChatOverAllHeader.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
};

const ChatOverAll = () => {
  const { pathname } = useLocation();
  const { chatId, userId: userIdParam } = useParams();
  const {
    chats = [],
    currentChat,
    createChat,
    setCurrentChat,
    setIsOpenChatList
  } = useContext(ChatContext);
  const { me, partners = [] } = useContext(UserContext);
  const [userId] = useState(userIdParam);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const chat = chats.find(({ NO_ID_FIELD }) => NO_ID_FIELD === chatId);
    (currentChat?.NO_ID_FIELD !== chat?.NO_ID_FIELD || !currentChat?.name) &&
      setCurrentChat?.(chat);
    !chatId && setIsOpenChatList?.(true);
  }, [chatId, chats]);

  useEffect(() => {
    if (!userId || !chats?.length) {
      return;
    }
    const isChat = !!pathname?.match(/^\/company\/chat\//);
    const { ref } =
      partners?.find(({ NO_ID_FIELD }) => NO_ID_FIELD === userId) || {};
    const participants = [me?.ref, ref];
    isChat && ref && createChat({ participants, type: 'user' });
  }, [userId, chats?.length, pathname]);

  return (
    <Card className="d-none d-lg-block position-fixed bottom-0 end-0 me-8 bg-white border border-bottom-0 rounded-3 rounded-bottom-0 w-350px z-index-1030">
      <ChatOverAllHeader isOpen={isOpen} setIsOpen={setIsOpen} />
      <Collapse in={isOpen}>
        <div>
          <Card className="card-chat w-100 max-vh-75 rounded-bottom-0 shadow-none">
            <Card.Body className="p-0">
              <ChatCreator wrapper="overAll" />
              <ChatSidebar wrapper="overAll" />
            </Card.Body>
          </Card>
        </div>
      </Collapse>
    </Card>
  );
};

export default ChatOverAll;
