import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SoftBadge = ({
  as: Component = 'div',
  bg = 'primary',
  pill,
  children,
  className,
  ...rest
}) => {
  return (
    <Component
      className={classNames(className, `badge badge-soft-${bg}`, {
        'rounded-pill': pill
      })}
      {...rest}
    >
      {children}
    </Component>
  );
};

SoftBadge.propTypes = {
  as: PropTypes.func,
  bg: PropTypes.oneOf([
    'primary',
    'accent',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'light',
    'dark',
    'yellow',
    '400',
    '800'
  ]),
  pill: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
};

export default SoftBadge;
