import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteField } from 'firebase/firestore';
import FilesProvider from 'components/company/files/FilesProvider';
import Avatar from 'components/common/Avatar';
import FalconDropzone from 'components/common/FalconDropzone';
import Flex from 'components/common/Flex';
import Tooltip from 'components/common/Tooltip';
import { CalendarContext, FilesContext, UserContext } from 'context/Context';
import { download, getSize } from 'helpers/utils';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import fileTypes from './fileTypes.json';

const fileIcons = {
  'application/pdf': 'file-pdf',
  'image/pdf': 'file-image',
  'image/jpeg': 'file-image',
  'image/jpg': 'file-image',
  'image/png': 'file-image'
};

const File = ({ file, isLast }) => {
  const { path, mimeType, size, type } = file;
  const { isEditable } = fileTypes[type] || {};
  const { deleteFile, getFileUrl, updateFile } = useContext(FilesContext);
  const { update: updateCalendar } = useContext(CalendarContext);
  const { me } = useContext(UserContext);

  const handleDownloadFile = async () => {
    const url = await getFileUrl(file);
    url && download(url, path);
  };

  const handleClick = event => {
    event.currentTarget.nextElementSibling.click();
  };

  const handleChangeFile = async event => {
    const { files = [] } = event.target;
    const newFile = files?.[0];
    const { name: path, type: mimeType, size } = newFile;
    await updateFile(file, { file: newFile, mimeType, path, size });
    event.target = null;
  };

  const handleDeleteFile = async () => {
    const { timeOffId } = file;
    await deleteFile(file);
    if (timeOffId) {
      await updateCalendar(
        { NO_ID_FIELD: timeOffId },
        { fileId: deleteField() }
      );
    }
  };

  return (
    <>
      <Flex
        alignItems="center"
        className="position-relative hover-bg-100 p-3 btn-reveal-trigger"
      >
        <FontAwesomeIcon icon={fileIcons[mimeType] || 'file'} size="2x" />
        <Flex
          justifyContent="between"
          alignItems="center"
          className="ms-3 w-100"
        >
          <div>
            <Button variant="link" className="p-0" onClick={handleDownloadFile}>
              <h6 className="mb-0" data-dz-name="">
                {path}
              </h6>
            </Button>
            <p className="mb-0 fs--1 text-400 line-height-1">{getSize(size)}</p>
          </div>
          {(me?.type === 'admin' || isEditable) && (
            <ButtonGroup>
              <Button
                variant="falcon-default"
                className="p-0"
                onClick={handleClick}
              >
                <Tooltip className="py-1 px-3" title="Modificar">
                  <FontAwesomeIcon icon="file-upload" />
                </Tooltip>
              </Button>
              <input
                type="file"
                className="visually-hidden"
                onChange={handleChangeFile}
              />
              <Button
                variant="falcon-danger"
                className="p-0"
                onClick={handleDeleteFile}
              >
                <Tooltip className="py-1 px-3" title="Eliminar">
                  <FontAwesomeIcon icon="trash" />
                </Tooltip>
              </Button>
            </ButtonGroup>
          )}
        </Flex>
        {!isLast && (
          <div className="position-absolute w-100 start-0 bottom-0 px-3 m-0 mb-n3">
            <hr className="w-100" />
          </div>
        )}
      </Flex>
    </>
  );
};

File.propTypes = {
  file: PropTypes.object,
  isLast: PropTypes.bool
};

const FilesContent = ({ className, files = [], type, user }) => {
  const { createFile } = useContext(FilesContext);
  const { me } = useContext(UserContext);
  const {
    description,
    icon = 'file',
    title,
    isEditable
  } = fileTypes[type] || {};
  const canUpload = me?.type === 'admin' || isEditable;

  const handleChange = (filesInfo, files) => {
    files.forEach((file, index) => {
      const fileInfo = filesInfo[index];
      // eslint-disable-next-line no-unused-vars
      const { base64, type: mimeType, ...info } = fileInfo;
      const { NO_ID_FIELD: userId } = user;
      createFile(file, { type, mimeType, userId, ...info });
    });
  };

  return (
    <>
      <Card className={classNames('g-3 m-0 overflow-hidden', className)}>
        <Card.Header className="bg-light border-bottom border-200" as={Flex}>
          <Avatar src={user?.avatar} name={user?.name} size="2xl" />
          <Avatar
            icon={icon}
            size="xl"
            className="ms-n2 me-3"
            mediaClass="bg-primary text-white d-flex align-items-center justify-content-center fs-1"
          />
          <div>
            <h5 className="mb-0">{title}</h5>
            <p className="fs--1">{description}</p>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          {!files?.length && <p className="p-3">No hay archivos</p>}
          {files.map((file, index) => (
            <File
              key={`File-${file.NO_ID_FIELD}`}
              file={file}
              isLast={index === files.length - 1}
            />
          ))}
        </Card.Body>
        {canUpload && (
          <Card.Footer>
            <FalconDropzone
              className="h-auto py-6"
              edit={true}
              files={files}
              onChange={handleChange}
              placeholder={
                <>
                  <img src={cloudUpload} alt="" width={25} />
                  <p className="fs-0 mb-0 text-700">
                    Arrastra aquí tus {title.toLocaleLowerCase()} o clica para
                    subirlos
                  </p>
                </>
              }
            />
          </Card.Footer>
        )}
      </Card>
    </>
  );
};

FilesContent.propTypes = {
  className: PropTypes.string,
  files: PropTypes.array,
  type: PropTypes.string,
  user: PropTypes.object
};

const TimeOffList = props => {
  return (
    <FilesProvider>
      <FilesContent {...props} />
    </FilesProvider>
  );
};

export default TimeOffList;
