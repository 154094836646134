import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import StarRating from 'components/common/StarRating';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { ProductContext, UserContext } from 'context/Context';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

dayjs.extend(calendar);

const Review = ({ product, review, setEdit, isLast }) => {
  const { userId, rating, content, createdAt, liked } = review;
  const { getUser, me } = useContext(UserContext);
  const { deleteReview } = useContext(ProductContext);
  const [user, setUser] = useState();
  const { avatar, name } = user || {};
  const date = dayjs(createdAt)
    .calendar(null, {
      sameDay: '[Hoy], HH:mm',
      lastDay: '[Ayer], HH:mm',
      lastWeek: 'dddd, HH:mm',
      sameElse: 'ddd, D MMM, HH:mm'
    })
    .replace('.', '');

  const handleDelete = () => {
    deleteReview(product, review);
  };

  useEffect(() => {
    (async () => {
      const user = await getUser(userId);
      setUser(user);
    })();
  }, [userId]);

  return (
    <Row
      className={classNames(
        'g-3 align-items-center text-center text-md-start py-3',
        {
          'border-bottom border-200': isLast
        }
      )}
    >
      <Col md="auto">
        <Avatar size="3xl" src={avatar} />
      </Col>
      <Col>
        <Row>
          <Col xs={12}>
            <h6 className="fs-0">
              <span className="me-2 text-primary">{name}</span>
              <StarRating readonly rating={rating} />
            </h6>
          </Col>
          <Col md={10}>
            <p className="fs--1 text-800">{content}</p>
          </Col>
          <Col xs={12}>
            <Flex
              alignItems="center"
              className="flex-md-row gap-2 fs--2 text-600"
            >
              <h6 className="fs--2 text-600 mb-0">{date}</h6>
              {liked && (
                <>
                  ·
                  <p className="mb-0 ms-1">
                    A {liked} personas les resultó útil
                  </p>
                </>
              )}
              {userId === me?.NO_ID_FIELD && (
                <>
                  ·
                  <Button
                    variant="link"
                    className="fs--1 p-0"
                    onClick={() => setEdit(true)}
                  >
                    Editar
                  </Button>
                  <Button
                    variant="link"
                    className="fs--1 p-0"
                    onClick={handleDelete}
                  >
                    Eliminar
                  </Button>
                </>
              )}
            </Flex>
          </Col>
        </Row>
      </Col>
      {/* <Col md="auto" className="d-flex justify-content-center gap-2">
        <Button
          variant="falcon-default"
          type="button"
          size="sm"
          className="icon-item focus-bg-primary"
        >
          <FontAwesomeIcon icon="thumbs-up" className="fs--2" />
        </Button>
        <Button
          variant="falcon-default"
          type="button"
          size="sm"
          className="icon-item focus-bg-secondary"
        >
          <FontAwesomeIcon
            icon="thumbs-up"
            transform="rotate-180"
            className="fs--2"
          />
        </Button>
      </Col> */}
    </Row>
  );
};

const ProductReviews = ({ product = {} }) => {
  const { NO_ID_FIELD, reviews = [] } = product;
  const { me } = useContext(UserContext);
  const { createReview, updateReview } = useContext(ProductContext);
  const myReview = reviews.find(({ userId }) => userId === me?.NO_ID_FIELD);
  const [review, setReview] = useState(myReview?.content || '');
  const [rating, setRating] = useState(myReview?.rating || 5);
  const [edit, setEdit] = useState(false);
  const footer = useRef();

  useEffect(() => {
    if (myReview) {
      setReview(myReview?.content);
      setRating(myReview?.rating);
    }
  }, [myReview]);

  useEffect(() => {
    edit &&
      footer?.current?.scrollIntoView?.({
        block: 'center',
        behavior: 'smooth'
      });
  }, [edit]);

  const submitComment = e => {
    e.preventDefault();
    if (myReview) {
      updateReview(product, myReview, {
        content: review,
        rating,
        userId: me?.NO_ID_FIELD
      });
      return;
    }
    createReview(product, {
      content: review,
      rating,
      userId: me?.NO_ID_FIELD
    });
    setReview('');
  };

  if (!NO_ID_FIELD) {
    return null;
  }

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Reseñas" titleTag="h5" className="bg-light" />
      <Card.Body className="py-0">
        {!reviews.length && (
          <p className="mt-3 fs--1 text-800">Todavía no hay reseñas</p>
        )}
        {reviews.map((review, index) => (
          <Review
            key={`Review-${review?.NO_ID_FIELD}-${index}`}
            product={product}
            review={review}
            setEdit={setEdit}
            isLast={reviews.length - 1 !== index}
          />
        ))}
      </Card.Body>
      {(!myReview || edit) && (
        <Card.Footer ref={footer} className="bg-light text-end">
          <Form onSubmit={submitComment}>
            <Flex>
              <Avatar
                src={me?.avatar}
                name={me?.name}
                size="3xl"
                className="mt-3 me-3"
              />
              <div className="w-100 text-start">
                <div className="fs-3 mb-2">
                  <StarRating
                    rating={rating}
                    handleChange={value => setRating(value)}
                  />
                </div>
                <Form.Control
                  type="text"
                  className="fs--1 mb-3"
                  placeholder="Escribe una reseña..."
                  value={review}
                  onChange={e => setReview(e.target.value)}
                />
                <Button type="submit" className="mb-2">
                  Enviar
                </Button>
              </div>
            </Flex>
          </Form>
        </Card.Footer>
      )}
    </Card>
  );
};

Review.propTypes = {
  product: PropTypes.object,
  review: PropTypes.object,
  setEdit: PropTypes.func,
  isLast: PropTypes.bool
};

ProductReviews.propTypes = {
  product: PropTypes.object
};

export default ProductReviews;
