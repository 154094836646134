import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import KanbanColumnHeader from './KanbanColumnHeader';
import OfferCard from './OfferCard';
import AddOfferForm from './AddOfferForm';
import IconButton from 'components/common/IconButton';
import StrictModeDroppable from './StrictModeDroppable';

const OffersColumn = ({ kanbanColumnItem }) => {
  const { id, items } = kanbanColumnItem;
  const [showForm, setShowForm] = useState(false);
  const formViewRef = useRef(null);

  useEffect(() => {
    formViewRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [showForm]);

  return (
    <div className={classNames('kanban-column', { 'form-added': showForm })}>
      <KanbanColumnHeader column={kanbanColumnItem} id={id} />
      <StrictModeDroppable droppableId={`${id}`} type="OFFERS">
        {provided => (
          <>
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              id="Column-offers"
              className="kanban-items-container scrollbar"
            >
              {items.map((task, index) => (
                <OfferCard key={task.id} index={index} task={task} />
              ))}
              <AddOfferForm showForm={showForm} setShowForm={setShowForm} />
              {provided.placeholder}
              <div ref={formViewRef}></div>
            </div>
            {!showForm && (
              <div className="kanban-column-footer">
                <IconButton
                  size="sm"
                  variant="link"
                  className="d-block w-100 btn-add-card text-decoration-none text-600"
                  icon="plus"
                  iconClassName="me-2"
                  onClick={() => setShowForm(true)}
                >
                  Añadir oferta de trabajo
                </IconButton>
              </div>
            )}
          </>
        )}
      </StrictModeDroppable>
    </div>
  );
};

OffersColumn.propTypes = {
  kanbanColumnItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string,
    items: PropTypes.arrayOf(OfferCard.propTypes.task),
    unit: PropTypes.string
  })
};

export default OffersColumn;
