import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Modal, Nav, Row } from 'react-bootstrap';
import ls from 'local-storage';
import Flex from 'components/common/Flex';
import ProductProvider from 'components/marketplace/ProductProvider';
import { ProductContext, UserContext } from 'context/Context';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination || []);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // change background colour if dragging
  transform: isDragging ? 'scale(1.2)' : '',

  // styles we need to apply on draggables
  ...draggableStyle
});
const getListStyle = isDraggingOver => ({
  color: isDraggingOver ? 'var(--falcon-primary)' : ''
});

const Custom = ({ apps, children }) => {
  const localConfig = ls('home');
  const [state, setState] = useState([]);
  const {
    isEditing,
    isWidgetsModalOpened,
    myCompany,
    openWidgetsModal,
    updateCompany
  } = useContext(UserContext);
  const { apps: allApps } = useContext(ProductContext);
  const { apps: activeApps } = myCompany || {};

  const getLocalApps = (group = localConfig) => {
    return group?.reduce(
      (all, apps) => [
        ...all,
        ...(Array.isArray(apps?.[0]) ? getLocalApps(apps) : apps)
      ],
      []
    );
  };

  const localApps = getLocalApps();

  const onDragStart = () => {
    document.body.classList.add('dragging');
  };

  const onDragEnd = result => {
    document.body.classList.remove('dragging');
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sRow = +source.droppableId.split('-').shift();
    const dRow = +destination.droppableId.split('-').shift();
    const sCol = +source.droppableId.split('-').pop();
    const dCol = +destination.droppableId.split('-').pop();
    state[sRow] = state[sRow] || [];
    state[sRow][sCol] = state[sRow][sCol] || [];
    state[dRow] = state[dRow] || [];
    state[dRow][dCol] = state[dRow][dCol] || [];
    let newState = [...state];
    if (sRow === dRow && sCol === dCol) {
      const items = reorder(state[sRow][sCol], source.index, destination.index);
      newState[sRow][sCol] = items;
      newState = newState
        .map(row => row.map(col => col).filter(apps => apps.length))
        .filter(cols => cols.length);
      setState(newState);
    } else {
      const result = move(
        state[sRow][sCol],
        state[dRow][dCol],
        source,
        destination
      );
      newState[sRow][sCol] = result[`${sRow}-${sCol}`];
      newState[dRow][dCol] = result[`${dRow}-${dCol}`];
      newState = newState
        .map(row => row.map(col => col).filter(apps => apps.length))
        .filter(cols => cols.length);
      setState(newState);
    }
    ls(
      'home',
      newState.map(row =>
        // eslint-disable-next-line no-unused-vars
        row.map(col => col.map(({ content, ...rest }) => rest))
      )
    );
  };

  const handleDeleteButtonClick = keyToDelete => {
    let newState = [...state]
      .map(row =>
        row
          .map(col => col.filter(({ key }) => key !== keyToDelete))
          .filter(apps => apps.length)
      )
      .filter(cols => cols.length);
    setState(newState);
    ls(
      'home',
      newState.map(row =>
        // eslint-disable-next-line no-unused-vars
        row.map(col => col.map(({ content, ...rest }) => rest))
      )
    );
  };

  const handleAddWidgetClick = appToAdd => {
    let newState = [...state];
    let [row, col] = isWidgetsModalOpened.split?.('-') || [];
    let allowedRowIndex = newState.findIndex(cols => cols.length < 4);
    allowedRowIndex =
      allowedRowIndex === -1 ? newState.length : allowedRowIndex;
    row = !isNaN(row) ? +row : allowedRowIndex;
    newState[row] = newState[row] || [];

    col = !isNaN(col)
      ? +col
      : newState[row].length < 4
      ? newState[row].length
      : 0;

    newState[row][col] = newState[row][col] || [];
    newState[row][col].push(appToAdd);
    setState(newState);
    ls(
      'home',
      newState.map(row =>
        // eslint-disable-next-line no-unused-vars
        row.map(col => col.map(({ content, ...rest }) => rest))
      )
    );
    openWidgetsModal(false);
  };

  const homeApps = ['default', ...(activeApps || [])].reduce(
    (homeApps, key) => [...homeApps, ...(apps[key] || [])],
    []
  );
  const activeHomeApps =
    localConfig?.reduce((homeApps, rowApps) => {
      const colApps = rowApps.reduce(
        (colApps, apps) => [...(colApps || []), ...(apps || [])],
        []
      );
      return [...homeApps, ...colApps];
    }, []) || [];
  const unactiveHomeApps = homeApps.filter(
    ({ key }) => !activeHomeApps.map(({ key }) => key).includes(key)
  );

  useEffect(() => {
    if (typeof activeApps !== 'undefined') {
      return;
    }
    const freeApps = allApps
      .filter(({ free }) => free)
      .map(({ NO_ID_FIELD }) => NO_ID_FIELD);
    updateCompany(myCompany, { apps: freeApps });
  }, [myCompany?.NO_ID_FIELD]);

  useEffect(() => {
    if (typeof activeApps === 'undefined' || !homeApps?.length || !myCompany) {
      return;
    }
    const activeAppsLength =
      activeApps?.reduce((sum, key) => {
        return sum + (apps?.[key]?.length || 0);
      }, 0) + (apps?.default?.length || 0);

    if (localConfig?.length && activeAppsLength === localApps?.length) {
      const state = localConfig
        .map(row =>
          row
            ?.map(col =>
              col?.map(app => {
                const { content } =
                  homeApps.find(({ key }) => key === app.key) || {};
                return { ...app, content };
              })
            )
            .filter(apps => apps?.length)
        )
        .filter(cols => cols?.length);
      setState(state);
      ls(
        'home',
        state.map(row =>
          // eslint-disable-next-line no-unused-vars
          row.map(col => col.map(({ content, ...rest }) => rest))
        )
      );
      return;
    }

    const newState = [[]];
    const totalSize = homeApps.reduce((total, { size }) => total + size, 0);
    const maxSizePerCol = Math.ceil(totalSize / 4);
    let currentRow = 0;
    let currentCol = 0;
    homeApps.forEach(app => {
      const { size } = app;
      newState[currentRow] = newState[currentRow] || [];
      newState[currentRow][currentCol] = newState[currentRow][currentCol] || [];
      const totalSize = newState[currentRow][currentCol].reduce(
        (total, { size }) => total + size,
        0
      );
      if (totalSize + size > maxSizePerCol) {
        currentCol += 1;
        if (currentCol > 3) {
          currentRow += 1;
          currentCol = 0;
          newState[currentRow] = newState[currentRow] || [];
        }
        newState[currentRow][currentCol] =
          newState[currentRow][currentCol] || [];
      }
      newState[currentRow][currentCol].push(app);
    });
    setState(newState);
    ls(
      'home',
      newState.map(row =>
        // eslint-disable-next-line no-unused-vars
        row.map(col => col.map(({ content, ...rest }) => rest))
      )
    );
  }, [homeApps?.length]);

  return (
    <>
      <Modal
        show={!!isWidgetsModalOpened}
        onHide={() => openWidgetsModal(false)}
      >
        <Modal.Header closeButton>Añadir widgets</Modal.Header>
        <Modal.Body>
          {unactiveHomeApps.map(app => (
            <div
              key={app.key}
              className="cursor-pointer"
              onClick={() => handleAddWidgetClick(app)}
            >
              <div
                className="py-2 pointer-none"
                style={{
                  height: `${app.size * 160 + app.size * 16}px`
                }}
              >
                {app.content}
              </div>
            </div>
          ))}
        </Modal.Body>
      </Modal>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        {state.map((row, rowIndex) => (
          <Row
            key={`Row-${rowIndex}`}
            className={classNames('g-3', {
              'py-1': isEditing,
              'flex-grow-1': state.length === 1
            })}
          >
            {row.map((col, colIndex) => (
              <Droppable
                key={`Row-${rowIndex}-Col-${colIndex}`}
                droppableId={`${rowIndex}-${colIndex}`}
              >
                {(provided, snapshot) => (
                  <Col
                    xs={12}
                    md={6}
                    xxl={true}
                    ref={provided.innerRef}
                    className="droppable d-flex flex-column"
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {col
                      .filter(app => app.content)
                      .map(
                        (app, index) =>
                          app.content && (
                            <Draggable
                              key={app.key}
                              draggableId={app.key}
                              index={index}
                              isDragDisabled={!isEditing}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  className={classNames(
                                    'draggable d-flex align-items-start justify-content-end py-2',
                                    {
                                      'animate-none': snapshot.isDragging,
                                      'flex-grow-1':
                                        !isEditing &&
                                        index ===
                                          col.filter(app => app.content)
                                            .length -
                                            1,
                                      'disabled pointer-none desaturate opacity-50':
                                        !homeApps.some(
                                          ({ key }) => key === app.key
                                        )
                                    }
                                  )}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(snapshot.isDragging, {
                                    ...provided.draggableProps.style,
                                    height: `${
                                      app.size * 160 + app.size * 16
                                    }px`
                                  })}
                                >
                                  {isEditing && (
                                    <div className="position-absolute z-index-2">
                                      <Button
                                        variant="falcon-default"
                                        className="delete-widget position-absolute end-0 rounded-pill mt-n1 me-n1"
                                        size="sm"
                                        onClick={() =>
                                          handleDeleteButtonClick(app.key)
                                        }
                                      >
                                        <FontAwesomeIcon icon="times" />
                                      </Button>
                                    </div>
                                  )}
                                  {app.content || (
                                    <div className="border border-2 border-opacity-50 h-100 rounded-3 w-100">
                                      {Object.values(apps).find(
                                        ({ key }) => key === app.key
                                      )?.content || null}
                                    </div>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          )
                      )}
                    {provided.placeholder}
                  </Col>
                )}
              </Droppable>
            ))}
            {isEditing && row.length < 4 && (
              <Droppable droppableId={`${rowIndex}-${row.length}`}>
                {(provided, snapshot) => (
                  <Col
                    xs="auto"
                    ref={provided.innerRef}
                    className="flex-grow-0 droppable border border-2 border-dashed border-400 rounded-3"
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    <Flex
                      className="w-100 h-100 p-3"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {!!unactiveHomeApps.length && (
                        <Nav.Link
                          className="px-2 theme-control-toggle"
                          onClick={() =>
                            openWidgetsModal(`${rowIndex}-${row.length}`)
                          }
                        >
                          <div className="theme-control-toggle-label w-auto h-auto rounded-pill py-1 px-2 fs--2">
                            <FontAwesomeIcon icon="plus" className="fs-0" />
                          </div>
                        </Nav.Link>
                      )}
                    </Flex>
                  </Col>
                )}
              </Droppable>
            )}
          </Row>
        ))}
        {isEditing && (
          <Row className={classNames('py-2 g-3')}>
            <Droppable droppableId={`${state?.length}-0`}>
              {(provided, snapshot) => (
                <Col
                  xs={12}
                  ref={provided.innerRef}
                  className="droppable border border-2 border-dashed border-400 rounded-3"
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  <Flex
                    className="w-100 h-100 p-3"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {!!unactiveHomeApps.length && (
                      <Nav.Link
                        className="px-2 theme-control-toggle"
                        onClick={() => openWidgetsModal(`${state?.length}-0`)}
                      >
                        <div className="theme-control-toggle-label w-auto h-auto rounded-pill py-1 px-2 fs--2">
                          <FontAwesomeIcon icon="plus" className="fs-0" />
                        </div>
                      </Nav.Link>
                    )}
                  </Flex>
                </Col>
              )}
            </Droppable>
          </Row>
        )}
      </DragDropContext>
      {children}
    </>
  );
};

Custom.propTypes = {
  apps: PropTypes.object,
  children: PropTypes.node
};

const CustomWithProducts = props => {
  return (
    <ProductProvider>
      <Custom {...props} />
    </ProductProvider>
  );
};

export default CustomWithProducts;
