import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import { Button, Card, ProgressBar } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Loader from 'components/common/Loader';
import Tooltip from 'components/common/Tooltip';
import EmployeesProvider from 'components/company/employees/EmployeesProvider';
import { EmployeesContext, UserContext } from 'context/Context';
import check from 'assets/img/animated-icons/check-primary-light.json';

const ResendButton = ({ percentage }) => {
  const { partners = [] } = useContext(UserContext);
  const { sendSignInLink } = useContext(EmployeesContext);
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      const employeesOut = partners.filter(
        ({ happiness, token }) => typeof happiness !== 'number' && !token
      );
      if (!employeesOut?.length) {
        toast.success(
          'Todos los empleados ya están disfrutando de Happyfy. ¡Enhorabuena!'
        );
        setLoading(false);
        return;
      }
      await sendSignInLink(employeesOut.map(({ email }) => email));
      toast.success('Acceso reenviado');
    } catch (error) {
      toast.error('Ha habido un error al reenviar el acceso');
    }
    setLoading(false);
  };

  return percentage === 100 ? (
    <Tooltip
      className="position-absolute end-0 bottom-0 m-2"
      title="Todos los empleados ya están disfrutando de Happyfy. ¡Enhorabuena!"
    >
      <Lottie animationData={check} className="w-60px" loop={false} />
    </Tooltip>
  ) : (
    <Tooltip title="Te ayudamos a que todos los empleados hagan el onboarding reenviándoles el email de acceso a Happyfy">
      <Button
        variant="falcon-default me-0 ms-auto"
        size="sm"
        onClick={handleClick}
      >
        {loading ? (
          <Loader
            className="p-1"
            style={{ width: '0.75rem', height: '0.75rem' }}
          />
        ) : (
          'Dar acceso a los que faltan'
        )}
      </Button>
    </Tooltip>
  );
};

ResendButton.propTypes = {
  percentage: PropTypes.number
};

const UsersUsingHappyfy = ({ className }) => {
  const { partners = [] } = useContext(UserContext);
  const using = partners.filter(
    ({ happiness, lastQuestionnaireAt, token }) =>
      typeof happiness === 'number' || token || lastQuestionnaireAt
  ).length;
  let percentage = Math.round((using * 100) / partners.length);
  if (isNaN(percentage)) {
    percentage = 0;
  }

  return (
    <Card className={className}>
      <Card.Header className="pb-0">
        <h6 className="m-0">Empleados en Happyfy</h6>
      </Card.Header>
      <Card.Body>
        <ProgressBar
          now={percentage}
          key="UsersUsingHappyfy-ProgressBar"
          style={{ height: '8px' }}
          className="rounded-pill mb-2"
        />
        <p className="mb-0 text-primary">{percentage}% completado</p>
        <p className="mb-0 fs--1 text-500">
          {using} de {partners?.length}
        </p>
        <div className="text-end">
          <EmployeesProvider avoidQuery={true}>
            <ResendButton percentage={percentage} />
          </EmployeesProvider>
        </div>
      </Card.Body>
    </Card>
  );
};

UsersUsingHappyfy.propTypes = {
  className: PropTypes.string
};

export default UsersUsingHappyfy;
