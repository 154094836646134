import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AssistantProvider from './AssistantProvider';
import KanbanHeader from './KanbanHeader';
import OffersContainer from './OffersContainer';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { AssistantContext } from 'context/Context';

const Offers = () => {
  const {
    kanbanState: { kanbanItems }
  } = useContext(AssistantContext);
  const { items = [] } = kanbanItems.find(({ id }) => id === 'offers') || {};

  return (
    <>
      <KanbanHeader
        title={
          <Flex alignItems="center">
            <SoftBadge bg="success" className="me-1">
              <FontAwesomeIcon icon="magic" className="me-1" />
              IA
            </SoftBadge>
            Ofertas
          </Flex>
        }
        itemCount={items.length}
        help="La IA crea por ti, en varios idiomas, descripciones de ofertas de trabajo a partir del departamento."
      />
      <OffersContainer />
    </>
  );
};

const OffersWithProvider = props => {
  return (
    <AssistantProvider>
      <Offers {...props} />
    </AssistantProvider>
  );
};

export default OffersWithProvider;
