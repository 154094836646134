import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Modal } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { version } from 'config';
import equal from 'fast-deep-equal';
import dayjs from 'dayjs';
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  linkWithPopup,
  linkWithRedirect,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
  signOut as firebaseSignOut,
  TwitterAuthProvider,
  unlink,
  updatePassword,
  updateProfile
} from '@firebase/auth';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where
} from '@firebase/firestore';
import { getDownloadURL, ref } from '@firebase/storage';
import {
  useAuth,
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
  useStorage
} from 'reactfire';
import { toast } from 'react-toastify';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import HolidaysModal from 'components/company/calendar/HolidaysModal';
import AppContext, { UserContext } from 'context/Context';
import { getUnique, isMobile } from 'helpers/utils';

let isRegistered = false;

const getProvider = providerId => {
  let provider;
  switch (providerId) {
    case 'email':
    case 'password':
      provider = new EmailAuthProvider();
      break;
    case 'facebook.com':
      provider = new FacebookAuthProvider();
      break;
    case 'google.com':
      provider = new GoogleAuthProvider();
      break;
    case 'linkedin.com':
      provider = new OAuthProvider('oidc.linkedin.com');
      provider.addScope('openid');
      provider.addScope('email');
      provider.addScope('profile');
      break;
    case 'twitter':
    case 'twitter.com':
      provider = new TwitterAuthProvider();
      break;
    default:
      throw new Error(`No provider implemented for ${providerId}`);
  }
  return provider;
};

const NoteModal = () => {
  const { createNote, selectedUser, showNotesModal, setShowNotesModal } =
    useContext(UserContext);
  const { avatar, department, name } = selectedUser || {};
  const [state, setState] = useState({});

  const handleClose = () => {
    setShowNotesModal(false);
    setState({});
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!state?.title || !state?.description) {
      return;
    }
    createNote(selectedUser?.NO_ID_FIELD, state);
    handleClose();
  };

  return (
    <Modal show={showNotesModal} onHide={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton className="bg-white dark__bg-1100 sticky-top">
          <Modal.Title>Nueva nota</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Empleado</Form.Label>
          <Flex alignItems="center" className="gap-2">
            <Avatar src={avatar} name={name} size="2xl" />
            <p className="mb-0">
              <span className="fw-semi-bold mb-0 text-800">{name}</span>
              <span className="mb-0 fs--1 d-block text-500">{department}</span>
            </p>
          </Flex>
          <Form.Label className="mt-3">Título</Form.Label>
          <Form.Control
            value={state?.title}
            onChange={({ target }) =>
              setState(state => ({ ...state, title: target.value }))
            }
            placeholder="Título"
          />
          <Form.Label className="mt-3">Descripción</Form.Label>
          <Form.Control
            as="textarea"
            className="resize-none"
            value={state?.description}
            rows={3}
            onChange={({ target }) =>
              setState(state => ({ ...state, description: target.value }))
            }
            placeholder="Descripción"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!state?.title?.trim() || !state?.description?.trim()}
            size="sm"
            type="submit"
          >
            <span className="d-none d-sm-inline-block ms-1">Guardar nota</span>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const ToDoModal = () => {
  const { createTodoItem, showToDoModal, setShowToDoModal } =
    useContext(UserContext);
  const [state, setState] = useState({});

  const handleClose = () => {
    setShowToDoModal(false);
    setState({});
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!state?.title || !state?.task) {
      return;
    }
    createTodoItem(state);
    handleClose();
  };

  return (
    <Modal show={showToDoModal} onHide={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton className="bg-white dark__bg-1100 sticky-top">
          <Modal.Title>Nueva tarea</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Título</Form.Label>
          <Form.Control
            value={state?.title}
            onChange={({ target }) =>
              setState(state => ({ ...state, title: target.value }))
            }
            placeholder=""
          />
          <Form.Label className="mt-3">Tarea</Form.Label>
          <Form.Control
            as="textarea"
            className="resize-none"
            value={state?.task}
            rows={3}
            onChange={({ target }) =>
              setState(state => ({ ...state, task: target.value }))
            }
            placeholder=""
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!state?.title?.trim() || !state?.task?.trim()}
            size="sm"
            type="submit"
          >
            <span className="d-none d-sm-inline-block ms-1">Guardar tarea</span>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

let blurDate;
const UserProvider = ({ children }) => {
  const thisYear = new Date().getFullYear();
  const reactLocation = useLocation();
  const { pathname, state: stateProp } = reactLocation;
  const { from = '/' } = stateProp || {};
  const navigate = useNavigate();
  const auth = useAuth();
  const db = useFirestore();
  const { ip } = useContext(AppContext);
  const storage = useStorage();
  const [isEditing, setEditing] = useState(false);
  const [selectedLocationId, selectLocationId] = useState();
  const [isOpenHolidaysModal, setIsOpenHolidaysModal] = useState(false);
  const [isWidgetsModalOpened, openWidgetsModal] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState({});
  const [companyId, setCompanyId] = useState();
  const [state, setState] = useState({});
  const [selectedUser, selectUser] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showToDoModal, setShowToDoModal] = useState(false);
  const [users, setUsers] = useState([]);
  let {
    authUser,
    avatar = '',
    companies,
    locations,
    isSignedOut,
    myCompanyAvatar = '',
    partners
  } = state;

  const defaultsRef = query(
    collection(db, 'defaults'),
    where('current', '==', true),
    limit(1)
  );
  const { data: defaultsRaw = [] } = useFirestoreCollectionData(defaultsRef);
  let defaults = defaultsRaw?.[0];
  defaults = {
    ...defaults,
    happy_questionaire: {
      ...defaults?.happy_questionaire,
      definitions:
        defaults?.happy_questionaire?.definitions?.map((definition, index) => {
          return {
            ...definition,
            extra: {
              min: {
                title: defaults?.happy_questionaire?.definitions?.[index]?.[0],
                value: 0
              },
              max: {
                title: defaults?.happy_questionaire?.definitions?.[index]?.[10],
                value: 10
              }
            }
          };
        }) || []
    }
  };
  let companyRef = doc(db, 'none', 'none');
  let myRef = doc(db, 'none', 'none');
  let myCompanyRef = doc(db, 'none', 'none');
  let partnersQuery = query(collection(db, 'none'));
  let companiesQuery = query(collection(db, 'none'));
  let departmentsQuery = query(collection(db, 'none'));
  let locationsQuery = query(collection(db, 'none'));
  let notesQuery = query(collection(db, 'none'));
  let todoListQuery = query(collection(db, 'none'));
  let schedulesQuery = query(collection(db, 'none'));
  if (authUser?.uid) {
    myRef = doc(db, 'users', authUser?.uid);
  }
  let { data: me, status: myStatus } = useFirestoreDocData(myRef);
  if (me?.company) {
    myCompanyRef = doc(db, 'companies', me?.company);
    companiesQuery = query(
      collection(db, 'companies'),
      where('parentCompanyId', '==', me?.company),
      orderBy('name', 'asc')
    );
  }
  let { data: myCompany } = useFirestoreDocData(myCompanyRef);

  if (companyId) {
    companyRef = doc(db, 'companies', companyId);
    departmentsQuery = query(
      collection(db, 'companies', companyId, 'departments'),
      orderBy('name', 'asc')
    );
    locationsQuery = query(
      collection(db, 'companies', companyId, 'locations'),
      orderBy('name', 'asc')
    );
    schedulesQuery = query(
      collection(db, 'companies', companyId, 'schedules'),
      orderBy('name', 'asc')
    );
    todoListQuery = query(
      collection(db, 'companies', companyId, 'todoList'),
      orderBy('createdAt', 'desc')
    );
  }
  if (selectedUser) {
    notesQuery = query(
      collection(db, 'users', selectedUser?.NO_ID_FIELD, 'notes'),
      orderBy('createdAt', 'asc')
    );
  }

  let { data: company } = useFirestoreDocData(companyRef);
  const { data: departments = [] } =
    useFirestoreCollectionData(departmentsQuery);
  const { data: _locations = [] } = useFirestoreCollectionData(locationsQuery);
  const { data: notes = [] } = useFirestoreCollectionData(notesQuery);
  const { data: schedulesData = [] } =
    useFirestoreCollectionData(schedulesQuery);
  const { data: todoList = [] } = useFirestoreCollectionData(todoListQuery);

  const schedules = schedulesData?.map(schedule => {
    const { periods } = schedule;
    const yearPeriods = periods.map(period => {
      return {
        ...period,
        start: period?.start?.replace?.(/\d{4}/, thisYear),
        end: period?.end?.replace?.(/\d{4}/, thisYear)
      };
    });
    return { ...schedule, periods: yearPeriods };
  });

  if (company?.NO_ID_FIELD && me?.NO_ID_FIELD) {
    partnersQuery = query(
      collection(db, 'users'),
      where('company', '==', company?.NO_ID_FIELD),
      where('id', '!=', me?.NO_ID_FIELD)
    );
  }
  const { data: companiesRaw = [] } =
    useFirestoreCollectionData(companiesQuery);
  const { data: partnersRaw = [] } = useFirestoreCollectionData(partnersQuery);

  const init = useCallback(async () => {
    try {
      const happybotDoc = await getDoc(
        doc(collection(db, 'users'), 'HAPPYBOT')
      );
      const happybot = {
        NO_ID_FIELD: happybotDoc.id,
        id: happybotDoc.id,
        ref: happybotDoc.ref,
        ...(await happybotDoc.data()),
        avatar: await getDownloadURL(
          ref(storage, `/users/HAPPYBOT/avatar.jpeg`)
        )
      };
      setState(state => ({ ...state, happybot }));
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getUser = async userId => {
    let user = partners.find(({ NO_ID_FIELD }) => NO_ID_FIELD === userId);
    if (!user) {
      user = users.find(({ NO_ID_FIELD }) => NO_ID_FIELD === userId);
    }
    if (!user) {
      const userDoc = await getDoc(doc(db, 'users', userId));
      const userData = await userDoc.data();
      let avatar = null;
      try {
        avatar = await getDownloadURL(
          ref(storage, `/users/${userId}/avatar.jpeg`)
        );
      } catch (error) {
        console.error(error);
      }
      user = { ...userData, avatar, NO_ID_FIELD: userId };
      setUsers([...users, user]);
    }
    return user;
  };

  const setUser = useCallback(async authUser => {
    if (!authUser) {
      signOut();
    }
    setState(state => ({
      ...state,
      authUser,
      loading: !state?.subscription
    }));
  }, []);

  const resetPassword = useCallback(async email => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const socialSignIn = async providerId => {
    let data = {};
    const provider = getProvider(providerId);
    providerId = provider?.providerId;

    try {
      if (authUser?.uid) {
        data = await linkWithPopup(auth.currentUser, provider);
      } else {
        data = await signInWithPopup(auth, provider);
      }

      const { user } = data;
      try {
        Cookies.set('__fbu', JSON.stringify(user), { expires: 14 });
      } catch (error) {
        console.error(error);
      }
      setUser(user);
      navigate(from);
    } catch (error) {
      console.error(error);

      if (
        error.customData?._tokenResponse &&
        error.code === 'auth/credential-already-in-use'
      ) {
        try {
          await unlink(auth.currentUser, providerId);
        } catch (error) {
          console.error(error);
        }
        if (error?.customData?.email !== auth?.currentUser?.email) {
          const data = await linkWithRedirect(auth, provider);
          const { user } = data;
          try {
            Cookies.set('__fbu', JSON.stringify(user), { expires: 14 });
          } catch (error) {
            console.error(error);
          }
          setUser(user);
          navigate(from);
        }
      }
      if (
        error.customData?._tokenResponse &&
        error.code === 'auth/account-exists-with-different-credential'
      ) {
        const { email } = error.customData;
        if (state?.user?.uid) {
          provider.setCustomParameters({ login_hint: email });
          const result = await linkWithRedirect(auth.currentUser, provider);
          return result;
        }
        const data = await linkWithRedirect(auth, provider);
        const { user } = data;
        try {
          Cookies.set('__fbu', JSON.stringify(user), { expires: 14 });
        } catch (error) {
          console.error(error);
        }
        setUser(user);
        navigate(from);
      }
    }
  };

  const linkSocialSignIn = async providerId => {
    try {
      const provider = getProvider(providerId);
      await linkWithPopup(auth.currentUser, provider);
    } catch (error) {
      console.error(error);
    }
  };

  const unlinkSocialSignIn = async providerId => {
    try {
      await unlink(auth.currentUser, providerId);
    } catch (error) {
      console.error(error);
    }
  };

  const register = useCallback(
    async (
      email,
      password,
      { name, redirect = true, showToast = true } = {}
    ) => {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const { user } = userCredential;
        name &&
          (await updateProfile(auth.currentUser, {
            displayName: name
          }));
        try {
          Cookies.set('__fbu', JSON.stringify(user));
        } catch (error) {
          console.error(error);
        }
        showToast &&
          toast.success(`Successfully registered as ${name}`, {
            theme: 'colored'
          });
        redirect && navigate(from);
        return true;
      } catch (error) {
        if (error.code === 'auth/email-already-in-use') {
          toast.error(`Email en uso`, {
            theme: 'colored'
          });
        }
      }
    },
    []
  );

  const signIn = useCallback(async (email, password, options = {}) => {
    setState({ loading: true });
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const { user } = userCredential;
      try {
        Cookies.set('__fbu', JSON.stringify(user), options);
      } catch (error) {
        console.error(error);
      }
      navigate(from);
    } catch (error) {
      if (['auth/too-many-requests'].includes(error.code)) {
        toast.error(
          `El acceso a esta cuenta se ha deshabilitado temporalmente debido a que has hecho demasiados intentos fallidos. Puedes restablecerla reseteando la contraseña o puedes intentarlo más tarde`,
          {
            theme: 'colored'
          }
        );
      }
      if (
        [
          'auth/invalid-login-credentials',
          'auth/invalid-email',
          'auth/wrong-password',
          'auth/user-not-found'
        ].includes(error.code)
      ) {
        toast.error(`Email y/o contraseña incorrectos`, {
          theme: 'colored'
        });
      }
      console.error(error);
    }
  }, []);

  const signInWithEmail = useCallback(async (email, password, options = {}) => {
    setState({ loading: true });
    try {
      const userCredential = await signInWithEmailLink(
        auth,
        email,
        window.location.href
      );
      const { user } = userCredential;
      await updatePassword(user, password);
      try {
        Cookies.set('__fbu', JSON.stringify(user), options);
      } catch (error) {
        console.error(error);
      }
      navigate(from);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const signOut = useCallback(async () => {
    await firebaseSignOut(auth);
    Cookies.remove('__fbu');
    isSignedOut && navigate('/authentication/login');
    setState({ isSignedOut: true });
  }, []);

  const refreshAvatar = async () => {
    try {
      const avatar = await getDownloadURL(
        ref(storage, `/users/${me?.NO_ID_FIELD}/avatar.jpeg`)
      );
      setState(state => ({ ...state, avatar }));
    } catch (error) {
      console.error(error);
    }
  };

  const refreshCompanyAvatar = async () => {
    try {
      const avatar = await getDownloadURL(
        ref(storage, `/companies/${company?.NO_ID_FIELD}/logo.png`)
      );
      const _companies = companies.map(c =>
        c?.NO_ID_FIELD === company?.NO_ID_FIELD ? { ...company, avatar } : c
      );
      setState(state => ({ ...state, companies: _companies }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleMeSnapshot = async me => {
    if (!me?.NO_ID_FIELD) {
      return;
    }
    try {
      await updateDoc(doc(db, 'users', me?.NO_ID_FIELD), {
        status: 'online'
      });
      const _avatar =
        avatar ||
        (await getDownloadURL(
          ref(storage, `/users/${me?.NO_ID_FIELD}/avatar.jpeg`)
        ));

      setState(state => {
        return {
          ...state,
          avatar: _avatar
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleMyCompanySnapshot = async company => {
    if (!company?.NO_ID_FIELD) {
      return;
    }
    try {
      const avatar =
        myCompanyAvatar ||
        (await getDownloadURL(
          ref(storage, `/companies/${company?.NO_ID_FIELD}/logo.png`)
        ));

      setState(state => {
        return {
          ...state,
          myCompanyAvatar: avatar
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCompaniesSnapshot = async companiesRaw => {
    const _companies = await Promise.all(
      companiesRaw?.map(async data => {
        const currentCompany = companies?.find(
          ({ NO_ID_FIELD }) => NO_ID_FIELD === data.NO_ID_FIELD
        );
        try {
          const avatar =
            currentCompany?.avatar ||
            (await getDownloadURL(
              ref(storage, `/companies/${data?.NO_ID_FIELD}/logo.png`)
            ));

          return {
            ...data,
            avatar,
            ref: currentCompany?.ref || doc(db, 'companies', data?.NO_ID_FIELD)
          };
        } catch (error) {
          // console.error(error);
          return {
            ...data,
            avatar: '',
            ref: currentCompany?.ref || doc(db, 'companies', data?.NO_ID_FIELD)
          };
        }
      }) || []
    );
    !equal(_companies, companies) &&
      setState(state => ({ ...state, companies: _companies }));
  };

  const handlePartnersSnapshot = async partnersRaw => {
    const _partners = await Promise.all(
      partnersRaw?.map(async data => {
        const currentPartner = partners?.find(({ id }) => id === doc.id);
        try {
          const avatar =
            currentPartner?.avatar ||
            data?.avatar?.base64 ||
            (await getDownloadURL(
              ref(storage, `/users/${data?.id}/avatar.jpeg`)
            ));
          return {
            id: data.id,
            ...data,
            avatar,
            ref: currentPartner?.ref || doc(db, 'users', data?.NO_ID_FIELD)
          };
        } catch (error) {
          //console.error(error);
          return {
            id: data.id,
            ...data,
            avatar: '',
            ref: currentPartner?.ref || doc(db, 'users', data?.NO_ID_FIELD)
          };
        }
      }) || []
    );
    !equal(_partners, partners) &&
      setState(state => ({ ...state, partners: _partners }));
  };

  const createCompany = async params => {
    const { email, name, parentCompanyId } = params;
    if (!email || !name || !parentCompanyId) {
      return;
    }
    const updatedAt = new Date().toISOString();
    return await addDoc(collection(db, 'companies'), { ...params, updatedAt });
  };

  const updateCompany = async (company, params) => {
    const updatedAt = new Date().toISOString();
    const companyRef = company?.NO_ID_FIELD
      ? await updateDoc(doc(db, 'companies', company?.NO_ID_FIELD), {
          updatedAt,
          ...params
        })
      : await createCompany({ ...params, updatedAt });
    return companyRef;
  };

  const updateUser = async (user, params) => {
    if (!user) {
      return;
    }
    const updatedAt = new Date().toISOString();
    const userRef = user?.NO_ID_FIELD
      ? await updateDoc(doc(db, 'users', user?.NO_ID_FIELD), {
          updatedAt,
          ...params
        })
      : await setDoc(doc(db, 'users', authUser?.uid), { ...params, updatedAt });
    return userRef;
  };

  const getSubscription = async () => {
    try {
      if (!company?.email) {
        throw new Error();
      }
      const { subscriptions } = await (
        await fetch(
          `${process.env.REACT_APP_FIREBASE_URL}/getSubscriptions?email=${company?.email}`,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
      ).json();
      const subscription = subscriptions?.find(({ status }) =>
        ['active', 'canceled', 'trialing', 'past_due'].includes(status)
      );
      setState(state => ({ ...state, loading: false, subscription }));
    } catch (error) {
      setState(state => ({ ...state, loading: false, subscription: null }));
    }
  };

  const createDepartment = async department => {
    await addDoc(
      collection(db, 'companies', company?.NO_ID_FIELD, 'departments'),
      {
        name: department
      }
    );
  };

  const deleteDepartment = async departmentId => {
    try {
      await deleteDoc(
        doc(db, 'companies', company?.NO_ID_FIELD, 'departments', departmentId)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const createLocation = async location => {
    await addDoc(
      collection(db, 'companies', company?.NO_ID_FIELD, 'locations'),
      location
    );
  };

  const updateLocation = async (location, params) => {
    try {
      await updateDoc(
        doc(
          db,
          'companies',
          company?.NO_ID_FIELD,
          'locations',
          location?.NO_ID_FIELD
        ),
        params
      );
    } catch (error) {
      console.error(error);
    }
  };

  const deleteLocation = async location => {
    try {
      await deleteDoc(
        doc(
          db,
          'companies',
          company?.NO_ID_FIELD,
          'locations',
          location?.NO_ID_FIELD
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const createNote = async (userId, params) => {
    if (!userId) {
      return;
    }
    const data = {
      createdAt: new Date(Date.now()).toISOString(),
      from: me?.ref,
      ...params
    };
    await addDoc(collection(db, 'users', userId, 'notes'), data);
  };

  const updateNote = async (userId, noteId, params) => {
    try {
      await updateDoc(doc(db, 'users', userId, 'notes', noteId), params);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteNote = async (userId, noteId) => {
    try {
      await deleteDoc(doc(db, 'users', userId, 'notes', noteId));
    } catch (error) {
      console.error(error);
    }
  };

  const createSchedule = async schedule => {
    await addDoc(
      collection(db, 'companies', company?.NO_ID_FIELD, 'schedules'),
      schedule
    );
  };

  const updateSchedule = async (schedule, params) => {
    try {
      await updateDoc(
        doc(
          db,
          'companies',
          company?.NO_ID_FIELD,
          'schedules',
          schedule?.NO_ID_FIELD
        ),
        params
      );
    } catch (error) {
      console.error(error);
    }
  };

  const deleteSchedule = async schedule => {
    try {
      await deleteDoc(
        doc(
          db,
          'companies',
          company?.NO_ID_FIELD,
          'schedules',
          schedule?.NO_ID_FIELD
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const createTodoItem = async todoItem => {
    const data = {
      createdAt: new Date().toISOString(),
      completed: false,
      ...todoItem
    };
    await addDoc(
      collection(db, 'companies', company?.NO_ID_FIELD, 'todoList'),
      data
    );
  };

  const updateTodoItem = async (todoItem, params) => {
    try {
      await updateDoc(
        doc(
          db,
          'companies',
          company?.NO_ID_FIELD,
          'todoList',
          todoItem?.NO_ID_FIELD
        ),
        params
      );
    } catch (error) {
      console.error(error);
    }
  };

  const deleteTodoItem = async todoItem => {
    try {
      await deleteDoc(
        doc(
          db,
          'companies',
          company?.NO_ID_FIELD,
          'todoList',
          todoItem?.NO_ID_FIELD
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!me?.NO_ID_FIELD) {
      return;
    }
    (async () => {
      await handleMeSnapshot(me);
      if (!me?.hmac) {
        try {
          await fetch(`${process.env.REACT_APP_FIREBASE_URL}/createHmac`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              userId: me?.NO_ID_FIELD
            })
          });
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, [me?.NO_ID_FIELD]);

  useEffect(() => {
    if (!me?.hmac) {
      return;
    }
    const { customer, plan, quantity, status } = state?.subscription || {};
    const { amount = 0, interval } = plan || {};
    window.intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'uksbh9ja',
      name: me.name,
      company_id: me.company,
      company_name: me.company_name,
      company_size: (partners?.length || 0) + 1,
      user_id: me.id,
      email: me.email,
      phone: me.phone,
      level: me.level,
      type: me.type,
      user_hash: me.hmac,
      stripe_id: customer,
      stripe_plan: plan?.product?.name,
      stripe_plan_price: amount / 100,
      stripe_plan_interval: interval,
      stripe_subscription_status: status,
      subscription_quantity: quantity
    };
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    }
  }, [me?.hmac, partners?.length, state?.subscription]);

  useEffect(() => {
    handleMyCompanySnapshot(myCompany);
  }, [myCompany?.NO_ID_FIELD]);

  useEffect(() => {
    handlePartnersSnapshot(partnersRaw);
  }, [partnersRaw]);

  useEffect(() => {
    handleCompaniesSnapshot(companiesRaw);
  }, [companiesRaw]);

  useEffect(() => {
    getSubscription();
  }, [company?.NO_ID_FIELD]);

  useEffect(() => {
    setCompanyId(myCompany.NO_ID_FIELD);
  }, [myCompany?.NO_ID_FIELD]);

  const handleWindowFocus = () => {
    if (!me?.NO_ID_FIELD) {
      return;
    }
    const minutesDiff = dayjs().diff(blurDate, 'minutes');
    if (blurDate && minutesDiff > 60) {
      window.location = '/';
      return;
    }
    updateDoc(doc(db, 'users', me?.NO_ID_FIELD), {
      status: 'online'
    });
  };

  const handleWindowBlur = () => {
    if (!me?.NO_ID_FIELD) {
      return;
    }
    blurDate = new Date();
    updateDoc(doc(db, 'users', me?.NO_ID_FIELD), {
      status: 'offline'
    });
  };

  const handleWindowClose = async () => {
    if (!me?.NO_ID_FIELD) {
      return;
    }
    updateDoc(doc(db, 'users', me?.NO_ID_FIELD), {
      status: 'offline'
    });
  };

  const resetUnreadMessages = useCallback(
    async chat => {
      if (!me?.NO_ID_FIELD) {
        return;
      }
      let { unreadMessages } = me;
      unreadMessages = unreadMessages?.filter(
        ({ ref }) => ref?.path !== chat?.ref?.path
      );
      unreadMessages &&
        (await updateDoc(myRef, {
          unreadMessages
        }));
    },
    [me?.NO_ID_FIELD]
  );

  useEffect(() => {
    window.addEventListener('focus', handleWindowFocus);
    window.addEventListener('blur', handleWindowBlur);
    window.addEventListener('beforeunload', handleWindowClose);
    return () => {
      window.removeEventListener('focus', handleWindowFocus);
      window.removeEventListener('blur', handleWindowBlur);
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [me?.NO_ID_FIELD]);

  useEffect(() => {
    if (
      isRegistered ||
      !company?.NO_ID_FIELD ||
      !me?.NO_ID_FIELD ||
      !ip ||
      !version
    ) {
      return;
    }
    isRegistered = true;
    fetch(`${process.env.REACT_APP_FIREBASE_URL}/registerActivity`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        companyId: company?.NO_ID_FIELD,
        createdAt: new Date(Date.now()).toISOString(),
        web_info: {
          userAgent: window.navigator?.userAgent,
          mobile: window.navigator?.userAgentData?.mobile || isMobile(),
          platform:
            window.navigator?.userAgentData?.platform ||
            window.navigator?.platform,
          vendor: window.navigator?.vendor,
          version
        },
        lang: window.navigator.language,
        ip,
        userId: me?.NO_ID_FIELD
      })
    });
  }, [company, ip, me, version]);

  useEffect(() => {
    (async () => {
      let locations = await Promise.all(
        _locations.map(async location => {
          const stateLocation = state?.locations?.find(
            ({ NO_ID_FIELD }) => NO_ID_FIELD === location.NO_ID_FIELD
          );
          if (stateLocation?.holidays?.some(({ kind }) => kind)) {
            return stateLocation;
          }
          const { country = 'Spain', administrativeAreaLevel1 } = location;
          const localHolidays = location?.holidays
            ?.map(data => ({
              ...data,
              start: dayjs(data.start).format('YYYY-MM-DD')
            }))
            .sort((h1, h2) => (h1.start < h2.start ? -1 : 1));
          const workingHolidays = localHolidays?.filter(
            ({ working }) => working
          );
          let items = [];

          const calendar = await fetch(
            `https://www.googleapis.com/calendar/v3/calendars/es.${country.toLowerCase()}%23holiday@group.v.calendar.google.com/events?key=${
              process.env.REACT_APP_GOOGLE_API_KEY
            }&timeMin=${thisYear - 1}-01-01T00:00:00.000Z&timeMax=${
              thisYear + 1
            }-12-31T00:00:00.000Z`
          );
          const json = (await calendar.json()) || {};
          items = [...(json?.items || [])];
          let { nextPageToken } = json;
          while (nextPageToken) {
            const calendar = await fetch(
              `https://www.googleapis.com/calendar/v3/calendars/es.${country.toLowerCase()}%23holiday@group.v.calendar.google.com/events?key=${
                process.env.REACT_APP_GOOGLE_API_KEY
              }&timeMin=${thisYear - 1}-01-01T00:00:00.000Z&timeMax=${
                thisYear + 1
              }-12-31T00:00:00.000Z&pageToken=${json.nextPageToken}`
            );
            const nextJson = (await calendar.json()) || {};
            items = [...items, ...(nextJson?.items || [])];
            nextPageToken = nextJson.nextPageToken;
          }

          items =
            items?.map(data => ({
              ...data,
              start: dayjs(data.start.date).format('YYYY-MM-DD'),
              end: dayjs(data.start.date).format('YYYY-MM-DD'),
              title: data.summary
            })) || [];
          let holidays = getUnique(
            [...(items || []), ...(localHolidays || [])]?.filter(data => {
              const isWorkingHoliday = workingHolidays?.some(
                ({ start }) => start === data.start
              );
              return (
                !isWorkingHoliday &&
                (!data?.kind ||
                  data?.description?.toLowerCase().includes('día festivo') ||
                  administrativeAreaLevel1
                    ?.split(' ')
                    ?.some(word =>
                      data?.description
                        ?.toLowerCase()
                        .includes(word?.toLowerCase())
                    ))
              );
            }),
            'start'
          );
          return { ...location, holidays };
        })
      );
      if (
        JSON.stringify(
          _locations?.map(({ holidays }) =>
            holidays?.sort((h1, h2) => (h1.start < h2.start ? -1 : 1))
          )
        ) !==
        JSON.stringify(
          state?.locations?.map(({ holidays }) =>
            holidays?.filter(({ kind }) => !kind)
          )
        )
      ) {
        locations = locations.map(location => {
          const { NO_ID_FIELD, holidays: allHolidays } = location;
          const { holidays: manualHolidays = [] } =
            _locations.find(
              _location => _location.NO_ID_FIELD === NO_ID_FIELD
            ) || {};
          const holidays = getUnique(
            [
              ...allHolidays.filter(({ kind }) => kind),
              ...manualHolidays.filter(({ working }) => !working)
            ],
            'start'
          )?.sort((h1, h2) => (h1.start < h2.start ? -1 : 1));
          return { ...location, holidays, manualHolidays };
        });
      }
      setState(state => ({ ...state, locations }));
    })();
  }, [
    JSON.stringify(_locations?.map(({ NO_ID_FIELD }) => NO_ID_FIELD)),
    JSON.stringify(_locations?.map(({ holidays }) => holidays))
  ]);

  useEffect(() => {
    !isOpenHolidaysModal && selectLocationId();
  }, [isOpenHolidaysModal]);

  useEffect(() => {
    init();
    const unsubscribe = onAuthStateChanged(auth, setUser);
    return unsubscribe;
  }, []);

  if (
    !isSignedOut &&
    pathname !== '/welcome' &&
    authUser?.uid &&
    myStatus === 'success' &&
    !me?.NO_ID_FIELD
  ) {
    getDoc(myRef).then(doc => {
      if (!doc.exists()) {
        navigate('/welcome');
      }
    });
  }
  if (
    me?.NO_ID_FIELD &&
    me?.type !== 'admin' &&
    pathname !== '/welcome' &&
    !me?.lastQuestionnaireAt
    // (isNaN(parseInt(me?.happiness)) || isNaN(parseInt(me?.stress)))
  ) {
    const json = { userId: me?.NO_ID_FIELD };
    const params = btoa(JSON.stringify(json));
    const link = `/hq/${params}`;
    navigate(link);
  }

  const location =
    locations?.find(({ NO_ID_FIELD }) => NO_ID_FIELD === me?.locationId) ||
    locations?.[0] ||
    {};
  me = me && {
    ...me,
    location,
    ref: myRef,
    avatar: avatar || authUser?.photoURL
  };
  partners = partners?.map(partner => {
    const location =
      locations?.find(
        ({ NO_ID_FIELD }) => NO_ID_FIELD === partner?.locationId
      ) ||
      locations?.[0] ||
      {};
    return { ...partner, location };
  });
  myCompany = {
    ...myCompany,
    ref: myCompanyRef,
    avatar: myCompanyAvatar
  };
  companies = getUnique(
    [...(myCompany?.NO_ID_FIELD ? [myCompany] : []), ...(companies || [])],
    'NO_ID_FIELD'
  );
  company = {
    ...companies.find(
      ({ NO_ID_FIELD }) => NO_ID_FIELD === company?.NO_ID_FIELD
    ),
    ...company
  };

  // console.log('USER >>>', {
  //   authUser,
  //   me,
  //   myCompany,
  //   company,
  //   companies,
  //   departments,
  //   partners,
  //   state
  // });

  return (
    <UserContext.Provider
      value={{
        ...state,
        defaults,
        me,
        myCompany,
        company,
        setCompanyId,
        companies,
        createDepartment,
        deleteDepartment,
        departments,
        createLocation,
        updateLocation,
        deleteLocation,
        createNote,
        updateNote,
        deleteNote,
        notes,
        createSchedule,
        updateSchedule,
        deleteSchedule,
        schedules,
        createTodoItem,
        updateTodoItem,
        deleteTodoItem,
        todoList,
        upgradeModal,
        createCompany,
        updateCompany,
        updateUser,
        refreshAvatar,
        refreshCompanyAvatar,
        setUpgradeModal,
        getUser,
        getSubscription,
        partners: [
          ...(me && company?.NO_ID_FIELD === me?.company ? [me] : []),
          ...(partners || [])
        ],
        resetPassword,
        resetUnreadMessages,
        register,
        selectedLocationId,
        selectLocationId,
        isOpenHolidaysModal,
        setIsOpenHolidaysModal,
        signIn,
        signInWithEmail,
        signOut,
        socialSignIn,
        linkSocialSignIn,
        unlinkSocialSignIn,
        isEditing,
        setEditing,
        isWidgetsModalOpened,
        openWidgetsModal,
        selectedUser,
        selectUser,
        showNotesModal,
        setShowNotesModal,
        showToDoModal,
        setShowToDoModal
      }}
    >
      <HolidaysModal
        isOpen={isOpenHolidaysModal}
        setIsOpen={setIsOpenHolidaysModal}
      />
      <NoteModal />
      <ToDoModal />
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default UserProvider;
