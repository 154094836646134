import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { ReportsContext } from 'context/Context';
import RankingPodium from './RankingPodium';
import RankingUserList from './RankingUserList';
import { groupBy } from 'helpers/utils';

const RankingEvents = () => {
  const { department: departmentProp } = useParams();
  const { company, partners = [], events } = useContext(ReportsContext);
  const { apps = [] } = company || {};

  if (!apps.includes('objectives')) {
    return null;
  }

  const eventsByUser = Object.entries(groupBy(events, 'userPath'))
    .map(([path, data]) => ({
      count: data?.length,
      user: partners?.find(({ ref }) => ref?.path === path)
    }))
    .filter(
      ({ user }) =>
        (!departmentProp && user) || user?.department === departmentProp
    )
    .sort((u1, u2) => (u1.count > u2.count ? -1 : 1));

  return (
    <>
      <Row className="g-3 mb-3">
        <RankingPodium data={eventsByUser} unit="asistencia(s) a eventos" />
        <RankingUserList data={eventsByUser} unit="asistencia(s) a eventos" />
      </Row>
    </>
  );
};

export default RankingEvents;
