import React, { useContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';
import { QuestionairesContext } from 'context/Context';
import { questionaireReducer } from 'reducers/questionaireReducer';
import { UserContext } from 'context/Context.js';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  updateDoc,
  where
} from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

const QuestionaireProvider = ({ children }) => {
  const { company } = useContext(UserContext);
  const db = useFirestore();
  const [month, setMonth] = useState(
    new Date().toISOString().match(/\d+-\d+/)[0]
  );
  const endMonth = new Date(`${month}-10`);
  new Date(endMonth.setMonth(endMonth.getMonth() + 1));

  let happyResponseQuery = query(collection(db, 'none'));
  let questionaireQuery = query(collection(db, 'none'));
  const templatesQuery = query(collection(db, 'questionaires_templates'));
  if (company?.NO_ID_FIELD) {
    happyResponseQuery = query(
      collection(db, 'form_responses'),
      where('companyId', '==', company?.NO_ID_FIELD),
      where('createdAt', '>', month),
      where('createdAt', '<', endMonth.toISOString().match(/\d+-\d+/)[0]),
      orderBy('createdAt', 'desc')
    );
    questionaireQuery = query(
      collection(db, 'questionaires'),
      where('companyId', '==', `${company?.NO_ID_FIELD}`)
    );
  }
  const { data: happyResponses = [] } =
    useFirestoreCollectionData(happyResponseQuery);
  let { data: raw = [] } = useFirestoreCollectionData(questionaireQuery);
  let { data: templates = [] } = useFirestoreCollectionData(templatesQuery);

  const [questionaireState, questionaireDispatch] = useReducer(
    questionaireReducer,
    {
      questionaires: raw,
      allQuestionaires: raw,
      filters: [
        { label: 'Todos', value: 'all' },
        { label: 'Favoritos', value: 'star' },
        { label: 'Archivados', value: 'archived' }
      ],
      currentFilter: 'all'
    }
  );

  const create = async params => {
    try {
      const createdAt = new Date(Date.now()).toISOString();
      const data = {
        companyId: company?.NO_ID_FIELD,
        createdAt,
        ...params
      };
      await addDoc(collection(db, 'questionaires'), data);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteQuestionaire = async id => {
    try {
      await deleteDoc(doc(db, 'questionaires', id));
    } catch (error) {
      console.error(error);
    }
  };

  const update = async (NO_ID_FIELD, params) => {
    try {
      const ref = doc(db, 'questionaires', NO_ID_FIELD);
      await updateDoc(ref, params);
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   company?.NO_ID_FIELD &&
  //     questionaireDispatch({ type: 'RESET_QUESTIONAIRES', payload: [] });
  // }, [company?.NO_ID_FIELD]);

  useEffect(() => {
    if (equal(raw, questionaireState?.allQuestionaires)) {
      return;
    }
    questionaireDispatch({ type: 'UPDATE', payload: raw });
  }, [raw]);

  return (
    <QuestionairesContext.Provider
      value={{
        questionaireState,
        questionaireDispatch,
        create,
        update,
        deleteQuestionaire,
        happyResponses,
        month,
        setMonth,
        templates
      }}
    >
      {children}
    </QuestionairesContext.Provider>
  );
};

QuestionaireProvider.propTypes = { children: PropTypes.node };

export default QuestionaireProvider;
