import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Background from 'components/common/Background';
import corner1 from 'assets/img/illustrations/corner-1.png';
import { UserContext } from 'context/Context';

const QuestionaireIntro = ({ questionaire }) => {
  const { me } = useContext(UserContext);
  const { title, description } = questionaire;
  const text = title?.replace(/{{([^}]+)}}/g, (s, key) => me?.[key]);

  return (
    <Card className="mb-3 py-2">
      <Background image={corner1} className="p-x1 bg-card" />
      <Card.Header className="pb-0">
        <h3 className="mb-0">{text}</h3>
      </Card.Header>
      <Card.Body>
        <span>{description}</span>
      </Card.Body>
    </Card>
  );
};

QuestionaireIntro.propTypes = {
  questionaire: PropTypes.object
};

export default QuestionaireIntro;
