import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form } from 'react-bootstrap';
import { collection, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import BubbleChart from 'components/common/BubbleChart';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import { getColor, groupBy } from 'helpers/utils';

dayjs.locale('es');
registerLocale('es', es);
setDefaultLocale('es');

const articles = ['el', 'la', 'los', 'las', 'un', 'una', 'unos', 'unas'];
const conjunctions = ['i', 'y', 'o', 'pero', 'que', 'porque', 'si', 'cuando'];
const prepositions = [
  'a',
  'ante',
  'bajo',
  'cabe',
  'con',
  'contra',
  'de',
  'desde',
  'durante',
  'en',
  'entre',
  'hacia',
  'hasta',
  'mediante',
  'para',
  'por',
  'según',
  'sin',
  'so',
  'sobre',
  'tras',
  'versus',
  'vía'
];
const pronouns = [
  'yo',
  'tú',
  'él',
  'nosotros',
  'ustedes',
  'ellos',
  'que',
  'quien',
  'me',
  'mi',
  'su'
];
const other = [
  ' ',
  '-',
  '.',
  ',',
  '(',
  ')',
  'as',
  'be',
  'del',
  'es',
  'https://www',
  'esa',
  'ese',
  'eso',
  'esta',
  'este',
  'esto',
  'etc',
  'mas',
  'más',
  'muy',
  'nada',
  'no',
  'nos',
  'per',
  'se',
  'si',
  'ya'
];
const wordBlackList = [
  ...articles,
  ...conjunctions,
  ...pronouns,
  ...prepositions,
  ...other
];

let userAction = false;
const FeedbackTrendingWords = React.memo(
  ({ className, company: companyProp, header }) => {
    const { company: companyContext } = useContext(UserContext);
    const company = companyProp || companyContext;
    const [minMatches, setMinMatches] = useState(2);
    const db = useFirestore();
    const defaultFromDate = new Date(
      new Date().setMonth(new Date().getMonth() - 1)
    )
      .toISOString()
      .split(':')[0];
    const [fromDateValue, setFromDateValue] = useState(1);
    const [fromDate, setFromDate] = useState(defaultFromDate);
    const toDate = new Date(new Date().setDate(new Date().getDate() + 1))
      .toISOString()
      .split(':')[0];
    const filters = [
      where('companyId', '==', `${company?.NO_ID_FIELD}`),
      where('createdAt', '>', fromDate),
      where('createdAt', '<=', toDate)
    ].filter(filter => filter);
    let feedbackQuery = query(collection(db, 'none'));
    if (company?.NO_ID_FIELD) {
      feedbackQuery = query(collection(db, 'feedback'), ...filters);
    }

    let { data: raw = [] } = useFirestoreCollectionData(feedbackQuery);
    const wordRepetitions = [];
    const data = [
      ...Object.entries(
        raw
          .filter(({ feedback }) => feedback)
          .reduce((total, entry) => {
            const { feedback } = entry;
            const words = `${`${feedback}`.replace(
              /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/g,
              ''
            )}`
              .toLowerCase()
              .replace(/[.,]/g, ' ')
              .split(' ')
              .filter(raw => {
                let word =
                  raw.replace(/^[^A-zÀ-ú]*/g, '').replace(/[^A-zÀ-ú]*$/g, '') ||
                  raw;
                word = word.match(/[A-zÀ-ú]/) ? word : '';
                return (
                  word &&
                  word.length >= minMatches &&
                  word.length < 23 &&
                  !wordBlackList.includes(word.toLowerCase())
                );
              })
              .map(raw => {
                const word =
                  raw.replace(/^[^A-zÀ-ú]*/g, '').replace(/[^A-zÀ-ú]*$/g, '') ||
                  raw;
                wordRepetitions[word.toLowerCase()] =
                  (wordRepetitions?.[word.toLowerCase()] || 0) + 1;
                return {
                  [word.toLowerCase()]:
                    (wordRepetitions?.[word.toLowerCase()] || 0) + 1
                };
              })
              .reduce((words, word) => {
                const [key, value] = Object.entries(word)?.[0] || [];
                return { ...words, [key]: value };
              }, {});
            return { ...total, ...words };
          }, {})
      )
        .filter(([name]) => {
          return name && wordRepetitions[name];
        })
        .map(([name, v]) => {
          return { name, v, color: getColor('blue') };
        })
        .sort(({ v: v1 }, { v: v2 }) => (v1 > v2 ? -1 : 1))
        .splice(0, 30),
      ...Object.entries(
        groupBy(
          raw
            .filter(({ tags }) => tags)
            .reduce(
              (all, { tags }) => [...all, ...tags.map(name => ({ name }))],
              []
            ),
          'name'
        )
      )
        .map(([name, tags]) => {
          return {
            name,
            v: tags.length,
            color: getColor('primary'),
            level: 1
          };
        })
        .filter(data => data)
        .sort(({ v: v1 }, { v: v2 }) => (v1 > v2 ? -1 : 1))
        .splice(0, 30)
    ];

    const handleChange = event => {
      const { currentTarget } = event;
      const { value } = currentTarget;
      userAction = true;
      setFromDateValue(value);
    };

    useEffect(() => {
      const fromDate = new Date(
        new Date().setMonth(new Date().getMonth() - fromDateValue)
      )
        .toISOString()
        .split(':')[0];
      setFromDate(fromDate);
    }, [fromDateValue]);

    useEffect(() => {
      if (userAction || !raw.length || data?.length) {
        return;
      }
      if (minMatches > 1) {
        setMinMatches(1);
        return;
      }
      setFromDateValue(3);
    }, [raw.length]);

    return (
      <Card className={className}>
        {header || (
          <Card.Header>
            <h6 className="m-0 text-capitalize">Trending topics</h6>
          </Card.Header>
        )}

        <Card.Body
          alignItems="center"
          justifyContent="center"
          className="fs--1 overflow-hidden"
          as={Flex}
          style={{ height: '28rem' }}
        >
          {(data.length && <BubbleChart data={data} />) || (
            <span>No hay trending topics</span>
          )}
        </Card.Body>

        <Card.Footer className="bg-light py-2 align-content-end">
          <Form.Select
            value={fromDateValue}
            size="sm"
            className="me-2"
            onChange={handleChange}
          >
            <option value={1}>Último mes</option>
            <option value={3}>Últimos 3 meses</option>
            <option value={12}>Últimos 12 meses</option>
          </Form.Select>
        </Card.Footer>
      </Card>
    );
  },
  ({ className: class1, company: c1 }, { className: class2, company: c2 }) => {
    return c1?.NO_ID_FIELD === c2?.NO_ID_FIELD && class1 === class2;
  }
);

FeedbackTrendingWords.propTypes = {
  className: PropTypes.string,
  company: PropTypes.object,
  header: PropTypes.node
};

export default FeedbackTrendingWords;
