import React from 'react';
import Flex from 'components/common/Flex';
import LoginFormWithLink from 'components/authentication/LoginFormWithLink';

const Login = () => {
  return (
    <>
      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>Bienvenido a Happyfy</h5>
      </Flex>
      <LoginFormWithLink />
    </>
  );
};

export default Login;
