import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OnBoardingContext } from 'context/Context';

const QuestionaireOutcome = ({ readOnly, isRewardable }) => {
  const { setStep } = useContext(OnBoardingContext);
  return (
    <Card>
      <Card.Body className="text-end">
        {readOnly && (
          <FontAwesomeIcon icon="check" className="text-success fs-3 ms-2" />
        )}
        <Button
          variant="link"
          className="text-500"
          onClick={() => setStep(step => step + 1)}
        >
          Omitir
        </Button>
        <Button disabled={readOnly} variant="primary" type="submit">
          Siguiente {isRewardable && 'y obtener recompensa'}
        </Button>
      </Card.Body>
    </Card>
  );
};

QuestionaireOutcome.propTypes = {
  questionaire: PropTypes.object,
  readOnly: PropTypes.bool,
  isRewardable: PropTypes.bool
};

export default QuestionaireOutcome;
