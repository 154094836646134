import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Badge, Button, Card, Form, ListGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { GaugeChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { Doughnut } from 'react-chartjs-2';
import { CanvasRenderer } from 'echarts/renderers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Calendar from 'components/common/Calendar';
import Flex from 'components/common/Flex';
import Loader from 'components/common/Loader';
import {
  CalendarContext,
  TimeControlContext,
  UserContext
} from 'context/Context';
import {
  getColor,
  getCurrentPosition,
  getTodaySeconds,
  getUserSchedule
} from 'helpers/utils';
import dayjs from 'dayjs';
import timeOffSubtypes from 'components/user/time-off/subtypes.json';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  GaugeChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = ({
  color = 'success',
  labels = ['', ''],
  data = [0, 100],
  isReached,
  isWorking,
  records
}) => ({
  options: {
    // rotation: -90,
    // circumference: '180',
    cutout: !records?.length ? '100%' : isWorking ? '96%' : '99%',
    hover: { mode: null },
    plugins: {
      legend: {
        display: false
      },
      tooltip: null
    }
  },
  data: {
    labels,
    datasets: [
      {
        data,
        backgroundColor: [
          getColor(isReached ? 'danger' : isWorking ? color : 'gray-600'),
          getColor('gray-200')
        ],
        borderWidth: 0
      }
    ]
  }
});

const Today = () => {
  const [now] = useState(new Date());
  const day = now.getDate();
  const month = dayjs(now).format('MMM');

  return <Calendar day={day} month={month} />;
};

const TimeIn = ({ placeholder, value }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="between"
      className={classNames({ 'opacity-25': !value })}
    >
      <FontAwesomeIcon icon="arrow-right-to-bracket" className="me-2" />
      <span>{value ? dayjs(value).format('H:mm') : placeholder}</span>
    </Flex>
  );
};
const TimeOut = ({ placeholder, value }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="between"
      className={classNames({ 'opacity-25': !value })}
    >
      <FontAwesomeIcon icon="arrow-right-from-bracket" className="me-2" />
      <span>{value ? dayjs(value).format('H:mm') : placeholder}</span>
    </Flex>
  );
};

TimeIn.propTypes = TimeOut.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string
};

const defaultMaxDuration = 8 * 3600;
const ElapsedTime = ({ isLocating, records, todayDuration }) => {
  const { me } = useContext(UserContext);
  const { avatar } = me || {};
  const [now, setNow] = useState(new Date());
  const lastRecord = [...(records || [])].pop();
  const isWorking = lastRecord?.type === 'in';
  const lastRecordAt = isWorking ? now : new Date(lastRecord?.createdAt);
  const { diff: todayTime } =
    records?.reduce(
      ({ diff, prev }, { createdAt, type }) => {
        const time =
          type === 'in' ? dayjs(prev).diff(createdAt, 'seconds') || 0 : 0;
        return {
          diff: diff + time,
          prev: new Date(createdAt)
        };
      },
      { diff: 0, prev: lastRecordAt }
    ) || {};
  const maxTime = todayDuration || defaultMaxDuration;
  const isReached = todayTime > maxTime;
  let remainingTime = maxTime;
  let totalSeconds = todayTime || 0;
  let hours = Math.min(
    Math.floor(totalSeconds / 3600) || 0,
    Math.floor(remainingTime / 3600)
  );
  totalSeconds = totalSeconds - hours * 3600;
  remainingTime = remainingTime - hours * 3600;
  let minutes = isReached
    ? Math.floor(remainingTime / 60)
    : Math.floor(totalSeconds / 60) || 0;
  totalSeconds = totalSeconds - minutes * 60;
  if (totalSeconds > 30) {
    minutes += 1;
    if (minutes >= 60) {
      minutes = 0;
      hours += 1;
    }
  }

  let extraHours = 0;
  let extraMinutes = 0;
  if (isReached) {
    extraHours = Math.floor(totalSeconds / 3600) || 0;
    totalSeconds = totalSeconds - extraHours * 3600;
    extraMinutes = Math.floor(totalSeconds / 60) || 0;
  }
  const data = [Math.min(todayTime, maxTime), Math.max(maxTime - todayTime, 0)];

  useEffect(() => {
    let interval = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      className="position-relative mb-3"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        className={classNames(
          'position-relative d-block transition-base rounded-circle',
          {
            'pt-6 px-50px': !records?.length,
            'shadow-none': !isWorking,
            shadow: isWorking
          }
        )}
      >
        <Doughnut
          className={classNames('position-absolute z-index-2', {
            'visually-hidden': !records?.length
          })}
          {...getOptions({ data, isWorking, records, isReached })}
        />
        <Avatar
          className={classNames('position-relative d-block transition-base', {
            'w-200px h-200px': !records?.length,
            'opacity-50 w-300px h-300px': !isWorking && records?.length,
            'opacity-25 w-300px h-300px': isWorking
          })}
          mediaClass="w-100 h-100"
          src={avatar}
        />

        {isLocating && (
          <Flex
            alignItems="center"
            justifyContent="center"
            className="w-300px h-300px rounded-circle position-absolute pointer-none z-index-2 overflow-hidden"
          >
            <div className="w-350px h-350px bg-opacity-50 bg-white fa-beat-fade position-absolute rounded-circle" />
          </Flex>
        )}
      </Flex>
      <Flex
        direction="column"
        alignItems="center"
        className={classNames(
          'position-absolute text-center text-dark transition-base',
          { 'opacity-0': !isWorking && !records?.length }
        )}
      >
        <Form.Label className="m-0">Tiempo trabajado</Form.Label>
        <Flex
          alignItems="center"
          justifyContent="center"
          className="transition-base fs-3 position-relative"
        >
          <FontAwesomeIcon
            className={classNames('transition-base overflow-hidden', {
              'text-300 w-0 me-0': !isWorking,
              'text-success w-16px me-2': isWorking
            })}
            size={classNames({
              '2xs': !isWorking,
              xs: !!isWorking
            })}
            icon="circle"
            fade={isWorking}
          />
          <span className="me-2">{hours}h</span>
          <span>{minutes}m</span>
        </Flex>
        {isReached && (
          <div className="position-absolute bottom-0 mb-n5">
            <Badge bg="danger" pill>
              <Flex
                alignItems="center"
                justifyContent="center"
                className="transition-base position-relative"
              >
                <span className="me-1">+</span>
                <span className="me-2">{extraHours}h</span>
                <span>{extraMinutes}m</span>
              </Flex>
            </Badge>
          </div>
        )}
      </Flex>
    </Flex>
  );
};

ElapsedTime.propTypes = {
  isLocating: PropTypes.bool,
  records: PropTypes.array,
  todayDuration: PropTypes.number
};

const Welcome = () => {
  const { myTodayRecords } = useContext(TimeControlContext);
  const { me } = useContext(UserContext);
  const { firstname } = me || {};

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      className={classNames(
        'position-absolute start-0 w-100 text-center transition-base',
        {
          'opacity-0 scale-0': myTodayRecords?.length
        }
      )}
    >
      <h3 className="m-0">Hola {firstname}</h3>
    </Flex>
  );
};

const TodayTimeControl = ({ className }) => {
  const { createEntry, isReady, myTodayRecords } =
    useContext(TimeControlContext);
  const { timeOff } = useContext(CalendarContext);
  const { me, schedules } = useContext(UserContext);
  const [now] = useState(new Date());
  const today = dayjs(now).format('YYYY-MM-DD');
  const lastAccess = [...(myTodayRecords || [])].pop();
  const { location } = me || {};
  const { holidays = [] } = location || {};
  const holiday = holidays.find(
    ({ start }) =>
      dayjs(start).format('YYYY-MM-DD') === dayjs(now).format('YYYY-MM-DD')
  );
  const isWorking = lastAccess?.type === 'in';
  const schedule = getUserSchedule(schedules, me);
  const todayDuration = getTodaySeconds(schedule);
  let totalSeconds = todayDuration;
  let totalHours = Math.floor(totalSeconds / 3600) || 0;
  totalSeconds = totalSeconds - totalHours * 3600;
  let totalMinutes = Math.floor(totalSeconds / 60) || 0;
  totalSeconds = totalSeconds - totalMinutes * 60;
  if (totalSeconds > 30) {
    totalMinutes += 1;
    if (totalMinutes >= 60) {
      totalMinutes = 0;
      totalHours += 1;
    }
  }
  const todayAscRecords = [...myTodayRecords]?.sort((r1, r2) =>
    r1.createdAt < r2.createdAt ? -1 : 1
  );
  const todayInRecords =
    todayAscRecords?.filter(({ type }) => type === 'in') || [];
  const todayOutRecords =
    todayAscRecords?.filter(({ type }) => type === 'out') || [];
  const [state, setState] = useState(false);
  const [isLocating, setIsLocating] = useState(false);
  const myTimeOff =
    timeOff?.filter(
      ({ approved, participants }) =>
        approved &&
        (!participants || participants.some(ref => ref?.path === me?.ref?.path))
    ) || [];
  const todayTimeOff =
    myTimeOff.find(
      ({ start, end }) =>
        dayjs(start).format('YYYY-MM-DD') <= today &&
        dayjs(end).format('YYYY-MM-DD') >= today
    ) || {};
  const subtypeData =
    timeOffSubtypes.find(({ value }) => value === todayTimeOff?.subtype) || {};
  const todayTitle =
    holiday?.title ||
    (!subtypeData?.working && subtypeData?.label) ||
    `${totalHours}h ${totalMinutes}m${
      subtypeData?.working ? ` (${subtypeData?.label})` : ''
    }`;

  const handleClick = async () => {
    const [lastAccessCreatedAt] =
      lastAccess?.createdAt.match(/\d{2}:\d{2}:/) || [];
    const [now] = new Date().toISOString().match(/\d{2}:\d{2}:/) || [];
    const sameTime = lastAccessCreatedAt === now;
    if (sameTime) {
      toast.error(
        classNames({
          'Acabas de entrar': isWorking,
          'Acabas de salir': !isWorking
        })
      );
      return;
    }
    setIsLocating(true);
    let location = null;
    try {
      location = await getCurrentPosition();
    } catch (error) {
      console.error(error);
      alert(
        'Para utilizar al máximo las funcionalidades de Happyfy debes habilitar la ubicación del navegador'
      );
    }
    createEntry({ location, type: isWorking ? 'out' : 'in' });
    setIsLocating(false);
  };

  useEffect(() => {
    (async () => {
      const { state } = await navigator.permissions.query({
        name: 'geolocation'
      });
      setState(state);
    })();
  }, []);

  return (
    <Card className={classNames('p-3 sticky-sidebar', className)}>
      <Card.Header as={Flex} alignItems="center" justifyContent="between">
        <Card.Title className="me-5">
          Jornada de hoy
          <br />
          <span className="fw-normal">{todayTitle}</span>
        </Card.Title>
        <Today />
      </Card.Header>
      <Card.Body>
        <Welcome />
        <ElapsedTime
          isLocating={isLocating}
          records={myTodayRecords}
          todayDuration={todayDuration}
        />
        <Flex justifyContent="between" wrap="nowrap">
          <ListGroup className="w-100" variant="flush">
            {!!todayInRecords?.length && (
              <ListGroup.Item>
                <Flex alignItems="center" justifyContent="between">
                  <Form.Label className="m-0">Entrada</Form.Label>
                  <Form.Label className="m-0">Salida</Form.Label>
                </Flex>
              </ListGroup.Item>
            )}
            {todayInRecords.map(({ NO_ID_FIELD, createdAt }, index) => (
              <ListGroup.Item
                key={`Time-Control-${NO_ID_FIELD}`}
                variant="light"
              >
                <Flex alignItems="center" justifyContent="between">
                  <TimeIn value={createdAt} />
                  <TimeOut
                    value={todayOutRecords?.[index]?.createdAt}
                    placeholder="00:00"
                  />
                </Flex>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Flex>
        {state === 'denied' && (
          <Card className="max-w-100 border shadow-none mt-3 text-center fs--1">
            <Card.Body className="p-3">
              <FontAwesomeIcon
                icon="exclamation-triangle"
                className="me-2 text-warning"
              />
              Habilita la ubicación del navegador
            </Card.Body>
          </Card>
        )}
        <Button
          className="w-100 mt-3"
          onClick={handleClick}
          size="lg"
          disabled={!isReady || isLocating}
        >
          {isLocating && <Loader style={{ width: '1rem', height: '1rem' }} />}
          {classNames({
            Entrada: !isLocating && !isWorking,
            Salida: !isLocating && isWorking
          })}
        </Button>
      </Card.Body>
    </Card>
  );
};

TodayTimeControl.propTypes = {
  className: PropTypes.string
};

export default TodayTimeControl;
