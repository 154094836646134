import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Collapse, Form, Card } from 'react-bootstrap';
import { slugifyText } from 'helpers/utils';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { ProductContext } from 'context/Context';

const ProductFilters = ({ setShow, isOffcanvas }) => {
  const {
    productFilters = [],
    productsState: { filters = [] },
    productsDispatch
  } = useContext(ProductContext);

  const handleFilterOptions = e => {
    const { type, name, value, checked } = e.target;
    if (type === 'checkbox') {
      let _filters = [...filters];
      _filters = _filters.filter(option => option.value !== value);

      checked && _filters.push({ name, value });
      productsDispatch({
        type: 'FILTER_PRODUCT',
        payload: { filters: _filters }
      });
    }

    if (type === 'radio') {
      const isExists = filters.some(el => el.name === name);
      isExists
        ? productsDispatch({
            type: 'FILTER_PRODUCT',
            payload: {
              filters: filters.map(el =>
                el.name === name ? { ...el, value } : el
              )
            }
          })
        : productsDispatch({
            type: 'FILTER_PRODUCT',
            payload: {
              filters: [...filters, { name, value }]
            }
          });
    }
  };

  return (
    <Card className="product-filter">
      <SimpleBarReact style={{ height: '100%' }}>
        <Card.Header as={Flex} className="flex-between-center pt-x1">
          <Flex className="gap-2 flex-xl-grow-1 align-items-center justify-content-xl-between">
            <h5 className="mb-0 text-700 fs-0 d-flex align-items-center">
              <FontAwesomeIcon icon="filter" className="fs--1 me-1" />
              <span>Filtro</span>
            </h5>
            <Button
              variant="outline-secondary"
              size="sm"
              className="ms-2 mt-0 mb-0"
              style={{ fontSize: '12px' }}
              onClick={() =>
                productsDispatch({
                  type: 'FILTER_PRODUCT',
                  payload: { filters: [] }
                })
              }
            >
              <FontAwesomeIcon icon="redo-alt" className="me-1 fs--2" />
              Borrar
            </Button>
          </Flex>
          {isOffcanvas && (
            <Button
              onClick={() => setShow(false)}
              className="btn-close text-reset"
              size="sm"
              variant="link"
            ></Button>
          )}
        </Card.Header>
        <Card.Body className="py-0">
          {filters.length > 0 && (
            <Flex wrap="wrap" className="gap-2 mb-3">
              {filters.map(tag => (
                <SoftBadge
                  key={tag.value}
                  className="text-capitalize bg-300 text-700 py-0"
                >
                  {tag.value}
                  <Button
                    size="sm"
                    variant="link"
                    className="p-0 text-700"
                    onClick={() =>
                      productsDispatch({
                        type: 'FILTER_PRODUCT',
                        payload: {
                          filters: filters.filter(
                            ({ value }) => value !== tag.value
                          )
                        }
                      })
                    }
                  >
                    <FontAwesomeIcon icon="times" className="ms-1 fs--2" />
                  </Button>
                </SoftBadge>
              ))}
            </Flex>
          )}
          <ul className="list-unstyled">
            {productFilters.map((filter, index) => (
              <FilterItem
                key={slugifyText(filter.label)}
                index={index}
                filter={filter}
                filterOptions={filters}
                handleFilterOptions={handleFilterOptions}
              />
            ))}
          </ul>
        </Card.Body>
      </SimpleBarReact>
    </Card>
  );
};

ProductFilters.propTypes = {
  setShow: PropTypes.func,
  isOffcanvas: PropTypes.bool
};

const FilterItem = ({ index, filter, filterOptions, handleFilterOptions }) => {
  const [open, setOpen] = useState(false);
  const { label, options = [] } = filter;

  return (
    <li>
      <Button
        variant="link"
        onClick={() => setOpen(!open)}
        aria-controls={`${slugifyText('Categorías')}-collapse`}
        aria-expanded={index === 0 || index === 1 ? !open : open}
        className="collapse-indicator-plus w-100 fs--2 fw-medium text-start text-600 text-decoration-none text-capitalize py-3 px-0"
      >
        {label}
      </Button>
      <Collapse
        in={index === 0 || index === 1 ? !open : open}
        id={`${slugifyText('Categorías')}-collapse`}
      >
        <ul className="list-unstyled">
          {options.map(({ label, name, value }) => (
            <li key={slugifyText(label)}>
              <Form.Check type="checkbox" className="form-check d-flex ps-0">
                <Form.Check.Label
                  className="fs--1 flex-1 text-capitalize text-truncate"
                  htmlFor={`filter-${slugifyText(label)}`}
                >
                  {label}
                </Form.Check.Label>

                <Form.Check.Input
                  type="checkbox"
                  onChange={e => handleFilterOptions(e)}
                  checked={filterOptions.some(
                    ({ value: checked }) => value === checked
                  )}
                  id={`filter-${slugifyText(label)}`}
                  name={name}
                  value={value}
                />
              </Form.Check>
            </li>
          ))}
        </ul>
      </Collapse>
    </li>
  );
};

FilterItem.propTypes = {
  index: PropTypes.number,
  filter: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })
    )
  }),
  handleFilterOptions: PropTypes.func,
  filterOptions: PropTypes.array
};

export default ProductFilters;
