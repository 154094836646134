import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { collection, deleteDoc, doc, getDoc } from '@firebase/firestore';
import { getDownloadURL, ref } from '@firebase/storage';
import { useFirestore, useStorage } from 'reactfire';

import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { Link } from 'react-router-dom';
import { Dropdown, Form } from 'react-bootstrap';
import createMarkup from 'helpers/createMarkup';
import { FeedContext, UserContext } from 'context/Context';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

dayjs.extend(relativeTime);
dayjs.locale('es');

const Comment = ({ comment, feed }) => {
  const db = useFirestore();
  const storage = useStorage();
  const [edit, setEdit] = useState(false);
  const [editedComment, setComment] = useState(comment?.content);
  const { NO_ID_FIELD, content, createdAt, userId } = comment;
  const { updateComment } = useContext(FeedContext);
  const { company, me, partners = [] } = useContext(UserContext);
  const [user, setUser] = useState();
  const { avatar, disabled, name } = user || {};
  const time = dayjs(createdAt)
    .calendar(null, {
      sameDay: `[${dayjs().to(dayjs(createdAt))}]`,
      lastDay: '[ayer]',
      lastWeek: 'dddd',
      sameElse: 'ddd, D MMM'
    })
    .replace('.', '');

  const handleDelete = () => {
    deleteDoc(doc(db, 'feeds', feed?.NO_ID_FIELD, 'comments', NO_ID_FIELD));
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const submitComment = e => {
    e.preventDefault();
    updateComment(feed, comment, {
      content: editedComment
    });
    setComment('');
    setEdit(false);
  };

  const getUser = async () => {
    let user = partners.find(({ NO_ID_FIELD }) => NO_ID_FIELD === userId);
    if (!user) {
      try {
        const userDoc = await getDoc(doc(collection(db, 'users'), userId));
        let avatar;
        try {
          avatar = await getDownloadURL(
            ref(storage, `/users/${userId}/avatar.jpeg`)
          );
        } catch (error) {
          console.error(error);
        }
        user = !userDoc
          ? {}
          : {
              NO_ID_FIELD: userDoc.id,
              id: userDoc.id,
              ref: userDoc.ref,
              ...(await userDoc.data()),
              avatar,
              disabled: true
            };
      } catch (error) {
        console.error(error);
      }
    }
    setUser(user);
  };

  useEffect(() => {
    partners?.length > 1 && getUser();
  }, [company?.NO_ID_FIELD, userId, partners?.length]);

  return (
    <div>
      <Flex className="mt-3">
        <Avatar
          className={classNames({ disabled })}
          src={avatar}
          name={name}
          size="xl"
        />
        <div className="flex-1 ms-2 fs--1">
          <p className="mb-1 bg-200 rounded-3 p-2 hover-actions-trigger position-relative">
            {disabled ? (
              <span className="fw-semi-bold text-500">{name}</span>
            ) : (
              <Link
                disabled={disabled}
                className="fw-semi-bold"
                to={`/user/profile/${userId}`}
              >
                {name}
              </Link>
            )}
            {edit ? (
              <Form onSubmit={submitComment}>
                <Form.Control
                  type="text"
                  className="rounded-pill mt-1 fs--1"
                  placeholder="Escribe un comentario..."
                  value={editedComment}
                  onChange={e => setComment(e.target.value)}
                  autoFocus
                />
              </Form>
            ) : (
              <span
                className="ms-1"
                dangerouslySetInnerHTML={createMarkup(content)}
              />
            )}
            {me?.NO_ID_FIELD === userId && (
              <Dropdown className="font-sans-serif btn-reveal-trigger position-absolute end-0 top-0">
                <Dropdown.Toggle
                  variant="link"
                  size="sm"
                  className="px-1 text-600 btn-reveal hover-actions position-relative m-1"
                >
                  <FontAwesomeIcon icon="ellipsis-h" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Dropdown.Item onClick={handleEdit}>Editar</Dropdown.Item>
                  <Dropdown.Item className="text-danger" onClick={handleDelete}>
                    Eliminar
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </p>
          <div className="px-2">{time}</div>
        </div>
      </Flex>
    </div>
  );
};

const Comments = ({ feed }) => {
  const { comments = [] } = feed;
  const [showDropShadow, setShowDropShadow] = useState(false);
  const scrollRef = useRef();
  const commentsEnd = useRef();
  const setDropShadow = () => {
    const el = scrollRef?.current;
    if (el?.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    if (!scrollRef?.current || !commentsEnd?.current) {
      return;
    }
    scrollRef.current.scrollTop = commentsEnd.current.offsetTop;
    scrollRef.current.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, [scrollRef, commentsEnd]);

  useEffect(() => {
    const top = commentsEnd?.current?.offsetTop;
    scrollRef?.current?.scrollTo({ top, behavior: 'smooth' });
  }, [comments.length]);

  if (!comments?.length) {
    return null;
  }
  return (
    <>
      <div
        className={classNames(
          'max-h- position-relative pt-3 pb-1 mt-n2 mx-n2 z-index-2',
          {
            'navbar-glass-shadow': showDropShadow
          }
        )}
      />
      <div
        className={classNames('mb-3 overflow-auto')}
        ref={scrollRef}
        style={{ maxHeight: '200px' }}
      >
        {comments.map(comment => (
          <Comment
            key={`Comment-${comment?.NO_ID_FIELD}}`}
            comment={comment}
            feed={feed}
          />
        ))}
        <div ref={commentsEnd} />
      </div>
    </>
  );
};

Comment.propTypes = {
  comment: PropTypes.object,
  feed: PropTypes.object
};
Comments.propTypes = {
  feed: PropTypes.object
};

export default Comments;
