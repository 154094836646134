import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { OnBoardingContext } from 'context/Context';

const OnBoardingProvider = ({ children }) => {
  const [step, setStep] = useState(1);
  const [maxStep, setMaxStep] = useState(1);

  const location = useLocation();
  const { hash } = location;

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          const card = element.parentElement.parentElement;
          card.classList.add(...['border-4']);
          setTimeout(() => {
            card.classList.remove(...['border-4']);
          }, 1000);
          element.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }
    }, 0);
  }, [hash]);

  useEffect(() => {
    setMaxStep(Math.max(maxStep, step));
    window.scrollTo(0, 0);
  }, [step]);

  const value = { step, setStep, maxStep };
  return (
    <OnBoardingContext.Provider value={value}>
      {children}
    </OnBoardingContext.Provider>
  );
};

OnBoardingProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default OnBoardingProvider;
