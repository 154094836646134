import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card } from 'react-bootstrap';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import Event from 'components/company/events/Event';
import { CalendarContext } from 'context/Context';

const Events = ({
  bodyClassName,
  cardTitle,
  exclude,
  hasFooter = true,
  header,
  ...rest
}) => {
  let auxDate = new Date();
  auxDate.setDate(auxDate.getDate() - 1);
  const [yesterday] = useState(auxDate.toISOString());
  const { events: raw } = useContext(CalendarContext);
  const events =
    raw
      ?.filter(
        ({ NO_ID_FIELD, start }) =>
          (!exclude || !exclude.includes(NO_ID_FIELD)) && start > yesterday
      )
      .sort((e1, e2) => (e1.start < e2.start ? -1 : 1)) || [];

  return (
    <Card {...rest}>
      {header || (cardTitle && <FalconCardHeader title={cardTitle} light />)}
      <Card.Body className={classNames('fs--1', bodyClassName)}>
        {!events.length && <span>No hay eventos</span>}
        {events.map((event, index) => (
          <Event
            key={`Event-${event.NO_ID_FIELD}`}
            details={event}
            isLast={index === events.length - 1}
          />
        ))}
      </Card.Body>
      {hasFooter && (
        <FalconCardFooterLink
          title="Todos los eventos"
          to="/company/events"
          size="sm"
        />
      )}
    </Card>
  );
};

Events.propTypes = {
  bodyClassName: PropTypes.string,
  cardTitle: PropTypes.string,
  exclude: PropTypes.array,
  hasFooter: PropTypes.bool,
  header: PropTypes.node
};

export default Events;
