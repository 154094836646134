import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup } from 'react-bootstrap';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import Tooltip from 'components/common/Tooltip';
import { UserContext } from 'context/Context';
import EducationForm from './EducationForm';

export const EducationItem = ({ details, index, isLast, user }) => {
  const { me, updateUser } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const { dates, degree, institution } = details;
  const { start, end } = dates;

  const handleDelete = event => {
    event.preventDefault();
    if (me?.type !== 'admin' && me?.NO_ID_FIELD !== user?.NO_ID_FIELD) {
      return;
    }
    const { profile = {} } = user || {};
    const education = profile?.education?.filter((exp, i) => i !== index) || [];
    updateUser(user, { 'profile.education': education });
  };
  const startStr =
    start &&
    dayjs(
      `${start?.year}${start?.month ? `-${start?.month}` : ''}${
        start?.day ? `-${start?.day}` : ''
      }`
    ).format(`${start?.day ? `D ` : ''}${start?.month ? `MMMM ` : ''}YYYY`);
  const endStr =
    start &&
    (end && !isNaN(end?.year)
      ? dayjs(
          `${end?.year}${end?.month ? `-${end?.month}` : ''}${
            end?.day ? `-${end?.day}` : ''
          }`
        ).format(`${end?.day ? `D ` : ''}${end?.month ? `MMMM ` : ''}YYYY`)
      : 'Presente');
  return (
    <EducationForm
      show={show}
      setShow={setShow}
      data={details}
      index={index}
      user={user}
    >
      <Flex>
        <Avatar size="3xl" name={institution} />
        <div className="flex-1 position-relative ms-3">
          <h6 className="fs-0 mb-0">
            <Link
              to={`https://www.google.com/search?q=${encodeURIComponent(
                institution
              )}`}
              target="_blank"
            >
              {institution}
            </Link>
          </h6>
          <p className="text-secondary mb-1">{degree}</p>
          <p className="text-1000 text-capitalize mb-0">
            {dates && startStr === endStr ? (
              startStr
            ) : (
              <>
                {startStr} - {endStr}
              </>
            )}
          </p>
          {!isLast && <div className="border-dashed border-bottom my-3" />}
        </div>
        {me?.NO_ID_FIELD === user?.NO_ID_FIELD && (
          <div>
            <ButtonGroup>
              <Button
                variant="falcon-default"
                className="p-0"
                onClick={() => setShow(show => !show)}
              >
                <Tooltip className="py-1 px-3" title="Editar">
                  <FontAwesomeIcon
                    icon={classNames({
                      pencil: !show,
                      times: show
                    })}
                  />
                </Tooltip>
              </Button>
              <Button
                variant="falcon-danger"
                className="p-0"
                onClick={handleDelete}
              >
                <Tooltip className="py-1 px-3" title="Eliminar">
                  <FontAwesomeIcon icon="trash" />
                </Tooltip>
              </Button>
            </ButtonGroup>
          </div>
        )}
      </Flex>
    </EducationForm>
  );
};

EducationItem.propTypes = {
  details: PropTypes.shape({
    dates: PropTypes.object,
    degree: PropTypes.string,
    institution: PropTypes.string
  }),
  index: PropTypes.number,
  isLast: PropTypes.bool,
  user: PropTypes.object
};

export default EducationItem;
