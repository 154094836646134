import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CloseButton, Col, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IndexGraph from 'components/charts/IndexGraph';
import CVAttachment from './CVAttachment';
import ModalMediaContent from './ModalMediaContent';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { AssistantContext } from 'context/Context';
import { getColor } from 'helpers/utils';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

const CandidateModal = ({ candidate, show }) => {
  const { showCandidateModal } = useContext(AssistantContext);
  const { createdAt, data = {}, title } = candidate;
  const { completion = {} } = data;
  let {
    address,
    email,
    info,
    description,
    skills = [],
    experience = [],
    education = [],
    personality = [],
    tags = [],
    tel,
    compatibility,
    pros = [],
    cons = []
  } = completion;
  compatibility = compatibility && parseInt(compatibility);
  let compatibilityColor =
    (isNaN(compatibility) && 'none') ||
    (compatibility > 75 && 'success') ||
    (compatibility >= 60 && 'warning') ||
    'danger';

  const handleClose = () => {
    showCandidateModal(false);
  };

  return (
    <Modal
      show={show}
      size="lg"
      onHide={handleClose}
      contentClassName="border-0"
      dialogClassName="mt-6"
    >
      <Modal.Body className="p-0">
        <div className="sticky-top bg-light rounded-top-lg px-4 py-3 z-index-1016">
          <h4 className="mb-0">{title}</h4>
          <div className="position-absolute top-0 end-0 mt-2 me-3 z-index-1">
            <CloseButton
              onClick={handleClose}
              className="btn btn-sm btn-circle d-flex flex-center transition-base"
            />
          </div>
        </div>
        <Row>
          <Col xs={9}>
            <div className="p-4">
              <ModalMediaContent
                title="Información"
                icon="info"
                className="position-relative"
              >
                <div>
                  <p className="font-sans-serif text-pre-wrap">{info}</p>
                  {tel && (
                    <Link to={`tel:${tel}`}>
                      <SoftBadge className="me-1 mb-1 py-2 text-wrap text-start">
                        <FontAwesomeIcon icon="phone" className="me-2" />
                        {tel}
                      </SoftBadge>
                    </Link>
                  )}
                  {email && (
                    <Link to={`mailto:${email}`}>
                      <SoftBadge className="me-1 mb-1 py-2 text-wrap text-start">
                        <FontAwesomeIcon icon="envelope" className="me-2" />
                        {email}
                      </SoftBadge>
                    </Link>
                  )}
                  {address && (
                    <SoftBadge className="me-1 mb-1 py-2 text-wrap text-start">
                      <FontAwesomeIcon icon="map-marker-alt" className="me-2" />
                      {address}
                    </SoftBadge>
                  )}
                </div>
              </ModalMediaContent>

              <ModalMediaContent
                title="Descripción"
                icon="align-left"
                className="position-relative"
              >
                <div>
                  <p className="font-sans-serif text-pre-wrap">{description}</p>
                </div>
              </ModalMediaContent>

              <ModalMediaContent title="Habilidades" icon="check">
                <Flex wrap="wrap">
                  {skills.map(tag => (
                    <SoftBadge
                      key={`Skills-${tag}`}
                      bg="primary"
                      className="me-1 mb-1 py-2 text-wrap text-start"
                    >
                      {tag.replace(/^-/, '').trim()}
                    </SoftBadge>
                  ))}
                </Flex>
              </ModalMediaContent>

              <ModalMediaContent title="Personalidad" icon="user">
                <Flex wrap="wrap">
                  {personality.map(tag => (
                    <SoftBadge
                      key={`Personality-${tag}`}
                      bg="info"
                      className="me-1 mb-1 py-2 text-wrap text-start"
                    >
                      {tag.replace(/^-/, '').trim()}
                    </SoftBadge>
                  ))}
                </Flex>
              </ModalMediaContent>

              <ModalMediaContent title="Pros" icon="thumbs-up">
                <Flex wrap="wrap">
                  {pros.map(tag => (
                    <SoftBadge
                      key={`Pros-${tag}`}
                      bg="success"
                      className="me-1 mb-1 py-2 text-wrap text-start"
                    >
                      {tag.replace(/^-/, '').trim()}
                    </SoftBadge>
                  ))}
                </Flex>
              </ModalMediaContent>

              <ModalMediaContent title="Contras" icon="thumbs-down">
                <Flex wrap="wrap">
                  {cons.map(tag => (
                    <SoftBadge
                      key={`Cons-${tag}`}
                      bg="danger"
                      className="me-1 mb-1 py-2 text-wrap text-start"
                    >
                      {tag.replace(/^-/, '').trim()}
                    </SoftBadge>
                  ))}
                </Flex>
              </ModalMediaContent>

              <ModalMediaContent title="Experiencia" icon="building">
                {experience.map(({ company, dates, position }, index) => {
                  dates = dates || {};
                  let { start, end } = dates;
                  return (
                    <div
                      key={`Experience-${index}`}
                      className="me-1 mb-1 py-2 text-wrap text-start"
                    >
                      <h5>{company}</h5>
                      <h6 className="m-0 fw-normal">{position}</h6>
                      <p className="m-0 text-capitalize fs--1">
                        {dates && (
                          <>
                            {start &&
                              dayjs(`${start?.year}-${start?.month}`).format(
                                'MMMM YYYY'
                              )}{' '}
                            -{' '}
                            {start &&
                              (end && !isNaN(end?.year)
                                ? dayjs(`${end?.year}-${end?.month}`).format(
                                    'MMMM YYYY'
                                  )
                                : 'Presente')}
                          </>
                        )}
                      </p>
                    </div>
                  );
                })}
              </ModalMediaContent>

              <ModalMediaContent title="Educación" icon="graduation-cap">
                {education.map(
                  (
                    { institution, date_range: dateRange, dates, degree },
                    index
                  ) => {
                    dates = dates || dateRange || {};
                    let { start, end, date_start, date_end } = dates;
                    start = start || date_start;
                    end = end || date_end;
                    return (
                      <div
                        key={`Experience-${index}`}
                        className="me-1 mb-1 py-2 text-wrap text-start"
                      >
                        <h5>{institution}</h5>
                        <h6 className="m-0 fw-normal">{degree}</h6>
                        <p className="m-0 text-capitalize fs--1">
                          {dates && (
                            <>
                              {start &&
                                dayjs(`${start?.year}-${start?.month}`).format(
                                  'MMMM YYYY'
                                )}{' '}
                              -{' '}
                              {start &&
                                (end
                                  ? dayjs(`${end?.year}-${end?.month}`).format(
                                      'MMMM YYYY'
                                    )
                                  : 'Presente')}
                            </>
                          )}
                        </p>
                      </div>
                    );
                  }
                )}
              </ModalMediaContent>

              <ModalMediaContent
                title="Currículum"
                icon="paperclip"
                headingClass="d-flex justify-content-between"
              >
                <CVAttachment
                  candidate={candidate}
                  attachments={[
                    {
                      type: 'pdf',
                      title: `${title} CV`,
                      date: createdAt
                    }
                  ]}
                />
              </ModalMediaContent>
            </div>
          </Col>
          <Col xs={3}>
            <div className="pt-7 mt-n6 pe-4 sticky-top">
              <IndexGraph
                title={
                  <>
                    <h3 className={`fs-5 mt-n7 text-${compatibilityColor}`}>
                      {compatibility}
                    </h3>
                    <h6 className="mt-4">Compatibilidad</h6>
                  </>
                }
                svgOptions={{
                  width: '120',
                  height: '120',
                  viewBox: '0 0 120 120'
                }}
                color={getColor(compatibilityColor)}
                data={compatibility}
              />

              <Flex className="mt-3" justifyContent="center" wrap="wrap">
                {tags.map(tag => (
                  <SoftBadge
                    bg="secondary"
                    className="me-1 mb-1 py-2 text-truncate"
                    key={`Tag-${tag}`}
                  >
                    {tag}
                  </SoftBadge>
                ))}
              </Flex>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

CandidateModal.propTypes = {
  candidate: PropTypes.object,
  show: PropTypes.bool
};

export default CandidateModal;
