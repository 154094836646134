import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card } from 'react-bootstrap';
import { UserReviewsContext, UserContext } from 'context/Context';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';

const User = ({ user, divider, selected }) => {
  const { setUser } = useContext(UserReviewsContext);
  const { avatar, name, response } = user;

  return (
    <Card
      className={classNames({
        'sticky-sidebar top-navbar-height bottom-0 mx-n1': selected,
        'shadow-none rounded-0': !selected,
        'bg-transparent': !selected && !response,
        'bg-soft-success': response
      })}
    >
      <Card.Body className={classNames({ 'py-0': !selected })}>
        {(selected && (
          <>
            <h6>Evaluando a:</h6>
            <Avatar
              src={avatar}
              name={name}
              size="5xl"
              rounded=""
              className="mb-2 w-100"
            />
            <h4 className="d-flex m-0">
              <span className="flex-1">{name}</span>
              {response && (
                <FontAwesomeIcon icon="check" className="text-success" />
              )}
            </h4>
          </>
        )) || (
          <Button
            alignItems="center"
            as={Flex}
            className="p-0 fs--1 fw-semi-bold text-dark"
            variant="link"
            onClick={() => setUser(user)}
          >
            <Avatar size="2xl" src={avatar} name={name} />
            <div className="flex-1 ms-2 mt-3 text-start">
              <div className="mb-3">{name}</div>
              {!divider && <div className="border-dashed border-bottom" />}
            </div>
            {response && (
              <FontAwesomeIcon icon="check" className="text-success" />
            )}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

User.propTypes = {
  user: PropTypes.object,
  divider: PropTypes.bool,
  selected: PropTypes.bool
};

const UserInfo = ({ users = [] }) => {
  const { me } = useContext(UserContext);
  const { user: selectedUser = {} } = useContext(UserReviewsContext);
  const { NO_ID_FIELD } = selectedUser;

  if (!users.length) {
    return;
  }

  return (
    <Card
      className={classNames('h-100 bg-transparent shadow-none', {
        'py-3': users.length > 1,
        'border-start rounded-0 border-300': me?.type === 'admin',
        'border border-300': me?.type !== 'admin'
      })}
    >
      {users.map((user, index) => (
        <User
          key={`Partner-${user.NO_ID_FIELD}`}
          user={user}
          divider={users.length === index + 1}
          selected={NO_ID_FIELD === user?.NO_ID_FIELD || users.length === 1}
        />
      ))}
    </Card>
  );
};

UserInfo.propTypes = {
  users: PropTypes.array
};

export default UserInfo;
