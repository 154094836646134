import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Offcanvas, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductContext } from 'context/Context';
import usePagination from 'hooks/usePagination';
import ProductGrid from './ProductGrid';
import ProductsHeader from './ProductsHeader';
import ProductsPagination from './ProductsPagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProductFilters from './ProductFilters';
import { useBreakpoints } from 'hooks/useBreakpoints';

const Products = () => {
  const [showFilterOffcanvas, setShowFilterOffcanvas] = useState(false);
  const [productsPerPage, setProductsPerPage] = useState(24);
  const navigate = useNavigate();
  const { breakpoints } = useBreakpoints();
  const { layout = 'grid' } = useParams();
  const {
    productsState: { products }
  } = useContext(ProductContext);
  const pagination = usePagination(products, productsPerPage);
  const {
    paginationState: { data: paginatedProducts }
  } = pagination;

  const isList = layout === 'list';
  const isGrid = layout === 'grid';

  useEffect(() => {
    isList || isGrid || navigate('/errors/404');
  }, []);

  return (
    <>
      <Row className="g-3">
        {breakpoints.up('xl') && (
          <Col xs={12} md={3} xl={2}>
            <ProductFilters />
          </Col>
        )}
        <Col xs={12} md={9} xxl={10}>
          {/* Products Header */}
          <ProductsHeader
            layout={layout}
            setShowFilterOffcanvas={setShowFilterOffcanvas}
          />
          {/* Products */}
          <Row className="mb-3 g-3">
            {paginatedProducts.length > 0 ? (
              paginatedProducts.map(product => (
                <Col key={product.id} md={6} xl={4} xxl={3}>
                  <ProductGrid product={product} />
                </Col>
              ))
            ) : (
              <Card className="bg-transparent shadow-none">
                <Card.Body className="border-2 border-dashed border-400 border rounded text-center py-5">
                  <div className="fs--1">
                    <FontAwesomeIcon
                      icon="exclamation-triangle"
                      className="fs-6 mb-3"
                    />
                    <h5>No se han encontrado productos</h5>
                    <p className="mb-0">
                      La búsqueda no coincide con ningún producto. Intenta
                      filtrar con otros parámetros.
                    </p>
                  </div>
                </Card.Body>
              </Card>
            )}
          </Row>
          <ProductsPagination
            pagination={pagination}
            setProductsPerPage={setProductsPerPage}
          />
        </Col>
      </Row>
      {breakpoints.down('xl') && (
        <Offcanvas
          show={showFilterOffcanvas}
          onHide={() => setShowFilterOffcanvas(false)}
          placement="start"
          className="offcanvas offcanvas-filter-sidebar"
        >
          <ProductFilters isOffcanvas={true} setShow={setShowFilterOffcanvas} />
        </Offcanvas>
      )}
    </>
  );
};

export default Products;
