import React from 'react';
import PropTypes from 'prop-types';
import { doc } from '@firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const lastMessagePreview = {
  checklist: title => (
    <>
      <FontAwesomeIcon icon="list-check" size="sm" />
      <span>{` Checklist: ${title}`}</span>
    </>
  ),
  deleted: () => (
    <>
      <FontAwesomeIcon icon="ban" size="xs" />{' '}
      <span style={{ fontStyle: 'italic' }}>Mensaje eliminado</span>
    </>
  ),
  event: title => (
    <>
      <FontAwesomeIcon icon="flag" size="xs" />
      <span>{` Evento: ${title}`}</span>
    </>
  ),
  happy_questionaire: () => (
    <>
      <FontAwesomeIcon icon="clipboard-list" size="sm" />
      <span>{` Encuesta Happyfy`}</span>
    </>
  ),
  image: () => (
    <>
      <FontAwesomeIcon icon="camera" size="xs" />
      <span>{' Foto'}</span>
    </>
  ),
  objective: title => (
    <>
      <FontAwesomeIcon icon="trophy" size="xs" />
      <span>{` Objetivo: ${title}`}</span>
    </>
  ),
  questionaire: title => (
    <>
      <FontAwesomeIcon icon="clipboard-list" size="sm" />
      <span>{` Encuesta: ${title}`}</span>
    </>
  )
};

const LastMessage = ({ chat }) => {
  const { lastMessage = {} } = chat;
  const db = useFirestore();
  const {
    ref = doc(db, 'none', 'none'),
    text: textProp = 'Sé el primero en escribir en el chat'
  } = lastMessage;
  const { data = {} } = useFirestoreDocData(ref);
  const { extra, type } = data;
  const { title } = extra || {};
  const text = title || textProp;
  const textToShow = lastMessagePreview[type]?.(text) || text || textProp;
  return <div>{textToShow}</div>;
};
LastMessage.propTypes = {
  chat: PropTypes.object.isRequired
};

export default LastMessage;
