import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { ticketBadges } from 'components/feedback';
import SoftBadge from 'components/common/SoftBadge';
import { FeedbackContext, UserContext } from 'context/Context';

const ContactInfo = ({ feedback, user }) => {
  const { feedback: all = [] } = useContext(FeedbackContext);
  const { me } = useContext(UserContext);
  const { NO_ID_FIELD, avatar, department, email, name, phone } = user || {};
  const userFeedbacks = all
    .sort((f1, f2) => (f1.createdAt < f2.createdAt ? -1 : 1))
    .filter(({ userId }) => userId === NO_ID_FIELD);
  const index = userFeedbacks.findIndex(
    f => f?.NO_ID_FIELD === feedback?.NO_ID_FIELD
  );
  const prevFeedback = userFeedbacks[index - 1];
  let {
    comments,
    createdAt: prevCreatedAt,
    feedback: prevFeedbackText,
    status: statusProp
  } = prevFeedback || {};
  const diff = dayjs().diff(new Date(prevCreatedAt), 'days');
  const isResponded = comments?.some(({ userId }) => userId !== NO_ID_FIELD);
  statusProp =
    statusProp ||
    (isResponded && 'responded') ||
    (diff > 3 ? 'overdue' : 'recent');

  const prevTitle = prevFeedbackText;
  const status = ticketBadges.find(({ value }) => value === statusProp) || {};

  return (
    <Card>
      <FalconCardHeader
        title="Información del empleado"
        titleTag="h6"
        className="py-3 bg-light"
      />
      <Card.Body>
        <Row className="g-0 border-bottom pb-x1 mb-x1 align-items-sm-center align-items-xl-start">
          <Col xs={12} sm="auto" xl={12} className="me-sm-3 me-xl-0">
            <Avatar src={avatar} name={name} size="3xl" />
          </Col>
          <Col xs={12} sm="auto" xl={12}>
            <p className="fw-semi-bold text-800 mb-0">{name}</p>
            <p>{department}</p>
            <Link
              to={`/feedback/contact-details/${NO_ID_FIELD}`}
              className="btn btn-link btn-sm p-0 fe-medium fs--1"
            >
              Ver más detalles
            </Link>
          </Col>
        </Row>
        <Row className="g-0 justify-content-lg-between">
          <Col xs="auto" md={6} lg="auto">
            <Row>
              <Col
                md="auto"
                className={classNames({
                  'mb-4 mb-md-0 mb-xl-4': me?.type === 'admin'
                })}
              >
                <h6 className="mb-1">Email</h6>
                <a href={`mailto:${email}`} className="fs--1">
                  {email}
                </a>
              </Col>
              <Col
                md="auto"
                className={classNames({
                  'mb-4 mb-md-0 mb-xl-4': me?.type === 'admin'
                })}
              >
                <h6 className="mb-1">Teléfono</h6>
                <a href={`tel:${phone}`} className="fs--1">
                  {phone}
                </a>
              </Col>
            </Row>
          </Col>
          {!!prevFeedback && me?.type === 'admin' && (
            <Col xs="auto" md={6} lg="auto" className="ps-md-5 ps-xl-0">
              <div
                className="border-start position-absolute start-50 d-none d-md-block d-xl-none"
                style={{ height: '4.5rem' }}
              ></div>
              <h6 className="d-flex align-items-center mb-1">
                <span className="me-2">Ticket anterior</span>
                <SoftBadge bg={status?.type}>{status?.content}</SoftBadge>
              </h6>
              <Link
                to="/support-desk/tickets-preview"
                className="text-truncate fs--1 font-sans-serif mb-1 d-block text-700"
              >
                {prevTitle}
              </Link>
              <p className="fs--1 mb-0 text-600 fw-semi-bold">
                {dayjs(prevCreatedAt).format('D MMMM, YYYY')}
                <span className="mx-1 ">|</span>
                <span className="fst-italic">
                  {dayjs(prevCreatedAt).format('H:mm')}
                </span>
              </p>
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

ContactInfo.propTypes = {
  feedback: PropTypes.object,
  user: PropTypes.object
};

export default ContactInfo;
