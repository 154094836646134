import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import DropdownFilter from 'components/common/DropdownFilter';
import Flex from 'components/common/Flex';
import Tooltip from 'components/common/Tooltip';
import AppContext, { CalendarContext, UserContext } from 'context/Context';
import { darken, getColor, getColorByName, hexToRgba } from 'helpers/utils';
import { diff } from 'deep-object-diff';
import timeOffSubtypes from 'components/user/time-off/subtypes.json';

const viewName = [
  {
    title: 'Mes',
    value: 'month'
  },
  {
    title: 'Año',
    value: 'year'
  },
  {
    title: 'Lista',
    value: 'list'
  }
];

const viewData = [
  {
    title: 'Ausencias',
    value: 'time-off'
  },
  {
    title: 'Eventos',
    value: 'event'
  },
  {
    title: 'Objetivos',
    value: 'objective'
  }
];

const renderEventContent = eventInfo => {
  const { event = {}, partners, timeText } = eventInfo;
  const { allDay, extendedProps, title } = event;
  const { NO_ID_FIELD, participants, state, type, subtype } = extendedProps;

  // const { icon: subtypeDataIcon = 'suitcase-rolling' } =
  //   timeOffSubtypes.find(({ value }) => value === subtype) || {};
  const subtypeData =
    timeOffSubtypes.find(({ value }) => value === subtype) || {};
  const { emoji, label } = subtypeData;
  let titleToShow = `${title}${label ? ` - ${label}` : ''}`;
  const names =
    participants
      ?.map(ref => {
        const user = partners.find(partner => partner.ref?.path === ref?.path);
        const { name } = user || {};
        return name;
      })
      .join(', ') || 'Toda la empresa';

  if (type === 'objective') {
    titleToShow = `${titleToShow} - ${names}`;
  }

  return (
    <Flex alignItems="center" className="w-100" title={titleToShow}>
      {type === 'holiday' && <div className="me-2">🎉</div>}
      {/* {type === 'objective' && (
        <div className="me-2">
          {classNames({
            '⏳': state === 'on-course',
            '⚠️': state === 'waiting',
            '✅': state === 'success',
            '❌': state === 'fail'
          })}
        </div>
      )} */}
      {type === 'objective' && (
        <FontAwesomeIcon
          className="icon ms-n4 me-3 text-center text-white opacity-50"
          icon={classNames({
            clock: state === 'on-course',
            exclamation: state === 'waiting',
            check: state === 'success',
            times: state === 'fail'
          })}
        />
      )}
      {type === 'time-off' && emoji && <div className="me-2">{emoji}</div>}
      {/* {type === 'time-off' && (
        <FontAwesomeIcon icon={subtypeDataIcon} className="me-2" />
      )} */}
      {timeText && timeText !== '0' && (
        <small className="fw-bold fc-event-time">{timeText}h.</small>
      )}

      <div
        className={classNames(
          'cursor-default w-0 flex-grow-1 fc-event-title text-truncate',
          {
            'fw-semi-bold': allDay,
            'text-accent': type === 'holiday'
          }
        )}
      >
        {titleToShow}
      </div>
      {participants?.length && (
        <Tooltip
          title={names}
          className={classNames('ms-1 justify-content-start', {})}
        >
          <AvatarGroup className="my-n1 me-n1">
            {participants?.map((ref, index) => {
              const user =
                partners.find(partner => partner?.ref?.path === ref?.path) ||
                {};
              const { id, avatar, name } = user;
              return (
                <Avatar
                  key={`Event-${NO_ID_FIELD}-Avatar-${id}-${index}`}
                  src={avatar && avatar}
                  name={name && name}
                  size="m"
                />
              );
            })}
          </AvatarGroup>
        </Tooltip>
      )}
    </Flex>
  );
};

const CalendarSchedule = React.memo(
  ({
    calendarApi,
    calendarRef,
    filter,
    events,
    setModalEventContent,
    setModalObjectiveContent,
    setModalTimeOffContent,
    setIsOpenEventModal,
    setIsOpenObjectiveModal,
    setIsOpenTimeOffModal,
    partners
  }) => {
    const {
      config: { isRTL }
    } = useContext(AppContext);

    const handleEventClick = info => {
      if (info.event.url) {
        window.open(info.event.url);
        info.jsEvent.preventDefault();
      } else {
        const { type } = info?.event?.extendedProps || info?.event || {};
        if (type === 'event') {
          setModalEventContent(info);
          setIsOpenEventModal(true);
        } else if (type === 'objective') {
          setModalObjectiveContent(info);
          setIsOpenObjectiveModal(true);
        } else if (type === 'time-off') {
          setModalTimeOffContent(info);
          setIsOpenTimeOffModal(true);
        }
      }
    };

    return (
      <div className="main-calendar">
        <FullCalendar
          firstDay={1}
          ref={calendarRef}
          headerToolbar={false}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            listPlugin,
            multiMonthPlugin
          ]}
          locale="es"
          initialView="dayGridMonth"
          themeSystem="bootstrap"
          dayMaxEvents={5}
          direction={isRTL ? 'rtl' : 'ltr'}
          height={800}
          slotEventOverlap={false}
          stickyHeaderDates={false}
          selectMirror
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            meridiem: false
          }}
          eventClick={handleEventClick}
          events={events
            .map(item => {
              let {
                allDay,
                approved,
                className,
                end,
                participants,
                start,
                state,
                type
              } = item;
              const names = participants
                ?.map(ref => {
                  const user = partners.find(
                    partner => partner.ref?.path === ref?.path
                  );
                  const { name } = user || {};
                  return name;
                })
                .join(', ');
              const mainColor = getColorByName(names);
              const backgroundColor = hexToRgba(mainColor, 0.3);
              const textColor = darken(mainColor, 50);
              let borderColor = mainColor;

              if (['holiday', 'objective', 'time-off'].includes(type)) {
                allDay = true;
              }
              if (type === 'time-off') {
                className = '';
                if (!approved) {
                  return;
                }
              }
              if (type === 'objective') {
                const color =
                  state === 'success'
                    ? 'success'
                    : state === 'fail'
                    ? 'danger'
                    : state === 'waiting'
                    ? 'yellow'
                    : 'gray-400';
                className = 'objective';
                borderColor = getColor(color);
              }
              const _end =
                end && start !== end
                  ? new Date(
                      new Date(end).setDate(new Date(end).getDate() + 1)
                    ).toISOString()
                  : end;
              return {
                ...item,
                end: _end,
                allDay,
                backgroundColor,
                borderColor,
                className,
                textColor
              };
            })
            ?.filter(
              event =>
                event &&
                (event.type === 'holiday' ||
                  filter.some(({ value }) => value === event.type))
            )}
          allDayContent={() => ''}
          eventContent={params => renderEventContent({ ...params, partners })}
          datesSet={() => {
            setTimeout(() => {
              calendarApi?.updateSize?.();
            }, 0);
          }}
          moreLinkClassNames={() => {
            return 'btn btn-falcon-default btn-sm border-0 w-auto m-0 text-center';
          }}
          moreLinkContent={({ shortText }) => {
            return `${shortText} más`;
          }}
        />
      </div>
    );
  },
  (prev, next) => {
    const eventDiffs = diff(prev.events, next.events);
    const partnerDiffs = diff(prev.partners, next.partners);
    const filterDiffs = diff(prev.filter, next.filter);
    const render =
      Object.keys(eventDiffs).length ||
      Object.values(partnerDiffs).some(
        obj => Object.keys(obj).length > 1 || !obj.status
      ) ||
      Object.keys(filterDiffs).length;
    return !render;
  }
);

CalendarSchedule.propTypes = {
  calendarApi: PropTypes.object,
  calendarRef: PropTypes.object,
  filter: PropTypes.array,
  events: PropTypes.array,
  setModalEventContent: PropTypes.func,
  setModalObjectiveContent: PropTypes.func,
  setModalTimeOffContent: PropTypes.func,
  setIsOpenEventModal: PropTypes.func,
  setIsOpenObjectiveModal: PropTypes.func,
  setIsOpenTimeOffModal: PropTypes.func,
  partners: PropTypes.array
};

const Calendar = () => {
  const {
    initialEvents,
    setModalEventContent,
    setModalObjectiveContent,
    setModalTimeOffContent,
    setIsOpenEventModal,
    setIsOpenObjectiveModal,
    setIsOpenTimeOffModal
  } = useContext(CalendarContext);
  const { me, partners = [] } = useContext(UserContext);
  const { location } = me || {};
  const { holidays = [] } = location || {};
  const calendarRef = useRef();
  const [title, setTitle] = useState('');
  const [calendarApi, setCalendarApi] = useState({});
  const [currentFilter, setCurrentFilter] = useState({
    title: 'Mes',
    value: 'month'
  });
  const [currentDataFilter, setCurrentDataFilter] = useState([
    {
      title: 'Ausencias',
      value: 'time-off'
    },
    {
      title: 'Eventos',
      value: 'event'
    },
    {
      title: 'Objetivos',
      value: 'objective'
    }
  ]);
  const [currentDepartmentFilter, setCurrentDepartmentFilter] = useState({
    title: 'Todos los departamentos',
    value: ''
  });
  const departments = [
    {
      title: 'Todos los departamentos',
      value: ''
    },
    ...[...new Set(partners.map(({ department }) => department))]
      .sort()
      .map(value => ({ title: value, value }))
  ];

  const handleFilter = filter => {
    setCurrentFilter(filter);
    switch (filter.value) {
      case 'month':
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'week':
        calendarApi.changeView('timeGridWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'day':
        calendarApi.changeView('timeGridDay');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'list':
        calendarApi.changeView('listYear');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      default:
        calendarApi.changeView('multiMonthYear');
        setTitle(calendarApi.getCurrentData().viewTitle);
    }
  };

  const handleDataFilter = filter => {
    setCurrentDataFilter(currentFilter =>
      currentFilter.some(({ value }) => value === filter.value)
        ? currentFilter.filter(({ value }) => value !== filter.value)
        : [...currentFilter, filter]
    );
  };

  const handleDepartmentFilter = filter => {
    setCurrentDepartmentFilter(filter);
  };

  useEffect(() => {
    const api = calendarRef?.current?.getApi();
    api && setCalendarApi(api);
  }, [calendarRef?.current]);

  setTimeout(() => {
    calendarApi?.updateSize?.();
  }, 0);

  return (
    <>
      <Card>
        <Card.Header>
          <Row className="align-items-center gx-0">
            <Col xs="auto" className="d-flex justify-content-end order-md-1">
              <Tooltip title="Anterior">
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                  onClick={() => {
                    calendarApi.prev();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
              </Tooltip>

              <Tooltip title="Siguiente">
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                  onClick={() => {
                    calendarApi.next();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </Button>
              </Tooltip>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end order-md-2">
              <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2">
                {title || `${calendarApi.currentDataManager?.data?.viewTitle}`}
              </h4>
            </Col>
            <Col xs md="auto" className="d-flex justify-content-end order-md-3">
              <Button
                size="sm"
                variant="falcon-primary"
                onClick={() => {
                  calendarApi.today();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
              >
                Hoy
              </Button>
            </Col>
            <Col md="auto" className="d-md-none">
              <hr />
            </Col>

            <Col xs="auto" className="d-flex order-md-0">
              <DropdownFilter
                className="me-2"
                filters={viewData}
                currentFilter={currentDataFilter}
                handleFilter={handleDataFilter}
                icon="filter"
                isMulti={true}
              />
            </Col>
            <Col xs="auto" className="d-flex order-md-0">
              <DropdownFilter
                className="me-2"
                filters={departments}
                currentFilter={currentDepartmentFilter}
                handleFilter={handleDepartmentFilter}
                icon="users"
                autoClose={true}
              />
            </Col>
            <Col className="d-flex justify-content-end order-md-2">
              <DropdownFilter
                className="me-2"
                filters={viewName}
                currentFilter={currentFilter}
                handleFilter={handleFilter}
                icon="sort"
                menuParams={{ align: 'end' }}
                autoClose={true}
              />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <CalendarSchedule
            calendarApi={calendarApi}
            calendarRef={calendarRef}
            events={[
              ...(holidays?.map(data => ({
                ...data,
                className: 'bg-accent bg-opacity-25 text-accent',
                eventColor: '#fff',
                type: 'holiday'
              })) || []),
              ...(initialEvents?.filter(({ participants }) => {
                if (!currentDepartmentFilter?.value) {
                  return true;
                }
                const users = partners.filter(partner =>
                  participants?.some(ref => ref?.path === partner?.ref?.path)
                );
                return users?.some(
                  ({ department }) =>
                    department === currentDepartmentFilter?.value
                );
              }) || [])
            ]}
            filter={currentDataFilter}
            setModalEventContent={setModalEventContent}
            setModalObjectiveContent={setModalObjectiveContent}
            setModalTimeOffContent={setModalTimeOffContent}
            setIsOpenEventModal={setIsOpenEventModal}
            setIsOpenObjectiveModal={setIsOpenObjectiveModal}
            setIsOpenTimeOffModal={setIsOpenTimeOffModal}
            partners={partners}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Calendar;
