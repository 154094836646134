import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import AppContext, { UserContext } from 'context/Context';
import Flex from 'components/common/Flex';
import dayjs from 'dayjs';

const getCircleStackIcon = (icon, transform) => (
  <span className="fa-stack ms-n1 me-3">
    <FontAwesomeIcon icon="circle" className="text-200 fa-stack-2x" />
    <FontAwesomeIcon
      icon={icon}
      transform={transform ?? ''}
      className="text-primary fa-stack-1x"
      inverse
    />
  </span>
);

const EventModalMediaContent = ({ icon, heading, content, children }) => (
  <Flex className="mt-3">
    {getCircleStackIcon(icon)}
    <div className="flex-1">
      <h6>{heading}</h6>
      {children || <p className="mb-0 text-justify">{content}</p>}
    </div>
  </Flex>
);

const CalendarEventModal = ({ setIsOpenModal, isOpenModal, modalContent }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { partners = [] } = useContext(UserContext);

  const handleClose = () => {
    setIsOpenModal(!isOpenModal);
  };

  const {
    title,
    end,
    start,
    extendedProps = {}
  } = (isOpenModal && modalContent?.event) || {};
  const { participants = [], subtype } = extendedProps;
  const startDate = dayjs(start).format('dddd, DD MMMM YYYY');
  let _end = new Date(
    new Date(end || start).setDate(new Date(end || start).getDate() + 1)
  );
  while (_end && [6, 0].includes(_end.getDay())) {
    _end.setDate(_end.getDate() + 1);
  }
  const endDate = dayjs(_end).format('dddd, DD MMMM YYYY');
  return (
    <Modal
      show={isOpenModal}
      onHide={handleClose}
      contentClassName="border"
      centered
    >
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className="bg-light px-x1 border-bottom-0"
      >
        <Modal.Title>
          <h5 className="mb-0">{title}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-x1 pb-x1 pt-1 fs--1">
        {subtype === 'lock' ? (
          <>
            <EventModalMediaContent
              icon="lock"
              heading="Fecha de inicio del bloqueo"
            >
              <p className="mb-1">{startDate}</p>
            </EventModalMediaContent>
            <EventModalMediaContent
              icon="lock-open"
              heading="Fecha fin del bloqueo"
            >
              <p className="mb-1">{endDate}</p>
            </EventModalMediaContent>
          </>
        ) : (
          <>
            <EventModalMediaContent
              icon="sign-out-alt"
              heading="Empieza las vacaciones"
            >
              <p className="mb-1">{startDate}</p>
            </EventModalMediaContent>
            {startDate !== endDate && (
              <EventModalMediaContent
                icon="sign-in-alt"
                heading="Vuelve al trabajo"
              >
                <p className="mb-1">{endDate}</p>
              </EventModalMediaContent>
            )}
            {(!participants?.length || participants.length > 1) && (
              <EventModalMediaContent icon="users" heading="Empleados">
                {!participants?.length ? (
                  <span className="text-700">Para toda la empresa</span>
                ) : (
                  participants.map((ref, index) => {
                    const user = partners.find(
                      partner => partner.ref?.path === ref?.path
                    );
                    const { NO_ID_FIELD, name } = user || {};
                    return (
                      <span key={`TimeOff-Participant-${NO_ID_FIELD || index}`}>
                        <Link
                          to={`/user/profile/${NO_ID_FIELD}`}
                          className={classNames('text-700', {
                            'ps-1': index > 0
                          })}
                        >
                          {name || index}
                        </Link>
                        {index < participants.length - 1 && <span>,</span>}
                      </span>
                    );
                  })
                )}
              </EventModalMediaContent>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

EventModalMediaContent.propTypes = {
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string,
  children: PropTypes.node
};

CalendarEventModal.propTypes = {
  setIsOpenModal: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  modalContent: PropTypes.object.isRequired
};

export default CalendarEventModal;
