import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const TextMessage = ({ message }) => {
  const { text } = message;
  const isEmoji = /\p{Extended_Pictographic}/gu;
  const emojis = text.match(isEmoji)?.length;
  const isBig = [...text].length === emojis;
  let textComponents = text
    .trim()
    .replace(/(\w+:\/\/[^ ]*|(\w+)?.\w+\.(com|es|app)[^ ]*)/gi, s => `||${s}||`)
    .split('||')
    .map(text => {
      return text.match(/(\w+:\/\/[^ ]*|\w+\.(com|es|app)[^ ]*)/i) ? (
        <Link
          key={`Text-${text}`}
          to={`https://${text.replace(/(http|https):\/\//i, '')}`}
          target="_blank"
        >
          {text}
        </Link>
      ) : (
        text
      );
    });
  textComponents = Array.isArray(textComponents)
    ? textComponents
    : [textComponents];
  return (
    <span
      className={classNames({ 'fs-3 ': isBig })}
      style={{ whiteSpace: 'break-spaces' }}
    >
      {textComponents.filter(text => text).map(text => text)}
    </span>
  );
};

TextMessage.propTypes = {
  message: PropTypes.object
};

export default TextMessage;
