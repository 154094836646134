import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import image from 'assets/img/icons/spot-illustrations/navbar-vertical.png';

const UpgradeModal = ({ data = {}, setData }) => {
  const navigate = useNavigate();
  const { open, message } = data;

  const close = () => {
    setData(data => ({ ...data, open: false }));
  };

  const handleClick = () => {
    navigate('/subscription');
    close();
  };

  return (
    <Modal
      show={open}
      backdrop="static"
      keyboard={false}
      onHide={close}
      contentClassName="border"
      centered
    >
      <Modal.Header className="bg-light text-center justify-content-center">
        <img src={image} alt="" width={200} style={{ marginTop: '-8rem' }} />
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <Button onClick={handleClick} variant="primary" size="lg">
          <span>Suscripción</span>
          <FontAwesomeIcon icon="angle-right" className="fs--2 ms-1" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

UpgradeModal.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func
};

export default UpgradeModal;
