import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const isMobile = () => {
  const a = navigator.userAgent || navigator.vendor || window.opera;
  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      a
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      a.substr(0, 4)
    )
  );
};
export const isIterableArray = array => Array.isArray(array) && !!array.length;

//===============================
// Breakpoints
//===============================
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540
};

export const getItemFromStore = (key, defaultValue, store = localStorage) => {
  try {
    return store.getItem(key) === null
      ? defaultValue
      : JSON.parse(store.getItem(key));
  } catch {
    return store.getItem(key) || defaultValue;
  }
};

export const setItemToStore = (key, payload, store = localStorage) =>
  store.setItem(key, payload);

export const getStoreSpace = (store = localStorage) =>
  parseFloat(
    (
      escape(encodeURIComponent(JSON.stringify(store))).length /
      (1024 * 1024)
    ).toFixed(2)
  );

//===============================
// Cookie
//===============================
export const getCookieValue = name => {
  const value = document.cookie.match(
    '(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)'
  );
  return value ? value.pop() : null;
};

export const createCookie = (name, value, cookieExpireTime) => {
  const date = new Date();
  date.setTime(date.getTime() + cookieExpireTime);
  const expires = '; expires=' + date.toUTCString();
  document.cookie = name + '=' + value + expires + '; path=/';
};

export const numberFormatter = (number, fixed = 2) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
    ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'K'
    : Math.abs(Number(number)).toFixed(fixed);
};

//===============================
// Colors
//===============================
export const isValidHex = hex =>
  hex.match(/^#([A-Fa-f0-9]{3,4}){1,2}$/) !== null;
const getAlphafloat = (a, alpha) => {
  if (typeof alpha !== 'undefined') {
    if (alpha > 1 && alpha <= 100) {
      return alpha / 100;
    }
    if (alpha >= 0 && alpha <= 1) {
      return alpha;
    }
  }
  if (typeof a !== 'undefined') {
    return a / 255;
  }
  return 1;
};
export const hexToRgb = hexValue => {
  let hex;
  hexValue.indexOf('#') === 0
    ? (hex = hexValue.substring(1))
    : (hex = hexValue);
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  );
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null;
};

export const hexToRgba = (hex, alpha) => {
  if (!isValidHex(hex)) {
    return '';
  }
  const chunkSize = Math.floor((hex.length - 1) / 3);
  let hexArr = getChunksFromString(hex.slice(1), chunkSize);
  hexArr = hexArr.length === 3 ? ['ff', ...hexArr] : hexArr;
  const [a, r, g, b] = hexArr.map(convertHexUnitTo256);
  return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`;
};
export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) =>
  `rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
  '#2c7be5',
  '#00d97e',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343'
];

export const themeColors = {
  accent: '#e81d23',
  primary: '#bc2489',
  secondary: '#748194',
  success: '#00d27a',
  info: '#27bcfd',
  warning: '#f5803e',
  danger: '#e63757',
  light: '#f9fafd',
  dark: '#0b1727',
  orange: '#fd7e14',
  yellow: '#ffc107'
};

export const grays = {
  white: '#fff',
  100: '#f9fafd',
  200: '#edf2f9',
  300: '#d8e2ef',
  400: '#b6c1d2',
  500: '#9da9bb',
  600: '#748194',
  700: '#5e6e82',
  800: '#4d5969',
  900: '#344050',
  1000: '#232e3c',
  1100: '#0b1727',
  black: '#000'
};

export const darkGrays = {
  white: '#fff',
  1100: '#f9fafd',
  1000: '#edf2f9',
  900: '#d8e2ef',
  800: '#b6c1d2',
  700: '#9da9bb',
  600: '#748194',
  500: '#5e6e82',
  400: '#4d5969',
  300: '#344050',
  200: '#232e3c',
  100: '#0b1727',
  black: '#000'
};

function hashCode(str) {
  // java String#hashCode
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i) {
  var c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '#' + '00000'.substring(0, 6 - c.length) + c;
}

const getChunksFromString = (st, chunkSize) =>
  st.match(new RegExp(`.{${chunkSize}}`, 'g'));
const convertHexUnitTo256 = hexStr =>
  parseInt(hexStr.repeat(2 / hexStr.length), 16);

export const isDark = color => {
  const chunkSize = Math.floor((color.length - 1) / 3);
  let hexArr = getChunksFromString(color.slice(1), chunkSize);
  hexArr = hexArr.length === 3 ? ['ff', ...hexArr] : hexArr;
  const [, r, g, b] = hexArr.map(convertHexUnitTo256);

  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map(col => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179;
};

export const getTextColor = (
  bgColor,
  lightColor = '#ffffff',
  darkColor = '#000000'
) => {
  if (!bgColor) {
    return;
  }
  return isDark(bgColor) ? darkColor : lightColor;
};

let colorsByName = {};
export const getColorByName = (name = '') => {
  const hash = hashCode(name);
  colorsByName[name] = colorsByName[name] || intToRGB(hash);
  return colorsByName[name];
};

export const getGrays = isDark => (isDark ? darkGrays : grays);

export const rgbColors = colors.map(color => rgbColor(color));
export const rgbaColors = colors.map(color => rgbaColor(color));

export const getColor = (name, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(`--falcon-${name}`).trim();
};

export const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16).padStart(6, 0)}`;
  } else {
    return hexColor;
  }
};

export const darken = (hexColor, magnitude) => {
  magnitude = -magnitude;
  return newShade(hexColor, magnitude);
};

export const lighten = (hexColor, magnitude) => {
  return newShade(hexColor, magnitude);
};

//===============================
// Arrays
//===============================

export const getUnique = (arr, attribute = 'id') => {
  return arr
    .map(e => e?.[attribute])
    .filter(e => e)
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter(e => arr[e])
    .map(e => arr[e]);
};

export const groupBy = (xs = [], key = '') => {
  if (!Array.isArray(xs)) {
    return xs;
  }
  return xs?.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

//===============================
// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2
});

//===============================
// E-Commerce
//===============================
export const calculateSale = (base, less = 0, fix = 2) =>
  (base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
  cart.reduce((accumulator, currentValue) => {
    const { id, quantity } = currentValue;
    const { price, sale } = baseItems.find(item => item.id === id);
    return accumulator + calculateSale(price, sale) * quantity;
  }, 0);
export const getSubtotal = items =>
  items.reduce((acc, curr) => curr.price * curr.quantity + acc, 0);
export const getDiscountPrice = (total, discount) =>
  total - total * (discount / 100);

export const getProductsQuantity = products =>
  products.reduce((acc, product) => product.quantity + acc, 0);

//===============================
// Helpers
//===============================
export const download = (what, name) => {
  const url = what?.size ? URL.createObjectURL(what) : what;
  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', name);
  a.setAttribute('target', '_blank');
  a.click();
};

export const getPaginationArray = (totalSize, sizePerPage) => {
  const noOfPages = Math.ceil(totalSize / sizePerPage);
  const array = [];
  let pageNo = 1;
  while (pageNo <= noOfPages) {
    array.push(pageNo);
    pageNo = pageNo + 1;
  }
  return array;
};

export const capitalize = str =>
  (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

export const camelize = str => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const dashed = str => {
  return str.toLowerCase().replaceAll(' ', '-');
};

//routes helper

export const flatRoutes = childrens => {
  const allChilds = [];

  const flatChild = childrens => {
    childrens.forEach(child => {
      if (child.children) {
        flatChild(child.children);
      } else {
        allChilds.push(child);
      }
    });
  };
  flatChild(childrens);

  return allChilds;
};

export const getFlatRoutes = children =>
  children.reduce(
    (acc, val) => {
      if (val.children) {
        return {
          ...acc,
          [camelize(val.name)]: flatRoutes(val.children)
        };
      } else {
        return {
          ...acc,
          unTitled: [...acc.unTitled, val]
        };
      }
    },
    { unTitled: [] }
  );

export const routesSlicer = ({ routes, columns = 3, rows }) => {
  const routesCollection = [];
  routes.map(route => {
    if (route.children) {
      return route.children.map(item => {
        if (item.children) {
          return routesCollection.push(...item.children);
        }
        return routesCollection.push(item);
      });
    }
    return routesCollection.push(route);
  });

  const totalRoutes = routesCollection.length;
  const calculatedRows = rows || Math.ceil(totalRoutes / columns);
  const routesChunks = [];
  for (let i = 0; i < totalRoutes; i += calculatedRows) {
    routesChunks.push(routesCollection.slice(i, i + calculatedRows));
  }
  return routesChunks;
};

export const getPageName = pageName => {
  return window.location.pathname.split('/').slice(-1)[0] === pageName;
};

export const copyToClipBoard = textFieldRef => {
  const textField = textFieldRef.current;
  textField.focus();
  textField.select();
  document.execCommand('copy');
};

export const reactBootstrapDocsUrl = 'https://react-bootstrap.github.io';

export const pagination = (currentPage, size) => {
  const pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  let prev = currentPage - 1 - Math.floor(size / 2);

  if (currentPage - 1 - Math.floor(size / 2) < 0) {
    prev = 0;
  }
  if (currentPage - 1 - Math.floor(size / 2) > pages.length - size) {
    prev = pages.length - size;
  }
  const next = prev + size;

  return pages.slice(prev, next);
};

export const tooltipFormatter = params => {
  let tooltipItem = ``;
  params.forEach(el => {
    tooltipItem =
      tooltipItem +
      `<div class='ms-1'> 
        <h6 class="text-700"><span class="fas fa-circle me-1 fs--2" style="color:${
          el.borderColor ? el.borderColor : el.color
        }"></span>
          ${el.seriesName} : ${
        typeof el.value === 'object' ? el.value[1] : el.value
      }
        </h6>
      </div>`;
  });
  return `<div>
            <p class='mb-2 text-600'>
              ${
                dayjs(params[0].axisValue).isValid()
                  ? dayjs(params[0].axisValue).format('MMMM DD')
                  : params[0].axisValue
              }
            </p>
            ${tooltipItem}
          </div>`;
};

export const addIdField = items => {
  return items.map((item, index) => ({
    id: index + 1,
    ...item
  }));
};

export const getCurrencyFormat = amount => {
  const formatter = new Intl.NumberFormat('es', {
    style: 'currency',
    currency: 'EUR'

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(amount);
};

// get file size
export const getSize = size => {
  if (size < 1024) {
    return `${size} Byte`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }
};

/* Get A Random Number */
export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

/* get Dates between */

export const getDates = (
  startDate,
  endDate,
  interval = 1000 * 60 * 60 * 24
) => {
  const duration = endDate - startDate;
  const steps = duration / interval;
  return Array.from(
    { length: steps + 1 },
    (v, i) => new Date(startDate.valueOf() + interval * i)
  );
};

/* Get Past Dates */
export const getPastDates = duration => {
  let days;

  switch (duration) {
    case 'week':
      days = 7;
      break;
    case 'month':
      days = 30;
      break;
    case 'year':
      days = 365;
      break;

    default:
      days = duration;
  }

  const date = new Date();
  const endDate = date;
  const startDate = new Date(new Date().setDate(date.getDate() - (days - 1)));
  return getDates(startDate, endDate);
};

// Add id to items in array
export const addId = items =>
  items.map((item, index) => ({
    id: index + 1,
    ...item
  }));

//
export const getTimeDuration = (startDate, endDate, format = '') => {
  return dayjs.duration(endDate.diff(startDate)).format(format);
};

// Get Percentage
export const getPercentage = (number, percent) => {
  return (Number(number) / 100) * Number(percent);
};

//get chunk from array
export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};

// Slugify text
export const slugifyText = str =>
  str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');

//===============================
// Location
//===============================
function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export const getCurrentPosition = async (params = {}) => {
  const { allowGeoRecall = true, attempts = 3, nearTo } = params;
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };

  const currentPosition = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      success => {
        const { coords } = success;
        const { latitude: lat, longitude: lng } = coords;
        resolve({ lat, lng });
      },
      error => {
        reject(error);
      },
      options
    );
  });

  const distance = nearTo && getDistance(currentPosition, nearTo);
  if (
    !currentPosition?.lat &&
    allowGeoRecall &&
    attempts &&
    distance &&
    distance > 80
  ) {
    return getCurrentPosition({ ...params, attempts: attempts - 1 });
  }

  return currentPosition;
};

export const getDistance = (
  { lat: lat1, lng: lng1 } = {},
  { lat: lat2, lng: lng2 } = {}
) => {
  if (!lat1 || !lat2 || !lng1 || !lng2) {
    return 0;
  }
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1);
  var dLng = deg2rad(lng2 - lng1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d * 1000; // Distance in m
};

//===============================
// SCHEDULES
//===============================

export const getUserSchedule = (schedules, user) => {
  const schedule = schedules?.find(({ users }) =>
    users?.some(ref => ref?.path === user?.ref?.path)
  );
  return schedule;
};

export const getDayStartTime = (schedule, date) => {
  const day = date.getDay();
  const nowISO = date.toISOString().replace(/\d{4}-/, '');
  const period = schedule?.periods
    ?.sort?.((p1, p2) =>
      dayjs(p1.end).diff(p1.start, 'days') <
      dayjs(p2.end).diff(p2.start, 'days')
        ? -1
        : 1
    )
    ?.find(
      ({ end, start }) =>
        (!start || nowISO >= start?.replace?.(/\d{4}-/, '')) &&
        (!end || nowISO <= end?.replace?.(/\d{4}-/, ''))
    );
  const [{ start } = {}] =
    period?.time?.filter(({ days }) => days.includes(day)) || [];
  const [dateStr] = date?.toISOString?.().match(/\d{4}-\d{2}-\d{2}/) || [];
  const startDate = new Date();
  const [, startHours = '08'] = start?.match(/(\d{2}):/) || [];
  const [, startMinutes = '00'] = start?.match(/:(\d{2})/) || [];
  startDate.setHours(parseInt(startHours));
  startDate.setMinutes(parseInt(startMinutes));
  startDate.setSeconds(0);
  const [timeStr] = startDate?.toISOString?.().match(/\d{2}:\d{2}:/) || [];
  return start && `${dateStr}T${timeStr}00.000Z`;
};

export const getDayEndTime = (schedule, date) => {
  const day = date.getDay();
  const nowISO = date.toISOString().replace(/\d{4}-/, '');
  const period = schedule?.periods
    ?.sort?.((p1, p2) =>
      dayjs(p1.end).diff(p1.start, 'days') <
      dayjs(p2.end).diff(p2.start, 'days')
        ? -1
        : 1
    )
    ?.find(
      ({ end, start }) =>
        (!start || nowISO >= start?.replace?.(/\d{4}-/, '')) &&
        (!end || nowISO <= end?.replace?.(/\d{4}-/, ''))
    );
  const [{ end } = {}] = period?.time
    ?.filter(({ days }) => days.includes(day))
    .reverse() || [[]];
  const [dateStr] = date?.toISOString?.().match(/\d{4}-\d{2}-\d{2}/) || [];
  const endDate = new Date();
  const [, endHours = '18'] = end?.match(/(\d{2}):/) || [];
  const [, endMinutes = '00'] = end?.match(/:(\d{2})/) || [];
  endDate.setHours(parseInt(endHours));
  endDate.setMinutes(parseInt(endMinutes));
  const [timeStr] = endDate?.toISOString?.().match(/\d{2}:\d{2}:/) || [];
  return end && `${dateStr}T${timeStr}00.000Z`;
};

export const getDaySeconds = (schedule, date) => {
  const day = date.getDay();
  const nowISO = date.toISOString();
  const period = schedule?.periods
    ?.sort?.((p1, p2) =>
      dayjs(p1.end).diff(p1.start, 'days') <
      dayjs(p2.end).diff(p2.start, 'days')
        ? -1
        : 1
    )
    ?.find(
      ({ end, start }) =>
        (!start ||
          dayjs(nowISO).format('YYYY-MM-DD') >=
            dayjs(start).format('YYYY-MM-DD')) &&
        (!end ||
          dayjs(nowISO).format('YYYY-MM-DD') <= dayjs(end).format('YYYY-MM-DD'))
    );
  const dayTimes = period?.time?.filter(({ days }) => days.includes(day));
  const dayMaxSeconds = dayTimes?.reduce((total, { start, end }) => {
    const startDate = new Date();
    const [, startHours = '00'] = start?.match(/(\d{2}):/) || [];
    const [, startMinutes = '00'] = start?.match(/:(\d{2})/) || [];
    startDate.setHours(parseInt(startHours));
    startDate.setMinutes(parseInt(startMinutes));
    startDate.setSeconds(0);
    const endDate = new Date();
    const [, endHours = '00'] = end?.match(/(\d{2}):/) || [];
    const [, endMinutes = '00'] = end?.match(/:(\d{2})/) || [];
    endDate.setHours(parseInt(endHours));
    endDate.setMinutes(parseInt(endMinutes));
    endDate.setSeconds(0);
    const seconds = dayjs(endDate).diff(startDate, 'seconds');
    return total + seconds;
  }, 0);
  return dayMaxSeconds;
};

export const getTodaySeconds = schedule => {
  const now = new Date();
  return getDaySeconds(schedule, now);
};

export const parseUrls = input => {
  const output = input?.replace(
    /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/g,
    url =>
      `<a class="align-middle d-inline-block max-w-100 text-truncate" title="${url}" href="${url}" target="_blank">${url}</a>`
  );
  return output;
};

// // Workaround: https://github.com/facebook/react/issues/11538#issuecomment-417504600
// if (typeof Node === 'function' && Node.prototype) {
//   const originalRemoveChild = Node.prototype.removeChild;
//   Node.prototype.removeChild = function (child) {
//     if (child.parentNode !== this) {
//       if (console) {
//         // console.error(
//         //   'Cannot remove a child from a different parent',
//         //   child,
//         //   this
//         // );
//       }
//       return child;
//     }
//     return originalRemoveChild.apply(this, arguments);
//   };

//   const originalInsertBefore = Node.prototype.insertBefore;
//   Node.prototype.insertBefore = function (newNode, referenceNode) {
//     if (referenceNode && referenceNode.parentNode !== this) {
//       if (console) {
//         // console.error(
//         //   'Cannot insert before a reference node from a different parent',
//         //   referenceNode,
//         //   this
//         // );
//       }
//       return newNode;
//     }
//     return originalInsertBefore.apply(this, arguments);
//   };
// }
