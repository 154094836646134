import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { UserContext } from 'context/Context';

const LocationSelect = ({ className, ...rest }) => {
  const { locations, selectedLocationId, selectLocationId } =
    useContext(UserContext);

  const handleLocationChange = event => {
    const { target } = event;
    const { value } = target;
    selectLocationId(value);
  };

  return (
    <Form.Select
      className={className}
      value={selectedLocationId}
      onChange={handleLocationChange}
      {...rest}
    >
      {locations?.map(({ NO_ID_FIELD, locality, name }) => (
        <option key={`Location-${NO_ID_FIELD}`} value={NO_ID_FIELD}>
          {name}
          {!!locality && !name.includes(locality) && ` (${locality})`}
        </option>
      ))}
    </Form.Select>
  );
};

LocationSelect.propTypes = {
  className: PropTypes.string
};

export default LocationSelect;
