import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Card } from 'react-bootstrap';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import TimeOff from 'components/user/profile/TimeOff';

const MyNextTimesOff = ({ className }) => {
  return (
    <Card className={className}>
      <div className="overflow-hidden rounded-4 min-h-100">
        <SimpleBarReact className="h-100">
          <Card.Header className="sticky-top">
            <h6 className="m-0">Mis próximas ausencias</h6>
          </Card.Header>
          <Card.Body className="flex-grow-1">
            <TimeOff
              className="shadow-none flex-grow-1"
              bodyClassName="p-0"
              hasFooter={false}
            />
          </Card.Body>
          <FalconCardFooterLink
            className="sticky-bottom"
            title="Todas las ausencias"
            to="/user/time-off"
            size="sm"
          />
        </SimpleBarReact>
      </div>
    </Card>
  );
};

MyNextTimesOff.propTypes = {
  className: PropTypes.string
};

export default MyNextTimesOff;
