import React from 'react';
import AssistantProvider from './AssistantProvider';
import KanbanHeader from './KanbanHeader';
import CRMContainer from './CRMContainer';

const Crm = () => {
  return (
    <>
      <KanbanHeader title="CRM Contratación" />
      <CRMContainer />
    </>
  );
};

const CrmWithProvider = props => {
  return (
    <AssistantProvider>
      <Crm {...props} />
    </AssistantProvider>
  );
};

export default CrmWithProvider;
