import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Card } from 'react-bootstrap';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import Objective from 'components/company/objectives/Objective';
import { CalendarContext, UserContext } from 'context/Context';

const MyObjectives = ({ className }) => {
  const { objectives: data = [] } = useContext(CalendarContext);
  const { me } = useContext(UserContext);
  const myObjectives = data
    .filter(
      ({ participants, validated }) =>
        (!participants || participants?.[0]?.path === me?.ref?.path) &&
        validated
    )
    .sort(({ createdAt: c1 }, { createdAt: c2 }) => (c1 > c2 ? -1 : 1));
  const objectives = myObjectives.filter(
    ({ state }) => !state || ['on-course', 'waiting'].includes(state)
  );

  return (
    <Card className={className}>
      <div className="overflow-hidden rounded-4 min-h-100">
        <SimpleBarReact className="h-100">
          <Card.Header className="sticky-top">
            <h6 className="m-0">Objetivos en curso</h6>
          </Card.Header>
          <Card.Body className="flex-grow-1 fs--1">
            {!objectives?.length && <p>No hay objetivos</p>}
            {objectives?.map((objective, index) => (
              <Objective
                key={`Objective-${objective?.NO_ID_FIELD}`}
                details={objective}
                isLast={index === objectives.length - 1}
              />
            ))}
          </Card.Body>
          <FalconCardFooterLink
            className="sticky-bottom"
            title="Todos los objetivos"
            to="/company/objectives"
            size="sm"
          />
        </SimpleBarReact>
      </div>
    </Card>
  );
};

MyObjectives.propTypes = {
  className: PropTypes.string
};

export default MyObjectives;
