import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { UserContext } from 'context/Context';
import dayjs from 'dayjs';

const SubscriptionInfo = ({ className, bodyClassName }) => {
  const { subscription } = useContext(UserContext);
  const {
    current_period_end: currentPeriodEnd,
    current_period_start: currentPeriodStart,
    start_date: startDate
  } = subscription || {};

  return (
    <Card className={className}>
      <Card.Body className={bodyClassName}>
        <h6>Llevas en Happyfy desde</h6>
        <p>
          {startDate
            ? dayjs(startDate * 1000).format('dddd, DD MMMM YYYY')
            : '-'}
        </p>
        <hr />
        <h6>Inicio del periodo de facturación</h6>
        <p>
          {currentPeriodStart
            ? dayjs(currentPeriodStart * 1000).format('dddd, DD MMMM YYYY')
            : '-'}
        </p>
        <hr />
        <h6>Tu próxima factura se emite</h6>
        <p>
          {currentPeriodEnd
            ? dayjs(currentPeriodEnd * 1000).format('dddd, DD MMMM YYYY')
            : '-'}
        </p>
      </Card.Body>
    </Card>
  );
};

SubscriptionInfo.propTypes = {
  className: PropTypes.string,
  bodyClassName: PropTypes.string
};

export default SubscriptionInfo;
