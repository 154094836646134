import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Alert, Card } from 'react-bootstrap';
import { QuestionairesContext, UserContext } from 'context/Context';
import usePagination from 'hooks/usePagination';
import HappyQuestionaire from './HappyQuestionaire';
import Questionaire from './Questionaire';
import Footer from './Footer';
import QuestionaireProvider from './QuestionaireProvider';

const QuestionairesContent = () => {
  const {
    questionaireState: { questionaires }
  } = useContext(QuestionairesContext);

  const {
    paginationState: {
      data: paginatedQuestionaires,
      totalItems,
      canNextPage,
      canPreviousPage,
      from,
      to
    },
    nextPage,
    prevPage
  } = usePagination(questionaires, 10);

  return (
    <>
      <Card className="mb-3">
        <HappyQuestionaire />
      </Card>
      <Card>
        <Card.Header>
          <h5>Cuestionarios</h5>
        </Card.Header>
        <Card.Body className="fs--1 border-top border-200 p-0">
          {paginatedQuestionaires.length > 0 ? (
            <>
              {questionaires.map(questionaire => (
                <Questionaire
                  key={questionaire.NO_ID_FIELD}
                  questionaire={questionaire}
                />
              ))}
            </>
          ) : (
            <Alert variant="info" className="mb-0 rounded-0">
              <h5 className="alert-heading">No hay cuestionarios</h5>
            </Alert>
          )}
        </Card.Body>
        <Footer
          totalItems={totalItems}
          from={from}
          to={to}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          prevPage={prevPage}
        />
      </Card>
    </>
  );
};

const Questionaires = () => {
  const { me } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    me?.type === 'admin' && navigate('/company/questionaires');
  }, [me?.type]);

  return (
    <QuestionaireProvider>
      <QuestionairesContent />
    </QuestionaireProvider>
  );
};

Questionaires.propTypes = {
  creation: PropTypes.bool,
  templates: PropTypes.bool
};

export default Questionaires;
