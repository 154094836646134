import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';

const DeletedMessage = () => {
  return (
    <Flex alignItems="baseline">
      <FontAwesomeIcon className="me-1" icon="ban" />{' '}
      <span className="fst-italic">Mensaje eliminado</span>
    </Flex>
  );
};

export default DeletedMessage;
