import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form } from 'react-bootstrap';
import { UserContext } from 'context/Context';

const ProfileDescription = ({ className, user }) => {
  const { me, updateUser } = useContext(UserContext);
  const { profile } = user || {};
  const { description: descriptionProp = '' } = profile || {};
  const [description, setDescription] = useState(descriptionProp);

  const handleChange = ({ target }) => {
    const { value: description } = target;
    setDescription(description);
  };

  const handleBlur = () => {
    updateUser(user, { 'profile.description': description });
  };

  useEffect(() => {
    setDescription(descriptionProp);
  }, [descriptionProp]);

  return (
    <Card className={className}>
      <Card.Header className="bg-light">
        <h5 className="mb-0">Descripción profesional</h5>
      </Card.Header>

      <Card.Body className="text-justify text-1000">
        {me?.NO_ID_FIELD === user?.NO_ID_FIELD ? (
          <Form.Control
            as="textarea"
            rows={6}
            placeholder="Escribe un breve resumen de tu carrera profesional"
            value={description}
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        ) : (
          <p className="font-sans-serif text-pre-wrap">{description}</p>
        )}
      </Card.Body>
    </Card>
  );
};

ProfileDescription.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object
};

export default ProfileDescription;
