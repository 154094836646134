import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import Flex from 'components/common/Flex';
import { Posts } from 'components/company/feed/FeedContent';
import { FeedContext, UserContext } from 'context/Context';

const LastFeed = ({ className }) => {
  const { feeds = [], fetchMore } = useContext(FeedContext);
  const { company } = useContext(UserContext);
  const { NO_ID_FIELD: companyId } = company || {};
  const [lastFeed] = feeds || [];

  useEffect(() => {
    companyId && !lastFeed && fetchMore(1);
  }, [companyId, lastFeed]);

  return (
    <Card className={className}>
      <Flex direction="column" className="h-100 overflow-hidden rounded-4">
        <Card.Header>
          <h6 className="m-0">Última noticia</h6>
        </Card.Header>
        <Card.Body className="p-0 bg-light h-0 flex-grow-1">
          <SimpleBarReact className="h-100">
            <Posts className="shadow-none" feeds={[lastFeed]} />
          </SimpleBarReact>
        </Card.Body>
        <FalconCardFooterLink title="Todas las noticias" to="/feed" size="sm" />
      </Flex>
    </Card>
  );
};

LastFeed.propTypes = {
  className: PropTypes.string
};

export default LastFeed;
