import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Badge, Col, Row } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import IndexGraph from 'components/charts/IndexGraph';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import types from 'components/dashboards/types.json';
import { QuestionairesContext, UserContext } from 'context/Context';
import { toast } from 'react-toastify';
import { getColor, getUnique } from 'helpers/utils';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import logo from 'assets/img/logo.png';

dayjs.extend(relativeTime);
dayjs.locale('es');

const HappyIndex = ({ type, values }) => {
  let { [type]: value = '-' } = values;
  if (isNaN(value) || value === null) {
    value = '-';
  }
  let color =
    (isNaN(value) && 'none') ||
    (value > 75 && getColor('success')) ||
    (value > 60 && getColor('warning')) ||
    getColor('danger');

  if (type === 'stress') {
    color =
      (isNaN(value) && 'none') ||
      (value > 80 && getColor('danger')) ||
      (value > 60 && getColor('warning')) ||
      getColor('success');
  }
  return (
    <div className="flex-grow-1 p-3 text-center">
      <IndexGraph title={value} color={color} data={value} />
      <small className="text-capitalize">{types[type]}</small>
    </div>
  );
};

HappyIndex.propTypes = {
  type: PropTypes.string,
  values: PropTypes.object
};

const HappyQuestionaire = () => {
  const { happyResponses: responsesRaw = [], month: _month } =
    useContext(QuestionairesContext);
  const { defaults, me, partners = [] } = useContext(UserContext);
  const month = dayjs(_month).format('MMMM');
  const { happy_questionaire: happyQuestionaire = {} } = defaults || {};
  const { definitions = [], subtitle } = happyQuestionaire;

  const responses = getUnique(responsesRaw, 'userId');
  const newResponses = responses.filter(({ new: isNew }) => isNew);
  const hasResponded = responses.length === partners.length;
  const valueResponses =
    responses?.map(({ responses }) =>
      responses
        .map(({ answer }) => parseInt(answer))
        .filter(value => !isNaN(value))
    ) || [];

  const values = valueResponses
    .reduce((all, values) => {
      return values.map((value, index) => (all[index] || 0) + value);
    }, [])
    .reduce((all, total, index) => {
      const { source } = definitions?.[index] || {};
      const average = Math.round(total / responses.length);
      return { ...all, [source]: average };
    }, {});
  values.happiness = Math.round(
    responses.reduce((total, { userId }) => {
      const user = partners?.find(({ NO_ID_FIELD }) => NO_ID_FIELD === userId);
      const { happiness } = user || {};
      return total + (happiness || 0);
    }, 0) / responses.length
  );

  const handleSendQuestionaire = async () => {
    const usersToRemind = partners.filter(
      ({ NO_ID_FIELD }) =>
        !responses.some(({ userId }) => userId === NO_ID_FIELD)
    );

    try {
      await Promise.all(
        usersToRemind.map(async ({ NO_ID_FIELD }) => {
          try {
            await fetch(
              `${process.env.REACT_APP_FIREBASE_URL}/sendHappyQuestionaire?userId=${NO_ID_FIELD}`
            );
          } catch (error) {
            console.error(error);
          }
          return;
        })
      );

      toast.success(`Cuestionario enviado`);
    } catch (error) {
      toast.error(`Ha habido un error al enviar el cuestionario`);
    }
  };

  return (
    <div
      className={classNames(
        'hover-actions-trigger hover-shadow rounded align-items-center fs--1',
        {
          'bg-light': hasResponded,
          'fw-bold': !hasResponded,
          'bg-soft-accent': !!newResponses.length
        }
      )}
    >
      <Row className="py-3 mx-0">
        <Col>
          <Flex alignItems="center">
            <Avatar src={logo} size="m" isExact={false} className="me-2" />
            <Link
              className="d-block inbox-link"
              to={
                me?.type === 'admin'
                  ? '/company/happy-questionaire/detail'
                  : '/user/questionaires/happyfy'
              }
            >
              <Flex alignItems="center">
                <span className="text-dark">
                  Cuestionario de felicidad de {month}
                </span>
                <span className="mx-1 fw-normal">&ndash;</span>
                <span className="text-600 fw-normal">{subtitle}</span>
                {!!newResponses.length && (
                  <Badge bg="accent" className="ms-2">
                    {newResponses.length} nuevo(s)
                  </Badge>
                )}
              </Flex>
            </Link>
          </Flex>
        </Col>
        <Col xs="auto" className="ms-auto align-self-start">
          <IconButton
            variant="falcon-primary"
            size="sm"
            icon="paper-plane"
            onClick={handleSendQuestionaire}
            transform="shrink-3"
          >
            Recordar
          </IconButton>
        </Col>
      </Row>
      <Row className="mx-0">
        <Col xs={12} lg={4}>
          <div className="ps-4 pt-2">
            <h1 className="m-0">
              {Math.round(((responses?.length || 0) / partners.length) * 100)}%
              <SoftBadge bg="success" className="ms-1 fs--2">
                {responses?.length || 0}/{partners.length}
              </SoftBadge>
            </h1>
            <small>Tasa de participación</small>
          </div>
        </Col>
        <Col xs={12} lg={8}>
          <SimpleBarReact>
            <Flex justifyContent="end">
              <Flex
                className="flex-grow-1 flex-lg-grow-0"
                justifyContent="between"
              >
                <HappyIndex type="happiness" values={values} />
                {Object.keys(types)
                  .filter(key => key !== 'happiness')
                  .sort((key1, key2) => (types[key1] < types[key2] ? -1 : 1))
                  .map(type => (
                    <HappyIndex
                      key={`Happy-Index-${type}`}
                      type={type}
                      values={values}
                    />
                  ))}
              </Flex>
            </Flex>
          </SimpleBarReact>
        </Col>
      </Row>
    </div>
  );
};

export default HappyQuestionaire;
