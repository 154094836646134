import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import PendingTimesOffContent from 'components/user/time-off/PendingTimesOff';

const PendingTimesOff = ({ className }) => {
  return (
    <Card className={className}>
      <Card.Header>
        <h6 className="m-0">Ausencias pendientes</h6>
      </Card.Header>
      <Card.Body className="pt-0 overflow-auto">
        <PendingTimesOffContent className="m-n3 h-100 shadow-none" />
      </Card.Body>
    </Card>
  );
};

PendingTimesOff.propTypes = {
  className: PropTypes.string
};

export default PendingTimesOff;
