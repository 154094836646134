import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { Card, Col, ListGroup } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';

const UserItem = ({ count = '-', index, unit, user }) => {
  const { avatar, name = '-' } = user || {};
  return (
    <ListGroup.Item>
      <Flex className="p-0" alignItems="center">
        <SoftBadge pill bg="primary">
          {index + 1}
        </SoftBadge>
        <Avatar className="mx-3" src={avatar} name={name} size="xl" />
        <div className="text-nowrap text-truncate">
          <h6 className="m-0">{name}</h6>
          <span className="fs--1 text-600">
            {count} {unit}
          </span>
        </div>
      </Flex>
    </ListGroup.Item>
  );
};

const RankingUserList = ({ data: raw, unit }) => {
  const data = raw?.slice(3, 8);

  if (!data?.length) {
    return null;
  }

  return (
    <Col xs={12}>
      <Card className="border border-3 h-100 shadow-none">
        <Card.Body>
          <ListGroup variant="flush">
            {data.map((item, index) => (
              <UserItem
                key={`Ranking-User-${item?.user?.NO_ID_FIELD || uuid()}`}
                {...item}
                index={index + 3}
                unit={unit}
              />
            ))}
          </ListGroup>
        </Card.Body>
      </Card>
    </Col>
  );
};

UserItem.propTypes = {
  count: PropTypes.number,
  index: PropTypes.number,
  unit: PropTypes.string,
  user: PropTypes.object
};

RankingUserList.propTypes = {
  data: PropTypes.array,
  unit: PropTypes.string
};

export default RankingUserList;
