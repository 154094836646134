import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { diff } from 'deep-object-diff';
import { AssistantContext } from 'context/Context';
import CandidateModal from './CandidateModal';
import { UserContext } from 'context/Context';
import { kanbanReducer } from 'reducers/kanbanReducer';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch
} from '@firebase/firestore';
import {
  deleteObject,
  ref,
  getDownloadURL,
  uploadBytes
} from '@firebase/storage';
import {
  useFirestore,
  useFirestoreCollectionData,
  useStorage
} from 'reactfire';

const defaultKanbanItems = [
  {
    id: 'offers',
    name: 'Ofertas de trabajo',
    unit: 'oferta de trabajo',
    items: []
  },
  {
    id: 'candidates',
    name: 'Candidatos',
    unit: 'candidato',
    items: []
  }
];

const languagesList = [
  { value: 'es', label: 'Español' },
  { value: 'en', label: 'Inglés' },
  { value: 'fr', label: 'Francés' },
  { value: 'de', label: 'Alemán' },
  { value: 'ch', label: 'Chino' }
];

const AssistantProvider = ({ children }) => {
  const db = useFirestore();
  const storage = useStorage();
  const [isLoading] = useState(false);
  const { company, me } = useContext(UserContext);
  const [offerModal, showOfferModal] = useState();
  const [candidateModal, showCandidateModal] = useState();
  const initData = {
    kanbanItems: defaultKanbanItems
  };
  let offersQuery = query(collection(db, 'none'));
  let candidatesQuery = query(collection(db, 'none'));
  let columnsQuery = query(collection(db, 'none'));
  if (company?.NO_ID_FIELD) {
    offersQuery = query(
      collection(db, 'offers'),
      where('companyId', '==', company?.NO_ID_FIELD),
      orderBy('createdAt', 'desc')
    );
    candidatesQuery = query(
      collection(db, 'candidates'),
      where('companyId', '==', company?.NO_ID_FIELD),
      orderBy('createdAt', 'desc')
    );
    columnsQuery = query(
      collection(db, 'columns'),
      where('companyId', '==', company?.NO_ID_FIELD)
    );
  }
  let { data: offers = [] } = useFirestoreCollectionData(offersQuery);
  let { data: candidates = [] } = useFirestoreCollectionData(candidatesQuery);
  let { data: columns = [] } = useFirestoreCollectionData(columnsQuery);

  const [kanbanState, kanbanDispatch] = useReducer(kanbanReducer, initData);

  const createColumn = useCallback(
    async ({ title }) => {
      const createdAt = new Date().toISOString();
      await addDoc(collection(db, 'columns'), {
        companyId: company?.NO_ID_FIELD,
        createdAt,
        createdBy: me?.NO_ID_FIELD,
        title
      });
    },
    [company?.NO_ID_FIELD, me?.NO_ID_FIELD]
  );

  const removeColumn = async id => {
    let index =
      candidates?.filter(({ column }) => column === 'candidates').length || 0;
    const batch = writeBatch(db);
    const candidatesToMove = candidates.filter(({ column }) => column === id);
    candidatesToMove.forEach(card => {
      batch.update(doc(db, 'candidates', card?.NO_ID_FIELD), {
        column: 'candidates',
        index
      });
      index++;
    });
    batch.delete(doc(db, 'columns', id));
    await batch.commit();
  };

  const createOffer = useCallback(
    async options => {
      let { department, languages, text, index = 0 } = options;
      let extra = {};
      if (text) {
        extra = {
          data: {
            completion: languages.map(language => ({
              language:
                languagesList.find(({ label }) => label === language)?.value ||
                language,
              text
            }))
          }
        };
      }
      const languagesTxt = languages.join(', ');
      const createdAt = new Date().toISOString();
      await addDoc(collection(db, 'offers'), {
        ...options,
        companyId: company?.NO_ID_FIELD,
        createdAt,
        createdBy: me?.NO_ID_FIELD,
        index,
        state: Object.keys(extra?.data || {}).length ? 'success' : 'loading',
        title: `${department} (${languagesTxt})`,
        ...extra
      });
    },
    [company?.NO_ID_FIELD, me?.NO_ID_FIELD]
  );

  const removeOffer = async id => {
    await deleteDoc(doc(db, 'offers', id));
  };

  const createCandidate = useCallback(
    async options => {
      const createdAt = new Date().toISOString();
      const { cv, index, offerId } = options;
      const candidateDoc = await addDoc(collection(db, 'candidates'), {
        column: 'candidates',
        companyId: company?.NO_ID_FIELD,
        createdAt,
        createdBy: me?.NO_ID_FIELD,
        index,
        offerId,
        state: 'loading'
      });

      const cvRef = ref(
        storage,
        `companies/${company?.NO_ID_FIELD}/cvs/${candidateDoc.id}.pdf`
      );
      await uploadBytes(cvRef, cv);
    },
    [company?.NO_ID_FIELD, me?.NO_ID_FIELD]
  );

  const getCVDownloadUrl = async candidate => {
    const { NO_ID_FIELD, companyId } = candidate;
    return await getDownloadURL(
      ref(storage, `/companies/${companyId}/cvs/${NO_ID_FIELD}.pdf`)
    );
  };

  const removeCandidate = async id => {
    try {
      const fileRef = ref(
        storage,
        `/companies/${company?.NO_ID_FIELD}/cvs/${id}.pdf`
      );
      await deleteObject(fileRef);
    } catch (error) {
      console.error(error);
    }
    await deleteDoc(doc(db, 'candidates', id));
  };

  const processCandidate = candidateId => {
    fetch(`${process.env.REACT_APP_FIREBASE_URL}/createCandidate`, {
      method: 'POST',
      body: JSON.stringify({
        companyId: company?.NO_ID_FIELD,
        candidateId
      })
    }).catch(error => console.error(error));
  };

  const reorderCards = async ({ source, destination }) => {
    const batch = writeBatch(db);
    source?.items.forEach((card, index) => {
      const collection = card.column ? 'candidates' : 'offers';
      card.index !== index &&
        batch.update(doc(db, collection, card?.NO_ID_FIELD), { index });
    });
    destination?.items.forEach((card, index) => {
      const collection = card.column ? 'candidates' : 'offers';
      (card.index !== index || card.column !== destination.column) &&
        batch.update(doc(db, collection, card?.NO_ID_FIELD), {
          column: destination.column,
          index
        });
    });
    await batch.commit();
  };

  // const updateCard = async (card, params) => {
  //   const updatedAt = new Date().toISOString();
  //   const collection = card.column === 'offers' ? 'offers' : 'candidates';
  //   const cardRef = await updateDoc(doc(db, collection, card?.NO_ID_FIELD), {
  //     updatedAt,
  //     ...params
  //   });
  //   return cardRef;
  // };

  const updateColumn = async (column, params) => {
    const updatedAt = new Date().toISOString();
    const columnRef = await updateDoc(doc(db, 'columns', column?.NO_ID_FIELD), {
      ...params,
      updates: [
        ...(column.updates || []),
        {
          params,
          at: updatedAt,
          by: me?.NO_ID_FIELD
        }
      ]
    });
    return columnRef;
  };

  useEffect(() => {
    if (!company?.NO_ID_FIELD) {
      return;
    }
    const offersColumn = {
      id: 'offers',
      name: 'Ofertas',
      items: offers
        ?.sort(({ index: i1 }, { index: i2 }) => (i1 < i2 ? -1 : 1))
        .map(params => ({
          id: params?.NO_ID_FIELD,
          ...params
        })),
      unit: 'oferta de trabajo'
    };

    const candidatesColumn = {
      id: 'candidates',
      name: 'Candidatos',
      items: candidates
        ?.filter(({ column }) => column === 'candidates')
        .sort(({ index: i1 }, { index: i2 }) => (i1 < i2 ? -1 : 1))
        .map(params => ({
          id: params?.NO_ID_FIELD,
          ...params
        })),
      unit: 'candidato'
    };

    const lists =
      columns
        ?.sort(({ index: i1 }, { index: i2 }) => (i1 < i2 ? -1 : 1))
        .map(params => ({
          id: params?.NO_ID_FIELD,
          name: params?.title,
          items:
            candidates
              ?.filter(({ column }) => column === params?.NO_ID_FIELD)
              .map(params => ({
                id: params?.NO_ID_FIELD,
                ...params
              })) || [],
          ...params
        })) || [];

    diff(kanbanState?.kanbanItems, [
      offersColumn,
      candidatesColumn,
      ...lists
    ]) &&
      kanbanDispatch({
        type: 'RESET_KANBAN_COLUMNS',
        payload: [offersColumn, candidatesColumn, ...lists]
      });
  }, [company?.NO_ID_FIELD, candidates, columns, offers]);

  // console.log('ASSISTANT >>>', kanbanState.kanbanItems);

  return (
    <AssistantContext.Provider
      value={{
        candidateModal,
        candidates,
        createCandidate,
        createColumn,
        createOffer,
        getCVDownloadUrl,
        isLoading,
        kanbanDispatch,
        kanbanState,
        offerModal,
        offers,
        processCandidate,
        removeCandidate,
        removeColumn,
        removeOffer,
        reorderCards,
        showCandidateModal,
        showOfferModal,
        updateColumn
      }}
    >
      {children}
      <CandidateModal
        show={!!candidateModal}
        candidate={candidateModal || {}}
      />
    </AssistantContext.Provider>
  );
};

AssistantProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AssistantProvider;
