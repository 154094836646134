import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { UserContext } from 'context/Context';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';

const styles = {
  control: base => ({
    ...base,
    backgroundColor: 'transparent !important',
    border: 'none !important',
    minHeight: '28px !important',
    marginTop: '-0.25rem !important',
    marginBottom: '-0.25rem !important',
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
    boxShadow: 'none !important'
  }),
  dropdownIndicator: base => ({
    ...base,
    display: 'none'
  }),
  clearIndicator: base => ({
    ...base,
    display: 'none'
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  }),
  menuPortal: base => ({
    ...base,
    marginTop: '-0.5rem !important',
    marginLeft: '-0.5rem !important'
  }),
  menu: base => ({
    ...base,
    minWidth: '200px'
  }),
  valueContainer: base => ({
    ...base,
    marginTop: '-0.25rem !important',
    marginBottom: '-0.25rem !important',
    paddingTop: 0,
    paddingBottom: 0
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: 'rgba(255,255,255,0.35) !important',
    height: '22px'
  })
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center'
};

const AddItem = ({
  color = 'primary',
  profileKey: key,
  options,
  placeholder,
  user
}) => {
  const { updateUser } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [text, setText] = useState('');

  const handleChange = ({ target }) => {
    setText(target?.value);
  };

  const handleChangeSelect = selected => {
    setText(selected.map(({ value }) => value));
  };

  const handleCancel = () => {
    setShow(false);
    setText('');
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!text) {
      return;
    }
    const { profile = {} } = user;
    const _text = Array.isArray(text) ? text : [text];
    profile[key] = [...(profile?.[key] || []), ..._text];

    updateUser(user, { profile });
    setText('');
    setShow(false);
  };

  return (
    <SoftBadge bg={color} className="me-1 mb-1 text-wrap text-start lh-base">
      {!show ? (
        <Button
          variant="link"
          className={`m-0 p-0 shadow-none lh-1 badge-soft-${color}`}
          onClick={() => setShow(true)}
        >
          <FontAwesomeIcon icon="plus" size="sm" />
        </Button>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Flex alignItems="center">
            {options?.length ? (
              <CreatableSelect
                closeMenuOnSelect={false}
                menuPortalTarget={document.body}
                formatGroupLabel={formatGroupLabel}
                isMulti
                isSearchable
                menuPlacement="auto"
                classNamePrefix="react-select"
                options={options}
                placeholder={placeholder}
                autoFocus={true}
                styles={styles}
                onChange={handleChangeSelect}
              />
            ) : (
              <Form.Control
                size="sm"
                className="fw-semi-bold mt-n1 shadow-none mb-n1 px-0 border-0 bg-transparent fs--1 fw-semibold"
                placeholder={placeholder}
                onChange={handleChange}
                autoFocus
              />
            )}
            <div
              className="cursor-pointer px-1 hover-opacity-50"
              onClick={handleCancel}
            >
              <FontAwesomeIcon icon="times" />
            </div>
            <div
              className="cursor-pointer px-1 hover-opacity-50"
              onClick={handleSubmit}
            >
              <FontAwesomeIcon icon="check" />
            </div>
          </Flex>
        </Form>
      )}
    </SoftBadge>
  );
};

AddItem.propTypes = {
  color: PropTypes.string,
  options: PropTypes.array,
  profileKey: PropTypes.string,
  placeholder: PropTypes.string,
  user: PropTypes.object
};

export default AddItem;
