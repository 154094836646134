import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { UserContext } from 'context/Context';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import Wallet from 'components/user/benefits/Wallet';

const ProfileDropdown = () => {
  const { me } = useContext(UserContext);
  let { avatar, department, email, name, status } = me || {};
  status = ['offline', 'online'].includes(status) ? status : 'offline';

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        className="pe-0 ps-2 nav-link"
      >
        <Avatar
          key={`My-Avatar-${me?.NO_ID_FIELD}`}
          className={`status-${status}`}
          src={avatar && avatar}
          name={name && name}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Header>
            <Flex alignItems="center">
              <div className="p-2">
                <Avatar size="3xl" src={avatar} name={name} />
              </div>
              <div className="p-2">
                <div className="fs--1">{name}</div>
                <div className="fs--2 text-400">{department}</div>
                <div className="fs--2 text-400 text-lowercase">{email}</div>
              </div>
            </Flex>
          </Dropdown.Header>

          <Dropdown.Item
            as={Link}
            to="/user/benefits"
            className="d-block bg-white"
          >
            <Wallet
              headerClassName="fs--1"
              amountClassName="display-5"
              showRewards={false}
            />
          </Dropdown.Item>

          <Dropdown.Divider className="border-0" />

          <Dropdown.Item as={Link} to="/me">
            Cuenta
          </Dropdown.Item>

          <Dropdown.Item as={Link} to="/user/benefits">
            Beneficios
          </Dropdown.Item>

          <Dropdown.Divider />

          <Dropdown.Item as={Link} to="/authentication/logout">
            Cerrar sesión
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
