import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import dayjs from 'dayjs';

const AgentSelect = ({ agent, className, style }) => {
  const { partners = [] } = useContext(UserContext);
  return (
    <Form.Select
      style={style}
      className={className}
      size="sm"
      defaultValue={agent}
    >
      <option key={`Agent-none`}>Selecciona al encargado</option>
      {partners
        .filter(({ level, type }) => level || type === 'admin')
        .map(({ NO_ID_FIELD, name }) => (
          <option key={`Agent-${NO_ID_FIELD}`} value={NO_ID_FIELD}>
            {name}
          </option>
        ))}
    </Form.Select>
  );
};

AgentSelect.propTypes = {
  agent: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object
};

const Notes = () => {
  const { deleteNote, notes, partners, selectedUser } = useContext(UserContext);

  const handleDelete = note => {
    deleteNote(selectedUser?.NO_ID_FIELD, note?.NO_ID_FIELD);
  };

  return (
    <Flex direction="column" className="gap-3">
      {!notes?.length && <div>No hay notas</div>}
      {notes.map(note => {
        const { NO_ID_FIELD, createdAt, description, from, title } = note;
        const userFrom = partners?.find(({ ref }) => ref?.path === from?.path);
        return (
          <div
            key={`Note-${NO_ID_FIELD}`}
            className="bg-white dark__bg-1100 p-x1 rounded-3 shadow-sm"
          >
            <Row className="flex-between-center">
              <Col
                xs={12}
                md={7}
                xl={12}
                xxl={8}
                className="order-1 order-md-0 order-xl-1 order-xxl-0"
              >
                <h5 className="mb-0 border-top border-md-0 border-xl-top border-xxl-0 mt-x1 mt-md-0 mt-xl-x1 mt-xxl-0 pt-x1 pt-md-0 pt-xl-x1 pt-xxl-0 border-200 border-xl-200">
                  {title}
                </h5>
              </Col>
              <Col
                xs={12}
                md="auto"
                xl={12}
                xxl="auto"
                className="d-flex flex-between-center"
              >
                <Dropdown
                  align="end"
                  className="btn-reveal-trigger d-inline-block"
                >
                  <Dropdown.Toggle split variant="falcon-default" size="sm">
                    <FontAwesomeIcon icon="ellipsis-h" className="fs--2" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="border py-0">
                    <div className="py-2">
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => handleDelete(note)}
                      >
                        Remove
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <h6 className="mb-0 mt-2">
              <FontAwesomeIcon icon="clock" className="text-primary me-2" />
              <span className="text-600">
                {dayjs(createdAt).format('D MMM YYYY, HH:MM')}
              </span>
            </h6>
            <h6 className="mb-0 mt-2">
              <FontAwesomeIcon
                icon="user-circle"
                className="text-primary me-2"
              />
              <span className="text-600">{userFrom?.name}</span>
            </h6>
            <p className="w-lg-75 w-xl-100 w-xxl-75 mb-0 border-top border-md-0 border-xl-top border-xxl-0 mt-x1 mt-md-4 mt-xl-x1 mt-xxl-4 pt-x1 pt-md-0 pt-xl-x1 pt-xxl-0 border-200 border-xl-200">
              {description}
            </p>
          </div>
        );
      })}
    </Flex>
  );
};

export default Notes;
