import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { doc, deleteField } from '@firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import UserList from 'components/common/UserList';
import { CalendarContext, ChatContext, UserContext } from 'context/Context';
import { useFirestore, useFirestoreDocData } from 'reactfire';

const AssistantsList = ({ data, title }) => {
  const { partners = [] } = useContext(UserContext);
  return (
    <>
      <div>
        <h5>{title}</h5>
      </div>
      <UserList
        className="shadow-none"
        users={data.map(({ ref }) => {
          const user =
            partners.find(partner => partner?.ref?.path === ref?.path) || {};
          return user;
        })}
        tableProps={{
          pagination: true,
          perPage: 10
        }}
      />
    </>
  );
};

const EventMessage = ({ message }) => {
  const db = useFirestore();
  const { me, partners } = useContext(UserContext);
  const { currentChat, setModalChildren, showModal, updateChat } =
    useContext(ChatContext);
  const { update } = useContext(CalendarContext);
  const { NO_ID_FIELD, chatId, extra, messageRef } = message || {};
  const { calendarRef, title, date, location } = extra || {};
  const { data: calendarData } = useFirestoreDocData(
    calendarRef || doc(db, 'none', 'none')
  );
  const { assistance = [] } = calendarData || {};
  const handleAssist = useCallback(
    async assist => {
      let _assistance = assistance.filter(
        user => user?.ref?.path !== me?.ref.path
      );
      const createdAt = new Date(Date.now()).toISOString();
      _assistance = [..._assistance, { ref: me?.ref, assist, createdAt }];
      await update(calendarData, { assistance: _assistance });
      const assistText = {
        true: 'va a asistir',
        false: 'no va a asistir',
        null: 'puede que asista'
      }[assist];
      const ref = doc(db, 'chat_messages', NO_ID_FIELD);
      await updateChat(chatId, {
        lastMessage: {
          ref,
          text: `${title}: ${me?.name} ${assistText}`
        },
        updatedAt: new Date().toISOString()
      });
      if (currentChat?.type === 'user' && currentChat?.deletedTo) {
        await updateChat(currentChat?.NO_ID_FIELD, {
          deletedTo: deleteField()
        });
      }
    },
    [assistance, me?.ref, messageRef, extra, currentChat]
  );

  const dontKnow = assistance.filter(({ assist }) => assist === null);
  const go = assistance.filter(({ assist }) => assist === true);
  const dontGo = assistance.filter(({ assist }) => assist === false);

  const { assist } =
    assistance.find(({ ref }) => ref?.path === me?.ref?.path) || {};

  const handleShowAssistants = (show, { title, assistance }) => {
    const data = assistance.filter(({ assist }) => assist === assistance);
    setModalChildren(<AssistantsList data={data} title={title} />);
    showModal(true);
  };

  return (
    <>
      <h5 className="mb-1">{title}</h5>
      {calendarData && (
        <div className="d-grid mb-3">
          <Button
            as={Link}
            to={`/company/events/${calendarData.NO_ID_FIELD}`}
            variant="falcon-primary"
            className="rounded-pill"
            size="sm"
          >
            <span>Ver más detalles</span>
            <FontAwesomeIcon icon="angle-right" className="fs--2 ms-1" />
          </Button>
        </div>
      )}
      <Flex alignItems="center" className="mb-2">
        <FontAwesomeIcon icon="map-marker-alt" className="me-2" />
        <div className="fs--1">{location}</div>
      </Flex>
      <Flex alignItems="center" className="mb-2">
        <FontAwesomeIcon icon="calendar-day" className="me-2" />
        <div className="fs--1">
          {new Intl.DateTimeFormat('es', {
            weekday: 'long',
            day: 'numeric',
            year: 'numeric',
            month: 'long'
          }).format(new Date(date))}
        </div>
      </Flex>
      <Flex alignItems="center" className="mb-3">
        <FontAwesomeIcon icon="clock" className="me-2" />
        <div className="fs--1">
          {new Intl.DateTimeFormat('es', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
          }).format(new Date(date))}
        </div>
      </Flex>
      <ButtonGroup>
        <ToggleButton
          type="radio"
          variant="outline-primary"
          name="assist"
          checked={assist === true}
          onClick={() => handleAssist(true)}
        >
          Voy
        </ToggleButton>
        <ToggleButton
          type="radio"
          variant="outline-primary"
          name="assist"
          checked={assist === false}
          onClick={() => handleAssist(false)}
        >
          No voy
        </ToggleButton>
        <ToggleButton
          type="radio"
          variant="outline-primary"
          name="assist"
          checked={assist === null}
          onClick={() => handleAssist(null)}
        >
          No lo sé
        </ToggleButton>
      </ButtonGroup>

      {!!go.length && (
        <>
          <Flex
            className="pt-3 pb-1"
            alignItems="center"
            justifyContent="space-between"
          >
            <div style={{ fontWeight: 'bold' }}>Van:</div>
            <Flex
              className="flex-grow-1 me-2"
              direction="column"
              alignItems="flex-end"
            >
              <AvatarGroup className="justify-content-end">
                {go.map(({ ref }, index) => {
                  const user =
                    partners.find(
                      partner => partner?.ref?.path === ref?.path
                    ) || {};
                  const { id, avatar, name } = user;
                  return (
                    <Avatar
                      key={`Go-Avatar-${id}-${index}`}
                      src={avatar && avatar}
                      name={name && name}
                      size="s"
                    />
                  );
                })}
              </AvatarGroup>
            </Flex>
            <div className="fs--1 fw-bold">{go.length}</div>
          </Flex>
          <div className="d-grid">
            <Button
              className="rounded-pill fs--1 fw-normal"
              size="sm"
              variant="outline-primary"
              onClick={() =>
                handleShowAssistants(true, { title: 'Van', assistance: true })
              }
            >
              Ver quién va
            </Button>
          </div>
        </>
      )}
      {!!dontGo.length && (
        <>
          <Flex
            className="pt-3 pb-1"
            alignItems="center"
            justifyContent="space-between"
          >
            <div style={{ fontWeight: 'bold' }}>No van:</div>
            <Flex
              className="flex-grow-1 me-2"
              direction="column"
              alignItems="flex-end"
            >
              <AvatarGroup className="justify-content-end">
                {dontGo.map(({ ref }, index) => {
                  const user =
                    partners.find(
                      partner => partner?.ref?.path === ref?.path
                    ) || {};
                  const { id, avatar, name } = user;
                  return (
                    <Avatar
                      key={`DontGo-Avatar-${id}-${index}`}
                      src={avatar && avatar}
                      name={name && name}
                      size="s"
                    />
                  );
                })}
              </AvatarGroup>
            </Flex>
            <div className="fs--1 fw-bold">{dontGo.length}</div>
          </Flex>
          <div className="d-grid">
            <Button
              className="rounded-pill fs--1 fw-normal"
              size="sm"
              variant="outline-primary"
              onClick={() =>
                handleShowAssistants(false, {
                  title: 'No van',
                  assistance: true
                })
              }
            >
              Ver quién no va
            </Button>
          </div>
        </>
      )}
      {!!dontKnow.length && (
        <>
          <Flex
            className="pt-3 pb-1"
            alignItems="center"
            justifyContent="space-between"
          >
            <div style={{ fontWeight: 'bold' }}>No lo saben:</div>
            <Flex
              className="flex-grow-1 me-2"
              direction="column"
              alignItems="flex-end"
            >
              <AvatarGroup className="justify-content-end">
                {dontKnow.map(({ ref }, index) => {
                  const user =
                    partners.find(
                      partner => partner?.ref?.path === ref?.path
                    ) || {};
                  const { id, avatar, name } = user;
                  return (
                    <Avatar
                      key={`DontKnow-Avatar-${id}-${index}`}
                      src={avatar && avatar}
                      name={name && name}
                      size="s"
                    />
                  );
                })}
              </AvatarGroup>
            </Flex>
            <div className="fs--1 fw-bold">{dontKnow.length}</div>
          </Flex>
          <div className="d-grid">
            <Button
              className="rounded-pill fs--1 fw-normal"
              size="sm"
              variant="outline-primary"
              onClick={() =>
                handleShowAssistants(null, {
                  title: 'No lo saben',
                  assistance: true
                })
              }
            >
              Ver quién no lo sabe
            </Button>
          </div>
        </>
      )}
    </>
  );
};

AssistantsList.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string
};

EventMessage.propTypes = {
  message: PropTypes.object
};

export default EventMessage;
