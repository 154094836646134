import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import ActiveBenefits from './ActiveBenefits';
import Transactions from './Transactions';
import Wallet from './Wallet';
import Rewards from './Rewards';

const Benefits = () => {
  const { key = 'transactions' } = useParams();
  const [activeKey, setActiveKey] = useState(key);
  const navigate = useNavigate();

  const handleSelect = key => {
    setActiveKey(key);
    navigate(`/user/benefits/${key}`);
  };

  useEffect(() => {
    setActiveKey(key);
  }, [key]);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col lg={8}>
          <Card>
            <Card.Header>
              <h5 className="m-0">Beneficios</h5>
            </Card.Header>
            <Tabs activeKey={activeKey} onSelect={handleSelect}>
              <Tab eventKey="transactions" title="Transacciones">
                <Transactions />
              </Tab>
              <Tab eventKey="rewards" title="Recompensas">
                <Rewards />
              </Tab>
              <Tab eventKey="active" title="Activos">
                <ActiveBenefits />
              </Tab>
            </Tabs>
          </Card>
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <Wallet className="mb-3" amountClassName="display-3" />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Benefits;
