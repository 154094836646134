import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Button, Card, Form } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { FeedbackContext, UserContext } from 'context/Context';
import { toast } from 'react-toastify';

export const UserFeedbackContent = ({
  buttonClass,
  buttonOrder = 1,
  buttonSize,
  className,
  rows = 8,
  setShowFeedbackInput
}) => {
  const { createFeedback } = useContext(FeedbackContext);
  const [feedback, setFeedback] = useState({
    anonymous: false
  });
  const { anonymous = false, text = '' } = feedback;

  const handleChange = ({ target }) => {
    let { name, value, checked } = target;
    if (name === 'anonymous') {
      value = checked;
    }
    setFeedback(feedback => ({ ...feedback, [name]: value }));
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      await createFeedback({
        feedback: text,
        anonymous
      });

      toast.success(
        anonymous ? 'Sugerencia anónima enviada' : 'Sugerencia enviada'
      );
      setFeedback({ anonymous: false });
      setShowFeedbackInput?.(false);
    } catch (error) {
      console.error(error);
      toast.error(
        'Ha habido un problema y tu sugerencia no ha podido ser enviada'
      );
    }
  };

  return (
    <Form className={className} onSubmit={handleSubmit}>
      <Form.Control
        as="textarea"
        className="h-100"
        rows={rows}
        name="text"
        value={text}
        placeholder="Escribe tu sugerencia"
        onChange={handleChange}
      />
      <Flex className="mt-3 gap-3">
        <div className="order-1">
          <Form.Check
            type="checkbox"
            label={
              <div>
                <div>Quiero que sea anónimo</div>
                <div className="fs--1 lh-1 fw-normal text-500">
                  No te podrán contestar porque no deja rastro
                </div>
              </div>
            }
            name="anonymous"
            checked={anonymous}
            onChange={handleChange}
          />
        </div>
        <div
          className={classNames('d-grid d-md-block', {
            'order-0': buttonOrder === 0,
            'order-1': buttonOrder === 1,
            'order-2': buttonOrder === 2
          })}
        >
          <Button className={buttonClass} size={buttonSize} type="submit">
            Enviar
          </Button>
        </div>
      </Flex>
    </Form>
  );
};

UserFeedbackContent.propTypes = {
  buttonClass: PropTypes.string,
  buttonOrder: PropTypes.number,
  buttonSize: PropTypes.string,
  className: PropTypes.string,
  rows: PropTypes.number,
  setShowFeedbackInput: PropTypes.func
};

const UserFeedback = ({ className, ...props }) => {
  const { me } = useContext(UserContext);

  return (
    <Card className={className}>
      <Card.Header className="bg-light rounded-top border-bottom border-200">
        <Flex>
          <Link to={`/user/profile/${me?.NO_ID_FIELD}`}>
            <Avatar src={me?.avatar} name={name} size="l" />
          </Link>
          <div className="flex-1 ms-2 fs--1">
            <h5>Nueva sugerencia</h5>
          </div>
        </Flex>
      </Card.Header>
      <Card.Body className="p-0">
        <UserFeedbackContent buttonOrder={0} className="p-3" {...props} />
      </Card.Body>
    </Card>
  );
};

UserFeedback.propTypes = {
  className: PropTypes.string
};

export default UserFeedback;
