import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import 'dayjs/locale/es';
dayjs.extend(calendar);

const AnniversaryNotice = ({ user, className }) => {
  const {
    NO_ID_FIELD,
    anniversaryDate,
    company_name: companyName,
    name,
    workAnniversaryDiff,
    workAnniversaryYears
  } = user || {};

  const profileLink = `/user/profile/${NO_ID_FIELD}`;
  const workAnniveraryText = dayjs(anniversaryDate)
    .calendar(null, {
      sameDay: '[hoy]',
      nextDay: '[mañana]',
      nextWeek: '[el] dddd',
      lastDay: '[ayer]',
      lastWeek: '[el] dddd',
      sameElse: '[el] dddd, D [de] MMMM'
    })
    .replace('.', '');

  return (
    <>
      <Card className={className}>
        <Card.Body className="fs--1">
          <Flex>
            🎉
            <div className="ms-2 flex-1">
              <Link className="fw-semi-bold" to={profileLink}>
                {name}
              </Link>{' '}
              {workAnniversaryDiff >= 0 ? 'cumple' : 'cumplió'}{' '}
              <strong>{workAnniversaryYears}</strong>{' '}
              {workAnniversaryYears === 1 ? 'año' : 'años'} en {companyName}{' '}
              {workAnniveraryText}
              {workAnniversaryDiff > 1 && (
                <SoftBadge bg="warning" pill className="ms-2">
                  dentro de {workAnniversaryDiff} días
                </SoftBadge>
              )}
            </div>
          </Flex>
        </Card.Body>
      </Card>
    </>
  );
};

AnniversaryNotice.propTypes = {
  user: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default AnniversaryNotice;
