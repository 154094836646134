import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { UserContext } from 'context/Context';
import InputField from 'components/common/InputField';

const styles = {
  control: base => ({
    ...base,
    borderRadius: '1.375rem',
    fontFamily:
      'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol'
  })
};

const Menu = ({ children, ...rest }) => {
  return (
    <components.Menu
      {...rest}
      className="overflow-hidden min-w-300px mt-0 border-top border-200 rounded-top-0 shadow"
    >
      {children}
    </components.Menu>
  );
};

Menu.propTypes = {
  children: PropTypes.node
};

const ProfileSettings = ({
  bodyClassName,
  className,
  headerClassName,
  user = {}
}) => {
  const {
    createDepartment,
    departments = [],
    locations = [],
    me,
    updateUser
  } = useContext(UserContext);
  const [isLoading, setLoading] = useState(false);
  const [isUpdated, setUpdated] = useState(false);
  const [formData, setFormData] = useState({});
  const data = { ...user, ...formData };
  const { NO_ID_FIELD, email } = user;
  const location = locations.find(
    ({ NO_ID_FIELD }) => NO_ID_FIELD === data?.locationId
  );

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const newData = { [name]: value };
    if (name === 'birthday') {
      const date = new Date(value);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      newData.birthday = `${date.getFullYear()}-${
        month < 10 ? `0${month}` : month
      }-${day < 10 ? `0${day}` : day}`;
      const [, birthdayMonth = ''] = value.match(/\d{4}-(\d{2}-\d{2})/) || [];
      newData.birthdayMonth = birthdayMonth;
    }
    if (name === 'startAt') {
      const date = new Date(value);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      newData.startAt = `${date.getFullYear()}-${
        month < 10 ? `0${month}` : month
      }-${day < 10 ? `0${day}` : day}`;
      const [, workAnniversaryMonth = ''] =
        value.match(/\d{4}-(\d{2}-\d{2})/) || [];
      newData.workAnniversaryMonth = workAnniversaryMonth;
    }
    if (name === 'location') {
      const date = new Date(value);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      newData.startAt = `${date.getFullYear()}-${
        month < 10 ? `0${month}` : month
      }-${day < 10 ? `0${day}` : day}`;
      const [, workAnniversaryMonth = ''] =
        value.match(/\d{4}-(\d{2}-\d{2})/) || [];
      newData.workAnniversaryMonth = workAnniversaryMonth;
    }
    setFormData({
      ...formData,
      ...newData
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    if (formData?.email && formData?.email !== email) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FIREBASE_URL}/updateEmail`,
          {
            method: 'POST',
            body: JSON.stringify({
              userId: NO_ID_FIELD,
              email: formData?.email
            })
          }
        );
        if (response.status !== 200) {
          throw Error();
        }
      } catch (error) {
        console.error(error);
      }
    }

    const { firstname: userFirstname, lastname: userLastname } = user;
    const { firstname = userFirstname, lastname = userLastname } = formData;
    formData.name = `${firstname} ${lastname}`;
    await updateUser(user, formData);
    setLoading(false);
    setUpdated(true);
    setFormData({});
  };

  useEffect(() => {
    isUpdated && setTimeout(() => setUpdated(false), 3000);
  }, [isUpdated]);

  return (
    <Card className={className}>
      <FalconCardHeader title="Información" className={headerClassName} />
      <Card.Body className={classNames('bg-light', bodyClassName)}>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="firstName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={data?.firstname}
                name="firstname"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="lastName">
              <Form.Label>Apellidos</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={data?.lastname}
                name="lastname"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder=""
                value={data?.email}
                name="email"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="phone">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={data?.phone}
                name="phone"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="birthday">
              <InputField
                type="date"
                label="Fecha de nacimiento"
                name="birthday"
                setValue={(name, value) => {
                  value = new Date(value).toISOString();
                  handleChange({ target: { name, value } });
                }}
                datepickerProps={{
                  dateFormat: 'dd/MM/yyyy',
                  selected:
                    (data?.birthday?.toMillis?.() || data?.birthday) &&
                    new Date(data?.birthday?.toMillis?.() || data?.birthday)
                }}
                formControlProps={{
                  placeholder: '01/01/1986',
                  icon: 'calendar-alt'
                }}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="nif">
              <Form.Label>NIF</Form.Label>
              <Form.Control
                type="text"
                placeholder="00000000A"
                value={data?.nif}
                name="nif"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} lg={6} className="mb-3" controlId="address">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="text"
                placeholder="C/ Nombre de la calle, 0"
                value={data?.address}
                name="address"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="startAt">
              <InputField
                type="date"
                label="Fecha de inicio en la empresa"
                name="startAt"
                setValue={(name, value) => {
                  value = new Date(value).toISOString();
                  handleChange({ target: { name, value } });
                }}
                datepickerProps={{
                  dateFormat: 'dd/MM/yyyy',
                  selected:
                    (data?.startAt?.toMillis?.() || data?.startAt) &&
                    new Date(data?.startAt?.toMillis?.() || data?.startAt)
                }}
                formControlProps={{
                  disabled: me?.type !== 'admin',
                  icon: 'calendar-alt',
                  placeholder: '01/01/1986'
                }}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group
              as={Col}
              lg={6}
              className="mb-3"
              controlId="anniversary"
            >
              <Form.Label>Departamento</Form.Label>
              {me?.type !== 'admin' ? (
                <Form.Select
                  disabled={true}
                  placeholder="Selecciona el departamento"
                  value={data?.department}
                >
                  <option value={data?.department}>{data?.department}</option>
                </Form.Select>
              ) : (
                <CreatableSelect
                  options={departments.map(({ name }) => ({
                    label: name,
                    value: name
                  }))}
                  placeholder="Selecciona el departamento"
                  classNamePrefix="react-select"
                  value={{ label: data?.department, value: data?.department }}
                  onChange={({ value }) =>
                    handleChange({ target: { name: 'department', value } })
                  }
                  onCreateOption={createDepartment}
                  menuPortalTarget={document.body}
                  components={{ Menu }}
                  styles={styles}
                />
              )}
            </Form.Group>

            <Form.Group
              as={Col}
              lg={6}
              className="mb-3"
              controlId="anniversary"
            >
              <Form.Label>Localización</Form.Label>
              {me?.type !== 'admin' ? (
                <Form.Select
                  disabled={true}
                  placeholder="Selecciona la localización"
                  value={location?.NO_ID_FIELD}
                >
                  <option value={location?.NO_ID_FIELD}>
                    {location?.name}
                  </option>
                </Form.Select>
              ) : (
                <CreatableSelect
                  options={locations.map(({ NO_ID_FIELD, name }) => ({
                    label: name,
                    value: NO_ID_FIELD
                  }))}
                  placeholder="Selecciona la localización"
                  classNamePrefix="react-select"
                  value={{
                    label: location?.name,
                    value: location?.NO_ID_FIELD
                  }}
                  onChange={({ value }) =>
                    handleChange({ target: { name: 'locationId', value } })
                  }
                  onCreateOption={createDepartment}
                  menuPortalTarget={document.body}
                  components={{ Menu }}
                  styles={styles}
                />
              )}
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="intro">
            <Form.Label>Descripción</Form.Label>
            {me?.NO_ID_FIELD === user?.NO_ID_FIELD ? (
              <Form.Control
                as="textarea"
                rows={6}
                placeholder="Escribe aquí cómo te defines, tus hobbies, gustos..."
                value={data?.description}
                name="description"
                onChange={handleChange}
              />
            ) : (
              <p className="form-control bg-light font-sans-serif text-pre-wrap">
                {data?.description}
              </p>
            )}
          </Form.Group>
          <div className="text-end">
            <Button
              variant={classNames({
                primary: !isUpdated,
                success: isUpdated
              })}
              type="submit"
              disabled={!Object.keys(formData).length}
            >
              {(isUpdated && (
                <FontAwesomeIcon icon="check" className="me-2" />
              )) ||
                (isLoading && (
                  <Spinner
                    size="sm"
                    className="me-2"
                    style={{ width: '1rem', height: '1rem' }}
                  />
                ))}
              {isUpdated ? 'Guardado' : 'Guardar'}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

ProfileSettings.propTypes = {
  bodyClassName: PropTypes.string,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  user: PropTypes.object
};

export default ProfileSettings;
