import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

const AddAnotherForm = ({
  onSubmit: handleSubmit,
  type,
  showForm,
  setShowForm
}) => {
  const [formData, setFormData] = useState({});
  const inputRef = useRef(null);

  useEffect(() => {
    if (showForm) {
      inputRef.current.focus();
    }
  }, [showForm]);

  return (
    <>
      {showForm && (
        <div
          className={classNames('rounded-3 transition-none', {
            'bg-100 p-x1': type === 'list',
            'p-3 border bg-white dark__bg-1000 mt-3': type === 'card'
          })}
        >
          <Form
            onSubmit={e => {
              e.preventDefault();
              return handleSubmit(formData);
            }}
          >
            <Form.Control
              className="mb-2"
              ref={inputRef}
              onChange={({ target }) =>
                setFormData({ ...formData, title: target.value })
              }
              placeholder={
                type === 'list'
                  ? 'Escribe el título de la columna...'
                  : 'Escribe el título de la tarjeta...'
              }
            />
            <Row className="gx-2">
              <Col xs={5}>
                <Button
                  variant="link"
                  size="sm"
                  className="d-block w-100 text-500 text-decoration-none"
                  type="button"
                  onClick={() => setShowForm(false)}
                >
                  Cancelar
                </Button>
              </Col>
              <Col xs={7}>
                <Button
                  variant="primary"
                  size="sm"
                  className="d-block w-100"
                  type="submit"
                >
                  Añadir columna
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </>
  );
};

AddAnotherForm.propTypes = {
  onSubmit: PropTypes.func,
  type: PropTypes.string,
  showForm: PropTypes.bool,
  setShowForm: PropTypes.func
};

export default AddAnotherForm;
