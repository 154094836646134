import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, OverlayTrigger, Row, Col, Tooltip } from 'react-bootstrap';
import HappyfyResponses from './HappyfyResponses';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import QuestionaireProvider from 'components/company/questionaires/QuestionaireProvider';
import { QuestionairesContext } from 'context/Context';
import { collection, limit, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import logo from 'assets/img/logo.png';
import IconButton from 'components/common/IconButton';

dayjs.extend(relativeTime);
dayjs.locale('es');

const ItemButton = ({ tootltip, icon, className, onClick }) => {
  return (
    <OverlayTrigger
      overlay={<Tooltip id="overlay-trigger-example">{tootltip}</Tooltip>}
    >
      <div className="d-inline-block">
        <IconButton
          variant="falcon-default"
          size="sm"
          icon={icon}
          className={className}
          onClick={onClick}
        />
      </div>
    </OverlayTrigger>
  );
};

ItemButton.propTypes = {
  tootltip: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func
};

const QuestionaryHappyfyContent = () => {
  const location = useLocation();
  const { hash } = location;
  const { month } = useContext(QuestionairesContext);
  const navigate = useNavigate();
  const db = useFirestore();
  const defaultsRef = query(
    collection(db, 'defaults'),
    where('current', '==', true),
    limit(1)
  );
  const { data: defaults = [] } = useFirestoreCollectionData(defaultsRef);
  const { happy_questionaire: happyQuestionaire = {} } = defaults?.[0] || {};
  const { subtitle } = happyQuestionaire;
  const time = dayjs(month).format('MMMM');

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        const card = element?.parentElement?.parentElement;
        if (element) {
          card?.classList.add(...['border-4']);
          setTimeout(() => {
            card?.classList.remove(...['border-4']);
          }, 1000);
          element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }
    }, 0);
  }, [hash]);

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row>
            <Col md as={Flex}>
              <ItemButton
                className="mt-1 me-3"
                tootltip="Volver a las encuestas"
                icon="arrow-left"
                onClick={() => {
                  navigate('/user/questionaires');
                }}
              />
              <Avatar src={logo} size="2xl" />
              <div className="flex-1 ms-2">
                <Flex>
                  <h5 className="mb-0">Cuestionario Happyfy de {time}</h5>
                </Flex>
                <span className="text-500">{subtitle}</span>
              </div>
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <HappyfyResponses questionaire={happyQuestionaire} />
    </>
  );
};

const QuestionaryHappyfyDetail = () => {
  return (
    <QuestionaireProvider>
      <QuestionaryHappyfyContent />
    </QuestionaireProvider>
  );
};

export default QuestionaryHappyfyDetail;
