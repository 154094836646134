import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';
import { v4 as uuid } from 'uuid';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  query,
  updateDoc,
  where
} from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { CalendarContext, ChatContext, UserContext } from 'context/Context';
import LockModal from 'components/company/calendar/LockModal';
import NewEventModal from 'components/company/calendar/NewEventModal';
import NewObjectiveModal from 'components/company/calendar/NewObjectiveModal';
import NewTimeOffModal from 'components/company/calendar/NewTimeOffModal';
import CalendarEventModal from 'components/company/calendar/CalendarEventModal';
import CalendarObjectiveModal from 'components/company/calendar/CalendarObjectiveModal';
import CalendarTimeOffModal from 'components/company/calendar/CalendarTimeOffModal';
import { getUnique } from 'helpers/utils';

const CalendarProvider = ({ children }) => {
  const { company, me } = useContext(UserContext);
  const { location } = me || {};
  const { holidays = [] } = location || {};
  const { createMessage, currentChat, referrer } =
    useContext(ChatContext) || {};
  const [editingEvent, setEditingEvent] = useState();
  const [isOpenNewEventModal, setIsOpenNewEventModal] = useState(false);
  const [isOpenNewObjectiveModal, setIsOpenNewObjectiveModal] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(me?.NO_ID_FIELD);
  const [isOpenNewTimeOffModal, setIsOpenNewTimeOffModal] = useState(false);
  const [isOpenLockModal, setIsOpenLockModal] = useState(false);
  const [scheduleStartDate, setScheduleStartDate] = useState();
  const [scheduleEndDate, setScheduleEndDate] = useState();
  const [scheduleDate, setScheduleDate] = useState();
  const [isOpenEventModal, setIsOpenEventModal] = useState(false);
  const [isOpenObjectiveModal, setIsOpenObjectiveModal] = useState(false);
  const [isOpenTimeOffModal, setIsOpenTimeOffModal] = useState(false);
  const [modalEventContent, setModalEventContent] = useState({});
  const [modalObjectiveContent, setModalObjectiveContent] = useState({});
  const [modalTimeOffContent, setModalTimeOffContent] = useState({});
  const db = useFirestore();
  let dataQuery = query(collection(db, 'none'));
  let companyQuery = query(collection(db, 'none'));
  let timeOffQuery = query(collection(db, 'none'));
  if (company?.NO_ID_FIELD && me?.NO_ID_FIELD) {
    const filters = [where('companyId', '==', company?.NO_ID_FIELD)];
    if (me?.type && me?.type === 'employee') {
      filters.push(where('participants', 'array-contains', me?.ref));
    }
    dataQuery = query(
      collection(db, 'calendar'),
      where('type', '!=', 'time-off'),
      ...filters
    );
    timeOffQuery = query(
      collection(db, 'calendar'),
      where('companyId', '==', company?.NO_ID_FIELD),
      where('type', '==', 'time-off')
    );

    if (me?.type && me?.type === 'employee') {
      companyQuery = query(
        collection(db, 'calendar'),
        where('companyId', '==', company?.NO_ID_FIELD),
        where('participants', '==', null),
        where('type', '!=', 'time-off')
      );
    }
  }
  const { status, data: raw = [] } = useFirestoreCollectionData(dataQuery);
  let dataRaw = [...raw];
  const { data: aux = [] } = useFirestoreCollectionData(companyQuery);
  const { data: timeOffRaw = [] } = useFirestoreCollectionData(timeOffQuery);
  dataRaw = getUnique([...dataRaw, ...aux, ...timeOffRaw], 'NO_ID_FIELD');
  const eventList = dataRaw.reduce(
    (acc, event) =>
      event.schedules
        ? acc.concat(event.schedules.concat(event))
        : acc.concat(event),
    []
  );
  const [initialEvents, setInitialEvents] = useState(eventList);

  const create = async params => {
    try {
      const { location, reward, start: date, title, type, validated } = params;
      const createdAt = new Date(Date.now()).toISOString();
      const data = {
        companyId: company?.NO_ID_FIELD,
        organizerId: me?.NO_ID_FIELD,
        createdAt,
        new: true,
        ...params
      };
      const calendarDoc = await addDoc(collection(db, 'calendar'), data);
      if (currentChat) {
        const data = {
          chatId: currentChat?.NO_ID_FIELD,
          chat: currentChat?.ref,
          status: 'delivered',
          type
        };
        if (referrer) {
          data.referrer = referrer;
        }
        if (type === 'event') {
          const extra = {
            calendarRef: calendarDoc,
            date,
            location,
            title
          };
          data.extra = extra;
        }
        if (type === 'objective') {
          const extra = {
            calendarRef: calendarDoc,
            date,
            reward,
            title,
            validated
          };
          data.extra = extra;
        }
        createMessage(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const update = async (calendarItem, params) => {
    try {
      const { NO_ID_FIELD } = calendarItem;
      const ref = doc(db, 'calendar', NO_ID_FIELD);
      await updateDoc(ref, params);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteItem = async calendarItem => {
    try {
      const { NO_ID_FIELD } = calendarItem;
      const ref = doc(db, 'calendar', NO_ID_FIELD);
      await deleteDoc(ref);
    } catch (error) {
      console.error(error);
    }
  };

  const holidayDates = holidays.map(({ start }) =>
    new Date(start).toDateString()
  );
  const data = dataRaw
    .map(data => ({ id: data.NO_ID_FIELD, ...data }))
    .sort((c1, c2) => (c1.start < c2.start ? -1 : 1));
  const events = data.filter(({ type }) => type === 'event');
  const objectives = data.filter(({ type }) => type === 'objective');
  const timeOff = data
    .filter(({ type }) => type === 'time-off')
    .map(timeOff => {
      const { start, end } = timeOff;
      const dates = [];
      const date = new Date(start);
      const endDate = new Date(end);
      while (
        date.toISOString().split('T')[0] <= endDate.toISOString().split('T')[0]
      ) {
        const day = date.getDay();
        if (
          day !== 0 &&
          day !== 6 &&
          !holidayDates.includes(date.toDateString())
        ) {
          dates.push(date);
        }
        date.setDate(date.getDate() + 1);
      }
      return { ...timeOff, days: dates.length };
    });

  useEffect(() => {
    const eventList = dataRaw.reduce(
      (acc, event) =>
        event.schedules
          ? acc.concat(
              event.schedules
                .map(schedule => ({ id: uuid(), ...schedule }))
                .concat(event)
            )
          : acc.concat(event),
      []
    );
    !equal(initialEvents, eventList) && setInitialEvents(eventList);
  }, [raw, aux]);

  // console.log('CALENDAR >>>', status, data);

  return (
    <CalendarContext.Provider
      value={{
        deleteItem,
        status,
        data,
        events,
        objectives,
        timeOff,
        create,
        editingEvent,
        setEditingEvent,
        initialEvents,
        isOpenNewEventModal,
        setIsOpenNewEventModal,
        isOpenNewObjectiveModal,
        setIsOpenNewObjectiveModal,
        selectedEmployeeId,
        setSelectedEmployeeId,
        isOpenNewTimeOffModal,
        setIsOpenNewTimeOffModal,
        isOpenLockModal,
        setIsOpenLockModal,
        scheduleStartDate,
        scheduleEndDate,
        setIsOpenEventModal,
        setIsOpenObjectiveModal,
        setIsOpenTimeOffModal,
        setScheduleStartDate,
        setScheduleEndDate,
        setModalEventContent,
        setModalObjectiveContent,
        setModalTimeOffContent,
        setScheduleDate,
        update
      }}
    >
      {children}
      {me?.type === 'admin' && (
        <>
          <LockModal isOpen={isOpenLockModal} setIsOpen={setIsOpenLockModal} />
        </>
      )}
      <NewEventModal
        isOpenScheduleModal={isOpenNewEventModal}
        setIsOpenScheduleModal={setIsOpenNewEventModal}
        initialEvents={initialEvents}
        setInitialEvents={setInitialEvents}
        scheduleStartDate={scheduleStartDate}
        scheduleEndDate={scheduleEndDate}
        setScheduleStartDate={setScheduleStartDate}
        setScheduleEndDate={setScheduleEndDate}
      />

      <NewObjectiveModal
        isOpenScheduleModal={isOpenNewObjectiveModal}
        setIsOpenScheduleModal={setIsOpenNewObjectiveModal}
        initialEvents={initialEvents}
        setInitialEvents={setInitialEvents}
        scheduleDate={scheduleDate}
        setScheduleDate={setScheduleDate}
      />
      <NewTimeOffModal
        isOpenScheduleModal={isOpenNewTimeOffModal}
        setIsOpenScheduleModal={setIsOpenNewTimeOffModal}
        initialEvents={initialEvents}
        setInitialEvents={setInitialEvents}
        scheduleStartDate={scheduleStartDate}
        scheduleEndDate={scheduleEndDate}
        setScheduleStartDate={setScheduleStartDate}
        setScheduleEndDate={setScheduleEndDate}
      />
      <CalendarEventModal
        isOpenModal={isOpenEventModal}
        setIsOpenModal={setIsOpenEventModal}
        modalContent={modalEventContent}
      />
      <CalendarObjectiveModal
        isOpenModal={isOpenObjectiveModal}
        setIsOpenModal={setIsOpenObjectiveModal}
        modalContent={modalObjectiveContent}
      />
      <CalendarTimeOffModal
        isOpenModal={isOpenTimeOffModal}
        setIsOpenModal={setIsOpenTimeOffModal}
        modalContent={modalTimeOffContent}
      />
    </CalendarContext.Provider>
  );
};

CalendarProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CalendarProvider;
