import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from 'context/Context';

const QuestionaireOutcome = ({ questionaire, readOnly }) => {
  const { me } = useContext(UserContext);
  const { outcome } = questionaire;
  return (
    <Card
      className={classNames('py-2', {
        'shadow-none': me?.type === 'admin'
      })}
    >
      <Card.Body>
        <div className="mb-3">{outcome}</div>
        <Button disabled={readOnly} size="lg" variant="primary" type="submit">
          Finalizar
        </Button>
        {readOnly && (
          <FontAwesomeIcon icon="check" className="text-success fs-3 ms-2" />
        )}
      </Card.Body>
    </Card>
  );
};

QuestionaireOutcome.propTypes = {
  questionaire: PropTypes.object,
  readOnly: PropTypes.bool
};

export default QuestionaireOutcome;
