import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { collection, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Tooltip from 'components/common/Tooltip';
import { UserContext } from 'context/Context';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

dayjs.extend(relativeTime);
dayjs.locale('es');

const Questionaire = ({ questionaire }) => {
  const { NO_ID_FIELD, anonymous, createdAt, title, description } =
    questionaire;
  const { me } = useContext(UserContext);
  const time = dayjs(createdAt)
    .calendar(null, {
      sameDay: 'HH:mm',
      lastDay: 'D MMM',
      lastWeek: 'D MMM',
      sameElse: 'D MMM'
    })
    .replace('.', '');

  const db = useFirestore();
  let userResponseQuery = query(collection(db, 'none'));
  if (NO_ID_FIELD && me?.NO_ID_FIELD) {
    userResponseQuery = query(
      collection(db, 'questionaires_responses'),
      where('questionaireId', '==', NO_ID_FIELD),
      where('userId', '==', me?.NO_ID_FIELD)
    );
  }
  const { data: responses = [] } =
    useFirestoreCollectionData(userResponseQuery);
  const hasResponded = responses.length;
  const { createdAt: respondedAt } = responses?.[0] || {};
  const responseTime = dayjs(respondedAt)
    .calendar(null, {
      sameDay: `[${dayjs().to(dayjs(respondedAt))}]`,
      lastDay: '[ayer] HH:mm',
      lastWeek: 'dddd HH:mm',
      sameElse: 'ddd, D MMM HH:mm'
    })
    .replace('.', '');

  return (
    <Row
      className={classNames(
        'border-bottom border-200 hover-actions-trigger hover-shadow hover-z-index-1016 py-2  px-3 mx-0 align-items-center',
        {
          'bg-light': hasResponded,
          'fw-bold': !hasResponded
        }
      )}
    >
      <Col>
        <Row>
          <Col sm="auto" className="ps-md-0 mb-1 mb-md-0">
            {hasResponded ? (
              <Tooltip title={`Respondido: ${responseTime}`}>
                <SoftBadge bg="success" className="ms-2">
                  <FontAwesomeIcon icon="check" />
                </SoftBadge>
              </Tooltip>
            ) : (
              <SoftBadge bg="warning" className="ms-2">
                <FontAwesomeIcon icon="exclamation-triangle" />
              </SoftBadge>
            )}
          </Col>
          <Col>
            <Link
              className="d-block inbox-link"
              to={`/user/questionaires/detail/${NO_ID_FIELD}`}
            >
              <Flex alignItems="center">
                <span className="text-dark">{title}</span>
                {anonymous && (
                  <SoftBadge bg="secondary" className="ms-2">
                    <FontAwesomeIcon icon="user-slash" className="me-1" />
                    Anónimo
                  </SoftBadge>
                )}
                <span className="mx-1 fw-normal">&ndash;</span>
                <span className="w-0 flex-1 text-600 fw-normal overflow-hidden text-truncate text-nowrap">
                  {description}
                </span>
              </Flex>
            </Link>
          </Col>
        </Row>
      </Col>
      <Col xs="auto" className="ms-auto align-self-start">
        <small>{time}</small>
      </Col>
    </Row>
  );
};

Questionaire.propTypes = {
  questionaire: PropTypes.shape({
    NO_ID_FIELD: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    anonymous: PropTypes.bool,
    archived: PropTypes.bool,
    star: PropTypes.bool,
    createdAt: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    badge: PropTypes.string
  }),
  isSelectedItem: PropTypes.func,
  toggleSelectedItem: PropTypes.func.isRequired
};

export default Questionaire;
