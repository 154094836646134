import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import CreatePostForm from './CreatePostForm';
import CreatePostAppreciation from './CreatePostAppreciation';
import { UserContext } from 'context/Context';

const CreatePost = ({ ...rest }) => {
  const { company, me } = useContext(UserContext);
  const [state, setState] = useState('post');
  const { pathname } = useLocation();

  if (me?.type !== 'admin') {
    return (
      <Card {...rest}>
        <Card.Body className="p-0">
          <CreatePostAppreciation />
        </Card.Body>
      </Card>
    );
  }

  const { avatar, name } = company || {};
  return state === 'post' ? (
    <>
      <Card {...rest}>
        <Card.Header className="bg-light">
          <Flex alignItems="center">
            <Avatar size="m" src={avatar} name={name} />
            <div className="flex-1 ms-2 d-flex">
              <h5 className="mb-0 fs-0 text-nowrap text-truncate w-0 flex-grow-1">
                Crear publicación{' '}
                {pathname !== '/feed' ? 'en el tablón de anuncios ' : ''}como{' '}
                {name}
              </h5>
            </div>
          </Flex>
        </Card.Header>
        <Card.Body className="p-0">
          <CreatePostForm setState={setState} />
        </Card.Body>
      </Card>
    </>
  ) : (
    <Card {...rest}>
      <Card.Body className="p-0">
        <CreatePostAppreciation setState={setState} />
      </Card.Body>
    </Card>
  );
};

export default CreatePost;
