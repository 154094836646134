import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Dropdown, Row } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import Avatar from 'components/common/Avatar';
import { UserContext } from 'context/Context';

const CompaniesMenu = () => {
  const {
    company: currentCompany,
    companies = [],
    setCompanyId
  } = useContext(UserContext);
  const [show, setShow] = useState(null);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setShow(false);
    });
  }, []);

  return (
    <Dropdown navbar={true} as="li" show={show} onToggle={() => setShow(!show)}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Button}
        variant="link"
        className="nav-link px-2"
      >
        <Avatar
          size="xl"
          src={currentCompany?.avatar}
          name={currentCompany?.name}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="dropdown-caret dropdown-menu-card dropdown-menu-end me-2"
        show={show}
      >
        <Card className="dropdown-menu-end shadow-none">
          <SimpleBarReact className="nav-quick-links max-vh-50">
            <Card.Body className="px-3 pt-2 pb-0">
              <Row className="text-center g-0">
                {companies.map((company, index) => (
                  <Col
                    key={`Company-Item-${company?.NO_ID_FIELD || index}`}
                    xs={4}
                  >
                    <div
                      className={classNames(
                        'd-block w-100 rounded-3 text-decoration-none',
                        {
                          'badge-soft-primary':
                            company?.NO_ID_FIELD === currentCompany?.NO_ID_FIELD
                        }
                      )}
                    >
                      <Button
                        variant="link"
                        className={classNames(
                          'd-block w-100 px-2 py-3 rounded-3 text-decoration-none',
                          {
                            'hover-bg-200':
                              company?.NO_ID_FIELD !==
                              currentCompany?.NO_ID_FIELD
                          }
                        )}
                        onClick={() => {
                          setCompanyId(company?.NO_ID_FIELD);
                          setShow(false);
                        }}
                      >
                        <Avatar
                          size="2xl"
                          src={company?.avatar}
                          name={company?.name}
                          className="shadow"
                        />
                        <p className="mb-0 fw-medium text-800 text-truncate fs--2">
                          {company?.name}
                        </p>
                      </Button>
                    </div>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </SimpleBarReact>
        </Card>

        <Dropdown.Divider className="mt-0" />

        <div className="bg-white rounded-2 pb-2 dark__bg-1000">
          <Dropdown.Item
            className="fw-bold text-warning"
            as={Link}
            to="/subscription"
          >
            <FontAwesomeIcon icon="crown" className="me-1" />
            <span>Suscripción</span>
          </Dropdown.Item>

          <Dropdown.Divider />

          <Dropdown.Item as={Link} to="/company/profile">
            Cuenta de empresa
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CompaniesMenu;
