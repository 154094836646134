import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import TimeOff from '../time-off/TimeOff';
import { CalendarContext, UserContext } from 'context/Context';

const TimeOffs = ({
  bodyClassName,
  cardTitle,
  hasFooter = true,
  header,
  user,
  ...rest
}) => {
  let auxDate = new Date();
  auxDate.setDate(auxDate.getDate() - 1);
  const [yesterday] = useState(auxDate.toISOString());
  const { timeOff: raw } = useContext(CalendarContext);
  const { me } = useContext(UserContext);
  user = user || me;
  const timeOff = raw?.filter(({ approved, participants, start }) => {
    const participant = participants?.[0];
    return (
      approved && participant?.path === user?.ref?.path && start > yesterday
    );
  });

  return (
    <Card {...rest}>
      {header || (cardTitle && <FalconCardHeader title={cardTitle} light />)}
      <Card.Body className={classNames('fs--1', bodyClassName)}>
        {!timeOff?.length && (
          <span>
            {user?.NO_ID_FIELD === me?.NO_ID_FIELD
              ? 'No tienes ausencias asignadas'
              : `${user?.firstname} no tiene ausencias asignadas`}
          </span>
        )}
        {timeOff?.map((timeOff, index) => (
          <TimeOff
            className="w-100"
            key={`TimeOff-${timeOff?.NO_ID_FIELD}`}
            details={timeOff}
            user={user}
            isLast={index === timeOff?.length - 1}
          />
        ))}
      </Card.Body>
      {hasFooter && (
        <FalconCardFooterLink
          title="Todas las vacaciones"
          to="/company/time-off"
          size="sm"
        />
      )}
    </Card>
  );
};

TimeOffs.propTypes = {
  bodyClassName: PropTypes.string,
  cardTitle: PropTypes.string,
  hasFooter: PropTypes.bool,
  header: PropTypes.node,
  user: PropTypes.object
};

export default TimeOffs;
