import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Col, Navbar, Row } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import { Outlet } from 'react-router-dom';

import Section from 'components/common/Section';

const LegalLayout = () => {
  const [showDropShadow, setShowDropShadow] = useState(false);

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100">
        <Col sm={11} md={9}>
          <Navbar
            className={classNames('navbar-glass fs--1 navbar-top sticky-kit', {
              'navbar-glass-shadow': showDropShadow
            })}
          >
            <Logo at="navbar-top" />
          </Navbar>
          <Outlet />
        </Col>
      </Row>
    </Section>
  );
};

export default LegalLayout;
