import { useContext, useEffect, useState } from 'react';
import { ProductContext } from 'context/Context';

const useProducts = product => {
  const [isAsc, setIsAsc] = useState(true);
  const [sortBy, setSortBy] = useState('title');

  const {
    productsDispatch: productsDispatch,
    isInCart,
    isInFavouriteItems
  } = useContext(ProductContext);

  const handleSearch = searchedText => {
    productsDispatch({
      type: 'SEARCH_PRODUCT',
      payload: {
        searchedText
      }
    });
  };

  const handleSort = () => {
    productsDispatch({
      type: 'SORT_PRODUCT',
      payload: {
        sortBy,
        order: isAsc ? 'asc' : 'desc'
      }
    });
  };

  const handleSortByChange = sortValue => {
    setSortBy(sortValue);
    setIsAsc(true);

    handleSort();
  };

  const handleIsAscClick = isAsc => {
    setIsAsc(isAsc);

    handleSort();
  };

  const handleAddToCart = () => {
    productsDispatch({
      type: isInCart(product.id) ? 'REMOVE_FROM_CART' : 'ADD_TO_CART',
      payload: { product }
    });
  };

  const handleFavouriteClick = () => {
    productsDispatch({
      type: isInFavouriteItems(product.id)
        ? 'REMOVE_FROM_FAVOURITES'
        : 'ADD_TO_FAVOURITES',
      payload: { product }
    });
  };

  useEffect(() => handleSort(), []);

  return {
    handleAddToCart,
    handleFavouriteClick,
    handleSearch,
    handleIsAscClick,
    handleSortByChange,
    isAsc
  };
};

export default useProducts;
