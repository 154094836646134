import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SimpleBarReact from 'simplebar-react';
import Partner from './Partner';
import GroupCreator from './GroupCreator';
import ChatCreatorHeader from './ChatCreatorHeader';
import ChatContactsSearch from '../ChatContactsSearch';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { ChatContext, UserContext } from 'context/Context';
import defaultGroup from 'assets/img/chat/default_group.png';

const ChatCreator = ({ wrapper }) => {
  const { happybot, partners = [] } = useContext(UserContext);
  const { isOpenNewChat, setIsOpenNewGroup } = useContext(ChatContext);
  const [query, setSearch] = useState('');

  return (
    <>
      <div
        className={classNames('chat-creator bg-white', {
          show: isOpenNewChat,
          'border-end': wrapper !== 'overAll'
        })}
      >
        {wrapper !== 'overAll' && <ChatCreatorHeader />}

        <ChatContactsSearch
          placeholder="Busca el contacto"
          onChange={query => setSearch(query)}
        />

        <div className="contacts-list">
          <SimpleBarReact style={{ height: '100%', minWidth: '72px' }}>
            <div
              className="cursor-pointer"
              onClick={() => setIsOpenNewGroup(true)}
            >
              <Flex alignItems="center">
                <Avatar
                  className={classNames('ms-3', {
                    [`status-${status}`]: !!status
                  })}
                  src={defaultGroup}
                  name={name}
                  size="2xl"
                />
                <div className="border-bottom flex-1 py-3 chat-contact-body ms-3 d-md-none d-lg-block">
                  <h6 className="my-1">Nuevo grupo</h6>
                </div>
              </Flex>
            </div>
            <div className="mt-4 mb-2 ps-4">
              <h6 className="fw-normal text-primary text-uppercase">
                Contactos
              </h6>
            </div>
            {[happybot, ...partners]
              .filter(({ id } = {}) => id)
              .filter(({ name = '' }) => {
                const normalizedName =
                  name
                    ?.toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '') || '';
                const normalizedQuery =
                  query
                    ?.toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '') || '';
                return normalizedName.includes(normalizedQuery);
              })
              .sort((p1, p2) => (p1.name < p2.name ? -1 : 1))
              .map((partner, index) => (
                <Partner
                  partner={partner}
                  index={index}
                  key={`Sidebar-Partner-${partner?.id}`}
                />
              ))}
          </SimpleBarReact>
        </div>
      </div>
      <GroupCreator />
    </>
  );
};

ChatCreator.propTypes = {
  wrapper: PropTypes.string
};

export default ChatCreator;
