import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, ButtonGroup, Col, Form, Row } from 'react-bootstrap';
import ActionButton from 'components/common/ActionButton';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { collection, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import SoftBadge from 'components/common/SoftBadge';
import { ReviewsContext } from 'context/Context';
import { UserContext } from 'context/Context';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import { groupBy } from 'helpers/utils';

dayjs.extend(relativeTime);
dayjs.locale('es');

const Review = ({ review, isSelectedItem, toggleSelectedItem }) => {
  const { NO_ID_FIELD, archived, star, createdAt, title, description, type } =
    review;
  const { authUser, partners = [] } = useContext(UserContext);
  const { accessToken } = authUser || {};
  const [marked, setMarked] = useState(star);
  const { reviewDispatch, deleteReview, update } = useContext(ReviewsContext);
  const time = dayjs(createdAt)
    .calendar(null, {
      sameDay: `HH:mm`,
      lastDay: 'D MMM',
      lastWeek: 'D MMM',
      sameElse: 'D MMM'
    })
    .replace('.', '');

  let users = [...partners];
  if (type === 'manager') {
    users = partners.filter(
      ({ department, level }) =>
        level !== 0 &&
        partners.some(
          p => p?.department === department && p?.level === level - 1
        )
    );
  }
  if (type === 'employeeToManager') {
    users = partners.filter(({ department, level }) =>
      partners.some(p => p?.department === department && p?.level === level + 1)
    );
  }

  const db = useFirestore();
  let userResponseQuery = query(collection(db, 'none'));
  if (NO_ID_FIELD) {
    userResponseQuery = query(
      collection(db, 'reviews_responses'),
      where('reviewId', '==', NO_ID_FIELD)
    );
  }
  let { data: responses = [] } = useFirestoreCollectionData(userResponseQuery);
  const newResponses = responses.filter(({ new: isNew }) => isNew);
  const userResponses = groupBy(responses, 'userId');
  const usersDone = Object.entries(userResponses)
    .map(([userId, responses]) => {
      const user = partners.find(({ NO_ID_FIELD }) => NO_ID_FIELD === userId);
      let users = [user];
      if (type === 'manager') {
        users = partners.filter(
          ({ department, level }) =>
            department === user?.department && level === user?.level - 1
        );
      }
      if (type === 'employeeToManager') {
        users = partners.filter(
          ({ department, level }) =>
            department === user?.department && level === user?.level + 1
        );
      }
      return users.length === responses.length;
    })
    .filter(done => done);
  const hasResponded = responses.length === users.length;

  useEffect(() => {
    star !== marked && NO_ID_FIELD && update(NO_ID_FIELD, { star: marked });
  }, [marked]);

  const handleSendReview = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_URL}/sendReview?reviewId=${NO_ID_FIELD}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (response.status !== 200) {
        throw Error();
      }
      toast.success(`Evaluación enviada`);
    } catch (error) {
      toast.error(`Ha habido un error al enviar la evaluación`);
    }
  };

  const handleActionButtonClick = async type => {
    reviewDispatch({ type, payload: [NO_ID_FIELD] });
    switch (type) {
      case 'ARCHIVE':
        await update(NO_ID_FIELD, { archived: !archived });
        toast.success(
          !archived ? 'Evaluación archivada' : 'Evaluación desarchivada'
        );
        break;
      case 'DELETE':
        deleteReview(NO_ID_FIELD);
        toast.success('Evaluación eliminada');
        break;

      default:
        break;
    }
  };

  return (
    <Row
      className={classNames(
        'border-bottom border-200 hover-actions-trigger hover-shadow hover-z-index-1016 py-2 px-1 mx-0 align-items-center',
        {
          'bg-light': hasResponded,
          'fw-bold': !hasResponded,
          'bg-soft-accent': !!newResponses.length
        }
      )}
    >
      <ButtonGroup
        size="sm"
        className="hover-actions end-0 me-3 p-0 email-row-actions w-auto"
      >
        <ActionButton
          tooltip="Enviar a los que no han contestado"
          icon="paper-plane"
          size="sm"
          handleClick={handleSendReview}
        />
        <ActionButton
          className="mx-1"
          tooltip={archived ? 'Desarchivar' : 'Archivar'}
          variant={archived ? 'warning' : 'falcon-default'}
          icon="archive"
          size="sm"
          handleClick={() => handleActionButtonClick('ARCHIVE')}
        />
        <ActionButton
          tooltip="Eliminar"
          icon="trash"
          variant="danger"
          size="sm"
          handleClick={() => handleActionButtonClick('DELETE')}
        />
      </ButtonGroup>
      <Col xs="auto" className="d-none d-sm-block align-self-start">
        <Flex alignItems="center">
          {typeof isSelectedItem !== 'undefined' && (
            <Form.Check
              type="checkbox"
              id="inboxBulkSelect"
              className="form-check mb-0 fs-0"
              checked={isSelectedItem(NO_ID_FIELD)}
              onChange={() => toggleSelectedItem(NO_ID_FIELD)}
            />
          )}
          <FontAwesomeIcon
            onClick={() => setMarked(!marked)}
            icon={marked ? 'star' : ['far', 'star']}
            // transform="down-2"
            className={classNames('ms-1 cursor-pointer', {
              'text-warning': marked,
              'text-300': !marked
            })}
          />
        </Flex>
      </Col>
      <Col className="p-0">
        <Link
          className="d-block inbox-link"
          to={`/company/reviews/detail/${NO_ID_FIELD}`}
        >
          <Flex alignItems="center">
            <span className="text-dark">{title}</span>
            <SoftBadge bg="success" className="ms-1">
              {usersDone?.length}/{users.length}
            </SoftBadge>
            <span className="mx-1 fw-normal">&ndash;</span>
            <span className="w-0 flex-1 text-600 fw-normal overflow-hidden text-truncate text-nowrap">
              {description}
            </span>
          </Flex>
        </Link>
      </Col>
      <Col xs="auto" className="ms-auto align-self-start text-end">
        <small>{time}</small>
        {!!newResponses.length && (
          <div className="text-end mt-1">
            <Badge bg="accent">{newResponses.length} nuevas</Badge>
          </div>
        )}
      </Col>
    </Row>
  );
};

Review.propTypes = {
  review: PropTypes.object,
  isSelectedItem: PropTypes.func,
  toggleSelectedItem: PropTypes.func.isRequired
};

export default Review;
