import React from 'react';
import PropTypes from 'prop-types';
import SoftBadge from 'components/common/SoftBadge';

const ChatContentBodyIntro = ({ chat }) => {
  const { name, type } = chat || {};
  const isGroup = type === 'group';

  return (
    <div className="position-relative p-2 text-center">
      <SoftBadge bg="info" className="p-2 fs--2 fw-normal text-wrap">
        <p className="mb-0">
          {isGroup
            ? `Eres un miembro de ${name}. Empieza la conversación en el grupo.`
            : `Empieza la conversación`}
        </p>
      </SoftBadge>
    </div>
  );
};

ChatContentBodyIntro.propTypes = {
  chat: PropTypes.object.isRequired
};

export default ChatContentBodyIntro;
