import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Alert, Card } from 'react-bootstrap';
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { QuestionairesContext } from 'context/Context';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import anonymousAvatar from 'assets/img/icons/blank-profile.svg';

dayjs.extend(relativeTime);
dayjs.locale('es');

const OptionsResponse = ({ value }) => {
  return <div className="text-wrap">{value.split('|||').join(', ')}</div>;
};

const TextResponse = ({ value }) => {
  value = value || '-';
  return <div className="text-wrap">{value}</div>;
};

const VoteResponse = ({ extra = {}, value }) => {
  const { max = {} } = extra;
  const { value: maxValue } = max;
  return (
    <div>
      <Flex alignItems="center" justifyContent="start">
        <h6 className="m-0 me-2">{value}</h6>{' '}
        <Rating
          start={0}
          stop={maxValue}
          initialRating={value}
          readonly={true}
          emptySymbol={
            <FontAwesomeIcon
              icon="circle"
              className="text-300"
              transform="shrink-3"
            />
          }
          fullSymbol={
            <FontAwesomeIcon
              icon="circle"
              className="text-primary"
              transform="shrink-3"
            />
          }
        />
      </Flex>
    </div>
  );
};

const UserCell = ({ anonymous, response = {} }) => {
  const { partners = [] } = useContext(QuestionairesContext);
  const { createdAt, userId } = response;
  const time = dayjs().to(dayjs(createdAt));
  const user = anonymous
    ? {
        avatar: anonymousAvatar,
        name: 'Anónimo'
      }
    : partners.find(({ id } = {}) => id === userId) || {};
  let { avatar, name } = user;
  return (
    <>
      <Flex alignItems="center">
        <Avatar size="2xl" src={avatar} name={name || 'H'} />
        <div className="ms-3">
          <h6 className="mb-0 fw-semi-bold">{name}</h6>
          <p className="fs--2 mb-0 text-500">{time}</p>
        </div>
      </Flex>
    </>
  );
};

const TargetCell = ({ response = {} }) => {
  const { partners = [] } = useContext(QuestionairesContext);
  const { targetId } = response;
  const target = partners.find(({ id } = {}) => id === targetId) || {};
  let { avatar: targetAvatar, name: targetName } = target;

  return (
    <>
      <Flex alignItems="center">
        <FontAwesomeIcon
          icon="arrow-right"
          className="mx-3 text-primary opacity-50"
        />
        <Avatar size="2xl" src={targetAvatar} name={targetName || 'H'} />
        <div className="ms-3">
          <h6 className="mb-0 fw-semi-bold">{targetName}</h6>
        </div>
      </Flex>
    </>
  );
};

const responseTypes = {
  options: OptionsResponse,
  text: TextResponse,
  vote: VoteResponse
};

const ResponseCell = ({ type, ...rest }) => {
  const ResponseType = responseTypes[type];
  if (!ResponseType) {
    return null;
  }
  return <ResponseType {...rest} />;
};

const Responses = ({ questionaire, responses }) => {
  const { anonymous, definitions } = questionaire;
  const { partners = [] } = useContext(QuestionairesContext);
  const userResponses = responses.map(response => {
    const { responses, userId } = response;
    const user = partners.find(({ id } = {}) => id === userId) || {};
    const { name } = user;
    return {
      ...response,
      responses,
      name
    };
  });
  const questionIndexes = definitions?.reduce((nums, { type }) => {
    const last = nums[nums.length - 1];
    let thisIndex =
      type === 'divider'
        ? type
        : last === 'divider'
        ? nums[nums.length - 2] + 1 || 1
        : last + 1;
    if (type !== 'divider' && isNaN(thisIndex)) {
      thisIndex = 1;
    }
    return [...nums, thisIndex];
  }, []);

  return (
    <>
      <Card.Header className="ps-4 py-3 mt-5 mb-4 bg-200">
        <h5 className="ms-4">Respuestas</h5>
      </Card.Header>
      <Card.Body>
        {!userResponses.length && (
          <div className="bg-white p-3 border-bottom border-200">
            <Alert variant="info" className="mb-0">
              <h5 className="alert-heading">No hay respuestas</h5>
            </Alert>
          </div>
        )}
        {userResponses.map(response => {
          const { responses = [] } = response;
          return (
            <div key={`Response-${response?.NO_ID_FIELD}`}>
              <Flex alignItems="center">
                <UserCell anonymous={anonymous} response={response} />
              </Flex>
              <div className="ps-4 ms-4 mt-3">
                {definitions.map((definition, index) => {
                  const { question, type } = definition;
                  const questionIndex = questionIndexes[index];
                  return (
                    <div
                      key={`Response-${response?.NO_ID_FIELD}-Question${index}`}
                      className="mt-3"
                    >
                      <h6
                        className={classNames({
                          'border-bottom fs-1 pb-2': type === 'divider',
                          'mt-5': type === 'divider' && index !== 0
                        })}
                      >
                        {isNaN(questionIndex) ? '' : `${questionIndex}. `}
                        {question}
                      </h6>
                      <ResponseCell {...definition} value={responses[index]} />
                    </div>
                  );
                })}
              </div>
              <hr />
            </div>
          );
        })}
      </Card.Body>
    </>
  );
};

OptionsResponse.propTypes = {
  value: PropTypes.string
};
TextResponse.propTypes = {
  value: PropTypes.string
};
VoteResponse.propTypes = {
  extra: PropTypes.object,
  value: PropTypes.number
};

ResponseCell.propTypes = {
  type: PropTypes.string
};

UserCell.propTypes = TargetCell.propTypes = {
  response: PropTypes.object
};

Responses.propTypes = {
  questionaire: PropTypes.object,
  responses: PropTypes.array
};

export default Responses;
