import React from 'react';
import { Card } from 'react-bootstrap';
import Lottie from 'lottie-react';
import working from 'assets/img/animated-icons/working.json';

const Soon = () => {
  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <Lottie
          className="m-auto"
          animationData={working}
          loop={true}
          style={{ height: '300px', width: '300px' }}
        />
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Estamos trabajando en esta página
        </p>
        <hr />
        <p>Pronto estará disponible</p>
      </Card.Body>
    </Card>
  );
};

export default Soon;
