import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import AddItem from './AddItem';
import Item from './Item';
import { UserContext } from 'context/Context';

const Skills = ({ className, color, user }) => {
  const { me } = useContext(UserContext);
  const { profile = {} } = user || {};
  const { skills = [] } = profile;

  return (
    <Card className={className}>
      <FalconCardHeader title="Habilidades" light />
      <Card.Body>
        <Flex wrap="wrap">
          {skills.map(tag => (
            <Item
              key={`Skill-${tag}`}
              profileKey="skills"
              color={color}
              user={user}
              text={tag}
            />
          ))}
          {me?.NO_ID_FIELD === user?.NO_ID_FIELD && (
            <AddItem
              color={color}
              profileKey="skills"
              placeholder="Habilidad"
              user={user}
            />
          )}
        </Flex>
      </Card.Body>
    </Card>
  );
};

Skills.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  user: PropTypes.object
};

export default Skills;
