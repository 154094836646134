import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Card, Tab, Nav } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IndexChart from './IndexChart';
import SimpleBarReact from 'simplebar-react';
import classNames from 'classnames';
import { groupBy } from 'helpers/utils';
import types from 'components/dashboards/types.json';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { ReportsContext } from 'context/Context';

dayjs.locale('es');

const TabTitle = ({ title, value, icon, diff, diffColor }) =>
  isNaN(value) ? null : (
    <div className="p-2 text-center cursor-pointer">
      <h6 className="text-800 fs--2 text-nowrap text-capitalize">{title}</h6>
      <Flex alignItems="baseline" className="gap-1 mb-1">
        <h5 className="mb-0">{value}%</h5>
        <SoftBadge pill bg={diffColor} className="ms-2 mb-0 fw-semi-bold">
          <FontAwesomeIcon icon={icon} className="fs--2 me-1" />
          <span className="fs--1">{diff}%</span>
        </SoftBadge>
      </Flex>
    </div>
  );

const IndexCharts = ({ className, type }) => {
  const { department: departmentProp } = useParams();
  const { historicIndexes = [], partners = [] } = useContext(ReportsContext);
  const raw = historicIndexes
    .map(data => {
      const { createdAt, userId } = data;
      const [date] = new Date(createdAt).toISOString().match(/\d+-\d+/);
      const { department } =
        partners.find(({ id } = {}) => id === userId) || {};
      return { ...data, date, department };
    })
    .filter(
      ({ department }) => !departmentProp || department === departmentProp
    );
  const rawByType = Object.entries(groupBy(raw, 'type'))
    .sort(([key1], [key2]) => (key1 < key2 ? -1 : 1))
    .map(([key, values]) => ({ [key]: values }))
    .reduce((result, date) => ({ ...result, ...date }), {});
  const chartDataByType = Object.entries(rawByType)
    .map(([type, values]) => {
      const byDate = groupBy(values, 'date');
      let data = Object.values(byDate).map(values =>
        Math.round(
          values.reduce((total, { value }) => total + value, 0) / values.length
        )
      );
      const average = Math.round(
        data.reduce((total, value) => total + value, 0) / data.length
      );
      data = [Array(data.length).fill(average), data];
      const labels = Object.keys(byDate).map(key => {
        const date = new Date(key);
        const format =
          date.getFullYear() === new Date().getFullYear() ? 'MMM' : 'MMM YYYY';
        return dayjs(date).format(format);
      });
      return { type, labels, data, title: types[type] };
    })
    .reduce((data, { type, ...rest }) => ({ ...data, [type]: rest }), {});

  const chartData = type ? { [type]: chartDataByType[type] } : chartDataByType;

  return (
    <Card
      className={classNames(
        'border border-3 overflow-hidden shadow-none',
        className
      )}
    >
      <Tab.Container defaultActiveKey={type || 'happiness'}>
        <SimpleBarReact>
          <Card.Header className="p-0">
            <Nav className="nav-tabs border-0 flex-nowrap chart-tab">
              {Object.entries(chartData).map(([key, value = {}]) => {
                const { data: arr, title } = value;
                const data = arr?.[1] || [];
                const current = data[data.length - 1];
                const prev = data[data.length - 2];
                const diff = isNaN(prev) ? 0 : current - prev;
                const diffPercentage = Math.min(
                  100,
                  Math.max(
                    0,
                    diff === 0
                      ? 0
                      : Math.abs(Math.round((diff * 100) / (prev || 1)))
                  )
                );
                const icon =
                  diff === 0 ? 'minus' : diff > 0 ? 'caret-up' : 'caret-down';
                const diffColor =
                  diff === 0
                    ? 'info'
                    : diff > 0
                    ? key === 'stress'
                      ? 'danger'
                      : 'success'
                    : key === 'stress'
                    ? 'success'
                    : 'danger';

                return (
                  <Nav.Item
                    key={`HappinessTabTitle-${key}`}
                    className="text-center"
                  >
                    <Nav.Link className="mb-0" eventKey={key}>
                      <TabTitle
                        title={`${title}`}
                        value={current}
                        icon={icon}
                        diff={diffPercentage}
                        diffColor={diffColor}
                      />
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </Card.Header>
        </SimpleBarReact>

        <Card.Body>
          <Tab.Content>
            {Object.entries(chartData).map(([key, value]) => {
              return (
                <Tab.Pane
                  key={`HappinessTabPane-${key}`}
                  unmountOnExit
                  eventKey={key}
                >
                  <IndexChart {...value} />
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
};

TabTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.number,
  icon: PropTypes.string,
  diff: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  diffColor: PropTypes.string
};

IndexCharts.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  type: PropTypes.string,
  user: PropTypes.object
};

export default IndexCharts;
