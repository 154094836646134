import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import dayjs from 'dayjs';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { ticketBadges, ticketPriorities } from 'components/feedback';
import { FeedbackContext, UserContext } from 'context/Context';
import { getColor } from 'helpers/utils';

const PrioritySelect = ({ title, color, data }) => {
  return (
    <div
      style={{ width: '7.5rem' }}
      className="d-flex align-items-center gap-2 ms-md-4 ms-xl-0 ms-xxl-4"
    >
      <div style={{ '--falcon-circle-progress-bar': data }}>
        <svg
          className="circle-progress-svg"
          width="26"
          height="26"
          viewBox="0 0 120 120"
        >
          <circle
            className="progress-bar-rail"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            strokeWidth="12"
          ></circle>
          <circle
            className="progress-bar-top"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            stroke={getColor(color)}
            strokeWidth="12"
          ></circle>
        </svg>
      </div>
      <h6 className="mb-0 text-700">{title}</h6>
    </div>
  );
};

PrioritySelect.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

const AgentSelect = ({ agent, className, style }) => {
  const { partners = [] } = useContext(UserContext);
  return (
    <Form.Select
      style={style}
      className={className}
      size="sm"
      defaultValue={agent}
    >
      <option key={`Agent-none`}>Selecciona al encargado</option>
      {partners
        .filter(({ level, type }) => level || type === 'admin')
        .map(({ NO_ID_FIELD, name }) => (
          <option key={`Agent-${NO_ID_FIELD}`} value={NO_ID_FIELD}>
            {name}
          </option>
        ))}
    </Form.Select>
  );
};

AgentSelect.propTypes = {
  agent: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object
};

const Tickets = () => {
  const { me } = useContext(UserContext);
  const { userId = me?.NO_ID_FIELD } = useParams();
  const { feedback = [] } = useContext(FeedbackContext);
  const tickets = feedback
    .filter(f => f.userId === userId)
    .sort((f1, f2) => (f1.createdAt > f2.createdAt ? -1 : 1));

  return (
    <Flex direction="column" className="gap-3">
      {tickets.map(item => {
        let {
          NO_ID_FIELD,
          agent,
          comments = [],
          createdAt,
          feedback,
          priority: priorityProp = 'medium',
          status: statusProp
        } = item;
        let title = `${feedback.substr(0, 60)}`;
        title = title < feedback ? `${title}...` : title;
        const diff = dayjs().diff(new Date(createdAt), 'days');
        const isResponded = comments?.some(({ userId: uid }) => uid !== userId);
        statusProp =
          statusProp ||
          (isResponded && 'responded') ||
          (diff > 3 ? 'overdue' : 'recent');

        const status =
          ticketBadges.find(({ value }) => value === statusProp) || {};
        const priority =
          ticketPriorities.find(({ value }) => value === priorityProp) || {};

        return (
          <div
            key={`Feedback-Ticket-${NO_ID_FIELD}`}
            className="bg-white dark__bg-1100 p-x1 rounded-3 shadow-sm d-md-flex d-xl-inline-block d-xxl-flex align-items-center"
          >
            <div>
              <p className="fw-semi-bold">
                <Link to={`/feedback/tickets-preview/${NO_ID_FIELD}`}>
                  {title}
                </Link>
              </p>
              <Flex alignContent="center">
                <h6 className="mb-0 me-3 text-800 lh-base">
                  {dayjs(createdAt).format('D MMMM, YYYY')}
                </h6>
                <SoftBadge bg={status.type}>{status.content}</SoftBadge>
              </Flex>
            </div>
            <div className="border-bottom mt-4 mb-x1"></div>
            <Flex justifyContent="between" className="ms-auto">
              <PrioritySelect
                title={priority.title}
                color={priority.color}
                data={priority.data}
              />
              <AgentSelect agent={agent} style={{ width: '15.375rem' }} />
            </Flex>
          </div>
        );
      })}
    </Flex>
  );
};

export default Tickets;
