import React, { useContext } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import google from 'assets/img/nav-icons/google.png';
// import facebook from 'assets/img/nav-icons/facebook.png';
// import linkedin from 'assets/img/nav-icons/linkedin.png';

const socialLinks = [
  {
    title: 'Google',
    image: google,
    provider: 'google.com'
  }
  // {
  //   title: 'Facebook',
  //   image: facebook,
  //   provider: 'facebook.com'
  // },
  // {
  //   title: 'LinkedIn',
  //   image: linkedin,
  //   provider: 'linkedin.com'
  // }
];

const SocialAuthButtons = () => {
  const { socialSignIn } = useContext(UserContext);
  return (
    <Form.Group className="mb-0">
      <Row className="g-2">
        {socialLinks.map(({ title, image, provider }) => (
          <Col key={`SocialLink-${provider}`}>
            <Button
              variant="falcon-default"
              className="w-100"
              onClick={() => socialSignIn(provider)}
            >
              <Flex alignItems="center" justifyContent="center">
                <img src={image} alt={title} width={23} className="me-2" />
                <h6 className="mb-0">{title}</h6>
              </Flex>
            </Button>
          </Col>
        ))}
      </Row>
    </Form.Group>
  );
};

export default SocialAuthButtons;
