import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { ChatContext, UserContext } from 'context/Context';
import { Card } from 'react-bootstrap';
import ChatContent from './content/ChatContent';
import ChatCreator from './creator/ChatCreator';
import ChatSidebar from './sidebar/ChatSidebar';
import ChatInfo from './info/ChatInfo';

const Chat = () => {
  const { chatId, userId: userIdParam } = useParams();
  const {
    chats = [],
    currentChat,
    createChat,
    setCurrentChat,
    setIsOpenChatList
  } = useContext(ChatContext);
  const { me, partners = [] } = useContext(UserContext);
  const [userId] = useState(userIdParam);

  useEffect(() => {
    const chat = chats.find(({ NO_ID_FIELD }) => NO_ID_FIELD === chatId);
    (currentChat?.NO_ID_FIELD !== chat?.NO_ID_FIELD || !currentChat?.name) &&
      setCurrentChat?.(chat);
    !chatId && setIsOpenChatList?.(true);
  }, [chatId, chats]);

  useEffect(() => {
    if (!userId || !chats?.length) {
      return;
    }
    const { ref } =
      partners?.find(({ NO_ID_FIELD }) => NO_ID_FIELD === userId) || {};
    const participants = [me?.ref, ref];
    ref && createChat({ participants, type: 'user' });
  }, [userId, chats?.length]);

  return (
    <Card className="card-chat overflow-hidden mx-n3">
      <Card.Body as={Flex} className="p-0 h-100">
        <ChatCreator />
        <ChatSidebar />
        <ChatContent />
        <ChatInfo />
      </Card.Body>
    </Card>
  );
};

export default Chat;
