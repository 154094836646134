import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { ReportsContext } from 'context/Context';
import anonymousAvatar from 'assets/img/icons/blank-profile.svg';

dayjs.extend(relativeTime);
dayjs.locale('es');

const UserCell = ({ response = {}, user = {} }) => {
  const { createdAt, department } = response;
  const time = dayjs(createdAt).format('DD/MM/YYYY');
  const { id, avatar, name } = user;

  return (
    <Flex alignItems="center">
      <Avatar size="xl" src={avatar} name={name || 'H'} />
      <div className="ms-3 text-wrap">
        {!id ? (
          <h6 className="mb-0 fw-semi-bold">{name}</h6>
        ) : (
          <Link to={`/user/profile/${id}`} className="text-800">
            <h6 className="mb-0 text-wrap">{name}</h6>
          </Link>
        )}
        {department && <p className="fs--2 mb-0">{department}</p>}
        <p className="fs--2 mb-0 text-500">{time}</p>
      </div>
    </Flex>
  );
};

const Responses = () => {
  const { department: departmentProp } = useParams();
  const { partners = [], feedback: raw = [] } = useContext(ReportsContext);

  const feedbacks = raw
    .map(feedback => {
      const { userId } = feedback;
      const user =
        userId === 'anonymous'
          ? { avatar: anonymousAvatar, name: 'Anónimo' }
          : partners.find(({ id } = {}) => id === userId) || {};
      if (!user || (departmentProp && user?.department !== departmentProp)) {
        return;
      }
      const { name, department } = user;
      return {
        ...feedback,
        name,
        department
      };
    })
    .filter(({ feedback, name } = {}) => feedback && name);

  const columns = [
    {
      accessor: 'name',
      Header: 'Empleado',
      headerProps: {
        className: 'col-5'
      },
      Cell: rowData => {
        const { userId } = rowData.row.original;
        const user = partners.find(({ id } = {}) => id === userId) || {
          avatar: anonymousAvatar,
          name: 'Anónimo'
        };
        return <UserCell response={rowData.row.original} user={user} />;
      }
    },
    {
      accessor: 'feedback',
      Header: 'Sugerencia',
      Cell: rowData => {
        return <div className="text-wrap">{rowData.row.original.feedback}</div>;
      }
    }
  ];

  return (
    <>
      {!feedbacks.length ? (
        <Card className="border border-3">
          <Card.Body>
            <span>No hay sugerencias</span>
          </Card.Body>
        </Card>
      ) : (
        <AdvanceTableWrapper
          columns={columns}
          data={feedbacks}
          pagination
          perPage={999999}
        >
          <AdvanceTable
            table
            headerClassName="bg-light text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              className: 'fs--1 mb-0',
              style: { tableLayout: 'fixed' }
            }}
          />
        </AdvanceTableWrapper>
      )}
    </>
  );
};

const Feedback = props => {
  return (
    <>
      <Card className="overflow-hidden shadow-none">
        <Card.Body className="p-0">
          <Responses {...props} />
        </Card.Body>
      </Card>
    </>
  );
};

UserCell.propTypes = {
  response: PropTypes.object,
  user: PropTypes.object
};

Responses.propTypes = {
  date: PropTypes.object
};

export default Feedback;
