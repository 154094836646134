import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TimeOff from 'components/user/time-off/TimeOff';
import Flex from 'components/common/Flex';
import { CalendarContext, UserContext } from 'context/Context';

export const PendingTimesOff = ({ className }) => {
  const { timeOff: data = [], setIsOpenNewTimeOffModal } =
    useContext(CalendarContext);
  const { me } = useContext(UserContext);
  const myTimeOff = data.filter(
    ({ participants }) =>
      !participants || participants.some(ref => ref?.path === me?.ref?.path)
  );
  const pendingTimeOff = (me?.type === 'admin' ? data : myTimeOff).filter(
    ({ approved = null }) => approved === null
  );

  const handleEnter = timeOff => {
    const { NO_ID_FIELD } = timeOff;
    const els = document.querySelectorAll(`[data-id="${NO_ID_FIELD}"]`);
    els?.forEach(el => {
      el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.add(
        'highlight'
      );
    });
  };

  const handleLeave = timeOff => {
    const { NO_ID_FIELD } = timeOff;
    const els = document.querySelectorAll(`[data-id="${NO_ID_FIELD}"]`);
    els?.forEach(el => {
      el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.remove(
        'highlight'
      );
    });
  };

  return (
    <Card className={className}>
      <Card.Body className="fs--1">
        {!pendingTimeOff?.length && (
          <Flex
            className="h-100 p-3 text-center"
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              icon="suitcase-rolling"
              className="fs-2 text-400 mb-2"
            />
            <p>No hay ausencias pendientes</p>
            <Button
              variant="falcon-default"
              className="rounded-pill"
              size="sm"
              onClick={() => setIsOpenNewTimeOffModal(true)}
            >
              Crear ausencia
            </Button>
          </Flex>
        )}
        {pendingTimeOff.map((details, index) => (
          <TimeOff
            key={`TimeOff-${details.NO_ID_FIELD}`}
            details={details}
            isLast={index === pendingTimeOff.length - 1}
            onMouseEnter={() => handleEnter(details)}
            onMouseLeave={() => handleLeave(details)}
          />
        ))}
      </Card.Body>
    </Card>
  );
};

PendingTimesOff.propTypes = {
  className: PropTypes.string
};

export default PendingTimesOff;
