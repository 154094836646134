import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { collection, limit, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Tooltip from 'components/common/Tooltip';
import { QuestionairesContext } from 'context/Context';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import logo from 'assets/img/logo.png';

dayjs.extend(relativeTime);
dayjs.locale('es');

const ActionButton = ({ tooltip, icon, handleClick, variant = 'light' }) => (
  <Tooltip title={tooltip}>
    <Button variant={variant} onClick={handleClick} className="shadow-none">
      <FontAwesomeIcon icon={icon} />
    </Button>
  </Tooltip>
);

const HappyQuestionaire = () => {
  const { happyResponses: responses = [], month } =
    useContext(QuestionairesContext);
  const time = dayjs(month).format('MMMM');
  const db = useFirestore();
  const defaultsRef = query(
    collection(db, 'defaults'),
    where('current', '==', true),
    limit(1)
  );
  const { data: defaults = [] } = useFirestoreCollectionData(defaultsRef);
  const { happy_questionaire: happyQuestionaire = {} } = defaults?.[0] || {};
  const { subtitle } = happyQuestionaire;
  const hasResponded = responses.length;
  const { createdAt: respondedAt } = responses?.[0] || {};
  const responseTime = dayjs(respondedAt)
    .calendar(null, {
      sameDay: `[${dayjs().to(dayjs(respondedAt))}]`,
      lastDay: '[ayer] HH:mm',
      lastWeek: 'dddd HH:mm',
      sameElse: 'ddd, D MMM HH:mm'
    })
    .replace('.', '');

  return (
    <Row
      className={classNames(
        'hover-actions-trigger hover-shadow py-3 mx-0 rounded align-items-center fs--1',
        {
          'bg-light': hasResponded,
          'fw-bold': !hasResponded
        }
      )}
    >
      <Col>
        <Flex alignItems="center">
          {hasResponded ? (
            <Tooltip title={`Respondido: ${responseTime}`}>
              <SoftBadge bg="success" className="ms-2">
                <FontAwesomeIcon icon="check" />
              </SoftBadge>
            </Tooltip>
          ) : (
            <SoftBadge bg="warning" className="ms-2">
              <FontAwesomeIcon icon="exclamation-triangle" />
            </SoftBadge>
          )}
          <Avatar src={logo} size="m" isExact={false} className="ms-4 me-2" />
          <Link className="d-block inbox-link" to="/user/questionaires/happyfy">
            <Flex alignItems="center">
              <span className="text-dark">Cuestionario Happyfy de {time}</span>
              <span className="mx-1 fw-normal">&ndash;</span>
              <span className="text-600 fw-normal">{subtitle}</span>
            </Flex>
          </Link>
        </Flex>
      </Col>
      <Col xs="auto" className="ms-auto align-self-start p-0"></Col>
    </Row>
  );
};

ActionButton.propTypes = {
  tooltip: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  variant: PropTypes.string
};

export default HappyQuestionaire;
