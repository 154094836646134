import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import ReactPlayer from 'react-player/youtube';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FeedImages from 'components/company/feed/FeedImages';
import FeedUrl from 'components/company/feed/FeedUrl';
import createMarkup from 'helpers/createMarkup';

const ProductContent = ({ data }) => {
  if (!data) {
    return;
  }

  const { text, images, url, video } = data;
  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader
          title="Descripción"
          titleTag="h5"
          className="bg-light"
        />
        <Card.Body className={classNames('position-relative')}>
          {!!text && <p dangerouslySetInnerHTML={createMarkup(text)} />}
        </Card.Body>
      </Card>
      {(images || url || video) && (
        <Card className="mb-3">
          <Card.Body className={classNames('position-relative')}>
            {!!images && <FeedImages images={images} />}
            {!!url && <FeedUrl url={url} />}
            {!!video && (
              <ReactPlayer
                url={video}
                controls={true}
                className="react-player mt-3"
              />
            )}
          </Card.Body>
        </Card>
      )}
    </>
  );
};

ProductContent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
};

export default ProductContent;
