import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Modal,
  Row
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import ActionButton from 'components/common/ActionButton';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import AppContext, { QuestionairesContext, UserContext } from 'context/Context';
import useBulkSelect from 'hooks/useBulkSelect';
import usePagination from 'hooks/usePagination';
import HappyQuestionaire from './HappyQuestionaire';
import Questionaire from './Questionaire';
import FilterDropdown from './FilterDropdown';
import Footer from './Footer';
import QuestionaireProvider from './QuestionaireProvider';

const QuestionairesContent = () => {
  const {
    questionaireState: { questionaires, filters, currentFilter },
    questionaireDispatch,
    deleteQuestionaire,
    update
  } = useContext(QuestionairesContext);

  const {
    paginationState: {
      data: paginatedQuestionaires,
      totalItems,
      canNextPage,
      canPreviousPage,
      from,
      to
    },
    nextPage,
    prevPage
  } = usePagination(questionaires, 10);
  const questionairesIds = questionaires.map(
    questionaire => questionaire.NO_ID_FIELD
  );

  const {
    selectedItems,
    isSelectedItem,
    isAllSelected,
    isIndeterminate,
    toggleSelectedItem,
    toggleIsAllSelected
  } = useBulkSelect(questionairesIds);

  const handleActionButtonClick = async type => {
    questionaireDispatch({
      type,
      payload: selectedItems
    });

    switch (type) {
      case 'ARCHIVE':
        await Promise.all(
          selectedItems.map(NO_ID_FIELD =>
            update(NO_ID_FIELD, { archived: true })
          )
        );
        toast.success('Cuestionarios archivados');
        break;
      case 'DELETE':
        await Promise.all(
          selectedItems.map(NO_ID_FIELD => deleteQuestionaire(NO_ID_FIELD))
        );
        toast.success('Cuestionarios eliminados');
        break;

      default:
        break;
    }
    toggleIsAllSelected();
  };

  const handleSendQuestionaire = selectedItems => {
    try {
      selectedItems.forEach(async questionaireId => {
        const response = await fetch(
          `${process.env.REACT_APP_FIREBASE_URL}/sendQuestionaire?questionaireId=${questionaireId}`
        );
        if (response.status !== 200) {
          throw Error();
        }
        toast.success(`Cuestionario enviado`);
      });
    } catch (error) {
      toast.error(`Ha habido un error al enviar el cuestionario`);
    }
  };

  return (
    <>
      <Card className="mb-3">
        <HappyQuestionaire />
      </Card>
      <Card>
        <Card.Header>
          <Row className="align-items-center">
            <Col as={Flex} className="align-items-center">
              <Form.Check
                type="checkbox"
                id="inboxBulkSelect"
                className="mb-0 d-none d-sm-block form-check"
                checked={isAllSelected || isIndeterminate}
                onChange={() => toggleIsAllSelected()}
              />

              {selectedItems.length === 0 && (
                <>
                  <FilterDropdown
                    filters={filters}
                    currentFilter={currentFilter}
                  />
                </>
              )}
              {selectedItems.length > 0 && (
                <div className="ms-1">
                  <ButtonGroup size="sm">
                    <ActionButton
                      tooltip="Enviar"
                      icon="paper-plane"
                      handleClick={() => handleSendQuestionaire(selectedItems)}
                    />
                    {currentFilter !== 'archived' && (
                      <ActionButton
                        tooltip="Archivar"
                        icon="archive"
                        handleClick={() => handleActionButtonClick('ARCHIVE')}
                      />
                    )}
                    <ActionButton
                      tooltip="Eliminar"
                      icon="trash"
                      variant="danger"
                      handleClick={() => handleActionButtonClick('DELETE')}
                    />
                  </ButtonGroup>
                </div>
              )}
            </Col>
            <Col xs="auto">
              <IconButton
                variant="falcon-primary"
                size="sm"
                icon="plus"
                as={Link}
                to="/company/questionaires/create"
                transform="shrink-3"
              >
                Crear cuestionario
              </IconButton>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="fs--1 border-top border-200 p-0">
          {paginatedQuestionaires.length > 0 ? (
            <>
              {questionaires.map(questionaire => (
                <Questionaire
                  key={questionaire.NO_ID_FIELD}
                  questionaire={questionaire}
                  isSelectedItem={isSelectedItem}
                  toggleSelectedItem={toggleSelectedItem}
                />
              ))}
            </>
          ) : (
            <Alert variant="info" className="mb-0 rounded-0">
              <h5 className="alert-heading">No hay cuestionarios</h5>
            </Alert>
          )}
        </Card.Body>
        <Footer
          totalItems={totalItems}
          from={from}
          to={to}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          prevPage={prevPage}
        />
      </Card>
    </>
  );
};

const CreationModal = ({ show: showProp }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [show, setShow] = useState(showProp);
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/company/questionaires');
  };

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="border-0"
      dialogClassName="mt-6"
    >
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className="bg-light px-x1 border-bottom-0"
      >
        <Modal.Title as="h5">Nuevo cuestionario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Comienza un cuestionario para obtener información y tomar acción para
          mejorar la satisfacción y la retención de los empleados/as.
        </p>
        <div className="d-grid">
          <Button
            variant="outline-primary"
            as={Link}
            to="/company/questionaires/create/templates"
          >
            Utilizar plantilla
          </Button>
        </div>
        <div className="d-grid mt-2">
          <Button
            variant="outline-primary"
            as={Link}
            to="/company/questionaires/create/blank"
          >
            Desde cero
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const TemplatesModal = ({ show: showProp }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { templates = [] } = useContext(QuestionairesContext);
  const [show, setShow] = useState(showProp);
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/company/questionaires');
  };

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="border-0"
      dialogClassName="mt-6"
    >
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className="bg-light px-x1 border-bottom-0"
      >
        <Modal.Title as="h5">Desde plantilla</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-grid">
        <p>Utiliza una de nuestras plantillas.</p>
        {templates.map(template => {
          const { NO_ID_FIELD, title } = template;
          return (
            <Button
              key={`Questionaire-Template-${NO_ID_FIELD}`}
              className="mb-2"
              variant="outline-primary"
              as={Link}
              to={`/company/questionaires/create/templates/${NO_ID_FIELD}`}
            >
              {title}
            </Button>
          );
        })}
      </Modal.Body>
    </Modal>
  );
};

const Questionaires = ({ templates, creation }) => {
  const { me } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    me?.type === 'employee' && navigate('/user/questionaires');
  }, [me?.type]);

  return (
    <QuestionaireProvider>
      <QuestionairesContent />
      <CreationModal show={creation} />
      <TemplatesModal show={templates} />
    </QuestionaireProvider>
  );
};

CreationModal.propTypes = {
  show: PropTypes.bool
};

TemplatesModal.propTypes = {
  show: PropTypes.bool
};

Questionaires.propTypes = {
  creation: PropTypes.bool,
  templates: PropTypes.bool
};

export default Questionaires;
