import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import PendingTimesOff from './PendingTimesOff';
import TimeOff from './TimeOff';
import TimeBalanceProvider from 'components/user/time-control/TimeBalanceProvider';
import EmployeeSelector from 'components/common/EmployeeSelector';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import Tooltip from 'components/common/Tooltip';
import AppContext, {
  CalendarContext,
  TimeBalanceContext,
  UserContext
} from 'context/Context';
import { getUnique, groupBy } from 'helpers/utils';
import timeOffSubtypes from 'components/user/time-off/subtypes.json';
import dayjs from 'dayjs';
import { useBreakpoints } from 'hooks/useBreakpoints';

const renderEventContent = eventInfo => {
  const { event = {}, partners, timeText } = eventInfo;
  const { allDay, extendedProps, title } = event;
  const { NO_ID_FIELD, participants, state, type } = extendedProps;
  let titleToShow = title;

  if (type === 'objective' && participants?.length) {
    titleToShow = `${titleToShow} - ${participants
      .map(ref => {
        const user = partners.find(partner => partner.ref?.path === ref?.path);
        const { name } = user || {};
        return name;
      })
      .join(', ')}`;
  }

  return (
    <Flex
      data-id={NO_ID_FIELD}
      alignItems="center"
      className="w-100"
      title={titleToShow}
    >
      {type === 'objective' && state && state !== 'on-course' && (
        <FontAwesomeIcon
          className={classNames('me-1', {
            'text-yellow': state === 'waiting',
            'text-success': state === 'success',
            'text-danger': state === 'fail'
          })}
          icon={classNames({
            'exclamation-circle': state !== 'success' && state !== 'fail',
            'check-circle': state === 'success',
            'times-circle': state === 'fail'
          })}
        />
      )}
      {type === 'time-off' && (
        <FontAwesomeIcon icon="suitcase-rolling" className="me-1 text-white" />
      )}
      {timeText && timeText !== '0' && (
        <small className="fw-bold fc-event-time">{timeText}h.</small>
      )}
      <span
        className={classNames('w-0 flex-grow-1 fc-event-title', {
          'fw-semi-bold': allDay,
          'text-white': type === 'time-off'
        })}
      >
        {titleToShow}
      </span>
    </Flex>
  );
};

const TimeOffsContent = ({ user }) => {
  const { hash } = useLocation();
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const {
    timeOff: data = [],
    setIsOpenNewTimeOffModal,
    setModalTimeOffContent,
    setIsOpenTimeOffModal,
    setScheduleStartDate,
    setScheduleEndDate
  } = useContext(CalendarContext);
  const { balance: balanceSeconds = 0 } = useContext(TimeBalanceContext);
  const { company, me, partners = [] } = useContext(UserContext);
  const [calendarApi, setCalendarApi] = useState({});
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const thisYear = new Date().getFullYear();
  const { vacationDays: companyVacationDays = 22, vacationLock } =
    company || {};
  const calendarRef = useRef();
  let {
    location,
    startAt,
    vacationDays: fullYearVacationDays = companyVacationDays
  } = user || {};
  const { holidays = [] } = location || {};
  const vacationDaysPerMonth = fullYearVacationDays / 12;

  const prevStartDateISO =
    startAt > `${currentYear - 1}-01-01` ? startAt : `${currentYear - 1}-01-01`;
  const prevStartDate = new Date(prevStartDateISO);
  const startDateISO =
    startAt > `${currentYear}-01-01` ? startAt : `${currentYear}-01-01`;
  const prevStartDateMonthDays = new Date(
    prevStartDate.getFullYear(),
    prevStartDate.getMonth() + 1,
    0
  ).getDate();
  const prevPercentage =
    ((prevStartDateMonthDays - (prevStartDate.getDate() - 1)) * 100) /
    prevStartDateMonthDays;
  const prevFirstMonthDays = (prevPercentage * vacationDaysPerMonth) / 100;
  const prevRestDays =
    (12 - (prevStartDate.getMonth() + 1)) * vacationDaysPerMonth;
  const prevYearGeneratedDays = parseInt(
    Number((prevFirstMonthDays + prevRestDays).toFixed(13))
  );
  const prevYearEmployeeTimeOff = data
    .filter(
      ({ start, participants }) =>
        `${new Date(start).getFullYear()}` === `${currentYear - 1}` &&
        (!participants ||
          participants.some(ref => ref?.path === user?.ref?.path))
    )
    .sort(({ start: s1 }, { start: s2 }) => (s1 < s2 ? -1 : 1));
  const prevYearApprovedTimeOff = prevYearEmployeeTimeOff.filter(
    ({ approved }) => approved === true
  );
  const prevYearApprovedDays = prevYearApprovedTimeOff
    .filter(({ subtype = 'vacation' }) => subtype === 'vacation')
    .reduce((total, { days }) => total + days, 0);
  const prevYearDays =
    prevYearApprovedDays > (80 * prevYearGeneratedDays) / 100
      ? prevYearGeneratedDays - prevYearApprovedDays
      : 0;

  const startDate = new Date(startDateISO);
  const startDateMonthDays = new Date(
    startDate.getFullYear(),
    startDate.getMonth() + 1,
    0
  ).getDate();
  const percentage =
    ((startDateMonthDays - (startDate.getDate() - 1)) * 100) /
    startDateMonthDays;
  const firstMonthDays = (percentage * vacationDaysPerMonth) / 100;
  const restDays = (12 - (startDate.getMonth() + 1)) * vacationDaysPerMonth;
  const generatedDays = parseInt(
    Number((firstMonthDays + restDays).toFixed(13))
  );

  const employeeTimeOff = data
    .filter(
      ({ start, participants }) =>
        `${new Date(start).getFullYear()}` === `${currentYear}` &&
        (!participants ||
          participants.some(ref => ref?.path === user?.ref?.path))
    )
    .sort(({ start: s1 }, { start: s2 }) => (s1 < s2 ? -1 : 1));
  const pendingTimeOff = (me?.type === 'admin' ? data : employeeTimeOff).filter(
    ({ approved = null }) => approved === null
  );
  const approvedTimeOff = employeeTimeOff.filter(
    ({ approved }) => approved === true
  );
  const notApprovedTimeOff = employeeTimeOff.filter(
    ({ approved }) => approved === false
  );
  const pastTimeOff = approvedTimeOff.filter(
    ({ end, subtype = 'vacation' }) =>
      end < yesterday.toISOString() && subtype === 'vacation'
  );
  const approvedDays = approvedTimeOff.reduce(
    (total, { days }) => total + days,
    0
  );
  const approvedVacationDays = approvedTimeOff
    .filter(({ subtype = 'vacation' }) => subtype === 'vacation')
    .reduce((total, { days }) => total + days, 0);
  const pastTimeOffDays = pastTimeOff.reduce(
    (total, { days }) => total + days,
    0
  );
  const myPendingDays = employeeTimeOff
    .filter(({ approved = null }) => approved === null)
    .reduce((total, { days }) => total + days, 0);

  const eventTimeFormat = {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: true,
    meridiem: true
  };

  const politicDays =
    generatedDays +
    (approvedVacationDays ? prevYearDays - approvedVacationDays : 0);
  const availablePrevYearDays = Math.max(
    0,
    prevYearDays - approvedVacationDays
  );
  const balanceHours = parseInt(balanceSeconds / 3600);
  const availableDays = politicDays + availablePrevYearDays;

  const handleEventClick = info => {
    setModalTimeOffContent(info);
    setIsOpenTimeOffModal(true);
  };

  const handleEnter = timeOff => {
    const { NO_ID_FIELD } = timeOff;
    const els = document.querySelectorAll(`[data-id="${NO_ID_FIELD}"]`);
    els?.forEach(el => {
      el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.add(
        'highlight'
      );
    });
  };

  const handleLeave = timeOff => {
    const { NO_ID_FIELD } = timeOff;
    const els = document.querySelectorAll(`[data-id="${NO_ID_FIELD}"]`);
    els?.forEach(el => {
      el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.remove(
        'highlight'
      );
    });
  };

  const handlePrevYear = () => {
    setCurrentYear(currentYear - 1);
    calendarApi.gotoDate(new Date(`${currentYear - 1}`));
  };

  const handleNextYear = () => {
    setCurrentYear(currentYear + 1);
    calendarApi.gotoDate(new Date(`${currentYear + 1}`));
  };

  useEffect(() => {
    const api = calendarRef?.current?.getApi();
    api && setCalendarApi(api);
  }, [calendarRef?.current]);

  useEffect(() => {
    if (!hash || !data?.length) {
      return;
    }
    const el = document.querySelector(hash);
    el?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    setTimeout(() => el.classList.add('highlight'), 600);
  }, [hash, data?.length]);

  setTimeout(() => {
    calendarApi?.updateSize?.();
  }, 0);

  return (
    <Row className="g-3 m-0">
      <Col xs={12} lg={5} className="m-0">
        <Card.Body className="fs--1">
          <Card className="border text-center mb-5 shadow-none">
            <Card.Header className="border-bottom">
              <Flex alignItems="center" justifyContent="between">
                <Button
                  disabled={currentYear === thisYear - 1}
                  variant="falcon-default"
                  size="sm"
                  onClick={handlePrevYear}
                >
                  <FontAwesomeIcon icon="chevron-left" />
                </Button>
                <div>
                  <h6>
                    {me?.NO_ID_FIELD === user?.NO_ID_FIELD
                      ? 'Mis ausencias'
                      : `Ausencias de ${user?.name}`}
                    <SoftBadge pill className="ms-2">
                      {generatedDays} días
                    </SoftBadge>
                  </h6>
                  <span>
                    Del {dayjs(startDate).format('D [de] MMMM')} al 31 de
                    diciembre de {currentYear}
                  </span>
                </div>
                <Button
                  disabled={currentYear === thisYear + 1}
                  variant="falcon-default"
                  size="sm"
                  onClick={handleNextYear}
                >
                  <FontAwesomeIcon icon="chevron-right" />
                </Button>
              </Flex>
            </Card.Header>
            <Card.Body>
              <Flex justifyContent="between" className="text-nowrap">
                <div className="w-0 flex-grow-1 px-2">
                  <h4 className="text-500">{myPendingDays}</h4>
                  <h6
                    className="text-truncate text-uppercase m-0"
                    title="Pendiente"
                  >
                    pendiente
                  </h6>
                </div>
                <div className="w-0 flex-grow-1 px-2">
                  <Tooltip
                    className="overflow-hidden w-100"
                    placement="bottom"
                    tooltipClass="max-w-400px"
                    title={
                      !!approvedDays && (
                        <div className="p-3 fw-semi-bold">
                          <h6 className="fs-0 text-white text-uppercase">
                            Días aprobados
                          </h6>
                          {!!prevYearDays && !!approvedDays && (
                            <Flex
                              alignItems="center"
                              className="py-2"
                              justifyContent="between"
                            >
                              <span className="d-block pe-4 text-start text-uppercase">
                                Vacaciones del año pasado
                              </span>{' '}
                              <span className="fs-1 d-block">
                                {Math.min(prevYearDays, approvedDays)}
                              </span>
                            </Flex>
                          )}
                          {Object.entries(
                            groupBy(approvedTimeOff, 'subtype')
                          ).map(([key, approvedTimeOff], index) => {
                            let approvedDays = approvedTimeOff.reduce(
                              (total, { days }) => total + days,
                              0
                            );
                            if (key === 'vacation' && prevYearDays) {
                              approvedDays = approvedDays - prevYearDays;
                            }

                            return (
                              !!approvedDays && (
                                <Flex
                                  key={`${key}-approved-days`}
                                  alignItems="center"
                                  className={classNames('py-2', {
                                    'border-top border-800':
                                      !!prevYearDays || index > 0
                                  })}
                                  justifyContent="between"
                                >
                                  <span className="d-block pe-4 text-start text-uppercase">
                                    {
                                      timeOffSubtypes.find(
                                        ({ value }) => value === key
                                      )?.label
                                    }
                                  </span>{' '}
                                  <span className="fs-1 d-block">
                                    {approvedDays}
                                  </span>
                                </Flex>
                              )
                            );
                          })}
                        </div>
                      )
                    }
                  >
                    <>
                      <h4 className="text-primary">
                        {approvedTimeOff.reduce(
                          (total, { days }) => total + days,
                          0
                        )}
                        {!!approvedDays && (
                          <FontAwesomeIcon
                            icon="chevron-down"
                            className="text-400 ms-1 fs-0"
                          />
                        )}
                      </h4>
                      <h6
                        className="text-truncate text-uppercase m-0"
                        title="Aprobado"
                      >
                        aprobado
                      </h6>
                    </>
                  </Tooltip>
                </div>
                <div className="w-0 flex-grow-1 px-2">
                  <h4>{pastTimeOffDays}</h4>
                  <h6
                    className="text-truncate text-uppercase m-0"
                    title="Usado"
                  >
                    usado
                  </h6>
                </div>
                <div className="w-0 flex-grow-1">
                  <Tooltip
                    className="overflow-hidden w-100"
                    placement="bottom"
                    tooltipClass="max-w-400px"
                    title={
                      !!availableDays && (
                        <div className="p-3 fw-semi-bold">
                          <h6 className="fs-0 text-white text-uppercase">
                            Días disponibles
                          </h6>
                          {!!politicDays && (
                            <Flex
                              alignItems="center"
                              className="py-2"
                              justifyContent="between"
                            >
                              <span className="d-block pe-4 text-start text-uppercase">
                                Por política
                              </span>{' '}
                              <span className="fs-1 d-block">
                                {politicDays}
                              </span>
                            </Flex>
                          )}
                          {!!availablePrevYearDays && (
                            <Flex
                              alignItems="center"
                              className="py-2 border-top border-800"
                              justifyContent="between"
                            >
                              <span className="d-block pe-4 text-start text-uppercase">
                                {currentYear === thisYear + 1
                                  ? 'De este año'
                                  : currentYear === thisYear
                                  ? 'Del año pasado'
                                  : `Del año ${currentYear - 1}`}
                              </span>{' '}
                              <span className="fs-1 d-block">
                                {prevYearDays - approvedVacationDays}
                              </span>
                            </Flex>
                          )}
                          {!!balanceHours && (
                            <>
                              <h6 className="border-top border-800 fs-0 pt-3 text-white text-uppercase">
                                Horas disponibles
                              </h6>
                              <Flex
                                alignItems="center"
                                className="py-2"
                                justifyContent="between"
                              >
                                <span className="d-block pe-4 text-start text-uppercase">
                                  Por acumulación de horas
                                </span>{' '}
                                <span className="fs-1 d-block">
                                  {balanceHours}
                                </span>
                              </Flex>
                            </>
                          )}
                        </div>
                      )
                    }
                  >
                    <>
                      <h4>
                        {generatedDays - approvedVacationDays + prevYearDays}
                        {!!availableDays && (
                          <FontAwesomeIcon
                            icon="chevron-down"
                            className="text-400 ms-1 fs-0"
                          />
                        )}
                      </h4>
                      <h6
                        className="text-truncate text-uppercase m-0"
                        title="Disponible"
                      >
                        disponible
                      </h6>
                    </>
                  </Tooltip>
                </div>
              </Flex>
            </Card.Body>
          </Card>
          <h5 className="fs-3">
            <FontAwesomeIcon icon={['far', 'calendar']} className="text-500" />
          </h5>
          <h5>Solicitudes de ausencia pendientes de revisión</h5>
          <PendingTimesOff className="border mt-3 mb-5 shadow-none" />
          <h5 className="fs-3">
            <FontAwesomeIcon
              icon={['far', 'calendar-check']}
              className="text-success"
            />
          </h5>
          <h5>Ausencia aprobadas</h5>
          <Card className="border mt-3 mb-5 shadow-none">
            <Card.Body>
              {!approvedTimeOff?.length && 'No hay ausencias aprobadas'}
              {approvedTimeOff.map((details, index) => (
                <TimeOff
                  key={`TimeOff-${details.NO_ID_FIELD}`}
                  color="success"
                  details={details}
                  isLast={index === approvedTimeOff.length - 1}
                  showUser={false}
                  onMouseEnter={() => handleEnter(details)}
                  onMouseLeave={() => handleLeave(details)}
                />
              ))}
            </Card.Body>
          </Card>
          <h5 className="fs-3">
            <FontAwesomeIcon
              icon={['far', 'calendar-times']}
              className="text-danger"
            />
          </h5>
          <h5>Ausencia denegadas</h5>
          <Card className="border mt-3 mb-5 shadow-none">
            <Card.Body>
              {!notApprovedTimeOff?.length && 'No hay ausencias denegadas'}
              {notApprovedTimeOff.map((details, index) => (
                <TimeOff
                  key={`TimeOff-${details.NO_ID_FIELD}`}
                  color="danger"
                  details={details}
                  isLast={index === notApprovedTimeOff.length - 1}
                  showUser={false}
                  onMouseEnter={() => handleEnter(details)}
                  onMouseLeave={() => handleLeave(details)}
                />
              ))}
            </Card.Body>
          </Card>
        </Card.Body>
      </Col>
      <Col xs={12} lg={7} className="time-off-calendar bg-200 m-0">
        <div className="sticky-sidebar p-3">
          <FullCalendar
            firstDay={1}
            headerToolbar={{
              start: '',
              center: 'title',
              end: ''
            }}
            ref={calendarRef}
            plugins={[multiMonthPlugin, interactionPlugin]}
            locale="es"
            initialView="multiMonthYear"
            themeSystem="bootstrap"
            dayHeaderFormat={{ weekday: 'narrow' }}
            dayMaxEvents={9}
            direction={isRTL ? 'rtl' : 'ltr'}
            multiMonthMaxColumns={4}
            multiMonthMinWidth={160}
            selectable
            selectMirror
            select={info => {
              const end = new Date(
                new Date(info.end).setDate(new Date(info.end).getDate() - 1)
              );
              if (
                [6, 0].includes(info.start.getDay()) &&
                [6, 0].includes(end.getDay())
              ) {
                return;
              }
              setIsOpenNewTimeOffModal(true);
              setScheduleStartDate(info.start);
              setScheduleEndDate(end);
            }}
            eventTimeFormat={eventTimeFormat}
            eventClick={handleEventClick}
            events={[
              ...(vacationLock?.map(data => ({ ...data, subtype: 'lock' })) ||
                []),
              ...(holidays?.map(data => ({ ...data, subtype: 'holiday' })) ||
                []),
              ...getUnique(
                [...pendingTimeOff, ...approvedTimeOff],
                'NO_ID_FIELD'
              )
            ].map(item => {
              let { className, end, start } = item;
              const _end =
                end && start !== end
                  ? new Date(
                      new Date(end).setDate(new Date(end).getDate() + 1)
                    ).toISOString()
                  : end;
              return {
                ...item,
                allDay: true,
                className,
                end: _end
              };
            })}
            eventClassNames={data => {
              const { event } = data || {};
              const { extendedProps } = event || {};
              const { approved, subtype = 'vacation' } = extendedProps || {};
              const approvedClass =
                typeof approved !== 'undefined' &&
                `time-off-${
                  approved === true
                    ? 'approved'
                    : approved === false
                    ? 'not-approved'
                    : 'pending'
                }`;
              const subtypeClass = `time-off-${subtype}`;
              return [subtypeClass, approvedClass];
            }}
            eventContent={params => renderEventContent({ ...params, partners })}
            moreLinkContent=""
            moreLinkClassNames=""
          />
          <Flex
            className="gap-2"
            alignItems="center"
            justifyContent="center"
            wrap="wrap"
          >
            <SoftBadge
              key="Calendar-Subtype-Today"
              alignItems="center"
              bg="light"
              pill
              as={Flex}
            >
              <FontAwesomeIcon
                className="bg-primary border border-2 border-primary text-primary rounded-circle me-2"
                icon="circle"
              />
              <span>Hoy</span>
            </SoftBadge>
            <SoftBadge
              key="Calendar-Subtype-Holidays"
              alignItems="center"
              bg="light"
              pill
              as={Flex}
            >
              <FontAwesomeIcon
                className="bg-accent fs-0 opacity-25 text-transparent rounded-circle me-2"
                icon="circle"
              />
              <span>Festivos</span>
            </SoftBadge>
            {timeOffSubtypes
              .filter(({ legend }) => legend)
              .map(({ color, label, value }) => (
                <SoftBadge
                  key={`Calendar-Subtype-${value}`}
                  alignItems="center"
                  bg="light"
                  pill
                  as={Flex}
                >
                  <FontAwesomeIcon
                    className={`border border-2 border-${color} text-light rounded-circle me-2`}
                    icon="circle"
                  />
                  <span>{label}</span>
                </SoftBadge>
              ))}
          </Flex>
        </div>
      </Col>
    </Row>
  );
};

TimeOffsContent.propTypes = {
  user: PropTypes.object
};

const TimeOffs = ({ userId }) => {
  const { breakpoints } = useBreakpoints();
  const {
    selectedEmployeeId,
    setSelectedEmployeeId,
    setIsOpenNewTimeOffModal,
    setIsOpenLockModal
  } = useContext(CalendarContext);
  const {
    company,
    locations,
    me,
    partners = [],
    selectLocationId,
    setIsOpenHolidaysModal
  } = useContext(UserContext);
  const [showPolitics, setShowPolitics] = useState(false);
  const selectedEmployee = partners?.find(
    ({ NO_ID_FIELD }) => NO_ID_FIELD === selectedEmployeeId
  );
  const { vacationPolitics: companyVacationPolitics } = company || {};
  const { vacationPolitics = companyVacationPolitics } = selectedEmployee || {};

  const handleChangeEmployee = ({ value }) => {
    setSelectedEmployeeId(value);
  };

  useEffect(() => {
    userId && setSelectedEmployeeId?.(userId);
  }, [userId]);

  useEffect(() => {
    if (userId || selectedEmployeeId?.NO_ID_FIELD) {
      return;
    }
    setSelectedEmployeeId?.(me?.NO_ID_FIELD);
  }, [me?.NO_ID_FIELD]);

  const partnersId = partners?.map(({ NO_ID_FIELD }) => NO_ID_FIELD) || [];
  useEffect(() => {
    if (
      !partnersId ||
      company?.NO_ID_FIELD ===
        partners?.find(({ NO_ID_FIELD }) => NO_ID_FIELD === selectedEmployeeId)
          ?.company
    ) {
      return;
    }
    setSelectedEmployeeId?.(
      me?.company === company?.NO_ID_FIELD
        ? me?.NO_ID_FIELD
        : partners?.[0]?.company === company?.NO_ID_FIELD
        ? partners?.[0]?.NO_ID_FIELD
        : ''
    );
  }, [partnersId]);

  return (
    <>
      <Card>
        <Card.Header as={Flex} alignItems="center" className="bg-light">
          <Row className="w-100 g-2">
            <Col xs={true}>
              {me?.type === 'admin' && !userId && (
                <EmployeeSelector
                  className="w-100 max-w-350px"
                  isClearable={false}
                  value={
                    selectedEmployee && {
                      avatar: selectedEmployee?.avatar,
                      label: selectedEmployee?.name,
                      value: selectedEmployee?.NO_ID_FIELD
                    }
                  }
                  onChange={handleChangeEmployee}
                />
              )}
            </Col>
            {vacationPolitics && (
              <>
                <Modal
                  show={showPolitics}
                  size="lg"
                  onHide={() => setShowPolitics(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Política de vacaciones</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="custom-html-style">
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {vacationPolitics}
                    </ReactMarkdown>
                  </Modal.Body>
                </Modal>
                <Col xs="auto">
                  <IconButton
                    variant="falcon-default"
                    icon={['fab', 'readme']}
                    transform="shrink-3"
                    size={breakpoints.up('lg') && 'sm'}
                    onClick={() => setShowPolitics(true)}
                  >
                    {breakpoints.up('lg') && (
                      <span>Política de vacaciones</span>
                    )}
                  </IconButton>
                </Col>
              </>
            )}

            {me?.type === 'admin' && (
              <>
                <Col xs="auto">
                  <IconButton
                    variant="falcon-default"
                    icon="calendar-day"
                    transform="shrink-3"
                    size={breakpoints.up('lg') && 'sm'}
                    onClick={() => {
                      selectLocationId(locations?.[0]?.NO_ID_FIELD);
                      setIsOpenHolidaysModal(isOpen => !isOpen);
                    }}
                  >
                    {breakpoints.up('lg') && <span>Días festivos</span>}
                  </IconButton>
                </Col>
                <Col xs="auto">
                  <IconButton
                    variant="falcon-default"
                    icon="lock"
                    transform="shrink-3"
                    size={breakpoints.up('lg') && 'sm'}
                    onClick={() => {
                      setIsOpenLockModal(isOpen => !isOpen);
                    }}
                  >
                    {breakpoints.up('lg') && <span>Días bloqueados</span>}
                  </IconButton>
                </Col>
              </>
            )}
            <Col xs="auto">
              <IconButton
                variant="primary"
                icon="plus"
                transform="shrink-3"
                size={breakpoints.up('lg') && 'sm'}
                onClick={() => {
                  setIsOpenNewTimeOffModal(isOpen => !isOpen);
                }}
              >
                {breakpoints.up('lg') && <span>Crear ausencia</span>}
              </IconButton>
            </Col>
          </Row>
        </Card.Header>
        <TimeBalanceProvider userId={selectedEmployeeId}>
          <TimeOffsContent user={selectedEmployee} />
        </TimeBalanceProvider>
      </Card>
    </>
  );
};

TimeOffs.propTypes = {
  userId: PropTypes.string
};

export default TimeOffs;
