import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AIContext, UserContext } from 'context/Context';
import AIModal from './AIModal';

const AIProvider = ({ children }) => {
  const { authUser } = useContext(UserContext);
  const { accessToken } = authUser || {};
  const [show, setShow] = useState(false);
  const [actions, setActions] = useState();
  const [content, setContent] = useState();
  const [filename, setFilename] = useState();

  const ai = async ({ actions, context = [], prompt, filename }) => {
    setShow(true);
    actions?.length && setActions(actions);
    filename && setFilename(filename);
    let _content = [...context];
    setContent((content = []) => {
      _content = [...content, ...context];
      return [..._content, { content: prompt, role: 'user' }];
    });

    const response = await fetch(`${process.env.REACT_APP_FIREBASE_URL}/ai`, {
      method: 'post',
      body: JSON.stringify({
        context: _content,
        prompt
      }),
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await response.json();
    const { choices = [], content = [] } = json;
    const { message = {} } = choices?.[0] || {};
    setContent([...content, message]);
  };

  useEffect(() => {
    if (!show) {
      setContent();
      setFilename();
    }
  }, [show]);

  // console.log('AI >>>', content);

  return (
    <AIContext.Provider
      value={{ ai, actions, content, filename, show, setShow }}
    >
      {children}
      <AIModal />
    </AIContext.Provider>
  );
};

AIProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AIProvider;
