import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { getCurrencyFormat } from 'helpers/utils';

const ProductOptions = ({ data = [] }) => {
  if (!data?.length) {
    return null;
  }
  return (
    <Card className="mb-3">
      <FalconCardHeader title="Opciones" titleTag="h5" light />
      <Card.Body className="p-0">
        {data.map((item, index) => (
          <Item key={item.id} item={item} isLast={index === data.length - 1} />
        ))}
      </Card.Body>
    </Card>
  );
};

ProductOptions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
};

const Item = ({ item, isLast }) => {
  return (
    <>
      <Flex
        key={item.id}
        className={classNames('px-x1 py-3', {
          'border-bottom border-200': !isLast
        })}
      >
        <Avatar
          name={item.description}
          isExact={true}
          maxChars={item?.description?.split(' ')?.[0]?.length}
        />
        <div className="flex-1 ms-3">
          <h5 className="text-primary fs-2 p-0 fw-medium">{item.title}</h5>
          <p className="fs--1 mb-0">{item.description}</p>
        </div>

        {item.isLocked ? (
          <FontAwesomeIcon icon="lock" className="fs--1 text-secondary" />
        ) : (
          <h5 className="text-primary">{getCurrencyFormat(item.price)}</h5>
        )}
      </Flex>
    </>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  isLast: PropTypes.bool
};

export default ProductOptions;
