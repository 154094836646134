import React, { useRef } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import useScrollSpy from 'react-use-scrollspy';

const PrivacyPolicy = () => {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  return (
    <>
      <PageHeader
        title="Política de privacidad"
        description="Política de privacidad de protección de datos personales"
        className="mb-3"
      />
      <Row className="g-0">
        <Col lg={8} className="pe-lg-2">
          <Card className="mb-3">
            <Card.Body>
              <h6 id="intro" className="text-primary" ref={sectionRefs[0]}>
                INTRODUCCIÓN
              </h6>
              <p>
                La entidad HAPPYFY SOFTWARE SL con NIF B72769904 con domicilio
                en C/ de la Trama, 1 de Ontinyent (Valencia) (en adelante
                HAPPYFY), desea poner en conocimiento de los usuarios de la web,
                como responsable del Tratamiento de la política llevada a cabo
                respecto al tratamiento de todos los datos de carácter personal
                que se faciliten a través de los formularios de la web, redes
                sociales u otros medios tanto electrónicos como en papel.
              </p>
              <p>
                La recogida de datos personales, el tratamiento y su utilización
                posterior se sujetan a la legislación vigente en España en
                materia de protección de datos, establecida actualmente por la
                Ley 3/2018, de 5 de diciembre de Protección de Datos Personales
                y Garantía de los Derechos Digitales (en adelante LOPD-GDD) y su
                normativa complementaria, y por el Reglamento (UE) 2016/679 del
                Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo
                a la protección de las personas físicas en lo que respecta al
                tratamiento de datos personales y a la libre circulación de
                estos datos y por el que se deroga la Directiva 95/46/CE
                (Reglamento general de protección de datos).
              </p>
              <hr className="my-4" />
              <h6 id="where" className="text-primary" ref={sectionRefs[1]}>
                DONDE SE RECOGEN SUS DATOS PERSONALES
              </h6>
              <p>
                HAPPYFY informa a sus usuarios que la recogida de datos de
                personales podría obtenerse, principalmente, a través del envío
                de un correo electrónico a la dirección electrónica
                info@brandsproducts.es , siempre que usted incluyese en el mismo
                algún dato de carácter personal, así como por medio de los
                formularios que HAPPYFY tiene en el sitio web y en los que se
                identifique a HAPPYFY como responsable de los datos recabados
                por medio de los mismos.
              </p>
              <p>
                HAPPYFY informa a los usuarios del sitio web que el carácter
                facultativo u obligatorio de la cumplimentación de la totalidad
                de los datos solicitados será advertido previamente.
              </p>
              <p>
                Si usted no desea que HAPPYFY someta a tratamiento sus datos
                personales, con la finalidad indicada, le rogamos se abstenga de
                cumplimentar los formularios existentes en el sitio web. En
                cualquier caso, se le informará expresamente y de forma legible
                respecto de las condiciones en que se recogen datos personales y
                las finalidades con las que van a ser utilizados, de la
                obligación de facilitarlos o no, de los derechos que asisten al
                usuario, de las condiciones en que se pueden ejercitar, y de
                cualquier otra información complementaria que resulte necesaria.
              </p>
              <p>
                Por tratamiento de datos personales se entenderá aquellas
                operaciones y procedimientos técnicos de carácter automatizado o
                no, que permitan la recogida, grabación, conservación,
                elaboración, modificación, bloqueo y cancelación, así como las
                cesiones de datos que resulten de comunicaciones, consultas,
                interconexiones y transferencias.
              </p>
              <hr className="my-4" />
              <h6
                id="responsible"
                className="text-primary"
                ref={sectionRefs[2]}
              >
                RESPONSABLE DE TRATAMIENTO
              </h6>
              <p>Denominación Social: HAPPYFY SOFTWARE SL</p>
              <p>NIF: B72769904</p>
              <p>
                Domicilio Social: C/ de la Trama, 1 de Ontinyent (Valencia){' '}
              </p>
              <p>Teléfono: 625 03 15 81 </p>
              <p>Email de contacto de la empresa: info@happyfy.app</p>
              <hr className="my-4" />
              <h6 id="purpose" className="text-primary" ref={sectionRefs[3]}>
                FINALIDAD DE LA RECOGIDA Y TRATAMIENTO DE DATOS PERSONALES
              </h6>
              <p>
                Respecto de los datos personales recogidos por e-mail y/o
                cumplimentación de los formularios de la web, las finalidades de
                tratamiento serán las siguientes:{' '}
              </p>
              <ol type="a">
                <li>
                  GESTION CONTACTOS: contestar a su comunicación; atender su
                  solicitud de información; incluirle en la agenda de contactos
                  a usted o a la empresa a la que pertenece, facilitar futuras
                  relaciones comerciales o gestionar la relación comercial
                  actualmente existente, llevar a cabo las comunicaciones
                  necesarias a través de usted, remitirle la documentación
                  necesaria o información solicitada, así como mantener un
                  histórico de relaciones comerciales y de comunicaciones.
                </li>
                <li>
                  VIDEOVIGILANCIA. Videovigilancia de las instalaciones por
                  motivos de seguridad.
                </li>
                <li>
                  GESTION CLIENTES Y PROVEEDORES: Prestar el servicio contratado
                  o la entrega del bien solicitado, la facturación y el cobro.
                  Envío de comunicaciones comerciales del interés de nuestros
                  clientes, así como de mantener informados acerca de las
                  novedades y promociones de productos y servicios que pueda
                  ofrecer HAPPYFY
                </li>
              </ol>
              <hr className="my-4" />
              <h6 id="decisions" className="text-primary" ref={sectionRefs[4]}>
                DECISIONES INDIVIDUALES AUTOMATIZADAS
              </h6>
              <p>
                HAPPYFY no utiliza procesos de toma de decisiones totalmente
                automatizadas para entablar, desarrollar o terminar una relación
                contractual con el cliente. Con el fin de poder ofrecerle
                productos y/o servicios de acuerdo con sus intereses y mejorar
                su experiencia de usuario, podremos elaborar un "perfil
                comercial" en base a la información facilitada. No obstante, no
                se tomarán decisiones automatizadas en base a dicho perfil.
              </p>
              <hr className="my-4" />
              <h6
                id="legitimation"
                className="text-primary"
                ref={sectionRefs[5]}
              >
                LEGITIMACIÓN
              </h6>
              <p>
                La legitimación del tratamiento de datos personales está basada
                en el consentimiento del usuario al aceptar la política de
                privacidad y política de cookies y enviarnos sus datos
                personales. El usuario puede revocar su consentimiento en
                cualquier momento. En cada proceso de toma de datos a las
                diferentes categorías de interesados, se establece la
                legitimación al tratamiento de los datos que siempre está basado
                en las siguientes consideraciones:
              </p>
              <ol type="a">
                <li>
                  el interesado dio su consentimiento para el tratamiento de sus
                  datos personales para uno o varios fines específicos;{' '}
                </li>
                <li>
                  el tratamiento es necesario para la ejecución de un contrato
                  en el que el interesado es parte o para la aplicación a
                  petición de este de medidas precontractuales;{' '}
                </li>
                <li>
                  el tratamiento es necesario para el cumplimiento de una
                  obligación legal aplicable al responsable del tratamiento;
                </li>
                <li>
                  el tratamiento es necesario para la satisfacción de intereses
                  legítimos perseguidos por el responsable del tratamiento o por
                  un tercero, siempre que sobre dichos intereses no prevalezcan
                  los intereses o los derechos y libertades fundamentales del
                  interesado que requieran la protección de datos personales.
                </li>
              </ol>
              <hr className="my-4" />
              <h6
                id="confidenciality"
                className="text-primary"
                ref={sectionRefs[6]}
              >
                CONFIDENCIALIDAD Y SEGURIDAD
              </h6>
              <p>
                Los datos de carácter personal objeto de tratamiento no se
                utilizarán para otras finalidades que no se encuentren aquí
                recogidas o, en su caso, por otro documento o contrato que
                vinculase a ambas partes con condiciones particulares.
              </p>
              <p>
                Salvo consentimiento expreso por parte de los usuarios, no
                realizará cesiones ni comunicaciones de datos a terceros que no
                estén previstos en la LOPD.
              </p>
              <p>
                El responsable tomará medidas para garantizar que cualquier
                persona que actúe bajo su autoridad y tenga acceso a datos
                personales solo pueda tratar dichos datos siguiendo
                instrucciones del responsable, salvo que esté obligada a ello en
                virtud del Derecho de la Unión o de los Estados miembros.
              </p>
              <p>
                El Responsable de Tratamiento informa a los usuarios que,
                teniendo en cuenta el estado de la técnica, los costes de
                aplicación, y la naturaleza, el alcance, el contexto y los fines
                del tratamiento, así como riesgos de probabilidad y gravedad
                variables para los derechos y libertades de las personas
                físicas, el responsable y, en su caso, el encargado del
                tratamiento aplicará, medidas técnicas y organizativas
                apropiadas para garantizar un nivel de seguridad adecuado al
                riesgo.
              </p>
              <hr className="my-4" />
              <h6 id="quality" className="text-primary" ref={sectionRefs[7]}>
                CALIDAD DE LOS DATOS
              </h6>
              <p>
                Los datos que se comuniquen a través de la web o un formulario
                tendrán que ser exactos y puestos al día siendo de exclusiva
                responsabilidad de los usuarios la actualización de los datos.
              </p>
              <hr className="my-4" />
              <h6 id="rights" className="text-primary" ref={sectionRefs[8]}>
                EJERCICIO DE LOS DERECHOS
              </h6>
              HAPPYFY le informa de que los usuarios podrán ejercitar los
              derechos de acceso, rectificación, supresión, oposición,
              limitación al tratamiento de datos y a la portabilidad de los
              datos, mediante cualquiera de los siguientes medios: El ejercicio
              de estos derechos por parte del usuario deberá realizarse
              dirigiendo una comunicación escrita, junto con la fotocopia del
              Documento Nacional de Identidad u otro medio válido en derecho que
              acredite la identidad del usuario, a la siguiente dirección:
              HAPPYFY, sito en C/ de la Trama, 1 de Ontinyent - Valencia . Dicha
              comunicación contendrá la siguiente información: Nombre y
              apellidos del usuario. Petición en que concreta la solicitud.
              Domicilio a efectos de notificaciones, fecha y firma del usuario.
              Documentos acreditativos de la petición que formula, en su caso.
              Por correo electrónico en la dirección info@brandsproducts.es al
              que deberá dirigir una comunicación desde la cuenta registrada en
              nuestros ficheros. Dicha comunicación contendrá la misma
              información que la indicada en el apartado anterior.
              <hr className="my-4" />
              <h6
                id="conservation"
                className="text-primary"
                ref={sectionRefs[9]}
              >
                TIEMPO DE CONSERVACIÓN
              </h6>
              Los datos personales identificativos de clientes y proveedores,
              son conservados mientras se mantenga relación contractual con
              ellos, permanezca de alta en el servicio o no ha solicitado
              expresamente la baja y supresión de datos, y siempre en el plazo
              mínimo obligado por la legislación. Los datos de contactos se
              conservan indefinidamente hasta que han solicitado expresamente la
              baja. Las imágenes de videovigilancia se conservarán durante el
              plazo máximo de un mes desde su captación. Se procederá a la
              eliminación de los datos recogidos cuando dejen de ser necesarios
              o pertinentes para la finalidad para la que hubiesen sido
              recogidos o registrados. En el caso de que los datos recogidos se
              pretendan utilizar para una finalidad distinta a la que hubiesen
              sido recogidos se requerirá el consentimiento previo de los
              interesados.
              <hr className="my-4" />
              <h6 id="minors" className="text-primary" ref={sectionRefs[10]}>
                MENORES
              </h6>
              A través de los formularios no recogemos el dato de fecha de
              nacimiento ni edad, por lo que solo los mayores de edad, podrán
              enviarnos sus datos personales, o en caso contrario deberá remitir
              la autorización del padre, madre o tutor. GARANTIAS DE LOS
              DERECHOS DIGITALES Tal y como establece la nueva Ley 3/2018 de la
              LOPDGDD: Los usuarios de la Web y redes sociales, tienen derecho a
              la seguridad de las comunicaciones que transmitan y reciban a
              través de Internet y tienen todos derecho a la libertad de
              expresión en Internet. HAPPYFY ha adoptado protocolos adecuados
              para posibilitar el ejercicio del derecho de rectificación ante
              los usuarios que difundan contenidos que atenten contra el derecho
              al honor, la intimidad personal y familiar en Internet y el
              derecho a comunicar o recibir libremente información veraz. Toda
              persona tiene derecho a que sean suprimidos, a su simple
              solicitud, los datos personales que hubiese facilitado para su
              publicación por servicios de redes sociales de HAPPYFY y servicios
              de la sociedad de la información equivalentes. Toda persona tiene
              derecho a que sean suprimidos los datos personales que le
              conciernan y que hubiesen sido facilitados por terceros para su
              publicación por los servicios de redes sociales y servicios de la
              sociedad de la información equivalentes cuando fuesen inadecuados,
              inexactos, no pertinentes, no actualizados o excesivos o hubieren
              devenido como tales por el transcurso del tiempo, teniendo en
              cuenta los fines para los que se recogieron o trataron, el tiempo
              transcurrido y la naturaleza e interés público de la información.
              <hr className="my-4" />
              <h6 id="update" className="text-primary" ref={sectionRefs[11]}>
                ACTUALIZACIÓN DE LA POLÍTICA DE PRIVACIDAD
              </h6>
              <p>
                Es posible que se deba actualizar esta Política de Privacidad;
                por ello es necesario que revises esta política periódicamente y
                si es posible cada vez que accedas al Sitio Web con el objetivo
                de estar adecuadamente informado sobre el tipo de información
                recopilada y su tratamiento. Puedes encontrar la Política de
                Privacidad al pie del Sitio Web. No obstante, te comunicaremos
                cualquier modificación de la presente política de privacidad que
                afecte al tratamiento de tus datos personales.
              </p>
              <p>
                Fecha de la última actualización de la política de privacidad:
                30 de septiembre de 2022
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} className="ps-lg-2">
          <div className="sticky-sidebar">
            <Card className="sticky-top">
              <Card.Header className="border-bottom">
                <h6 className="mb-0 fs-0">Índice</h6>
              </Card.Header>
              <Card.Body>
                <div
                  id="terms-sidebar"
                  className="terms-sidebar nav flex-column fs--1"
                >
                  <Nav.Item>
                    <Nav.Link
                      href="#intro"
                      className="nav-link px-0 py-1"
                      active={activeSection === 0}
                    >
                      Introducción
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#where"
                      className="nav-link px-0 py-1"
                      active={activeSection === 1}
                    >
                      Dónde se recogen tus datos
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#responsible"
                      className="nav-link px-0 py-1"
                      active={activeSection === 2}
                    >
                      Responsable de tratamiento
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#purpose"
                      className="nav-link px-0 py-1"
                      active={activeSection === 3}
                    >
                      Finalidad y tratamiento
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#decisions"
                      className="nav-link px-0 py-1"
                      active={activeSection === 4}
                    >
                      Decisines individuales automatizadas
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#legitimation"
                      className="nav-link px-0 py-1"
                      active={activeSection === 5}
                    >
                      Legitimación
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#confidenciality"
                      className="nav-link px-0 py-1"
                      active={activeSection === 6}
                    >
                      Confidencialidad y seguridad
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#quality"
                      className="nav-link px-0 py-1"
                      active={activeSection === 7}
                    >
                      Calidad de los datos
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#rights"
                      className="nav-link px-0 py-1"
                      active={activeSection === 8}
                    >
                      Ejercicio de los derechos
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#conservation"
                      className="nav-link px-0 py-1"
                      active={activeSection === 9}
                    >
                      Tiempo de conservación
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#minors"
                      className="nav-link px-0 py-1"
                      active={activeSection === 10}
                    >
                      Menores
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#update"
                      className="nav-link px-0 py-1"
                      active={activeSection === 11}
                    >
                      Actualización de política
                    </Nav.Link>
                  </Nav.Item>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PrivacyPolicy;
