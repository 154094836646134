import React, { useContext } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import {
  ticketBadges,
  ticketPriorities,
  ticketTypes
} from 'components/feedback';
import { AgentSelect } from 'components/dashboards/support-desk/unsolved-tickets/TicketsLayout';
import { FeedbackContext } from 'context/Context';

const TicketFilteringForm = () => {
  const { feedback, filters, setFilter } = useContext(FeedbackContext);
  const ticketTags =
    feedback
      ?.reduce((all, { tags }) => [...(all || []), ...(tags || [])], [])
      .filter(tag => tag) || [];

  const handleFilter = ({ target }) => {
    const { name, value } = target;
    const filter = value ? { [name]: value } : {};
    setFilter(filters => {
      if (!value) {
        delete filters?.[name];
      }
      return { ...filters, ...filter };
    });
  };

  const handleFilterAgent = ({ value }) => {
    setFilter(filters => ({ ...filters, agent: value }));
  };
  const handleFilterTag = () => {};

  return (
    <Card className="shadow-none shadow-show-xl scrollbar">
      <Card.Header className="bg-light d-none d-xl-block">
        <h6 className="mb-0">Filtros</h6>
      </Card.Header>
      <Card.Body>
        <Form>
          <div className="mb-2 mt-n2">
            <Form.Label className="mb-1 fs--1">Prioridad</Form.Label>
            <Form.Select size="sm" name="priority" onChange={handleFilter}>
              <option>-</option>
              {ticketPriorities.map(({ title, value }) => (
                <option key={`Priority-Filter-${value}`} value={value}>
                  {title}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs--1">Estado</Form.Label>
            <Form.Select size="sm" name="status" onChange={handleFilter}>
              <option>-</option>
              {ticketBadges.map(({ content, value }) => (
                <option key={`Status-Filter-${value}`} value={value}>
                  {content}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs--1">Encargado</Form.Label>
            <AgentSelect onChange={handleFilterAgent} placeholder="-" />
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs--1">Tipo</Form.Label>
            <Form.Select size="sm" name="type" onChange={handleFilter}>
              <option>-</option>
              {ticketTypes.map(({ title, value }) => (
                <option key={`Type-Filter-${value}`} value={value}>
                  {title}
                </option>
              ))}
            </Form.Select>
          </div>
          {!!ticketTags?.length && (
            <div className="mb-2">
              <Form.Label className="mb-1 mt-2 fs--1">Tags</Form.Label>
              <Form.Select size="sm" onChange={handleFilterTag}>
                <option>-</option>
                {ticketTags.map((tag, index) => (
                  <option key={`Tag-Filter-${tag}-${index}`} value={tag}>
                    {tag}
                  </option>
                ))}
              </Form.Select>
            </div>
          )}
        </Form>
      </Card.Body>
      {filters && (
        <Card.Footer className="border-top border-200 py-x1">
          <Button
            varient="primary"
            className="w-100"
            onClick={() => setFilter()}
          >
            Borrar filtros
          </Button>
        </Card.Footer>
      )}
    </Card>
  );
};

export default TicketFilteringForm;
