import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import AppContext, { UserContext } from 'context/Context';
import Logo from 'components/common/Logo';
import SearchBox from './SearchBox';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { useLocation } from 'react-router-dom';

const breadCrumbsMap = {
  company: 'Empresa',
  feedback: 'Sugerencias',
  calendar: 'Calendario',
  events: 'Eventos',
  employees: 'Empleados',
  objectives: 'Objetivos',
  user: 'Usuario',
  profile: 'Perfil'
};

const SearchBoxWrapper = () => {
  const { recentlyBrowsedItems = [] } = useContext(AppContext);
  const { partners = [] } = useContext(UserContext);
  const autoComplete = [
    ...recentlyBrowsedItems.map(url => ({
      id: url,
      url,
      breadCrumbTexts: url
        .split('/')
        .filter(part => part)
        .map(key => breadCrumbsMap[key] || key),
      catagories: 'recentlyBrowsedItems'
    })),
    ...partners
      .sort((p1, p2) => (p1.name < p2.name ? -1 : 1))
      .map(({ avatar, department, id, name, status }) => ({
        id,
        url: `/user/profile/${id}`,
        catagories: 'users',
        icon: {
          img: avatar,
          size: 'l',
          status
        },
        title: name,
        text: department
      }))
  ];
  return <SearchBox autoCompleteItem={autoComplete} />;
};

const NavbarTop = () => {
  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed },
    setConfig
  } = useContext(AppContext);

  const { pathname } = useLocation();
  const isChat = pathname.includes('chat');

  const [showDropShadow, setShowDropShadow] = useState(false);

  const handleBurgerMenu = () => {
    (navbarPosition === 'top' || navbarPosition === 'double-top') &&
      setConfig('navbarCollapsed', !navbarCollapsed);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
      setConfig('showBurgerMenu', !showBurgerMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  const burgerMenuRef = useRef();

  return (
    <Navbar
      className={classNames('navbar-glass fs--1 navbar-top sticky-kit', {
        // 'navbar-glass-shadow': showDropShadow
        'navbar-glass-shadow': showDropShadow && !isChat
      })}
      expand={
        navbarPosition === 'top' ||
        navbarPosition === 'combo' ||
        navbarPosition === 'double-top'
          ? topNavbarBreakpoint
          : true
      }
    >
      <NavbarTopElements
        navbarCollapsed={navbarCollapsed}
        navbarPosition={navbarPosition}
        handleBurgerMenu={handleBurgerMenu}
        burgerMenuRef={burgerMenuRef}
      />
    </Navbar>
  );
};

const NavbarTopElements = ({ navbarPosition, handleBurgerMenu }) => {
  const burgerMenuRef = useRef();
  return (
    <>
      <Navbar.Toggle
        ref={burgerMenuRef}
        className={classNames('toggle-icon-wrapper me-md-3 me-2', {
          'd-lg-none':
            navbarPosition === 'top' || navbarPosition === 'double-top',
          [`d-${navbarBreakPoint}-none`]:
            navbarPosition === 'vertical' || navbarPosition === 'combo'
        })}
        as="div"
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>

      <Logo at="navbar-top" width={128} id="topLogo" />

      <Nav
        navbar
        className={`align-items-center d-none d-${topNavbarBreakpoint}-block`}
        as="ul"
      >
        <Nav.Item as="li">
          <SearchBoxWrapper />
        </Nav.Item>
      </Nav>
      <TopNavRightSideNavItem />
    </>
  );
};

NavbarTopElements.propTypes = {
  navbarPosition: PropTypes.string,
  handleBurgerMenu: PropTypes.func,
  navbarCollapsed: PropTypes.bool
};
export default NavbarTop;
