import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { addDoc, collection, doc, updateDoc } from '@firebase/firestore';
import { useFirestore } from 'reactfire';
import { HappyQuestionairesContext } from 'context/Context';
import { toast } from 'react-toastify';
import { getCurrencyFormat } from 'helpers/utils';
import { UserContext } from 'context/Context';

const HappyQuestionaireProvider = ({ children }) => {
  const { company } = useContext(UserContext);
  const [messageId, setMessageId] = useState();
  const db = useFirestore();

  const createEvent = data => {
    try {
      const { createdAt = new Date(Date.now()).toISOString() } = data;
      addDoc(collection(db, 'events'), {
        ...data,
        createdAt
      });
    } catch (error) {
      console.error('Error creating index:', error);
    }
  };

  const createFeedback = async data => {
    const {
      companyId,
      createdAt = new Date(Date.now()).toISOString(),
      feedback,
      userId
    } = data;
    try {
      await addDoc(collection(db, 'feedback'), {
        companyId,
        createdAt,
        updatedAt: createdAt,
        feedback,
        userId,
        type: 'happy-questionaire',
        new: true
      });

      const eventData = {
        color: 'primary',
        companyId,
        createdAt: new Date(Date.now()).toISOString(),
        text: feedback,
        timeline: true,
        title: 'Nueva sugerencia registrada',
        icon: 'comment-medical',
        userId
      };
      createEvent(eventData);
      return { companyId, createdAt, feedback, userId, new: true };
    } catch (error) {
      console.error('Error creating feedback:', error);
    }
  };

  const createIndex = data => {
    try {
      if (isNaN(data?.value)) {
        return;
      }
      const { createdAt = new Date(Date.now()).toISOString() } = data;
      addDoc(collection(db, 'indexes'), {
        ...data,
        createdAt
      });
    } catch (error) {
      console.error('Error creating event:', error);
    }
  };

  const finishHappyQuestionaire = async ({ definitions, answers, user }) => {
    try {
      const {
        company: companyId,
        id: userId,
        lastQuestionnaireAt: userLastQuestionnaireAt
      } = user;
      const submitionDate = new Date(Date.now());
      const submittedAt = submitionDate.toISOString();
      let score = 0;

      const responses = definitions.map(
        ({ question, source, type, values }, i) => {
          let answer = answers[i];

          if (type === 'number') {
            score += values[answer];
            const value = answer * 10;
            createIndex({
              createdAt: submittedAt,
              companyId,
              type: source,
              userId,
              value
            });
            answer = `${value}%`;
          } else if (type === 'text') {
            answer && createFeedback({ companyId, feedback: answer, userId });
          }
          return { question, answer };
        }
      );

      const happiness =
        (score / definitions.filter(({ type }) => type === 'number').length) *
        10;
      const stress =
        answers[definitions.findIndex(({ source }) => source === 'stress')] *
        10;

      createIndex({
        createdAt: submittedAt,
        companyId,
        type: 'happiness',
        userId,
        value: happiness
      });

      addDoc(collection(db, 'form_responses'), {
        companyId,
        createdAt: submittedAt,
        new: true,
        payload: { origin: 'web' },
        responses,
        userId
      });

      const lastQuestionnaireDate = new Date(userLastQuestionnaireAt);
      const isRewardable =
        company?.apps?.includes('questionaires') &&
        lastQuestionnaireDate.getMonth() !== new Date().getMonth();

      if (isRewardable) {
        const amount = 1;
        const dateDescription = new Intl.DateTimeFormat('es', {
          month: 'long',
          year: 'numeric'
        }).format(submitionDate);
        addDoc(collection(db, 'transactions'), {
          amount,
          createdAt: submittedAt,
          currency: 'EUR',
          description: `Cuestionario de ${dateDescription}`,
          img: 'wallet/happyfy.png',
          icon: 'wallet/plus.png',
          userId,
          new: true
        });
        const amountTxt = getCurrencyFormat(amount);
        const title = amount
          ? `${amountTxt} añadidos a tu monedero`
          : 'Nueva recompensa';
        addDoc(collection(db, 'notifications'), {
          body: `Cuestionario de ${dateDescription}`,
          createdAt: submittedAt,
          target: userId,
          title,
          type: 'user',
          data: {
            screen: 'Wallet'
          }
        });
      }

      // Updates
      updateDoc(doc(db, 'users', userId), {
        happiness,
        stress,
        lastSubmition: submittedAt,
        lastQuestionnaireAt: submittedAt
      });

      messageId &&
        updateDoc(doc(db, 'chat_messages', messageId), {
          'extra.answers': responses,
          'extra.submittedAt': submittedAt
        });

      const eventData = {
        color: 'accent',
        companyId,
        createdAt: submittedAt,
        text: user.name,
        timeline: true,
        title: 'Cuestionario Happyfy finalizado',
        icon: 'laugh',
        userId
      };
      createEvent(eventData);
    } catch (error) {
      console.error(error);
      toast.error();
    }
  };

  return (
    <HappyQuestionairesContext.Provider
      value={{
        finishHappyQuestionaire,
        setMessageId
      }}
    >
      {children}
    </HappyQuestionairesContext.Provider>
  );
};

HappyQuestionaireProvider.propTypes = { children: PropTypes.node };

export default HappyQuestionaireProvider;
