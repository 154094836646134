import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import UserList from 'components/common/UserList';
import Events from 'components/user/profile/Events';
import { UserContext } from 'context/Context';

const EventDetailAside = ({ event = {} }) => {
  const { partners } = useContext(UserContext);
  const { NO_ID_FIELD, address, assistance, location = '', start, end } = event;
  const duration = dayjs(end).diff(start, 'day', true);
  const locationUrl = location.replace(/ /g, '+');
  const mapLink = `https://www.google.com/maps/dir//${locationUrl}`;
  const assistanceTrue = assistance
    ?.filter(({ assist }) => assist === true)
    .map(({ ref }) =>
      partners.find(partner => partner?.ref?.path === ref?.path)
    )
    .filter(partner => partner);
  const assistanceFalse = assistance
    ?.filter(({ assist }) => assist === false)
    .map(({ ref }) =>
      partners.find(partner => partner?.ref?.path === ref?.path)
    )
    .filter(partner => partner);
  const assistanceNull = assistance
    ?.filter(({ assist }) => assist === null)
    .map(({ ref }) =>
      partners.find(partner => partner?.ref?.path === ref?.path)
    )
    .filter(partner => partner);

  return (
    <div className="sticky-sidebar">
      <Card className="mb-3 fs--1">
        <Card.Body>
          <h6>Fecha y hora</h6>
          <p className="mb-1">
            {dayjs(start).format('dddd, DD MMMM YYYY, hh:mm A')}
            {end && (
              <>
                -{duration > 1 && <br />}
                {dayjs(end).format(
                  duration > 1 ? 'dddd, DD MMMM YYYY, hh:mm A' : 'hh:mm A'
                )}
              </>
            )}
          </p>
          <Link to="#!">Añadir al calendario</Link>
          <h6 className="mt-4">Localización</h6>
          <div className="mb-1">{location}</div>
          {address && <div className="mb-1 text-500">{address}</div>}
          <Link to={mapLink} target="_blank">
            Ver mapa
          </Link>
        </Card.Body>
      </Card>
      {!!assistance?.length && (
        <Card className="mb-3 fs--1">
          <Card.Body className="pb-0">
            {!!assistanceTrue?.length && (
              <>
                <h6>Van a ir</h6>
                <UserList
                  key={assistanceTrue
                    .map(({ NO_ID_FIELD }) => NO_ID_FIELD)
                    .join('-')}
                  className="shadow-none"
                  users={assistanceTrue}
                  tableProps={{
                    pagination: true,
                    perPage: 10
                  }}
                />
                <hr className="border-0" />
              </>
            )}
            {!!assistanceFalse?.length && (
              <>
                <h6>No van a ir</h6>
                <UserList
                  key={assistanceFalse
                    .map(({ NO_ID_FIELD }) => NO_ID_FIELD)
                    .join('-')}
                  className="shadow-none"
                  users={assistanceFalse}
                  tableProps={{
                    pagination: true,
                    perPage: 10
                  }}
                />
                <hr className="border-0" />
              </>
            )}
            {!!assistanceNull?.length && (
              <>
                <h6>No lo saben</h6>
                <UserList
                  key={assistanceNull
                    .map(({ NO_ID_FIELD }) => NO_ID_FIELD)
                    .join('-')}
                  className="shadow-none"
                  users={assistanceNull}
                  tableProps={{
                    pagination: true,
                    perPage: 10
                  }}
                />
                <hr className="border-0" />
              </>
            )}
          </Card.Body>
        </Card>
      )}
      <Events
        bodyClassName="pt-2"
        header={
          <Card.Header className="pb-0">
            <h6>Próximos eventos</h6>
          </Card.Header>
        }
        exclude={[NO_ID_FIELD]}
      />
    </div>
  );
};

EventDetailAside.propTypes = {
  event: PropTypes.object
};

export default EventDetailAside;
