import React from 'react';
import ForgetPasswordForm from 'components/authentication/ForgetPasswordForm';

const ForgetPassword = () => {
  return (
    <div className="text-center">
      <h5 className="mb-0">¿Se te ha olvidado la contraseña?</h5>
      <small>
        Escribe tu email y te enviaremos un link para que la resetees.
      </small>
      <ForgetPasswordForm />
    </div>
  );
};

export default ForgetPassword;
