import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  ButtonGroup,
  Card,
  Row,
  Col,
  Spinner,
  Placeholder
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import { getCurrencyFormat } from 'helpers/utils';
import dayjs from 'dayjs';

const Subscription = ({ link }) => {
  const [loading, setLoading] = useState(true);
  const {
    company,
    getSubscription,
    loading: userLoading = true,
    subscription,
    upcomingInvoice: upcomingInvoiceProp
  } = useContext(UserContext);
  const [upcomingInvoice, setUpcomingInvoice] = useState({ loading: true });
  const { quantity: quantityProp = 1 } = subscription || {};
  const [quantity, setQuantity] = useState(quantityProp);

  const handleChangeQuantity = quantityToAdd => {
    setQuantity(quantity => quantity + quantityToAdd);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const { items } = subscription;
    const { upcomingInvoice } = await fetch(
      `${process.env.REACT_APP_FIREBASE_URL}/updateSubscriptionItem`,
      {
        method: 'POST',
        body: JSON.stringify({
          subscriptionItemId: items.data[0].id,
          quantity
        })
      }
    );
    setUpcomingInvoice(upcomingInvoice);
    getSubscription();
    setLoading(false);
  };

  const getUpcomingInvoice = async () => {
    if (!subscription?.id) {
      return;
    }
    setUpcomingInvoice(upcomingInvoice => ({
      ...upcomingInvoice,
      loading: true
    }));
    try {
      const { id: subscriptionId, items } = subscription;
      const { upcomingInvoice: _upcomingInvoice } = await (
        await fetch(
          `${process.env.REACT_APP_FIREBASE_URL}/getUpcomingInvoice?subscriptionId=${subscriptionId}&subscriptionItemId=${items.data[0].id}&quantity=${quantity}`
        )
      ).json();
      setUpcomingInvoice(upcomingInvoice => ({
        ...(upcomingInvoice || {}),
        ...(_upcomingInvoice || {}),
        loading: false
      }));
    } catch (error) {
      console.error(error);
    }
    setUpcomingInvoice(upcomingInvoice => ({
      ...upcomingInvoice,
      loading: false
    }));
  };

  useEffect(() => {
    getUpcomingInvoice();
  }, [quantity]);

  useEffect(() => {
    if (!company?.NO_ID_FIELD) {
      return;
    }
    setLoading(userLoading);
  }, [userLoading]);

  useEffect(() => {
    setQuantity(quantityProp);
  }, [quantityProp]);

  useEffect(() => {
    setUpcomingInvoice(upcomingInvoiceProp);
  }, [upcomingInvoiceProp]);

  const {
    now = {},
    next = {},
    loading: loadingInvoice = false
  } = upcomingInvoice || {};
  const { total: nowTotal = 0 } = now;
  const { period_end: nextPeriodEnd, total: nextTotal = 0 } = next;

  return (
    <Card className="mb-3 fs--1">
      <Card.Body>
        <Row className="flex-between-center">
          <Col className="d-md-flex d-lg-block flex-between-center">
            <h6 className="mb-md-0 mb-lg-2">Empleados incluidos en el plan</h6>
          </Col>
          <Col xs="auto">
            <h4 className="fs-3 fw-normal text-700">x{quantity}</h4>
          </Col>
        </Row>

        {subscription?.id && (
          <>
            <Row className="flex-between-center">
              <Col md={6}>
                <ButtonGroup>
                  <Button
                    variant="outline-secondary"
                    onClick={() => handleChangeQuantity(-1)}
                  >
                    -
                  </Button>
                  <Button disabled={true} variant="outline-secondary">
                    {quantity}
                  </Button>
                  <Button
                    variant="outline-secondary"
                    onClick={() => handleChangeQuantity(1)}
                  >
                    +
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>

            <hr />
            {quantity !== quantityProp && (
              <>
                <Row className="flex-between-center">
                  <Col md={6}>
                    Lo que pagarás por mes a partir del{' '}
                    {dayjs(nextPeriodEnd * 1000).format('DD MMMM YYYY')}
                  </Col>
                  <Col xs="auto">
                    <h5 className="fw-normal">
                      {loadingInvoice ? (
                        <Placeholder animation="glow">
                          <Placeholder as="h5" style={{ width: '5rem' }} />
                        </Placeholder>
                      ) : (
                        getCurrencyFormat(nextTotal / 100)
                      )}
                      <Button
                        variant="falcon-default"
                        as={Link}
                        to={link}
                        target="_blank"
                        className="ms-2 mt-n2 px-2 py-0 fs--2"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="external-link-alt" />
                      </Button>
                    </h5>
                  </Col>
                </Row>
                <hr />
                <Row className="flex-between-center">
                  <Col md={8}>Importe debido: hoy</Col>
                  <Col xs="auto">
                    <h5 className="fw-normal">
                      {loadingInvoice ? (
                        <Placeholder animation="glow">
                          <Placeholder as="h5" style={{ width: '5rem' }} />
                        </Placeholder>
                      ) : (
                        getCurrencyFormat(Math.max(0, nowTotal) / 100)
                      )}
                      <Button
                        variant="falcon-default"
                        as={Link}
                        to={link}
                        target="_blank"
                        className="ms-2 mt-n2 px-2 py-0 fs--2"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="external-link-alt" />
                      </Button>
                    </h5>
                  </Col>
                </Row>
              </>
            )}
            {quantity === quantityProp && (
              <>
                <Row className="flex-between-center">
                  <Col md={8}>Lo que pagarás al mes</Col>
                  <Col xs="auto">
                    <h5 className="fw-normal">
                      {loadingInvoice ? (
                        <Placeholder animation="glow">
                          <Placeholder as="h5" style={{ width: '5rem' }} />
                        </Placeholder>
                      ) : (
                        getCurrencyFormat(Math.max(0, nowTotal) / 100)
                      )}
                      <Button
                        variant="falcon-default"
                        as={Link}
                        to={link}
                        target="_blank"
                        className="ms-2 mt-n2 px-2 py-0 fs--2"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="external-link-alt" />
                      </Button>
                    </h5>
                  </Col>
                </Row>
              </>
            )}

            <div className="d-grid mt-4">
              <Button
                className="text-nowrap flex-grow-1"
                disabled={loading || quantity === quantityProp}
                onClick={handleSubmit}
              >
                <Flex alignItems="center" justifyContent="center">
                  {loading && <Spinner />}
                  Confirmar
                </Flex>
              </Button>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

Subscription.propTypes = {
  link: PropTypes.string
};

export default Subscription;
