import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EventDetailsGmap from './EventDetailsGmap';
import { parseUrls } from 'helpers/utils';

const EventDetailContent = ({ event = {} }) => {
  const { NO_ID_FIELD, title, description, tags, latlng } = event;

  return (
    <Card>
      <Card.Body>
        {title && <h5 className="fs-0 mb-3">{title}</h5>}
        {description && (
          <p
            className="text-pre-wrap"
            dangerouslySetInnerHTML={{ __html: parseUrls(description) }}
          />
        )}
        {tags && (
          <>
            <h5 className="fs-0 mt-5 mb-2 ">Tags </h5>
            {tags.map(({ tag }, index) => (
              <Link
                key={`Tag-${NO_ID_FIELD}-${index}`}
                className="badge border link-secondary text-decoration-none me-1"
              >
                {tag}
              </Link>
            ))}
          </>
        )}

        {latlng && <EventDetailsGmap latlng={latlng} />}
      </Card.Body>
    </Card>
  );
};

EventDetailContent.propTypes = {
  event: PropTypes.object
};

export default EventDetailContent;
