import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button, Card, Row, Col, Form, Placeholder } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SubscriptionInfo from './SubscriptionInfo';
import SubscriptionTotals from './SubscriptionTotals';

const Subscription = () => {
  const [loading, setLoading] = useState(true);
  const [link, setLink] = useState();
  const {
    company,
    loading: userLoading = true,
    subscription
  } = useContext(UserContext);
  const { plan = {}, quantity: quantityProp = 1, status } = subscription || {};
  const { product = {} } = plan;
  const { description, images = [], name } = product;

  const refreshRedirectionLink = async () => {
    try {
      const redirection = await (
        await fetch(
          `${process.env.REACT_APP_FIREBASE_URL}/redirectToBillingPortal`,
          {
            method: 'POST',
            body: JSON.stringify({
              companyId: company?.NO_ID_FIELD
            })
          }
        )
      ).text();

      setLink(redirection);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!company?.NO_ID_FIELD) {
      return;
    }
    refreshRedirectionLink();
  }, [company?.NO_ID_FIELD]);

  useEffect(() => {
    if (!company?.NO_ID_FIELD) {
      return;
    }
    setLoading(userLoading);
  }, [userLoading]);

  if (loading) {
    return (
      <Row className="g-3">
        <Col lg={8}>
          <Card>
            <Card.Body className="overflow-hidden p-lg-6">
              <Row className="align-items-center justify-content-between">
                <Col className="ps-lg-4 my-5 text-center text-lg-left">
                  <Placeholder animation="glow">
                    <Placeholder
                      as={Avatar}
                      size="5xl"
                      rounded="soft"
                      name="H"
                      className="avatar-5xl"
                    />
                    <Placeholder
                      as="h3"
                      className="d-block mx-auto mt-2"
                      xs={4}
                    />
                    <Placeholder as="h1" className="d-block mx-auto" xs={2} />
                    <Placeholder xs={10} className="d-block mx-auto mt-3" />
                    <Placeholder xs={4} className="d-block mx-auto mt-2" />
                    <Placeholder.Button
                      className="d-block mx-auto mt-3 text-primary"
                      variant="falcon-primary"
                    >
                      {classNames({
                        'Activa tu plan': status !== 'active',
                        'Edita tu plan': status === 'active'
                      })}
                    </Placeholder.Button>
                  </Placeholder>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <Card className="mb-3 fs--1">
              <Card.Body>
                <Placeholder animation="glow">
                  <Placeholder as="h6" xs={6} />
                  <Placeholder as="p" xs={10} />
                  <Placeholder as="h6" xs={6} />
                  <Placeholder as="p" xs={10} />
                  <Placeholder as="h6" xs={6} />
                  <Placeholder as="p" xs={10} />
                </Placeholder>
              </Card.Body>
            </Card>
            <Card className="mb-3 fs--1">
              <Card.Body>
                <Placeholder as={Flex} animation="glow">
                  <Placeholder as="h6" xs={4} />
                </Placeholder>
                <Placeholder animation="glow">
                  <Placeholder
                    as="h2"
                    className="position-absolute top-0 end-0 m-3"
                    xs={1}
                  />
                </Placeholder>
                <Placeholder animation="glow">
                  <Flex alignItems="center" className="mt-4">
                    <div>
                      <Placeholder as={Form.Control} xs={3} className="w-0" />
                    </div>
                    <Placeholder.Button
                      className="d-block ms-3 mx-auto text-primary"
                      variant="primary"
                      xs={9}
                    >
                      Confirmar
                    </Placeholder.Button>
                  </Flex>
                </Placeholder>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="g-3">
      <Col lg={8}>
        <Card>
          <Card.Body className="overflow-hidden p-lg-6">
            <Row className="align-items-center justify-content-between">
              <Col className="ps-lg-4 my-5 text-center text-lg-left">
                <Avatar
                  size="5xl"
                  rounded="soft"
                  src={images?.[0]}
                  name="FREE"
                  isExact
                  maxChars={4}
                />
                <h3>{name}</h3>
                <Flex alignItems="baseline" justifyContent="center">
                  <h1>
                    x{quantityProp}
                    <FontAwesomeIcon icon="user" className="ms-1 mb-1 fs-2" />
                  </h1>
                </Flex>
                <p className="lead">{description}</p>
                <Button
                  variant="falcon-default"
                  as={Link}
                  to={link}
                  target="_blank"
                  className="me-2"
                >
                  {classNames({
                    'Activa tu plan': status !== 'active',
                    'Edita tu plan': status === 'active'
                  })}
                </Button>
                <Button
                  variant="falcon-primary"
                  as={Link}
                  to="/subscription/plans"
                >
                  Ver planes
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={4}>
        <div className="sticky-sidebar">
          <SubscriptionInfo className="mb-3 fs--1" />
          <SubscriptionTotals link={link} />
        </div>
      </Col>
    </Row>
  );
};

export default Subscription;
