/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { diff } from 'deep-object-diff';
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter
} from 'react-table';

export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, className, ...rest }, ref) => {
    const defaultRef = React.useRef();

    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Form.Check
        type="checkbox"
        className={classNames('form-check fs-0 mb-0', className)}
      >
        <Form.Check.Input type="checkbox" ref={resolvedRef} {...rest} />
      </Form.Check>
    );
  }
);

const AdvanceTableWrapper = React.memo(
  ({
    children,
    columns,
    data,
    sortable,
    selection,
    selectionColumnWidth,
    pagination,
    perPage = 10,
    innerRef
  }) => {
    const table = useTable(
      {
        columns,
        data,
        disableSortBy: !sortable,
        initialState: { pageSize: pagination ? perPage : data.length }
      },
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect,
      hooks => {
        if (selection) {
          hooks.visibleColumns.push(columns => [
            {
              id: 'selection',
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              ),
              headerProps: {
                className: 'p-2 ps-3',
                style: {
                  width: selectionColumnWidth
                }
              },
              cellProps: {
                className: 'p-0',
                style: {
                  width: selectionColumnWidth
                }
              },
              Cell: ({ row }) => (
                <div className="position-relative p-2 ps-3 z-index-2">
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              )
            },
            ...columns
          ]);
        }
      }
    );
    const {
      getTableProps,
      headers,
      page,
      prepareRow,
      canPreviousPage,
      canNextPage,
      nextPage,
      previousPage,
      setPageSize,
      gotoPage,
      pageCount,
      state: { pageIndex, pageSize, selectedRowIds, globalFilter },
      setGlobalFilter
    } = table;

    innerRef && (innerRef.current = table);

    const recursiveMap = children => {
      return React.Children.map(children, child => {
        if (child?.props?.children) {
          return React.cloneElement(child, {
            children: recursiveMap(child.props.children)
          });
        } else {
          if (child?.props?.table) {
            return React.cloneElement(child, {
              ...child.props,
              getTableProps,
              headers,
              page,
              prepareRow,
              canPreviousPage,
              canNextPage,
              nextPage,
              previousPage,
              gotoPage,
              pageCount,
              pageIndex,
              selectedRowIds,
              pageSize,
              setPageSize,
              globalFilter,
              setGlobalFilter
            });
          } else {
            return child;
          }
        }
      });
    };

    return (
      // <>
      //   {React.Children.map(children, child => {
      //     if (child.props.table) {
      //       return React.cloneElement(child, {
      //         ...child.props,
      //         getTableProps,
      //         headers,
      //         page,
      //         prepareRow,
      //         canPreviousPage,
      //         canNextPage,
      //         nextPage,
      //         previousPage,
      //         gotoPage,
      //         pageCount,
      //         pageIndex,
      //         selectedRowIds,
      //         pageSize,
      //         setPageSize
      //       });
      //     } else {
      //       return child;
      //     }
      //   })}
      // </>
      <>{recursiveMap(children)}</>
    );
  },
  (prev, next) => {
    const dataDiffs = diff(prev.data || [], next.data || []);
    const render = Object.values(dataDiffs).some(
      obj => Object.keys(obj || {}).length > 1 || !obj?.status
    );
    return !render;
  }
);

export default AdvanceTableWrapper;
