import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';
import { collection, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { RankingContext, UserContext } from 'context/Context';
import RankingModal from './RankingModal';

const RankingsProvider = ({ children }) => {
  const [thisMonth] = useState(new Date(new Date().setDate(1)));
  const { company, partners = [] } = useContext(UserContext);
  const [status, setStatus] = useState({});
  const [modalData, setModalData] = useState({});
  const [isOpenObjectivesModal, setIsOpenObjectivesModal] = useState(false);

  const db = useFirestore();
  let eventsQuery = query(collection(db, 'none'));
  let objectivesQuery = query(collection(db, 'none'));
  let appreciationsQuery = query(collection(db, 'none'));
  if (company?.NO_ID_FIELD) {
    const commonFilters = [where('companyId', '==', company?.NO_ID_FIELD)];
    const eventFilters = [
      ...commonFilters,
      where('type', '==', 'event'),
      where('createdAt', '>=', thisMonth.toISOString())
    ];
    const objectiveFilters = [
      ...commonFilters,
      where('type', '==', 'objective'),
      where('state', '==', 'success'),
      where('createdAt', '>=', thisMonth.toISOString())
    ];
    const appreciationsFilters = [
      ...commonFilters,
      where('createdAt', '>=', thisMonth.toISOString())
    ];
    eventsQuery = query(collection(db, 'calendar'), ...eventFilters);
    objectivesQuery = query(collection(db, 'calendar'), ...objectiveFilters);
    appreciationsQuery = query(
      collection(db, 'appreciations'),
      ...appreciationsFilters
    );
  }
  const { data: eventsRaw = [] } = useFirestoreCollectionData(eventsQuery);
  const { data: objectivesRaw = [] } =
    useFirestoreCollectionData(objectivesQuery);
  const { data: appreciationsRaw = [] } =
    useFirestoreCollectionData(appreciationsQuery);

  useEffect(() => {
    (async () => {
      const events = (
        await Promise.all(
          eventsRaw?.map(async event => {
            const { assistance = [] } = event;
            return assistance
              .filter(({ assist }) => assist === true)
              .map(({ ref }) => ({
                userPath: ref?.path,
                ...event
              }));
          }) || []
        )
      ).reduce((all, assistance) => [...all, ...assistance], []);
      !equal(status?.events, events) &&
        setStatus(status => ({ ...status, events }));
    })();
  }, [eventsRaw]);

  useEffect(() => {
    (async () => {
      const objectives = (
        await Promise.all(
          objectivesRaw?.map(async objective => {
            const { participants = [] } = objective;
            const _participants = participants?.length
              ? participants
              : partners.map(({ ref }) => ref);
            return _participants.map(user => ({
              userPath: user?.path,
              ...objective
            }));
          }) || []
        )
      ).reduce((all, participants) => [...all, ...participants], []);
      !equal(status?.objectives, objectives) &&
        setStatus(status => ({ ...status, objectives }));
    })();
  }, [objectivesRaw]);

  useEffect(() => {
    (async () => {
      const appreciations = await Promise.all(
        appreciationsRaw?.map(async appreciation => {
          return appreciation;
        }) || []
      );
      !equal(status?.appreciations, appreciations) &&
        setStatus(status => ({ ...status, appreciations }));
    })();
  }, [appreciationsRaw]);

  // console.log('RANKINGS >>>', eventsRaw, status);

  return (
    <RankingContext.Provider
      value={{
        ...status,
        setModalData,
        setIsOpenObjectivesModal
      }}
    >
      <RankingModal
        {...modalData}
        isOpen={isOpenObjectivesModal}
        setIsOpen={setIsOpenObjectivesModal}
      />
      {children}
    </RankingContext.Provider>
  );
};

RankingsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default RankingsProvider;
