import React from 'react';
import { Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const PricingTable = () => {
  return (
    <>
      <Helmet>
        <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      </Helmet>
      <Card>
        <Card.Body className="min-vh-100">
          <stripe-pricing-table
            pricing-table-id="prctbl_1MRxRSIZw3S88a4240AktE02"
            publishable-key="pk_live_51Lec66IZw3S88a42z12ESCRXy9V4eIlpF64QDSA7hEdZ3mcyhzjoleggeEjqbS9RDSrXMp2DBfGCxhojzu3bwlFm004B3hSlqB"
          ></stripe-pricing-table>
        </Card.Body>
      </Card>
    </>
  );
};

export default PricingTable;
