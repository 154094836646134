import { createContext } from 'react';
import { settings } from 'config';

const AppContext = createContext(settings);

export const AppsContext = createContext({});
export const AIContext = createContext({});
export const AppreciationContext = createContext({});
export const AssistantContext = createContext({
  KanbanColumns: [],
  kanbanTasks: []
});
export const BenefitsContext = createContext({});
export const CalendarContext = createContext({});
export const ChatContext = createContext({});
export const CourseContext = createContext({ courses: [], primaryCourses: [] });
export const EmployeesContext = createContext({ employees: [] });
export const FeedbackContext = createContext({});
export const FeedContext = createContext({ feeds: [] });
export const FilesContext = createContext({ files: [] });
export const IndexesContext = createContext({ indexes: [] });
export const HappyQuestionairesContext = createContext({});
export const KanbanContext = createContext({
  KanbanColumns: [],
  kanbanTasks: []
});
export const OnBoardingContext = createContext({});
export const ProductContext = createContext({ products: [] });
export const QuestionairesContext = createContext({ questionaires: [] });
export const RankingContext = createContext({});
export const ReportsContext = createContext({});
export const ReviewsContext = createContext({ reviews: [] });
export const TimeBalanceContext = createContext({});
export const TimeControlContext = createContext({});
export const UserContext = createContext({ me: {} });
export const UserReviewsContext = createContext({ reviews: [] });

export default AppContext;
