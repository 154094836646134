import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import Flex from 'components/common/Flex';
import { Alert, Card } from 'react-bootstrap';
import { CalendarContext, UserContext } from 'context/Context';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Avatar from 'components/common/Avatar';

const columns = [
  {
    accessor: 'state',
    cellProps: {
      className: 'white-space-nowrap',
      style: { maxWidth: '23rem' }
    },
    Cell: rowData => {
      const { state = 'on-course' } = rowData.row.original;
      const icon = {
        'on-course': 'clock',
        waiting: 'clock',
        success: 'check',
        fail: 'times'
      };

      return (
        <Flex alignItems="center" className="gap-3 position-relative">
          <Avatar
            mediaClass={classNames(
              'align-items-center justify-content-center fs-0',
              {
                'bg-secondary': state === 'on-course',
                'bg-yellow': state === 'waiting',
                'bg-success': state === 'success',
                'bg-danger': state === 'fail'
              }
            )}
            icon={icon[state]}
          />
        </Flex>
      );
    }
  },
  {
    accessor: 'title',
    Header: 'Recompensa',
    headerProps: {
      className: 'fw-medium'
    },
    Cell: rowData => {
      const { NO_ID_FIELD, start, title } = rowData.row.original;
      const date = dayjs(start).format('D MMMM YYYY');
      return (
        <>
          <p className="text-500 m-0">{date}</p>
          <Link to={`/company/objectives/${NO_ID_FIELD}`}>
            <h6>{title}</h6>
          </Link>
        </>
      );
    }
  },
  {
    accessor: 'reward',
    Header: 'Recompensa',
    headerProps: {
      className: 'fw-medium text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { reward, state } = rowData.row.original;
      return (
        <h6
          className={classNames('fw-bold', {
            'text-secondary': state === 'on-course',
            'text-yellow': state === 'waiting',
            'text-success': state === 'success',
            'text-danger': state === 'fail'
          })}
        >
          {reward}
        </h6>
      );
    }
  }
];

const Rewards = ({ bodyClassName }) => {
  const { me } = useContext(UserContext);
  const { objectives = [] } = useContext(CalendarContext);
  const rewards = objectives
    .filter(
      ({ participants }) =>
        !participants || participants.some(ref => ref?.path === me?.ref?.path)
    )
    .sort(({ createdAt: d1 }, { createdAt: d2 }) => (d1 > d2 ? -1 : 1));

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={rewards}
        pagination
        perPage={20}
      >
        <Card.Body
          className={classNames(bodyClassName, {
            'p-0': rewards.length
          })}
        >
          {!rewards.length ? (
            <Alert variant="info">
              <h5 className="alert-heading">No hay recompensas</h5>
            </Alert>
          ) : (
            <AdvanceTable
              table
              headerClassName="d-none"
              rowClassName="align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          )}
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={rewards.length}
            table
            rowInfo
            navButtons
          />
        </Card.Footer>
      </AdvanceTableWrapper>
    </>
  );
};

Rewards.propTypes = {
  bodyClassName: PropTypes.string
};

export default Rewards;
