import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { isIterableArray } from 'helpers/utils';
import Flex from './Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';

const fileIcons = {
  'application/pdf': 'file-pdf',
  'image/pdf': 'file-image',
  'image/jpeg': 'file-image'
};

const getSize = size => {
  if (size < 1024) {
    return (
      <>
        <strong>{size}</strong> Byte
      </>
    );
  } else if (size < 1024 * 1024) {
    return (
      <>
        <strong>{(size / 1024).toFixed(2)}</strong> KB
      </>
    );
  } else {
    return (
      <>
        <strong>{(size / (1024 * 1024)).toFixed(2)}</strong> MB
      </>
    );
  }
};

const FalconDropzone = ({
  placeholder,
  className,
  edit,
  hide,
  onChange,
  files,
  preview,
  ...rest
}) => (
  <>
    <Dropzone
      onDrop={acceptedFiles => {
        const stringFiles = [];
        if (acceptedFiles.length) {
          acceptedFiles.map(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              stringFiles.push({
                // id: uuid(),
                base64: reader.result,
                size: file.size,
                path: file.path,
                type: file.type
              });
              onChange([...stringFiles], [...acceptedFiles]);
            };
            return true;
          });
        }
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps({
            className: classNames('dropzone-area', className, {
              'px-2 pt-0 pb-2': hide
            })
          })}
        >
          <input {...{ ...getInputProps(), ...rest }} />
          {!hide && placeholder}
          {!!preview && isIterableArray(files) && (
            <div
              className={classNames({ 'border-top mt-3': !hide })}
              onClick={event => event.stopPropagation()}
            >
              {preview === true
                ? files.map(({ id, path, base64, mimeType, size }) => (
                    <Flex
                      alignItems="center"
                      className="cursor-default py-3 border-bottom btn-reveal-trigger"
                      key={id}
                    >
                      {base64 && (
                        <img
                          className="img-fluid"
                          width={38}
                          src={base64}
                          alt={path}
                        />
                      )}
                      <FontAwesomeIcon
                        icon={fileIcons[mimeType] || 'file'}
                        size="2x"
                      />
                      <Flex
                        justifyContent="between"
                        alignItems="center"
                        className="ms-3 w-100"
                      >
                        <div>
                          <h6 className="mb-0" data-dz-name="">
                            {path}
                          </h6>
                          <Flex className="position-relative" align="center">
                            <p className="mb-0 fs--1 text-400 line-height-1">
                              {getSize(size)}
                            </p>
                          </Flex>
                        </div>
                        {edit && (
                          <Dropdown className="font-sans-serif">
                            <Dropdown.Toggle
                              variant="link"
                              size="sm"
                              className="btn-falcon-default"
                            >
                              <FontAwesomeIcon icon="ellipsis-h" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="border py-0">
                              <div className="bg-white py-2">
                                <Dropdown.Item
                                  className="text-danger"
                                  onClick={() =>
                                    onChange(
                                      files.filter(file => file.id !== id)
                                    )
                                  }
                                >
                                  Remove File
                                </Dropdown.Item>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </Flex>
                    </Flex>
                  ))
                : preview}
            </div>
          )}
        </div>
      )}
    </Dropzone>
  </>
);

FalconDropzone.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  edit: PropTypes.bool,
  files: PropTypes.array,
  hide: PropTypes.bool,
  preview: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  isMulti: PropTypes.bool
};

FalconDropzone.defaultProps = {
  placeholder: <img src={cloudUpload} alt="" width={25} className="me-2" />,
  files: [],
  preview: false
};

export default FalconDropzone;
