import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import EmployeeDepartmentsSelector from 'components/common/EmployeeDepartmentsSelector';
import { Col, Row } from 'react-bootstrap';
import { TimeControlContext, UserContext } from 'context/Context';

registerLocale('es', es);
setDefaultLocale('es');

const styles = {
  control: base => ({
    ...base,
    borderRadius: '1.375rem',
    fontFamily:
      'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol'
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  })
};

export const Filters = () => {
  const { filters, setFilters } = useContext(TimeControlContext);
  const { me, partners } = useContext(UserContext);
  const { isDepartment, period: periodFilter, usersId } = filters || {};
  const navigate = useNavigate();
  const [period, setPeriod] = useState(periodFilter);
  const [today] = useState(new Date());
  const { start, end } = period || {};
  const select = useRef();
  const selectedPartner = partners?.find(
    ({ NO_ID_FIELD }) => NO_ID_FIELD === usersId?.[0]
  );
  const selectedValues = !usersId?.length
    ? null
    : isDepartment
    ? {
        isDepartment: true,
        label: selectedPartner?.department,
        value: selectedPartner?.department
      }
    : { label: selectedPartner?.name, value: selectedPartner?.NO_ID_FIELD };

  const handleDateChange = dates => {
    const [start, end] = dates;
    setPeriod({ start, end });

    if (!start || !end) {
      return;
    }

    const period = { start, end };
    setFilters(filters => ({ ...filters, period }));
  };

  const handleTargetChange = selected => {
    if (!selected) {
      setFilters(filters => ({ ...filters, usersId: [] }));
      return;
    }
    const { isDepartment, value } = selected || {};
    navigate(`/user/time-control/custom${isDepartment ? '' : `/${value}`}`);

    const departmentUsersId = partners
      ?.filter(({ department }) => department === value)
      .map(({ NO_ID_FIELD }) => NO_ID_FIELD);

    const usersId = [...(isDepartment ? departmentUsersId : [value])];
    setFilters(filters => ({ ...filters, isDepartment, usersId }));
  };

  useEffect(() => {
    setPeriod(periodFilter);
  }, [periodFilter]);

  return (
    <Row className="g-3">
      <Col xs={12} sm="auto">
        <DatePicker
          selected={start}
          onChange={handleDateChange}
          startDate={start}
          endDate={end}
          maxDate={today}
          selectsRange
          dateFormat="d MMM yy"
          className="form-control form-control-sm me-2"
          popperClassName="z-index-1016"
        />
      </Col>
      {me?.type === 'admin' && (
        <Col xs={12} sm="auto">
          <EmployeeDepartmentsSelector
            className="min-w-300px"
            externalRef={select}
            placeholder="Filtra por departamento o empleado"
            onChange={handleTargetChange}
            styles={styles}
            value={selectedValues}
            size="sm"
          />
        </Col>
      )}
    </Row>
  );
};

Filters.propTypes = {
  user: PropTypes.object
};

export default Filters;
