import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import { getColor } from 'helpers/utils';
import types from 'components/dashboards/types.json';
import { ReportsContext } from 'context/Context';

const getOptions = ({ color, labels = ['', ''], data = [40, 60] }) => ({
  options: {
    rotation: -90,
    circumference: '180',
    cutout: '80%',
    hover: { mode: null },
    plugins: {
      legend: {
        display: false
      },
      tooltip: chartJsDefaultTooltip()
    }
  },
  data: {
    labels,
    datasets: [
      {
        data,
        backgroundColor: [color, getColor('gray-300')],
        borderColor: [color, getColor('gray-300')]
      }
    ]
  }
});

const HalfDoughnutChart = ({ bodyClassName, type }) => {
  const title = types[type];
  const { indexes = [], partners = [] } = useContext(ReportsContext);
  const { department: departmentProp } = useParams();
  const rawFiltered = indexes.filter(({ type: t }) => type === t);
  const departmentRawFiltered = rawFiltered
    .map(data => {
      const { userId } = data;
      const { department } =
        partners.find(({ id } = {}) => id === userId) || {};
      if (!department) {
        return data;
      }
      return { ...data, department };
    })
    .filter(
      ({ department }) => !departmentProp || department === departmentProp
    );

  const departmentAverage = Math.round(
    departmentRawFiltered?.reduce((total, { value = 0 }) => total + value, 0) /
      (departmentRawFiltered?.length || 1) || 0
  );
  const companyAverage = Math.round(
    rawFiltered?.reduce((total, { value = 0 }) => total + value, 0) /
      (rawFiltered?.length || 1) || 0
  );
  const average = departmentProp ? departmentAverage : companyAverage;

  let color =
    (average > 75 && getColor('success')) ||
    (average > 60 && getColor('warning')) ||
    getColor('danger');

  const diff = average - companyAverage;
  let diffColor = diff === 0 ? 'info' : diff > 0 ? 'success' : 'danger';
  const departmentAverageText = average === 0 ? '-' : `${Math.abs(diff)}%`;

  if (type === 'stress') {
    color =
      (average > 80 && getColor('danger')) ||
      (average > 60 && getColor('warning')) ||
      getColor('success');
    diffColor = diff === 0 ? 'info' : diff > 0 ? 'danger' : 'success';
  }
  const data = departmentProp
    ? [average, 100 - average]
    : [companyAverage, 100 - companyAverage];

  return (
    <>
      <Card.Header className="pb-0">
        <h6 className="mb-0 text-capitalize text-nowrap text-truncate">
          {title}
        </h6>
      </Card.Header>
      <Card.Body
        className={classNames('p-0 m-auto', bodyClassName)}
        as={Flex}
        direction="column"
        alignItems="center"
        style={{ maxWidth: '12rem' }}
      >
        <div className="mt-n6 mb-n3">
          <Doughnut {...getOptions({ color, data })} width="300" height="300" />
        </div>
        <h1 className="text-center mt-n8 mb-6">
          {average}%
          {departmentProp && (
            <h1 className="fw-normal text-600 fs--1">
              Empresa: {companyAverage}%
              <SoftBadge className="ms-2" pill bg={diffColor}>
                {diff !== 0 && (
                  <FontAwesomeIcon
                    icon={`caret-${diff > 0 ? 'up' : 'down'}`}
                    className="fs--1 me-1"
                  />
                )}
                {departmentAverageText}
              </SoftBadge>
            </h1>
          )}
        </h1>
      </Card.Body>
    </>
  );
};

HalfDoughnutChart.propTypes = {
  bodyClassName: PropTypes.string,
  data: PropTypes.array,
  type: PropTypes.string
};

export default HalfDoughnutChart;
