import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Appreciations from './Appreciations';
import Birthdays from './Birthdays';
import CreatePost from './CreatePost';
import Events from './Events';
import Objectives from './Objectives';
import FeedProvider from './FeedProvider';
import FeedContent from './FeedContent';
import AppContext from 'context/Context';

const Feeds = () => {
  return (
    <Row className="g-0 g-lg-3">
      <Col xs={12} lg={8} className="mt-3 order-1">
        <FeedContent />
      </Col>
      <Col xs={12} lg={4} className="order-0 order-lg-2">
        <div className="sticky-sidebar">
          <CreatePost className="d-lg-none mb-3" />
          <Birthdays />
          <Appreciations />
          <Events cardTitle="Próximos eventos" className="mb-3" />
          <Objectives cardTitle="Objetivos en curso" />
        </div>
      </Col>
    </Row>
  );
};

const FeedWithProvider = props => {
  const { setConfig } = useContext(AppContext);

  useEffect(() => {
    setConfig('isFluid', false);
  }, []);

  return (
    <FeedProvider>
      <Feeds {...props} />
    </FeedProvider>
  );
};

export default FeedWithProvider;
