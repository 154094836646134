import React, { useContext } from 'react';
import classNames from 'classnames';
import { GaugeChart } from 'echarts/charts';
import Avatar from 'components/common/Avatar';
import Lottie from 'lottie-react';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import AIButton from 'components/common/AIButton';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { CanvasRenderer } from 'echarts/renderers';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { getUnique, groupBy } from 'helpers/utils';
import { AIContext, IndexesContext, UserContext } from 'context/Context';
import SimpleBarReact from 'simplebar-react';
import celebration from 'assets/img/animated-icons/celebration.json';
import types from 'components/dashboards/types.json';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  GaugeChart,
  CanvasRenderer,
  LegendComponent
]);

const alerts = {
  happiness: '< 70',
  environment: '< 70',
  fellowship: '< 70',
  motivation: '< 70',
  satisfaction: '< 70',
  stress: '> 70'
};

const Notification = props => {
  const { ai } = useContext(AIContext);
  const { indexes, isLast, showType = true, user } = props;
  const { id, name, avatar, birthday, description, department } = user;

  const handleAI = () => {
    const alertsTxt = indexes
      .map(({ type, value }) => `${types[type]} (${value}%)`)
      .join(', ');
    const alertsTypes = indexes.map(({ type }) => `${types[type]}`).join(', ');
    const actions = [
      'Dame más soluciones',
      {
        prompt:
          'Diséñame un plan de acción por semanas para mejorar esta métrica en 1 mes',
        text: 'Diséñame un plan de acción'
      }
    ];
    const context = [
      {
        role: 'system',
        content:
          'Eres un experto en recursos humanos de una empresa donde se cuidan mucho a sus empleados.'
      },
      birthday && {
        role: 'system',
        content: `Ten en cuenta la fecha de nacimento del empleado: ${dayjs(
          birthday
        ).format('D MMMM YYYY')}`
      },
      description && {
        role: 'system',
        content: `Ten en cuenta esta descripción y/o hobbies del empleado: ${description}`
      },
      {
        role: 'system',
        content: `A continuación te voy a plantear un caso real del que tienes que hacer una lista en formato tabla de 2 posibles soluciones. Las soluciones deben ser específicas para mejorar su estado en las métricas de ${alertsTypes} (Alguna podrían ser con coste económico). Además dime 1 caja de Smartbox que mejor se adapte. Enúnciame primero en negrita lo que vas a hacer. Sé conciso y evita ser genérico.`
      }
    ].filter(message => message);
    const prompt = `¿Cómo podría mejorar la métrica de ${alertsTxt} de ${name} a fecha de ${dayjs().format(
      'MMMM [de] YYYY'
    )}?`;
    const filename = `happyfy-ia-${name
      .toLowerCase()
      .replace(/ /g, '-')}--alert-${indexes
      .map(({ type }) => type)
      .join('-')}.pdf`;
    ai({ actions, context, prompt, filename });
  };

  return (
    <li>
      <Flex alignItems="center" className="position-relative">
        <div className="flex-grow-1">
          <Flex alignItems="start" className="position-relative">
            <Avatar
              size="2xl"
              className="mt-1"
              src={avatar}
              width="60"
              name={name}
              alt={name}
            />
            <div className="flex-1 ms-3 w-0">
              <h6 className="mb-0 fw-semi-bold">
                <Link className="text-dark" to={`/user/profile/${id}`}>
                  {name}
                </Link>
              </h6>
              <p className="fs--2 mb-1 text-600">{department}</p>
              <Flex alignItems="center" wrap="wrap" className="fs--1">
                {showType &&
                  indexes.map(({ type, value }) => (
                    <SoftBadge
                      key={`Alert-Index-${type}`}
                      pill
                      bg="primary"
                      className="me-1 mb-1 text-capitalize"
                    >
                      {types[type]}: <strong className="fs--2">{value}</strong>
                    </SoftBadge>
                  ))}
                <AIButton
                  className="me-1 mb-1"
                  onClick={handleAI}
                  tooltip="¿Cómo podría solucionar esta alerta?"
                />
              </Flex>
            </div>
          </Flex>
        </div>
        {!!indexes.length > 1 && (
          <div className="py-2 pe-3 align-middle text-end fs--1">
            <h5 className="mb-0 text-danger">{indexes[0]?.value}</h5>
          </div>
        )}
      </Flex>
      {!isLast && <div className="border-dashed border-bottom my-3" />}
    </li>
  );
};

Notification.propTypes = {
  indexes: PropTypes.array,
  user: PropTypes.object,
  isLast: PropTypes.bool,
  showType: PropTypes.bool
};

const Alerts = ({
  bodyClassName,
  bodyWrapperClassName,
  className,
  data: raw,
  header,
  showType,
  type,
  user
}) => {
  const { partners = [] } = useContext(UserContext);
  const { indexes: indexesContext = [] } = useContext(IndexesContext);
  let indexes = [...(raw || indexesContext)];
  indexes = indexes
    .filter(index => !type || index.type === type)
    .filter(({ userId }) => !user?.NO_ID_FIELD || userId === user.NO_ID_FIELD);

  if (user?.NO_ID_FIELD) {
    indexes = Object.values(groupBy(indexes, 'type')).map(
      indexes =>
        indexes.sort((i1, i2) => (i1.createdAt > i2.createdAt ? -1 : 1))[0]
    );
  }

  const data = user?.NO_ID_FIELD
    ? indexes
        // eslint-disable-next-line no-unused-vars
        .filter(({ type, value }) => eval(`value ${alerts[type]}`))
        .map(index => ({ ...user, ...index }))
    : Object.entries(groupBy(indexes, 'userId'))
        .map(([userId, indexes]) => {
          indexes = indexes.sort((i1, i2) =>
            i1.createdAt > i2.createdAt ? -1 : 1
          );
          indexes = getUnique(indexes, 'type');
          // eslint-disable-next-line no-unused-vars
          indexes = indexes.filter(({ type, value }) =>
            eval(`value ${alerts[type]}`)
          );
          const user = partners.find(({ id }) => id === userId);
          if (!user || !indexes.length) {
            return;
          }
          return { user, indexes };
        })
        .filter(data => data)
        .sort(({ value: v1 }, { value: v2 }) => (v1 > v2 ? -1 : 1))
        .reduce(
          (all, { indexes, user }) => [
            ...all,
            ...indexes.map(index => ({ ...user, ...index }))
          ],
          []
        );

  const indexesByUser = Object.entries(groupBy(data, 'userId'));
  return (
    <Card className={classNames('h-100', className)}>
      {data.length ? (
        <SimpleBarReact className={bodyWrapperClassName}>
          {header || (
            <Card.Header className="position-sticky top-0 bg-white dark__bg-1100 z-index-1">
              <h6 className="m-0">Alertas</h6>
            </Card.Header>
          )}
          <Card.Body className={classNames(bodyClassName)}>
            <ul className="mb-0 list-unstyled">
              {indexesByUser.map(([userId, indexes], index) => {
                const user = partners.find(({ id }) => id === userId);
                if (!user || !indexes.length) {
                  return;
                }
                return (
                  <Notification
                    key={`Alert-Notification-${userId}`}
                    user={user}
                    indexes={indexes}
                    isLast={indexesByUser.length - 1 === index}
                    showType={showType}
                  />
                );
              })}
            </ul>
          </Card.Body>
        </SimpleBarReact>
      ) : (
        <>
          {header || (
            <Card.Header>
              <h6 className="m-0">Alertas</h6>
            </Card.Header>
          )}
          <Card.Body
            className="p-0 h-0 flex-grow-1"
            style={{ height: '10.5rem' }}
          >
            <div className="px-3 py-2 flex-grow-1">
              <p className="fs--1 ms-1 mb-0 text-500">No hay alertas</p>
            </div>
            <Lottie
              animationData={celebration}
              className="position-absolute bottom-0 w-100"
              loop={false}
              initialSegment={[0, 50]}
            />
          </Card.Body>
        </>
      )}
    </Card>
  );
};

Alerts.propTypes = {
  bodyClassName: PropTypes.string,
  bodyWrapperClassName: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.array,
  header: PropTypes.node,
  showType: PropTypes.bool,
  type: PropTypes.string,
  user: PropTypes.object
};

export default Alerts;
