import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import FalconDropzone from 'components/common/FalconDropzone';
import defaultGroup from 'assets/img/chat/default_group.png';
import { fromBlob, urlToBlob } from 'image-resize-compress';

const GroupCreatorInfo = ({ avatar, setAvatar, name, setName }) => {
  const handleSelectAvatar = async (none, [file]) => {
    urlToBlob(file);
    const compressedFile = await fromBlob(file, 80, 350, 350, 'jepg');
    setAvatar(compressedFile);
  };

  return (
    <div>
      <Flex className="p-3" alignItems="center">
        <FalconDropzone
          className="m-auto p-0 overflow-hidden avatar-5xl rounded-circle"
          files={[avatar]}
          onChange={handleSelectAvatar}
          multiple={false}
          accept="image/*"
          placeholder={
            <div className="position-relative">
              <Avatar
                size="5xl"
                name={name?.trim() || ''}
                src={
                  ((avatar?.base64 || avatar?.src || avatar) &&
                    URL.createObjectURL(avatar)) ||
                  defaultGroup
                }
              />
              {!avatar && (
                <div className="position-absolute bottom-0 w-100 bg-black bg-opacity-50 pb-3">
                  <span className="fs--1 text-white">Editar</span>
                </div>
              )}
            </div>
          }
        />
      </Flex>
      <Flex className="p-3" alignItems="center">
        <Form.Control
          className="bg-200 border-0 shadow-none"
          placeholder="Asunto del grupo"
          onChange={({ target }) => setName(target.value)}
        />
      </Flex>
    </div>
  );
};

GroupCreatorInfo.propTypes = {
  avatar: PropTypes.object,
  setAvatar: PropTypes.func,
  name: PropTypes.string,
  setName: PropTypes.func
};

export default GroupCreatorInfo;
