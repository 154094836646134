import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  Modal,
  Row,
  ToggleButton,
  ToggleButtonGroup
} from 'react-bootstrap';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import UserList from 'components/common/UserList';
import AppContext, { UserContext } from 'context/Context';

const weekDaysName = [
  { value: 1, name: 'lunes' },
  { value: 2, name: 'martes' },
  { value: 3, name: 'miércoles' },
  { value: 4, name: 'jueves' },
  { value: 5, name: 'viernes' },
  { value: 6, name: 'sábado' },
  { value: 0, name: 'domingo' }
];

const WeekDays = ({ days, path, setValue }) => {
  return (
    <ToggleButtonGroup
      className="w-100"
      type="checkbox"
      value={days || []}
      onChange={days => setValue?.(`${path}.days`, days)}
    >
      {weekDaysName.map(({ name, value }) => (
        <ToggleButton
          className="w-16px h-16px border-white rounded-pill text-capitalize m-0 p-0"
          key={`${path}-WeekDay-${value}`}
          id={`${path}-WeekDay-${value}`}
          disabled={!setValue}
          value={value}
          variant="outline-secondary"
        >
          {name.substr(0, 1)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

WeekDays.propTypes = {
  days: PropTypes.array,
  path: PropTypes.string,
  setValue: PropTypes.func
};

const TimeInterval = ({ interval, index, path, ...form }) => {
  let { days, start, end } = interval || {};
  const [, startHours] = start?.match(/(\d{2}):/) || [];
  const [, startMinutes] = start?.match(/:(\d{2})/) || [];
  const [, endHours] = end?.match(/(\d{2}):/) || [];
  const [, endMinutes] = end?.match(/:(\d{2})/) || [];
  const { getValues, setValue } = form;
  const startDate = new Date();
  startHours && startDate.setHours(parseInt(startHours));
  startMinutes && startDate.setMinutes(parseInt(startMinutes));
  const endDate = new Date();
  endHours && endDate.setHours(parseInt(endHours));
  endMinutes && endDate.setMinutes(parseInt(endMinutes));

  const handleChange = ({ start, end }) => {
    const startHours =
      start?.getHours?.() || parseInt(start?.match?.(/(\d{2}):/)?.[1] || 0);
    const startMinutes =
      start?.getMinutes?.() || parseInt(start?.match?.(/:(\d{2})/)?.[1] || 0);
    const startTime = `${startHours < 10 ? '0' : ''}${startHours}:${
      startMinutes < 10 ? '0' : ''
    }${startMinutes}`;
    const endHours =
      end?.getHours?.() || parseInt(end?.match?.(/(\d{2}):/)?.[1] || 0);
    const endMinutes =
      end?.getMinutes?.() || parseInt(end?.match?.(/:(\d{2})/)?.[1] || 0);
    const endTime = `${endHours < 10 ? '0' : ''}${endHours}:${
      endMinutes < 10 ? '0' : ''
    }${endMinutes}`;
    start && setValue?.(`${path}.${index}.start`, startTime);
    end && setValue?.(`${path}.${index}.end`, endTime);
  };

  const handleDelete = () => {
    const item = getValues(path);
    const cleanItem = item.filter((a, i) => i !== index);
    setValue?.(`${path}`, cleanItem);
  };

  return (
    <ListGroup.Item>
      <Row className="g-3 align-items-center">
        <Col xs={3} className="text-center">
          <Form.Label
            className={classNames('text-truncate m-0', { 'fs--1': !setValue })}
          >
            Hora de inicio
          </Form.Label>
          <DatePicker
            className={classNames('form-control', {
              'form-control-sm': !setValue
            })}
            popperClassName="z-index-1016"
            selected={start && startDate}
            onChange={start => handleChange({ start, end })}
            readOnly={!setValue}
            disabled={!setValue}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Hora de inicio"
            dateFormat="HH:mm"
            placeholderText="--:--"
          />
        </Col>
        <Col xs={1}>
          <Flex alignItems="center" className="h-100">
            <FontAwesomeIcon className="mt-4" icon="arrow-right" />
          </Flex>
        </Col>
        <Col xs={3} className="text-center">
          <Form.Label
            className={classNames('text-truncate m-0', { 'fs--1': !setValue })}
          >
            Hora de fin
          </Form.Label>
          <DatePicker
            className={classNames('form-control', {
              'form-control-sm': !setValue
            })}
            popperClassName="z-index-1016"
            selected={end && endDate}
            onChange={end => handleChange({ start, end })}
            readOnly={!setValue}
            disabled={!setValue}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Hora de fin"
            dateFormat="HH:mm"
            placeholderText="--:--"
          />
        </Col>
        <Col xs={5} className="text-center">
          <Form.Label
            className={classNames('text-truncate m-0', { 'fs--1': !setValue })}
          >
            Días de la semana
          </Form.Label>
          <div className="my-1">
            <WeekDays days={days} path={`${path}.${index}`} {...form} />
          </div>
        </Col>
      </Row>
      {setValue && (
        <div
          className={classNames('position-absolute top-50 end-0 me-n3 mt-n3', {
            'visually-hidden': index === 0
          })}
        >
          <Button
            variant="falcon-danger"
            className="rounded-pill px-2"
            size="sm"
            onClick={handleDelete}
          >
            <FontAwesomeIcon icon="times" />
          </Button>
        </div>
      )}
    </ListGroup.Item>
  );
};

TimeInterval.propTypes = {
  interval: PropTypes.object,
  index: PropTypes.number,
  path: PropTypes.string
};

const TimeIntervals = ({ path, time, ...form }) => {
  const { setValue } = form;

  const handleClickAdd = () => {
    !time?.length && setValue?.(`${path}.time.0`, { days: [] });
    setValue?.(`${path}.time.${time?.length || 0}`, { days: [] });
  };

  return (
    <div className="mt-3">
      <Form.Label className={classNames({ 'fs--1': !setValue })}>
        Intervalos de tiempo
      </Form.Label>
      <ListGroup>
        {(time || [{}]).map((interval, index) => (
          <TimeInterval
            key={`TimeInterval-${index}`}
            interval={interval}
            index={index}
            path={`${path}.time`}
            {...form}
          />
        ))}
      </ListGroup>
      {setValue && (
        <div className="text-center">
          <Button
            variant="falcon-default"
            className="ms-3 my-2 rounded-pill px-2"
            onClick={handleClickAdd}
            size="sm"
          >
            <FontAwesomeIcon icon="plus" />
          </Button>
        </div>
      )}
    </div>
  );
};

TimeIntervals.propTypes = {
  path: PropTypes.string,
  time: PropTypes.array
};

const PeriodTotalTime = ({ period, schedule }) => {
  let { time } = period || {};
  let { maxHours = 8 } = schedule || {};
  maxHours = parseInt(maxHours);

  let totalSeconds = time?.reduce((total, { days, start, end }) => {
    const [, startHours = '00'] = start?.match(/(\d{2}):/) || [];
    const [, startMinutes = '00'] = start?.match(/:(\d{2})/) || [];
    const startDate = new Date();
    startDate.setHours(parseInt(startHours));
    startDate.setMinutes(parseInt(startMinutes));
    const [, endHours = '00'] = end?.match(/(\d{2}):/) || [];
    const [, endMinutes = '00'] = end?.match(/:(\d{2})/) || [];
    const endDate = new Date();
    endDate.setHours(parseInt(endHours));
    endDate.setMinutes(parseInt(endMinutes));
    const seconds = Math.abs(dayjs(endDate).diff(startDate, 'seconds'));
    const numDays = days?.length || 0;
    return total + seconds * numDays;
  }, 0);

  const hours = Math.min(Math.round(totalSeconds / 3600) || 0, maxHours);
  totalSeconds = totalSeconds - hours * 3600;
  const isReached = hours === maxHours;
  const minutes = isReached ? 0 : Math.round(totalSeconds / 60) || 0;
  totalSeconds = totalSeconds - minutes * 60;
  let extraHours = 0;
  let extraMinutes = 0;
  if (isReached) {
    extraHours = Math.min(Math.round(totalSeconds / 3600) || 0, maxHours);
    totalSeconds = totalSeconds - extraHours * 3600;
    extraMinutes = Math.round(totalSeconds / 60) || 0;
  }
  const hasExtraTime = (extraHours || extraMinutes) > 0;

  return (
    <div className="fs--1 flex-grow-1 text-start">
      Tiempo total semanal:{' '}
      <strong>
        {hours}h {minutes}m
      </strong>
      {hasExtraTime && (
        <SoftBadge bg="danger" className="ms-2" pill>
          <FontAwesomeIcon icon="plus" className="me-2" />
          {extraHours}h {extraMinutes}m
        </SoftBadge>
      )}
    </div>
  );
};

PeriodTotalTime.propTypes = {
  period: PropTypes.object,
  schedule: PropTypes.object
};

const Period = ({ path, period, schedule, index, ...form }) => {
  const { getValues, setValue } = form;
  const defaultStart = new Date();
  defaultStart.setMonth(0);
  defaultStart.setDate(1);
  const defaultEnd = new Date();
  defaultEnd.setMonth(11);
  defaultEnd.setDate(31);
  let { start, end, time } = period || {};

  const handleChange = ({ start, end }) => {
    setValue?.(`${path}.${index}.start`, start);
    setValue?.(`${path}.${index}.end`, end);
  };

  const handleDelete = () => {
    const item = getValues(path);
    const cleanItem = item.filter((a, i) => i !== index);
    setValue?.(`${path}`, cleanItem);
  };

  return (
    <ListGroup.Item
      className={classNames({
        'border-primary': !!setValue
      })}
    >
      <Row>
        <Col>
          <Form.Label className={classNames({ 'fs--1': !setValue })}>
            Desde
          </Form.Label>
          <DatePicker
            className={classNames('form-control', {
              'form-control-sm': !setValue
            })}
            popperClassName="z-index-1016"
            selected={start && new Date(start)}
            onChange={start => handleChange({ start, end })}
            timeIntervals={15}
            readOnly={!setValue}
            disabled={!setValue}
            dateFormat="d MMMM"
            dateFormatCalendar="LLLL"
            openToDate={new Date(start || defaultStart)}
            placeholderText="1 enero"
          />
        </Col>
        <Col xs="auto" className="p-0">
          <Flex alignItems="center" className="h-100">
            <FontAwesomeIcon className="mt-4" icon="arrow-right" />
          </Flex>
        </Col>
        <Col>
          <Form.Label className={classNames({ 'fs--1': !setValue })}>
            Hasta
          </Form.Label>
          <DatePicker
            className={classNames('form-control', {
              'form-control-sm': !setValue
            })}
            popperClassName="z-index-1016"
            selected={end && new Date(end)}
            onChange={end => handleChange({ start, end })}
            timeIntervals={15}
            readOnly={!setValue}
            disabled={!setValue}
            dateFormat="d MMMM"
            dateFormatCalendar="LLLL"
            openToDate={new Date(end || defaultEnd)}
            placeholderText="31 diciembre"
          />
        </Col>
      </Row>
      {setValue && (
        <div
          className={classNames('position-absolute top-50 end-0 me-n3 mt-n3', {
            'visually-hidden': index === 0
          })}
        >
          <Button
            variant="falcon-danger"
            className="rounded-pill px-2"
            size="sm"
            onClick={handleDelete}
          >
            <FontAwesomeIcon icon="times" />
          </Button>
        </div>
      )}
      <Form.Group className="mb-3">
        <TimeIntervals path={`${path}.${index}`} time={time} {...form} />
      </Form.Group>
      <PeriodTotalTime period={period} schedule={schedule} />
    </ListGroup.Item>
  );
};

Period.propTypes = {
  period: PropTypes.object,
  schedule: PropTypes.object,
  index: PropTypes.number,
  path: PropTypes.string,
  setValue: PropTypes.func
};

const Periods = ({ path, periods, schedule, ...form }) => {
  const { setValue } = form;

  const handleClickAdd = () => {
    !periods?.length && setValue?.('periods.0', {});
    setValue?.(`${path}.${periods?.length || 1}`, {});
  };

  return (
    <div>
      <ListGroup>
        {(periods || [{}]).map((period, index) => (
          <Period
            key={`Period-${index}`}
            period={period}
            schedule={schedule}
            index={index}
            path={path}
            {...form}
          />
        ))}
      </ListGroup>
      {setValue && (
        <div className="text-center">
          <IconButton
            variant="falcon-primary"
            icon="plus"
            className="ms-3 my-2 rounded-pill px-2"
            onClick={handleClickAdd}
            size="sm"
          >
            Añadir periodo
          </IconButton>
        </div>
      )}
    </div>
  );
};

Periods.propTypes = {
  path: PropTypes.string,
  periods: PropTypes.array,
  schedule: PropTypes.object
};

export const Schedule = ({ schedule, ...form }) => {
  const { register } = form;
  const { maxHours, name, periods } = schedule || {};

  return (
    <div>
      <Row>
        <Col xs={9}>
          <Form.Group className="mb-3">
            <Form.Label className={classNames({ 'fs--1': !register })}>
              Nombre
            </Form.Label>
            <Form.Control
              value={name || 'Horario'}
              readOnly={!register}
              disabled={!register}
              size={classNames({ sm: !register })}
              {...(register?.('name') || {})}
            />
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group className="mb-3">
            <Form.Label className={classNames({ 'fs--1': !register })}>
              Horas
            </Form.Label>
            <Form.Control
              type="number"
              value={maxHours || 40}
              readOnly={!register}
              disabled={!register}
              size={classNames({ sm: !register })}
              {...(register?.('maxHours') || {})}
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mb-3">
        <Periods
          path="periods"
          periods={periods}
          schedule={schedule}
          {...form}
        />
      </Form.Group>
    </div>
  );
};

Schedule.propTypes = {
  schedule: PropTypes.object
};

const ScheduleModal = ({ closeModal, isOpen, schedule: scheduleProp }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { NO_ID_FIELD, ...editingSchedule } = scheduleProp || {};
  const { createSchedule, updateSchedule } = useContext(UserContext);
  const form = useForm({ defaultValue: { ...(editingSchedule || {}) } });
  const { handleSubmit, reset, watch } = form;
  const schedule = watch();

  const onSubmitData = async () => {
    schedule.periods = schedule.periods.map(({ end, start, ...rest }) => ({
      end: end.toISOString(),
      start: start.toISOString(),
      ...rest
    }));

    try {
      NO_ID_FIELD
        ? await updateSchedule(scheduleProp, schedule)
        : await createSchedule(schedule);
      toast.success(
        NO_ID_FIELD
          ? 'Horario creado con éxito'
          : `${name} modificado con éxito`
      );
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    reset(scheduleProp || {});
  }, [isOpen, scheduleProp]);

  return (
    <Modal show={isOpen} onHide={closeModal} contentClassName="border">
      <Form onSubmit={handleSubmit(onSubmitData)}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-x1 border-bottom-0"
        >
          <Modal.Title as="h5">
            {classNames({
              'Nuevo horario': !scheduleProp,
              [`Editando ${name}`]: scheduleProp
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          <Schedule schedule={schedule} {...form} />
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">
            {classNames({
              Crear: !scheduleProp,
              Guardar: scheduleProp
            })}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ScheduleModal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  schedule: PropTypes.object
};

const UsersModal = ({ closeModal, isOpen, schedule: scheduleProp }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const userList = useRef();
  const { name, users: usersProp } = scheduleProp || {};
  const {
    partners,
    schedules: companySchedules,
    updateSchedule
  } = useContext(UserContext);
  const form = useForm({ defaultValue: { users: usersProp || [] } });
  const { handleSubmit, reset, setValue, watch } = form;
  const { users } = watch();

  const onSubmitData = async () => {
    try {
      await updateSchedule(scheduleProp, { users });
      toast.success(`${name} modificado con éxito`);
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeSelection = () => {
    const users =
      userList?.current?.selectedFlatRows
        ?.map(({ original }) => original?.ref)
        .filter(ref => ref) || [];
    setValue?.('users', users);
  };

  useEffect(() => {
    const { users } = scheduleProp || {};
    reset(users || []);
    users?.forEach(ref => {
      const index = partners.findIndex(
        partner => partner?.ref?.path === ref?.path
      );
      setTimeout(() => userList?.current?.toggleRowSelected(index, true), 100);
    });
  }, [isOpen, scheduleProp]);

  return (
    <Modal show={isOpen} onHide={closeModal} contentClassName="border">
      <Form onSubmit={handleSubmit(onSubmitData)}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-x1 border-bottom-0"
        >
          <Modal.Title as="h5">Empleados con {name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          <UserList
            innerRef={userList}
            className="shadow-none"
            onChangeSelection={handleChangeSelection}
            users={partners.map(partner => {
              const schedule =
                companySchedules?.find(({ users }) =>
                  users?.some(ref => ref?.path === partner?.ref?.path)
                ) || false;
              return { ...partner, schedule };
            })}
            tableProps={{
              sortable: true,
              selection: true,
              selectionColumnWidth: 28,
              pagination: true,
              perPage: 10
            }}
            extraColumns={[
              {
                accessor: 'schedule',
                Header: 'Horario',
                Cell: rowData => {
                  const { schedule } = rowData.row.original;
                  return (
                    schedule?.name || <SoftBadge pill>Sin horario</SoftBadge>
                  );
                }
              }
            ]}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Guardar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

UsersModal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  schedule: PropTypes.object
};

const Schedules = ({ className }) => {
  const {
    deleteSchedule,
    partners,
    schedules: companySchedules
  } = useContext(UserContext);
  const [isScheduleModalOpen, setScheduleModalIsOpen] = useState(false);
  const [isUsersModalOpen, setUsersModalIsOpen] = useState(false);
  const [schedule, setSchedule] = useState();

  const handleClose = () => {
    setSchedule();
    setScheduleModalIsOpen(false);
    setUsersModalIsOpen(false);
  };

  const handleOpenUsers = schedule => {
    setSchedule(schedule);
    setUsersModalIsOpen(true);
  };

  const handleAddSchedule = () => {
    setScheduleModalIsOpen(true);
  };

  const handleOpenSchedule = schedule => {
    setSchedule(schedule);
    setScheduleModalIsOpen(true);
  };

  const handleDeleteSchedule = async schedule => {
    await deleteSchedule(schedule);
  };

  return (
    <>
      <Card className={className}>
        <FalconCardHeader
          title="Horarios"
          endEl={
            <Button
              className="rounded-pill"
              size="sm"
              variant="falcon-default"
              onClick={handleAddSchedule}
            >
              <FontAwesomeIcon icon="plus" className="me-2" />
              Crear horario
            </Button>
          }
        />
        <Card.Body className="bg-light">
          <ListGroup>
            {companySchedules.map(schedule => {
              const { NO_ID_FIELD, name, users } = schedule;
              return (
                <ListGroup.Item key={`Location-Schedule-${NO_ID_FIELD}`}>
                  <Flex justifyContent="between" alignItems="center">
                    <div>
                      {name}{' '}
                      <SoftBadge className="fs--2" pill>
                        {users?.length || 0} empleados
                      </SoftBadge>
                    </div>
                    <Flex>
                      {!!users?.length && (
                        <AvatarGroup
                          className="justify-content-end me-2"
                          max={10}
                        >
                          {users
                            ?.map(ref => {
                              const user =
                                partners?.find(
                                  partner => partner?.ref?.path === ref?.path
                                ) || {};
                              const { NO_ID_FIELD, avatar, name } = user;
                              return NO_ID_FIELD ? (
                                <Avatar
                                  key={`Schedule-${NO_ID_FIELD}-Avatar-${NO_ID_FIELD}`}
                                  src={avatar && avatar}
                                  name={name && name}
                                  size="m"
                                />
                              ) : null;
                            })
                            .filter(avatar => avatar)}
                        </AvatarGroup>
                      )}
                      <Button
                        variant="outline-secondary"
                        className="border-0"
                        size="sm"
                        onClick={() => handleOpenUsers(schedule)}
                      >
                        <FontAwesomeIcon icon="users" />
                      </Button>
                      <Button
                        variant="outline-secondary"
                        className="border-0"
                        size="sm"
                        onClick={() => handleOpenSchedule(schedule)}
                      >
                        <FontAwesomeIcon icon="calendar-week" />
                      </Button>
                      <Button
                        variant="outline-danger"
                        className="border-0"
                        size="sm"
                        onClick={() => handleDeleteSchedule(schedule)}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </Button>
                    </Flex>
                  </Flex>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Card.Body>
      </Card>
      <ScheduleModal
        closeModal={handleClose}
        isOpen={isScheduleModalOpen}
        schedule={schedule}
      />
      <UsersModal
        closeModal={handleClose}
        isOpen={isUsersModalOpen}
        schedule={schedule}
      />
    </>
  );
};

Schedules.propTypes = {
  className: PropTypes.string
};

export default Schedules;
