import React, { useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import dayjs from 'dayjs';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import {
  CalendarContext,
  TimeControlContext,
  UserContext
} from 'context/Context';
import {
  DayTimeControl,
  WorkingDay,
  WorkingDayDuration,
  WorkingState
} from './Components';
import {
  getDayStartTime,
  getDayEndTime,
  getTodaySeconds,
  getUserSchedule
} from 'helpers/utils';
import timeOffSubtypes from 'components/user/time-off/subtypes.json';

const columns = [
  {
    accessor: 'name',
    Header: 'Nombre',
    headerProps: {
      className: 'py-3 min-w-220px w-220px'
    },
    cellProps: { className: 'pb-4' },
    Cell: rowData => {
      const { user } = rowData.row.original;
      let { id, avatar, department, name } = user;
      return (
        <Flex alignItems="center">
          <Avatar size="xl" src={avatar} name={name} />
          <div className="ps-2 text-start">
            <Link
              to={`/user/time-control/custom/${id}`}
              className="text-800 stretched-link"
            >
              <h6 className="mb-0">{name}</h6>
            </Link>
            <p className="mb-0 fs--2">{department}</p>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'isWorking',
    Header: 'Estado',
    cellProps: { className: 'w-0 pb-4' },
    Cell: rowData => {
      return <WorkingState {...rowData.row.original} isToday={true} />;
    }
  },
  {
    accessor: 'workingDay',
    Header: 'Jornada',
    cellProps: { className: 'w-0 pb-4' },
    Cell: rowData => {
      return <WorkingDay {...rowData.row.original} isToday={true} />;
    }
  },
  {
    accessor: 'todayTime',
    Header: 'Tiempo trabajado',
    cellProps: { className: 'w-0 pb-4' },
    Cell: rowData => {
      return <WorkingDayDuration {...rowData.row.original} isToday={true} />;
    }
  },
  {
    accessor: 'todayRecords',
    disableSortBy: true,
    Header: 'Control horario',
    cellProps: { className: 'pb-4' },
    Cell: rowData => {
      return <DayTimeControl {...rowData.row.original} isToday={true} />;
    }
  }
];

const perPage = 20;
const EmployeesTimeControlList = () => {
  const { timeOff: timesOff = [] } = useContext(CalendarContext);
  const { todayRecords = [] } = useContext(TimeControlContext);
  const { partners = [], schedules } = useContext(UserContext);
  const innerRef = useRef();
  const [now] = useState(new Date());
  const [day] = now.toISOString().match(/\d{4}-\d{2}-\d{2}/);
  const data = partners.map(user => {
    const { location } = user || {};
    const { holidays = [] } = location || {};
    const userTimesOff = timesOff.filter(
      ({ participants }) =>
        !participants || participants.some(ref => ref?.path === user?.ref?.path)
    );
    const records = todayRecords.filter(
      ({ userId }) => userId === user?.NO_ID_FIELD
    );
    const lastRecord = [...records].pop();
    const isWorking = lastRecord?.type === 'in';
    const holiday = holidays.find(
      ({ start }) =>
        dayjs(start).format('YYYY-MM-DD') === dayjs(now).format('YYYY-MM-DD')
    );
    const timeOff = userTimesOff.find(
      ({ start, end }) =>
        dayjs(day).format('YYYY-MM-DD') >= dayjs(start).format('YYYY-MM-DD') &&
        dayjs(day).format('YYYY-MM-DD') <= dayjs(end).format('YYYY-MM-DD')
    );
    const subtypeData =
      timeOffSubtypes.find(({ value }) => value === timeOff?.subtype) || {};
    const { working } = subtypeData;
    const isHoliday = !!holiday;
    const isTimeOff = !!timeOff;
    const schedule = getUserSchedule(schedules, user);
    const maxSeconds =
      isHoliday || (!working && isTimeOff) ? 0 : getTodaySeconds(schedule);
    const startTime =
      isHoliday || (!working && isTimeOff)
        ? ''
        : getDayStartTime(schedule, new Date(day));
    const endTime =
      isHoliday || (!working && isTimeOff)
        ? ''
        : getDayEndTime(schedule, new Date(day));

    return {
      isHoliday,
      holiday,
      timeOff,
      isWorking,
      lastRecord,
      records,
      schedule,
      maxSeconds,
      day,
      startTime,
      endTime,
      user
    };
  });

  return (
    <>
      <AdvanceTableWrapper
        innerRef={innerRef}
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={20}
      >
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName={classNames(
              'bg-light text-900 text-nowrap align-middle'
            )}
            rowClassName="position-relative align-middle white-space-nowrap"
            tableProps={{
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer className="bg-light pb-0">
          {perPage < data.length && (
            <AdvanceTableFooter
              rowCount={data.length}
              table
              rowInfo
              navButtons
            />
          )}
        </Card.Footer>
      </AdvanceTableWrapper>
    </>
  );
};

export default EmployeesTimeControlList;
