import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { UserContext } from 'context/Context';
import { getUnique } from 'helpers/utils';

const LCSText = ({ count, feed, text, users }) => {
  const { partners = [] } = useContext(UserContext);
  const usersList = users
    .map(
      ({ userId }) =>
        partners?.find(({ NO_ID_FIELD }) => NO_ID_FIELD === userId) || {}
    )
    .filter(user => user);
  return (
    <Flex alignItems="center">
      <AvatarGroup max={5} className="justify-content-end">
        {usersList.map((user, index) => {
          const { avatar, name, NO_ID_FIELD } = user;
          return (
            <Avatar
              key={`Feed-Reaction-${feed?.NO_ID_FIELD}-${text}-${NO_ID_FIELD}-${index}`}
              src={avatar}
              name={name}
              size="m"
              className="border border-3 rounded-circle border-light bg-light"
            />
          );
        })}
      </AvatarGroup>
      <span className="text-700 me-1">
        {count} {text}
        {count !== 1 && 's'}
      </span>
    </Flex>
  );
};

const FeedReactions = ({ feed, setShowComment }) => {
  const { comments = [], likes = [] } = feed;
  const commentUsers = getUnique(comments, 'userId');
  const likeUsers = getUnique(likes, 'userId');

  return (
    <Flex
      alignItems="center"
      className="border-bottom border-200 fs--1 pt-2 pb-1"
    >
      {!!likes?.length && (
        <Button variant="link" className="p-0 fs--1">
          <LCSText
            count={likes?.length}
            feed={feed}
            text="Like"
            users={likeUsers}
          />
        </Button>
      )}
      {!!likes?.length && !!comments?.length && (
        <>
          <span className="mx-1" />
          •
          <span className="mx-1" />
        </>
      )}
      {!!comments?.length && (
        <>
          <Button
            variant="link"
            className="p-0 fs--1"
            onClick={() => setShowComment(true)}
          >
            <LCSText
              count={comments?.length}
              feed={feed}
              text="Comentario"
              users={commentUsers}
            />
          </Button>
        </>
      )}
    </Flex>
  );
};

LCSText.propTypes = {
  count: PropTypes.number.isRequired,
  feed: PropTypes.object,
  text: PropTypes.string.isRequired,
  users: PropTypes.array
};

FeedReactions.propTypes = {
  feed: PropTypes.object,
  setShowComment: PropTypes.func
};

export default FeedReactions;
