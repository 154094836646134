import React, { useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import useProducts from 'hooks/useProducts';
import Flex from 'components/common/Flex';
import { ProductContext } from 'context/Context';

const ProductsHeader = ({ setShowFilterOffcanvas }) => {
  const { handleSearch, handleIsAscClick, handleSortByChange, isAsc } =
    useProducts();
  const {
    productsState: { primaryProducts = [] }
  } = useContext(ProductContext);
  const [searchText, setSearchText] = useState('');
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const q = params.get('q');

  useMemo(() => {
    q && setSearchText(q);
    handleSearch(q);
  }, [q]);

  const handleSubmit = event => {
    event.preventDefault();
    handleSearch(searchText);
  };

  return (
    <Card className="mb-3">
      <Card.Header
        justifyContent="between"
        className="position-relative pb-0"
        as={Flex}
      >
        <h5 className="mb-0 mt-1">Marketplace</h5>
        <p className="fs--1 text-500 m-0">Total: {primaryProducts.length}</p>
      </Card.Header>
      <Card.Body className="pt-0 pt-md-3">
        <Row className="g-3 align-items-center">
          <Col xs="auto" className="d-xl-none">
            <Button
              onClick={() => setShowFilterOffcanvas(true)}
              className="position-relative p-0"
              size="sm"
              variant="link"
            >
              <FontAwesomeIcon icon="filter" className="fs-0 text-700" />
            </Button>
          </Col>
          <Col>
            <Form className="position-relative" onSubmit={handleSubmit}>
              <Form.Control
                type="search"
                placeholder="Buscar..."
                value={searchText}
                size="sm"
                aria-label="Search"
                className="rounded search-input ps-4"
                onChange={({ target }) => setSearchText(target.value)}
              />
              <FontAwesomeIcon
                icon="search"
                className="fs--1 text-400 position-absolute text-400 start-0 top-50 translate-middle-y ms-2"
              />
            </Form>
          </Col>
          <Col xs="auto" className="col-md">
            <Row className="g-0 justify-content-end">
              <Col xs="auto">
                <Form as={Row} className="gx-2">
                  <Col xs="auto" className="d-none d-lg-block">
                    <small>Ordenar por:</small>
                  </Col>
                  <Col xs="auto">
                    <InputGroup size="sm">
                      <Form.Select
                        className="pe-5"
                        defaultValue="title"
                        style={{ maxWidth: '7.5rem' }}
                        onChange={({ target }) =>
                          handleSortByChange(target.value)
                        }
                      >
                        <option value="title">Nombre</option>
                        <option value="price" disabled>
                          Precio
                        </option>
                      </Form.Select>
                      <InputGroup.Text
                        as={Button}
                        variant="link"
                        className="border border-300 text-700"
                        onClick={() => handleIsAscClick(!isAsc)}
                      >
                        <FontAwesomeIcon
                          icon={isAsc ? 'sort-amount-up' : 'sort-amount-down'}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ProductsHeader.propTypes = {
  layout: PropTypes.string,
  isAsc: PropTypes.bool,
  setIsAsc: PropTypes.func,
  setSortBy: PropTypes.func,
  setShowFilterOffcanvas: PropTypes.func,
  searchedText: PropTypes.string,
  handleSearch: PropTypes.func
};

export default ProductsHeader;
