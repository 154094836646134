import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import Tooltip from 'components/common/Tooltip';
import { ChatContext } from 'context/Context';

const ChatSidebarHeader = () => {
  const { setIsOpenNewChat } = useContext(ChatContext);
  return (
    <div className="chat-content-header justify-content-end bg-250">
      <Tooltip placement="bottom" title="Nuevo chat">
        <Button
          variant="send"
          className="m-0 p-0 shadow-none"
          size="sm"
          onClick={() => setIsOpenNewChat(true)}
        >
          <FontAwesomeIcon icon={['far', 'edit']} size="lg" />
        </Button>
      </Tooltip>
    </div>
  );
};

export default ChatSidebarHeader;
