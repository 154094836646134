import React from 'react';
import PropTypes from 'prop-types';
import Editor from 'react-markdown-editor-lite';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import 'react-markdown-editor-lite/lib/index.css';

const MarkdownEditor = ({ value, onChange }) => {
  const mdEditor = React.useRef(null);

  const handleEditorChange = ({ text }) => {
    onChange(text);
  };

  return (
    <Editor
      ref={mdEditor}
      value={value}
      style={{
        height: '500px'
      }}
      onChange={handleEditorChange}
      renderHTML={text => (
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown>
      )}
    />
  );
};

MarkdownEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default MarkdownEditor;
