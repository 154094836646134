import React, { useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Row } from 'react-bootstrap';
import useVisibilityObserver from 'hooks/useVisibilityObserver';
import FalconCardHeader from 'components/common/FalconCardHeader';
import IconButton from 'components/common/IconButton';
import Bottombar from './Bottombar';
import { UserContext } from 'context/Context';

const ProductSteps = ({ product }) => {
  const {
    company = {},
    subscription = {},
    updateCompany
  } = useContext(UserContext);
  const { apps: activeApps = [], email: companyEmail } = company;
  const { customer } = subscription;
  const {
    NO_ID_FIELD,
    id,
    disabled,
    subscription: isSubscription,
    title,
    type,
    url
  } = product || {};
  const targetElRef = useRef();
  const { isVisible: inViewport, observer } = useVisibilityObserver(
    targetElRef,
    '-100px'
  );
  const isActive = activeApps.includes(id);

  const handleClick = event => {
    event.stopPropagation();
    const _activeApps = isActive
      ? [...activeApps].filter(app => app !== id)
      : [...activeApps, id];
    updateCompany(company, { apps: _activeApps });
  };

  useEffect(() => {
    return () => {
      observer &&
        targetElRef.current &&
        observer.unobserve(targetElRef.current);
    };
  }, [observer]);

  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader
          title={title}
          titleTag="h5"
          className="bg-light d-none d-lg-block mb-0"
        />

        <Card.Body>
          <Row>
            <Col md={7} lg={12} className="order-md-1 order-lg-0">
              {(NO_ID_FIELD && (
                <Button
                  ref={targetElRef}
                  size="lg"
                  className={classNames('w-100 fs-0 mt-1', {
                    disabled
                  })}
                  disabled={disabled}
                  variant={classNames({
                    danger: isActive,
                    success: !isActive
                  })}
                  onClick={handleClick}
                >
                  <span className="ms-1 d-none d-lg-inline">
                    {classNames({
                      Desactivar: isActive,
                      Activar: !isActive
                    })}
                  </span>
                </Button>
              )) || (
                <IconButton
                  className={classNames('w-100 fs-0 mt-1', { disabled })}
                  iconClassName="me-sm-2"
                  variant="primary"
                  size="lg"
                  icon="shopping-cart"
                  to={`${url}${
                    type === 'marketplace'
                      ? `?prefilled_email=${companyEmail}&locale=${
                          window.navigator.language.split('-')[0]
                        }&client_reference_id=${customer}`
                      : ''
                  }`}
                  target="_blank"
                  as={Link}
                >
                  <span className="d-none d-sm-inline-block">
                    {isSubscription ? 'Suscribirme' : 'Comprar'}
                  </span>
                </IconButton>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Bottombar product={product} inViewport={inViewport} />
    </>
  );
};

ProductSteps.propTypes = {
  product: PropTypes.object
};

export default ProductSteps;
