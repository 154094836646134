import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/common/Tooltip';

const VerifiedBadge = ({ placement = 'top' }) => {
  return (
    <Tooltip placement={placement} title="Verificado">
      <FontAwesomeIcon
        icon="check-circle"
        transform="shrink-4 down-2"
        className="text-primary me-1"
      />
    </Tooltip>
  );
};

VerifiedBadge.propTypes = {
  placement: PropTypes.oneOf(['top', 'bottom', 'right', 'left'])
};

export default VerifiedBadge;
