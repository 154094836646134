import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Avatar from 'components/common/Avatar';

const Partner = ({ user }) => {
  const { id, avatar, name, department } = user;

  return (
    <div className="bg-white dark__bg-1100 p-3 h-100">
      <Link to={`/user/profile/${id}`}>
        <Avatar
          name={name}
          className="mb-3 border border-5 border-white shadow-sm"
          src={avatar}
          size="4xl"
        />
      </Link>
      <h6 className="mb-1">
        <Link to={`/user/profile/${id}`}>{name}</Link>
      </h6>
      <p className="fs--2 mb-1">
        <Link className="text-700" to="#!">
          {department}
        </Link>
      </p>
    </div>
  );
};

Partner.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.string,
    name: PropTypes.string,
    department: PropTypes.string
  })
};

export default Partner;
