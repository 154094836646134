import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import 'tinymce/skins/ui/oxide/skin.css';
import ActionButton from 'components/common/ActionButton';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { ReviewsContext } from 'context/Context';
import Question from 'components/company/reviews/Question';
import ReviewProvider from 'components/company/reviews/ReviewProvider';
import types from '../reviewTypes.json';

const ReviewCreationContent = ({ bodyClassName }) => {
  const { templateId } = useParams();
  const { create, templates = [] } = useContext(ReviewsContext);
  const navigate = useNavigate();
  const { handleSubmit, ...form } = useForm();
  const { reset } = form;
  const watching = form.watch();
  const { definitions = [] } = watching;
  const questionIndexes = definitions?.reduce((nums, { type }) => {
    const last = nums[nums.length - 1];
    let thisIndex =
      type === 'divider'
        ? type
        : last === 'divider'
        ? nums[nums.length - 2] + 1 || 1
        : last + 1;
    if (type !== 'divider' && isNaN(thisIndex)) {
      thisIndex = 1;
    }
    return [...nums, thisIndex];
  }, []);

  const onSubmitData = data => {
    create(data);
    toast.success('Evaluación creada');
    setTimeout(() => {
      navigate('/company/reviews');
    }, 1000);
  };

  const createQuestion = () => {
    form.setValue('definitions', [...definitions, { type: 'vote' }]);
  };

  useEffect(() => {
    if (!templateId) {
      return;
    }
    const template = templates?.find(
      ({ NO_ID_FIELD }) => NO_ID_FIELD === templateId
    );
    template && reset(template);
  }, [templateId, templates]);

  return (
    <Form onSubmit={handleSubmit(onSubmitData)}>
      <Card className={classNames('mb-3 d-inline-block', bodyClassName)}>
        <Form.Select
          placeholder="Tipo de la evaluación"
          className="bg-transparent m-0 py-2 pe-5 fs-0 border-0 outline-none px-x1 rounded-0"
          {...form.register('type', { required: true })}
        >
          {types.map(({ title, value }) => (
            <option key={`ReviewType-${value}`} value={value}>
              {title}
            </option>
          ))}
        </Form.Select>
      </Card>
      <Card className="mb-3">
        <Card.Header className="p-0">
          <Form.Control
            placeholder="Título de la evaluación"
            className="bg-transparent m-0 p-x1 fs-2 border-0 outline-none px-x1 rounded-0"
            {...form.register('title', { required: true })}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <div>
            <Form.Control
              type="text"
              placeholder="Descripción"
              className="border-0 outline-none p-x1 pt-0 rounded-0"
              as="textarea"
              rows={3}
              {...form.register('description', { required: true })}
            />
          </div>
          <Card.Body className="bg-light border-top border-bottom border-200">
            {definitions.map((question, index) => {
              const questionIndex = questionIndexes[index];
              return (
                <Question
                  key={`Question-${index}`}
                  {...form}
                  question={question}
                  index={index}
                  questionIndex={questionIndex}
                  isLast={index === definitions.length}
                />
              );
            })}
            <div className="ms-1">
              <ActionButton
                tooltip="Crear pregunta"
                icon="plus"
                handleClick={createQuestion}
              />
            </div>
          </Card.Body>
          <div>
            <Form.Control
              type="text"
              placeholder="Escribe un texto para finalizar"
              className="bg-transparent border-0 outline-none p-x1 rounded-0"
              {...form.register('outcome')}
            />
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Footer>
          <Row className="flex-between-center">
            <Col md>
              <h5 className="mb-2 mb-md-0">
                {!definitions.length
                  ? 'Primero añade alguna pregunta'
                  : `Vas a crear una evaluación con ${
                      definitions.filter(({ type }) => type !== 'divider')
                        .length
                    } pregunta${definitions.length > 1 ? 's' : ''}`}
              </h5>
            </Col>
            <Col xs="auto">
              <Button
                type="submit"
                disabled={!definitions.length}
                variant="primary"
                size="lg"
              >
                Crear
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Form>
  );
};

const ReviewCreation = () => {
  return (
    <ReviewProvider>
      <ReviewCreationContent />
    </ReviewProvider>
  );
};

ReviewCreationContent.propTypes = {
  bodyClassName: PropTypes.string
};

export default ReviewCreation;
