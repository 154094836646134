import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import { UserContext } from 'context/Context';
import classNames from 'classnames';

const MostHappyUsers = ({ end = 10, header, bodyClassName, ...rest }) => {
  const { partners = [] } = useContext(UserContext);

  return (
    <Card {...rest}>
      <SimpleBarReact className={classNames('h-100', bodyClassName)}>
        {header || (
          <FalconCardHeader
            className="sticky-top"
            title="Empleados más felices"
            titleTag="h6"
          />
        )}
        <Card.Body className="py-2">
          {partners
            .sort((p1, p2) =>
              (p1.happiness || 0) > (p2.happiness || 0) ? -1 : 1
            )
            .slice(0, end)
            .map(({ id, ref, ...rest }, index) => (
              <HappyUser
                {...rest}
                _ref={ref}
                id={id}
                key={`HappyUser-${id}`}
                isLast={index === partners.length - 1}
              />
            ))}
        </Card.Body>
        <FalconCardFooterLink
          className="sticky-bottom"
          title="Todos los empleados"
          to="/company/employees"
          size="sm"
        />
      </SimpleBarReact>
    </Card>
  );
};

const HappyUser = ({ id, avatar, name, department, happiness, isLast }) => {
  return (
    <Flex className={classNames('align-items-center', { 'mb-3': !isLast })}>
      <Avatar src={avatar} name={name} size="2xl" />
      <div className="ms-3 flex-grow-1 w-0">
        <h6 className="mb-0 fw-semi-bold">
          <Link className="text-900" to={`/user/profile/${id}`}>
            {name}
          </Link>
        </h6>
        <p className="text-500 fs--2 mb-0">{department}</p>
      </div>
      <div>
        <h5 className="mb-0">{happiness}</h5>
      </div>
    </Flex>
  );
};

HappyUser.propTypes = {
  id: PropTypes.string,
  avatar: PropTypes.string,
  name: PropTypes.string,
  department: PropTypes.string,
  happiness: PropTypes.number,
  status: PropTypes.string,
  isLast: PropTypes.bool
};

MostHappyUsers.propTypes = {
  end: PropTypes.number,
  header: PropTypes.node,
  bodyClassName: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape(HappyUser.propTypes))
};

export default MostHappyUsers;
