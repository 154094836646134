import React, { useContext } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { GaugeChart } from 'echarts/charts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import types from 'components/dashboards/types.json';
import { Card } from 'react-bootstrap';
import { ReportsContext } from 'context/Context';
import SoftBadge from 'components/common/SoftBadge';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  GaugeChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (data, color) => ({
  series: [
    {
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      radius: '90%',
      pointer: {
        show: false
      },
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
        itemStyle: {
          color
        }
      },
      axisLine: {
        lineStyle: {
          width: 4,
          color: [[1, getColor('gray-200')]]
        }
      },
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      },
      data: [
        {
          value: data,
          detail: {
            offsetCenter: ['7%', '4%']
          }
        }
      ],
      detail: {
        width: 50,
        height: 14,
        fontSize: 16,
        fontWeight: 500,
        fontFamily: 'poppins',
        color: getColor('gray-500'),
        formatter: '{value}%'
      }
    }
  ]
});

const Totals = ({ bodyClassName, type }) => {
  const title = types[type];
  const { indexes = [], partners = [] } = useContext(ReportsContext);
  const { department: departmentProp } = useParams();
  const rawFiltered = indexes.filter(({ type: t }) => type === t);
  const departmentRawFiltered = rawFiltered
    .map(data => {
      const { userId } = data;
      const { department } =
        partners.find(({ id } = {}) => id === userId) || {};
      if (!department) {
        return data;
      }
      return { ...data, department };
    })
    .filter(
      ({ department }) => !departmentProp || department === departmentProp
    );
  const departmentAverage = Math.round(
    departmentRawFiltered?.reduce((total, { value = 0 }) => total + value, 0) /
      (departmentRawFiltered?.length || 1) || 0
  );
  const companyAverage = Math.round(
    rawFiltered?.reduce((total, { value = 0 }) => total + value, 0) /
      (rawFiltered?.length || 1) || 0
  );
  const average = departmentProp ? departmentAverage : companyAverage;

  let color =
    (average > 75 && getColor('success')) ||
    (average > 60 && getColor('warning')) ||
    getColor('danger');

  const diff = average - companyAverage;
  let diffColor = diff === 0 ? 'info' : diff > 0 ? 'success' : 'danger';
  const departmentAverageText = diff === 0 ? '-' : `${Math.abs(diff)}%`;

  if (type === 'stress') {
    color =
      (average > 80 && getColor('danger')) ||
      (average > 60 && getColor('warning')) ||
      getColor('success');
    diffColor = diff === 0 ? 'info' : diff > 0 ? 'danger' : 'success';
  }

  return (
    <>
      <Card.Header className="pb-0">
        <h6 className="mb-0 text-capitalize text-nowrap text-truncate">
          {title}
          {departmentProp && (
            <h6 className="fw-normal text-600 text-wrap">
              <span className="fs--2 me-2">Empresa: {companyAverage}%</span>
              {diff !== 0 && (
                <SoftBadge pill bg={diffColor}>
                  <FontAwesomeIcon
                    icon={`caret-${diff > 0 ? 'up' : 'down'}`}
                    className="fs--1 me-1"
                  />

                  {departmentAverageText}
                </SoftBadge>
              )}
            </h6>
          )}
        </h6>
      </Card.Header>
      <Card.Body
        className={classNames(
          'w-100 d-flex align-items-end justify-content-center',
          bodyClassName
        )}
      >
        <ReactEChartsCore
          className="ms-n3 w-100"
          echarts={echarts}
          option={getOptions(
            departmentProp ? departmentAverage : average,
            color
          )}
          style={{ height: '4rem' }}
        />
      </Card.Body>
    </>
  );
};

Totals.propTypes = {
  bodyClassName: PropTypes.string,
  data: PropTypes.array,
  type: PropTypes.string
};

export default Totals;
