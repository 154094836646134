import React from 'react';
import ReportsProvider from 'components/company/reports/ReportsProvider';
import ReportsList from 'components/company/reports/DepartmentReportsList';

const DepartmentReportsContent = () => {
  return (
    <>
      <ReportsList />
    </>
  );
};

const DepartmentReports = () => {
  return (
    <ReportsProvider>
      <DepartmentReportsContent />
    </ReportsProvider>
  );
};

export default DepartmentReports;
