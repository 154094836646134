import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { Button } from 'react-bootstrap';
import { doc } from '@firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import FilesProvider from 'components/company/files/FilesProvider';
import { CalendarContext, FilesContext } from 'context/Context';
import { download } from 'helpers/utils';

const ProofButtonWithProvider = ({ timeOff, user }) => {
  const { NO_ID_FIELD: timeOffId, fileId, start } = timeOff;
  const { NO_ID_FIELD: userId } = user;
  const { update } = useContext(CalendarContext);
  const { createFile, getFileUrl, updateFile } = useContext(FilesContext);
  const db = useFirestore();
  let fileRef = doc(db, 'none', 'none');
  if (fileId) {
    fileRef = doc(db, 'files', fileId);
  }
  let { data: file } = useFirestoreDocData(fileRef);

  const handleClick = async event => {
    if (fileId) {
      const url = await getFileUrl(file);
      download(url);
      return;
    }
    event.currentTarget.lastElementChild.click();
  };

  const handleChangeFile = async event => {
    try {
      const { files = [] } = event.target;
      const file = files?.[0];
      const { name, type: mimeType, size } = file;
      const [extension] = name.match(/([^.]*)$/);
      const day = dayjs(start).format('DD MMMM YYYY');
      const path = `Justificante ${day}.${extension}`;
      if (fileId) {
        await updateFile(file, { file, mimeType, path, size });
      } else {
        const fileDoc = await createFile(file, {
          mimeType,
          path,
          size,
          timeOffId,
          type: 'proof',
          userId
        });
        await update(timeOff, { fileId: fileDoc.id });
      }
    } catch (error) {
      console.error(error);
    }
    event.target = null;
  };

  return (
    <Button
      variant={classNames({
        'falcon-success': !!fileId,
        'falcon-default': !fileId
      })}
      size="sm"
      onClick={handleClick}
    >
      {classNames({
        'Ver justificante': !!fileId,
        'Subir justificante': !fileId
      })}
      <input
        type="file"
        className="visually-hidden"
        onChange={handleChangeFile}
      />
    </Button>
  );
};

ProofButtonWithProvider.propTypes = {
  timeOff: PropTypes.object,
  user: PropTypes.object
};

export const ProofButton = props => {
  return (
    <FilesProvider>
      <ProofButtonWithProvider {...props} />
    </FilesProvider>
  );
};

export default ProofButton;
