import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Form } from 'react-bootstrap';
import dayjs from 'dayjs';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { AIContext, FeedbackContext, UserContext } from 'context/Context';
import { parseUrls } from 'helpers/utils';

const Mail = ({ message }) => {
  const { createdAt, text, userId } = message;
  const { partners } = useContext(UserContext);
  const user = partners?.find(({ NO_ID_FIELD }) => NO_ID_FIELD === userId);
  const { avatar, department, name } = user || {};
  const date = createdAt;

  return (
    <div>
      <div className="d-md-flex d-xl-inline-block d-xxl-flex align-items-center justify-content-between mb-x1">
        <Flex alignItems="center" className="gap-2">
          <Link to={`/feedback/contact-details/${userId}`}>
            <Avatar src={avatar} name={name} size="2xl" />
          </Link>
          <p className="mb-0">
            <Link
              to={`/feedback/contact-details/${userId}`}
              className="fw-semi-bold mb-0 text-800"
            >
              {name}
            </Link>
            <span className="mb-0 fs--1 d-block text-500">{department}</span>
          </p>
        </Flex>
        <p className="mb-0 fs--2 fs-sm--1 fw-semi-bold mt-2 mt-md-0 mt-xl-2 mt-xxl-0 ms-5">
          {dayjs(date).format('D MMMM, YYYY')}
          <span className="mx-1">|</span>
          <span className="fst-italic">
            {dayjs(date).format(`H:mm [(${dayjs().to(dayjs(date))})]`)}
          </span>
        </p>
      </div>
      <div className="mb-5 pb-5 border-bottom">
        <div className="ps-5">
          <p
            className="text-pre-wrap"
            dangerouslySetInnerHTML={{ __html: parseUrls(text) }}
          />
        </div>
      </div>
    </div>
  );
};

Mail.propTypes = {
  message: PropTypes.object
};

const MailResponse = ({ feedback }) => {
  const { createComment } = useContext(FeedbackContext);
  const { me } = useContext(UserContext);
  const [text, setText] = useState('');
  const { avatar, department, name } = me || {};

  const handleSubmit = event => {
    event.preventDefault();
    if (!text) {
      return;
    }
    createComment(feedback, {
      userId: me?.NO_ID_FIELD,
      text
    });
  };

  return (
    <div>
      <Flex alignItems="center" className="gap-2">
        <Avatar src={avatar} name={name} size="2xl" />
        <p className="mb-0">
          <span className="fw-semi-bold mb-0 text-800">{name}</span>
          <span className="mb-0 fs--1 d-block text-500">{department}</span>
        </p>
      </Flex>
      <div className="flex-1 fs--1 mt-3">
        <div className="position-relative border rounded mb-3">
          <Form onSubmit={handleSubmit}>
            <Form.Control
              as="textarea"
              size="sm"
              className="border-0 resize-none rounded-bottom-0"
              value={text}
              rows={3}
              onFocus={() => focus(true)}
              onChange={({ target }) => setText(target.value)}
            />
            <Flex
              alignItems="center"
              className="bg-light rounded-bottom p-2 mt-1"
            >
              <IconButton
                disabled={!text.trim()}
                size="sm"
                icon="reply"
                type="submit"
              >
                <span className="d-none d-sm-inline-block ms-1">Contestar</span>
              </IconButton>
            </Flex>
          </Form>
        </div>
      </div>
    </div>
  );
};

MailResponse.propTypes = {
  feedback: PropTypes.object
};

const Conversation = ({ feedback: item, user }) => {
  const { ai } = useContext(AIContext);
  const { me, selectUser, setShowNotesModal } = useContext(UserContext);
  const { agent, comments = [], createdAt, feedback, userId } = item || {};
  let title = `${feedback?.substr(0, 30)}`;
  title = title < feedback ? `${title}...` : title;

  const handleAI = () => {
    const { name, department } = user;
    const actions = ['Dame otra solución'];
    const context = [
      {
        role: 'system',
        content:
          'Eres un experto en recursos humanos de una empresa donde se cuidan mucho a sus empleados. Yo soy el manager de la plantilla.'
      },
      {
        role: 'system',
        content: `A continuación te voy a pasar un caso de un empleado que ha hecho una sugerencia o petición a la empresa. Dame según tu conocimiento la mejor propuesta para solucionar la petición, poniendo si es posible algún ejemplo.`
      },
      {
        role: 'system',
        content: `Redacta una respuesta legible y fácil de entender dirigiéndote a mi (${me?.firstname}), no al empleado. Tienes que dar respuestas muy gráficas. Sé breve, muy conciso, y evita ser genérico. No redactes la respuesta en un modo demasiado formal.`
      }
    ];
    const prompt = `${name} del departamento de ${department} ha puesto la siguiente sugerencia a fecha de ${dayjs().format(
      'MMMM [de] YYYY'
    )}: ${feedback}.`;
    const filename = `happyfy-ia-${name
      .toLowerCase()
      .replace(/ /g, '-')}--feedback.pdf`;
    ai({ context, prompt, actions, filename });
  };

  const handleNoteButtonClick = () => {
    selectUser(user);
    setShowNotesModal(true);
  };

  return (
    <Card className="mt-3">
      <Card.Header className="bg-light">
        <h5 className="mb-0">
          <FontAwesomeIcon icon="comment-medical" className="me-2" />
          {title}
        </h5>
      </Card.Header>
      <Card.Body>
        <Mail message={{ createdAt, text: feedback, userId }} />
        {comments.map((message, index) => (
          <Mail key={index} message={message} />
        ))}
        <MailResponse feedback={item} />
      </Card.Body>
      {(me?.type === 'admin' || me?.NO_ID_FIELD === agent) && (
        <Card.Footer className="bg-light">
          <IconButton
            size="sm"
            variant="falcon-default"
            icon="file-alt"
            className="me-2"
            onClick={handleNoteButtonClick}
          >
            <span className="d-none d-sm-inline-block ms-1">Escribir nota</span>
          </IconButton>
          {me?.type === 'admin' && (
            <IconButton
              size="sm"
              variant="falcon-default"
              icon="magic"
              onClick={() => handleAI(true)}
            >
              <span className="d-none d-sm-inline-block ms-1">
                Solicitar consejo
              </span>
            </IconButton>
          )}
        </Card.Footer>
      )}
    </Card>
  );
};

Conversation.propTypes = {
  feedback: PropTypes.object,
  user: PropTypes.object
};

export default Conversation;
