import React, { useEffect } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  addDoc,
  collection,
  doc,
  limit,
  query,
  where
} from '@firebase/firestore';
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData
} from 'reactfire';
import UserInfo from './UserInfo';
import QuestionaireIntro from './QuestionaireIntro';
import Questionaire from './Questionaire';
import QuestionaireOutcome from './QuestionaireOutcome';

const Error = () => <Navigate to="errors/404" replace={true} />;

const QuestionaireViewer = () => {
  let { base64JsonStr } = useParams();
  const db = useFirestore();
  let params = {};
  try {
    params = JSON.parse(atob(base64JsonStr));
  } catch (error) {
    return <Error />;
    //console.error(error);
  }
  const { userId, questionaireId } = params;
  const navigate = useNavigate();
  const userRef = doc(db, 'users', userId);
  const questionaireRef = doc(db, 'questionaires', questionaireId);
  const { data: user = {} } = useFirestoreDocData(userRef);
  const { data: questionaire = {} } = useFirestoreDocData(questionaireRef);

  const { company: userCompanyId = null } = user;
  const { companyId: questionaireCompanyId = null, definitions = [] } =
    questionaire;

  const userResponseRef = query(
    collection(db, 'questionaires_responses'),
    where('userId', '==', userId),
    where('questionaireId', '==', questionaireId),
    limit(1)
  );
  const { data: response = [] } = useFirestoreCollectionData(userResponseRef);
  const { responses = [] } = response?.[0] || {};
  const readOnly = !!response?.[0];
  const { handleSubmit, ...rest } = useForm();
  const { setValue } = rest;

  useEffect(() => {
    responses.forEach((value, index) => {
      setValue(`entry-${index}`, value);
    });
  }, [responses]);

  if (userCompanyId !== questionaireCompanyId) {
    return <Error />;
  }

  const onSubmitData = async data => {
    const responses = Object.entries({
      ...definitions.reduce(
        (all, { type }, index) => ({
          ...all,
          [`entry-${index}`]: type === 'divider' || undefined
        }),
        {}
      ),
      ...data
    })
      .sort(([key1], [key2]) => (parseInt(key1) < parseInt(key2) ? -1 : 1))
      .map(([, value]) => value);

    if (
      !responses.length ||
      responses.some((response, index) => {
        const isUndefined = typeof response === 'undefined';
        if (isUndefined) {
          const url = `#entry-${index}`;
          navigate(url);
        }
        return isUndefined;
      })
    ) {
      return;
    }

    const createdAt = new Date(Date.now()).toISOString();
    const responseData = {
      companyId: questionaireCompanyId,
      createdAt,
      questionaireId,
      userId,
      responses,
      new: true
    };
    try {
      await addDoc(collection(db, 'questionaires_responses'), responseData);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Row className="g-3">
        <Col lg={4} xl={3}>
          <div className="sticky-sidebar top-navbar-height">
            <UserInfo user={user} />
          </div>
        </Col>
        <Col lg={8} xl={9}>
          <Form onSubmit={handleSubmit(onSubmitData)}>
            <QuestionaireIntro questionaire={questionaire} />
            <Questionaire
              {...rest}
              questionaire={questionaire}
              readOnly={readOnly}
            />
            <QuestionaireOutcome
              questionaire={questionaire}
              readOnly={readOnly}
            />
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default QuestionaireViewer;
