// import React, { useContext, useEffect, useState } from 'react';
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// import CustomDateInput from 'components/common/CustomDateInput';
import { ReviewsContext } from 'context/Context';
import dayjs from 'dayjs';
// import DatePicker from 'react-datepicker';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import { toast } from 'react-toastify';
import types from '../reviewTypes.json';

dayjs.extend(relativeTime);
dayjs.locale('es');

const Settings = ({ review, hasResponses }) => {
  const { update } = useContext(ReviewsContext);
  // const date = `${new Date().toLocaleDateString()} ${new Date()
  //   .toLocaleTimeString()
  //   .replace(/:\d+$/, '')}`;
  // const [startPlaceholder] = useState(date);
  const { handleSubmit, ...form } = useForm();
  // const watching = form.watch();
  // const { startAt } = watching;

  const onSubmitData = data => {
    update(review?.NO_ID_FIELD, data);
    toast.success('Cuestionario modificado');
  };

  useEffect(() => {
    form.reset(review);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmitData)}>
      <Card.Body className="border-top bg-light border-bottom border-200">
        <Row className="g-3 mt-0">
          <Col xs={12} md={6} className="mt-0">
            <Form.Group className="mb-3 flex-grow-1">
              <Form.Label>Tipo</Form.Label>
              <Form.Select
                disabled={hasResponses}
                placeholder="Tipo de la evaluación"
                {...form.register('type', { required: true })}
              >
                {types.map(({ title, value }) => (
                  <option key={`ReviewType-${value}`} value={value}>
                    {title}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          {/* <Col xs={12} md={6} className="mt-0">
            <Form.Group className="mb-3 flex-grow-1">
              <Form.Label>Fecha de inicio</Form.Label>
              <DatePicker
                selected={startAt && new Date(startAt)}
                className="form-control"
                placeholderText="DD-MM-YYYY H:M"
                dateFormat="dd-MM-yyyy h:mm aa"
                showTimeSelect
                onChange={newDate => {
                  form.setValue('startAt', newDate.toISOString());
                }}
                customInput={
                  <CustomDateInput
                    formControlProps={{
                      placeholder: startPlaceholder,
                      ...form.register('startAt')
                    }}
                  />
                }
              />
            </Form.Group>
          </Col> */}
        </Row>
        <Form.Group className="mb-3 flex-grow-1">
          <Form.Label>Título</Form.Label>
          <Form.Control
            disabled={hasResponses}
            placeholder="Título del cuestionario"
            {...form.register('title', { required: true })}
          />
        </Form.Group>
        <Form.Group className="mb-3 flex-grow-1">
          <Form.Label>Descripción</Form.Label>
          <Form.Control
            disabled={hasResponses}
            placeholder="Descripción"
            {...form.register('description', { required: true })}
          />
        </Form.Group>

        <Form.Group className="flex-grow-1">
          <Form.Label>Despedida</Form.Label>
          <Form.Control
            disabled={hasResponses}
            placeholder="Escribe un texto para finalizar"
            {...form.register('outcome')}
          />
        </Form.Group>
      </Card.Body>
      <Card.Footer>
        {hasResponses ? (
          <small>Al haber respuestas ya no se puede modificar</small>
        ) : (
          <Button type="submit" variant="primary" size="lg">
            Guardar
          </Button>
        )}
      </Card.Footer>
    </Form>
  );
};

Settings.propTypes = {
  review: PropTypes.object,
  hasResponses: PropTypes.bool
};

export default Settings;
