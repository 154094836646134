import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import Main from './Main';
import './i18n';
import Root from './components/root';
import 'helpers/initFA';

const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Suspense fallback={<Root />}>
      <Main>
        <App />
      </Main>
    </Suspense>
  </React.StrictMode>
);
