import React, { useContext, useEffect, useRef, useState } from 'react';
import KanbanColumn from './KanbanColumn';
import CandidatesColumn from './CandidatesColumn';
import AddAnotherForm from './AddAnotherForm';
import { DragDropContext } from 'react-beautiful-dnd';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import is from 'is_js';
import { AssistantContext } from 'context/Context';

const KanbanContainer = () => {
  const {
    createColumn,
    kanbanState: { kanbanItems },
    kanbanDispatch,
    reorderCards
  } = useContext(AssistantContext);
  const [showForm, setShowForm] = useState(false);
  const containerRef = useRef(null);

  const handleSubmit = ({ title }) => {
    if (title) {
      createColumn({ title });
      setShowForm(false);
    }
  };

  useEffect(() => {
    if (is.ipad()) {
      containerRef.current.classList.add('ipad');
    }

    if (is.mobile()) {
      containerRef.current.classList.add('mobile');
      if (is.safari()) {
        containerRef.current.classList.add('safari');
      }
      if (is.chrome()) {
        containerRef.current.classList.add('chrome');
      }
    }
  }, []);

  const getColumn = id => {
    return kanbanItems.find(item => item.id === id);
  };

  const reorderArray = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };

  const move = (source, destination) => {
    const sourceItemsClone = [...getColumn(source.droppableId).items];
    const destItemsClone = [...getColumn(destination.droppableId).items];

    const [removedItem] = sourceItemsClone.splice(source.index, 1);
    destItemsClone.splice(destination.index, 0, removedItem);

    return {
      updatedDestItems: destItemsClone,
      updatedSourceItems: sourceItemsClone
    };
  };

  const handleDragEnd = result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    let newSource;
    let newDestination;

    if (source.droppableId === destination.droppableId) {
      const items = getColumn(source.droppableId).items;
      const column = getColumn(source.droppableId);
      const reorderedItems = reorderArray(
        items,
        source.index,
        destination.index
      );
      newSource = { column: source.droppableId, items: reorderedItems };

      kanbanDispatch({
        type: 'UPDATE_SINGLE_COLUMN',
        payload: { column, reorderedItems }
      });
    } else {
      const sourceColumn = getColumn(source.droppableId);
      const destColumn = getColumn(destination.droppableId);

      const movedItems = move(source, destination);
      const { updatedDestItems, updatedSourceItems } = movedItems;
      newSource = { column: source.droppableId, items: updatedSourceItems };
      newDestination = {
        column: destination.droppableId,
        items: updatedDestItems
      };
      kanbanDispatch({
        type: 'UPDATE_DUAL_COLUMN',
        payload: {
          sourceColumn,
          updatedSourceItems,
          destColumn,
          updatedDestItems
        }
      });
    }
    reorderCards({ source: newSource, destination: newDestination });
  };

  return (
    <Flex wrap="nowrap">
      <DragDropContext onDragEnd={handleDragEnd}>
        <div
          className="kanban-container scrollbar me-n3 w-0 flex-grow-1"
          ref={containerRef}
        >
          <CandidatesColumn
            kanbanColumnItem={kanbanItems.find(({ id }) => id === 'candidates')}
          />
          {kanbanItems
            .filter(({ id }) => !['offers', 'candidates'].includes(id))
            .map(kanbanColumnItem => (
              <KanbanColumn
                key={kanbanColumnItem.id}
                kanbanColumnItem={kanbanColumnItem}
              />
            ))}
          <div className="kanban-column">
            <AddAnotherForm
              type="list"
              onSubmit={handleSubmit}
              showForm={showForm}
              setShowForm={setShowForm}
            />
            {!showForm && (
              <IconButton
                variant="secondary"
                className="d-block w-100 border-400 bg-400"
                icon="plus"
                iconClassName="me-1"
                onClick={() => setShowForm(true)}
              >
                Añade otra columna
              </IconButton>
            )}
          </div>
        </div>
      </DragDropContext>
    </Flex>
  );
};

export default KanbanContainer;
