import { getUnique } from 'helpers/utils';

export const questionaireReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'FILTER':
      return {
        ...state,
        currentFilter: payload,
        questionaires: state.allQuestionaires.filter(questionaire => {
          if (payload === 'all') {
            return !questionaire?.archived;
          }
          return questionaire[payload];
        })
      };
    case 'BULK_UPDATE':
      return {
        ...state,
        allQuestionaires: state.allQuestionaires.map(questionaire =>
          payload.ids.includes(questionaire.NO_ID_FIELD)
            ? { ...questionaire, [payload.key]: payload.value }
            : questionaire
        )
      };
    case 'UPDATE':
      return {
        ...state,
        questionaires: getUnique(
          [
            ...(payload || []),
            ...state.questionaires.map(questionaire => {
              const questionaireUpdated = payload.find(
                ({ NO_ID_FIELD }) => NO_ID_FIELD === questionaire.NO_ID_FIELD
              );
              return questionaireUpdated
                ? { ...questionaire, ...questionaireUpdated }
                : questionaire;
            })
          ],
          'NO_ID_FIELD'
        ).filter(questionaire => {
          if (state?.currentFilter === 'all') {
            return !questionaire?.archived;
          }
          return questionaire[payload];
        }),
        allQuestionaires: getUnique(
          [
            ...(payload || []),
            ...state.allQuestionaires.map(questionaire => {
              const questionaireUpdated = payload.find(
                ({ NO_ID_FIELD }) => NO_ID_FIELD === questionaire.NO_ID_FIELD
              );
              return questionaireUpdated
                ? { ...questionaire, ...questionaireUpdated }
                : questionaire;
            })
          ],
          'NO_ID_FIELD'
        )
      };
    case 'ARCHIVE':
      return {
        ...state,
        allQuestionaires: state.allQuestionaires.map(questionaire =>
          payload.includes(questionaire.NO_ID_FIELD)
            ? { ...questionaire, archived: !questionaire?.archived }
            : questionaire
        ),
        questionaires: state.allQuestionaires
          .map(questionaire =>
            payload.includes(questionaire.NO_ID_FIELD)
              ? { ...questionaire, archived: !questionaire?.archived }
              : questionaire
          )
          .filter(({ archived }) => {
            if (state?.currentFilter === 'all') {
              return !archived;
            }
            if (state?.currentFilter === 'archived') {
              return archived;
            }
            return false;
          })
      };
    case 'DELETE':
      return {
        ...state,
        allQuestionaires: state.questionaires.filter(
          questionaire => !payload.includes(questionaire.NO_ID_FIELD)
        ),
        questionaires: state.questionaires.filter(
          questionaire => !payload.includes(questionaire.NO_ID_FIELD)
        )
      };
    case 'RESET':
      return {
        ...state,
        questionaires: state.allQuestionaires.filter(questionaire => {
          return !questionaire?.archived;
        }),
        currentFilter: 'all'
      };
    case 'RESET_QUESTIONAIRES':
      return {
        ...state,
        allQuestionaires: payload,
        questionaires: payload.filter(questionaire => {
          return !questionaire?.archived;
        }),
        currentFilter: 'all'
      };
    default:
      return state;
  }
};
