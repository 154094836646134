import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import useScrollSpy from 'react-use-scrollspy';
import Avatar from 'components/common/Avatar';
import EmployeeSelector from 'components/common/EmployeeSelector';
import FilesProvider from 'components/company/files/FilesProvider';
import UploadPayoffs from 'components/company/files/UploadPayoffs';
import Flex from 'components/common/Flex';
import FilesByType from './FilesByType';
import fileTypes from './fileTypes.json';
import { FilesContext, UserContext } from 'context/Context';

export const FilesContent = ({ userId, offsetPx = 0 }) => {
  const { files, selectedEmployeeId, setSelectedEmployeeId } =
    useContext(FilesContext);
  const { me, partners } = useContext(UserContext);
  const sectionRefs = Object.keys(fileTypes).map(() => useRef(null));
  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: offsetPx - 150
  });
  const selectedEmployee = partners?.find(
    ({ NO_ID_FIELD }) => NO_ID_FIELD === selectedEmployeeId
  );

  const handleChangeEmployee = ({ value }) => {
    setSelectedEmployeeId(value);
  };

  useEffect(() => {
    userId && setSelectedEmployeeId(userId);
  }, [userId]);

  return (
    <Row className="g-3 mt-0">
      <Col xs={12} lg={9} className="m-0 order-1 order-lg-0">
        {Object.keys(fileTypes).map((type, index) => (
          <div
            key={type}
            ref={sectionRefs[index]}
            id={type.toLocaleLowerCase()}
            className={classNames({
              'mb-3': index < Object.keys(fileTypes).length - 1
            })}
          >
            <FilesByType
              key={`File-Type-${type}`}
              files={files.filter(file => file.type === type)}
              type={type}
              user={selectedEmployee}
            />
          </div>
        ))}
      </Col>
      <Col xs={12} lg={3} className="m-0 order-0 order-lg-1 mb-3 mb-lg-0">
        <div className="sticky-sidebar">
          <Card>
            {me?.type === 'admin' && !userId && (
              <Card.Header className="bg-light">
                <EmployeeSelector
                  className="w-100"
                  isClearable={false}
                  value={
                    selectedEmployee && {
                      avatar: selectedEmployee?.avatar,
                      label: selectedEmployee?.name,
                      value: selectedEmployee?.NO_ID_FIELD
                    }
                  }
                  onChange={handleChangeEmployee}
                />
              </Card.Header>
            )}
            <Card.Body>
              <Nav className="flex-column" variant="pills">
                {Object.entries(fileTypes).map(
                  ([type, { icon, title }], index) => (
                    <Nav.Link
                      key={`Nav-File-${type}`}
                      href={`#${type}`}
                      active={activeSection === index}
                    >
                      <Flex alignItems="center">
                        <Avatar
                          icon={icon}
                          className="bg-transparent me-1"
                          mediaClass={classNames(
                            'bg-transparent d-flex align-items-center justify-content-center fs-1',
                            {
                              'text-primary': activeSection !== index,
                              'text-white': activeSection === index
                            }
                          )}
                        />
                        {title}
                      </Flex>
                    </Nav.Link>
                  )
                )}
              </Nav>
            </Card.Body>
          </Card>
          {me?.type === 'admin' && !userId && (
            <Card className="mt-3">
              <Card.Header className="pb-0">
                <h6 className="m-0">Sube aquí las nóminas de forma masiva</h6>
              </Card.Header>
              <Card.Body>
                <UploadPayoffs />
              </Card.Body>
            </Card>
          )}
        </div>
      </Col>
    </Row>
  );
};

FilesContent.propTypes = {
  offsetPx: PropTypes.number,
  userId: PropTypes.string
};

const Files = props => {
  return (
    <FilesProvider>
      <FilesContent {...props} />
    </FilesProvider>
  );
};

export default Files;
