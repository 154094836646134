import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AppContext, { EmployeesContext } from 'context/Context';

const ActionsDropdown = ({
  user,
  btnRevealClass,
  drop,
  children,
  icon = 'ellipsis-h',
  page,
  selectedRowIds
}) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const { deleteEmployee, sendHappyQuestionaireLink, sendSignInLink } =
    useContext(EmployeesContext);

  const {
    employeesState: { employees },
    employeesDispatch
  } = useContext(EmployeesContext);

  const handleActionButtonClick = type => {
    employeesDispatch({
      type,
      payload: page
        .filter(({ id }) => Object.keys(selectedRowIds).includes(id))
        .map(({ original }) => original.id)
    });
  };

  return (
    <Dropdown
      className="font-sans-serif btn-reveal-trigger"
      align={isRTL ? 'start' : 'end'}
      drop={drop}
    >
      <Dropdown.Toggle
        variant="link"
        size="sm"
        data-boundary="viewport"
        className={classNames('text-600', {
          [btnRevealClass]: btnRevealClass,
          'btn-reveal': !btnRevealClass
        })}
      >
        <FontAwesomeIcon icon={icon} className="fs--2" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="border py-0">
        {children}
        {!children && (
          <div className="py-2">
            {(user && (
              <>
                <Dropdown.Item as={Link} to={`/user/profile/${user.id}`}>
                  Ver perfil
                </Dropdown.Item>
                <Dropdown.Item onClick={() => sendSignInLink(user.email)}>
                  Dar acceso al panel
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => sendHappyQuestionaireLink(user.id)}
                >
                  Reenviar cuestionario de felicidad
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  className="text-danger"
                  onClick={() => deleteEmployee(user.id)}
                >
                  Eliminar
                </Dropdown.Item>
              </>
            )) ||
              (Object.keys(selectedRowIds).length > 0 && (
                <>
                  <Dropdown.Item
                    onClick={() => handleActionButtonClick('ARCHIVE')}
                  >
                    Archivar
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      sendHappyQuestionaireLink(
                        Object.keys(selectedRowIds).map(
                          row => employees[row].id
                        )
                      )
                    }
                  >
                    Reenviar cuestionario de felicidad
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      sendSignInLink(
                        Object.keys(selectedRowIds).map(
                          row => employees[row].email
                        )
                      )
                    }
                  >
                    Dar acceso al panel
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    className="text-danger"
                    onClick={() => handleActionButtonClick('DELETE')}
                  >
                    Eliminar
                  </Dropdown.Item>
                </>
              )) || (
                <Dropdown.Item disabled>
                  Selecciona algún empleado
                </Dropdown.Item>
              )}
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ActionsDropdown.propTypes = {
  user: PropTypes.object,
  btnRevealClass: PropTypes.string,
  drop: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  page: PropTypes.array,
  selectedRowIds: PropTypes.object
};

export default ActionsDropdown;
