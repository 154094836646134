import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useCountdown from 'hooks/useCountdown';
import Flex from 'components/common/Flex';

export const Countdown = ({ date, className, inline, itemClassName }) => {
  const isInline = typeof inline !== 'undefined' && inline;
  const [days, hours, minutes, seconds] = useCountdown(date);

  return (
    <Flex className={className}>
      <Flex
        className={classNames('p-2', itemClassName)}
        direction={classNames({
          column: !isInline,
          row: isInline
        })}
        alignItems="center"
      >
        <h5
          className={classNames('text-inherit', {
            'me-2': isInline
          })}
        >
          {days}
        </h5>
        <span className="fs--2">días</span>
      </Flex>
      <Flex
        className={classNames('p-2', itemClassName)}
        direction={classNames({
          column: !isInline,
          row: isInline
        })}
        alignItems="center"
      >
        <h5
          className={classNames('text-inherit', {
            'me-2': isInline
          })}
        >
          {hours}
        </h5>
        <span className="fs--2">horas</span>
      </Flex>
      <Flex
        className={classNames('p-2', itemClassName)}
        direction={classNames({
          column: !isInline,
          row: isInline
        })}
        alignItems="center"
      >
        <h5
          className={classNames('text-inherit', {
            'me-2': isInline
          })}
        >
          {minutes}
        </h5>
        <span className="fs--2">minutos</span>
      </Flex>
      <Flex
        className={classNames('p-2', itemClassName)}
        direction={classNames({
          column: !isInline,
          row: isInline
        })}
        alignItems="center"
      >
        <h5
          className={classNames('text-inherit', {
            'me-2': isInline
          })}
        >
          {seconds}
        </h5>
        <span className="fs--2">segundos</span>
      </Flex>
    </Flex>
  );
};

Countdown.propTypes = {
  date: PropTypes.object,
  className: PropTypes.string,
  inline: PropTypes.bool,
  itemClassName: PropTypes.string
};

export default Countdown;
