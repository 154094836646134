import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Alert, Card } from 'react-bootstrap';
import { UserReviewsContext, UserContext } from 'context/Context';
import usePagination from 'hooks/usePagination';
import Review from './Review';
import Footer from './Footer';
import ReviewProvider from './ReviewProvider';

const ReviewsContent = () => {
  const {
    reviewState: { reviews },
    setUser
  } = useContext(UserReviewsContext);

  const {
    paginationState: {
      data: paginatedReviews,
      totalItems,
      canNextPage,
      canPreviousPage,
      from,
      to
    },
    nextPage,
    prevPage
  } = usePagination(reviews, 10);

  useEffect(() => {
    setUser();
  }, []);

  return (
    <>
      <Card>
        <Card.Header>
          <h5>Evaluaciones</h5>
        </Card.Header>
        <Card.Body className="fs--1 border-top border-200 p-0">
          {paginatedReviews.length > 0 ? (
            <>
              {reviews.map(review => (
                <Review key={review.NO_ID_FIELD} review={review} />
              ))}
            </>
          ) : (
            <Alert variant="info" className="mb-0 rounded-0">
              <h5 className="alert-heading">No hay evaluaciones</h5>
            </Alert>
          )}
        </Card.Body>
        <Footer
          totalItems={totalItems}
          from={from}
          to={to}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          prevPage={prevPage}
        />
      </Card>
    </>
  );
};

const Reviews = () => {
  const { me } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    me?.type === 'admin' && navigate('/company/reviews');
  }, [me?.type]);

  return (
    <ReviewProvider>
      <ReviewsContent />
    </ReviewProvider>
  );
};

Reviews.propTypes = {
  creation: PropTypes.bool,
  templates: PropTypes.bool
};

export default Reviews;
