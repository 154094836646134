import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import { FeedContext, UserContext } from 'context/Context';

const FeedDropdown = ({ feed, variant = 'link' }) => {
  const { deleteFeed, updateFeed } = useContext(FeedContext);
  const { me } = useContext(UserContext);
  const { archived = false, privacy } = feed;

  const handleArchive = () => {
    updateFeed(feed, { privacy: 'admin', archived: !archived });
  };

  const handleChangePrivacy = () => {
    const updatedAt = new Date().toISOString();
    updateFeed(feed, {
      privacy: privacy === 'admin' ? 'public' : 'admin',
      updatedAt
    });
  };

  const handleDelete = () => {
    deleteFeed(feed);
  };

  if (me?.type !== 'admin') {
    return null;
  }

  return (
    <Dropdown className="font-sans-serif btn-reveal-trigger">
      <Dropdown.Toggle
        variant={variant}
        size="sm"
        className="px-1 text-600 btn-reveal"
      >
        <FontAwesomeIcon icon="ellipsis-h" />
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">
        <Dropdown.Item onClick={handleChangePrivacy}>
          {classNames({
            'Hacer público': privacy === 'admin',
            'Hacer privado': privacy === 'public'
          })}
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item className="text-warning" onClick={handleArchive}>
          {classNames({
            Archivar: !archived,
            Desarchivar: archived
          })}
        </Dropdown.Item>
        <Dropdown.Item className="text-danger" onClick={handleDelete}>
          Eliminar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

FeedDropdown.propTypes = {
  feed: PropTypes.object,
  variant: PropTypes.string
};

export default FeedDropdown;
