import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { addDoc, collection, doc, query, where } from '@firebase/firestore';
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData
} from 'reactfire';
import ReviewProvider from '../ReviewProvider';
import ReviewIntro from './ReviewIntro';
import Review from './Review';
import ReviewOutcome from './ReviewOutcome';
import UserInfo from './UserInfo';
import { UserReviewsContext, UserContext } from 'context/Context';
import { toast } from 'react-toastify';

const ReviewViewerContent = () => {
  const location = useLocation();
  const { hash } = location;
  let { me, partners: all = [] } = useContext(UserContext);
  let { user, setUser } = useContext(UserReviewsContext);
  const { reviewId } = useParams();
  const db = useFirestore();

  const { NO_ID_FIELD: userId } = me || {};
  const navigate = useNavigate();

  let userResponseRef = query(collection(db, 'none'));
  let reviewRef = doc(db, 'none', 'none');
  if (userId && reviewId) {
    reviewRef = doc(db, 'reviews', reviewId);
    userResponseRef = query(
      collection(db, 'reviews_responses'),
      where('userId', '==', userId),
      where('reviewId', '==', reviewId)
    );
  }
  const { data: review = {} } = useFirestoreDocData(reviewRef);
  const { data: response = [] } = useFirestoreCollectionData(userResponseRef);
  const { companyId: reviewCompanyId = null, definitions = [], type } = review;
  const { responses = [] } =
    response?.find(({ targetId }) => targetId === user?.NO_ID_FIELD) || {};
  const readOnly = !!responses?.length;
  const { handleSubmit, ...form } = useForm();
  const { reset, setValue } = form;
  let users = [me];
  if (type === 'manager') {
    users = all.filter(
      ({ department, level }) =>
        department === me?.department && level === me?.level - 1
    );
  }
  if (type === 'employeeToManager') {
    users = all.filter(
      ({ department, level }) =>
        department === me?.department && level === me?.level + 1
    );
  }
  users = users.map(user => ({
    ...user,
    response: response.find(({ targetId }) => targetId === user?.NO_ID_FIELD)
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
    reset();
    responses.forEach((value, index) => {
      setValue(`entry-${index}`, value);
    });
  }, [user?.NO_ID_FIELD]);

  const onSubmitData = async data => {
    const responses = Object.entries({
      ...definitions.reduce(
        (all, { type }, index) => ({
          ...all,
          [`entry-${index}`]: type === 'divider' || undefined
        }),
        {}
      ),
      ...data
    })
      .sort(([key1], [key2]) => (parseInt(key1) < parseInt(key2) ? -1 : 1))
      .map(([, value]) => value);

    if (
      !responses.length ||
      responses.some((response, index) => {
        const isUndefined = typeof response === 'undefined';
        if (isUndefined) {
          const url = `#entry-${index}`;
          navigate(url);
        }
        return isUndefined;
      })
    ) {
      return;
    }

    const createdAt = new Date(Date.now()).toISOString();
    const responseData = {
      companyId: reviewCompanyId,
      createdAt,
      reviewId,
      userId,
      responses,
      new: true
    };
    if (user?.NO_ID_FIELD) {
      responseData.targetId = user.NO_ID_FIELD;
    }

    try {
      await addDoc(collection(db, 'reviews_responses'), responseData);
      toast.success(`Evaluación enviada: ${user?.name}`);
      const nextUser = users.filter(
        ({ NO_ID_FIELD }) =>
          ![
            user.NO_ID_FIELD,
            ...response.map(({ targetId }) => targetId)
          ].includes(NO_ID_FIELD)
      )?.[0];
      nextUser
        ? setUser(nextUser)
        : setTimeout(() => {
            navigate(
              me?.type === 'admin' ? '/company/reviews' : '/user/reviews'
            );
          }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const firstUnresponsedUser = users?.filter(
      ({ NO_ID_FIELD }) =>
        ![
          user?.NO_ID_FIELD,
          ...response.map(({ targetId }) => targetId)
        ].includes(NO_ID_FIELD)
    )?.[0];
    !user?.NO_ID_FIELD && setUser(firstUnresponsedUser || users[0]);
  }, [users?.length]);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        const card = element?.parentElement?.parentElement;
        if (element) {
          card?.classList.add(...['border-4']);
          setTimeout(() => {
            card?.classList.remove(...['border-4']);
          }, 1000);
          element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }
    }, 0);
  }, [hash]);

  return (
    <>
      <Row className="g-3 m-0">
        <Col className={classNames({ 'mt-0': me?.type === 'admin' })}>
          <Form onSubmit={handleSubmit(onSubmitData)}>
            {me?.type !== 'admin' && <ReviewIntro review={review} />}
            <Review {...form} review={review} readOnly={readOnly} />
            <ReviewOutcome review={review} readOnly={readOnly} />
          </Form>
        </Col>
        {!!users.length && me?.NO_ID_FIELD !== user?.NO_ID_FIELD && (
          <Col
            lg={4}
            xl={3}
            className={classNames({ 'mt-0 pe-0': me?.type === 'admin' })}
          >
            <div className="h-100">
              <UserInfo users={users} />
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};

const ReviewViewer = () => {
  const { me } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  useEffect(() => {
    me?.type === 'admin' && pathname.includes('/user/reviews/detail')
      ? navigate(`${pathname.replace('/user/', '/company/')}/review`)
      : me?.type !== 'admin' && pathname.includes('/company/reviews/detail')
      ? navigate(
          pathname.replace('/company/', '/user/').replace(/\/review$/, '')
        )
      : null;
  }, [me?.type, pathname]);

  return (
    <ReviewProvider>
      <ReviewViewerContent />
    </ReviewProvider>
  );
};

export default ReviewViewer;
