import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card, Form } from 'react-bootstrap';
import { UserContext } from 'context/Context';
import { getUnique } from 'helpers/utils';

const notificationsData = [
  { label: 'Ausencias', name: 'time-off' },
  { label: 'Documentación', name: 'files' },
  { label: 'Eventos', name: 'event' },
  { label: 'Informes', name: 'reports', role: 'admin' },
  { label: 'Informes', name: 'reports', level: 1 },
  { label: 'Objetivos', name: 'objective' },
  { label: 'Reconocimientos', name: 'appreciations' },
  { label: 'Sugerencias', name: 'feedback', role: 'admin' },
  { label: 'Tablón de anuncios', name: 'feed' }
];
const AccountSettings = ({ user }) => {
  const { me, updateUser } = useContext(UserContext);
  const { settings = {} } = user || {};
  const { notifications = {} } = settings;

  const handleChange = ({ target }) => {
    let { name, checked, type, value } = target;
    if (type === 'checkbox') {
      value = checked;
    }
    updateUser(user, { [`settings.${name}`]: value });
  };

  const isDisabled = user?.NO_ID_FIELD !== me?.NO_ID_FIELD;
  const notificationItems = getUnique(
    notificationsData.filter(
      ({ level, role }) =>
        (!level || level === me?.level) && (!role || role === me?.type)
    ),
    'name'
  );

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Configuración" />
      <Card.Body className="bg-light">
        <div>
          <h6 className="fw-bold m-0">Notificaciones</h6>
          <p className="fs--2 mb-2">
            Activar o desactivar el envío de emails relacionado con cada
            funcionalidad
          </p>
          {notificationItems.map(({ label, name }) => (
            <div key={`Settings-Notification-${name}`} className="ps-2">
              <Form.Check
                disabled={isDisabled}
                type="switch"
                label={label}
                className="form-label-nogutter"
                name={`notifications.${name}`}
                onChange={handleChange}
                checked={notifications?.[name] !== false}
              />
            </div>
          ))}
        </div>
        <div className="mt-3">
          <h6 className="fw-bold">Esquema de colores</h6>
          <div className="ps-2">
            <Form.Check
              disabled={isDisabled}
              type="radio"
              id="color-auto"
              label="Auto"
              className="form-label-nogutter"
              value="auto"
              name="colorScheme"
              onChange={handleChange}
              checked={settings?.colorScheme === 'auto'}
            />
            <Form.Check
              disabled={isDisabled}
              type="radio"
              id="color-light"
              label="Claro"
              className="form-label-nogutter"
              value="light"
              name="colorScheme"
              onChange={handleChange}
              checked={settings?.colorScheme === 'light'}
            />
            <Form.Check
              disabled={isDisabled}
              type="radio"
              id="color-dark"
              label="Oscuro"
              className="form-label-nogutter"
              value="dark"
              name="colorScheme"
              onChange={handleChange}
              checked={settings?.colorScheme === 'dark'}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

AccountSettings.propTypes = {
  user: PropTypes.object
};

export default AccountSettings;
