import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Button, Card, Form } from 'react-bootstrap';
import { UserContext } from 'context/Context';
import { useAuth } from 'reactfire';
import { signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { toast } from 'react-toastify';

const initState = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
};

const ChangePassword = ({ user }) => {
  const auth = useAuth();
  const { me } = useContext(UserContext);
  const { email } = me || {};
  const [formData, setFormData] = useState(initState);
  const { oldPassword, newPassword, confirmPassword } = formData;

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error('Las contraseñas no coinciden');
      return;
    }
    try {
      const { currentUser } = auth;
      await signInWithEmailAndPassword(auth, email, oldPassword);
      await updatePassword(currentUser, newPassword);
      toast.success('La contraseña se ha cambiado correctamente');
      setFormData(initState);
    } catch (error) {
      const { code, message } = error;
      console.error(code);
      if (code === 'auth/wrong-password') {
        toast.error('La contraseña actual es incorrecta');
        return;
      }
      toast.error(message);
    }
  };

  if (user?.NO_ID_FIELD !== me?.NO_ID_FIELD) {
    return null;
  }

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Cambiar contraseña" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="oldPassword">
            <Form.Label>Contraseña antigua</Form.Label>
            <Form.Control
              type="text"
              value={oldPassword}
              name="oldPassword"
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="newPassword">
            <Form.Label>Contraseña nueva</Form.Label>
            <Form.Control
              type="text"
              value={newPassword}
              name="newPassword"
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>Confirmar contraseña</Form.Label>
            <Form.Control
              type="text"
              value={confirmPassword}
              name="confirmPassword"
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Button className="w-100" type="submit">
            Cambiar contraseña
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

ChangePassword.propTypes = {
  user: PropTypes.object
};

export default ChangePassword;
