import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import ModalMediaContent from './ModalMediaContent';

const CurrentOffer = ({ offer, show }) => {
  const { NO_ID_FIELD, data = {}, title } = offer;
  const { completion = [] } = data;

  return (
    show && (
      <div className="d-flex flex-column flex-1 kanban-container">
        <Card className="shadow-none h-0 flex-grow-1">
          <Card.Header className="bg-light fw-semi-bold">{title}</Card.Header>
          <Card.Body className="p-0 scrollbar">
            <div className="p-4">
              {completion?.map?.(({ language, text }) => {
                return (
                  <ModalMediaContent
                    key={`Offer-${NO_ID_FIELD}-${language}`}
                    title={language}
                    icon="align-left"
                    className="position-relative"
                  >
                    <div>
                      <p className="font-sans-serif text-pre-wrap">{text}</p>
                    </div>
                  </ModalMediaContent>
                );
              })}
            </div>
          </Card.Body>
        </Card>
      </div>
    )
  );
};

CurrentOffer.propTypes = {
  offer: PropTypes.object,
  show: PropTypes.bool
};

export default CurrentOffer;
