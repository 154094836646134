import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { QuestionairesContext } from 'context/Context';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import { toast } from 'react-toastify';

dayjs.extend(relativeTime);
dayjs.locale('es');

const Settings = ({ questionaire, hasResponses }) => {
  const { update } = useContext(QuestionairesContext);
  const { handleSubmit, ...form } = useForm();
  const { reset } = form;

  const onSubmitData = data => {
    update(questionaire, data);
    toast.success('Cuestionario creado');
  };

  useEffect(() => {
    reset(questionaire);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmitData)}>
      <Card.Body className="border-top bg-light border-bottom border-200">
        <Form.Group className="mb-3 me-x1 flex-grow-1">
          <Form.Label>Título</Form.Label>
          <Form.Control
            disabled={hasResponses}
            placeholder="Título del cuestionario"
            {...form.register('title', { required: true })}
          />
        </Form.Group>
        <Form.Group className="mb-3 me-x1 flex-grow-1">
          <Form.Label>Descripción</Form.Label>
          <Form.Control
            disabled={hasResponses}
            placeholder="Descripción"
            {...form.register('description', { required: true })}
          />
        </Form.Group>

        <Form.Group className="me-x1 flex-grow-1">
          <Form.Label>Despedida</Form.Label>
          <Form.Control
            disabled={hasResponses}
            placeholder="Escribe un texto para finalizar"
            {...form.register('outcome')}
          />
        </Form.Group>
      </Card.Body>
      <Card.Footer>
        {hasResponses ? (
          <small>Al haber respuestas ya no se puede modificar</small>
        ) : (
          <Button type="submit" variant="primary" size="lg">
            Guardar
          </Button>
        )}
      </Card.Footer>
    </Form>
  );
};

Settings.propTypes = {
  questionaire: PropTypes.object,
  hasResponses: PropTypes.bool
};

export default Settings;
