import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  collection,
  getDocs,
  orderBy,
  query,
  where
} from '@firebase/firestore';
import dayjs from 'dayjs';
import { useFirestore } from 'reactfire';
import {
  CalendarContext,
  TimeBalanceContext,
  UserContext
} from 'context/Context';
import { getDaySeconds, getUserSchedule, groupBy } from 'helpers/utils';
import timeOffSubtypes from 'components/user/time-off/subtypes.json';

const TimeBalanceProvider = ({ children, userId }) => {
  const { timeOff: timesOff } = useContext(CalendarContext);
  const { company, partners, schedules } = useContext(UserContext);
  const { NO_ID_FIELD: companyId } = company;
  const user = partners?.find(({ NO_ID_FIELD }) => NO_ID_FIELD === userId);
  const { location } = user || {};
  const { holidays = [] } = location || {};
  const schedule = getUserSchedule(schedules, user);
  const userTimesOff = timesOff?.filter(
    ({ participants }) =>
      !participants || participants.some(ref => ref?.path === user?.ref?.path)
  );
  const userCompensations = userTimesOff?.filter(
    ({ approved, subtype }) => approved && subtype === 'compensation'
  );
  const holidayDates = holidays.map(({ start }) =>
    new Date(start).toDateString()
  );
  const compensationDates = [];
  userCompensations?.forEach(({ start, end }) => {
    const date = new Date(start);
    const endDate = new Date(end);
    while (
      date.toISOString().split('T')[0] <= endDate.toISOString().split('T')[0]
    ) {
      const _date = new Date(date);
      const day = _date.getDay();
      if (
        day !== 0 &&
        day !== 6 &&
        !holidayDates.includes(_date.toDateString())
      ) {
        compensationDates.push(_date);
      }
      date.setDate(date.getDate() + 1);
    }
  });
  const compensationSeconds = compensationDates.reduce((total, date) => {
    const daySeconds = getDaySeconds(schedule, date);
    return total + daySeconds;
  }, 0);
  const [state, setState] = useState({});
  const { seconds = 0, records = [], teoricSeconds = 0 } = state || {};
  const [now] = useState(new Date());
  const db = useFirestore();
  const date = new Date();
  const monday = new Date();
  const daytoset = 1;
  const currentDay = date.getDay();
  const distance = daytoset - currentDay;
  monday.setDate(date.getDate() + distance);

  let recordsQuery = query(collection(db, 'none'));

  useEffect(() => {
    (async () => {
      if (
        !companyId ||
        !userId ||
        (state?.companyId === companyId && state?.userId === userId)
      ) {
        return;
      }
      recordsQuery = query(
        collection(db, 'time_control'),
        where('companyId', '==', companyId),
        where('userId', '==', userId),
        where('createdAt', '>=', `${now.getFullYear()}-01-01`),
        where(
          'createdAt',
          '<=',
          `${now.toISOString().match(/\d{4}-\d{2}-\d{2}/)[0]}`
        ),
        orderBy('createdAt', 'asc')
      );
      const snapshot = await getDocs(recordsQuery);
      const { docs = [] } = snapshot || {};
      const records = await Promise.all(docs.map(doc => doc.data()));
      const recordsWithDay = records.map(record => {
        const [day] = record.createdAt.match(/\d{4}-\d{2}-\d{2}/);
        return { ...record, day };
      });
      setState(state => ({
        ...state,
        companyId,
        userId,
        records: recordsWithDay
      }));
    })();
  }, [companyId, userId]);

  useEffect(() => {
    const { diff: seconds, teoricSeconds } = Object.entries(
      groupBy(records, 'day')
    )?.reduce(
      ({ diff, teoricSeconds }, [day, records]) => {
        let lastRecord = [...(records || [])].pop();
        const lastRecordAt = new Date(lastRecord?.createdAt);
        const currentDay = new Date(day);
        const holiday = holidays.find(
          ({ start }) =>
            dayjs(start).format('YYYY-MM-DD') ===
            dayjs(currentDay).format('YYYY-MM-DD')
        );
        const timeOff = userTimesOff?.find(
          ({ start, end }) =>
            dayjs(currentDay).format('YYYY-MM-DD') >=
              dayjs(start).format('YYYY-MM-DD') &&
            dayjs(currentDay).format('YYYY-MM-DD') <=
              dayjs(end).format('YYYY-MM-DD')
        );
        const subtypeData =
          timeOffSubtypes.find(({ value }) => value === timeOff?.subtype) || {};
        const { working } = subtypeData;
        const isHoliday = !!holiday;
        const isTimeOff = !!timeOff;
        const maxSeconds =
          isHoliday || (!working && isTimeOff)
            ? 0
            : getDaySeconds(schedule, currentDay);
        const { diff: dayDiff = 0 } =
          isHoliday || (!working && isTimeOff)
            ? {}
            : records?.reduce(
                ({ diff, prev }, { createdAt, type }) => {
                  const time =
                    type === 'in'
                      ? dayjs(prev).diff(createdAt, 'seconds') || 0
                      : 0;
                  return {
                    diff: diff + time,
                    prev: new Date(createdAt),
                    teoricSeconds: teoricSeconds + maxSeconds
                  };
                },
                { diff: 0, prev: lastRecordAt, teoricSeconds: 0 }
              ) || {};
        return {
          diff: diff + dayDiff,
          teoricSeconds: teoricSeconds + maxSeconds
        };
      },
      { diff: 0, teoricSeconds: 0 }
    ) || { diff: 0, teoricSeconds: 0 };
    setState(state => ({ ...state, seconds, teoricSeconds }));
  }, [records]);

  const balance = Math.max(0, seconds - teoricSeconds - compensationSeconds);

  // console.log('TIME BALANCE >>>', state, schedule, balance);

  return (
    <TimeBalanceContext.Provider
      value={{
        ...state,
        balance
      }}
    >
      {children}
    </TimeBalanceContext.Provider>
  );
};

TimeBalanceProvider.propTypes = {
  children: PropTypes.node.isRequired,
  userId: PropTypes.string
};

export default TimeBalanceProvider;
