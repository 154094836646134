import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import AppContext, { EmployeesContext } from 'context/Context';
import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';

const texts = {
  all: 'Todos',
  star: 'Favoritos',
  archive: 'Archivados'
};
const FilterDropdownItem = ({ active, children, ...rest }) => (
  <Dropdown.Item
    as={Flex}
    justifyContent="between"
    className="text-capitalize cursor-pointer"
    {...rest}
  >
    {children}
    {active && <FontAwesomeIcon icon="check" transform="down-4 shrink-4" />}
  </Dropdown.Item>
);

const FilterDropdown = () => {
  const {
    employeesState: { filters, currentFilter },
    employeesDispatch
  } = useContext(EmployeesContext);
  const {
    config: { isRTL }
  } = useContext(AppContext);

  const handleSelect = filter => {
    employeesDispatch({
      type: 'FILTER',
      payload: filter
    });
  };

  return (
    <Dropdown className="font-sans-serif">
      <Dropdown.Toggle
        variant="falcon-default"
        size="sm"
        className="text-600 dropdown-caret-none ms-1"
      >
        <FontAwesomeIcon icon="sliders-h" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="py-2" align={isRTL ? 'end' : 'start'}>
        {filters?.map(filter =>
          filter ? (
            <FilterDropdownItem
              active={filter === currentFilter}
              key={`Filter-${filter}`}
              onClick={() => handleSelect(filter)}
            >
              {texts[filter]}
            </FilterDropdownItem>
          ) : (
            <hr key={`Filter-${filter}`} />
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

FilterDropdownItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node
};

export default FilterDropdown;
