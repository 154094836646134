import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card } from 'react-bootstrap';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import Event from 'components/company/events/Event';
import { collection, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { UserContext } from 'context/Context';

const Events = ({ bodyClassName, cardTitle, header, ...rest }) => {
  const db = useFirestore();
  const { company } = useContext(UserContext);
  let auxDate = new Date();
  auxDate.setDate(auxDate.getDate() - 1);
  const [yesterday] = useState(auxDate.toISOString());

  let eventsQuery = query(collection(db, 'none'));
  if (company?.NO_ID_FIELD) {
    eventsQuery = query(
      collection(db, 'calendar'),
      where('type', '==', 'event'),
      where('companyId', '==', company?.NO_ID_FIELD),
      where('participants', '==', null),
      where('createdAt', '>', yesterday)
    );
  }
  const { data = [] } = useFirestoreCollectionData(eventsQuery);

  return (
    <Card {...rest}>
      {header || <FalconCardHeader title={cardTitle} light />}
      <Card.Body className={classNames('fs--1 overflow-auto', bodyClassName)}>
        {!data.length && <span>No hay eventos</span>}
        {data.map((event, index) => (
          <Event
            key={`Event-${event.NO_ID_FIELD}`}
            details={event}
            isLast={index === data.length - 1}
          />
        ))}
      </Card.Body>
      <FalconCardFooterLink
        title="Todos los eventos"
        to="/company/events"
        size="sm"
      />
    </Card>
  );
};

Events.propTypes = {
  bodyClassName: PropTypes.string,
  cardTitle: PropTypes.string,
  header: PropTypes.node,
  events: PropTypes.arrayOf(PropTypes.shape(Event.propTypes))
};

export default Events;
