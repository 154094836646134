import React, { useContext } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { RankingContext, UserContext } from 'context/Context';
import RankingPodium from 'components/company/rankings/RankingPodium';
import RankingUserList from 'components/company/rankings/RankingUserList';
import SectionTitle from 'components/company/rankings/SectionTitle';
import { groupBy } from 'helpers/utils';

const RankingEvents = () => {
  const {
    events = [],
    setModalData,
    setIsOpenObjectivesModal
  } = useContext(RankingContext);
  const { partners = [] } = useContext(UserContext);

  const eventsByUser = Object.entries(groupBy(events, 'userPath'))
    .map(([path, data]) => ({
      count: data?.length,
      user: partners?.find(({ ref }) => ref?.path === path)
    }))
    .filter(({ user }) => user)
    .sort((u1, u2) => (u1.count > u2.count ? -1 : 1));

  return (
    <>
      <SectionTitle
        icon="calendar-day"
        title="Eventos"
        subtitle="Ranking de empleados por asistencia a eventos"
        transform="shrink-4"
        className="mb-4 mt-6"
      />
      <Row className="g-3 mb-3">
        <Col xs={12} lg={eventsByUser.length > 3 ? 8 : 12}>
          <Card className="h-100">
            <Card.Body>
              <RankingPodium
                data={eventsByUser}
                unit="asistencia(s) a eventos"
              />
            </Card.Body>
          </Card>
        </Col>
        {eventsByUser.length > 3 && (
          <Col xs={12} lg={4}>
            <Card className="h-100">
              <Card.Body>
                <RankingUserList
                  data={eventsByUser}
                  unit="asistencia(s) a eventos"
                />
              </Card.Body>
              <Card.Footer className="d-grid bg-light p-0 border-top text-center">
                <Button
                  variant="link"
                  size="sm"
                  className="w-100 py-2"
                  onClick={() => {
                    setModalData({
                      data: eventsByUser,
                      title: 'Ranking de empleados por asistencia a eventos',
                      unit: 'asistencia(s) a eventos'
                    });
                    setIsOpenObjectivesModal(true);
                  }}
                >
                  Ver todo
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default RankingEvents;
