import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { Card, CardGroup, Row, Col } from 'react-bootstrap';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import IndexCharts from './IndexCharts';
import Departments from './Departments';
import Alerts from './Alerts';
import Employees from './Employees';
import Feedback from './Feedback';
import FeedbackTrendingWords from './FeedbackTrendingWords';
import HalfDoughnutChart from './HalfDoughnutChart';
import MonthlyUseOfHappyfy from './MonthlyUseOfHappyfy';
import MostHappyUsers from './MostHappyUsers';
import RankingEvents from './RankingEvents';
import RankingObjectives from './RankingObjectives';
import RecentActivity from './RecentActivity';
import SectionTitle from './SectionTitle';
import Totals from './Totals';
import UsersUsingHappyfy from './UsersUsingHappyfy';
import types from './types.json';

dayjs.locale('es');

const MonthlyReport = () => {
  let { department, report } = useParams();
  report = report || 'happiness';

  return (
    <>
      {report === 'happiness' && (
        <>
          <section>
            <SectionTitle
              icon="hand-point-up"
              title="Uso de Happyfy"
              className="mb-5"
            />
            <Row className="g-3 mb-3">
              <Col xs={6}>
                <MonthlyUseOfHappyfy />
              </Col>
              <Col xs={6}>
                <UsersUsingHappyfy />
              </Col>
            </Row>
          </section>

          <section>
            <SectionTitle
              icon="chart-line"
              title="Índices totales de este mes"
              className="mb-5"
            />
            <Card className="border border-3 shadow-none">
              <HalfDoughnutChart type="happiness" />
            </Card>
            <Row
              className="border border-2 rounded-3 bg-300 overflow-hidden g-3 mb-3 mx-0"
              as={CardGroup}
            >
              {Object.keys(types).map(
                type =>
                  type !== 'happiness' && (
                    <Col
                      key={`Totals-${type}`}
                      as={Card}
                      className={classNames(
                        'border border-1 rounded-top-0 m-0 shadow-none',
                        {}
                      )}
                    >
                      <Totals type={type} />
                    </Col>
                  )
              )}
            </Row>
          </section>

          <section>
            <SectionTitle
              icon="flag"
              title="Actividad este mes"
              className="mb-5"
            />
            <Row className="g-3 mb-3">
              <Col>
                <RecentActivity className="h-100" />
              </Col>
            </Row>
          </section>

          {!department && (
            <section>
              <SectionTitle
                icon="laugh"
                title="Empleados y departamentos más felices"
                className="mb-5"
              />
              <Row className="g-3 mb-3">
                <Col xs={6}>
                  <MostHappyUsers type="happiness" className="h-100" />
                </Col>
                <Col xs={6}>
                  <Departments type="happiness" className="h-100" />
                </Col>
              </Row>
            </section>
          )}

          <section>
            <SectionTitle
              icon="chart-line"
              title="Gráficas por índice"
              // subtitle={`El porcentage que se muestra es el de ${month} y la diferencia respecto al mes anterior. La línea azul es la media.`}
              className="mb-5"
            />
            {Object.keys(types).map(type => (
              <IndexCharts key={`Chart-${type}`} className="mb-4" type={type} />
            ))}
          </section>

          <section>
            <SectionTitle
              icon="users"
              title="Empleados"
              subtitle="La lista de empleados está ordenada por felicidad"
              className="mb-5"
            />
            <Employees />
          </section>

          <section>
            <SectionTitle
              icon="exclamation-circle"
              title="Alertas"
              className="mb-4"
            />
            <Alerts />
          </section>
        </>
      )}

      {!department && ['happiness', 'feedback'].includes(report) && (
        <>
          <section>
            <SectionTitle
              icon="comment-medical"
              title="Sugerencias"
              className="mb-5"
            />
            <FeedbackTrendingWords className="h-100" />
            <Feedback />
          </section>
        </>
      )}

      {['happiness', 'events'].includes(report) && (
        <>
          <section>
            <SectionTitle
              icon="calendar-day"
              title="Eventos"
              subtitle="Ranking de empleados por asistencia a eventos"
              transform="shrink-4"
              className="mb-5"
            />
            <RankingEvents />
          </section>
        </>
      )}

      {['happiness', 'objectives'].includes(report) && (
        <>
          <section>
            <SectionTitle
              icon="trophy"
              title="Objetivos"
              subtitle="Ranking de empleados por objetivos cumplidos"
              transform="shrink-4"
              className="mb-5"
            />
            <RankingObjectives />
          </section>
        </>
      )}
    </>
  );
};

MonthlyReport.propTypes = {
  date: PropTypes.string
};

export default MonthlyReport;
