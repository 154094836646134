import React, { useContext } from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import SimpleBarReact from 'simplebar-react';
import { ChatContext } from 'context/Context';
import ChatInfoHeader from './ChatInfoHeader';

const ChatInfo = () => {
  const { currentChat, isOpenChatInfo } = useContext(ChatContext);
  const { avatar, name, participants = [], type } = currentChat || {};

  return (
    <div
      className={classNames(
        'conversation-info bg-white border-start overflow-hidden',
        {
          show: isOpenChatInfo
        }
      )}
    >
      <Flex direction="column" className="h-100">
        <ChatInfoHeader chat={currentChat} />
        <Flex
          direction="column"
          alignItems="center"
          className="position-relative p-3 border-bottom hover-actions-trigger"
        >
          <Avatar src={avatar} name={name} size="5xl" />
          <Flex className="mt-2">
            <h6 className="mb-0">{name}</h6>
          </Flex>
        </Flex>
        <div className="h-0 flex-grow-1 overflow-auto">
          <SimpleBarReact
            style={{
              height: '100%',
              minWidth: '75px'
            }}
          >
            <div className="px-3 pt-2">
              <Nav className="flex-column font-sans-serif fw-medium"></Nav>
            </div>
            <div className="px-3">
              <div className="title">
                {['company', 'group'].includes(type) && (
                  <>
                    <h6>Participantes</h6>
                    <div>
                      {participants.map(
                        ({ id, avatar, department, name, status }, index) => (
                          <Flex
                            className="align-items-center py-2 hover-actions-trigger"
                            key={index}
                          >
                            <Avatar
                              className={status}
                              size="xl"
                              src={avatar}
                              name={name}
                            />
                            <div className="d-flex flex-1 ms-2 justify-content-between">
                              <div>
                                <h6 className="mb-0">
                                  <Link
                                    to={id && `/user/profile/${id}`}
                                    className="text-700"
                                  >
                                    {name}
                                  </Link>
                                </h6>
                                <div className="fs--2 text-400">
                                  {department}
                                </div>
                              </div>
                              <Dropdown className="dropdown-active-trigger hover-actions position-relative z-index-1">
                                <Dropdown.Toggle
                                  id="dropdown-button"
                                  className="dropdown-caret-none text-400"
                                  variant="link"
                                  size="sm"
                                >
                                  <FontAwesomeIcon icon="ellipsis-h" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="py-2">
                                  <Dropdown.Item className="cursor-pointer">
                                    Mensaje
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="cursor-pointer"
                                    to={`/user/profile/${id}`}
                                    as={Link}
                                  >
                                    Ver perfil
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </Flex>
                        )
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </SimpleBarReact>
        </div>
      </Flex>
    </div>
  );
};

export default ChatInfo;
