import React from 'react';
import { Card } from 'react-bootstrap';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import FeedProvider from 'components/company/feed/FeedProvider';
import Cashback from 'components/benefits/cashback';
import Discounts from 'components/benefits/discounts';
import Exclusive from 'components/benefits/exclusive';
import Custom from './Custom';
import LastFeed from './LastFeed';
import NextHoliday from './NextHoliday';
import MyNextEvents from './MyNextEvents';
import MyNextTimesOff from './MyNextTimesOff';
import MyObjectives from './MyObjectives';
import NewAppreciation from './NewAppreciation';
import NewFeedback from './NewFeedback';
import PendingEvents from './PendingEvents';
import PendingObjectives from './PendingObjectives';
import PendingTimesOff from './PendingTimesOff';
import TodayTimeControl from './TodayTimeControl';
import TimeControlPartners from './TimeControlPartners';

const apps = {
  appreciation: [
    {
      key: 'NewAppreciation',
      content: <NewAppreciation className="w-100 h-100" />,
      size: 2
    }
  ],
  events: [
    {
      key: 'PendingEvents',
      content: <PendingEvents className="w-100 h-100 accordion-collapse" />,
      size: 2
    },
    {
      key: 'NextEvents',
      content: <MyNextEvents className="w-100 h-100 accordion-collapse" />,
      size: 2
    }
  ],
  feed: [
    {
      key: 'LastFeed',
      content: (
        <FeedProvider>
          <LastFeed className="w-100 h-100" />
        </FeedProvider>
      ),
      size: 2
    }
  ],
  feedback: [
    {
      key: 'NewFeedback',
      content: <NewFeedback className="w-100 h-100" />,
      size: 2
    }
  ],
  objectives: [
    {
      key: 'PendingObjectives',
      content: (
        <PendingObjectives
          key="PendingObjectives"
          className="w-100 h-100 flex-grow-1"
        />
      ),
      size: 2
    },
    {
      key: 'MyObjectives',
      content: (
        <MyObjectives key="MyObjectives" className="w-100 h-100 flex-grow-1" />
      ),
      size: 2
    }
  ],
  'time-control': [
    {
      key: 'TodayTimeControl',
      content: <TodayTimeControl className="w-100 h-100" />,
      size: 1
    },
    {
      key: 'TimeControlPartners',
      content: <TimeControlPartners className="w-100 h-100" />,
      size: 2
    },
    {
      key: 'NextHoliday',
      content: <NextHoliday className="w-100 h-100" />,
      size: 1
    }
  ],
  'time-off': [
    {
      key: 'NextTimesOff',
      content: <MyNextTimesOff className="w-100 h-100 accordion-collapse" />,
      size: 2
    },
    {
      key: 'PendingTimesOff',
      content: <PendingTimesOff className="w-100 h-100 accordion-collapse" />,
      size: 2
    }
  ]
};

const Employee = () => {
  return (
    <Custom apps={apps}>
      <Exclusive
        className="mt-2"
        gridClass="flex-nowrap overflow-auto"
        grid={{
          lg: 3
        }}
        header={
          <Card.Header className="pb-0">
            <h6 className="m-0">Ofertas exclusivas</h6>
          </Card.Header>
        }
        footer={
          <FalconCardFooterLink
            title="Todas las ofertas exclusivas"
            to="/benefits/exclusive"
            size="sm"
          />
        }
      />
      <Discounts
        gridClass="flex-nowrap overflow-auto"
        grid={{
          lg: 3
        }}
        header={
          <Card.Header className="pb-0">
            <h6 className="m-0">Descuentos</h6>
          </Card.Header>
        }
        footer={
          <FalconCardFooterLink
            title="Todos los descuentos"
            to="/benefits/discounts"
            size="sm"
          />
        }
      />
      <Cashback
        gridClass="flex-nowrap overflow-auto"
        header={
          <Card.Header className="pb-0">
            <h6 className="m-0">Cashback en compras online</h6>
          </Card.Header>
        }
        footer={
          <FalconCardFooterLink
            title="Todas las marcas"
            to="/benefits/cashback"
            size="sm"
          />
        }
      />
    </Custom>
  );
};

export default Employee;
