import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import Flex from 'components/common/Flex';
import { Button, Col, Collapse, Form, Row } from 'react-bootstrap';
import InputField from '../InputField';
import { UserContext } from 'context/Context';

const ExperienceForm = ({ children, data, index, setShow, show, user }) => {
  const { updateUser } = useContext(UserContext);
  const {
    company: companyProp,
    dates = {},
    position: positionProp
  } = data || {};
  const { start: startProp, end: endProp } = dates;
  const [formData, setFormData] = useState({
    company: companyProp,
    position: positionProp,
    currentlyWork: !endProp,
    start: startProp,
    end: endProp
  });
  const {
    day: startDay,
    month: startMonth,
    year: startYear
  } = formData.start || {};
  const startDate =
    startYear &&
    new Date(
      `${startYear ? startYear : ''}${startMonth ? `-${startMonth}` : ''}${
        startDay ? `-${startDay}` : ''
      }`
    );
  const { day: endDay, month: endMonth, year: endYear } = formData.end || {};
  const endDate =
    endYear &&
    new Date(
      `${endYear ? endYear : ''}${endMonth ? `-${endMonth}` : ''}${
        endDay ? `-${endDay}` : ''
      }`
    );

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSetStart = value => {
    const month = value.getMonth() + 1;
    const year = value.getFullYear();
    setFormData({ ...formData, start: { month, year } });
  };

  const handleSetEnd = value => {
    const month = value.getMonth() + 1;
    const year = value.getFullYear();
    setFormData({ ...formData, end: { month, year } });
  };

  const handleCancel = () => {
    setShow(false);
    setFormData({
      company: companyProp || '',
      position: positionProp || '',
      currentlyWork: !endProp,
      start: startProp || null,
      end: endProp || null
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { company, currentlyWork, position, start, end } = formData;
    const data = {
      company,
      position,
      dates: {
        start,
        end: currentlyWork ? null : end
      }
    };
    let { experience = [] } = user?.profile || {};
    if (index) {
      experience[index] = data;
    } else {
      experience = [...experience, data];
    }
    experience = experience.sort((e1, e2) =>
      `${e1.dates?.start?.year}-${e1.dates?.start?.month}` >
      `${e2.dates?.start?.year}-${e2.dates?.start?.month}`
        ? -1
        : 1
    );
    updateUser(user, { 'profile.experience': experience });
    handleCancel();
  };

  return (
    <div>
      {children || (
        <Flex
          alignItems="center"
          className="mb-4 text-primary cursor-pointer fs-0"
          onClick={() => setShow(show => !show)}
        >
          <span className="circle-dashed">
            <FontAwesomeIcon icon="plus" />
          </span>
          <span className="ms-3">Añadir experiencia</span>
        </Flex>
      )}
      <Collapse in={show}>
        <div>
          <Form onSubmit={handleSubmit}>
            <InputField
              value={formData.company}
              label="Empresa"
              name="company"
              handleChange={handleChange}
            />
            <InputField
              value={formData.position}
              label="Posición"
              name="position"
              handleChange={handleChange}
            />

            <Form.Group as={Row} className="mb-3" controlId="currentlyWork">
              <Col sm={{ span: 10, offset: 3 }}>
                <Form.Check
                  type="checkbox"
                  label="Sigo trabajando aquí"
                  checked={formData.currentlyWork}
                  onChange={({ target }) =>
                    setFormData({ ...formData, currentlyWork: target.checked })
                  }
                />
              </Col>
            </Form.Group>

            <InputField
              type="date"
              value={startDate && dayjs(startDate).format('MMMM YYYY')}
              openToDate={startDate}
              label="Desde"
              name="start"
              onChange={handleSetStart}
            />

            <InputField
              type="date"
              value={endDate && dayjs(endDate).format('MMMM YYYY')}
              openToDate={endDate}
              label="Hasta"
              name="end"
              onChange={handleSetEnd}
              formControlProps={{
                disabled: formData.currentlyWork
              }}
            />

            <Form.Group as={Row} className="mb-3 ms-0 g-3">
              <Col sm={{ span: 'auto', offset: 3 }}>
                <Button
                  variant="link"
                  className="text-600"
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
              </Col>
              <Col sm={{ span: 'auto' }}>
                <Button type="submit">Guardar</Button>
              </Col>
            </Form.Group>
          </Form>

          <div className="border-dashed border-bottom my-3" />
        </div>
      </Collapse>
    </div>
  );
};

ExperienceForm.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
  index: PropTypes.number,
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  user: PropTypes.object
};

export default ExperienceForm;
