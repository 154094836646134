import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Background from 'components/common/Background';
import corner1 from 'assets/img/illustrations/corner-1.png';

const QuestionaireIntro = ({ questionaire }) => {
  const { title, description } = questionaire;
  return (
    <Card className="mb-3 py-2">
      <Background image={corner1} className="p-x1 bg-card" />
      <Card.Header className="pb-0">
        <h3 className="mb-0">{title}</h3>
      </Card.Header>
      <Card.Body>
        <span>{description}</span>
      </Card.Body>
    </Card>
  );
};

QuestionaireIntro.propTypes = {
  questionaire: PropTypes.object
};

export default QuestionaireIntro;
