import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { Card, Col } from 'react-bootstrap';
import dayjs from 'dayjs';
import Avatar from 'components/common/Avatar';
import Background from 'components/common/Background';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { UserContext } from 'context/Context';
import cornerBg from 'assets/img/illustrations/corner-2.png';

const InfoItem = ({ title, content, className }) => {
  return (
    <div className={classNames('mb-4', className)}>
      <h6
        className={classNames({
          'mb-1': title === 'Email' || title === 'Phone Number'
        })}
      >
        {title}
      </h6>
      {title === 'Email' && (
        <a className="fs--1" href={`mailto: ${content}`}>
          {content}
        </a>
      )}
      {title === 'Phone Number' && (
        <a className="fs--1" href={`tel: ${content.replace(/\s+/g, '')}`}>
          {content}
        </a>
      )}
      {title !== 'Email' && title !== 'Phone Number' && (
        <p className="mb-0 text-700 fs--1">{content}</p>
      )}
    </div>
  );
};

InfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  className: PropTypes.string
};

const ContactProfile = () => {
  const { me } = useContext(UserContext);
  const { userId = me?.NO_ID_FIELD } = useParams();
  const { partners } = useContext(UserContext);
  const user = partners?.find(({ NO_ID_FIELD }) => NO_ID_FIELD === userId);
  const { address, avatar, birthday, department, email, name, phone, startAt } =
    user || {};

  return (
    <Card className="mb-xl-3">
      <FalconCardHeader
        title="Información del empleado"
        titleTag="h6"
        className="py-3"
      />
      <Card.Body className="bg-light">
        <Card className="border rounded-3 bg-white dark__bg-1000 shadow-none">
          <Background
            image={cornerBg}
            className="bg-card d-none d-sm-block d-xl-none"
          />
          <Card.Body className="row g-0 flex-column flex-sm-row flex-xl-column z-index-1 align-items-center">
            <Col xs="auto" className="text-center me-sm-x1 me-xl-0">
              <Avatar
                className="ticket-preview-avatar"
                src={avatar}
                name={name}
              />
            </Col>
            <Col sm={8} md={6} lg={4} xl={12} className="ps-sm-1 ps-xl-0">
              <p className="fw-semi-bold mb-0 text-800 text-center text-sm-start text-xl-center mt-3 mt-sm-0 mt-xl-3">
                {name}
              </p>
              <p className="mb-0 text-800 text-center text-sm-start text-xl-center mb-3">
                {department}
              </p>
              <Flex justifyContent="center" className="gap-2">
                <IconButton
                  variant="primary"
                  icon="plus"
                  transform="shrink-3 down-1"
                  size="sm"
                  className="w-50 px-2 text-nowrap"
                >
                  <span className="fs--2">New Ticket</span>
                </IconButton>
                {phone && (
                  <IconButton
                    variant="falcon-default"
                    icon="phone-alt"
                    transform="shrink-4"
                    size="sm"
                    className="w-50"
                    as={Link}
                    to={`tel:${phone}`}
                  >
                    <span className="fs--2">Llamar</span>
                  </IconButton>
                )}
              </Flex>
            </Col>
          </Card.Body>
        </Card>
        <div className="border rounded-3 p-x1 mt-3 bg-white dark__bg-1000 mx-0 g-0">
          {email && <InfoItem title="Email" content={email} />}
          {phone && <InfoItem title="Teléfono" content={phone} />}
          {address && <InfoItem title="Dirección" content={address} />}
          {birthday && (
            <InfoItem
              title="Cumpleaños"
              content={dayjs(birthday).format('D MMMM YYYY')}
            />
          )}
          {startAt && (
            <InfoItem
              className="mb-sm-0"
              title="Fecha de inicio"
              content={dayjs(startAt).format('D MMMM YYYY')}
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ContactProfile;
