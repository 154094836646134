import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row, Table } from 'react-bootstrap';
import AIButton from 'components/common/AIButton';
import Avatar from 'components/common/Avatar';
import BasicECharts from 'components/common/BasicEChart';
import Flex from 'components/common/Flex';
import * as echarts from 'echarts/core';
import { getColor, groupBy } from 'helpers/utils';
import FalconLink from 'components/common/FalconLink';
import { AIContext, IndexesContext, UserContext } from 'context/Context';
import types from './types.json';

import dayjs from 'dayjs';
dayjs.locale('es');

const MembersRow = ({
  user,
  color,
  value,
  labels,
  data,
  diff,
  title,
  type
}) => {
  let {
    NO_ID_FIELD,
    birthday,
    description,
    name,
    avatar,
    department,
    isLast,
    status
  } = user || {};
  const { ai } = useContext(AIContext);
  const date = labels[labels.length - 1];
  status = ['offline', 'online'].includes(status) ? status : 'offline';

  const handleAI = () => {
    const context = [
      {
        role: 'system',
        content:
          'Eres un experto en recursos humanos de una empresa donde se cuidan mucho a sus empleados.'
      },
      birthday && {
        role: 'system',
        content: `Ten en cuenta la fecha de nacimento del empleado: ${dayjs(
          birthday
        ).format('D MMMM YYYY')}`
      },
      description && {
        role: 'system',
        content: `Ten en cuenta esta descripción y/o hobbies del empleado: ${description}`
      },
      {
        role: 'system',
        content: `Tras la siguiente pregunta que te voy a hacer, vas a escribir una valoración de la métrica de ${title} de ${name} que es ${value}%. Haz una lista en formato tabla de 2 posibles formas de mejorar esta métrica dependiendo de la valoración. Las soluciones deben ser específicas para mejorar su estado en las métricas indicadas (Alguna podrían ser con coste económico). Resalta en bold lo que vas a hacer. Sé conciso y evita ser genérico.`
      }
    ].filter(message => message);
    const prompt = `¿Cómo podría mejorar la métrica de ${title} de ${name} a fecha de ${dayjs().format(
      'MMMM [de] YYYY'
    )}?`;
    const filename = `happyfy-ia-${name
      .toLowerCase()
      .replace(/ /g, '-')}--improve-${type}.pdf`;
    ai({ context, prompt, filename });
  };

  return (
    <tr className={classNames({ 'border-bottom border-200': !isLast })}>
      <td>
        <Flex alignItems="center" className="position-relative">
          <Avatar
            className={`status-${status}`}
            size="2xl"
            src={avatar}
            width="60"
            name={name}
            alt={name}
          />
          <div className="flex-1 ms-3">
            <h6 className="mb-0 fw-semi-bold">
              <Link
                className="text-dark stretched-link"
                to={`/user/profile/${NO_ID_FIELD}`}
              >
                {name}
              </Link>
            </h6>
            <p className="fs--2 mb-0 text-500">{department}</p>
          </div>
        </Flex>
      </td>
      <td className="align-middle fw-semi-bold">
        <Flex alignItems="baseline" className="gap-1 mb-1">
          <h5 className="mb-0">{value}</h5>
          {diff !== 0 && (
            <h6 className={classNames(`mb-0 fw-semi-bold text-${color}`)}>
              <FontAwesomeIcon
                icon={`caret-${diff > 0 ? 'up' : 'down'}`}
                className="fs--2 me-1 ms-2"
              />
              <span className="fs--1">{Math.abs(diff)}</span>
            </h6>
          )}
        </Flex>
        <h6 className="mb-0 text-700">{dayjs(date).format('DD MMM YYYY')}</h6>
      </td>
      <td className="fs-0 align-middle text-center fw-semi-bold">
        {value < 100 && (
          <AIButton
            onClick={handleAI}
            tooltip="¿Cómo podría mejorar esta métrica?"
          />
        )}
      </td>
      <td className="align-middle">
        <Row className="justify-content-end">
          <Col xs="auto" className="mt-auto">
            <BasicECharts
              echarts={echarts}
              options={{
                color: getColor('primary'),
                labels,
                tooltip: {
                  trigger: 'item',
                  padding: [7, 10],
                  backgroundColor: getColor('gray-100'),
                  borderColor: getColor('gray-300'),
                  textStyle: { color: getColor('dark') },
                  borderWidth: 1,
                  transitionDuration: 0,
                  formatter: params => {
                    const date = dayjs(
                      new Date(labels[params.dataIndex])
                    ).format('MMM YYYY');
                    return `<strong>${date} :</strong> ${params.data}%`;
                  }
                },
                series: [
                  {
                    data
                  }
                ]
              }}
              className="mb-1"
              style={{ width: '3.625rem', height: '1rem' }}
            />
          </Col>
        </Row>
      </td>
    </tr>
  );
};

const MemberInfo = ({ type }) => {
  const title = types[type];
  const { indexes = [] } = useContext(IndexesContext);
  const { partners = [] } = useContext(UserContext);
  const data = Object.entries(
    groupBy(
      indexes.filter(index => index.type === type),
      'userId'
    )
  )
    .map(([userId, indexes]) => {
      const user = partners.find(({ id }) => id === userId);
      if (!user) {
        return;
      }
      const data = indexes
        .sort((i1, i2) => (i1.createdAt < i2.createdAt ? -1 : 1))
        .map(({ value }) => value);
      const labels = indexes
        .sort((i1, i2) => (i1.createdAt < i2.createdAt ? -1 : 1))
        .map(({ createdAt }) => createdAt);
      const last = data?.[data?.length - 1] || 0;
      const prev = data?.[data?.length - 2] || last;
      const diff = last - prev;
      const color =
        last === prev
          ? 'info'
          : last > prev
          ? (type === 'stress' && 'danger') || 'success'
          : (type === 'stress' && 'success') || 'danger';
      return { user, userId, color, value: last, labels, data, diff };
    })
    .filter(data => data)
    .sort(({ value: v1 }, { value: v2 }) => (v1 > v2 ? -1 : 1));

  return (
    <Card className="h-100">
      <Card.Body className="p-0">
        <SimpleBarReact>
          <Table
            borderless
            className="mb-0 fs--1 border-200 rounded-3 table-dashboard table-member-info"
          >
            <thead className="bg-light">
              <tr className="text-900">
                <th>Usuario</th>
                <th className="text-capitalize">{title}</th>
                <th className="text-center">Formas de mejorar {title}</th>
                <th className="text-end">Histórico</th>
              </tr>
            </thead>
            <tbody>
              {data.map((info, index) => (
                <MembersRow
                  key={`MemberRow-${info.userId}`}
                  {...info}
                  title={title}
                  type={type}
                  isLast={index === data.length - 1}
                />
              ))}
            </tbody>
          </Table>
        </SimpleBarReact>
      </Card.Body>

      <Card.Footer className="bg-light py-2">
        <Row className="g-0 flex-between-center">
          <Col xs="auto"></Col>
          <Col xs="auto">
            <FalconLink
              title="Todos los empleados"
              to="/company/employees"
              className="px-0"
            />
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

MembersRow.propTypes = {
  user: PropTypes.object,
  isLast: PropTypes.bool,
  color: PropTypes.string,
  value: PropTypes.number,
  labels: PropTypes.array,
  data: PropTypes.array,
  diff: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  type: PropTypes.string
};

MemberInfo.propTypes = {
  type: PropTypes.string,
  data: PropTypes.array.isRequired
};

export default MemberInfo;
