import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import Objective from 'components/company/objectives/Objective';
import { CalendarContext, UserContext } from 'context/Context';

export const PendingObjectives = ({ className }) => {
  const { objectives: data = [], setIsOpenNewObjectiveModal } =
    useContext(CalendarContext);
  const { me } = useContext(UserContext);
  const myObjectives = data
    .filter(
      ({ participants }) =>
        !participants || participants?.[0]?.path === me?.ref?.path
    )
    .sort(({ createdAt: c1 }, { createdAt: c2 }) => (c1 > c2 ? -1 : 1));
  const objectives = (me?.type === 'admin' ? data : myObjectives)
    .filter(
      ({ start, state, validated }) =>
        validated === null ||
        state === 'waiting' ||
        (start < new Date().toISOString() && (!state || state === 'on-course'))
    )
    .sort((o1, o2) => (o1.validated < o2.validated ? -1 : 1));

  const handleEnter = timeOff => {
    const { NO_ID_FIELD } = timeOff;
    const els = document.querySelectorAll(`[data-id="${NO_ID_FIELD}"]`);
    els?.forEach(el => {
      el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.add(
        'highlight'
      );
    });
  };

  const handleLeave = timeOff => {
    const { NO_ID_FIELD } = timeOff;
    const els = document.querySelectorAll(`[data-id="${NO_ID_FIELD}"]`);
    els?.forEach(el => {
      el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.remove(
        'highlight'
      );
    });
  };

  return (
    <Card className={className}>
      <Card.Body className="fs--1">
        {!objectives.length && (
          <Flex
            className="h-100 p-3 text-center"
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon icon="trophy" className="fs-2 text-400 mb-2" />
            <p>No hay objetivos pendientes de revisar</p>
            <Button
              variant="falcon-default"
              className="rounded-pill"
              size="sm"
              onClick={() => setIsOpenNewObjectiveModal(true)}
            >
              Crear objetivo
            </Button>
          </Flex>
        )}
        {objectives.map((details, index) => (
          <Objective
            key={`Objective-${details.NO_ID_FIELD}`}
            color="yellow"
            details={details}
            isLast={index === objectives.length - 1}
            onMouseEnter={() => handleEnter(details)}
            onMouseLeave={() => handleLeave(details)}
          />
        ))}
      </Card.Body>
    </Card>
  );
};

PendingObjectives.propTypes = {
  className: PropTypes.string
};

export default PendingObjectives;
