import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import ProductGrid from './ProductGrid';
import Flex from 'components/common/Flex';
import Search from 'components/common/Search';
import SoftBadge from 'components/common/SoftBadge';
import { collection, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

const week = new Date();
week.setDate(week.getDate() - 7);

const Cashback = ({ grid, gridClass, header, footer, limit }) => {
  const db = useFirestore();
  const [search, setSearch] = useState('');

  const productsQuery = query(
    collection(db, 'programmes'),
    where('source', '==', 'awin')
  );
  const { data: products = [] } = useFirestoreCollectionData(productsQuery);
  const totalItems = products.length;
  let filteredProducts = products
    .map(product => {
      const isNew = product.start > week.toISOString();
      return { ...product, isNew };
    })
    .filter(({ name = '' }) => {
      const normalizedName =
        name
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '') || '';
      const normalizedQuery =
        search
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '') || '';
      return normalizedName.includes(normalizedQuery);
    })
    .sort((p1, p2) => (p1.name < p2.name ? -1 : 1));
  if (limit) {
    filteredProducts = filteredProducts.slice(0, limit);
  }

  return (
    <>
      {!header && (
        <Card className="mb-3">
          <Card.Header>
            <h5 className="mb-3">Cashback</h5>
            <Row className="flex-between-center">
              <Col
                sm="auto"
                as={Flex}
                alignItems="center"
                className="mb-2 mb-sm-0"
              >
                <div>
                  <h6>
                    Obtén un{' '}
                    <SoftBadge pill bg="primary">
                      porcentage
                    </SoftBadge>{' '}
                    o{' '}
                    <SoftBadge pill bg="accent">
                      euros
                    </SoftBadge>{' '}
                    de reembolso en tus compras en{' '}
                    {search ? (
                      <>
                        {filteredProducts.length} tiendas online{' '}
                        <span className="text-500">
                          ({totalItems} en total)
                        </span>
                      </>
                    ) : (
                      <>{totalItems} tiendas online</>
                    )}{' '}
                    a través de estos links.
                  </h6>
                  <h6>
                    El reembolso se añadirá a tu monedero de Happyfy una vez
                    realizada la compra
                  </h6>
                </div>
              </Col>
              <Col sm="auto">
                <Row className="gx-2 align-items-center">
                  <Col xs="auto">
                    <Form as={Row} className="gx-2">
                      <Col xs="auto">
                        <Search onChange={setSearch} />
                      </Col>
                    </Form>
                  </Col>
                  <Col xs="auto" className="pe-0"></Col>
                </Row>
              </Col>
            </Row>
          </Card.Header>
        </Card>
      )}
      <Card className="mb-3">
        {header}
        <Card.Body className="pb-0">
          <Row className={gridClass}>
            {filteredProducts.map((product, index) => (
              <ProductGrid
                product={product}
                key={`Product-${product.NO_ID_FIELD}`}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xxl={2}
                {...grid}
                index={index}
              />
            ))}
          </Row>
        </Card.Body>
        {footer}
      </Card>
    </>
  );
};

Cashback.propTypes = {
  grid: PropTypes.object,
  gridClass: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
  limit: PropTypes.number
};

export default Cashback;
