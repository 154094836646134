import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, Row, Col, Dropdown, Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Calendar from 'components/common/Calendar';
import EmojiMart from 'components/common/EmojiMart';
import { Link } from 'react-router-dom';
import { CalendarContext, UserContext } from 'context/Context';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ObjectiveDetailHeader = ({ objective = {} }) => {
  const { me, partners = [] } = useContext(UserContext);
  const { update } = useContext(CalendarContext);
  const {
    NO_ID_FIELD,
    state = 'on-course',
    participants = [],
    photos,
    reactions: rawReactions = [],
    start,
    title,
    validated
  } = objective;
  const day = dayjs(start).format('DD');
  const month = dayjs(start).format('MMM');
  const reactions = Object.entries(
    rawReactions.reduce((all, { reaction }) => {
      return { ...all, [reaction]: (all?.[reaction] || 0) + 1 };
    }, {})
  );
  const stateText = !validated
    ? 'Pendiente de validar'
    : state === 'success'
    ? 'Conseguido'
    : state === 'fail'
    ? 'No conseguido'
    : 'En curso';

  const handleReaction = async ({ native: reaction }) => {
    const createdAt = new Date().toISOString();
    const reactions = [
      {
        createdAt,
        ref: me?.ref,
        reaction
      },
      ...(rawReactions.filter(({ ref }) => ref?.path !== me?.ref?.path) || [])
    ];
    update(objective, { reactions });
  };

  const handleStatus = async state => {
    update(objective, { state });
  };

  const handleValidate = async validated => {
    update(objective, { validated });
  };

  const objectiveBanner = photos?.[0];

  return (
    <Card className="p-0 mb-3">
      {!!objectiveBanner && (
        <img
          className="card-img-top fit-cover max-vh-50"
          src={objectiveBanner}
          alt=""
        />
      )}
      <Card.Body>
        <Row className="flex-center">
          <Col>
            <Flex>
              <Calendar day={day} month={month} color="warning" />
              <div className="fs--1 ms-2 flex-1">
                <h5 className="fs-0 text-capitalize">{title}</h5>
                <p className="mb-1">
                  {!participants?.length ? (
                    <span className="text-700">Para toda la empresa</span>
                  ) : (
                    participants.map((ref, index) => {
                      const user = partners.find(
                        partner => partner.ref?.path === ref?.path
                      );
                      const { NO_ID_FIELD, name } = user || {};
                      return (
                        <>
                          <Link
                            key={`User-${NO_ID_FIELD}`}
                            to={`/user/profile/${NO_ID_FIELD}`}
                            className={classNames('text-700', {
                              'ps-1': index > 0
                            })}
                          >
                            {name}
                          </Link>
                          {index < participants.length - 1 && <span>,</span>}
                        </>
                      );
                    })
                  )}
                </p>
              </div>
            </Flex>
          </Col>
          <Col md="auto" className="mt-4 mt-md-0">
            <Flex wrap="wrap" justifyContent="end" className="gap-2">
              <EmojiMart onEmojiSelect={handleReaction} />
              {reactions.map(([key, value], index) => (
                <Button
                  key={`Reaction-${NO_ID_FIELD}-${index}`}
                  variant="falcon-default"
                  onClick={() => handleReaction({ native: key })}
                >
                  {key} <span className="ms-1">{value}</span>
                </Button>
              ))}
              {me?.type === 'admin' && validated === null && (
                <Dropdown className="z-index-1016">
                  <Dropdown.Toggle
                    className="px-4 px-sm-5"
                    variant={
                      validated === true
                        ? 'success'
                        : validated === false
                        ? 'danger'
                        : 'falcon-default'
                    }
                  >
                    {validated === null && (
                      <FontAwesomeIcon
                        className="me-2"
                        icon={['far', 'clock']}
                      />
                    )}
                    Pendiente de validar
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="py-2">
                    <Dropdown.Item
                      active={validated === null}
                      onClick={() => handleValidate(null)}
                    >
                      <span>Pendiente de validar</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      active={validated === true}
                      onClick={() => handleValidate(true)}
                    >
                      Validado
                    </Dropdown.Item>
                    <Dropdown.Item
                      active={validated === false}
                      onClick={() => handleValidate(false)}
                    >
                      No validado
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {me?.type !== 'admin' && (
                <Button
                  disabled={me?.type !== 'admin'}
                  className="px-4 px-sm-5"
                  variant={
                    validated === 'success'
                      ? 'success'
                      : state === 'fail'
                      ? 'danger'
                      : 'falcon-default'
                  }
                >
                  {validated === null && (
                    <FontAwesomeIcon className="me-2" icon={['far', 'clock']} />
                  )}
                  {stateText}
                </Button>
              )}
              {me?.type === 'admin' && validated && (
                <Dropdown className="z-index-1016">
                  <Dropdown.Toggle
                    className="px-4 px-sm-5"
                    variant={
                      state === 'success'
                        ? 'success'
                        : state === 'fail'
                        ? 'danger'
                        : 'falcon-default'
                    }
                  >
                    {state === 'on-course' && (
                      <FontAwesomeIcon
                        className="me-2"
                        icon={['far', 'clock']}
                      />
                    )}
                    {stateText}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="py-2">
                    <Dropdown.Item
                      active={state === 'on-course'}
                      onClick={() => handleStatus('on-course')}
                    >
                      <span>En curso</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      active={state === 'success'}
                      onClick={() => handleStatus('success')}
                    >
                      Conseguido
                    </Dropdown.Item>
                    <Dropdown.Item
                      active={state === 'fail'}
                      onClick={() => handleStatus('fail')}
                    >
                      No conseguido
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ObjectiveDetailHeader.propTypes = {
  objective: PropTypes.object
};

export default ObjectiveDetailHeader;
