import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import SoftBadge from 'components/common/SoftBadge';
import { Nav } from 'react-bootstrap';
import LastMessage from './LastMessage';
import ChatSidebarDropdownAction from './ChatSidebarDropdownAction';
import { ChatContext, UserContext } from 'context/Context';

dayjs.extend(calendar);

const Chat = ({ chat = {} }) => {
  const { setIsOpenChatList } = useContext(ChatContext);
  const { me } = useContext(UserContext);
  const { unreadMessages } = me || {};
  const { unread = 0 } =
    unreadMessages?.find(({ ref }) => ref?.path === chat?.ref?.path) || {};
  let { NO_ID_FIELD, avatar, name, status, type, updatedAt } = chat || {};

  return (
    <Nav.Link
      to={`/company/chat/${NO_ID_FIELD}`}
      className={classNames(`chat-contact border-0 p-0 hover-actions-trigger`, {
        'unread-message': unread,
        'read-message': !unread
      })}
      as={Link}
      active={window.location.pathname.includes(NO_ID_FIELD)}
      onClick={() => setIsOpenChatList?.(false)}
    >
      <div className="d-md-none d-lg-block">
        <ChatSidebarDropdownAction chat={chat} />
      </div>
      <Flex alignItems="center">
        <Avatar
          className={classNames('ms-3', {
            [`status-${status}`]: type === 'user' && !!status
          })}
          src={avatar}
          name={name}
          size="2xl"
        />
        <div className="border-top py-3 pe-3 flex-1 chat-contact-body ms-3 d-md-none d-lg-block">
          <Flex justifyContent="between">
            <h6 className="mb-0 chat-contact-title">{name}</h6>
            <span className="message-time text-400 fs--2">
              {' '}
              {dayjs(updatedAt)
                .calendar(null, {
                  sameDay: 'HH:mm',
                  lastDay: '[Ayer]',
                  lastWeek: 'dddd',
                  sameElse: 'ddd, D MMM'
                })
                .replace('.', '')}{' '}
            </span>
          </Flex>
          <div className="min-w-0">
            <div className="chat-contact-content">
              <LastMessage chat={chat} />
            </div>
          </div>
        </div>
        {unread > 0 && (
          <SoftBadge
            pill
            bg="accent"
            className="position-absolute bottom-0 end-0 m-2"
          >
            <span className="fs--2">{unread}</span>
          </SoftBadge>
        )}
      </Flex>
    </Nav.Link>
  );
};

Chat.propTypes = {
  chat: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export default Chat;
