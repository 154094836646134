import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Schedule } from 'components/company/profile/Schedules';
import { UserContext } from 'context/Context';

const UserSchedule = ({ className, user }) => {
  const { schedules } = useContext(UserContext);
  const [schedule] =
    schedules?.filter(({ users }) =>
      users?.some(ref => ref?.path === user?.ref?.path)
    ) || [];

  return (
    <Card className={className}>
      <FalconCardHeader title="Horario" light />
      <Card.Body>
        <Schedule schedule={schedule} />
      </Card.Body>
    </Card>
  );
};

UserSchedule.propTypes = {
  className: PropTypes.string,
  company: PropTypes.object,
  user: PropTypes.object
};

export default UserSchedule;
