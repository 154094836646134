import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { collection, query, where } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { UserContext } from 'context/Context';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

dayjs.extend(relativeTime);
dayjs.locale('es');

const Review = ({ review }) => {
  const {
    NO_ID_FIELD,
    createdAt,
    title: rawTitle,
    description: rawDescription,
    type
  } = review;
  const { me, partners: all = [] } = useContext(UserContext);
  const time = dayjs(createdAt)
    .calendar(null, {
      sameDay: `HH:mm`,
      lastDay: 'D MMM',
      lastWeek: 'D MMM',
      sameElse: 'D MMM'
    })
    .replace('.', '');

  const db = useFirestore();
  let userResponseQuery = query(collection(db, 'none'));
  if (NO_ID_FIELD && me?.NO_ID_FIELD) {
    userResponseQuery = query(
      collection(db, 'reviews_responses'),
      where('reviewId', '==', NO_ID_FIELD),
      where('userId', '==', me?.NO_ID_FIELD)
    );
  }
  const { data: responses = [] } =
    useFirestoreCollectionData(userResponseQuery);

  let users = [me];
  if (type === 'manager') {
    users = all.filter(
      ({ department, level }) =>
        department === me?.department && level === me?.level - 1
    );
  }
  if (type === 'employeeToManager') {
    users = all.filter(
      ({ department, level }) =>
        department === me?.department && level === me?.level + 1
    );
  }
  const hasResponded = responses.length === users.length;

  if (!users.length) {
    return null;
  }

  const user = users[0];
  const title = rawTitle?.replace(/{{([^}]+)}}/g, (s, key) => user?.[key]);
  const description = rawDescription?.replace(
    /{{([^}]+)}}/g,
    (s, key) => user?.[key]
  );

  return (
    <Row
      className={classNames(
        'border-bottom border-200 hover-actions-trigger hover-shadow hover-z-index-1016 py-2 px-3 mx-0 align-items-center',
        {
          'bg-light': hasResponded,
          'fw-bold': !hasResponded
        }
      )}
    >
      <Col className="flex-grow-1">
        <Row>
          <Col sm="auto" className="ps-md-0 mb-1 mb-md-0">
            <SoftBadge
              bg={classNames({
                success: hasResponded,
                warning: !hasResponded
              })}
              className="ms-2"
            >
              <FontAwesomeIcon
                icon={classNames({
                  check: hasResponded,
                  'exclamation-triangle': !hasResponded
                })}
              />
            </SoftBadge>
          </Col>
          <Col>
            <Link
              className="d-block inbox-link"
              to={`/user/reviews/detail/${NO_ID_FIELD}`}
            >
              <Flex alignItems="center">
                <span className="text-dark">{title}</span>
                <SoftBadge
                  bg={classNames({
                    success: hasResponded,
                    warning: !hasResponded
                  })}
                  className="ms-1"
                >
                  {users.length > 1 && (
                    <span>
                      {responses.length}/{users.length}
                    </span>
                  )}
                </SoftBadge>
                <span className="mx-1 fw-normal">&ndash;</span>
                <span className="w-0 flex-1 text-600 fw-normal overflow-hidden text-truncate text-nowrap">
                  {description}
                </span>
              </Flex>
            </Link>
          </Col>
        </Row>
      </Col>
      <Col xs="auto" className="ms-auto align-self-start">
        <small>{time}</small>
      </Col>
    </Row>
  );
};

Review.propTypes = {
  review: PropTypes.shape({
    NO_ID_FIELD: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    archived: PropTypes.bool,
    star: PropTypes.bool,
    createdAt: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string
  }),
  isSelectedItem: PropTypes.func,
  toggleSelectedItem: PropTypes.func.isRequired
};

export default Review;
