import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import AppContext, {
  CalendarContext,
  ChatContext,
  UserContext
} from 'context/Context';
import DatePicker from 'react-datepicker';
import UserList from 'components/common/UserList';

const NewObjectiveModal = ({
  setIsOpenScheduleModal,
  isOpenScheduleModal,
  scheduleDate,
  setScheduleDate
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const { create } = useContext(CalendarContext);
  const { currentChat } = useContext(ChatContext) || {};
  const { me, partners = [] } = useContext(UserContext);
  const { participants } = currentChat || {};
  const userListRef = useRef();
  const userList =
    participants ||
    partners?.filter(({ department, level }) => {
      return (
        me?.type === 'admin' ||
        (department === me?.department && level < me?.level && false)
      );
    }) ||
    [];

  const [formData, setFormData] = useState({
    className: 'bg-soft-orange',
    validated: me?.type === 'admin' || null,
    state: 'on-course',
    participants: [me?.ref]
  });

  const handleClose = () => {
    setIsOpenScheduleModal(!isOpenScheduleModal);
  };

  const handleChange = useCallback(({ target }) => {
    let name = target.name;
    let value = name === 'validated' ? target.checked : target.value;
    setFormData(formData => ({ ...formData, [name]: value }));
  }, []);

  const handleChangeSelection = useCallback(users => {
    handleChange({
      target: {
        name: 'participants',
        value: users.map(({ ref }) => ref)
      }
    });
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    let { participants, ...data } = formData;
    if (!participants?.length) {
      participants = null;
    }
    create({ ...data, participants, type: 'objective' });
    setIsOpenScheduleModal(false);
  };

  useEffect(() => {
    if (!isOpenScheduleModal) {
      return;
    }
    setTimeout(() => {
      const { id: rowToSelect } =
        userListRef?.current?.rows.find(
          ({ original }) => original?.ref?.path === me?.ref?.path
        ) || {};
      rowToSelect && userListRef?.current?.toggleRowSelected(rowToSelect, true);
    }, 10);
  }, [isOpenScheduleModal]);

  useEffect(() => {
    if (isOpenScheduleModal) {
      const start = scheduleDate && new Date(scheduleDate).toISOString();
      setFormData({
        ...formData,
        start,
        end: null
      });
    } else {
      setScheduleDate(null);
    }
  }, [isOpenScheduleModal, scheduleDate]);

  return (
    <Modal
      show={isOpenScheduleModal}
      onHide={handleClose}
      contentClassName="border"
      size="lg"
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-x1 border-bottom-0"
        >
          <Modal.Title as="h5">Nuevo objetivo</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          <Row className="g-3">
            <Col className="d-flex flex-column">
              <Form.Group className="mb-3" controlId="titleInput">
                <Form.Label className="fs-0">Título</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  required
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="startDate">
                <Form.Label className="fs-0">Fecha límite</Form.Label>
                <DatePicker
                  selected={scheduleDate}
                  onChange={date => {
                    setScheduleDate(date);
                    setFormData({ ...formData, start: date });
                  }}
                  className="form-control"
                  placeholderText="DD-MM-YYYY"
                  dateFormat="dd-MM-yyyy"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="titleInput">
                <Form.Label className="fs-0">Recompensa</Form.Label>
                <Form.Control
                  type="text"
                  name="reward"
                  required
                  onChange={handleChange}
                />
              </Form.Group>

              {me?.type === 'admin' && (
                <Form.Group controlId="Validated">
                  <Form.Check
                    type="checkbox"
                    id="Validated"
                    label="Validado"
                    name="validated"
                    checked={formData.validated}
                    onChange={handleChange}
                  />
                </Form.Group>
              )}

              <Form.Group
                className="mb-3 flex-grow-1 d-flex flex-column"
                controlId="description"
              >
                <Form.Label className="fs-0">Descripción</Form.Label>
                <Form.Control
                  as="textarea"
                  className="flex-grow-1 mb-5"
                  rows={3}
                  name="description"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            {(me?.type === 'admin' || me?.level > 0) && !!userList?.length && (
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="fs-0">Participantes</Form.Label>
                  <UserList
                    innerRef={userListRef}
                    className="shadow-none"
                    onChangeSelection={handleChangeSelection}
                    users={userList}
                    headerClassName="d-none"
                    tableProps={{
                      search: true,
                      selection: true,
                      sortable: true,
                      selectionColumnWidth: 28,
                      pagination: true,
                      perPage: 10
                    }}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer className="bg-light px-x1 border-top-0">
          <Button variant="primary" type="submit" className="px-4 mx-0">
            Crear objetivo
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

NewObjectiveModal.propTypes = {
  setIsOpenScheduleModal: PropTypes.func.isRequired,
  isOpenScheduleModal: PropTypes.bool.isRequired,
  scheduleDate: PropTypes.instanceOf(Date),
  setScheduleDate: PropTypes.func.isRequired
};

export default NewObjectiveModal;
