import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import SimpleBarReact from 'simplebar-react';
import { Card } from 'react-bootstrap';
import Tooltip from 'components/common/Tooltip';
import Avatar from 'components/common/Avatar';
import TimeControlProvider from 'components/user/time-control/TimeControlProvider';
import {
  CalendarContext,
  TimeControlContext,
  UserContext
} from 'context/Context';
import { getDaySeconds, getDistance, getUserSchedule } from 'helpers/utils';

const UserAvatar = ({ user }) => {
  const { avatar, name } = user;
  return (
    <Tooltip className="me-1" title={name}>
      <Avatar src={avatar} name={name} size="2xl" />
    </Tooltip>
  );
};

UserAvatar.propTypes = {
  user: PropTypes.object
};

const TimeControlPartners = ({ className }) => {
  const { timeOff = [] } = useContext(CalendarContext);
  const { todayRecords = [] } = useContext(TimeControlContext);
  const { partners: rawPartners = [], schedules } = useContext(UserContext);
  const [now] = useState(new Date());
  const today = dayjs(now).format('YYYY-MM-DD');
  const partners = rawPartners
    .sort(({ name: n1 }, { name: n2 }) => (n1 < n2 ? -1 : 1))
    .map(user => {
      const records = todayRecords
        .sort((r1, r2) => (r1.createdAt < r2.createdAt ? -1 : 1))
        .filter(({ userId }) => userId === user?.NO_ID_FIELD);
      const schedule = getUserSchedule(schedules, user);
      return { ...user, records, schedule };
    });

  const teleWorkingPartners = partners.filter(
    ({ location: { latlng }, records }) => {
      if (!records?.length) {
        return false;
      }
      const lastRecord = [...(records || [])].pop();
      const { location, type } = lastRecord || {};
      if (!location) {
        return false;
      }
      const distance = getDistance(location, latlng);
      return type === 'in' && distance > 100;
    }
  );

  const workingPartners = partners.filter(({ NO_ID_FIELD, records }) => {
    if (!records?.length) {
      return false;
    }
    const lastRecord = [...(records || [])].pop();
    return (
      lastRecord?.type === 'in' &&
      !teleWorkingPartners
        .map(({ NO_ID_FIELD }) => NO_ID_FIELD)
        .includes(NO_ID_FIELD)
    );
  });

  const pausedPartners = partners.filter(({ location, records, schedule }) => {
    if (!records?.length) {
      return false;
    }
    const lastRecord = [...(records || [])].pop();
    const isWorking = lastRecord?.type === 'in';
    const { diff = 0 } =
      records?.reduce(
        ({ diff, prev }, { createdAt, type }) => {
          const time =
            type === 'in' ? dayjs(prev).diff(createdAt, 'seconds') || 0 : 0;
          return {
            diff: diff + time,
            prev: new Date(createdAt)
          };
        },
        { diff: 0, prev: new Date() }
      ) || {};
    const { holidays = [] } = location;
    const holiday = holidays.find(
      ({ start }) => dayjs(start).format('YYYY-MM-DD') === today
    );
    const isHoliday = !!holiday;
    const maxSeconds = isHoliday ? 0 : getDaySeconds(schedule, now);
    return !isWorking && maxSeconds - diff > 3600;
  });

  const vacationPartners = partners.filter(user => {
    const userTimeOff = timeOff.filter(
      ({ approved, participants, subtype }) =>
        approved &&
        subtype === 'vacation' &&
        (!participants ||
          participants.some(ref => ref?.path === user?.ref?.path))
    );
    return userTimeOff.some(
      ({ start, end }) =>
        dayjs(start).format('YYYY-MM-DD') <= today &&
        dayjs(end).format('YYYY-MM-DD') >= today
    );
  });

  const timeOffPartners = partners.filter(({ NO_ID_FIELD, records }) => {
    return (
      !records?.length &&
      !vacationPartners.some(user => user?.NO_ID_FIELD === NO_ID_FIELD)
    );
  });

  return (
    <Card className={className}>
      <SimpleBarReact className="h-100">
        <Card.Body className="p-0">
          <div>
            <Card.Header>
              <h6 className="m-0">Trabajando</h6>
            </Card.Header>
            <Card.Body className="pt-0">
              {!workingPartners?.length && (
                <span className="fs--1">Nadie trabajando</span>
              )}
              {workingPartners.map(user => (
                <UserAvatar key={`Working-${user?.NO_ID_FIELD}`} user={user} />
              ))}
            </Card.Body>
          </div>
          <div>
            <Card.Header>
              <h6 className="m-0">Teletrabajando</h6>
            </Card.Header>
            <Card.Body className="pt-0">
              {!teleWorkingPartners?.length && (
                <span className="fs--1">Nadie teletrabajando</span>
              )}
              {teleWorkingPartners.map(user => (
                <UserAvatar
                  key={`TeleWorking-${user?.NO_ID_FIELD}`}
                  user={user}
                />
              ))}
            </Card.Body>
          </div>
          <div>
            <Card.Header>
              <h6 className="m-0">Descansando</h6>
            </Card.Header>
            <Card.Body className="pt-0">
              {!pausedPartners?.length && (
                <span className="fs--1">Nadie descansando</span>
              )}
              {pausedPartners.map(user => (
                <UserAvatar key={`Pause-${user?.NO_ID_FIELD}`} user={user} />
              ))}
            </Card.Body>
          </div>
          <div>
            <Card.Header>
              <h6 className="m-0">Vacaciones</h6>
            </Card.Header>
            <Card.Body className="pt-0">
              {!vacationPartners?.length && (
                <span className="fs--1">Nadie de vacaciones</span>
              )}
              {vacationPartners.map(user => (
                <UserAvatar
                  key={`Pause-${user?.NO_ID_FIELD}`}
                  user={user}
                  badge
                />
              ))}
            </Card.Body>
          </div>
          <div>
            <Card.Header>
              <h6 className="m-0">Ausencias</h6>
            </Card.Header>
            <Card.Body className="pt-0">
              {!timeOffPartners?.length && (
                <span className="fs--1">Nadie ausente</span>
              )}
              {timeOffPartners.map(user => (
                <UserAvatar
                  key={`Pause-${user?.NO_ID_FIELD}`}
                  user={user}
                  badge
                />
              ))}
            </Card.Body>
          </div>
        </Card.Body>
      </SimpleBarReact>
    </Card>
  );
};

TimeControlPartners.propTypes = {
  className: PropTypes.string
};

const TimeControlPartnersWithProvider = props => {
  return (
    <TimeControlProvider>
      <TimeControlPartners {...props} />
    </TimeControlProvider>
  );
};

export default TimeControlPartnersWithProvider;
