import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getColorByName, hexToRgba, isIterableArray } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from './Flex';
import classNames from 'classnames';

const Avatar = ({
  children,
  size,
  rounded,
  src: srcProp,
  name,
  emoji,
  className,
  maxChars,
  mediaClass,
  isExact,
  icon
}) => {
  const [src, setSrc] = useState(srcProp);
  const classNames = [
    'avatar bg-white',
    `avatar-${size}`,
    rounded ? `rounded-${rounded}` : 'rounded',
    className
  ].join(' ');
  const mediaClasses = [
    rounded ? `rounded-${rounded}` : 'rounded',
    mediaClass
  ].join(' ');

  useEffect(() => {
    setSrc(srcProp);
  }, [srcProp]);

  const getAvatar = () => {
    if (src) {
      if (isIterableArray(src)) {
        return (
          <div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
            <div className="w-50 border-right">
              <img src={src[0]} alt="" />
            </div>
            <div className="w-50 d-flex flex-column">
              <img src={src[1]} alt="" className="h-50 border-bottom" />
              <img src={src[2]} alt="" className="h-50" />
            </div>
          </div>
        );
      } else {
        return (
          <img
            className={mediaClasses}
            src={src}
            alt=""
            onError={() => setSrc()}
          />
        );
      }
    }

    if (typeof name !== 'undefined') {
      const color = getColorByName(name);
      return (
        <div
          className={`avatar-name overflow-hidden ${mediaClasses}`}
          style={{ background: hexToRgba(color, 0.3) }}
        >
          <span style={{ color }}>
            {(isExact ? name : name.match(/\b\w/g)?.join(''))?.substr(
              0,
              maxChars
            )}
          </span>
        </div>
      );
    }

    if (icon) {
      return (
        <Flex className={`avatar-name ${mediaClasses}`}>
          <FontAwesomeIcon icon={icon} />
        </Flex>
      );
    }

    return (
      <div className={`avatar-emoji ${mediaClasses}`}>
        {children || (
          <span role="img" aria-label="Emoji">
            {emoji}
          </span>
        )}
      </div>
    );
  };

  return <div className={classNames}>{getAvatar()}</div>;
};

export const AvatarGroup = ({ children = [], dense, className, max = 2 }) => {
  const childrenToShow = children?.slice?.(0, max) || [];
  const overflow = children.length - childrenToShow.length;

  return (
    <div
      className={classNames(className, 'avatar-group', {
        'avatar-group-dense': dense
      })}
    >
      {overflow > 0 && (
        <Avatar
          {...(children?.[0]?.props || {})}
          src=""
          name={`+${overflow}`}
          isExact
        />
      )}
      {childrenToShow}
    </div>
  );
};

Avatar.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl']),
  rounded: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  name: PropTypes.string,
  emoji: PropTypes.string,
  className: PropTypes.string,
  maxChars: PropTypes.number,
  mediaClass: PropTypes.string,
  isExact: PropTypes.bool,
  icon: PropTypes.string
};

Avatar.defaultProps = {
  size: 'xl',
  rounded: 'circle',
  emoji: '😊',
  maxChars: 2,
  isExact: false
};

AvatarGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dense: PropTypes.bool,
  max: PropTypes.number
};

export default Avatar;
