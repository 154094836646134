import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import VacationLock from 'components/company/profile/VacationLock';
import AppContext from 'context/Context';

const LockModal = ({ setIsOpen, isOpen }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      contentClassName="border"
      size="lg"
    >
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className="bg-light px-x1 border-bottom-0"
      >
        <Modal.Title>Días bloqueados para vacaciones</Modal.Title>
      </Modal.Header>
      <VacationLock className="shadow-none" showHeader={false} />
    </Modal>
  );
};

LockModal.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default LockModal;
