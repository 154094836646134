import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import ObjectiveDetailAside from './ObjectiveDetailAside';
import ObjectiveDetailContent from './ObjectiveDetailContent';
import ObjectiveDetailHeader from './ObjectiveDetailHeader';
import { CalendarContext } from 'context/Context';

const ObjectiveDetail = () => {
  const { objectiveId } = useParams();
  const { objectives = [] } = useContext(CalendarContext);
  const objective = objectives.find(({ id }) => id === objectiveId) || {};

  return (
    <>
      <ObjectiveDetailHeader objective={objective} />
      <Row className="g-3">
        <Col lg={8}>
          <ObjectiveDetailContent objective={objective} />
        </Col>
        <Col lg={4}>
          <ObjectiveDetailAside objective={objective} />
        </Col>
      </Row>
    </>
  );
};

export default ObjectiveDetail;
