import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import { getDownloadURL, ref } from '@firebase/storage';
import { useStorage } from 'reactfire';
import { FeedContext } from 'context/Context';
import FeedGallery from './FeedGallery';
import FalconLightBox from 'components/common/FalconLightBox';

const FeedImages = ({ images: imagesProp }) => {
  const { scrollToFeed } = useContext(FeedContext);
  const storage = useStorage();
  const [images, setImages] = useState(imagesProp);

  const initImages = async () => {
    const images = await Promise.all(
      imagesProp.map(async url => {
        if (
          url?.match(
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
          )
        ) {
          return url;
        }
        return await getDownloadURL(ref(storage, url));
      })
    );
    setImages(images);
  };

  useEffect(() => {
    initImages();
  }, [imagesProp]);

  return (
    <>
      {images?.length === 1 && (
        <FalconLightBox image={images[0]}>
          <Image
            className="max-w-640px"
            src={images[0]}
            fluid
            rounded
            onLoad={scrollToFeed}
          />
        </FalconLightBox>
      )}
      {images?.length > 1 && <FeedGallery images={images} />}
    </>
  );
};

FeedImages.propTypes = {
  images: PropTypes.array
};

export default FeedImages;
